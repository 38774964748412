import * as React from "react";
import {Dropdown, DropdownItemProps, Header, Icon, Modal} from "semantic-ui-react";
import {
    importSimOutputTableAvailableColumns,
    importSimOutputTableSelectedColumns
} from "./ImportSimTransportOrderOutputTable";
import "./ImportSimTransportOrderTableColumnArranger.css"

export interface ImportSimTransportOrderOutputTableColumnSelectorModalProps {
    isOpen: boolean
    onClose: (refresh: boolean) => void
}

export function ImportSimTransportOrderOutputTableColumnSelectorModal(props: React.PropsWithChildren<ImportSimTransportOrderOutputTableColumnSelectorModalProps>) {


    //save reference for dragItem and dragOverItem
    const dragItem = React.useRef<any>(null)
    const dragOverItem = React.useRef<any>(null)

    //const handle drag sorting
    const handleSort = () => {
        //duplicate items
        let _displayColumns = [...importSimOutputTableSelectedColumns.value]

        //remove and save the dragged item content
        const draggedItemContent = _displayColumns.splice(dragItem.current, 1)[0]

        //switch the position
        _displayColumns.splice(dragOverItem.current, 0, draggedItemContent)

        //reset the position ref
        dragItem.current = null
        dragOverItem.current = null

        //reset indexes
        let i = 0
        //update the actual array
        importSimOutputTableSelectedColumns.value = _displayColumns.map(c => {
            c.index = i
            i++
            return c
        })
    }

    function handleClose() {
        props.onClose(false)
    }

    function dropDownPropertyNameOptions() {
        let opt: Array<DropdownItemProps> = [];
        importSimOutputTableAvailableColumns.value.forEach(value => {
            opt.push({key: value, value: value.toString(), text: value});
        })
        return opt
    }

    function drawSorter() {
        return <div className="list-container">
            {importSimOutputTableSelectedColumns.value.map((column) => (
                <div
                    key={column.index}
                    className="list-item"
                    draggable
                    onDragStart={(e) => (dragItem.current = column.index)}
                    onDragEnter={(e) => (dragOverItem.current = column.index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}>

                    <Icon name={"bars"} />
                    <h4>{column.name}</h4>
                </div>
            ))}
        </div>
    }
    function drawDropdown() {
        return <Dropdown
            selectOnBlur={false}
            style={{width: "240px"}}
            placeholder={"Fields"}
            fluid
            multiple
            selection
            clearable
            search
            options={dropDownPropertyNameOptions()}
            value={importSimOutputTableSelectedColumns.value.map(c => c.name)}
            onChange={(evt, data) => {
                let newColumns: any[] = [];
                (data.value as string[]).forEach(value => {
                    newColumns.push({index: newColumns.length, name: value})
                })
                importSimOutputTableSelectedColumns.value = newColumns
                localStorage.setItem("importSimOutputTableSelectedColumns", JSON.stringify(importSimOutputTableSelectedColumns.value))
            }
            }/>
    }

    return <Modal
        open={props.isOpen}
        onClose={handleClose}
        dimmer="blurring"
        size='fullscreen'>
        <Header icon='browser' content='Select and order Columns'/>
        <Modal.Content>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            {drawDropdown()}
            {drawSorter()}
            </div>
        </Modal.Content>
    </Modal>

}