import * as React from "react";
import {Header, Modal} from "semantic-ui-react";
import {QueuedEvent} from "../../../../../generated";
import AceEditor from "react-ace";

export interface QueuedEventModalProps {
    isOpen: boolean
    onClose: (openRollback: boolean) => void
    queuedEvent: QueuedEvent
}

export class QueuedEventModal extends React.Component<QueuedEventModalProps, any> {

    constructor(props) {
        super(props)
        this.state = {
            showRollBackView: false,
            oldTo: null,
            newTo: null
        }
    }

    handleOpenRollback = (openRollback: boolean) => {

        this.props.onClose(openRollback)

    }

    handleClose = () => {
        this.props.onClose(false)
    }


    render() {
        if (this.props.queuedEvent != null) {
            return <Modal
                open={this.props.isOpen}
                onClose={this.handleClose}
                onMount={() => {
                }}
                dimmer="blurring"
                size='fullscreen'>
                <Header icon='browser' content='QueuedEvent View'/>
                <Modal.Content>

                    <AceEditor
                        theme="monokai"
                        mode='groovy'
                        value={JSON.stringify(this.props.queuedEvent, null, 4)}
                        placeholder='Message'
                        width="100%"
                        height="800px"
                        readOnly={true}

                    />
                </Modal.Content>
            </Modal>
        } else {
            return <p/>
        }
    }

}