import * as React from "react";
import {useContext, useEffect} from "react";
import {useHistory} from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import {ConfigContext} from "../../context/ConfigContext";
import {PageWithSidebar} from "../../../style/page_with_sidebar";
import {Tab} from "semantic-ui-react";
import {SystemStatusMessagePage} from "./SystemStatusMessage/SystemStatusMessagePage";
import {HealthAndVersionReportPage} from "./healthAndVersion/HealthAndVersionReportPage";
import {CustomerReportPage} from "./CustomerReport/CustomerReportPage";
import {RawRequestsPage} from "./RawRequests/RawRequestsPage";
import {ReleaseNotePage} from "./ReleaseNotes/ReleaseNotePage";
import {PipelineStatusPage} from "./PipelineStatus/PipelineStatusPage";

export function SystemStatusPage() {
    const context = useContext(ConfigContext);
    const history = useHistory();

    const [activeIndex, setActiveIndex] = React.useState(0);

    useEffect(() => {

        let tabFromUrl = getUrlTab();
        if (tabFromUrl) {
            console.log("[SystemStatusPage] setting tab from url ", tabFromUrl)
            setContextTab(parseInt(tabFromUrl));
            setActiveIndex(parseInt(tabFromUrl));
        } else {
            let tabFromContext = getContextTab();
            console.log("[SystemStatusPage] setting tab from context ", tabFromContext)
            if (tabFromContext) {
                setUrlTab(tabFromContext);
                setActiveIndex(tabFromContext);
            } else {
                console.log("[SystemStatusPage] no preset tab found, it stays 0")
            }
        }
    }, []);


    // URL handling
    function setUrlTab(tabIndex: string | number) {
        history.push({
            pathname: history.location.pathname,
            search: `?tab=${tabIndex}`
        });
    }

    function getUrlTab() {
        const url = new URL(window.location.href);
        return url.searchParams.get("tab");
    }

    // Context handling
    function setContextTab(tabIndex: number) {
        context.setSingleTabSession(
            "systemStatusTab",
            tabIndex,
            null,
            null
        )
    }

    function getContextTab() {
        return context.tabSession.systemStatusTab;
    }


    return (
        <PageWithSidebar activeMenu="System status">
            <Tab
                id={"SystemStatusTabView"}
                class={"flexTab"}
                panes={[
                    {pane: null, menuItem: 'Messages', render: () => <SystemStatusMessagePage/>},
                    {pane: null, menuItem: 'Health & Versions', render: () => <HealthAndVersionReportPage/>},
                    {pane: null, menuItem: 'Customer Report', render: () => <CustomerReportPage/>},
                    {pane: null, menuItem: 'Raw Requests', render: () => <RawRequestsPage/>},
                    {pane: null, menuItem: 'ReleaseNotes', render: () => <ReleaseNotePage/>},
                    {pane: null, menuItem: 'Pipeline', render: () => <PipelineStatusPage/>},
                ]}
                defaultActiveIndex={activeIndex}
                activeIndex={activeIndex}
                onTabChange={(_, data) => {
                    let index = parseInt(data.activeIndex.toString());
                    console.log("[SystemStatusPage] onTabChange", index);
                    setContextTab(index);
                    setUrlTab(index);
                    setActiveIndex(index);
                }}/>
        </PageWithSidebar>
    );
}