import * as React from 'react';

import {DateRangePicker} from "react-dates";

import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import '../../style/CustomDateRangePicker.css';
import {Moment} from "moment";
import * as moment from 'moment';


export interface CustomDateRangePickerProps {
    startDate?: Moment,
    endDate?: Moment,
    id?: string,
    onDatesSelected: (startDate?: Moment, endDate?: Moment) => (void),
}

export interface CustomDateRangePickerState {
    focusedInput: any,
    startDate?: Moment,
    endDate?: Moment,
    fullscreen: false,
    direction: 'left',
    dateFormat: 'DD.MM.YYYY',
    small: false,
    block: boolean,
    orientation: 'horizontal',
    numMonths: 1,
    minimumNights: 0,
    isOutsideRange: false,
}

export class CustomDateRangePicker extends React.Component<CustomDateRangePickerProps, CustomDateRangePickerState>{

    constructor (props: CustomDateRangePickerProps) {
        super(props)

        this.state = {
            focusedInput: null,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            fullscreen: false,
            direction: 'left',
            dateFormat: 'DD.MM.YYYY',
            small: false,
            block: false,
            orientation: 'horizontal',
            numMonths: 1,
            minimumNights: 0,
            isOutsideRange: false
        }

        this.handleDatesChange = this.handleDatesChange.bind(this)
        this.handleFocusChange = this.handleFocusChange.bind(this)
    }



    componentDidUpdate(prevProps: CustomDateRangePickerProps) {
        if(prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate){
            this.setState({startDate:this.props.startDate, endDate:this.props.endDate})
        }
        // if (prevProps.focusedInput != this.props.focusedInput) {
        //     this.handleFocusChange(this.props.focusedInput)
        // }
    }

    onClose = () => {
        setTimeout(()=>{this.props.onDatesSelected(this.state.startDate, this.state.endDate)}, 1)
    }


    handleDatesChange({ startDate, endDate }: any) {
        let startMoment = null
        if (startDate != null) {
            startMoment = moment(startDate.startOf('day'))
        }
        let endMoment = null
        if (endDate != null) {
            endMoment = moment(endDate.endOf('day'))
        }
        console.log("handleDate: ")
        console.log("start: ", startDate)
        console.log("start: ", startMoment)
        console.log("end: ", endDate)
        console.log("end: ", endMoment)
        this.setState({startDate:startMoment ?? undefined, endDate:endMoment ?? undefined})
    }

    handleFocusChange(focusedInput: any) {
        this.setState({ focusedInput })
    }





    render() {
        return <DateRangePicker
            noBorder={true}
            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
            startDateId={"unique_start_date_id" + "_" + this.props.id} // PropTypes.string.isRequired,
            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
            endDateId={"unique_end_date_id" + "_" + this.props.id} // PropTypes.string.isRequired,
            onDatesChange={this.handleDatesChange} // PropTypes.func.isRequired,
            onClose={this.onClose}
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={this.handleFocusChange} // PropTypes.func.isRequired,
            displayFormat={this.state.dateFormat}
            hideKeyboardShortcutsPanel={true}
            numberOfMonths={this.state.numMonths || 2}
            block={this.state.block}
            small={this.state.small}
            withFullScreenPortal={this.state.fullscreen}
            anchorDirection={this.state.direction}
            orientation={this.state.orientation}
            minimumNights={this.state.minimumNights}
            isOutsideRange={() => false}
        />
    }
}