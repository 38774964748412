import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button, Dropdown, DropdownItemProps, Grid, Header, Icon, Label, Modal, Radio} from "semantic-ui-react";
import {ConfigContext} from "../../../../main/context/ConfigContext";
import {IfBox} from "../../../../style/if";
import {CompanyConfiguration, GenericXlsSpecificConfiguration} from "../../../../../generated";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import {getSourceCode, setSourceCode} from "../slices/sourceCodeSlice";
import {useDispatch, useSelector} from "react-redux";
import {configStorage} from "../../../../../ConfigStorage";
import {updateConfig, workingConfig} from "../../../../main/pages/configChange/ConfigSignal";

export interface SyncScriptModalProps {
    isOpen: boolean,
    onClose: () => void,
    script: string
}

export function SyncScriptModal(props: React.PropsWithChildren<SyncScriptModalProps>) {

    const context = useContext(ConfigContext)


    const [layer, setLayer] = useState("default")
    const [selectedSpecific, setSelectedSpecific] = useState<DropdownProps>(null)
    const [path, setPath] = useState("$.")
    const [config, setConfig] = useState<CompanyConfiguration>(null)

    const dispatch = useDispatch()
    const source = useSelector(getSourceCode)

    useEffect(() => {
        if (workingConfig.value) {
            setConfig(JSON.parse(workingConfig.value))
        } else {
            configStorage.getElement(context.companyId).then((c) => {
                setConfig(c.config)
                updateConfig(JSON.stringify(config, null, 4), false, false)
            })

        }
    },[])

    function specificDropdownOptions() {
        let options: Array<DropdownItemProps> = [];

        let index = 0

        config.genericXlsConfiguration.specificConfiguration?.forEach(s => {
            options.push({
                key: index,
                value: JSON.stringify(s, null, 4),
                text: s.verboseName,
            })
            index++
        })
        return options
    }

    function drawPathSelection() {

        if (config?.genericXlsConfiguration) {
            return <>
                <Radio
                    label='default'
                    name='radioGroup'
                    value='default'
                    checked={layer == 'default'}
                    onChange={() => {
                        setLayer('default')
                    }}
                />
                <br/>
                <Radio
                    label='specific'
                    name='radioGroup'
                    value='specific'
                    checked={layer == 'specific'}
                    onChange={() => {
                        setLayer('specific')

                    }}
                />
                <br/>
                <IfBox shouldShow={layer == 'specific'}>
                    <Dropdown
                        selectOnBlur={false}
                        readOnly={false}
                        options={specificDropdownOptions()}
                        selection
                        value={selectedSpecific?.value}
                        onChange={(evt, data) =>
                            setSelectedSpecific(data)
                    }
                    />
                </IfBox>
            </>
        } else {
            return <p>There is no genericXlsConfiguration in your current config</p>
        }
    }

    function drawButtons() {

        let script
        if(layer == 'default') {
            script = config?.genericXlsConfiguration?.importScript
        } else {
            if(selectedSpecific?.value) {
                script = (JSON.parse(selectedSpecific?.value as string) as GenericXlsSpecificConfiguration).override?.importScript
            }
        }

        let newPath = path
        if(layer == 'default') {
            newPath = "$.genericXlsConfiguration.importScript"
        } else {
            if(selectedSpecific?.value) {
                let i = config?.genericXlsConfiguration?.specificConfiguration.findIndex(sc =>
                    sc.verboseName == (JSON.parse(selectedSpecific?.value as string) as GenericXlsSpecificConfiguration).verboseName
                )
                newPath = "$.genericXlsConfiguration.specificConfiguration." +
                    i +
                    ".override.importScript"
            }
        }

        if(path != newPath) {
            setPath(newPath)
        }
        return <>
            <br/>
            <Label>Path: </Label>
            <h4>
                {path}
            </h4>

            <IfBox shouldShow={script == null}>
                <Label color={'red'}>No script present there! Please create one first!</Label>
            </IfBox>
            <br/>

            <Button
                icon
                disabled={script == null}
                onClick={() => loadScript(script)}
            >
                <Icon color={'blue'} name='download' />
                load script
            </Button>
            <Button
                icon
                disabled={script == null}
                onClick={() => saveScript()}
            >
                <Icon color={'green'} name='save' />
                save script
            </Button>
            <Button
                icon
                onClick={() => props.onClose()}
            >
                <Icon color={'red'} name='close' />
                close
            </Button>
        </>
    }

    function loadScript(script) {
        dispatch(setSourceCode(script))
        props.onClose()
    }

    function saveScript() {
        // change in currentJSON
        const jp = require('jsonpath');
        jp.value(config, path, source)
        updateConfig(JSON.stringify(config, null, 4), true, false)
        props.onClose()
    }

    return <Modal
        open={props.isOpen}
        onClose={props.onClose}
        dimmer="blurring"
        size='large'>
        <Header icon='browser' content='Sync Script'/>
        <Modal.Content>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Column>
                    <Grid.Row>
                        {drawPathSelection()}

                    </Grid.Row>
                    <Grid.Row>
                        {drawButtons()}
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                    <h4>Info</h4>
                    <p> Here you can select if you want to load from/save to the default importScript of the genericXlsConfiguration or from/to a specificConfiguration override.
                        For overrides, the dropdown shows the verboseName.
                        This please make sure verboseNames are unique.
                        You cannot create new overrides from here, they must be created in the config before.
                    </p>
                </Grid.Column>
            </Grid>
        </Modal.Content>
    </Modal>
}