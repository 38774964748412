import { useEffect } from 'react';
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export default function useKeypress(actionMap) {

    function onKeyDown(e) {
        console.log("Key down event detected", e.key)
        actionMap[e.key] && actionMap[e.key]()

        var keyCode = e.keyCode;
        if (keyCode >= 37 && keyCode <= 40) {
            return false;
        }
    }

    useEffect(() => {
        const listener = (e)=>onKeyDown(e)
        window.addEventListener('keydown', listener);
        return () => window.removeEventListener('keydown', listener);
    });
}