import * as React from "react";
import {Button, Header, Modal, Popup, TextArea} from "semantic-ui-react";
import {parseDiff} from 'react-diff-view';
import {authentication} from "../../../../../../../authentication";
import {backend} from "../../../../../../../xconvert-backend";
import {Contract, SaveContractRequest} from "../../../../../../../generated";
import {notify} from 'react-notify-toast';
import {diffLines, formatLines} from 'unidiff';
import {DiffWrapper} from "../../../../configChange/configEdit/diff/DiffWrapper";
import {ConfigContext} from "../../../../../context/ConfigContext";
import {IfBox} from "../../../../../../style/if";
import {CustomDiff} from "../../../../configChange/configEdit/diff/CustomDiff";


export interface ResponseModalProps {
    isOpen: boolean
    onClose: () => void
    dispatch: (any) => void
}

export interface ResponseModalState {
    isLoading: boolean
    isSubmitting: boolean
    comment: string
    response: any | null
    hunks: any | null
    type: any | null
    activeIndex: number
    contracts: Contract[]
    canBeSavedAsNew: boolean
    canBeUpdated: boolean
}

export class SaveContractModal extends React.Component<ResponseModalProps, ResponseModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isSubmitting: false,
            comment: "",
            response: null,
            hunks: null,
            type: null,
            activeIndex: -1,
            contracts: [],
            canBeSavedAsNew: false,
            canBeUpdated: false,
        }
    }

    handleClose = (reload) => {
        this.props.dispatch(reload);
        this.props.onClose()
    }


    async save(update: boolean) {
        this.setState({isSubmitting: true})
        let reload = false
        try {
            console.log("updating contract in backend")
            let auth = backend.withTokenAuthHeader(authentication.token)
            let request = {} as SaveContractRequest
            request.companyId = this.context.companyId
            if (update) {
                request.oldContract = JSON.parse(this.context.oldContract)
            }
            request.newContract = JSON.parse(this.context.selectedContract)
            request.comment = this.state.comment
            let response = (await backend.internalApi.saveContract(request, auth))
            if (response.successful) {
                notify.show('saved contract', 'success', 3000, '#28f751');
                this.context.setSelectedContract(null)
            } else {
                notify.show("ERROR: unable to save contract.", 'error', 5000, '#fc0303')
            }

            console.log("backend responded with: ", response)
            this.setState({response: response})
            reload = response.successful
        } catch (ex) {
            console.log(ex);
        }
        this.setState({isSubmitting: false})
        this.handleClose(reload)

    }

    async componentDidMount() {
        if (this.state.hunks == null) {
            let oldConfig = this.context.oldContract
            let newConfig = this.context.selectedContract

            if (oldConfig == null) {
                oldConfig = ""
            }

            const diffText = formatLines(diffLines(oldConfig, newConfig), {context: 3});
            const [diff] = parseDiff(diffText, {nearbySequences: 'zip'});

            this.setState({hunks: diff.hunks});
        }
        let contracts = await this.fetchContracts()
        this.canBeSavedAsNew(contracts)
        this.canBeUpdated(contracts)
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            size='fullscreen'>
            <Header icon='browser' content='Contract change'/>
            <Modal.Content>

                <CustomDiff
                    oldJson={this.cleanBackslashR(this.context.oldContract)}
                    newJson={this.cleanBackslashR(this.context.selectedContract)}
                />

                <h3>leave a comment</h3>
                <TextArea id='CommentTextArea' onChange={(event) => this.setState({comment: event.currentTarget.value})}
                          cols={200}/>
                <br/>
                {this.drawButtons()}


            </Modal.Content>
        </Modal>

    }

    drawSaveButton() {
        return  <span>
                    <Button id='SaveContractModalSaveButton'
                            loading={this.state.isSubmitting}
                            disabled={!this.state.canBeSavedAsNew}
                            onClick={() => {
                                this.save(false);
                                this.handleClose
                            }}>Save as new</Button>
                    </span>
    }
    drawUpdateButton() {
        return   <span>
                    <Button id='UpdateContractModalUpdateButton'
                            loading={this.state.isSubmitting}
                            disabled={this.context.oldContract == null || !this.state.canBeUpdated}
                            onClick={() => {
                                this.save(true);
                                this.handleClose
                            }}>update</Button>
                        </span>
    }
    drawButtons() {
        return <span>
            <IfBox shouldShow={this.context.oldContract == null || !this.state.canBeUpdated}>
                <Popup content='Can not update: ValidFrom is already taken and must be unique.' trigger={
                    this.drawUpdateButton()
                }/>
            </IfBox>
            <IfBox shouldShow={!(this.context.oldContract == null || !this.state.canBeUpdated)}>
                {this.drawUpdateButton()}
            </IfBox>


            <IfBox shouldShow={!this.state.canBeSavedAsNew}>
                <Popup content='Can not save as new: ValidFrom is already taken and must be unique.' trigger={
                    this.drawSaveButton()
                }/>
            </IfBox>
            <IfBox shouldShow={this.state.canBeSavedAsNew}>
                {this.drawSaveButton()}
            </IfBox>
                <Button id='CancelContractModalCancelButton' onClick={() => this.handleClose(false)}>Cancel</Button>

        </span>
    }

    async fetchContracts() {
        this.setState({isLoading: true})
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let response = await backend.internalApi.fetchCompanyContracts(
            this.context.companyId,
            null,
            null,
            null,
            'VALID_FROM',
            "ASC",
            auth)

        this.setState({isLoading: false, contracts: response.contracts})
        return response.contracts
    }

    async canBeUpdated(contracts: Contract[]) {
        // check if validFrom has changed to a time that is already taken
        let canBeUpdated = true
        let contract = JSON.parse(this.context.selectedContract)
        let validFrom = contract.validFrom
        Object.values(contracts).forEach(c => {

            if (this.epoch(c.validFrom) == this.epoch(validFrom)) {
                if (c._id != contract._id)
                    canBeUpdated = false
            }
        })

        this.setState({canBeUpdated: canBeUpdated})


    }

    epoch (date) {
        return Date.parse(date)
    }

    async canBeSavedAsNew(contracts: Contract[]) {
        let canBeSavedAsNew = true
        let validFrom = JSON.parse(this.context.selectedContract).validFrom
        Object.values(contracts).forEach(c => {

            if (this.epoch(c.validFrom) == this.epoch(validFrom)) {
                canBeSavedAsNew = false
            }
        })

        this.setState({canBeSavedAsNew: canBeSavedAsNew})
    }

    cleanBackslashR(str: string): string {
        if (str) {
            return str.split("\\r").join("")
        } else {
            return ""
        }
    }

}

