import * as React from "react";
import { Modal, Header, Button, Label, Input, Divider, Grid } from "semantic-ui-react";
import { ConfigContext } from "../../../context/ConfigContext";
import AceEditor from 'react-ace';

import { backend } from "../../../../../xconvert-backend";
import { authentication } from "../../../../../authentication";
import { ftp } from "../../../../../xconvert-ftp";
import { AddFileRequest } from "../../../../../generated-ftp/api";

import 'brace/ext/searchbox'

export interface FtpExplorerAddFileModalProps {
    isOpen: boolean
    onClose: (success: boolean) => void
    path: string
    contentBase64: string
    content: string
    encoding: string
    fileName: string | null
}

export class FtpExplorerAddFileModal extends React.Component<FtpExplorerAddFileModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            fileName: "",
            content: ""
        }
    }

    async handleSave() {

        let request = {} as AddFileRequest
        if(this.props.contentBase64) {
            request.base64Content = this.props.contentBase64
        }
        request.content = this.state.content
        request.fileName = this.state.fileName
        request.encoding = this.props.encoding

        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let response = (await ftp.ftpApi.addFile(this.props.path, request, this.context.companyId,  auth))

        console.log(response)
        this.props.onClose(response.success)
    }

    handleClose = () => {
        this.props.onClose(false)
    }

    handleChange(event) {
        this.setState({ content: event })
    }

    componentDidMount() {
        if (this.props.content) {
            this.setState({ content: this.props.content })
            if(this.props.fileName) {
                this.setState({fileName: this.props.fileName})
            }
        }
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Add File' />
            <Modal.Content>
                <Grid>
                    <Grid.Row>
                        <Grid.Column />
                        <Grid.Column>
                            <Label pointing='below'>File Name: </Label>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column><Label color="blue" >{this.props.path}</Label></Grid.Column>
                        <Grid.Column>
                            <Input
                                type='text'
                                value={this.state.fileName}
                                placeholder='File name'
                                onChange={(evt) => this.setState({ fileName: evt.target.value })}
                            />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>

                <Divider />
                <Label pointing='below'>File Content: </Label>
                <AceEditor
                    theme="monokai"
                    mode="json"
                    value={this.state.content}
                    width="100%"
                    height="800px"
                    onChange={(e) => this.handleChange(e)}
                    readOnly={false}
                />

                <Button disabled={this.state.fileName == ""} onClick={() => this.handleSave()}>save</Button>
                <Button onClick={() => this.handleClose()}>close</Button>

            </Modal.Content>
        </Modal>
    }

}