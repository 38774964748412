import * as React from "react";
import { useState, useEffect } from 'react';
import { Label, Popup, Segment, Table } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';

export interface ExportSimFortrasDocTableProps {
    output: string,
    encoding: string
}

export function ExportSimFortrasDocTable(props:React.PropsWithChildren<ExportSimFortrasDocTableProps>){
    const [data, setData] = useState([]);
    const [badFormat, setBadFormat] = useState(true);

    async function convertDataToArray() {
        let lisData = props.output;
        try {
            let xReqId = uuid();
            let url = '/converter/fortrasDocToRecordsWithDescription'

            let resp = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    'x-request-id': xReqId,
                    'encoding': props.encoding
                },
                body: lisData
            }).then((resp) => {
                return resp.json();
            });
            setData(resp);
            setBadFormat(false);
        } catch (ex) {
            setBadFormat(true);
        }
    };

    useEffect(() => {
        convertDataToArray();
    }, [props.output]);

    function renderTable(){
        let lineNr = 0;
        return <Segment style={{ overflow: 'scroll', maxWidth: '100%', maxHeight: '650px' }}>
            <Table celled striped singleLine>
                <Table.Body>
                    {data.map((line: any) => {
                        lineNr = lineNr + 1;
                        return <Table.Row>
                            <Table.Cell selectable style={{ padding: '10px' }}>
                                {lineNr}
                            </Table.Cell>
                            {
                                Object.keys(line.values).map(key => {
                                    let element = line.definition.fields.find((d: any) => {
                                        return d.fieldName === key;
                                    });
                                    let info = element.fieldComment;
                                    let name = element.fieldName;
                                    return <Popup header={name} content={info} trigger={
                                        <Table.Cell selectable style={{ padding: '10px' }}>
                                            {line.values[key]}
                                        </Table.Cell>
                                    } />
                                })
                            }
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>
        </Segment>
    };

    if (data === undefined || data.length <= 1) {
        return <h3>LOADING</h3>
    } else {
        if (badFormat) {
            return <Label color='red'>The data is in bad format.</Label>
        } else {
            return renderTable();
        }
    }
}