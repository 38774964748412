import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button, Dropdown, DropdownItemProps} from "semantic-ui-react";
import AceEditor from 'react-ace';
import {IfBox} from "../../../style/if";
import {EncodingSelector} from "./EncodingSelector";
import {ConfigContext} from "../../context/ConfigContext";
import {ExportSimLisDocTable} from "../converter/ExportSim/output/ExportSimLisDocTable";

export interface LisDocViewerProps {
    output: string
}

export function LisDocViewer(props: React.PropsWithChildren<LisDocViewerProps>) {
    const context = useContext(ConfigContext);
    const [lisDoc, setLisDoc] = useState("");
    const [drawTable, setDrawTable] = useState(false);
    const [mode, setMode] = useState<"WinLager" | "WinSped">("WinSped");
    const [encoding, setEncoding] = useState("ISO-8859-1");

    useEffect(() => {
        if (props.output) {
            setLisDoc(props.output);
        } else {
            setLisDoc(context.lisDoc);
        }
    }, [props.output, context.lisDoc]);

    const options = () => {
        let enumValues: Array<DropdownItemProps> = [];
        enumValues.push({key: "WindSped", value: "WindSped", text: "WinSped"});
        enumValues.push({key: "WinLager", value: "WinLager", text: "WinLager"});

        return enumValues;
    }

    console.log("render lis doc viewer");
    let optionsList = options();
    return <>
        <div>
        <AceEditor
            theme="monokai"
            style={{flex: 2}}
            mode='groovy'
            value={lisDoc}
            placeholder='copy your LIS-Document here!'
            width="100%"
            onChange={(value) => {
                setLisDoc(value);
                setDrawTable(false);
                context.setLisDoc(value);
            }}
            readOnly={false}
        />
        </div>
        <div>
            <Dropdown
                selectOnBlur={false}
                selection
                options={optionsList}
                defaultValue={optionsList[0].value}
                onChange={(event, {value}) => {
                    setMode(value as "WinLager" | "WinSped");
                }}
            />
            <EncodingSelector
                encoding={encoding}
                onChange={(encoding) => {
                    setDrawTable(false);
                    setEncoding(encoding);
                }}
            />
            <Button type="button" onClick={() => setDrawTable(true)}>Draw Table</Button>
        </div>
        <div style={{flex: 2}}>
            <IfBox shouldShow={drawTable}>
                <ExportSimLisDocTable
                    output={lisDoc}
                    mode={mode}
                    encoding={encoding}
                />
            </IfBox>
        </div>

    </>
}