import * as React from "react";
import {Button, Grid, Modal} from "semantic-ui-react";
import {cleanPath, loadOpenInModalPaths, openInModalPaths} from "../newNavTree/NavTree";
import {ConfigContext} from "../../../../context/ConfigContext";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {CmNavTreeTextModalListRequest} from "../../../../../../generated";
import {notify} from 'react-notify-toast';

export interface NavTreeContextMenuProps {
    isOpen: boolean
    onClose: (modalToOpen: "other implementations" | null) => void
    x: number,
    y: number,
    values: any[],
    fieldName: any,
    changeTab: (index: number, fieldName: string) => void
}

export function NavTreeContextMenu(props: React.PropsWithChildren<NavTreeContextMenuProps>) {

    const context = React.useContext(ConfigContext)

    const handleClose = () => {
        props.onClose(null)
    }

    const calculateModalDirection = () => {
        if(props.y > window.innerHeight / 2) {
            return {bottom: window.innerHeight - props.y}
        } else {
            return {top: props.y}
        }
    }

    function canOpenInTextModal(path: string) {

        if(path == undefined) {
            return false
        }
        let cleanedPath = cleanPath(path)

        let splitPath = cleanedPath.split(".")
        let name = splitPath.pop()

        let parentPath = splitPath.join(".")

        let foundValue = props.values.find((v: { path: string; name: string; }) => {
            return v.path == parentPath && v.name == name
        })
        if (foundValue) {
            if(foundValue.type == "string") {
                return true
            }
        }
        return false
    }

    function customContextMenuOptions() {
        let options = [
            "other implementations"
        ]

        if(openInModalPaths.value.includes(props.fieldName)) {
            options.push("copy script path")
        }

        if(canOpenInTextModal(props.fieldName)) {
            if(openInModalPaths.value.includes(cleanPath(props.fieldName))){
                options.push("unmark to open in Text-Modal")
                options.push("open in Script-Converter-Playground")
            } else {
                options.push("mark to open in Text-Modal")
            }
        }

        return options
    }

    function currentJsonPathToFilePath() {
        return "\\" + props.fieldName.replaceAll(".", "\\").replace("$", context.companyId) + ".kt"
    }

    async function saveToOpenInModal(path: string) {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let request = {} as CmNavTreeTextModalListRequest
        request.newPath = cleanPath(path)
        let response = (await backend.internalApi.setCmNavTreeTextModal(request, auth))
        if(response.success) {
            await loadOpenInModalPaths()
            notify.show('added '+request.newPath+' to list to open in Script-Modal', 'success', 3000, '#28f751');
        } else {
            notify.show("ERROR: couldn't save it. See logs!", 'error', 5000, '#fc0303')
        }
    }

    async function removeFromOpenInModal(path: string) {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let request = {} as CmNavTreeTextModalListRequest
        request.newPath = cleanPath(path)
        let response = (await backend.internalApi.removeFromCmNavTreeTextModal(request, auth))
        if(response.success) {
            await loadOpenInModalPaths()
            notify.show('removed '+request.newPath+' from list to open in Script-Modal', 'success', 3000, '#28f751');
        } else {
            notify.show("ERROR: couldn't remove it. See logs!", 'error', 5000, '#fc0303')
        }
    }

    function onClickAction(option) {
        switch (option) {
            case "other implementations": {
                props.onClose("other implementations")
                break;
            }
            case "copy script path": {
                navigator.clipboard.writeText(currentJsonPathToFilePath()).then(_ => {})
                break;
            }
            case "mark to open in Text-Modal": {
                saveToOpenInModal(props.fieldName).then(_ => {})
                break;
            }
            case "unmark to open in Text-Modal": {
                removeFromOpenInModal(props.fieldName).then(_ => {})
                break;
            }
            case "open in Script-Converter-Playground": {
                console.log("OPENING IN SCRIPT CONVERTER PLAYGROUND")
                props.changeTab(3, props.fieldName.replace("CompanyConfiguration.", ""))
                break;
            }
            default: {
                break;
            }

        }
        handleClose()
    }

    let styles = {
        left: props.x,
        contextMenuModalDimmer: {
            backgroundColor: "transparent",
            '&:ui.dimmer': {
                position: 'static',
            }
        }
    }

    styles = {...styles, ...calculateModalDirection()}

    return (
        <Modal
            className={"contextMenuModal"}
            open={props.isOpen}
            onClose={handleClose}
            style={styles}
            size='mini'
            dimmer={{className: 'contextMenuModalDimmer'}}
        >
            <Modal.Content>
                <Grid padded="horizontally" stackable columns='equal' centered>
                    {customContextMenuOptions().map((option) => {
                        return <Grid.Row>
                            <Button
                                style={{width: "100%"}}
                                onClick={() => onClickAction(option) }
                            >
                                {option}
                            </Button>
                        </Grid.Row>
                    })}
                </Grid>
            </Modal.Content>
        </Modal>
    )
}


