import * as React from "react";
import {getSplitterOptions, initialState, initialState as initialRules, setRules} from "../../slices/ruleSlice";
import {useDispatch, useSelector} from "react-redux";
import "../RuleList.css"
import {initialState as initialTableState, setOpenFiles} from "../../slices/tableSlice";
import {setConversionLogs, setConversionOutput} from "../../slices/sourceCodeSlice";
import "./MainViewFloatingButtons.css"
import {Button, ButtonGroup, Popup} from "semantic-ui-react";
import {getRuleToDisplay, isEditSelectedRuleModeActive, setEditSelectedRuleMode} from "../../slices/rightMenuSlice";

interface MainViewFloatingButtonsProps {

}

export function MainViewFloatingButtons(props: React.PropsWithChildren<MainViewFloatingButtonsProps>) {
    const dispatch = useDispatch()

    const splitterOptions= useSelector(getSplitterOptions);

    function reset() {
        console.log("reset  splitterOptions ", splitterOptions)
        dispatch(setOpenFiles(initialTableState))
        dispatch(setRules(initialRules))
        dispatch(setConversionLogs(""))
        dispatch(setConversionOutput(""))
    }




    return <div id={"tableSplitterMainViewButtons"}>
        <ButtonGroup size={"small"}>

            <Popup content='Reset' trigger={
                <Button negative icon={'close'} onClick={reset}/>
            }/>
        </ButtonGroup>
    </div>
}
