import * as React from "react";
import {Button, Checkbox, Header, Input, InputProps, List, Modal, SemanticShorthandItem} from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import {authentication} from "../../authentication";

export interface ListCopyModalProps<T> {
    onClose: () => (void)
    initialItems?: T[]
    onSearch: (term: string, includeArchived: boolean) => Promise<T[]>
    open: boolean
    renderItem: (item: T) => JSX.Element
    icon?: any | SemanticShorthandItem<InputProps>
    placeholder?: string
    isSelectingItem?: boolean
}

export class ListCopyModal<T> extends React.Component<ListCopyModalProps<T>, any> {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            items: this.props.initialItems,
            isLoading: false,
            selectedItem: null,
            includeArchived: false,
            query: ""
        }
    }

    handleRef = (c) => {
        if (c) {
            c.focus()
        }
    }

    doSearch = async (value = this.state.query, includeArchived = this.state.includeArchived) => {

        this.setState({ isLoading: true })
        var result = await this.props.onSearch(value, includeArchived)
        this.setState({ isLoading: false, items: result, query: value  })

        console.log("Updating item list to ", result)
    }

    onCopyClicked(item) {
        navigator.clipboard.writeText(item._id)
        this.handleClose()
    }

    renderListItems() {
        if (this.state.items == null || this.state.items.length == 0) {
            return <List.Item key={0}>
                <List.Content>
                    Keine Ergebnisse
                </List.Content>
            </List.Item >
        }

        const items = this.state.items.length > 15 ? this.state.items.slice(0, 15) : this.state.items

        return items.map((x: T) => {

            return <List.Item key={uuid()}>
                <List.Content floated='right'>
                    <Button onClick={(evt) => this.onCopyClicked(x)} loading={this.props.isSelectingItem && x == this.state.selectedItem}>copy Id</Button>
                </List.Content>
                {this.props.renderItem(x)}
            </List.Item>

        })
    }

    handleClose = () => {
        this.setState({ items: null })
        this.props.onClose()
    }

    drawIncludeArchivedCheckbox() {
        return <>
            <Checkbox
                checked={this.state.includeArchived}
                onChange={() => {
                    this.doSearch(this.state.query, !this.state.includeArchived)
                    this.setState({includeArchived: !this.state.includeArchived})
                }}/> - include Archived
            <br/>
        </>
    }

    render() {

        const listItems = this.renderListItems()

        return <Modal dimmer="blurring" open={this.props.open}
            onClose={() => this.handleClose()} size="mini">
            <Modal.Content image>
                <Modal.Description>
                    <Header>{this.props.children}</Header>
                    {authentication.isSuperUser && this.drawIncludeArchivedCheckbox()}
                    <Input
                        loading={this.state.isLoading}
                        icon={this.props.icon || 'user'}
                        placeholder={this.props.placeholder || 'Suchen...'}
                        onChange={(evt) => this.doSearch(evt.target.value)}
                        ref={this.handleRef}
                    />
                    <List divided verticalAlign='middle'>
                        {listItems}
                    </List>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    }
}