import * as React from "react";
import {useRef} from "react";
import './Splitter.css'
import {Popup} from "semantic-ui-react";

interface SplitterHorizontalProps {
    name: string,
    top: JSX.Element,
    bottom: JSX.Element,
    onFlip?: () => void
}

export function SplitterHorizontal(props: React.PropsWithChildren<SplitterHorizontalProps>) {

    const offset = useRef({
        x: 0,
        y: 0,
    })

    const storedHeight = parseFloat(window.localStorage.getItem("splitter" + props.name + "_top_height") ?? "30")

    const [topHeight, setTopHeight] = React.useState(storedHeight)

    const mouseUp = (e: MouseEvent) => {
        window.dispatchEvent(new UIEvent('resize')) //This is just to get the Ace-Editor to resize
        document.removeEventListener("mousemove", mouseMove)
    }
    const mouseMove = (e: MouseEvent) => {
        const dy = e.clientY - offset.current.y

        let newHeight = (topHeight + (dy * 100 / window.innerHeight))

        if (newHeight < 10) newHeight = 10 // Minimum 10% to prevent it from disappearing
        if (newHeight > 90) newHeight = 90 // Maximum 90% to prevent it from covering whole window

        setTopHeight(newHeight)
        window.localStorage.setItem("splitter" + props.name + "_top_height", (newHeight).toString())
    }


    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()

        offset.current.x = e.clientX
        offset.current.y = e.clientY

        document.addEventListener("mousemove", mouseMove)
        document.addEventListener("mouseup", mouseUp)
    }

    return <div
        className={"splitterHorizontal"}
        style={{
            display: "flex",
            width: "100%",
            flex: 1,
            flexDirection: "column",
            minHeight: "100px",
        }}>
        <div id={"splitterHorizontalTop"} style={{
            width: "100%",
            height: topHeight + "%",
            display: "flex",
            flexDirection: "column",
            minHeight: "100px",
            overflow: "hidden"
        }}>{props.top}</div>

        {props.onFlip && <Popup
            content={"Double click to flip to vertical splitter"}
            trigger={
                <div
                    className="splitterResizerHorizontal"
                    id="dragMe"
                    onMouseDown={onMouseDown}
                    onDoubleClick={props.onFlip}
                >≡
                </div>
            }
            mouseEnterDelay={200}
        />
        }
        {!props.onFlip && <div
            className="splitterResizerHorizontal"
            id="dragMe"
            onMouseDown={onMouseDown}
            onDoubleClick={props.onFlip}
        >≡
        </div>}


        <div id={"splitterHorizontalBottom"} style={{
            flex: "1 1 0%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            minHeight: "100px",
            overflow: "hidden"
        }}>{props.bottom}</div>
    </div>
}