import * as React from "react";
import {useContext} from "react";
import {Icon} from "semantic-ui-react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {formatDate} from "../../../../../../format";
import {CustomerReportDynamicSection, healthyHighlighter} from "../CustomerReportDynamicSection";
import {AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest} from "../../../../../../generated";
import ServiceTypeEnum = AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest.ServiceTypeEnum;
import {customerReport} from "../../../../../style/page_with_sidebar";

export function CustomerReportStatusJsonReport({ reFetchReportData }: { reFetchReportData: () => Promise<void> }) {

    const context = useContext(ConfigContext)

    function drawSimpleBoolean(b: boolean) {
        if (b) {
            return <Icon name={'check'} color={'green'}/>
        } else {
            return <Icon name={'x'} color={'red'}/>
        }
    }

    const columns = [{
        id: 'name',
        Header: 'name',
        width: 300,
        accessor: (d: any) => decodeURIComponent(d.name).replace(new RegExp("\\+", 'g'), ' '),
    }, {
        id: 'icon',
        Header: 'healthy',
        width: 70,
        accessor: (d: any) =>
            <div>{healthyHighlighter(d.healthy)}</div>,
    }, {
        id: 'has file',
        Header: 'has file',
        width: 70,
        accessor: (d: any) => drawSimpleBoolean(d.statusJsonExists),
    }, {
        id: 'needs file',
        Header: 'needs file',
        width: 70,
        accessor: (d: any) => drawSimpleBoolean(d.checkMode == "ALWAYS_CHECK"),
    }, {
        id: 'lastRun',
        Header: 'last run',
        width: 160,
        accessor: (d: any) => <>{d.statusJsonExists && drawSimpleBoolean(!d.lastRunTooOld)}{formatDate(d.lastRun)} </>,
    }, {
        id: 'lastFileUpdate',
        Header: 'last file update',
        width: 160,
        accessor: (d: any) => <>{d.statusJsonExists && drawSimpleBoolean(!d.lastFileUpdateTooOld)} {formatDate(d.lastFileUpdate)} </>,
    }, {
        id: 'statusLine',
        Header: 'StatusLine',
        //width: 300,
        accessor: (d: any) => <>{d.statusJsonExists && drawSimpleBoolean(d.statusLine == "ok")} {d.statusLine} </>,
    },{
        id: 'responsibleManager',
        Header: 'Responsible manager',
        width: 100,
        accessor: (d: any) => d.responsibleManager,
    }
    ]

    return <>
        <CustomerReportDynamicSection
            columns={columns}
            data={customerReport.value?.statusJsonReport?.companies}
            currentService={ServiceTypeEnum.STATUSJSONREPORT}
            reFetchReportData={reFetchReportData}
        />
    </>


}