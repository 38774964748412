import * as React from "react";
import {authentication} from "../../../../../authentication";
import {Queue} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {ConfigContext} from "../../../context/ConfigContext";
import ReactTable from "react-table";
import {Button, Grid, Icon, Label, Popup} from "semantic-ui-react";
import AceEditor from 'react-ace';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import Notifications, {notify} from 'react-notify-toast';
import {IfBox} from "../../../../style/if";
import {ScriptModal} from "../../configChange/configEdit/modals/ScriptModal";
import {TestSuppressionScriptModal} from "../modal/TestSuppressionScriptModal";

export interface QueuesEditPageProps {

}


export interface QueuesEditPageState {
    isLoading: boolean,
    take: number
    page: number
    sortBy: 'ID' | 'QUEUE_ID' | 'QUEUE_TYPE' | 'EVENT_TYPE' | 'TIMESTAMP' | 'RATE_LIMITED' | 'SUPPRESSED' | 'PROCESSED' | 'PROCESSING_STATE'
    sortDirection: 'ASC' | 'DESC'
    count: number
    queues: Queue[]

    selectedQueue: Queue | null
    transportOrderId: string | null
    allSelected: boolean
    selected: string[]
    selectedIndex: number | null,
    workingCopy: string
    validJson: boolean

    openScriptModal: boolean
    suppressionScript: string
    openTestModal: boolean

    savable: boolean
}


export class QueuesEditPage extends React.Component<QueuesEditPageProps, QueuesEditPageState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading QueuesEditPage")

        this.state = {
            isLoading: false,
            take: 20,
            page: 1,
            sortBy: 'TIMESTAMP',
            sortDirection: 'DESC',
            count: 0,
            queues: [],
            selectedQueue: null,
            transportOrderId: null,
            allSelected: false,
            selected: [],
            selectedIndex: null,
            workingCopy: "",
            validJson: true,
            openScriptModal: false,
            suppressionScript: "",
            openTestModal: false,
            savable: false
        }
    }

    async componentDidMount() {
        await this.loadQueues()

        let workingCopy = this.context.queueWorkingCopies?.find(c => c.id === this.context.selectedQueueId)?.json
        this.setState({workingCopy: workingCopy, savable: workingCopy !== null})

        console.log("selectedQueueId: ", this.context.selectedQueueId)
        console.log("this.state.queues: ", this.state.queues)
        console.log("this.state.queues.find(q => q._id == this.context.selectedQueueId): ", this.state.queues.find(q => q._id == this.context.selectedQueueId))
        let index = this.state.queues.indexOf(this.state.queues.find(q => q._id == this.context.selectedQueueId))
        this.setState({
            selectedIndex: index,
            suppressionScript: this.getSuppressionScript()
        })
    }

    async componentDidUpdate() {
        let index = this.state.queues.indexOf(this.state.queues.find(q => q._id == this.context.selectedQueueId))
        if (this.state.selectedIndex !== index) {
            let wc = this.context.queueWorkingCopies?.find(c => c.id === this.context.selectedQueueId)?.json
            let selectedQueueObj = this.state.queues?.find(c => c._id === this.context.selectedQueueId)
            let selectedQueue = null
            if (selectedQueueObj) {
                selectedQueue = JSON.stringify(selectedQueueObj, null, 4)
            }
            if (!wc) {
                wc = selectedQueue
            }
            this.setState({
                selectedIndex: index,
                workingCopy: wc,
                selectedQueue: selectedQueue
            })
        }
        let newSupScript = this.getSuppressionScript()
        if (newSupScript != this.state.suppressionScript) {
            this.setState({suppressionScript: newSupScript})
        }
    }

    async loadQueues() {

        this.setState({isLoading: true})
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let resp = (await backend.queueApi.fetchQueueList(this.context.companyId, auth))
        await this.setState({
            queues: resp.queues,
            count: resp.queues.length,
            isLoading: false
        })
    }

    render() {
        return <>
            <div className={"flexRow"}>
                <div className={"flex1"} style={{margin: "5px"}}>
                    {this.renderTable()}
                </div>
                <div className={"flex1"}>
                    {this.drawEditor()}
                </div>
            </div>
            <Notifications/>
        </>
    }

    async onSave() {
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let newQueue = JSON.parse(this.state.workingCopy)
        let response = await backend.queueApi.updateQueue(newQueue._id, newQueue, auth)

        if (response) {

            notify.show('updated comment', 'success', 3000, '#28f751');
            await this.loadQueues()
        } else {
            notify.show("ERROR: unable to change comment.", 'error', 5000, '#fc0303')
        }

    }

    replaceSuppressionScript(script) {
        let jp = require('jsonpath');
        let jsonPath = '$.suppressionRules.suppressionScript'
        let workingCopy = JSON.parse(this.state.workingCopy)
        jp.value(workingCopy, jsonPath, script)
        let newWorkingCopyStr = JSON.stringify(workingCopy, null, 4)

        this.handleChange(newWorkingCopyStr)
        this.setState({openScriptModal: false})

    }

    getSuppressionScript() {
        if (this.state.workingCopy) {
            let jp = require('jsonpath');
            let jsonPath = '$.suppressionRules.suppressionScript'
            let workingCopy = JSON.parse(this.state.workingCopy)
            return jp.query(workingCopy, jsonPath)[0]
        }
        return null
    }

    handleChange(newWorkingCopy: string) {
        this.setState({
            workingCopy: newWorkingCopy
        })
        try {
            let parsed = JSON.parse(newWorkingCopy)
            this.setState({
                validJson: true,
                suppressionScript: this.getSuppressionScript()
            })
            if (newWorkingCopy === JSON.stringify(this.state.selectedQueue, null, 4)) {
                console.log("REMOVE WORKING COpY")
                // remove from array
                let newArray = []
                this.context.queueWorkingCopies.map(c => {
                    if (c.id != parsed._id) {
                        newArray.push(c)
                    }
                })
                this.context.setQueueWorkingCopies(newArray)
                this.setState({savable: false})
            } else {
                // create or update single element
                this.context.updateQueueWorkingCopy({id: parsed._id, json: newWorkingCopy})
                this.setState({savable: true})
            }
            console.log("SUPPRESSION SCRIPT: ", this.state.suppressionScript)
        } catch (e) {
            this.setState({validJson: false})
        }


    }

    drawEditor() {
        return <>
            <AceEditor
                theme="monokai"
                style={{flex: 1}}
                mode='groovy'
                value={this.state.workingCopy}
                placeholder='Message'
                width="100%"
                readOnly={false}
                onChange={(value) => this.handleChange(value)}

            />
            <div>
                <IfBox shouldShow={!this.state.validJson}>
                    <Label size={'large'} color='red'>INVALID JSON</Label>
                </IfBox>
                <Button disabled={this.state.workingCopy == JSON.stringify(this.state.workingCopy) || !this.state.validJson || !this.state.savable}
                        onClick={() => this.onSave()}>Save</Button>
                <Button disabled={this.state.workingCopy == JSON.stringify(this.state.workingCopy) || !this.state.validJson}
                        onClick={() => this.setState({openScriptModal: true})}>Edit SuppressionScript</Button>
                <Button disabled={this.state.workingCopy == JSON.stringify(this.state.workingCopy) || !this.state.validJson}
                        onClick={() => this.setState({openTestModal: true})}>Test SuppressionScript</Button>

                <IfBox shouldShow={this.state.openScriptModal}>
                    <ScriptModal
                        isOpen={this.state.openScriptModal}
                        onClose={() => this.setState({openScriptModal: false})}
                        script={this.state.suppressionScript}
                        dispatcher={(script) => this.replaceSuppressionScript(script)}
                        saveKeyListener={() => {
                        }}
                        path={"suppressionRules.suppressionScript"}
                        readOnly={false}
                        fieldDescriptionEnabled={false}
                        defaultDescription={""}
                    />
                </IfBox>
                <IfBox shouldShow={this.state.openTestModal}>
                    <TestSuppressionScriptModal
                        isOpen={this.state.openTestModal}
                        onClose={() => this.setState({openTestModal: false})}
                        queue={this.getWorkingCopyAsObject()}/>
                </IfBox>
            </div>
        </>
    }

    getWorkingCopyAsObject() {
        try {
            return JSON.parse(this.state.workingCopy)
        } catch (e) {
            return {}
        }
    }

    changed(d: any) {
        if (this.context.queueWorkingCopies.find(c => c.id === d._id)) {
            return <Popup trigger={<Icon name={"warning sign"} color={"yellow"} size='large'/>}>
                There are unsaved changes on this Queue!
            </Popup>
        }
        return <></>
    }

    renderTable() {

        const columns = [
            {
                id: 'Changed',
                Header: '',
                width: 50,
                accessor: (d: any) => this.changed(d),
                sortField: 'CHANGE'
            },
            {
                id: 'name',
                Header: 'name',
                width: 250,
                accessor: (d: any) => d.name,
                sortField: 'NAME'
            }, {
                id: '_id',
                Header: 'ID',
                width: 200,
                accessor: (d: any) => d._id,
                sortField: 'ID'
            }, {
                id: 'type',
                Header: 'type',
                width: 200,
                accessor: (d: any) => d.type,
                sortField: 'TYPE'
            }, {
                id: 'enabled',
                Header: 'enabled',
                width: 100,
                accessor: (d: any) => {
                    if (d.enabled != false) {
                        return <Icon name='check' color='green' size='large'/>
                    } else {
                        return <Icon name='x' color='red' size='large'/>
                    }
                },
                sortField: 'ENABLED'
            }, {
                id: 'mirroringEnabled',
                Header: 'mirroringEnabled',
                width: 150,
                accessor: (d: any) => {
                    if (d.mirroringEnabled) {
                        return <Icon name='check' color='green' size='large'/>
                    } else {
                        return <Icon name='x' color='red' size='large'/>
                    }
                },
                sortField: 'MIRRORING_ENABLED'
            }
        ]

        if (this.state.queues == null) {
            return <p>Loading</p>
        }

        return <ReactTable
            data={this.state.queues}
            pages={Math.ceil(this.state.count / this.state.take)}
            columns={columns}
            sorted={[
                {
                    id: 'NAME',
                    desc: false
                }
            ]}
            defaultPageSize={this.state.take}
            className="-striped -highlight"
            loading={this.state.isLoading}
            getTdProps={(state, rowInfo) => {
                return {
                    onClick: () => {
                        // check if there is a current workingCopy in the context
                        let savable = true
                        let wc = this.context.queueWorkingCopies?.find(c => c.id === rowInfo.row._original._id)?.json
                        if (!wc) {
                            wc = JSON.stringify(rowInfo.row._original, null, 4)
                            savable = false
                        }
                        this.setState({
                            selectedIndex: rowInfo.index,
                            selectedQueue: rowInfo.row._original,
                            workingCopy: wc,
                            savable: savable
                        })
                        this.context.setSelectedQueueId(rowInfo.row._original._id)

                    },
                    style: {
                        background: rowInfo?.index === this.state.selectedIndex ? '#00afec' : 'white',
                        color: rowInfo?.index === this.state.selectedIndex ? 'white' : 'black'
                    }
                };
            }}
            style={{cursor: "pointer"}}
        />
    }
}