import {TableDataCell, TableDataLine, TableState} from "../slices/tableSlice";

type Matrix2dPage = string[][]

export type Matrix2d = {
    pages: Matrix2dPage[],
    pageTitles: string[],
    currentPage: number,
    size: [number,number],
    labels: Matrix2dCellLabel[] | []
}

export type Matrix2dCellLabel = {
    page: number,
    lineIdx: number,
    cellIdx: number,
    labels: string,
    valueExtractionRegex: string
}

export function tableStateToMatrix2d(input: TableState): Matrix2d{
    const pageTitles = input.pages.map(x=>x.name)
    const pages = input.pages.map(p=>p.dataLines.map(l=>l.cells.map(c=>c.text)))

    return {
        pages: pages,
        pageTitles:pageTitles,
        currentPage:0,
        size:[0,0],
        labels: []
    }
}


export function matrix2dToTable(input:Matrix2d, fileName:string):TableState {
    return {
        inputFileName:fileName,
        pages: input.pages.map(function (pageData,pageIndex) {
            return {
                name: input.pageTitles[pageIndex],
                dataLines: pageData.map(function(lineData, lineIndex) {
                    return {
                        cells: lineData.map(function(cellText, cellIndex) {
                            let cell: TableDataCell = { text: cellText };

                            const matchingLabels = (input.labels as Matrix2dCellLabel[]).filter(label =>
                                label.page === pageIndex &&
                                label.lineIdx === lineIndex &&
                                label.cellIdx === cellIndex
                            );
                            // If there are matching labels, apply them to the cell
                            if (matchingLabels.length > 0) {
                                cell.selectionLabels = matchingLabels.map(label => ({
                                    label: label.labels,
                                    valueExtractionRegex: label.valueExtractionRegex
                                }));
                            }

                            return cell;
                        }) as TableDataCell[]
                    } as TableDataLine;
                }),
                selections: []
            }
        })
    }
}