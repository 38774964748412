import * as React from "react";
import { useState, useEffect, useContext } from 'react';
import ReactTable from "react-table";
import {Label, Segment} from "semantic-ui-react";
import {authentication} from "../../../../../../authentication";
import {formatDate} from "../../../../../../format";
import {backend} from "../../../../../../xconvert-backend";
import {ConfigContext} from "../../../../context/ConfigContext";
import {HistoryModal} from "../modals/HistoryModal";
import {RollbackConfigModal} from "../modals/RollbackConfigModal";
import {workingConfig} from "../../ConfigSignal";
import {cleanPath, focusesOnField} from "../newNavTree/NavTree";

export interface FieldChangesViewProps {
}

export function FieldChangesView(props: React.PropsWithChildren<FieldChangesViewProps>) {
    const context = useContext(ConfigContext);
    const [isLoading, setIsLoading] = useState(false);
    const [map, setMap] = useState([]);
    const [fieldChanges, setFieldChanges] = useState([]);
    const [showChanges, setShowChanges] = useState(false);
    const [history, setHistory] = useState(null);
    const [showRollBackView, setShowRollBackView] = useState(false);
    const [followingConfig, setFollowingConfig] = useState(null);
    const [take, setTake] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState<'TIMESTAMP' | 'CHANGED_BY' | 'COMMENT'>("TIMESTAMP");
    const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>("DESC");
    const [count, setCount] = useState(-1);

    useEffect(() => {
        loadFieldChanges().then(r => {});
        findMatchingChanges().then(r => {});
    }, []);

    useEffect(() => {
        fetchData().then(r => {});
    }, [focusesOnField.value, take, page, sortBy, sortDirection, isLoading]);
    
    
    async function loadFieldChanges() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = await backend.internalApi.fetchCompanyConfigurationChanges(context.companyId, null,  auth)
        setMap(response.changes)
    }

    async function findMatchingChanges() {
        let cleanedFieldPath = "$." + focusesOnField.value.path.split(".").pop()

        let fieldChanges = map.filter(entry => {
            return entry.path == cleanedFieldPath
        }).sort((a, b) => {
            return new Date(a.timestamp).getTime() -
                new Date(b.timestamp).getTime()
        }).reverse();

        setFieldChanges(fieldChanges)
    }


    function render() {
        if (focusesOnField.value == null || focusesOnField.value.path == null || focusesOnField.value.path == "") {
            return <p>Please click on one of the lables in the navTreeView to get the last change.</p>

        }
        if (fieldChanges == null || fieldChanges.length == 0) {
            return <>
                <Label color="yellow">No change found for : </Label>
                <h4>{focusesOnField.value.path}</h4>
            </>
        }

        return <Segment>
            <h3>Changes For :</h3>
            <h4>{focusesOnField.value.path}</h4>
            {drawTable()}

            <HistoryModal
                isOpen={showChanges}
                onClose={(openRollback: boolean) => {
                    if (openRollback) {
                        setShowChanges(false)
                        setShowRollBackView(true)
                    } else {
                        setShowChanges(false)
                    }
                }}
                history={history}
                followingConfig={followingConfig}
            />
            <RollbackConfigModal
                isOpen={showRollBackView}
                onClose={() => setShowRollBackView(false)}
                currentConfig={workingConfig.value}
                history={history}
            />

        </Segment>
    }

    function drawTable() {
        const
            columns = [{
                id: 'TIMESTAMP',
                Header: 'timestamp',
                width: 150,
                accessor: (d: any) => d.timestamp,
                Cell: (d: any) => {
                    return <span>{formatDate(d.value)}</span>
                },
                sortMethod: (a, b) => {
                    const a1 = new Date(a).getTime();
                    const b1 = new Date(b).getTime();
                    if (a1 < b1) {
                        return 1;
                    } else if (a1 > b1) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
                sortField: 'TIMESTAMP'

            }, {
                id: 'CHANGED_BY',
                Header: 'changedBy',
                width: 200,
                accessor: (d: any) => d.userName,
                sortField: 'CHANGED_BY'
            }, {
                id: 'COMMENT',
                Header: 'comment',
                accessor: (d: any) => d.comment,
                sortField: 'COMMENT'
            }]

        if (fieldChanges == null) {
            return <p>No entries found</p>
        } else {
            const data: any[] | null = fillDummyEntries(fieldChanges, count)

            return <ReactTable
                collapseOnDataChange
                data={data}
                pages={Math.ceil(count / take)}
                columns={columns}
                sorted={[
                    {
                        id: 'TIMESTAMP',
                        desc: false
                    }
                ]}
                onSortedChange={(newSorted, column, shiftKey) => {
                    changeSort(column.sortField).then(r => {})
                }}

                defaultPageSize={take}
                className="-striped -highlight"
                loading={isLoading}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        onClick: () => {
                            displayChanges(rowInfo.row._original.historyId).then(r => {})
                        }
                    };
                }}
                style={{cursor: "pointer"}}
                onPageChange={(pageIndex) => changePage(pageIndex)}
                onPageSizeChange={(pageSize, pageIndex) => changePageSize(pageSize)}

            />

        }
    }

    async function fetchData() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = await backend.internalApi.fetchCompanyConfigurationChangesForField(
            context.companyId,
            take,
            cleanPath(focusesOnField.value.path),
            (page - 1) * take,
            sortBy,
            sortDirection,
            auth
        )

        setFieldChanges(response.changes)
        setCount(response.count)
        setTake(take)
        setPage(page)
        setIsLoading(false)
    }

    async function displayChanges(historyId: string) {

        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = await backend.internalApi.fetchFollowingCompanyConfigurationHistory(context.companyId, historyId, auth)
        let followingConfig = response.followingConfig
        let history = response.historyEntry
        setShowChanges(true)
        setHistory(history)
        setFollowingConfig(followingConfig)
    }

    async function changeSort(sortBy: 'TIMESTAMP' | 'CHANGED_BY' | 'COMMENT' | 'NONE') {
        console.log("changing sort")
        setIsLoading(true)
        if (sortBy == 'NONE') {
            return
        }

        if (sortBy == sortBy) {
            //change direction
            let direction = sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            setSortDirection(direction)
        } else {
            //change sortBy and reset direction
            setSortBy(sortBy)
            setSortDirection("ASC")
        }

        setIsLoading(false)
    }

    function changePage(pageIndex: number) {
        setIsLoading(true)
        setPage(pageIndex + 1)
    }

    function changePageSize(newSize: number) {
        setIsLoading(true)
        setTake(newSize)
    }

    function fillDummyEntries(data: any[], totalAmount: number) {
        let dummy = {}
        let currentEntries: any[] = []
        console.log("found " + data.length + " entries of a total of " + totalAmount)

        let indexOffset = (page - 1) * take
        console.log("offset: " + indexOffset)

        let firstActualFileIndex = indexOffset
        console.log("firstActualFileIndex: " + firstActualFileIndex)

        let lastActualFileIndex = indexOffset + take - 1
        console.log("lastActualFileIndex: " + lastActualFileIndex)

        for (let i = 0; i < totalAmount; i++) {

            if (i < firstActualFileIndex) {
                currentEntries.push(dummy)
            } else if (i >= firstActualFileIndex) {
                if (i <= lastActualFileIndex) {
                    console.log("take index: " + (i - indexOffset))

                    currentEntries.push(data[i - indexOffset])
                    console.log("took: " + data[i - indexOffset])

                } else {
                    currentEntries.push(dummy)
                }

            }
        }

        while (currentEntries.length < totalAmount) {

            console.log("adding a dummy")
            currentEntries.push(dummy)
        }

        console.log("new total amount: " + currentEntries.length)

        return currentEntries
    }
    
    return render();
}