import * as React from "react";
import {useEffect} from "react";
import {CustomKotlinPlayground} from "./CustomKotlinPlayground";

export interface KotlinScriptIdeProps {
    scriptWithPath: ScriptWithPath
}

export interface ScriptWithPath {
    script: string | null
    path: string
}

export function KotlinScriptIde(props: React.PropsWithChildren<KotlinScriptIdeProps>) {

    const [imports, setImports] = React.useState([])
    const [preBoilerplate, setPreBoilerplate] = React.useState("")
    const [postBoilerplate, setPostBoilerplate] = React.useState("")
    const [scriptWithPath, setScriptWithPath] = React.useState(props.scriptWithPath)
    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
        setLoading(true)
        setImports([])
        prepareBoilerplateCode()
        setLoading(false)
    }, [props.scriptWithPath.path])

    function prepareBoilerplateCode() {
        console.log("[prepareBoilerplateCode] - scriptWithPath: ", props.scriptWithPath)
        let newImports = []
        let scriptByLine = props.scriptWithPath.script?.split("\n") ?? []
        console.log("[prepareBoilerplateCode] - scriptByLine: ", scriptByLine)
        let scriptByLineWithoutImports = []
        scriptByLine.forEach((line) => {
            if (line.startsWith("import")) {
                newImports.push(line)
            } else {
                scriptByLineWithoutImports.push(line)
            }
        })
        let scriptWithoutImports = props.scriptWithPath.script?.replace(/import.*\n/g, "") ?? null

        setImports(newImports)

        setPreBoilerplate(newImports.join("\n") + "\n" +
            "/*SCRIPT_IMPORTS_END*/\n" +
            "import com.logenios.xconvert.base.LogeniosLogger\n" +
            "import com.logenios.xconvert.base.logDebug\n" +
            "import com.logenios.xconvert.base.logInfo\n" +
            "import com.logenios.xconvert.base.logWarn\n" +
            "import com.logenios.xconvert.base.logError\n" +
            "fun main(args: Array<String>) {\n" +
            "println(\"This is just for a quick compiling test. Use the other CM features tu actually run/test the script.\")\n" +
            "}\n" +
            "fun testFunction(logger: Logger, orders: List<TransportOrder>, order: TransportOrder) { \n" +
            "/*PRE_BOILDERPLATE_END*/\n"
        )

        setPostBoilerplate("\n/*POST_BOILERPLATE_START*/" +
            "\n}" +
            "\n/*POST_BOILERPLATE_END*/"
        )

        /*setPreBoilerplate("/!*PRE_BOILDERPLATE_START*!/\n" +
            "import com.logenios.api.model.TransportOrder;\n " +
            "import com.github.kittinunf.fuel.Fuel\n" +
            "import com.logenios.xconvert.base.toJsonString\n" +
            "import kotlinx.serialization.*\n" +
            "import kotlinx.serialization.json.*\n"+
            "import com.github.kittinunf.fuel.core.ResponseResultOf\n" +
            "import com.github.kittinunf.result.Result\n" +
            "/!*SCRIPT_IMPORTS_START*!/\n" +
            //newImports.join("\n") + "\n" +
            "/!*SCRIPT_IMPORTS_END*!/\n" +
            "fun main(args: Array<String>) {\n" +
            "println(\"Starting Script Test\")\n" +
            "val outcome = testFunction()\n" +
            "println(\"Script Test ended. Outcome: \" + outcome.toString())\n" +
            "}\n" +
            "fun testFunction(): Any? { \n" +
            "val creationUserId=\"\$\"+\"creationUserId\"\n" +
            "/!*PRE_BOILDERPLATE_END*!/\n" +
            "val script = \"\"\""

        )

        setPostBoilerplate("\"\"\"\n" +
            "@Serializable\n" +
            "data class RunKotlinScriptWithContextRequest(\n" +
            "    val script: String? = null,\n" +
            "    val scriptName: String? = null,\n" +
            "    val context: Map<String, Any?>? = null\n" +
            ")\n" +
            "val req = RunKotlinScriptWithContextRequest(\n" +
            "      script = \"testScript\",\n" +
            "          scriptName = script.trimIndent(),\n" +
            "          //context = mapOf<String,Any>(\"order\" to TransportOrder().externalTransportOrderId(\"213\"))\n" +
            "  )\n" +
            "      val url = \"http://localhost:8090/converter/runKotlinScriptWithContext\"\n" +
            "val mockedJson = \"\"\"\n" +
            "{\"script\": $script,\"scriptName\": \"scriptName\",\"context\": {\"order\": {\"id\":\"'ORDER_ID'\"}}}\"\"\"\n" +
            "val healthUrl = \"https:\/\/filesystem-worker.logenios.com\/health\"\n" +
            "    val (request, response, result) = Fuel.get(healthUrl).response()\n" +
            "println(\"result = \" + result)\n" +
            "return response\n" +
            "\n/!*POST_BOILERPLATE_START*!/" +
            "\n}" +
            "\n/!*POST_BOILERPLATE_END*!/"
        ) */

        /*setPreBoilerplate("import com.logenios.api.model.TransportOrder;\n " +


            "fun main(args: Array<String>) {\n" +
            "}\n" +

            "fun scriptFunction(): Any? { \n"
        )

        setPostBoilerplate( "\n}\n")*/

        setScriptWithPath({script: scriptWithoutImports, path: props.scriptWithPath.path})


    }

    if(loading) return <div>Loading...</div>
    return <><CustomKotlinPlayground
        key={props.scriptWithPath.path}
        preBoilerplate={preBoilerplate}
        postBoilerplate={postBoilerplate}
        scriptWithPath={scriptWithPath}
        imports={imports?.join("\n") ?? ""}
        directSave={true}
    />
    </>

}
