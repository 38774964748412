import * as React from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import {parseDiff} from 'react-diff-view';
import {diffLines, formatLines} from 'unidiff';
import {DiffWrapper} from "../../../../configChange/configEdit/diff/DiffWrapper";
import {ConfigContext} from "../../../../../context/ConfigContext";
import {CustomDiff} from "../../../../configChange/configEdit/diff/CustomDiff";


export interface ShowUnsavedChangesModalProps {
    isOpen: boolean
    onClose: () => void
    dispatch: (any) => void
}

export interface ShowUnsavedChangesModalState {
    isSubmitting: boolean
    comment: string
    response: any | null
    hunks: any | null
    type: any | null
    activeIndex: number
}

export class ShowUnsavedChangesModal extends React.Component<ShowUnsavedChangesModalProps, ShowUnsavedChangesModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            comment: "",
            response: null,
            hunks: null,
            type: null,
            activeIndex: -1,
        }
    }

    handleClose = (save) => {
        this.props.dispatch(save);
        this.props.onClose()
    }

    async save() {

        this.handleClose(true)

    }

    handleDiscard() {
        this.context.setOldContract(null)
        this.context.setSelectedContract(null)
        this.handleClose(false)
    }

    componentDidMount() {
        if (this.state.hunks == null) {
            let oldConfig = this.context.oldContract ? this.context.oldContract : ''
            let newConfig = this.context.selectedContract

            const diffText = formatLines(diffLines(oldConfig, newConfig), {context: 3});
            const [diff] = parseDiff(diffText, {nearbySequences: 'zip'});

            this.setState({hunks: diff.hunks});
        }
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Unsaved changes in Contract'/>
            <Modal.Content>
                <span>Before you can add a new contract, please decide what should happen to your unsaved changes on the currently selected.</span>
                <CustomDiff
                    oldJson={this.cleanBackslashR(this.context.oldContract)}
                    newJson={this.cleanBackslashR(this.context.selectedContract)}
                />

                <Button id='SaveContractModalSaveButton' primary loading={this.state.isSubmitting} onClick={() => {
                    this.save()
                }}>Save</Button>
                <Button id='SaveContractModalCancelButton' onClick={() => this.handleDiscard()}>Discard</Button>
                <Button id='SaveContractModalCancelButton' onClick={() => this.handleClose(false)}>Cancel</Button>

            </Modal.Content>
        </Modal>

    }

    cleanBackslashR(str: string): string {
        if (str) {
            return str.split("\\r").join("")
        }
        return ''
    }

}

