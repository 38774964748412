import * as React from "react";
import {BrowserRouter as Router, Link, Redirect, Route} from "react-router-dom";
import {Icon, Segment} from "semantic-ui-react";
import {authentication} from "./authentication";
import {LoginPage, LogoutPage} from "./components/account/Login";
import Box from "./components/style/box";
import {LogeniosContext} from "./LogeniosContext";
import {ScriptExecution} from "./components/main/pages/scriptExecution/ScriptExecution";
import {ConfigProvider} from "./components/main/context/ConfigContext";
import {FtpExplorerPage} from "./components/main/pages/ftp-explorer/FtpExplorerPage";
import {DocViewer} from "./components/main/pages/docViewer/DocViewer";
import {TransportOrderView} from "./components/main/pages/transportOrders/TransportOrderView";
import {SchedulerTasksPage} from "./components/main/pages/SchedulerTasks/SchedulerTasksPage";
import {QueuesPage} from "./components/main/pages/queues/QueuesPage";
import {ConfigPage} from "./components/main/pages/configChange/ConfigPage";
import {ConverterPage} from "./components/main/pages/converter/ConverterPage";
import {CustomerAccountingPage} from "./components/main/pages/contracts/CustomerAccountingPage";
import {DBqueryPage} from "./components/main/pages/DBquery/DBqueryPage";
import {SystemStatusPage} from "./components/main/pages/systemStatus/SystemStatusPage";
import {HealthPage} from "./components/util/HealthPage";
import {TableSplitterPage} from "./components/main/pages/tableSplitter/TableSplitterPage";
import {Provider} from "react-redux";
import {store} from "./components/util/table-splitter-ui/src/store";
import 'react-table/react-table.css';
import {GeostatusPage} from "./components/main/pages/geostatus/GeostatusPage";

let forbiddenShame = <Box showLogo>
    <Segment textAlign='center'>
        Die Logenios Roboter können diese URL für Sie leider nicht aufrufen. Keine Berechtigung dafür.<Icon name='frown'
                                                                                                            color='teal'
                                                                                                            size='huge'></Icon>
    </Segment>
    <Segment textAlign='center'>
        <Link to="/config">Logenios</Link>
    </Segment>
</Box>

// READ THE WARNING
const PrivateRoute = ({component: Component, perms, superUserOnly, ...rest}) => (

    <Route
        {...rest}
        render={props => {

            if (authentication.isAuthenticated) {
                if (superUserOnly) {
                    if (authentication.isSuperUser) {
                        return <Component {...props} />
                    }
                    return forbiddenShame
                } else {
                    if (Array.isArray(perms) && perms.length > 0 && !authentication.hasAnyPermission(...perms)) {
                        return forbiddenShame
                    }
                    return <Component {...props} />
                }
            } else {
                return <Redirect
                    to={{
                        pathname: "/account/login",
                        state: {from: props.location}
                    }}
                />
            }
        }
        }
    />
);

// WARNING: WHEN YOU ADD A NEW PRIVATEROUTE YOU HAVE TO ADD PERMISSIONS HERE, WHO IS ALLOWED TO OPEN THE PAGE
// OTHERWISE THE PAGE CAN BE ACCESSED BY LINKHACKING
const AppRouter = () => (
    <Provider store={store}>
    <ConfigProvider>
        <Router>
            <LogeniosContext>
                <div>
                    {window.location.pathname.includes('index.html') && <Redirect to="/"/>}

                    <Route exact path={"/"} render={() => (
                        authentication.isAuthenticated ? (
                            <Redirect to={"/"+authentication.company._id+"/config"}/>
                        ) : (
                            <Redirect to="/account/login"/>
                        ))}/>
                    <Route path="/account/login" component={LoginPage}/>
                    <Route path="/account/logout" component={LogoutPage}/>
                    <Route path="/health" component={HealthPage}/>

                    <PrivateRoute path="/:companyId/config" component={ConfigPage} perms={[]} superUserOnly={false}/>
                    <PrivateRoute path="/:companyId/convPage" component={ConverterPage} perms={[]} superUserOnly={false}/>
                    <PrivateRoute path="/:companyId/systemStatus" component={SystemStatusPage} perms={[]} superUserOnly={true}/>
                    <PrivateRoute path="/:companyId/scriptExecution" component={ScriptExecution} perms={[]} superUserOnly={false}/>
                    <PrivateRoute path="/:companyId/docViewer" component={DocViewer} perms={[]} superUserOnly={false}/>
                    <PrivateRoute path="/:companyId/explorer" component={FtpExplorerPage} perms={[]} superUserOnly={false}/>
                    <PrivateRoute path="/:companyId/queues" component={QueuesPage} perms={[]} superUserOnly={false}/>
                    <PrivateRoute path="/:companyId/contracts" component={CustomerAccountingPage} perms={[]} superUserOnly={true}/>
                    <PrivateRoute path="/:companyId/transportOrderView" component={TransportOrderView} perms={[]} superUserOnly={false}/>
                    <PrivateRoute path="/:companyId/schedulerTaskPage" component={SchedulerTasksPage} perms={[]} superUserOnly={true}/>
                    <PrivateRoute path="/:companyId/DBqueryPage" component={DBqueryPage} perms={[]} superUserOnly={true}/>
                    <PrivateRoute path="/:companyId/TableSplitter" component={TableSplitterPage} perms={[]} superUserOnly={false}/>
                    <PrivateRoute path="/:companyId/Geostatus" component={GeostatusPage} perms={[]} superUserOnly={true}/>

                </div>
            </LogeniosContext>
        </Router>

    </ConfigProvider>
    </Provider>
);
// READ THE WARNING ABOVE

export default AppRouter;