import * as React from "react";
import {Button, Checkbox, Grid, Header, Icon, Modal, TextArea} from "semantic-ui-react";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {CompanyConfiguration, CompanyHistory} from "../../../../../../generated";
import {DiffWrapper} from "../diff/DiffWrapper";
import Notifications, {notify} from 'react-notify-toast';
import {IfBox} from "../../../../../style/if";
import {ConfigContext} from "../../../../context/ConfigContext";
import ace from "ace-builds";
import extendYamlMode from "../../../../../util/YamlValidator/extendYamlMode";

const YAML = require('yaml');

extendYamlMode(ace)

export interface HistoryModalProps {
    isOpen: boolean
    onClose: (openRollback: boolean, commentDidChange: boolean) => void
    followingConfig: CompanyConfiguration
    history: CompanyHistory
}

export class HistoryModal extends React.Component<HistoryModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            showRollBackView: false,
            changeComment: false,
            comment: "",
            newComment: "",
            commentDidChange: false,
            yamlView: false
        }
    }

    onOpen() {
        if (this.props.history?.changeComment != null) {
            this.setState({
                comment: this.props.history.changeComment,
                newComment: this.props.history.changeComment
            })
        }
    }

    handleOpenRollback = (openRollback: boolean) => {

        this.props.onClose(openRollback, this.state.commentDidChange)

    }

    handleClose = () => {
        this.props.onClose(false, this.state.commentDidChange)
    }

    changeComment() {
        console.log("changeComment")
        this.setState({ changeComment: true })
    }

    async saveCommentChange() {

        //send updateComment request
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let request = { newComment: this.state.newComment }
        let response = await backend.internalApi.updateCompanyConfigHistoryComment(this.context.companyId, this.props.history._id, request, auth)
        if (response.success) {
            notify.show('updated comment', 'success', 3000, '#28f751');
        } else {
            notify.show("ERROR: unable to change comment.", 'error', 5000, '#fc0303')

        }
        this.setState({ changeComment: false, comment: this.state.newComment, commentDidChange: true })

    }

    discardCommentChange() {
        this.setState({ comment: this.props.history.changeComment, changeComment: false })

    }

    toYaml(obj: any) {
        if (obj) {
            const doc = new YAML.Document();
            doc.contents = obj;
            return doc.toString();
        } else {
            return '';
        }
    }

    drawDiff() {
        let a = JSON.stringify(this.props.history.config, null, 4)
        let b = JSON.stringify(this.props.followingConfig, null, 4)
        let format = "json"
        if(this.state.yamlView) {
            a = this.toYaml(this.props.history.config)
            b = this.toYaml(this.props.followingConfig)
            format = "yaml"
        }

        console.log("set format to ", format)
        return <DiffWrapper
            id={"diffWrapper"}
            oldText={a}
            newText={b}
            format={format}
        />
    }

    render() {
        if (this.props.history != null) {
            return <Modal
                open={this.props.isOpen}
                onClose={this.handleClose}
                onMount={(event, data) => this.onOpen()}
                dimmer="blurring"
                size='fullscreen'>
                <Header icon='browser' content='Config History (change view)' />
                <Modal.Content>
                    <p>This view shows what did actually change with this history entry. It compares this history-state with the previous history-state.</p>
                    <Grid padded="horizontally" stackable columns='equal' centered>
                        <Grid.Row>
                            <Grid.Column>Id</Grid.Column>
                            <Grid.Column>{this.props.history._id}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>Changed By</Grid.Column>
                            <Grid.Column>{this.props.history.changedBy}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>Timestamp</Grid.Column>
                            <Grid.Column>{this.props.history.timestamp}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>changeMethod</Grid.Column>
                            <Grid.Column>{this.props.history.changeMethod}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>comment</Grid.Column>
                            <Grid.Column>
                                <IfBox shouldShow={!this.state.changeComment}>
                                    {this.state.comment}
                                    <Button icon onClick={() => this.changeComment()}><Icon name='pen square' /></Button>
                                </IfBox>
                                <IfBox shouldShow={this.state.changeComment}>
                                    <TextArea id='CommentTextArea' onChange={(event) => this.setState({ newComment: event.currentTarget.value })} cols={200} value={this.state.newComment} />
                                    <Button icon onClick={() => this.saveCommentChange()}><Icon name='save' /></Button>
                                    <Button icon onClick={() => this.discardCommentChange()}><Icon name='trash' /></Button>

                                </IfBox>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column><h2>BEFORE</h2></Grid.Column>
                            <Grid.Column><h2>{"--> compared to -->"}</h2></Grid.Column>
                            <Grid.Column><h2>AFTER</h2></Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <p>JSON - <Checkbox
                        type='checkbox'
                        toggle
                        checked={this.state.yamlView}
                        onChange={() => {
                            this.setState({yamlView: !this.state.yamlView})
                        }}
                    /> - YAML </p>
                    {this.drawDiff()}

                    <Button primary onClick={() => {
                        console.log("open Rollback view")
                        this.handleOpenRollback(true)
                    }}>rollback</Button>
                    <Button onClick={() => this.handleClose()}>cancel</Button>
                    <Notifications />
                </Modal.Content>
            </Modal>
        } else {
            return <p></p>
        }
    }

}