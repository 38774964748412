import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {authentication} from "../../../../../authentication";
import MatrixDocTabMenu from "./MatrixDocTabMenu";
import TabEditConfigMenu from "../../configChange/configEdit/TabEditConfigMenu";
import {backend} from "../../../../../xconvert-backend";
import {IfBox} from "../../../../style/if";
import {updateConfig, workingConfig} from "../../configChange/ConfigSignal";


export interface MatrixDocViewPageProps {
    output: string
}

export interface MatrixDocViewPageState {
    matrixDoc: string
    dictionary: string
    auth: any
    currentConfig: string
    xRequestId: string
}


export class MatrixDocViewPage extends React.Component<MatrixDocViewPageProps, MatrixDocViewPageState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)

        this.state = {
            matrixDoc: "",
            dictionary: "",
            auth: undefined,
            currentConfig: null,
            xRequestId: null,
        }
    }

    changeXRequestId(newId: string) {
        this.setState({xRequestId: newId})
    }

    async componentDidMount() {
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        if (workingConfig.value == undefined) {
            let currentCompanyConfig = (await backend.internalApi.fetchCompanyConfiguration(this.context.companyId, auth))
            updateConfig(JSON.stringify(currentCompanyConfig, null, 4), false, false)
        }
        this.setState({currentConfig: workingConfig.value, auth: auth})
    }

    render() {
        console.log("render matrix doc viewer")
        return <div className={"flexRow"}>
            <div style={{flex: 3}}>
                <MatrixDocTabMenu changeXRequestId={(newId: string) => {
                    this.changeXRequestId(newId)
                }} xRequestId={this.state.xRequestId} context={this.context} output={this.props.output}/>
            </div>
            <IfBox shouldShow={this.state.currentConfig != undefined}>
                <TabEditConfigMenu
                    currentCompanyConfig={workingConfig.value} auth={this.state.auth}
                    context={this.context}
                    jsonValidation={() => {}}
                />
            </IfBox>
            <IfBox shouldShow={this.state.currentConfig == undefined}>
                <p>loading config... </p>
            </IfBox>
        </div>

    }

}