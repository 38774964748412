import * as React from "react";
import {Modal, Header, Button, Grid} from "semantic-ui-react";
import {QueuedEvent} from "../../../../../generated";
import { DiffWrapper } from "../../configChange/configEdit/diff/DiffWrapper";
import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {CustomDiff} from "../../configChange/configEdit/diff/CustomDiff";

export interface QueuedEventDiffModalProps {
    isOpen: boolean
    onClose: () => void
    before: QueuedEvent
    after: QueuedEvent

}

export class QueuedEventDiffModal extends React.Component<QueuedEventDiffModalProps, any> {

    constructor(props) {
        super(props)
        this.state = {
            editors: null
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='QueuedEvent before and after' />
            <Modal.Content>
                <Grid padded="horizontally" stackable columns='equal' centered>
                    <Grid.Column>
                        <h3 style={{textAlign: "center"}}>BEFORE</h3>
                    </Grid.Column>
                    <Grid.Column>
                        <h3 style={{textAlign: "center"}}>AFTER</h3>
                    </Grid.Column>
                </Grid>
                <CustomDiff
                    oldJson={JSON.stringify(this.props.before, null, 4)}
                    newJson={JSON.stringify(this.props.after, null, 4)}
                />
                <Button onClick={() => this.handleClose()}>Close</Button>

            </Modal.Content>
        </Modal>
    }

}