import * as React from "react";
import {Accordion, Button, Grid, Icon, Label} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {Queue, QueuedEvent, QueueWithEvent} from "../../../../../generated";
import {LogView} from "../../logView/LogView";
import {QueuedEventsTable} from "../events/QueuedEventsTable";
import ReactTable from "react-table";
import {QueuedEventDiffModal} from "./QueuedEventDiffModal";

export interface TestSuppressionScriptModalMultiProps {

    queue: Queue

}

export interface TestSuppressionScriptModalMultiState {
    selected: QueuedEvent[]
    output: QueuedEvent[]
    activeIndex: number,
    xRequestId: string,
    before: QueuedEvent,
    after: QueuedEvent,
    showDiff: boolean,
}

export class TestSuppressionScriptModalMulti extends React.Component
    <TestSuppressionScriptModalMultiProps, TestSuppressionScriptModalMultiState> {

    constructor(props) {
        super(props)
        this.state = {
            selected: [],
            output: [],
            activeIndex: -1,
            xRequestId: null,
            before: null,
            after: null,
            showDiff: false,
        }
    }

    componentDidMount() {

    }

    async test() {
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let request = {} as QueueWithEvent
        request.queue = this.props.queue
        request.events = this.state.selected
        //let response = await backend.queueApi.testSuppressionScriptTest(request, auth)

        let response = await fetch('/api/queues/testSuppressionScriptTest', {
            method: 'POST',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(request)
        })

        let resp = (await response.json()).events

        this.setState({
            output: resp,
            xRequestId: response.headers.get("x-request-id")
        })
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    drawSuppression(event) {
        if (event) {
            let suppressed = event.suppressed
            if (suppressed == true) {
                return <a style={{color: "green", fontSize: "large"}}> <Icon name={"check"} color={"green"}/>Suppressed!</a>
            } else {
                return <a style={{color: "red", fontSize: "large"}}> <Icon name={"x"} color={"red"}/> NOT
                    suppressed!</a>

            }
        }
        return <a style={{color: "black", fontSize: "large"}}> unknown</a>
    }

    render() {
        const {activeIndex} = this.state

        return <>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Column>
                    <Grid.Row>
                        <Label>Input QueuedEvent</Label>
                    </Grid.Row>
                    <Grid.Row>
                        <QueuedEventsTable
                            onClickedEvent={(event) => {
                            }}
                            onSelectionChanged={(newSelected) => {
                                this.setState({selected: newSelected})
                            }}
                        />

                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                    <Accordion styled fluid>
                        <Accordion.Title
                            active={activeIndex === 0}
                            index={0}
                            onClick={this.handleClick}
                        >
                            <Icon name='dropdown'/>
                            Logs
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                            <LogView query={this.state.xRequestId} origin={"suppressionScriptModal"}/>
                        </Accordion.Content>
                    </Accordion>
                    {this.renderResultTable()}
                </Grid.Column>
            </Grid>
            <Button
                disabled={this.state.selected.length === 0}
                onClick={() => this.test()}
            >
                Test
            </Button>
            <QueuedEventDiffModal
                isOpen={this.state.showDiff}
                onClose={() => this.setState({showDiff: false})}
                before={this.state.before}
                after={this.state.after}
            />
        </>

    }

    changed(after: QueuedEvent) {
        let before = this.state.selected.find(s => s._id === after._id)
        let changed = JSON.stringify(before) !== JSON.stringify(after)
        if (changed) {
            return <a style={{color: "blue", fontSize: "large"}}> <Icon name={"radio"} color={"blue"}/> change detected!
            </a>

        }
        return <a style={{fontSize: "large"}}> <Icon name={"radio"}/>No change!</a>
    }

    renderResultTable() {
        const columns = [
            {
                id: 'Changed',
                Header: 'changed',
                width: 200,
                accessor: (d: any) => this.changed(d),
                sortField: 'CHANGE'
            },
            {
                id: 'suppressed',
                Header: 'suppressed',
                width: 150,
                accessor: (d: any) => this.drawSuppression(d),
                sortField: 'SUPPRESSED'
            },
            {
                id: 'queueType',
                Header: 'queueType',
                width: 150,
                accessor: (d: any) => d.queueType,
                sortField: 'QUEUE_TYPE'
            }, {
                id: 'eventType',
                Header: 'eventType',
                width: 200,
                accessor: (d: any) => d.eventType,
                sortField: 'EVENT_TYPE'
            },
            {
                id: 'orderId',
                Header: 'Order ID',
                width: 100,
                accessor: (d: QueuedEvent) => d.transportOrderEvent?.transportOrder?._id ?? d.positionEvent?.transportOrderId ?? d.etaEvent?.transportOrder?._id,
                Cell: (d: any) => {
                    return <span>{d.value}</span>
                }
            },
            {
                id: 'changeType',
                Header: 'Change Type',
                width: 150,
                accessor: (d: QueuedEvent) => d.transportOrderEvent?.change?.type ?? (d.etaEvent?.nextStops && d.etaEvent?.nextStops[0]?.city),
                Cell: (d: any) => {
                    return <span>{d.value}</span>
                }
            },
        ]

        return <ReactTable
            data={this.state.output}
            //pages={Math.ceil(this.state.count / this.state.take)}
            columns={columns}
            showPagination={false}
            //defaultPageSize={this.state.output.length}
            pageSize={this.state.output.length}
            className="-striped -highlight"
            //loading={this.state.isLoading}
            getTdProps={(state, rowInfo) => {
                return {
                    onClick: () => {
                        this.setState({
                            before: this.state.selected.find(s => s._id === rowInfo.row._original._id),
                            after: rowInfo.row._original,
                            showDiff: true
                        })
                    }
                };
            }}
        />
    }

}

