import * as React from "react";
import {Button, Grid, Header, Modal} from "semantic-ui-react";
import {TransportOrder, ValidationError} from "../../../../../generated";
import Notifications from 'react-notify-toast';
import {CustomDiff} from "../../configChange/configEdit/diff/CustomDiff";

export interface TransportOrderCreateOrModifyCompareModalProps {
    isOpen: boolean
    onClose: (openRollback: boolean) => void
    oldTo: TransportOrder,
    newTo: TransportOrder,
    errors: ValidationError[]
}

export class TransportOrderCreateOrModifyCompareModal extends React.Component<TransportOrderCreateOrModifyCompareModalProps, any> {

    constructor(props: TransportOrderCreateOrModifyCompareModalProps) {
        super(props)
        this.state = {
        }
    }

    handleClose = () => {
        this.props.onClose(false)
    }

    render() {
        if (this.props.newTo != null) {
            return <Modal
                open={this.props.isOpen}
                onClose={this.handleClose}
                onMount={() => {}}
                dimmer="blurring"
                size='fullscreen'>
                <Header icon='browser' content={'Before and after compare of ' + this.props.newTo._id}/>
                <Modal.Content>
                    {this.renderValidationErrors()}

                    <Grid padded="horizontally" stackable columns='equal' centered>
                            <Grid.Column><h2>BEFORE</h2></Grid.Column>

                            <Grid.Column><h2>AFTER</h2></Grid.Column>
                    </Grid>
                    <CustomDiff
                        oldJson={JSON.stringify(this.props.oldTo, null, 4)}
                        newJson={JSON.stringify(this.props.newTo, null, 4)}
                    />

                    <Button onClick={() => this.handleClose()}>close</Button>
                    <Notifications/>
                </Modal.Content>
            </Modal>
        } else {
            return <p/>
        }
    }

    renderValidationErrors() {
        if(this.props.errors.length === 0) {
            return <p>No validation errors!</p>
        } else {
            return <>
                <p>While creating or modifying the following validation errors occurred:</p>
            </>
        }

    }

}