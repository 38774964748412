import * as React from "react";
import {ConfigContext} from "../../../../context/ConfigContext";
import EditContractTabView from "./EditContractTabView";
import {Button, Dropdown, DropdownItemProps, Grid, Icon} from "semantic-ui-react";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {Contract, ExtractToSpecificConfigRequest} from "../../../../../../generated";
import {IfBox} from "../../../../../style/if";
import {SaveContractModal} from "./modals/SaveContractModal";
import {ShowUnsavedChangesModal} from "./modals/ShowUnsavedChanges";
import 'brace/mode/groovy'
import 'brace/ext/searchbox'


export interface EditContractProps {
    selectedContract: Contract | null,
    unselectDispatch: (newContract) => void
    refreshList: () => void
    readOnly: boolean
}


export interface EditContractState {
    isLoading: boolean
    showSaveContractModal: boolean
    showUnsavedChanges: boolean
}

export class EditContract extends React.Component<EditContractProps, EditContractState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            showSaveContractModal: false,
            showUnsavedChanges: false
        }
    }

    async addContract() {
        this.setState({isLoading: true})
        if (this.context.selectedContract != this.context.oldContract) {
            this.setState({showUnsavedChanges: true})
        } else {
            this.context.setOldContract(null)
            this.context.setSelectedContractIsExpired(false)
            await this.fetchDefaultContract()
            this.setState({isLoading: false})
        }
    }

    async fetchDefaultContract() {
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let response = (await backend.internalApi.fetchDefaultContract(auth))
        this.context.setSelectedContract(JSON.stringify(response, null, 4))
        this.props.unselectDispatch(response)
    }

    async saveContract() {
        this.setState({showSaveContractModal: true})
    }

    render() {
        return <div className={"flex3"}>
            <EditContractTabView context={this.context} jsonValidation={() => {
            }}
                                 readOnly={this.props.readOnly}/>
            <IfBox shouldShow={this.state.showSaveContractModal}>
                <SaveContractModal
                    isOpen={this.state.showSaveContractModal}
                    onClose={() => this.setState({showSaveContractModal: false})}
                    dispatch={(reload) => {
                        if (reload) {
                            this.props.refreshList()
                        }
                    }}
                />
            </IfBox>
            <IfBox shouldShow={this.state.showUnsavedChanges}>
                <ShowUnsavedChangesModal
                    isOpen={this.state.showUnsavedChanges}
                    onClose={() => this.setState({showUnsavedChanges: false})}
                    dispatch={(save) => {
                        if (save) {
                            this.setState({showSaveContractModal: true, showUnsavedChanges: false})
                        }
                    }}
                />
            </IfBox>
            <div>
                <Button icon onClick={() => this.addContract()}><Icon name='plus'/></Button>
                <Button icon onClick={() => this.saveContract()}><Icon name='save'/></Button>
            </div>
        </div>
    }


}