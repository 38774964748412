import * as React from "react";
import { Modal, Header, Button } from "semantic-ui-react";
import { backend } from "../../../../../../xconvert-backend";
import { authentication } from "../../../../../../authentication";
import AceEditor from 'react-ace';
import Notifications, { notify } from 'react-notify-toast';

import 'brace/ext/searchbox'

export interface ResponseModalProps {
    isOpen: boolean
    onClose: () => void
}

export class ShowDefaultLisConfigModal extends React.Component<ResponseModalProps, any> {

    constructor(props) {
        super(props)
        this.state = {
            defaultLisConfig: "",
            copySuccess: false
        }
    }

    async componentDidMount() {
        let auth = await backend.withTokenAuthHeader(authentication.token)
        await backend.internalApi.fetchDefaultLisConfiguration(auth).then((response) => {
            console.log("DEFAULT LIS CONFIG: ", response)
            this.setState({ defaultLisConfig: JSON.stringify(response, null, 4) })
        })

    }

    handleClose = () => {
        this.props.onClose()
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.state.defaultLisConfig)
        console.log("copied to Clipboard")
        notify.show('Copied To Clipboard!.', 'success', 3000, '#28f751');
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'
            id='ShowDefaultLisConfigModal'>
            <Header icon='browser' content='Default LIS-Configuration' />
            <Modal.Content>
                <AceEditor
                    theme="monokai"
                    mode="json"
                    value={this.state.defaultLisConfig}
                    width="100%"
                    height="900px"
                    readOnly={true}
                />
                <Button id='CloseDefaultLisModal' onClick={() => this.handleClose()}>Close</Button>
                <Button id='CopyDefaultLisModal' onClick={() => { this.copyToClipboard() }}>Copy</Button>
                <Notifications />

            </Modal.Content>
        </Modal>
    }

}