import * as React from "react";
import {PageWithSidebar} from "../../../style/page_with_sidebar";
import {DbQueryGrid} from "./DbQueryGrid";


export interface DBqueryPageProps {

}

export function DBqueryPage(props: React.PropsWithChildren<DBqueryPageProps>) {

    return <PageWithSidebar activeMenu="DBqueryPage">
        <DbQueryGrid />
    </PageWithSidebar>
}