import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {
    CreateOrModifyTransportOrderDryRunRequest,
    TransportOrder,
    TransportOrderResponse
} from "../../../../../generated";
import {Button} from "semantic-ui-react";
import ReactTable from "react-table";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {
    determineDotColorForTransportOrder,
    EtaDateTimeDisplay,
    formatFreight,
    formatTransport,
    formatTransportOrderStatus,
    getNextStopByEta
} from "../Formatters";
import {formatDate} from "../../../../../format";
import {TransportOrderCreateOrModifyCompareModal} from "../modals/TransportOrderCreateOrModifyCompareModal";
import {configStorage} from "../../../../../ConfigStorage";
import {LogView} from "../../logView/LogView";
import {SplitterHorizontal} from "../../../../util/table-splitter-ui/src/components/Splitters/SplitterHorizontal";
import {IfBox} from "../../../../style/if";


export interface TransportOrderTransportOrderCreateOrModifyProps {
    transportOrders: TransportOrder[]
}

export interface TransportOrderTransportOrderCreateOrModifyState {
    isLoading: boolean,

    responses: TransportOrderResponse[]
    selectedTransportOrderResponse: TransportOrderResponse
    highlightRowIndex: number,
    showCompareModal: boolean,

    xRequestId: string
}


export class TransportOrderCreateOrModify extends React.Component<TransportOrderTransportOrderCreateOrModifyProps, TransportOrderTransportOrderCreateOrModifyState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props: TransportOrderTransportOrderCreateOrModifyProps) {
        super(props)

        this.state = {
            isLoading: false,
            responses: [],
            selectedTransportOrderResponse: null,
            highlightRowIndex: null,
            showCompareModal: false,
            xRequestId: ""
        }
    }

    async send(sendConfig: boolean) {
        this.setState({isLoading: true})
        let auth = backend.withTokenAuthHeader(authentication.token)

        let request = {} as CreateOrModifyTransportOrderDryRunRequest
        request.orders = this.props.transportOrders
        if (sendConfig) {
            request.config = (await configStorage.getElement(this.context.companyId)).config
        }

        //let response = await backend.internalApi.createOrModifyTransportOrderDryRun(request, null, null, null, null, auth)
        let rawResp = await fetch("/api/internal/createOrModifyDryRun", {
            method: 'POST',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8",
                "x-company-id": this.context.companyId,
            },
            body: JSON.stringify(request)
        })

        let responseText = await rawResp.text()
        let response = JSON.parse(responseText)

        this.setState({
            isLoading: false,
            responses: response.transportOrderResponses,
            xRequestId: rawResp.headers.get("x-request-id")
        })
    }

    renderTop() {
        return <div className={"flex1"}>
            <div style={{display: "flex", flexDirection: "row", marginBottom: "2px"}}>
                <Button loading={this.state.isLoading}
                        disabled={this.props.transportOrders.length == 0}
                        onClick={() => this.send(true)}>
                    create or modify Dry Run with config from CM
                </Button>
                <Button loading={this.state.isLoading}
                        disabled={this.props.transportOrders.length == 0}
                        onClick={() => this.send(false)}>
                    create or modify Dry Run with config from DB
                </Button>
            </div>

            {this.renderTable()}


        </div>
    }

    renderBottom() {
        return <LogView
            query={this.state.xRequestId}
            origin={"transportOrderCreateOrModify"}
        />
    }

    renderModals() {
        return this.state.selectedTransportOrderResponse &&
            <TransportOrderCreateOrModifyCompareModal
                isOpen={this.state.showCompareModal}
                onClose={() => this.setState({showCompareModal: false})}
                oldTo={this.props.transportOrders?.find(order => order._id === this.state.selectedTransportOrderResponse?.transportOrder._id)}
                newTo={this.state.selectedTransportOrderResponse?.transportOrder}
                errors={this.state.selectedTransportOrderResponse?.validationErrors}
            />

    }

    render() {

        return <IfBox shouldShow={this.props.transportOrders.length == 0} else={
            <>
                <SplitterHorizontal
                    name={"TransportOrderCreateOrModify"}
                    top={this.renderTop()}
                    bottom={this.renderBottom()}
                />
                {this.renderModals()}
            </>
        }>
            <p>Please select transportOrder(s) on the left side</p>
        </IfBox>

    }

    renderTable() {
        const columns = [

            {
                id: '_id',
                Header: "Id",
                accessor: (d: any) => d.transportOrder._id,
            },
            {
                id: 'status',
                Header: 'Status',
                accessor: (d: TransportOrderResponse) => formatTransportOrderStatus(d.transportOrder.status),
            },
            {
                id: 'externalTransportOrderId',
                Header: 'ExternalOrderNo',
                accessor: (d: TransportOrderResponse) => d.transportOrder.externalTransportOrderId,
                Cell: (props: any) => <span className='number'>{props.value}</span> // Custom cell components!
            }, {
                id: 'created', // Required because our accessor is not a string
                Header: 'Created',
                width: 140,
                accessor: (d: TransportOrderResponse) => formatDate(d.transportOrder.created) // Custom value accessors!
            },
            {
                id: 'planStart',
                Header: 'PlanStart',
                width: 140,
                accessor: (d: TransportOrderResponse) => formatDate(d.transportOrder.planStart),
            },
            {
                id: 'planEnd',
                Header: 'PlanEnd',
                width: 140,
                accessor: (d: TransportOrderResponse) => formatDate(d.transportOrder.planEnd),
            },
            {
                id: 'targetCompanyName',
                Header: 'Contractor',
                accessor: (d: TransportOrderResponse) => d.transportOrder.targetCompanyName
            },
            {
                id: 'is_subchartered',
                Header: "Via Sub?",
                sortable: false,
                accessor: (d: TransportOrderResponse) => d.transportOrder.linkInfo != null && d.transportOrder.linkInfo.targetTransportOrderId != null ? "☑" : ""
            },
            {
                id: 'observer',
                sortable: false,
                Header: 'Observer',
                accessor: (d: TransportOrderResponse) => d.transportOrder.observer && d.transportOrder.observer.length > 0 ? "☑ " + d.transportOrder.observer.length : ""
            },
            {
                id: 'original_source_company',
                sortable: false,
                Header: 'Consignor',
                accessor: (d: TransportOrderResponse) => d.transportOrder.linkInfo != null ? d.transportOrder.linkInfo.sourceCompanyName : ""
            },
            {
                id: 'companyName',
                Header: 'Client',
                accessor: (d: TransportOrderResponse) => d.transportOrder.companyName
            },
            {
                id: 'vehicle',
                Header: 'Vehicle',
                accessor: (d: TransportOrderResponse) => {
                    if (d.transportOrder.assignedVehicle != null && d.transportOrder.assignedVehicle.numberPlate != null) {
                        return d.transportOrder.assignedVehicle.numberPlate
                    } else if (d.transportOrder.assignedDevice != null && d.transportOrder.assignedDevice.verboseName != null) {
                        return d.transportOrder.assignedDevice.verboseName
                    }
                    return ""
                }
            },
            {
                id: 'driver',
                Header: 'Driver',
                accessor: (d: TransportOrderResponse) => {
                    if (d.transportOrder.assignedDriver != null && (d.transportOrder.assignedDriver.firstName != null || d.transportOrder.assignedDriver.firstName != null)) {
                        return `${d.transportOrder.assignedDriver.firstName} ${d.transportOrder.assignedDriver.lastName}`
                    } else if (d.transportOrder.assignedDriver != null && d.transportOrder.assignedDriver.externalDriverId != null) {
                        return d.transportOrder.assignedDriver.externalDriverId
                    }
                    return ""
                }
            },
            {
                id: 'trailer',
                Header: 'Trailer',
                accessor: (d: TransportOrderResponse) => {
                    if (d.transportOrder.assignedTrailer?.numberPlate != null) {
                        return d.transportOrder.assignedTrailer.numberPlate
                    } else if (d.transportOrder.assignedTrailer?.externalVehicleId != null) {
                        return d.transportOrder.assignedTrailer.externalVehicleId
                    }
                    return ""
                }
            },
            {
                id: 'eta',
                Header: 'NextETA',
                sortable: false,
                width: 135,
                accessor: (d: TransportOrderResponse) => {
                    let nextEta = getNextStopByEta(d.transportOrder)

                    if (nextEta?.eta) {
                        return <EtaDateTimeDisplay eta={nextEta.eta}/>
                    }
                    return ""
                },
                Cell: (row: any) => (
                    <span>
                    <span style={{
                        color: determineDotColorForTransportOrder(row.original.transportOrder),
                        transition: 'all .3s ease'
                    }}>&#x25cf;</span>
                        {row.value}
                </span>
                )
            },
            {
                id: 'from',
                Header: 'Stops',
                sortable: false,
                accessor: (d: TransportOrderResponse) => d.transportOrder.transports != null && formatTransport(d.transportOrder.transports)
            },
            {
                id: 'freight',
                sortable: false,
                Header: 'Freight',
                accessor: (d: TransportOrderResponse) => d.transportOrder.freight != null && formatFreight(d.transportOrder.freight)
            },
            {
                id: 'assignedDeviceMail',
                sortable: false,
                visible: false,
                Header: 'AssignedDeviceMail',
                accessor: (d: TransportOrderResponse) => d.transportOrder.assignedDevice?.emailAddress
            },
            {
                id: 'assignedDevicePhone',
                sortable: false,
                visible: false,
                Header: 'AssignedDevicePhone',
                accessor: (d: TransportOrderResponse) => d.transportOrder.assignedDevice?.phoneNr
            }
        ]


        return <ReactTable
            data={this.state.responses}
            columns={columns}
            //showPagination={false}
            pageSize={10}
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: () => {
                        if (rowInfo) {
                            console.log("Opening TransportOrder with id", rowInfo.row)
                            this.setState({
                                selectedTransportOrderResponse: rowInfo.row._original,
                                highlightRowIndex: rowInfo.index,
                                showCompareModal: true
                            })
                        }
                    },
                    style: {
                        background: rowInfo?.index === this.state.highlightRowIndex ? '#00afec' : 'white',
                        color: rowInfo?.index === this.state.highlightRowIndex ? 'white' : 'black'
                    }
                };
            }}
            className="-striped -highlight"
            loading={this.state.isLoading}
            style={{cursor: "pointer"}}

        />
    }


}
