import * as React from "react";
import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {ConfigContext} from "../../../../context/ConfigContext";
import {formatDate} from "../../../../../../format";
import ReactTable from "react-table";
import {Contract} from "../../../../../../generated";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import moment = require("moment");
import {Icon} from "semantic-ui-react";

export interface ContractListProps {
    contractSelected: (contract: Contract) => void
    selectedContract: Contract | null
    refreshTrigger: boolean
}


export interface ContractListState {
    isLoading: boolean,
    take: number
    page: number
    sortBy: 'VALID_FROM' | 'BILLING_ACTIVE' | 'NONE'
    sortDirection: 'ASC' | 'DESC'
    count: number
    data: any[]
}


export class ContractList extends React.Component<ContractListProps, ContractListState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading ContractList")

        this.state = {
            isLoading: false,
            take: 20,
            page: 1,
            sortBy: 'VALID_FROM',
            sortDirection: 'ASC',
            count: 0,
            data: []
        }
    }

    async componentDidMount() {
        await this.fetchContracts()
        const data: any[] | null = this.fillDummyEntries(this.context.contracts, this.state.count)
        this.setState({data: data})

    }

    async componentWillReceiveProps(nextProps: Readonly<ContractListProps>, nextContext: any) {

        if(this.context.selectedContractIndex != null) {
            let index = this.state.data.findIndex(el => el.contract.validFrom === nextProps.selectedContract.validFrom)
            if(index== -1) {
                this.context.setSelectedContractIndex(null)
            }
        }

        if(this.props.refreshTrigger != nextProps.refreshTrigger) {
            await this.fetchContracts()
        }

    }

    async fetchContracts(take = this.state.take, page = this.state.page) {
        this.setState({isLoading: true})
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let response = await backend.internalApi.fetchCompanyContracts(
            this.context.companyId,
            take,
            null,
            (page - 1) * take,
            'VALID_FROM',
            "ASC",
            auth)
        this.context.setContracts(response.contracts)
        const data: any[] | null = this.fillDummyEntries(response.contracts, response.total)
        this.setState({isLoading: false, count: response.total, data: data})

    }

    unselectRow() {
        this.context.setSelectedContractIndex(null)
    }

    render() {

            const data = this.state.data
            const columns = [{
                id: 'ACTIVE',
                Header: 'active',
                width: 50,
                accessor: (d: any) => d.active,
                Cell: (d: any) => { return <span>{this.formatActive(d.value)}</span> },
                sortField: 'VALID_FROM'
            },{
                id: 'VALID_FROM',
                Header: 'validFrom',
                width: 150,
                accessor: (d: any) => d.contract.validFrom,
                Cell: (d: any) => { return <span>{formatDate(d.value)}</span> },
                sortField: 'VALID_FROM'
            }, {
                id: 'BILLING_ACTIVE',
                Header: 'billingActive',
                accessor: (d: any) => d.contract.billingActive,
                Cell: (d: any) => { return <span>{this.formatBoolean(d.value)}</span> },

                sortField: 'BILLING_ACTIVE'
            }, {
                id: 'EXPORT_TO',
                Header: 'exportTo',
                accessor: (d: any) => d.contract.exportTo,
                sortField: 'EXPORT_TO'
            }, {
                id: 'FIXED_BASE_PRICE',
                Header: 'fixedBasePrice',
                accessor: (d: any) => d.contract.fixedBasePrice,
                sortField: 'FIXED_BASE_PRICE'
            }, {
                id: 'GROUPING_TAG_SCRIPT',
                Header: 'groupingTagScript',
                accessor: (d: any) => d.contract.groupingTagScript,
                sortField: 'GROUPING_TAG_SCRIPT'
            }, {
                id: 'IMPORT_FROM',
                Header: 'importFrom',
                accessor: (d: any) => d.contract.importFrom,
                sortField: 'IMPORT_FROM'
            }, {
                id: 'PRICE_ORDER',
                Header: 'pricePerOrder',
                accessor: (d: any) => d.contract.pricePerOrder,
                sortField: 'PRICE_ORDER'
            }
            ]

            return <p>

                <ReactTable
                    data={data}
                    pages={Math.ceil(this.state.count / this.state.take)}
                    columns={columns}
                    sorted={[
                        {
                            id: 'VALID_FROM',
                            desc: true
                        }
                    ]}
                    onSortedChange={(newSorted, column, shiftKey) => { this.changeSort(column.sortField) }}

                    defaultPageSize={this.state.take}
                    className="-striped -highlight"
                    loading={this.state.isLoading}
                    getTdProps={(state, rowInfo, column, instance) => {

                        if (rowInfo && rowInfo.row) {
                            return {

                                onClick: (e) => {

                                    this.context.setSelectedContractIndex(rowInfo.index)

                                    this.selectContract(rowInfo.row._original.contract)

                                    if(rowInfo.row._original.active == ActiveEnum.EXPIRED) {
                                        this.context.setSelectedContractIsExpired(true)
                                    } else {
                                        this.context.setSelectedContractIsExpired(false)
                                    }
                                },
                                style: {
                                    background: rowInfo.index === this.context.selectedContractIndex ? '#00afec' : 'white',
                                    color: rowInfo.index === this.context.selectedContractIndex ? 'white' : 'black'
                                }
                            }
                        }else{
                            return {}
                        }

                    }}
                    style={{ cursor: "pointer" }}
                    onPageChange={(pageIndex) => this.changePage(pageIndex)}
                    onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}

                />

            </p>

    }

    formatActive(active: ActiveEnum) {
        switch (active) {
            case ActiveEnum.ACTIVE: return <Icon name='check' color='green' />
            case ActiveEnum.UPCOMPIN: return <Icon name='ellipsis horizontal' color='yellow' />
            case ActiveEnum.EXPIRED: return <Icon name='x' color='red' />

        }
    }

    formatBoolean(boolean: boolean) {
        if (boolean == true) {
            return "true"
        } else if (boolean == false){
            return "false"
        }
        return "null"
    }

    selectContract(contract) {
        this.setState({isLoading: true})
        this.context.setSelectedContract(JSON.stringify(contract, null, 4))
        this.context.setOldContract(JSON.stringify(contract, null, 4))
        this.props.contractSelected(contract)

        this.fetchContracts()
        this.setState({isLoading: false})

    }

    async changeSort(sortBy: 'VALID_FROM' | 'BILLING_ACTIVE' | 'NONE') {
        console.log("changing sort")
        this.setState({ isLoading: true })

        if (sortBy == 'NONE') {
            return
        }

        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({ sortDirection: direction })
        } else {
            //change sortBy and reset direction
            this.setState({ sortBy: sortBy, sortDirection: "ASC" })
        }

        await this.fetchContracts()
        this.setState({ isLoading: false })

    }
    changePage(pageIndex: number) {
        this.setState({ isLoading: true })
        this.fetchContracts(this.state.take, pageIndex + 1)
    }

    changePageSize(newSize: number) {
        this.setState({ isLoading: true })
        this.fetchContracts(newSize, this.state.page)
    }


    fillDummyEntries(contracts: Contract[], totalAmount: number) {
        let dummy = {} as Contract
        let currentEntries: Contract[] = []
        console.log("found " + contracts.length + " histories of a total of " + totalAmount)

        let indexOffset = (this.state.page - 1) * this.state.take
        console.log("offset: " + indexOffset)

        let firstActualFileIndex = indexOffset
        console.log("firstActualFileIndex: " + firstActualFileIndex)

        let lastActualFileIndex = indexOffset + this.state.take - 1
        console.log("lastActualFileIndex: " + lastActualFileIndex)

        for (let i = 0; i < totalAmount; i++) {

            if (i < firstActualFileIndex) {
                currentEntries.push(dummy)
            } else if (i >= firstActualFileIndex) {
                if (i <= lastActualFileIndex) {
                    console.log("take index: " + (i - indexOffset))

                    currentEntries.push(contracts[i - indexOffset])
                    console.log("took: " + contracts[i - indexOffset])

                } else {
                    currentEntries.push(dummy)
                }

            }
        }

        while (currentEntries.length < totalAmount) {

            console.log("adding a dummy")
            currentEntries.push(dummy)
        }

        console.log("new total amount: " + currentEntries.length)

        var entriesWithActive = []
        var currentActiveIndex = 0
        var currentIndex = 0
        currentEntries.map((entry) => {
            let active

            if(moment(entry.validFrom).isAfter(moment())) {
                active = ActiveEnum.UPCOMPIN
            } else {
                currentActiveIndex = currentIndex
                active = ActiveEnum.EXPIRED
            }
            let entryWithActive = {} as ContractWithActive
            entryWithActive.contract = entry
            entryWithActive.active = active
            entriesWithActive.push(entryWithActive)
            currentIndex++
        })

        if(entriesWithActive[currentActiveIndex]) {
            entriesWithActive[currentActiveIndex].active = ActiveEnum.ACTIVE
        }

        return entriesWithActive
    }


}

class ContractWithActive {
    contract: Contract
    active: ActiveEnum
}

enum ActiveEnum{
    UPCOMPIN,
    ACTIVE,
    EXPIRED
}