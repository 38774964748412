import * as React from "react";
import {PageWithSidebar} from "../../../style/page_with_sidebar";
import {
    CompanyConfiguration,
    FetchTransportOrderListFilterRequest,
    PartnerQueryObject,
    TransportOrder,
    TransportOrderStatus
} from "../../../../generated";
import {backend} from "../../../../xconvert-backend";
import {authentication} from "../../../../authentication";
import {Moment} from "moment";
import {DateRangeStr, FilterConditions, InTimeEnum} from "./TransportOrderFilterComponent";
import TransportOrderRightSideTabView from "./rightSide/TransportOrderRightSideTabView";
import {ConfigContext} from "../../context/ConfigContext";
import {TransportOrderSearch} from "./TransportOrderSearch";
import {Splitter} from "../../../util/table-splitter-ui/src/components/Splitters/Splitter";
import {workingConfig} from "../configChange/ConfigSignal";


export class Filter implements FilterConditions {
    dateRangeSelection: DateRangeStr | undefined;
    dateType: FetchTransportOrderListFilterRequest.DateTimeFieldEnum;
    fromDateTime: Date | undefined;
    inTime: InTimeEnum;
    partners: PartnerQueryObject[] | undefined;
    status: TransportOrderStatus.StatusCodeEnum
    term: string | undefined;
    toDateTime: Date | undefined;
    valid: "ONLY_VALID" | "ONLY_INVALID" | undefined;
}

export interface TransportOrderViewState {
    selectedTransportOrderIds: string[],
    allSelected: boolean
    selectedTransportOrder: TransportOrder,
    sortBy: string,
    sortDirection: "ASC" | "DESC",
    transportOrders: TransportOrder[],
    transportOrderAmount: number,
    filter: FilterConditions
    startDate: Moment
    endDate: Moment
    dateRangeStr: string
    dateStart: Moment
    dateEnd: Moment
    focusedInput: any
    highlightRowIndex: number
    showArchiveTransportOrdersModal: boolean
    linkingPartners: any[]
}

export class TransportOrderView extends React.Component<any, TransportOrderViewState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            selectedTransportOrderIds: [],
            allSelected: false,
            selectedTransportOrder: null,
            sortBy: 'NAME',
            sortDirection: "DESC",
            transportOrders: [],
            transportOrderAmount: 0,
            filter: new Filter(),
            startDate: null,
            endDate: null,
            dateRangeStr: "selection",
            dateStart: null,
            dateEnd: null,
            focusedInput: null,
            highlightRowIndex: null,
            showArchiveTransportOrdersModal: false,
            linkingPartners: []
        }
    }

    componentDidMount() {
        //this.fetchTransportOrders()
        this.findLinkingCompanies()
    }

    async findLinkingCompanies() {
        let auth = backend.withTokenAuthHeader(authentication.token)


        let resp = await backend.companyApi.linkingPartners(auth)
        let linking = resp.linkingPartners ?? []

        let config = JSON.parse(workingConfig.value) as CompanyConfiguration
        config.transportOrderLinkConfiguration?.companiesToLinkByKey?.map(async c => {
            if (c.active) {
                let response = await backend.companyApi.queryCompanies(c.companyId, false, auth)
                linking.push({name: response.results[0]?.name, id: c.companyId})
            }
        })

        this.setState({linkingPartners: linking})
    }

    drawLeftSide() {
        return <TransportOrderSearch
            selectedOrderIdsChanged={(newSelectedOrderIds) => this.setState({selectedTransportOrderIds: newSelectedOrderIds})}
            selectedOrderChanged={(newSelectedOrder) => this.setState({selectedTransportOrder: newSelectedOrder})}
            reload={() => {
                this.setState({selectedTransportOrderIds: []})
            }}
            history={this.props.history}
            initialPageSize={25}
            hideArchiveButton={false}
            foundTransportOrdersChanged={(newTransportOrders: TransportOrder[]) => {
                this.setState({transportOrders: newTransportOrders})
            }}
            location={this.props.location}
            match={this.props.match}
        />
    }

    drawRightSide() {
        // console.log("rendering right side. SELECTED IDS: ", this.state.selectedTransportOrderIds)
        // console.log("rendering right side. transportOrders: ", this.state.transportOrders)
        let selected = this.state.transportOrders.filter(to => this.state.selectedTransportOrderIds.includes(to._id))
        // console.log("rendering right side. SELECTED: ", selected)
        return <TransportOrderRightSideTabView
            context={this.context}
            transportOrder={this.state.selectedTransportOrder}
            selectedTransportOrders={selected}
            reload={() => {
                this.setState({selectedTransportOrderIds: []})
                //this.fetchTransportOrders()
            }}
            linkingPartners={this.state.linkingPartners}
        />
    }

    render() {
        console.log("render transportOrderView")
        return <PageWithSidebar activeMenu="TransportOrders">
            <Splitter name="TransportOrderView"
                      left={this.drawLeftSide()}
                      right={this.drawRightSide()}
            />
            {/*<ResizeBoxHorizontalPair*/}
            {/*    height={window.innerHeight - 150}*/}
            {/*    leftContent={}*/}
            {/*    rightContent={this.drawRightSide()}*/}
            {/*    leftWidthPercentage={0.6}*/}
            {/*/>*/}
        </PageWithSidebar>

    }

}