import * as React from "react";
import {ConfigContext} from "../../context/ConfigContext";
import {PageWithSidebar} from "../../../style/page_with_sidebar";

import Notifications from 'react-notify-toast';
import {LeftMenu} from "../../../util/table-splitter-ui/src/components/LeftMenu";
import {Splitter} from "../../../util/table-splitter-ui/src/components/Splitters/Splitter";
import {RightMenu} from "../../../util/table-splitter-ui/src/components/RightMenu";
import {MainView} from "../../../util/table-splitter-ui/src/components/MainView";


export class TableSplitterPage extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)

        this.state = {
            selectedConfig: "",
            updatedSplitterOptions: null
        }

    }

    render() {
        return <PageWithSidebar activeMenu="TableSplitter">
            <Splitter name="MenuContentSplitter"
                      left={<LeftMenu/>}
                      right={<Splitter name="DataResultSplitter" left={<MainView/>} right={<RightMenu/>}/>}
            />
            <Notifications/>
        </PageWithSidebar>
    }

}