import * as React from "react";
import {useContext} from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {
    EtaEvent,
    GpsPosition,
    PositionTestEventRequest,
    QueuedEvent,
    StopLocation,
    TelematicState,
    TransportOrder,
    TransportOrderChange,
    TransportOrderTestEventRequest
} from "../../../../../generated";
import Notifications, {notify} from 'react-notify-toast';
import {backend} from "../../../../../xconvert-backend";
import {EventEnum} from "../../scriptExecution/scriptRunner/QueuedEventCreator";
import {IfBox} from "../../../../style/if";
import {Message, MessageContent, MessageHeader, Tab} from "semantic-ui-react";
import {v4 as uuid} from 'uuid';
import {ExportSimTransportOrderEditor} from "./ExportSimTransportOrderEditor";
import {ExportSimQueuedEventEditor} from "./ExportSimQueuedEventEditor";
import TabEditConfigMenu from "../../configChange/configEdit/TabEditConfigMenu";
import {LTab} from "../../../../util/LTab";
import { workingConfig } from "../../configChange/ConfigSignal";

export interface ExportSimPrepareSideProps {
    auth: any,

    beforeSend(xRequestId: string): void

    onSend(
        response: any,
        queuedEvent: QueuedEvent,
        respondedConfig: any,
        encoding: string,
    ): void
}

export function ExportSimPrepareSide(props: React.PropsWithChildren<ExportSimPrepareSideProps>) {

    const context = useContext(ConfigContext)
    const [errorMessage, setErrorMessage] = React.useState(null)


    async function sendEventToConverter(event, config) {
        let url = '/converter/v3/convertQueuedEventToExternalFormat'
        if (context.customConverterBasePath != null) {
            url = context.customConverterBasePath + url
        }
        let xReqId = uuid()
        props.beforeSend(xReqId)
        let response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                'x-request-id': xReqId
            },
            body: JSON.stringify({
                queuedEvent: event,
                config: config,
            })
        })
        if (!response.ok) {
            setErrorMessage(response.statusText)
        } else {
            setErrorMessage(null)
        }

        let decoded = await decodeResponse(response)

        context.setExportSimOutput(decoded.text)
        context.setExportSimOutputEncoding(decoded.encoding)

        console.log("setting exportSimOutput to: " + context.exportSimOutput)
        props.onSend(
            response,
            event,
            config,
            decoded.encoding,
        )
    }

    async function decodeResponse(response) {

        if (response.status == 204) {
            return {text: "204 - No Content", encoding: "ISO-8859-1"}
        }
        let contentType = await response.headers.get('content-type').toString()

        let localEncoding = contentType.substring(contentType.indexOf("charset=") + 8)


        console.log("ENCODING: " + localEncoding)

        let buffer = await response.arrayBuffer()
        let decoder = new TextDecoder(localEncoding);
        let text = decoder.decode(buffer);

        return {text: text, encoding: localEncoding}
    }

    async function sendEvent(
        selectedEvent: EventEnum,
        changeType: TransportOrderChange.TypeEnum,
        status: string | null,
        telematicStateCode: TelematicState.StatusCodeEnum,
    ) {
        switch (selectedEvent) {
            case EventEnum.ASSIGN_COMPANY_EVENT:
                await convert(TransportOrderTestEventRequest.EventTypeEnum.ASSIGNCOMPANY, null, null)
                break;
            case EventEnum.STATUS_UPDATE_EVENT:
                await convertStatus(changeType, status, telematicStateCode)
                break;
            case EventEnum.STORNO_EVENT:
                await convert(TransportOrderTestEventRequest.EventTypeEnum.STORNO, null, null)
                break;
            case EventEnum.UPDATE_EVENT:
                await convert(TransportOrderTestEventRequest.EventTypeEnum.UPDATE, null, null)
                break;
            case EventEnum.POSITION_EVENT:
                await convertPosition()
                break;
            case EventEnum.ETA_EVENT:
                await convertEtaEvent()
                break;
            default:
                notify.show('Event must be selected ', 'error', 5000, '#fc0303');
                break;
        }
    }

    async function convert(
        eventType: TransportOrderTestEventRequest.EventTypeEnum,
        status: string | null,
        telematicStateStatus: TelematicState.StatusCodeEnum | null,
    ) {
        try {
            let orderCompanyId = null
            if (context.exportSimOrderId && context.exportSimOrderId != "") {
                orderCompanyId = context.exportSimOrderId
            }

            let telematicState = null
            if (telematicStateStatus != null) {
                telematicState = {} as TelematicState
                telematicState.statusCode = telematicStateStatus
            }

            let request = {} as TransportOrderTestEventRequest
            request.configCompanyId = context.companyId
            request.eventType = eventType
            request.newStatus = status
            request.telematicState = telematicState
            request.orderCompanyId = orderCompanyId
            request.transportOrder = JSON.parse(context.exportSimTo)
            request.transportOrderId = context.exportSimToId
            request.config = JSON.parse(workingConfig.value)

            let data = await backend.internalApi.getTransportOrderTestEvent(request, props.auth)

            sendEventToConverter(data.event, data.config).then(_ => {
            })

        } catch (e) {
            notify.show('Error while sending event: ' + e, 'error', 5000, '#fc0303');
            console.log(e)
        }
    }

    async function convertPosition() {
        try {

            let position = {} as GpsPosition
            position.latitude = 42
            position.longitude = 42
            position.timestamp = new Date
            position.accuracyInM = 1


            let request = {} as PositionTestEventRequest
            request.configCompanyId = context.companyId
            request.transportOrder = JSON.parse(context.exportSimTo)
            request.transportOrderId = context.exportSimToId
            request.config = JSON.parse(workingConfig.value)
            request.position = position


            let data = await backend.internalApi.getPositionTestEvent(request, props.auth)

            console.log(data);

            sendEventToConverter(data.event, data.config).then(_ => {
            })

        } catch (ex) {
            console.log(ex)
        }
    }

    async function convertEtaEvent() {
        try {

            // Build QueuedEvent with EtaEvent

            let to = JSON.parse(this.context.exportSimTo) as TransportOrder

            let stops = [] as StopLocation[]
            to.transports.forEach(t => {
                t.stops.forEach(s => {
                    stops.push(s)
                })
            })


            let gpsPosition = {} as GpsPosition
            gpsPosition.accuracyInM = 10
            gpsPosition.latitude = to.transports[0].stops[0].coordinates.latitude
            gpsPosition.longitude = to.transports[0].stops[0].coordinates.longitude
            gpsPosition.timestamp = new Date()

            let etaEvent = {} as EtaEvent
            etaEvent.currentPosition = gpsPosition
            etaEvent.transportOrder = to
            etaEvent.driver = to.assignedDriver
            etaEvent.vehicle = to.assignedVehicle
            etaEvent.nextStops = stops
            etaEvent.timestamp = new Date()

            let queuedEvent = {} as QueuedEvent
            queuedEvent._id = "testId"
            queuedEvent.companyId = this.context.companyId
            queuedEvent.eventType = QueuedEvent.EventTypeEnum.ETAEVENT
            queuedEvent.etaEvent = etaEvent
            queuedEvent.timestamp = new Date()

            await sendEventToConverter(queuedEvent, JSON.parse(workingConfig.value))

        } catch (ex) {
            console.log(ex)
        }
    }

    async function convertStatus(
        changeType,
        status: string | null,
        telematicStateCode: TelematicState.StatusCodeEnum | null
    ) {
        if ((status == null && telematicStateCode == null) || changeType == null) {

            console.log("(status and telematicStateCode) or changeType empty")
            notify.show("Please select a status or telematicStateCode or changeType", 'error', 5000, '#fc0303');
            return
        }
        console.log("status: " + status + "telematicStateCode: " + telematicStateCode + " changetype: " + changeType)
        await convert(changeType, status, telematicStateCode)
    }


    const panes = [
        {
            pane: null,
            menuItem: 'TransportOrder',
            render: () => <Tab.Pane><ExportSimTransportOrderEditor sendEvent={sendEvent}/></Tab.Pane>
        },
        {
            pane: null,
            menuItem: 'Queued Event',
            render: () => <Tab.Pane>
                <ExportSimQueuedEventEditor
                    triggerQueuedEventDirectly={async () => {
                        await sendEventToConverter(JSON.parse(context.exportSimQe), JSON.parse(workingConfig.value))}
                    }
                />
            </Tab.Pane>
        },
        {
            pane: null,
            menuItem: 'Config',
            render: () => <Tab.Pane>
                <TabEditConfigMenu
                    currentCompanyConfig={workingConfig.value}
                    auth={props.auth}
                    fieldPathDispatcher={() => {}}
                    context={context}
                    jsonValidation={() => {}}
                /></Tab.Pane>
        },
    ]

    return <div className={"flex3"}>
        <IfBox shouldShow={errorMessage != null}>
            <Message error>
                <MessageHeader>Error:</MessageHeader>
                <MessageContent>{errorMessage}</MessageContent>
            </Message>
        </IfBox>
        <LTab name={"exportSimPreEditTab"} panes={panes}/>

        <Notifications/>
    </div>

}