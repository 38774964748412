import * as React from "react";
import {Tab} from 'semantic-ui-react'
import {EditRawCompanyConfig} from "./EditRawCompanyConfig";
import {NavTree} from "./newNavTree/NavTree";

const panes = [
    {
        menuItem: 'NavTree', render: props => <Tab.Pane id='navTreeTab' style={{overflow: "auto"}}>
            <NavTree
                auth={props.auth}
                saveKeyListener={props.saveKeyListener}
                changeTab={() => {
                }}
            /></Tab.Pane>
    },
    {
        menuItem: 'Raw', render: props => <Tab.Pane id='rawTab'>
            <EditRawCompanyConfig
                currentCompanyConfig={props.currentCompanyConfig}
                auth={props.auth}
                updateSwitch={props.updateSwitch}
                jsonValidation={props.jsonValidation}
            /></Tab.Pane>
    },
]

const TabEditConfigMenu = props => <Tab
    id={"TabEditConfigMenu"}
    class={"flexTab"}
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.editConfigMenuTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("editConfigMenuTab", data.activeIndex)
    }}
/>

export default TabEditConfigMenu