import * as React from "react";
import {useContext, useEffect} from "react";
import {Dropdown, DropdownItemProps} from "semantic-ui-react";
import ImportSimOutputTabMenu from "./ImportSimOutputTabMenu";
import {ConfigContext} from "../../../context/ConfigContext";
import {importSimOutputs} from "./ImportSimTabMenu";

export function ImportSimOutputWithFileSelection() {

    const context = useContext(ConfigContext)

    const [displayedOutput, setDisplayedOutput] = React.useState(null)

    useEffect(() => {
        if (importSimOutputs.value?.length > 0) {
            setDisplayedOutput(importSimOutputs.value.find((value: any) => value.selected)?.result)
        }
    }, [importSimOutputs.value])


    function drawOutput() {
        if(displayedOutput) {
            return <ImportSimOutputTabMenu
                outputTO={displayedOutput.TOs}
                xRequestId={displayedOutput.xRequestId}
                selectedFileName={displayedOutput.fileName}
                context={context}
            />
        }
        return <></>
    }

    return <>
        {drawOutput()}
    </>

}