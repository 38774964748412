import * as React from "react";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {IfBox} from "../../../../../style/if";
import {Button, Label} from "semantic-ui-react";
import {CreateDefaultConfigModal} from "../modals/CreateDefaultConfigModal";
import {NavTreeNavigation} from "./NavTreeNavigation";
import {NavTreeDetails} from "./NavTreeDetails";
import {ConfigContext} from "../../../../context/ConfigContext";
import {configStorage} from "../../../../../../ConfigStorage";
import {GetTypeRequest, GetTypeResponse} from "../../../../../../generated";
import {NavTreeContextMenu} from "../modals/NavTreeContextMenu";
import {OtherConfigFieldImplementationsModal} from "../modals/OtherConfigFieldImplementationsModal";
import {Signal, signal} from "@preact/signals-react";
import {updateConfig, workingConfig} from "../../ConfigSignal";

const swagger = require("../../../../../../../../swaggerInternal.json")

export interface PathWithDescription {
    path: string
    description: string
}
export const focusesOnField: Signal<PathWithDescription> = signal(null)

export const openInModalPaths: Signal<string[]> = signal([])
export async function loadOpenInModalPaths() {
    let auth = backend.withTokenAuthHeader(authentication.token)
    openInModalPaths.value = (await backend.internalApi.fetchCmNavTreeTextModalList(auth)).pathList
}

export interface NavTreeProps {
    saveKeyListener: (e: any) => void
    changeTab: (index: number, scriptPath: string) => void
    auth: any
}

export function NavTree(props: React.PropsWithChildren<NavTreeProps>) {

    const context = React.useContext(ConfigContext)

    const [showCreateConfigModal, setShowCreateConfigModal] = React.useState(false)
    const [cursor, setCursor] = React.useState<any>({path: "$", name: "CompanyConfiguration"})
    const [data, setData] = React.useState<any>(null)
    const [values, setValues] = React.useState<any>(null)

    const [contextMenuOpen, setContextMenuOpen] = React.useState(false)
    const [contextMenuAnchorPoint, setContextMenuAnchorPoint] = React.useState({x: 0, y: 0})
    const [showDescriptionFor, setShowDescriptionFor] = React.useState<any>("")

    const [modalToOpen, setModalToOpen] = React.useState<any>(null)


    function rightClickHandler(event) {
        // @ts-ignore
        if(event.target.id == "withCustomContextMenu") {
            event.preventDefault();
            // @ts-ignore
            console.log(event.target.innerText);
            // @ts-ignore
            let name = event.target.innerText
            setContextMenuAnchorPoint({x: event.x, y: event.y})
            let c = context.navTreeCursor
            setShowDescriptionFor(c.path + "." + c.name + "." + name)
            setContextMenuOpen(true)

        }
    }
    React.useEffect(() => {
        loadOpenInModalPaths()
        refreshTree()

        document.addEventListener('contextmenu',rightClickHandler);

        return () => {
            document.removeEventListener('contextmenu', rightClickHandler);
        }
    }, [])

    React.useEffect(() => {
        console.log("NavTree useEffect: workingConfig: ", workingConfig)
        refreshTree().then(r => {})
    }, [workingConfig.value])



    let showCreateConfig = false
    if (workingConfig.value == "{}") {
        //config empty
        showCreateConfig = true
    }

    if (showCreateConfig) {
        return <>
            <Label color="yellow">There is no Configuration for this company, yet.</Label>
            <br/>
            <Button type="button" onClick={() => setShowCreateConfigModal(true)}>Create new config</Button>
            <IfBox shouldShow={showCreateConfigModal}>
                <CreateDefaultConfigModal
                    isOpen={showCreateConfigModal}
                    onClose={() => setShowCreateConfigModal(false)}
                    auth={props.auth}/>
            </IfBox>
        </>
    }

    async function refreshTree() {

        let unchangedConfig = (await configStorage.getElement(context.companyId))?.unchangedConfig
        if(unchangedConfig == null) {
            unchangedConfig = await backend.internalApi.fetchCompanyConfiguration(context.companyId, props.auth)
            let unchangedConfigString = JSON.stringify(unchangedConfig, null, 4)
            await updateConfig(unchangedConfigString, true, true)
        }

        let splitData = filterOnlyObjects(
            "$",
            JSON.parse(workingConfig.value),
            [],
            context.navTreeCursor,
            context,
            unchangedConfig
        )

        let somethingHasBeenEdited = splitData.values.filter(v => {
            return v.isEdited == true;
        }).length > 0;

        let formattedData = {
            name: "CompanyConfiguration",
            path: "$",
            toggled: true,
            children: splitData.objects,
            isEdited: somethingHasBeenEdited,
        }

        setData(formattedData)
        setValues(splitData.values)


    }

    function filterOnlyObjects(path, config, previousValues, cursor, context, unchangedConfig): { objects: any[], values: any[] } {
        let objects: any[] = [];
        let values = previousValues;

        let recursive = (p, c) => {
            return (filterOnlyObjects(p, c, values, cursor, context, unchangedConfig))
        }

        let renameListChildren = (list) => {
            //console.log("[renameListChildren] list: ", list)
            if (list.length == 0 || list[0].type != "object") {
                return list
            }

            let newList = []

            list.forEach((elem) => {
                let relevantValues = values.filter((v) => v.path == elem.path + "." + elem.name)
                //console.log("[renameListChildren] relevantValues for " + elem.name + ": ", relevantValues)

                if (relevantValues != null && relevantValues.length > 0) {

                    let api = relevantValues.find((v) => v.name == "api")
                    let key = relevantValues.find((v) => v.name == "key")
                    let verboseName = relevantValues.find((v) => v.name == "verboseName")
                    let etaSource = relevantValues.find((v) => v.name == "etaSource")
                    let description = relevantValues.find((v) => v.name == "description")
                    let ruleName = relevantValues.find((v) => v.name == "ruleName")
                    let lisStatusCode = relevantValues.find((v) => v.name == "lisStatusCode")
                    let name = relevantValues.find((v) => v.name == "name")
                    let columnName = relevantValues.find((v) => v.name == "columnName")
                    let displayName = relevantValues.find((v) => v.name == "displayName")
                    let defaultLabel = relevantValues.find((v) => v.name == "defaultLabel")

                    if (description != null) {
                        elem.displayName = description.value?.toString()
                    }
                    if (ruleName != null) {
                        elem.displayName = ruleName.value?.toString()
                    }
                    if (api != null) {
                        elem.displayName = api.value?.toString()
                    }
                    if (key != null) {
                        elem.displayName = key.value?.toString()
                    }
                    if (verboseName != null) {
                        elem.displayName = verboseName.value?.toString()
                    }
                    if (etaSource != null) {
                        elem.displayName = etaSource.value?.toString()
                    }
                    if (lisStatusCode != null) {
                        elem.displayName = lisStatusCode.value?.toString()
                    }
                    if (name != null) {
                        elem.displayName = name.value?.toString()
                    }
                    if (columnName != null) {
                        elem.displayName = columnName.value?.toString()
                    }
                    if (displayName != null) {
                        elem.displayName = displayName.value?.toString()
                    }
                    if (defaultLabel != null) {
                        elem.displayName = defaultLabel.value?.toString()
                    }
                }
                newList.push(elem)
                //console.log("[renameListChildren] newList: ", newList)
            })
            return newList
        }

        for (const key of Object.keys(config)) {
            let toggled = false
            let active = false

            if (cursor) {
                toggled = (cursor.path + "." + cursor.name).includes(key)
            }

            if (toggled == false && context.treeSearchQuery && context.treeSearchQuery != "") {
                // check treeSearchQuery
                let found = searchToggleFilter(config[key], context.treeSearchQuery)
                if (found) {
                    console.log("MATCH AT cursor: ", cursor)
                    toggled = true
                    active = true
                }
            }
            if ((path + "." + key) == "$.CompanyConfiguration") {
                toggled = true;
            }

            if (config[key] == null) {
                // null
                values.push({
                    type: "null",
                    name: key,
                    path: path,
                    value: config[key],
                })

            } else {
                let json = JSON.stringify(config[key])

                if (json.startsWith("{")) {
                    // object
                    let creationResponse = createChildren(recursive, path, key, config)

                    let foundChange = checkForChange({
                        name: key,
                        path: path,
                        value: config[key]
                    }, unchangedConfig)

                    let o = {
                        type: "object",
                        name: key,
                        toggled: toggled,
                        active: active,
                        isEdited: foundChange,
                        path: path,
                        children: creationResponse.children.objects,

                    }
                    objects.push(o)

                } else if (json.startsWith("[")) {
                    let creationResponse = createChildren(recursive, path, key, config)
                    let foundChange = checkForChange({
                        name: key,
                        path: path,
                        value: config[key]
                    }, unchangedConfig)

                    let l = {
                        type: "list",
                        name: key,
                        toggled: toggled,
                        active: active,
                        isEdited: foundChange,
                        path: path,
                        children: renameListChildren(creationResponse.children.objects)
                    }
                    objects.push(l)
                } else {
                    // value (String, number, boolean...)
                    let foundChange = checkForChange({name: key, path: path, value: config[key]}, unchangedConfig)
                    if (json.startsWith("\"")) {
                        if(path.endsWith("sourceCompanyTag")) {
                            console.log("[sourceCompanyTag] key: ", key, " value: ", config[key], " path: ", path)
                        }
                        let type = "string"
                        try {
                            let index = parseInt(key)
                            if (!isNaN(index)) {
                                // if the key is a number, it is a list element
                                type = "valueListElement"
                            }
                        } catch (e) {

                        }

                        values.push({
                            type: type,
                            name: key,
                            path: path,
                            active: active,
                            isEdited: foundChange,
                            value: config[key]
                        })

                    } else if (json == "true" || json == "false") {
                        // boolean
                        let foundChange = checkForChange({name: key, path: path, value: config[key]}, unchangedConfig)
                        values.push({
                            type: "boolean",
                            name: key,
                            path: path,
                            active: active,
                            isEdited: foundChange,
                            value: config[key],
                        })

                    } else {
                        // number?!
                        let foundChange = checkForChange({name: key, path: path, value: config[key]}, unchangedConfig)
                        values.push({
                            type: "number",
                            name: key,
                            path: path,
                            active: active,
                            isEdited: foundChange,
                            value: config[key]
                        })
                    }
                }
            }
        }

        return {objects: objects, values: values}
    }

    function checkForChange(element,unchangedConfig) {
        const jp = require('jsonpath');
        if(unchangedConfig != undefined) {
            let unchangedValue = jp.value(unchangedConfig, element.path + "." + element.name)
            return JSON.stringify(unchangedValue) != JSON.stringify(element.value)
        } else {
            return false
        }
    }

    function createChildren(recursive, path, key, config) {
        let nextPath = path + "." + key;
        let children = recursive(nextPath, config[key])
        let isChildEdited = false

        //checking if at least one of the sub objects has been edited
        if (children.objects) {
            let editedChildren = children.objects.filter(o => {
                return o.isEdited == true && o.path == nextPath;
            })
            //console.log("editedChildren: ", editedChildren)
            isChildEdited = editedChildren.length > 0
        }

        //if none of the sub objects has been edited, check if one the values has been edited
        if (children.values != undefined) {
            let editedChildren = children.values.filter(v => {
                return v.isEdited == true && v.path == nextPath;
            })

            //console.log("editedChildren: ", editedChildren)
            isChildEdited = editedChildren.length > 0
        }

        return {children: children, isChildEdited: isChildEdited}
    }

    function searchToggleFilter(config: any, searchQuery: string): Boolean {
        if (!config) {
            return false
        }
        let recursive = (c: any, sq: string) => {
            return (searchToggleFilter(c, sq))
        }

        if (typeof config == 'string') {
            return config.toLowerCase().includes(searchQuery.toLowerCase())
        }

        let list = Object.keys(config).map(key => {
            if (key) {
                let json = JSON.stringify(config[key])
                if (json.startsWith("{") || json.startsWith("[")) {
                    return recursive(config[key], searchQuery)
                } else {
                    return (key.toString().toLowerCase()).includes(searchQuery.toLowerCase())
                }
            } else {
                return false
            }
        }).filter(element => element == true)
        return list.length > 0

    }

    async function fixTypeDefinitionIfEnum(element, response) {

        if (/^\d+$/.test(element.key)) {
            console.log("key is element of array ")
        } else {
            if (response.isEnum) {
                element.type = "enum"
                element.enumOptions = JSON.parse(response.defaultObject)
                //console.log("FIXED DEF FOR " + element.name)
            }
            if (response.isValueList) {
                element.type = "valueListElement"

                console.log("[DEBUG] fixTypeDefinitionIfEnum: ", element.name, response)
                // get Type from Swagger
                let pathArray = element.path.split(".")
                let lastName = pathArray.pop()
                let path = pathArray.join(".")

                let previousResponse = (await getTypeByPath(path))


                let type = previousResponse.type.split("$").pop().split(".").pop()

                if (type == "List") {
                    type = previousResponse.listType.split(".").pop()
                }

                var jp = require('jsonpath');
                var jsonPath = "$.definitions." + type + ".properties." + lastName + ".items.enum"
                let obj = jp.query(swagger, jsonPath);

                element.enumOptions = obj[0]

                //console.log("FIXED DEF FOR " + element.name)
            }
        }

    }

    async function getType(element): Promise<GetTypeResponse> {
        return await getTypeByPath(element.path + "." + element.name)
    }

    async function getTypeByPath(path: string): Promise<GetTypeResponse> {
        let request = {} as GetTypeRequest
        request.config = JSON.parse(workingConfig.value)
        request.path = path
        return (await backend.internalApi.getType(request, props.auth))
    }

    return <div className={"flexRow"}>
        <NavTreeNavigation
            cursor={cursor}
            updateCursor={setCursor}
            data={data}
            updateData={setData}
            values={values}
            updateValues={setValues}
            refreshTree={refreshTree}
            fixTypeDefinitionIfEnum={fixTypeDefinitionIfEnum}

        />

        <NavTreeDetails
            cursor={cursor}
            values={values}
            refreshTree={refreshTree}
            updateValues={setValues}
            fixTypeDefinitionIfEnum={fixTypeDefinitionIfEnum}
            getType={getType}
            saveKeyListener={props.saveKeyListener}
        />

        {contextMenuOpen && <NavTreeContextMenu
                isOpen={contextMenuOpen}
                onClose={(mto) => {
                    setContextMenuOpen(false)
                    if (mto) {
                        setModalToOpen(mto)
                    }
                }}
                x={contextMenuAnchorPoint.x}
                y={contextMenuAnchorPoint.y}
                values={values}
                fieldName={showDescriptionFor}
                changeTab={props.changeTab}
        />
        }

        <IfBox shouldShow={modalToOpen == "other implementations"}>
            <OtherConfigFieldImplementationsModal
                isOpen={modalToOpen == "other implementations"}
                onClose={() => setModalToOpen(null)}
                path={showDescriptionFor}
            />
        </IfBox>
    </div>
}

export function cleanPath(path: string){
    let splitPath = path.split(".")
    let cleanSplitPath = []
    splitPath.forEach(part => {
        if(part != "CompanyConfiguration") {
            cleanSplitPath.push(part)
        }
    })
    return cleanSplitPath.join(".")
}