import * as React from "react";
import {useEffect} from "react";
import {GlobalGeofence} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import ReactTable from "react-table";
import {Button, Icon} from "semantic-ui-react";
import {GlobalGeofenceCreateModal} from "./GlobalGeofenceCreateModal";
import {GlobalGeofenceUpdateModal} from "./GlobalGeofenceUpdateModal";
import Notifications, {notify} from 'react-notify-toast';

export interface GlobalGeofenceListProps {

}

export function GlobalGeofenceList(props: React.PropsWithChildren<GlobalGeofenceListProps>) {

    const [globalGeofences, setGlobalGeofences] = React.useState<GlobalGeofence[]>()
    const [pageSize, setPageSize] = React.useState(25)
    const [isLoading, setLoading] = React.useState(false)
    const [openCreateModal, setOpenCreateModal] = React.useState(false)
    const [updateGeofence, setUpdateGeofence] = React.useState<GlobalGeofence | null>(null)


    useEffect(() => {
        fetch()
    }, [])

    async function fetch() {

        let auth = backend.withTokenAuthHeader(authentication.token)

        let response = await backend.internalApi.fetchGlobalGeofences(auth)
        setGlobalGeofences(response.globalGeofences)

    }

    async function onSave(newGlobalGeofence: GlobalGeofence) {
        let auth = backend.withTokenAuthHeader(authentication.token)

        let response = await backend.internalApi.addGlobalGeofences(newGlobalGeofence, auth)
        if(response.success) {
            notify.show('saved GlobalGeofence!', 'success', 3000, '#28f751');
            setOpenCreateModal(false)
            fetch()
        } else {
            notify.show("ERROR: unable to save GlobalGeofence!.", 'error', 5000, '#fc0303')

        }
    }

    async function onUpdate(newGlobalGeofence: GlobalGeofence) {
        let auth = backend.withTokenAuthHeader(authentication.token)

        let response = await backend.internalApi.updateGlobalGeofences(newGlobalGeofence._id, newGlobalGeofence, auth)
        if(response.success) {
            notify.show('updated GlobalGeofence!', 'success', 3000, '#28f751');
            setUpdateGeofence(null)
            fetch()
        } else {
            notify.show("ERROR: unable to update GlobalGeofence!.", 'error', 5000, '#fc0303')

        }
    }

    const columns = [
        {
            id: 'name',
            width: 250,
            Header: 'Name',
            accessor: (d: GlobalGeofence) => d.name
        }, {
            id: 'addressRegex',
            width: 300,
            Header: 'addressRegex',
            accessor: (d: GlobalGeofence) => d.addressRegex
        }
    ]

    let pages = globalGeofences ? Math.ceil(globalGeofences.length / pageSize) : 1
    return <>
        <ReactTable
            data={globalGeofences}
            pages={pages}
            columns={columns}
            sorted={[
                {
                    id: 'name',
                    desc: false
                }
            ]}
            onSortedChange={(newSorted, column, shiftKey) => {
            }}

            defaultPageSize={pageSize}
            className="-striped -highlight"
            loading={isLoading}
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: () => {
                        if (rowInfo) {
                            setUpdateGeofence(rowInfo.original)
                        }
                    }
                };
            }}
            style={{cursor: "pointer"}}
            //onPageChange={(pageIndex) => this.changePage(pageIndex)}
            //onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}

        />
        <Button icon onClick={async (evt) => {

            setOpenCreateModal(true)

        }}><Icon name='plus'/></Button>

        {openCreateModal && <GlobalGeofenceCreateModal
            isOpen={openCreateModal}
            onClose={() => setOpenCreateModal(false)}
            onPendingChange={() => {
            }}
            onSave={onSave}
        />}

        {updateGeofence && <GlobalGeofenceUpdateModal
            isOpen={updateGeofence != null}
            onClose={() => setUpdateGeofence(null)}
            onSave={onUpdate}
            onPendingChange={() => {
            }}
            globalGeofence={updateGeofence}
        />
        }
    </>
}