import * as React from "react";
import { PageWithSidebar } from "../../../style/page_with_sidebar";
import { ConfigContext } from "../../context/ConfigContext";
import { FtpExplorer } from "./FtpExplorer";


export class FtpExplorerPage extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading FtpExplorer Page")

        this.state = {
        }
    }

    render() {
        return <PageWithSidebar activeMenu="FTP Explorer">
            <FtpExplorer history={this.props.history}/>
        </PageWithSidebar>
    }
}
