import * as React from "react";
import {QueuedEvent} from "../../../../../generated";
import {Grid} from "semantic-ui-react";
import AceEditor from 'react-ace';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import Notifications from 'react-notify-toast';
import {QueuedEventsTable} from "./QueuedEventsTable";

export interface QueuedEventsPageProps {

}


export interface QueuedEventsPageState {
    selectedEvent: QueuedEvent | null
    selected: string[]
    editorHeight: string
}


export class QueuedEventsPage extends React.Component<QueuedEventsPageProps, QueuedEventsPageState> {

    constructor(props) {
        super(props)
        console.log("loading QueuedEventsPage")

        this.state = {
            selectedEvent: null,
            selected: [],
            editorHeight: "100%"
        }
    }

    render() {
        return <div className={"flexRow"}>
            <div className={"flex1"} style={{padding: "5px"}}>
                <QueuedEventsTable
                    onClickedEvent={(event) => {
                        this.setState({selectedEvent: event})
                    }}
                    onSelectionChanged={(newSelected) => {
                        this.setState({selected: newSelected.map(s => s._id)})
                    }}
                />
            </div>
            <div className={"flex1"}>
                {this.drawEditor()}
            </div>
        </div>
    }

    drawEditor() {
        let content
        if (this.state.selectedEvent == null) {
            content = ""
        } else {
            content = JSON.stringify(this.state.selectedEvent, null, 4)
        }
        return <AceEditor
            theme="monokai"
            style={{flex: 1}}
            mode='json'
            value={content}
            placeholder='Message'
            width="100%"
            readOnly={true}
        />
    }

}