import {Tab} from "semantic-ui-react";
import * as React from "react";
import {QueuesEditPage} from "./queues/QueueEditPage";
import {QueuedEventsPage} from "./events/QueuedEventsPage";
import {QueuedEventsErrorPage} from "./error/QueuedEventsErrorPage";
import {authentication} from "../../../../authentication";

const panes = [
    { menuItem: 'Events', render: props => <Tab.Pane><QueuedEventsPage/></Tab.Pane> },
    { menuItem: 'Queues', render: props => <Tab.Pane><QueuesEditPage/></Tab.Pane> },
   ]

const panesForSuperusers = [
    { menuItem: 'Events', render: props => <Tab.Pane><QueuedEventsPage/></Tab.Pane> },
    { menuItem: 'Error', render: props => <Tab.Pane><QueuedEventsErrorPage/></Tab.Pane> },
    { menuItem: 'Queues', render: props => <Tab.Pane><QueuesEditPage/></Tab.Pane> },
]

function panesForRole() {
    if(authentication.isSuperUser) {
        return panesForSuperusers
    } else {
        return panes
    }
}

const QueuesTabView = props => <Tab
    id={"QueuesTabView"}
    className={"flexTab"}
    {...props = props}
    panes={panesForRole()}
    defaultActiveIndex={props.context.tabSession.queuesTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("queuesTab", data.activeIndex)
    }}
/>

export default QueuesTabView