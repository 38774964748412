export interface CustomerReport {

    report: any | null,
    apiGatewayStatus: ApiGatewayStatus,
    fileSystemWorkerStatus: FileSystemWorkerStatus,
    queueStatus: QueueStatus,
    statusJsonReport: any,
    needsVerificationFilesCount: any,
    errorFilesCount: any,
    etaCalculatorHealth: any
    messageLogHealth: any[]
}

export interface ApiGatewayStatus {
    companyApiStatus: any[],
    totalWaitingMessages: number,
    totalPolledMessages: number
}

export interface FileSystemWorkerStatus {
    sftpSyncResults: any[]
    customerOldFiles: any[]
    logeniosOldFiles: any[]
    attachmentOldFiles: any[]
}

export interface QueueStatus {
    companies: any[]
}

export function createCustomerReport(resp: any) {
    let report = {} as CustomerReport;

    // Handle apiGatewayStatus
    if (resp.apiGatewayStatus) {
        let apiGatewayStatus = resp.apiGatewayStatus as ApiGatewayStatus;
        apiGatewayStatus.companyApiStatus = sortApiStatus(resp.apiGatewayStatus.companyApiStatus);
        report.apiGatewayStatus = apiGatewayStatus;
    }

    // Handle fileSystemWorkerStatus
    if (resp.fileSystemWorkerStatus) {
        let fileHealthReport = resp.fileSystemWorkerStatus as FileSystemWorkerStatus;
        fileHealthReport.sftpSyncResults = sortSFTPStatus(resp.fileSystemWorkerStatus.sftpSyncResults || []);
        fileHealthReport.customerOldFiles = sortFileHeathReport(resp.fileSystemWorkerStatus.customerOldFiles || []);
        fileHealthReport.logeniosOldFiles = sortFileHeathReport(resp.fileSystemWorkerStatus.logeniosOldFiles || []);
        fileHealthReport.attachmentOldFiles = sortFileHeathReport(resp.fileSystemWorkerStatus.attachmentOldFiles || []);
        report.fileSystemWorkerStatus = fileHealthReport;
    }

    // Handle queueStatus
    if (resp.queueStatus) {
        report.queueStatus = { companies: sortQueueStatus(resp.queueStatus.companies || []) } as QueueStatus;
    }

    // Handle statusJsonReport
    if (resp.statusJsonReport) {
        report.statusJsonReport = sortStatusJsonReportCompanies(resp.statusJsonReport || []);
    }

    // Handle needsVerificationFilesCount
    if (resp.needsVerificationFilesCount) {
        report.needsVerificationFilesCount = sortNeedsVerificationFilesCount(resp.needsVerificationFilesCount || []);
    }

    // Handle errorFilesCount
    if (resp.errorFilesCount) {
        report.errorFilesCount = sortNeedsVerificationFilesCount(resp.errorFilesCount || []);
    }

    // Handle etaCalculatorHealth
    if (resp.etaCalculatorHealth) {
        report.etaCalculatorHealth = sortEtaCalculatorWorkersByUnprocessedEvents(resp.etaCalculatorHealth || []);
    }

    // Handle messageLogHealth
    if (resp.messageLogHealth) {
        report.messageLogHealth = sortMessageLogReport(resp.messageLogHealth || []);
    }

    // Attach the raw response to the report
    report.report = resp;

    return report;
}


function sortMessageLogReport(messageLogHealth: any) {
    let companies = messageLogHealth?.companies
    companies?.sort((a, b) => {
        return a.totalSendErrors - b.totalSendErrors
    })
    return companies ?? []
}

function sortEtaCalculatorWorkersByUnprocessedEvents(etaCalculatorHealth: any) {
    etaCalculatorHealth?.workersHealthy.sort((a, b) => {
        return a.numUnprocessedEvents - b.numUnprocessedEvents
    })
    return etaCalculatorHealth
}

function sortNeedsVerificationFilesCount(list) {
    list?.companies.sort((a, b) => {
        return b.filesOlderThanFiveMinutes - a.filesOlderThanFiveMinutes
    })
    return list
}
function sortStatusJsonReportCompanies(statusJsonReport) {
    statusJsonReport?.companies.sort((a, b) => {
        return a.healthy - b.healthy
    })
    return statusJsonReport
}

function sortQueueStatus(list) {
    list?.sort((a, b) => {
        return b.unprocessedEventsOlderThanFiveMinutes - a.unprocessedEventsOlderThanFiveMinutes
    })
    return list
}

function sortFileHeathReport(list) {
    list?.sort((a, b) => {
        return (b.pendingRetryLoopEntries?.length?? 0) - (a.pendingRetryLoopEntries?.length?? 0)
    }).sort((a, b) => {
        return b.oldFileAmount - a.oldFileAmount
    })
    return list
}

function sortSFTPStatus(list) {
    list?.sort((a, b) => {
        let aVal = 0
        let bVal = 0

        switch (a.status) {
            case "SUCCESS": {
                aVal = 2;
                break;
            }
            case "ERROR": {
                aVal = 1;
                break;
            }
        }

        switch (b.status) {
            case "SUCCESS": {
                bVal = 2;
                break;
            }
            case "ERROR": {
                bVal = 1;
                break;
            }
        }
        console.log("SORT: a: " + aVal + " b: " + bVal)

        return aVal - bVal
    })
    return list
}

function sortApiStatus(list) {
    list?.sort((a, b) => {
        let aVal = 0
        let bVal = 0

        switch (a.status) {
            case "OK": {
                aVal = 3;
                break;
            }
            case "WARNING": {
                aVal = 2;
                break;
            }
            case "ERROR": {
                aVal = 1;
                break;
            }
        }

        switch (b.status) {
            case "OK": {
                bVal = 3;
                break;
            }
            case "WARNING": {
                bVal = 2;
                break;
            }
            case "ERROR": {
                bVal = 1;
                break;
            }
        }
        console.log("SORT: a: " + aVal + " b: " + bVal)

        return aVal - bVal
    })
    return list
}
