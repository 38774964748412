import * as React from "react";
import {useContext, useEffect} from "react";
import AceEditor from 'react-ace';

import 'brace/ext/searchbox'
import {IfBox} from "../../../../../style/if";
import {Button, Dropdown, DropdownItemProps} from "semantic-ui-react";
import {ConfigContext} from "../../../../context/ConfigContext";
import Notifications, {notify} from 'react-notify-toast';
import {ExportSimAddFilesToFTPModal} from "./ExportSimAddFilesToFTPModal";
import {ExportSimSaveAsTestModal} from "../ExportSimSaveAsTestModal";
import {DownloadButton} from "../../../../../util/react-download-link/DownloadButton";

export interface ExportSimOutputProps {
    placeholder: string
}

export function ExportSimOutput(props: React.PropsWithChildren<ExportSimOutputProps>) {

    const context = useContext(ConfigContext)
    const [outputFileIndex, setOutputFileIndex] = React.useState(0)
    const [isConverting, setIsConverting] = React.useState(false)
    const [showAddFileModal, setShowAddFileModal] = React.useState(false)
    const [showSaveAsTestModal, setShowSaveAsTestModal] = React.useState(false)
    const [transportOrder, setTransportOrder] = React.useState(null)
    const [selectedOutput, setSelectedOutput] = React.useState(null)
    const [selectedOutputBase64, setSelectedOutputBase64] = React.useState(null)
    const [selectedFile, setSelectedFile] = React.useState(null)

    const [blob, setBlob] = React.useState(null)

    useEffect(() => {
        if (context.exportSimTo != null && context.exportSimTo != "") {
            try {
                setTransportOrder(JSON.parse(context.exportSimTo))
            } catch (e) {
                console.log("ERROR: ", e)
            }

        }
        setOutputFileIndex(0)
        selectOutputFile(0)
        buildFile()
    }, [])

    useEffect(() => {
        selectOutputFile(0)
        buildFile()
    }, [props])

    function getExportSimOutputFileByIndex(index: number = outputFileIndex) {
        if (context.exportSimOutput != "" &&
            context.exportSimOutput != "[]" &&
            context.exportSimOutput != "204 - No Content" &&
            context.exportSimOutput != null
        ) {
            let resultFiles = JSON.parse(context.exportSimOutput).resultFiles

            return resultFiles[index]
        } else {
            return null
        }
    }

    async function buildFile() {
        if (selectedOutputBase64) {
            if (selectedFile?.fileName?.endsWith(".pdf") == true) {

                const byteCharacters = atob(selectedOutputBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                setBlob(new Blob([byteArray], {type: "application/pdf"}))
            }
        }
    }

    function filesBase64ContentToString(file) {
        if (file) {
            return atou(file)
        } else {
            return null
        }
    }

    function atou(file) {
        let b64 = file.base64Content
        if (file.encoding == "ISO-8859-1") {
            return atob(b64)
        }

        try {

            return decodeURIComponent(escape(atob(b64)));
        } catch (e) {
            console.log("Unable to decodeURIComponent. Exception: ", e)

            return atob(b64)
        }
    }

    function getExportSimOutputStringByIndex(index: number = outputFileIndex) {
        console.log("[getExportSimOutputStringByIndex]")
        let file = getExportSimOutputFileByIndex(index)
        let str = filesBase64ContentToString(file)

        if (str == null) {
            return JSON.parse(context.exportSimOutput).statusMessage
        }
        if (selectedFile?.fileName?.endsWith(".json") == true) {
            return JSON.stringify(JSON.parse(str), null, 4)
        }
        return str
    }

    function getExportSimOutputBase64ByIndex(index: number = outputFileIndex) {
        let file = getExportSimOutputFileByIndex(index)
        let str = filesBase64ContentToString(file)
        if (str == null) {
            return JSON.parse(context.exportSimOutput).statusMessage
        }
        return file?.base64Content
    }

    function selectOutputFile(newOutputFileIndex = outputFileIndex) {
        if (context.exportSimOutput != null && context.exportSimOutput != "" && context.exportSimOutput != "204 - No Content") {
            console.log("selectOutputFile: " + newOutputFileIndex)
            let result = JSON.parse(context.exportSimOutput).resultFiles
            console.log(result.length + " files responded")
            setOutputFileIndex(newOutputFileIndex)
            setSelectedOutput(getExportSimOutputStringByIndex(newOutputFileIndex))
            setSelectedOutputBase64(getExportSimOutputBase64ByIndex(newOutputFileIndex))
            setSelectedFile(getExportSimOutputFileByIndex(newOutputFileIndex))
        }
    }

    function outputEmpty() {
        return context.exportSimOutput == null || context.exportSimOutput == "" || context.exportSimOutput == "204 - No Content"
    }

    function fileAmountToArray() {
        let enumValues: Array<DropdownItemProps> = [];

        if (!outputEmpty()) {
            try {
                let output = JSON.parse(context.exportSimOutput)

                for (let i = 0; i < output.resultFiles.length; i++) {
                    let name = output.resultFiles[i]?.fileName
                    enumValues.push({key: name, value: i, text: name});

                }
            } catch (e) {
                console.log("ERROR: ", e)
            }
        }
        console.log("[fileAmountToArray] - ", enumValues)
        return enumValues
    }

    function getFileName() {
        try {
            JSON.parse(context.exportSimOutput)
        } catch (e) {
            return "broken JSON"
        }
        if (context.exportSimOutput) {
            return JSON.parse(context.exportSimOutput).resultFiles[outputFileIndex]?.fileName
        } else {
            return "N/A"
        }

    }

    function prepareFileToDownload(file) {
        if (file.fileName?.endsWith(".pdf")) {
            const byteCharacters = atob(file.base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            return new Blob([byteArray], {type: "application/pdf"})
        }

        const byteCharacters = atob(file.base64Content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], {type: "application/octet-stream;"})
    }

    function drawButtons() {

        return <div style={{flexDirection: "row", display: "flex", justifyContent: "space-between"}}>
            <IfBox shouldShow={!outputEmpty()}>
                <Dropdown
                    selectOnBlur={false}
                    readOnly={false}
                    options={fileAmountToArray()}
                    selection
                    compact
                    value={outputFileIndex}
                    onChange={(evt, data) => selectOutputFile(data.value as number)}
                />
            </IfBox>

            <div>
                {!outputEmpty() && getExportSimOutputFileByIndex(outputFileIndex) &&
                    <DownloadButton
                        filename={getFileName()}
                        exportFile={prepareFileToDownload(getExportSimOutputFileByIndex(outputFileIndex))}
                    />
                }
                <Button
                    style={{paddingTop: "-9px"}}
                    disabled={outputEmpty()}
                    onClick={(evt) => setShowAddFileModal(true)}
                    loading={isConverting}>
                    Add files to FTP
                </Button>
                <Button disabled={outputEmpty()}
                        onClick={(evt) => setShowSaveAsTestModal(true)}
                        loading={isConverting}>
                    Save as Test
                </Button>
            </div>
        </div>
    }

    function drawEditor() {
        if (blob && selectedFile?.fileName.endsWith(".csv") == false) {
            let url = URL.createObjectURL(blob)
            console.log("FILE NAME: " + selectedFile.fileName)

            return <iframe
                id={"attachmentPreviewIFrame"}
                key={0}
                src={url}
                title={selectedOutput}
                scrolling={""}
                style={{flex: 1, minHeight: "100px"}}
                // height="900px"
                width={'100%'}
            />

        } else {
            return <AceEditor
                theme="monokai"
                mode="json"
                style={{flex: 1}}
                value={selectedOutput}
                placeholder={props.placeholder}
                width="100%"
                readOnly={false}
            />
        }
    }

    function addModals() {
        return <>
            <IfBox shouldShow={showAddFileModal}>
                <ExportSimAddFilesToFTPModal
                    isOpen={showAddFileModal}
                    onClose={(success, path) => {
                        if (success == true) {
                            notify.show('File has been added to ' + path, 'success', 3000, '#28f751');
                        }
                        setShowAddFileModal(false)
                    }
                    }
                />
            </IfBox>

            <IfBox shouldShow={showSaveAsTestModal}>
                <ExportSimSaveAsTestModal
                    isOpen={showSaveAsTestModal}
                    onClose={() => {
                        setShowSaveAsTestModal(false)
                    }}
                    eventType={null} // only used for importSim
                    expectedResult={context.exportSimOutput}
                    newStatus={null} // only used for importSim
                    transportOrder={transportOrder}
                    telematicState={null} // only used for importSim
                />

            </IfBox>
            <Notifications/>
        </>
    }

    console.log("[ExportSimOutput] props: ", props)


    return <>
        {drawButtons()}
        {drawEditor()}
        {addModals()}
    </>


}