import * as React from "react";
import {Accordion, Button, Grid, Header, Icon, Modal, Popup} from "semantic-ui-react";
import {
    CompanyConfiguration,
    CompanyConfigurationChange,
    CompanyHistory,
    UpdateCompanyConfigRequestWrapper
} from "../../../../../../generated";
import {ConfigContext} from "../../../../context/ConfigContext";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {IfBox} from "../../../../../style/if";
import {CustomDiff} from "../diff/CustomDiff";
import {updateConfig, workingConfig} from "../../ConfigSignal";

export interface RollbackConfigModalProps {
    isOpen: boolean
    onClose: (reload: boolean) => void
    currentConfig: string
    history: CompanyHistory
}

export interface RollbackConfigModalState {
    isSubmitting: boolean
    activeIndex: number
    patchSet: CompanyConfigurationChange[] | null
    followingHistoryConfig: CompanyConfiguration | null
    isLoading: boolean
    patchedConfig: string
}

export class RollbackConfigModal extends React.Component<RollbackConfigModalProps, RollbackConfigModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            activeIndex: 0,
            patchSet: null,
            followingHistoryConfig: null,
            isLoading: true,
            patchedConfig: "",
        }
    }

    handleClose = (reload) => {
        this.props.onClose(reload)
    }


    handleAccordionClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? 1 : index

        this.setState({activeIndex: newIndex})
    }

    drawOverride() {
        let oldText = workingConfig.value
        let newText = JSON.stringify(this.state.followingHistoryConfig, null, 4)
        return <p>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Column>your current working copy:</Grid.Column>
                <Grid.Column>this version:</Grid.Column>
            </Grid>

            <IfBox shouldShow={oldText == newText}>
                The desired version doesn't differ from your current working copy
            </IfBox>
            <IfBox shouldShow={oldText != newText}>
                <CustomDiff
                    oldJson={oldText}
                    newJson={newText}
                />

                <br/>
                <Popup content='overrides your current changes with this version' trigger={
                    <Button primary loading={this.state.isSubmitting} onClick={() => {
                        this.save(newText)
                    }}>Override</Button>
                }/>
            </IfBox>
        </p>
    }

    applyPatchSet(config, patchSet) {
        const jp = require('jsonpath');
        let conf = JSON.parse(config)

        if (patchSet == undefined) {
            return ""
        }
        patchSet.map(patch => {
            let value = patch.oldValue
            if (value) {
                if (typeof (value) == "string" && (value.startsWith("[") || value.startsWith("{"))) {
                    try {
                        value = JSON.parse(patch.value)
                    } catch (e) {
                        // probably no object
                    }
                }
            }
            const jsonPath = patch.path

            jp.value(conf, jsonPath, value)
        })

        return JSON.stringify(conf, null, 4)
    }

    async componentDidMount() {

        let auth = await backend.withTokenAuthHeader(authentication.token)
        let changesResponse = await backend.internalApi.fetchCompanyConfigurationChanges(this.context.companyId, this.props.history._id, auth)

        let followingConfig = await backend.internalApi.fetchFollowingCompanyConfigurationHistory(this.context.companyId, this.props.history._id, auth)

        let patchedConfig = this.applyPatchSet(workingConfig.value, changesResponse.changes)
        this.setState({
            patchSet: changesResponse.changes,
            followingHistoryConfig: followingConfig.followingConfig,
            patchedConfig: patchedConfig
        })
        this.setState({
            isLoading: false
        })
    }

    drawRebase() {

        let oldText = workingConfig.value
        let newText = this.state.patchedConfig


        return <p>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Column>your current working copy:</Grid.Column>
                <Grid.Column>changes of this version:</Grid.Column>
            </Grid>

            <IfBox shouldShow={oldText == newText}>
                The desired version doesn't differ from your current working copy
            </IfBox>
            <IfBox shouldShow={oldText != newText}>
                <CustomDiff
                    oldJson={oldText}
                    newJson={newText}
                />

                <br/>
                <Popup
                    content="Rebase only overrides the fields in your config, that have been changed for this version. It's patching the config with it's patchSet."
                    trigger={
                        <Button primary loading={this.state.isSubmitting} onClick={() => {
                            this.save(newText)
                        }}>Rebase</Button>
                    }/>

            </IfBox>
        </p>
    }




    async save(newConfig: string) {
        await updateConfig(newConfig, true, false)
        this.handleClose(true)
    }

    render() {
        const {activeIndex} = this.state

        if (this.props.history != null) {
            return <Modal
                open={this.props.isOpen}
                onClose={this.handleClose}
                size='fullscreen'>
                <Header icon='browser' content='Config Rollback'/>
                <Modal.Content>

                    <Grid padded="horizontally" stackable columns='equal' centered>
                        <Grid.Row>
                            <Grid.Column>Id</Grid.Column>
                            <Grid.Column>{this.props.history._id}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>Changed By</Grid.Column>
                            <Grid.Column>{this.props.history.changedBy}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>Timestamp</Grid.Column>
                            <Grid.Column>{this.props.history.timestamp}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>changeMethod</Grid.Column>
                            <Grid.Column>{this.props.history.changeMethod}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>comment</Grid.Column>
                            <Grid.Column
                                id='RollbackModalChangeComment'>{this.props.history.changeComment}</Grid.Column>
                        </Grid.Row>


                    </Grid>

                    <br/>
                    <IfBox shouldShow={this.state.isLoading}>
                        Loading...
                    </IfBox>
                    <IfBox shouldShow={!this.state.isLoading}>

                        <Accordion styled fluid>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={this.handleAccordionClick}
                            >
                                <Icon name='dropdown'/>
                                Override
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 0}>
                                {this.drawOverride()}
                            </Accordion.Content>
                            <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={this.handleAccordionClick}
                            >
                                <Icon name='dropdown'/>
                                Rebase
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 1}>
                                {this.drawRebase()}
                            </Accordion.Content>
                        </Accordion>

                    </IfBox>
                    <br/>
                    <Button id='RollbackModalCancelButton' onClick={() => this.handleClose(false)}>cancel</Button>

                </Modal.Content>
            </Modal>
        } else {
            return <p/>
        }
    }

}