import * as React from "react";
import {Button, Grid, Header, Input, Label, Modal} from 'semantic-ui-react';
import {authentication} from "../../../../../authentication";
import {backend} from "../../../../../xconvert-backend";
import {ConfigContext} from "../../../context/ConfigContext";
import { DiffWrapper } from "../../configChange/configEdit/diff/DiffWrapper";
import AceEditor from 'react-ace';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {CustomDiff} from "../../configChange/configEdit/diff/CustomDiff";

export interface TransportOrderSourceAndTargetViewModalProps {
    isOpen: boolean,
    onClose: () => void
    transportOrderId: string,
}

export interface TransportOrderSourceAndTargetViewModalState {
    transportOrderId: string | null
    sourceTo: string | null
    targetTo: string | null
    diff: string | null
    sourceCompanyId: string | null
    targetCompanyId: string | null
    sourceCompanyName: string | null
    targetCompanyName: string | null
    isLoadingTransportOrder: boolean
}

export class TransportOrderSourceAndTargetViewModal extends React.Component<TransportOrderSourceAndTargetViewModalProps, TransportOrderSourceAndTargetViewModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading TransportOrderSourceAndTargetViewModal")

        this.state = {
            transportOrderId: null,
            sourceTo: "",
            targetTo: "",
            diff: "",
            sourceCompanyId: null,
            targetCompanyId: null,
            sourceCompanyName: null,
            targetCompanyName: null,
            isLoadingTransportOrder: false,
        }
    }

    componentDidMount() {
        this.setState({transportOrderId: this.props.transportOrderId})
        this.handleLoadTransportOrder()
    }

    handleClose = () => {
        this.props.onClose()
    }

    async handleLoadTransportOrder() {
        this.setState({ isLoadingTransportOrder: true })
        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = await backend.internalApi.fetchTransportOrderByIdSourceAndTargetView(this.state.transportOrderId, auth)
        let sourceTo = response.sourceTo
        let targetTo = response.targetTo
        let diff = response.diff

        this.setState({
            sourceTo: JSON.stringify(sourceTo, null, 4),
            targetTo: JSON.stringify(targetTo, null, 4),
            diff: JSON.stringify(diff, null, 4),
            sourceCompanyId: sourceTo.companyId,
            targetCompanyId: sourceTo.targetCompanyId,
            sourceCompanyName: response.sourceCompanyName,
            targetCompanyName: response.targetCompanyName,
            isLoadingTransportOrder: false,
        })
    }

    sourceColor() {
        if(this.state.sourceCompanyId == this.context.companyId) {
            return "green"
        } else {
            return "grey"
        }
    }

    targetColor() {
        if(this.state.targetCompanyId == this.context.companyId) {
            return "green"
        } else {
            return "grey"
        }
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            onMount={() => {
            }}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='TransportOrder Source- & Target-View'/>
            <Modal.Content>
                <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Row>
            <Input placeholder='TransportOrderId' name="transportOrderId" value={this.state.transportOrderId} onChange={evt => this.setState({transportOrderId: evt.target.value })} />
                    <Button onClick={evt => this.handleLoadTransportOrder()}>load by Id</Button>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Label>Source Company View</Label>
                        <AceEditor
                            theme="monokai"
                            mode="json"
                            value={this.state.sourceTo}
                            placeholder={'Nothing found'}
                            width="100%"
                            height="850px"
                            readOnly={true}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Label>Diff</Label>
                        <CustomDiff
                            oldJson={this.state.sourceTo}
                            newJson={this.state.targetTo}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Label>Target Company View</Label>
                        <AceEditor
                            theme="monokai"
                            mode="json"
                            value={this.state.targetTo}
                            placeholder={'Nothing found'}
                            width="100%"
                            height="850px"
                            readOnly={true}
                        />
                    </Grid.Column>

                </Grid.Row>
            </Grid>
            </Modal.Content>
        </Modal>
    }


}