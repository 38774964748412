import * as React from "react";
import {useEffect} from "react";
import {Dropdown, Header, Icon, Loader, Modal} from "semantic-ui-react";
import * as JSZip from "jszip";
import {DownloadButton} from "../../../../util/react-download-link/DownloadButton";
import {FileViewer} from "../../ftp-explorer/FileViewer";

export interface PreviewModalProps {
    isOpen: boolean;
    onClose: () => void;
    zipFile: any;
    year: number;
    month: number;
}

interface UnzippedFile {
    name: string;
    blob: Blob;
    file: File;
    buffer: ArrayBuffer;
}

export function PreviewModal(props: PreviewModalProps) {
    const [unzipped, setUnzipped] = React.useState(false);
    const [unzippedFiles, setUnzippedFiles] = React.useState<UnzippedFile[]>([]);
    const [selectedFile, setSelectedFile] = React.useState<UnzippedFile | null>(null);

    useEffect(() => {
        if (props.zipFile) {
            unzip();
        }
    }, [props.zipFile]);

    async function unzip() {
        console.log('unzip');
        const zip = new JSZip();
        const unzipped = await zip.loadAsync(props.zipFile);
        const files = [];

        for (const filename in unzipped.files) {
            const file = unzipped.files[filename];
            if (!file.dir) {
                const content = await file.async("blob");
                files.push({name: filename, blob: content});
            }
        }

        console.log('unziped files', files);
        let completeFiles: UnzippedFile[] = []
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let buffer = await file.blob.arrayBuffer();
            let newFile = {
                name: file.name,
                blob: file.blob,
                file: file,
                buffer: buffer
            }
            completeFiles.push(newFile);
        }
        setUnzippedFiles(completeFiles);
        setSelectedFile(completeFiles[0]);
        setUnzipped(true);
    }

    function dropDownOptions() {
        return unzippedFiles.map((uf: UnzippedFile, index) => {
            return {
                key: index,
                value: uf.name,
                text: uf.name,
            };
        });
    }

    function displayDropDown() {
        return <Dropdown
            selectOnBlur={false}
            style={{width: "500px"}}
            placeholder={"Select a Collection"}
            clearable
            options={dropDownOptions()}
            selection
            value={selectedFile.name}
            onChange={(evt, data) => setSelectedFile(fileByName(data.value.toString()))}
        />
    }

    function fileByName(name: string) {
        return unzippedFiles.find(file => file.name === name);
    }

    function displayFile() {
        return <FileViewer
            fileBuffer={selectedFile.buffer}
            fileName={selectedFile.name}
            displayName={false}
        />
    }

    function displayDownloadButton() {

        if (!props.zipFile) {
            return <></>
        }
        return <div
            style={{
                rowGap: "20px",
                display: "flex",
                columnGap: "20px",
                flexDirection: "row",
                justifyContent: "flex-start",
                paddingTop: 5
            }}
        >

            <DownloadButton
                filename={selectedFile.name}
                exportFile={selectedFile.buffer}
                label={"Download single file"}
                icon={<Icon name={"file excel outline"}/>}
            />
            <DownloadButton
                filename={"billing_" + props.year + "-" + props.month + ".zip"}
                exportFile={props.zipFile}
                label={"Download zip"}
                icon={<Icon name={"zip"}/>}
            />
        </div>
    }


    function renderLoadedContent() {
        return <>
            <div>
                {displayDropDown()}
                {displayFile()}
            </div>
            {displayDownloadButton()}
        </>
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={props.onClose}
            closeIcon
            dimmer="inverted"
            size="fullscreen"
        >
            <Header icon="browser" content="Preview"/>
            <Modal.Content>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '85vh',
                    overflow: 'auto',
                    justifyContent: 'space-between',
                }}>
                    {!unzipped && <Loader active={!unzipped}/>}
                    {unzipped && renderLoadedContent()}
                </div>
            </Modal.Content>
        </Modal>
    );
}