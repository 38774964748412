import * as React from "react";
import {Button, Grid, Header, Icon, Input, Message, Modal} from "semantic-ui-react";
import {
    CompanyConfiguration,
    TransportOrderConfigTest,
    TransportOrderConfigTestResult,
    TransportOrderConfigTestUpdate,
    UpdateTransportOrderConfigTestsRequest
} from "../../../../../generated";
import {ConfigContext} from "../../../context/ConfigContext";
import {IfBox} from "../../../../style/if";
import {CompanyConfig} from "../../configChange/configEdit/CompanyConfig";
import {TestCompareConfigModal} from "./TestCompareConfigModal";
import {UpdateTestDiffPickerModal} from "./UpdateTestDiffPickerModal";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {CustomDiff} from "../../configChange/configEdit/diff/CustomDiff";

export interface TestResultModalProps {
    isOpen: boolean
    onClose: (success: boolean) => void
    testResult: TransportOrderConfigTestResult
    lastSuccessConfig: CompanyConfiguration
    test: TransportOrderConfigTest
}

export interface TestResultModalState {
    testResultTestId: String,
    testResultMessage: String,
    testResultFoundDifferences: string[] | null
    testResultSuccess: boolean,
    testResultActual: string,
    isLoading: boolean,
    expected: string,
    showConfigCompare: boolean,
    showUpdateExpected: boolean,
    fieldsToIgnore: string[],
    responseMessage: string | null
    responseSuccess: boolean

}

export class TestResultModal extends React.Component<TestResultModalProps, TestResultModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            testResultTestId: null,
            testResultMessage: null,
            testResultFoundDifferences: [],
            testResultSuccess: null,
            testResultActual: null,
            isLoading: true,
            expected: null,
            showConfigCompare: false,
            showUpdateExpected: false,
            fieldsToIgnore: [],
            responseMessage: null,
            responseSuccess: false
        }
    }

    showResponseInfo() {
        if (this.state.responseMessage == null) {
            return <p/>
        }
        if (this.state.responseSuccess) {
            return <Message key={"test"}
                            success
                            content={this.state.responseMessage}
            />
        } else {
            return <Message key={"test"}
                            error
                            content={this.state.responseMessage}
            />
        }

    }

    testResultContentString(result: string): string {
        if (this.props.test.type == TransportOrderConfigTest.TypeEnum.EXPORT) {
            let object = JSON.parse(result)
            return object.map(it => this.filesBase64ContentToString(it)).join("\n")
        } else {
            return result
        }
    }

    filesBase64ContentToString(file) {
        if (file) {
            return atob(file.base64Content)
        } else {
            return null
        }
    }

    componentDidMount() {
        let result = this.props.testResult
        if (result != null) {
            let fieldsToIgnore = this.props.test.expectedResult.fieldsToIgnore
            if (fieldsToIgnore == null) {
                fieldsToIgnore = []
            }
            this.setState({
                testResultTestId: result.transportOrderConfigTestId,
                testResultMessage: result.errorMessage,
                testResultFoundDifferences: this.cleanFoundDifferences(result.foundDifferences),
                testResultSuccess: result.success,
                testResultActual: this.testResultContentString(result.fullResult),
                isLoading: false,
                expected: this.testResultContentString(result.expected),
                fieldsToIgnore: fieldsToIgnore

            })
        }
    }

    cleanFoundDifferences(foundDifferences: string[]) {
        let clean = []
        let regex = /(\[[0-9]*\]\.)/gm
        if (foundDifferences != null) {
            foundDifferences.map(d => {
                let c = d.replace(regex, "[*].")
                if (clean.includes(c) == false) {
                    clean.push(c)
                }
            })
            return clean
        }
        return []
    }

    handleClose = () => {
        this.props.onClose(false)
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    checkForProps(i: number) {
        if (this.state.isLoading && i < 50) {
            this.sleep(100).then(() => {
                console.log("checking for props: ", i)
                this.checkForProps(i + 1)
            })
        } else {
            console.log("found props")
        }
    }

    render() {
        this.checkForProps(1)
        let title = "TestResult for Test ID: " + this.state.testResultTestId
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="inverted"
            size='fullscreen'>
            <Header icon='browser' content={title}/>
            <Modal.Content>

                {this.showResponseInfo()}
                {this.drawSuccessIcon()}
                {this.state.testResultMessage}
                {this.drawDiffAndIgnoreFields()}
                {this.drawDiff()}
                <Button disabled={this.state.testResultSuccess}
                        onClick={() => this.setState({showConfigCompare: true})}>compare config with last
                    successful</Button>
                <Button onClick={(evt) => this.handleClose()}>Close</Button>
                <Button disabled={this.state.testResultSuccess}
                        onClick={(evt) => this.setState({showUpdateExpected: true})}>update expected</Button>
                <IfBox shouldShow={this.state.showConfigCompare}>
                    <TestCompareConfigModal
                        isOpen={this.state.showConfigCompare}
                        onClose={() => this.onCloseCompareModal()}
                        configToCompare={JSON.stringify(this.props.lastSuccessConfig)}
                    />
                </IfBox>
                <IfBox shouldShow={this.state.showUpdateExpected}>
                    <UpdateTestDiffPickerModal
                        isOpen={this.state.showUpdateExpected}
                        onClose={(saved) => this.onCloseUpdateModal(saved)}
                        expectedResult={this.state.expected}
                        newResult={this.state.testResultActual}
                        test={this.props.test}
                    />
                </IfBox>

            </Modal.Content>
        </Modal>
    }

    onCloseUpdateModal(saved) {
        this.setState({showUpdateExpected: false})
        if (saved) {
            this.handleClose()
        }
    }

    onCloseCompareModal() {
        this.setState({showConfigCompare: false})
    }

    drawDiffAndIgnoreFields() {
        return <Grid padded="horizontally" stackable columns='equal' centered>
            <Grid.Column>
                <ul>
                    {this.state.testResultFoundDifferences.map((value, index) => {
                        if (value != "" && value != null) {
                            return <li key={index}>{value}
                                <IfBox shouldShow={!this.state.fieldsToIgnore.includes(value)}>
                                    <Button icon onClick={(evt) => {

                                        this.addFieldToIgnore(value)
                                    }}>
                                        <Icon name='arrow right'/>
                                    </Button>
                                </IfBox>
                            </li>
                        }
                    })}
                </ul>
            </Grid.Column>
            <Grid.Column>
                {this.drawFieldsToIgnore()}
            </Grid.Column>

        </Grid>
    }

    drawFieldsToIgnore() {
        let index = -1
        return <p>
            <h3>Fields to ignore: </h3>
            {
                this.state.fieldsToIgnore?.map(f => {
                    index++
                    return this.drawSingleFieldToIgnore(f, index)

                })
            }
            <Button icon onClick={() => this.handleSaveFieldsToIgnore()}><Icon name='save'/></Button>
            <Button icon onClick={() => this.handleChangeOnFieldToIgnore("", index + 1)}><Icon name='plus'/></Button>
        </p>
    }

    handleChangeOnFieldToIgnore(newValue, index) {
        let fields = this.state.fieldsToIgnore
        fields[index] = newValue
        this.setState({fieldsToIgnore: fields})
    }

    drawSingleFieldToIgnore(field, index) {
        return <p>
            <Input value={field} onChange={evt => this.handleChangeOnFieldToIgnore(evt.target.value, index)}/>
            <Button icon onClick={() => this.deleteFieldToIgnore(index)}><Icon name='trash'/></Button>
        </p>
    }

    deleteFieldToIgnore(index) {
        let fields = this.state.fieldsToIgnore
        fields.splice(index, 1)
        console.log("FIELDS:", fields)
        this.setState({fieldsToIgnore: fields})
    }

    async handleSaveFieldsToIgnore() {
        console.log("saving changes")
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let test = this.props.test

        let update = {} as TransportOrderConfigTestUpdate
        update.testId = test._id
        update.testName = test.name
        update.input = test.input
        update.expectedResult = test.expectedResult
        update.expectedResult.fieldsToIgnore = this.state.fieldsToIgnore

        let request = {} as UpdateTransportOrderConfigTestsRequest
        request.updates = []
        request.updates.push(update)

        let response = await backend.internalApi.updateTransportOrderConfigTests(request, auth)
        let message: string
        if (response.failedUpdateTestIds.length == 0) {

            message = 'updated fields to ignore'
        } else {
            message = "ERROR: unable to change fields to ignore. Please check logs."

        }
        this.setState({responseSuccess: response.failedUpdateTestIds.length == 0, responseMessage: message})
    }

    addFieldToIgnore(newPath) {
        this.handleChangeOnFieldToIgnore(newPath, this.state.fieldsToIgnore.length)
    }

    drawSuccessIcon() {
        if (this.state.testResultSuccess) {
            return <p>Success: <Icon name='check' color='green' size='large'/></p>
        } else {
            return <p>Success: <Icon name='x' color='red' size='large'/></p>
        }
    }

    drawDiff() {
        if (this.state.testResultActual) {
            return <CustomDiff
                oldJson={this.state.expected}
                newJson={this.state.testResultActual}
            />
        } else {
            return <p>null</p>
        }
    }

}