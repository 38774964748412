import * as React from "react";
import {Accordion, Button, Grid, Header, Icon, Label, Modal} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {Queue, QueueWithEvent} from "../../../../../generated";
import AceEditor from "react-ace";
import {LogView} from "../../logView/LogView";

export interface TestSuppressionScriptModalSingleProps {

    queue: Queue

}

export interface TestSuppressionScriptModalSingleState {
    event: string
    output: string
    activeIndex: number,
    xRequestId: string
}

export class TestSuppressionScriptModalSingle extends React.Component
    <TestSuppressionScriptModalSingleProps, TestSuppressionScriptModalSingleState> {

    constructor(props) {
        super(props)
        this.state = {
            event: "",
            output: "",
            activeIndex: -1,
            xRequestId: null
        }
    }

    componentDidMount() {

    }

    async test() {
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let request = {} as QueueWithEvent
        request.queue = this.props.queue
        request.events = []
        request.events.push(JSON.parse(this.state.event))
        //let response = await backend.queueApi.testSuppressionScriptTest(request, auth)

        let response = await fetch('/api/queues/testSuppressionScriptTest', {
            method: 'POST',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(request)
        })

        let resp = await response.json()

        this.setState({
            output: JSON.stringify(resp, null, 4),
            xRequestId: response.headers.get("x-request-id")
        })
    }

    handleChange(value) {
        this.setState({event: value})
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({activeIndex: newIndex})
    }

    drawSuppression() {
        if(this.state.output) {
            let output = JSON.parse(this.state.output)
            let suppressed = output.events[0].suppressed
            if(suppressed == true) {
                return <a style={{color: "green", fontSize: "large"}}> <Icon name={"check"} color={"green"}/>Suppressed!</a>
            } else {
                return <a style={{color: "red", fontSize: "large"}}> <Icon name={"x"} color={"red"}/> NOT suppressed!</a>

            }
        }
        return <a style={{color: "black", fontSize: "large"}}>  unknown</a>
    }

    render() {
        const {activeIndex} = this.state

        return <>
                <Grid padded="horizontally" stackable columns='equal' centered>
                    <Grid.Column>
                        <Grid.Row>
                            <Label>Input QueuedEvent</Label>
                        </Grid.Row>
                        <Grid.Row>
                            <AceEditor
                                theme="monokai"
                                mode='groovy'
                                value={this.state.event}
                                width="100%"
                                height="800px"
                                readOnly={false}
                                onChange={(value) => this.handleChange(value)}
                            />

                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid.Row>
                            <Label size={"large"}>Suppressed: </Label>

                            {this.drawSuppression()}
                        </Grid.Row>
                        <Grid.Row>
                            <Accordion styled fluid>
                                <Accordion.Title
                                    active={activeIndex === 0}
                                    index={0}
                                    onClick={this.handleClick}
                                >
                                    <Icon name='dropdown'/>
                                    Logs
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 0}>
                                    <LogView query={this.state.xRequestId} origin={"suppressionScriptModal"}/>
                                </Accordion.Content>
                            </Accordion>
                        </Grid.Row>
                        <Grid.Row>
                            <Accordion styled fluid>
                                <Accordion.Title
                                    active={activeIndex === 1}
                                    index={1}
                                    onClick={this.handleClick}
                                >
                                    <Icon name='dropdown'/>
                                    Output QueuedEvent
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 1}>
                                    <AceEditor
                                        theme="monokai"
                                        mode='groovy'
                                        value={this.state.output}
                                        width="100%"
                                        height="800px"
                                        readOnly={true}
                                    />
                                </Accordion.Content>
                            </Accordion>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
                <Button onClick={() => this.test()}>Test</Button>

            </>

    }

}

