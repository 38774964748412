import * as React from "react";
import {Segment} from "semantic-ui-react";
import ReactTable from "react-table";
import {formatDate} from "../../../../../../format";
import {ConfigContext} from "../../../../context/ConfigContext";
import {Origin, TransportOrder, TransportOrderChange} from "../../../../../../generated";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {IfBox} from "../../../../../style/if";
import {TransportOrderHistoryModal} from "../../modals/TransportOrderHistoryModal";
import {TransportOrderRollbackModal_OLD} from "./TransportOrderRollbackModal_OLD";

export interface TransportOrderHistoryOldTableProps {
    reload: (reload: boolean) => void
    transportOrder: TransportOrder
}

export interface TransportOrderHistoryOldTableState {
    isLoading: boolean,
    history: TransportOrderChange[] | null,
    selectedHistory: TransportOrderChange | null,
    origin: Origin | null,
    showChanges: boolean,
    showRollBackView: boolean,

    //Table
    take: number,
    page: number,
    sortBy: "TIMESTAMP" | "TYPE" | "NONE",
    sortDirection: "DESC" | "ASC",

    count: number
}


export class TransportOrderHistoryOldTable extends React.Component<TransportOrderHistoryOldTableProps, TransportOrderHistoryOldTableState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading HistoryConfig")

        this.state = {
            isLoading: true,
            history: null,
            selectedHistory: null,
            origin: null,
            showChanges: false,
            showRollBackView: false,

            //Table
            take: 10,
            page: 1,
            sortBy: "TIMESTAMP",
            sortDirection: "DESC",

            count: -1
        }
    }

    async componentDidMount() {

        await this.fetchHistory()
        console.log("THE COMPONENT DID MOUNT:", this.state.history)

    }

    componentDidUpdate(prevProps: Readonly<TransportOrderHistoryOldTableProps>, prevState: Readonly<TransportOrderHistoryOldTableState>, snapshot?: any) {
            if(prevProps.transportOrder?._id != this.props.transportOrder?._id) {
                this.fetchHistory()
            }
    }

    async fetchHistory(take = this.state.take, page = this.state.page) {
        console.log("fetchHistory of TransportOrder")
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let history = await backend.transportOrderApi.fetchTransportOrderHistory(this.props.transportOrder._id, auth)

        let count = history.changes?.length

        if(count == undefined) {
            count = 0
        }

        console.log(history)
        this.setState({
            history: history.changes,
            count: count,
            isLoading: false,
            take: take,
            page: page
        })

    }

    async changeSort(sortBy: 'TIMESTAMP' | 'TYPE' | 'NONE') {
        console.log("changing sort")
        this.setState({isLoading: true})

        if (sortBy == 'NONE') {
            return
        }

        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({sortDirection: direction})
        } else {
            //change sortBy and reset direction
            this.setState({sortBy: sortBy, sortDirection: "ASC"})
        }

        await this.fetchHistory()
        this.setState({isLoading: false})

    }

    changePage(pageIndex: number) {
        this.setState({isLoading: true})
        this.fetchHistory(this.state.take, pageIndex + 1).then()
    }

    changePageSize(newSize: number) {
        this.setState({isLoading: true})
        this.fetchHistory(newSize, this.state.page).then()
    }

    render() {
        if (this.state.history == null) {
            return <p>No histories found</p>
        } else {
            const history: any[] | null = this.state.history
            const columns = [{
                id: 'TIMESTAMP',
                Header: 'timestamp',
                width: 150,
                accessor: (d: any) => d.timestamp,
                Cell: (d: any) => {
                    return <span>{formatDate(d.value)}</span>
                },
                sortMethod: (a, b) => {
                    let a1 = new Date(a).getTime();
                    let b1 = new Date(b).getTime();
                    if (a1 < b1) {
                        return 1;
                    } else if (a1 > b1) {
                        return -1;
                    } else {
                        return 0;
                    }
                },
                sortField: 'TIMESTAMP'

            }, {
                id: 'TYPE',
                Header: 'type',
                width: 200,
                accessor: (d: any) => d.type,
                sortField: 'TYPE'
            }, {
                id: 'CHANGED_BY',
                Header: 'Changed By',
                width: 200,
                accessor: (d: any) => d.origin.userName,
                sortField: 'CHANGED_BY'
            }]

            return <>

                <ReactTable
                    data={history}
                    pages={Math.ceil(this.state.count / this.state.take)}
                    columns={columns}
                    sorted={[
                        {
                            id: 'TIMESTAMP',
                            desc: false
                        }
                    ]}
                    onSortedChange={(newSorted, column) => {
                        this.changeSort(column.sortField).then()
                    }}

                    defaultPageSize={this.state.take}
                    className="-striped -highlight"
                    loading={this.state.isLoading}
                    getTdProps={(state, rowInfo) => {
                        return {
                            onClick: () => {
                                if (rowInfo) {
                                    console.log("Opening History-Entry with id", rowInfo.row)
                                    this.showChanges(rowInfo.row._original).then()
                                }
                            }
                        };
                    }}
                    style={{cursor: "pointer"}}
                    onPageChange={(pageIndex) => this.changePage(pageIndex)}
                    onPageSizeChange={(pageSize) => this.changePageSize(pageSize)}

                />

                <TransportOrderHistoryModal
                    isOpen={this.state.showChanges}
                    onClose={async (openRollback: boolean) => {
                        if (openRollback) {
                            this.setState({showChanges: false, showRollBackView: true})
                        } else {
                            this.setState({showChanges: false})
                        }

                    }}
                    change={this.state.selectedHistory}
                    followingConfig={this.state.origin}
                    transportOrder={this.props.transportOrder}
                    previousChange={null}
                />
                <IfBox shouldShow={this.state.showRollBackView}>
                    <TransportOrderRollbackModal_OLD
                        isOpen={this.state.showRollBackView}
                        onClose={async (reload: boolean) => {
                            this.setState({showRollBackView: false})

                            if (reload) {
                                this.setState({selectedHistory: null})
                                await this.fetchHistory()
                            }

                        }}
                        change={this.state.selectedHistory}
                        followingConfig={this.state.origin}
                        transportOrder={this.props.transportOrder}
                    />
                </IfBox>
            </>
        }
    }

    async showChanges(row) {
        this.setState({showChanges: true, selectedHistory: row, origin: row.origin})
    }

}
