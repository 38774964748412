import * as React from "react";
import {useContext, useEffect} from "react";
import {Dropdown, Menu} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {ExportSimPrepareSide} from "../../converter/ExportSim/ExportSimPrepareSide";
import {QueuedEvent} from "../../../../../generated";
import {ExportSimOutputSide} from "../../converter/ExportSim/output/ExportSimOutputSide";
import ImportSimTabMenu from "../../converter/ImportSim/ImportSimTabMenu";
import {ScriptModifier} from "../configEdit/ScriptModifier";
import {Signal, signal} from "@preact/signals-react";
import {ImportSimOutputWithFileSelection} from "../../converter/ImportSim/ImportSimOutputWithFileSelection";
import {updateConfig, workingConfig} from "../ConfigSignal";
import {Splitter} from "../../../../util/table-splitter-ui/src/components/Splitters/Splitter";
import {ChangeableSplitter} from "../../../../util/table-splitter-ui/src/components/Splitters/ChangableSplitter";


export const currentSelectedXRequestId: Signal<string> = signal("")

export interface ScriptConverterPlaygroundProps {
    scriptPath: string
}

export function ScriptConverterPlayground(props: React.PropsWithChildren<ScriptConverterPlaygroundProps>) {
    const context = useContext(ConfigContext)

    const [auth, setAuth] = React.useState(null)
    const [selectedConverterSim, setSelectedConverterSim] = React.useState("Export")

    const [response, setResponse] = React.useState(null)
    const [queuedEvent, setQueuedEvent] = React.useState(null)
    const [config, setConfig] = React.useState(null)
    const [encoding, setEncoding] = React.useState(null)

    const [script, setScript] = React.useState(null)
    const [scriptPath, setScriptPath] = React.useState(props.scriptPath)
    const [scriptPaths, setScriptPaths] = React.useState([])

    useEffect(() => {
        console.log("ScriptConverterPlayground useEffect, scriptPath: ", scriptPath)

        if (scriptPath == "" || scriptPath == undefined) {
            console.log("ScriptConverterPlayground useEffect, scriptPath is empty or undefined")

            setScriptPath(context.scriptConverterPlaygroundScriptPath)
            loadScriptFromContext(context.scriptConverterPlaygroundScriptPath)
        }

        console.log("ScriptConverterPlayground useEffect, scriptPath is set by context: ", scriptPath)

        if (scriptPath != "" && scriptPath != undefined) {
            // change in currentJSON
            loadScriptFromContext(scriptPath)

        }

        const getAuth = async () => {
            let auth = (await backend.withTokenAuthHeader(authentication.token))
            setAuth(auth)
        }
        getAuth().then(() => {
            console.log("got auth")
        })

        loadOpenInModalPaths()

    }, [])

    async function loadOpenInModalPaths() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let openInModalPaths = (await backend.internalApi.fetchCmNavTreeTextModalList(auth)).pathList
        setScriptPaths(openInModalPaths)
    }

    function loadScriptFromContext(path: string) {
        if (path == undefined) {
            return
        }
        // change in currentJSON
        const jp = require('jsonpath');
        const jsonPath = path.replace(".CompanyConfiguration", "");
        console.log("JSONPATH : ", jsonPath)

        let config = JSON.parse(workingConfig.value)
        var s = jp.value(config, jsonPath)
        console.log("ScriptConverterPlayground useEffect, script: ", s)

        setScript(s)
    }

    function converterSimSelector() {
        return <div style={{paddingBottom: 15, height: 42}}>
            <Menu>
                <Menu.Item
                    name='Export'
                    active={selectedConverterSim === 'Export'}
                    onClick={() => setSelectedConverterSim('Export')}
                >
                    Export
                </Menu.Item>
                <Menu.Item
                    name='Import'
                    active={selectedConverterSim === 'Import'}
                    onClick={() => setSelectedConverterSim('Import')}
                >
                    Import
                </Menu.Item>
            </Menu>
        </div>
    }

    function beforeSend(
        xRequestId: string,
    ) {
        currentSelectedXRequestId.value = xRequestId
    }

    function onSend(
        response: any,
        queuedEvent: QueuedEvent,
        respondedConfig: any,
        encoding: string,
    ) {
        console.log("onSend")
        setResponse(response)
        setQueuedEvent(JSON.stringify(queuedEvent, null, 4))
        setConfig(respondedConfig)
        setEncoding(encoding)
    }

    function drawExportSimulatorInput() {
        return <ExportSimPrepareSide
            auth={auth}
            beforeSend={beforeSend}
            onSend={onSend}
        />
    }

    function drawImportSimulatorInput() {
        return <ImportSimTabMenu
            context={context}
        />
    }

    function drawLeft() {
        return <div
            style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100px",
                flex: "1",
            }}
            //className={"flexEverythingInside"}
        >
            {converterSimSelector()}
            {selectedConverterSim === "Export" && drawExportSimulatorInput()}
            {selectedConverterSim === "Import" && drawImportSimulatorInput()}
        </div>
    }

    function saveNewScript(newScript) {
        //console.log("saveNewScript: ", newScript)
        const jp = require('jsonpath');
        let config = JSON.parse(workingConfig.value)
        jp.value(config, scriptPath, newScript)
        updateConfig(
            JSON.stringify(config, null, 4),
            true,
            false
        ).then(r => {
        })
    }

    function drawRightTop() {
        return <>

            <Dropdown
                style={{width: "100%", zIndex: 11}}
                //placeholder={"Select a Script"}
                //search
                selectOnBlur={false}
                clearable
                options={scriptPaths.map((value) => {
                    return {
                        key: value,
                        value: value,
                        text: value
                    }
                })}
                selection
                value={scriptPath}
                onChange={(evt, data) => {
                    //console.log("TEST onChange: ", data.value)

                    loadScriptFromContext(data.value.toString())
                    setScriptPath(data.value.toString())
                }
                }/>

            <ScriptModifier
                key="ScriptModifierFromScriptConverterPlayground"
                defaultDescription={null} //TODO: implement
                fieldDescriptionEnabled={true}
                readOnly={false}
                path={scriptPath}
                dispatcher={saveNewScript}
                script={script}
                saveKeyListener={null}
                directSave={true}
            />
        </>
    }

    function drawExportSimOutput() {
        return <ExportSimOutputSide
            queuedEvent={queuedEvent}
            xRequestId={currentSelectedXRequestId.value}
            response={response}
            config={config}
            encoding={encoding}
        />
    }

    function drawRightBottom() {
        return <>
            {selectedConverterSim === "Export" && drawExportSimOutput()}
            {selectedConverterSim === "Import" && <ImportSimOutputWithFileSelection/>}
        </>
    }

    function drawRight() {
        return <ChangeableSplitter
            name={"scriptConverterPlaygroundRight"}
            topOrLeft={drawRightTop()}
            bottomOrRight={drawRightBottom()}
        />
    }

    return <>
        <Splitter
            name={"scriptConverterPlaygroundSplitter"}
            left={drawLeft()}
            right={drawRight()}
        />
    </>
}