import * as React from "react";
import {Grid} from "semantic-ui-react";
import AceEditor from "react-ace";
import {VersionReportFunction} from "./VersionReportFunction";
import {ProxMoxStatusPage} from "./ProxMoxStatusPage";



export interface HealthAndVersionReportPageProps {

}

export class HealthAndVersionReportPage extends React.Component<HealthAndVersionReportPageProps, any> {

    constructor(props) {
        super(props)
        this.state = {
            displayText: ""
        }
    }


    render() {
        return <Grid padded="horizontally" stackable columns='equal' centered>
            <Grid.Row>
            <Grid.Column>
                <VersionReportFunction
                    lightView={false}
                    displayDetails={(details: string) => this.setState({displayText: details})}
                />
            </Grid.Column>
            <Grid.Column>
                <AceEditor
                    theme="monokai"
                    mode='groovy'
                    value={this.state.displayText}
                    width="100%"
                    height="800px"
                    readOnly={true}
                />
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <ProxMoxStatusPage/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    }

}


