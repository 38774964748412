import * as React from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {DiffWrapper} from "../../../configChange/configEdit/diff/DiffWrapper";
import {CustomDiff} from "../../../configChange/configEdit/diff/CustomDiff";


export interface UnsavedChangesDetectedModalProps {
    isOpen: boolean
    onClose: () => void
    newObject: any
    oldObject: any
    dispatcher: () => void
}

export interface UnsavedChangesDetectedModalState {
    isLoading: boolean
}

export class UnsavedChangesDetectedModal extends React.Component<UnsavedChangesDetectedModalProps, UnsavedChangesDetectedModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            size='fullscreen'>
            <Header icon='browser' content='Unsaved changes detected'/>
            <Modal.Content>

                <CustomDiff
                    oldJson={this.cleanBackslashR(JSON.stringify(this.props.oldObject,null,4))}
                    newJson={this.cleanBackslashR(JSON.stringify(this.props.newObject,null,4))}>
                </CustomDiff>

                <br/>
                {this.drawButtons()}


            </Modal.Content>
        </Modal>

    }

    drawButtons() {
        return <span>
                <Button id='DiscardButton' onClick={() => this.discard()}>Discard</Button>
                <Button id='CancelButton' onClick={() => this.handleClose()}>Cancel</Button>

        </span>
    }
    discard(){
        this.props.dispatcher()
        this.handleClose()
    }


    cleanBackslashR(str: string): string {
        if (str) {
            return str.split("\\r").join("")
        } else {
            return ""
        }
    }

}

