import * as React from 'react';
import {Button, Header, Icon, Modal} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";


export interface CompanyArchiveModalProps {
    isOpen: boolean,
    onClose: (success: boolean | null) => void,
}

export function CompanyArchiveModal(props: React.PropsWithChildren<CompanyArchiveModalProps>) {

    async function archive() {
        const auth = backend.withTokenAuthHeader(authentication.token)

        let response = await backend.companyApi.archiveCompany(auth)
        props.onClose(response != null)
    }

    return <Modal
        open={props.isOpen}
        onClose={() => props.onClose(null)}
        dimmer="blurring"
        size='small'>
        <Header icon='archive' content='Archive Company'/>
        <Modal.Content>

            <p>You are about to archive this company!</p>
            <p>Are you sure?</p>

            <Button
                icon
                onClick={() => archive()}>
                <Icon
                    name='archive'/>
                archive
            </Button>

            <Button
                icon
                onClick={() => props.onClose(null)}>
                <Icon
                    name='cancel'/>
                Cancel
            </Button>

        </Modal.Content>
    </Modal>

}