import * as React from "react";
import {Button, Icon, Segment} from "semantic-ui-react";
import ReactTable from "react-table";
import {formatDate} from "../../../../../../format";
import {ConfigContext} from "../../../../context/ConfigContext";
import {CompanyConfigurationDraft, FetchAllConfigDraftsResponse} from "../../../../../../generated";
import {DraftModal} from "../modals/DraftModal";
import {IfBox} from "../../../../../style/if";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {ShareDraftModal} from "../modals/ShareDraftModal";

export interface DraftTableProps {
    reloadFunction: () => void
}

export class DraftTable extends React.Component<DraftTableProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading DraftConfig")

        this.state = {
            isLoading: false,
            draft: null,
            take: 10,
            page: 0,
            pages: -1,
            drafts: [],
            showShareDraftModal: false,
            draftToShare: null,
            ownersFixed: false
        }
    }

    async componentDidMount() {
        await this.fetchDrafts()
        await this.fixOwners()
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    async fixOwners() {
        let newDrafts = []
        await this.state.drafts.map(async draft => {
            draft.userId = await this.sharedBy(draft)
            newDrafts.push(draft)
        })
        let counter = 0
        while (newDrafts.length < this.state.drafts.length && counter < 30) {
            await this.sleep(100)
            counter++
        }
        this.setState({drafts: newDrafts, ownersFixed: true})

    }

    async fetchDrafts() {
        try {
            let auth = await backend.withTokenAuthHeader(authentication.token)
            let resp: FetchAllConfigDraftsResponse = (await backend.internalApi.fetchAllConfigDrafts(this.context.companyId, auth))
            let drafts = resp.drafts
            console.log(drafts)
            this.setState({
                drafts: drafts
            })
        } catch (ex) {
            console.log(ex)
            console.log("unable to fetch drafts")
            this.setState({isLoading: false})
        }
    }

    async getOwnersName(id) {
        let auth = await backend.withTokenAuthHeader(authentication.token)
        let resp = (await backend.internalApi.getUserName(id, auth))
        return resp.userName
    }

    async sharedBy(draft) {
        let ownUserId = authentication.user._id
        if (draft.shared) {
            if (draft.userId == ownUserId) {
                return "you"
            } else {
                return await this.getOwnersName(draft.userId)
            }
        } else {
            return ""
        }
    }

    render() {
        if (this.state.drafts == null) {
            return <p>No drafts found</p>
        } else {
            if (this.state.ownersFixed) {
                const columns = [{
                    id: '_id',
                    Header: 'Id',
                    width: 220,
                    accessor: (d: any) => d._id,
                }, {
                    id: 'name',
                    Header: 'Name',
                    width: 120,
                    accessor: (d: any) => d.name,
                }, {
                    id: 'createdAt',
                    Header: 'createdAt',
                    width: 150,
                    accessor: (d: any) => d.createdAt,
                    Cell: (d: any) => {
                        return <span>{formatDate(d.value)}</span>
                    },
                    sortMethod: (a, b) => {
                        var a1 = new Date(a).getTime();
                        var b1 = new Date(b).getTime();
                        if (a1 < b1) {
                            return 1;
                        } else if (a1 > b1) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }

                }, {
                    id: 'sharedBy',
                    Header: 'sharedBy',
                    width: 200,
                    accessor: (d: any) => d.userId,
                }]

                return <Segment>
                    <h2> Draft </h2>

                    <ReactTable
                        data={this.state.drafts}
                        pages={this.state.pages}
                        columns={columns}
                        sorted={[
                            {
                                id: 'createdAt',
                                desc: false
                            }
                        ]}
                        defaultPageSize={this.state.take}
                        className="-striped -highlight"
                        loading={this.state.isLoading}
                        getTdProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick: () => {
                                    if (rowInfo) {
                                        if (column.id != 'share') {
                                            console.log("Opening Draft-Entry with id", rowInfo.row)
                                            this.setState({showChanges: true, selectedDraft: rowInfo.row._original})
                                        }
                                    }
                                }
                            };
                        }}
                        style={{cursor: "pointer"}}

                    />
                    <IfBox shouldShow={this.state.showChanges}>
                        <DraftModal isOpen={this.state.showChanges} onClose={(refresh) => {
                            this.setState({showChanges: false, selectedDraft: null});
                            if(refresh) {
                                this.props.reloadFunction()
                            }
                        }} draft={this.state.selectedDraft}/>
                    </IfBox>
                    <ShareDraftModal
                        onClose={() => {
                            this.setState({showShareDraftModal: false})
                        }}
                        isOpen={this.state.showShareDraftModal}
                        draft={this.state.draftToShare}
                    />
                </Segment>
            } else {
                return <p>Loading</p>
            }
        }
    }
}
