import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {generateNextRuleId} from "../slices/ruleSlice";
import {LoadDataButton} from "./LoadDataButton";
import {
    addActiveCell,
    clearActiveCells,
    deriveViewForSelectedRule,
    getActivePage,
    getSelectionIndex,
    querySubrules,
} from "../slices/tableSlice";
import './TableView.css'
import {
    addRuleToEdit,
    clearRulesToEdit,
    getRuleToDisplay,
    isEditSelectedRuleModeActive
} from "../slices/rightMenuSlice";
import {useKeyDownDetector} from "../misc/hooks/key_down_hook";
import {GenericXlsExtractionRule} from "../../../../../generated";


interface TableViewProps{
}

export function TableView(props:React.PropsWithChildren<TableViewProps>){
    const showAllData = !useSelector(isEditSelectedRuleModeActive)
    const tablePages = showAllData ? useSelector(getActivePage)?.pages : useSelector(deriveViewForSelectedRule)
    const nextRuleId = useSelector(generateNextRuleId)
    const dispatch = useDispatch()
    const matchingRuleSelectedByUser: GenericXlsExtractionRule|null = useSelector(getRuleToDisplay)
    const selectedRuleIncludingSubRules: GenericXlsExtractionRule[] = useSelector(querySubrules(matchingRuleSelectedByUser, 1, true))
    const isAltKeyPressed = useKeyDownDetector("Alt")

    if(!tablePages || tablePages.length == 0){
        return <div>
            <h3>No data found.</h3>
            <LoadDataButton/>
        </div>
    }

    return <table className={"DataTable"}>
        {
            tablePages.map(function(page, pIdx) {
                return <tbody key={`${pIdx}-tbody`}>
                <tr key={`${pIdx}-h`}><th colSpan={2}>Seite {pIdx}</th></tr>
                {
                    page.dataLines.map(function (line, idx) {

                        const lineRule = line.matchingRule ?? {
                            ruleId: nextRuleId,
                            matchText: `${idx}`,
                            matchWhen: "MATCH_ON_LINE",
                            matchOnColumns: undefined,
                            selector: "NEXT_ROW",
                            selectorMatchWhen: "MATCH_ON_LINE",
                            selectorMatchText: undefined,
                            skipEmptyCells: true,
                            action: "TAG",
                            extractionRegex: undefined,
                            tagName: undefined,
                            parentRuleId: showAllData ? undefined : matchingRuleSelectedByUser?.ruleId,
                            selectNumColumns:undefined,
                            selectNumLines:undefined,
                            skipNumEntriesAtStart:undefined,
                            skipNumEntriesAtEnd:undefined,
                            selectionLabel: undefined,
                            splitEvery:undefined,
                            skipNumColumns: undefined,
                            splitLineOffset: undefined,
                            offsetColumns: undefined,
                            offsetLines: undefined,
                            splitOnlyOnChange: undefined,
                            comment: undefined,
                            splitPointSelection:undefined,
                            ifNotAvailableTrigger: undefined
                        }

                        return <tr key={idx}>
                            <th onClick={() => {
                                dispatch(clearRulesToEdit())
                                dispatch(addRuleToEdit(lineRule))
                            }}
                            >{idx}</th>
                            {
                                line.cells.map(function (cell, cellIdx) {

                                    const rule = cell.matchingRule ?? {
                                        ruleId: nextRuleId,
                                        matchText: cell.text,
                                        matchWhen: "EXACT_MATCH",
                                        matchOnColumns: undefined,
                                        selector: "RIGHT_CELL",
                                        selectorMatchText: undefined,
                                        selectorMatchWhen: "EXACT_MATCH",
                                        skipEmptyCells: true,
                                        action: "TAG",
                                        tagName: undefined,
                                        extractionRegex: undefined,
                                        parentRuleId: showAllData ? undefined : matchingRuleSelectedByUser?.ruleId,
                                        selectNumColumns:undefined,
                                        selectNumLines:undefined,
                                        skipNumEntriesAtStart:undefined,
                                        skipNumEntriesAtEnd:undefined,
                                        selectionLabel: undefined,
                                        splitEvery: undefined,
                                        skipNumColumns: undefined,
                                        splitLineOffset: undefined,
                                        offsetColumns: undefined,
                                        offsetLines: undefined,
                                        splitOnlyOnChange: undefined,
                                        comment: undefined,
                                        splitPointSelection:undefined,
                                        ifNotAvailableTrigger:undefined
                                    }


                                    let cellClassName = "noMatchingRule"
                                    if (cell.matchingRule && cell.matchingRule == matchingRuleSelectedByUser) {
                                        cellClassName = "withMatchingRule"
                                    }


                                    const selectionIdex = getSelectionIndex(page.selections,idx, cellIdx, selectedRuleIncludingSubRules)
                                    if(selectionIdex != -1){
                                        //console.log("Found selection")
                                        cellClassName += ` withMatchingSelection${(selectionIdex+1)%8}`
                                    }

                                    if(page.activeCells?.find(c=>c.lineIdx == idx && c.colIdx == cellIdx)){
                                        cellClassName += ` activeCell`
                                    }

                                    return <td key={cellIdx} onClick={() => {
                                        if(!isAltKeyPressed){
                                            console.log("Clear")
                                            dispatch(clearActiveCells())
                                            dispatch(clearRulesToEdit())
                                        }
                                        dispatch(addRuleToEdit(rule))
                                        dispatch(addActiveCell({pageIdx: pIdx, cell:{lineIdx: idx, colIdx: cellIdx}}))
                                    }} className={cellClassName}>{cell.text}{/*cell.selectionLabels?<LabelIcon labels={cell.selectionLabels}/>:undefined*/}</td>
                                })
                            }
                        </tr>
                    })
                }</tbody>
            })
        }
    </table>
}