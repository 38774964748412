import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import * as localforage from "localforage";



type SourceCodeState = {
    extractionScript: string
    conversionOutput: string
    conversionLogs: string
}

const initialState = {
    extractionScript: ""
} as SourceCodeState

const sourceCodeSlice = createSlice({
    name:"sourceCode",
    initialState,
    reducers: {
        setSourceCode(state: any, action: PayloadAction<string>){
            console.log("[TableSplitter] - settingSourceCode")
            state.extractionScript = action.payload
            localStorage.setItem("TABLE_SPLITTER_SCRIPT", action.payload)
        },
        setConversionOutput(state: any, action: PayloadAction<string>){
            state.conversionOutput = action.payload
        },
        setConversionLogs(state: any, action: PayloadAction<string>){
            state.conversionLogs = action.payload
        }
    }
})

export const {setSourceCode, setConversionLogs,setConversionOutput} = sourceCodeSlice.actions
export default sourceCodeSlice.reducer
export const getSourceCode = (state: RootState) => state.sourceCode.extractionScript;
export const getConversionOutput = (state: RootState) => state.sourceCode.conversionOutput;
export const getConversionLogs = (state: RootState) => state.sourceCode.conversionLogs;