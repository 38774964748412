import * as React from "react";
import {useState} from "react";
import {Grid} from "semantic-ui-react";
import {DbQueryBuilder} from "./DbQueryBuilder";
import {DbQueryResultTable} from "./DbQueryResultTable";
import {QueryResponse} from "../../../../generated";

export interface DbQueryGridProps{

}
export function DbQueryGrid(props: React.PropsWithChildren<DbQueryGridProps>) {

    const [take, setTake] = useState(25)
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState('_id')
    const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC')

    const [queryResponse, setQueryResponse] = useState<QueryResponse>(null)
    const [tabCollection, setTabCollection] = useState<string>(null)


    function onUpdate(p_queryResponse: QueryResponse, p_tabCollection: string) {
        setQueryResponse(p_queryResponse)
        setTabCollection(p_tabCollection)
    }

    function updatePagination(
        p_take: number,
        p_page: number,
        p_sortBy: string,
        p_sortDirection: 'ASC' | 'DESC',
    ) {
        setTake(p_take)
        setPage(p_page)
        setSortBy(p_sortBy)
        setSortDirection(p_sortDirection)
    }

    return <Grid padded="horizontally" stackable columns={2} centered>

        <Grid.Column style={{width: "30%"}}>
            <DbQueryBuilder
                onUpdate={onUpdate}
                take={take}
                page={page}
                sortBy={sortBy}
                sortDirection={sortDirection}
                restrictToCollections={null}
            />
        </Grid.Column>
        <Grid.Column style={{width: "70%"}}>
            <DbQueryResultTable
                collection={tabCollection}
                queryResponse={queryResponse}
                updatePagination={updatePagination}
            />
        </Grid.Column>

    </Grid>
}