import * as React from "react";
import { Tab } from "semantic-ui-react";
import {LogView} from "../../logView/LogView";
import {MatrixDocInputTextView} from "./input/MatrixDocInputTextView";
import {MatrixDocGridView} from "./grid/MatrixDocGridView";
import {MatrixDocOrderView} from "./orders/MatrixDocOrderView";
import MatrixDocInputTabMenu from "./input/MatrixDocInputTabView";

const panes = [
    { menuItem: 'Input', render: props => <Tab.Pane><MatrixDocInputTabMenu changeXRequestId={props.changeXRequestId} context={props.context} output={props.output}/></Tab.Pane> },
    { menuItem: 'Grid', render: props => <Tab.Pane><MatrixDocGridView /></Tab.Pane> },
    { menuItem: 'Orders', render: props => <Tab.Pane><MatrixDocOrderView /></Tab.Pane> },
    { menuItem: 'Logs', render: props => <Tab.Pane><LogView query={props.xRequestId} origin={"matrixDoc"}/></Tab.Pane> },
]

const MatrixDocTabMenu = props => <Tab
    id={"MatrixDocTabMenu"}
    {...props = props}
    panes={panes}
    menu={{ fluid: true, vertical: true, tabular: 'left', attached: 'left'}}
    grid={{paneWidth: 14, tabWidth: 2}}
    defaultActiveIndex={props.context.tabSession.matrixDocTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("matrixDocTab", data.activeIndex)
    }}
/>

export default MatrixDocTabMenu