import * as React from 'react'
import {Splitter} from "../../../../util/table-splitter-ui/src/components/Splitters/Splitter";
import {TransportOrderTable} from "../../transportOrders/TransportOrderTable";
import AceEditor from "react-ace";
import {Button, Grid, Icon} from "semantic-ui-react";
import {LogView} from "../../logView/LogView";
import {ConfigContext} from "../../../context/ConfigContext";
import {MultiOrderScriptResultCompareModal} from "./MultiOrderScriptResultCompareModal";
import {IfBox} from "../../../../style/if";

export interface MultiOrderScriptResultProps {
    xRequestId
    originalOrders
}

export interface MultiOrderScriptResultState {
    selectedResult: string
    showCompareModal: boolean
}

export class MultiOrderScriptResult extends React.Component<MultiOrderScriptResultProps, MultiOrderScriptResultState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;


    constructor(props) {
        super(props)

        this.state = {
            selectedResult: "",
            showCompareModal: false
        }
    }

    componentDidMount() {

    }

    drawTable() {
        return <>
            <TransportOrderTable
                transportOrders={this.context.multiOrderScriptResults}
                transportOrderAmount={this.context.multiOrderScriptResults?.length}
                selectedOrderChanged={(newSelectedOrder) => {this.setState({selectedResult: JSON.stringify(newSelectedOrder, null, 4)})}}
                selectedOrderIdsChanged={() => {}}
                reload={() => {}}
                initialPageSize={20}
            />
        </>
    }

    drawSingleResultJson() {
        return  <>
                <Button
                    icon
                    disabled={this.state.selectedResult == ""}
                    onClick={(evt) => {
                        this.setState({
                            showCompareModal: true
                        })
                    }}>
                    <Icon name='exchange'/>
                </Button>

                <AceEditor
                    theme="monokai"
                    mode='groovy'
                    value={this.state.selectedResult}
                    placeholder='selected transportOrder'
                    width="100%"
                    height="96%"
                    readOnly={true}
                />
            </>
    }

    drawSplittedArea() {
        return <div style={{width: '100%', height: '100%'}}>
            <Splitter
                name="MultiOrderScriptResultSplitter"
                left={this.drawTable()}
                right={this.drawSingleResultJson()}
            /></div>
    }

    fetchBefore() {
        let result = null
        if(this.state.selectedResult) {
            try {
                result = this.props.originalOrders.find((order) => order._id == JSON.parse(this.state.selectedResult)._id)
            } catch (e) {
                console.log("ERROR: cannot parse selected result or it doesn't have an '_id' ", e)
                result = "ERROR: cannot parse selected result or it doesn't have an '_id'. Check the logs!"
            }

        }
        return result
    }

    render() {
        return <Grid padded="horizontally" stackable columns='equal' centered>
            <Grid.Row>
                {this.drawSplittedArea()}
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <h4>Logs:</h4>
                    <LogView query={this.props.xRequestId} origin={"singleScript"}/>
                </Grid.Column>
            </Grid.Row>
            <IfBox shouldShow={this.state.showCompareModal}>
            <MultiOrderScriptResultCompareModal
                isOpen={this.state.showCompareModal}
                onClose={() => {this.setState({showCompareModal: false})}}
                before={this.fetchBefore()}
                after={this.state.selectedResult}
            />
            </IfBox>
        </Grid>

    }
}