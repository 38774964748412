import * as React from "react";
import {Button, Grid, Header, Icon, Input, Label, Modal} from "semantic-ui-react";
import {PositionRequest, TelematicData, TransportOrder} from "../../../../../generated";
import {ConfigContext} from "../../../context/ConfigContext";
import {LatLng} from "leaflet";
import * as moment from 'moment';
import {Moment} from 'moment';
import {authentication} from "../../../../../authentication";
import {backend} from "../../../../../xconvert-backend";
import DatetimePicker from "react-datetime";

export interface AddNewPositionModalProps {
    isOpen: boolean
    onClose: (updated) => void
    position: LatLng
    order: TransportOrder
}

export interface AddNewPositionModalState {
    initialLat: string,
    initialLng: string,
    latitude: string,
    longitude: string,
    timestamp: Moment
}


export class AddNewPositionModal extends React.Component<AddNewPositionModalProps, AddNewPositionModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    timePickerRef = React.createRef<DatetimePicker>();

    constructor(props) {
        super(props)
        this.state = {
            initialLat: "",
            initialLng: "",
            latitude: "",
            longitude: "",
            timestamp: null
        }
    }

    componentDidMount() {
        this.setState({
            initialLat: this.props?.position?.lat?.toString(),
            initialLng: this.props?.position?.lng?.toString(),
            latitude: this.props?.position?.lat?.toString(),
            longitude: this.props?.position?.lng?.toString(),
            timestamp: moment()

        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
        if(nextProps.position?.lat?.toString() !== prevState.initialLat || nextProps.position?.lng?.toString() !== prevState.initialLng) {
            return {
                initialLat: nextProps.position?.lat?.toString(),
                initialLng: nextProps.position?.lng?.toString(),
                latitude: nextProps.position?.lat?.toString(),
                longitude: nextProps.position?.lng?.toString(),
                timestamp: moment()
            }
        }
    }

    handleClose = (update) => {
        this.props.onClose(update)
    }

    getInitialDate(value) {
        if (value) {
            return moment(new Date(value))
        }
        return moment()
    }

    formatDateTimeForPicker(dateTime) {
        var date = new Date();
        if (dateTime) {
            date = new Date(dateTime)
        } else {
            return ""
        }
    }

    async save() {
        let auth = backend.withTokenAuthHeader(authentication.token)

        let order = this.props.order

        let position = {} as PositionRequest
        position.companyId = order.companyId
        position.latitude = Number(this.state.latitude)
        position.longitude = Number(this.state.longitude)
        position.timestamp = this.state.timestamp.toDate()
        position.vehicle = order.assignedVehicle
        position.driver = order.assignedDriver
        position.orderBoxImei = order.assignedDevice?.phoneNr


        let positionRequests = []
        positionRequests.push(position)

        let request = {} as TelematicData
        request.positionRequests = positionRequests
        request.companyId = order.companyId


        let response = await backend.trackingApi.postTrackingPosition(request, auth)

        console.log("Response: ", response)
        this.handleClose(response.addedPositions == 1)
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            onMount={() => {
            }}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Add a new Position'/>
            <Modal.Content>

                <Grid padded="horizontally" stackable columns='equal' centered>


                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Label >Latitude</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Input type="text"
                                   pattern="\d*[\.|\,]?\d*"
                                   width
                                   onChange={(event) => this.setState({latitude: event.target.value})}
                                   value={this.state.latitude}/>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Label >Longitude</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Input type="text"
                                   pattern="\d*[\.|\,]?\d*"
                                   onChange={(event) => this.setState({longitude: event.target.value})}
                                   value={this.state.longitude}/>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Label >Timestamp</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Input style={{ width: 220 }} type="text" disabled={true} value={this.state.timestamp.toString()} />

                            <DatetimePicker ref={this.timePickerRef}
                                            value={this.state.timestamp}
                                            dateFormat={"DD.MM.YYYY HH:mm"} input={false}
                                            onChange={(dt: any) => this.setState({timestamp: dt})}/>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Button icon onClick={() => this.save()}><Icon name={'save'} />save</Button>
                        <Button onClick={() => this.handleClose(null)}>cancel</Button>
                    </Grid.Row>

                </Grid>
            </Modal.Content>
        </Modal>

    }

}