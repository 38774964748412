import * as React from "react";
import {Button, Grid, Header, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import {DiffWrapper} from "../../configChange/configEdit/diff/DiffWrapper";
import {
    QueuedEventConversionFileResult,
    TransportOrderConfigTest,
    TransportOrderConfigTestUpdate,
    UpdateTransportOrderConfigTestsRequest
} from "../../../../../generated/api";
import {notify} from 'react-notify-toast';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'

import "../../configChange/configEdit/merge/mergeStyle.css";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {CustomDiff} from "../../configChange/configEdit/diff/CustomDiff";

export interface UpdateTestModalProps {
    isOpen: boolean
    onClose: (boolean) => void
    expectedResult: string
    newResult: string
    test: TransportOrderConfigTest
}

export class UpdateTestModal extends React.Component<UpdateTestModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            propsFound: false,
            editors: null,
            expectedResult: null,
        }
    }

    handleClose = (saved) => {
        this.props.onClose(saved)
    }


    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Update test result'/>
            <Modal.Content>

                <Grid padded="horizontally" stackable columns='equal' centered>

                    <Grid.Column>
                        old expected result
                    </Grid.Column>
                    <Grid.Column>
                        new expected result
                    </Grid.Column>
                </Grid>
                <CustomDiff
                    oldJson={this.props.expectedResult}
                    newJson={this.props.newResult}
                />
                <Button onClick={() => this.saveChanges()}>Save Changes</Button>
                <Button onClick={() => this.handleClose(false)}>Cancel</Button>

            </Modal.Content>
        </Modal>
    }

    async saveChanges() {
        console.log("saving changes")
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let updates: TransportOrderConfigTestUpdate[] = []
        let test = this.props.test

        let update = {} as TransportOrderConfigTestUpdate
        update.testId = test._id
        update.testName = test.name
        update.input = test.input
        update.expectedResult = test.expectedResult
        if (this.props.test.type == "IMPORT") {
            update.expectedResult.importData = JSON.parse(this.props.newResult.toString())
        } else {
            update.expectedResult.exportDataList = [] as QueuedEventConversionFileResult[]
            let expected = {} as QueuedEventConversionFileResult
            expected.base64Content = btoa(this.props.newResult.toString())
            expected.encoding = "ISO-8859-1"
            update.expectedResult.exportDataList.push(expected)
        }
        updates.push(update)

        let request = {} as UpdateTransportOrderConfigTestsRequest
        request.updates = updates


        let response = await backend.internalApi.updateTransportOrderConfigTests(request, auth)
        if (response.failedUpdateTestIds.length > 0) {
            // TODO: implement error handling
            console.log("Error saving changes")
        }
        notify.show('test has been updated', 'success', 3000, '#28f751');
        this.handleClose(true)
    }

}