import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {ConfigContext} from "../../context/ConfigContext";
import {Button, ButtonGroup, Divider, Dropdown, Icon, List, Popup} from "semantic-ui-react";


export interface DrawCustomConverterBasePathDropdownProps {
    onClick: (evt: any) => void
    disabled: boolean
    loading: boolean
}

export function ConverterSendButton(props: React.PropsWithChildren<DrawCustomConverterBasePathDropdownProps>) {

    const [stateVal, setStateVal] = useState(null)

    const context = useContext(ConfigContext)

    useEffect(() => {
        console.log("UseEffect context customConverterBasePath is " + context.customConverterBasePath)
        setStateVal(context.customConverterBasePath)
    }, [])

    let options = [
        {key: "Alpha", value: "https://converter.alpha.logenios.com", text: "Alpha"},
        {key: "Canary", value: "https://converter-canary.alpha.logenios.com", text: "Canary"},
        {key: "Beta", value: "https://cm.logenios.com", text: "Beta"}
    ]


    return <ButtonGroup primary>
        <Button
            style={{paddingRight: "5px", marginRight: "0px"}}
            disabled={props.disabled}
            onClick={props.onClick}
            loading={props.loading}
        >
            Send
        </Button>
        <Dropdown
            selectOnBlur={false}
            button
            style={{paddingLeft: "5px", marginLeft: "0px", paddingTop: "11px"}}
            icon={"dropdown"}
            compact
            floating
            options={options}
            header={<>
                <Popup content={<>
                    <p>Use this dropdown to select the stage of the converter you want to send the event to.</p>
                    <List bulleted>
                        <List.Item>Alpha = "https://converter.alpha.logenios.com" </List.Item>
                        <List.Item>Canary = "https://converter-canary.alpha.logenios.com" </List.Item>
                        <List.Item>Beta = "https://cm.logenios.com" </List.Item>
                    </List>
                </>} trigger={
                    <p style={{margin: "10px"}}>
                        <Icon name='info circle' color={"blue"} style={{float: 'left'}}/>
                        Info
                    </p>
                }/>
                <Divider/>
            </>}
            clearable
            value={stateVal}
            onChange={(event, {value}) => {
                console.log("Setting customConverterBasePath to " + value)
                context.setCustomConverterBasePath(value.toString())
                setStateVal(value)
            }
            }
        />
    </ButtonGroup>
}