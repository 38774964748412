import * as React from "react";
import {signal, Signal} from "@preact/signals-react";
import {configStorage} from "../../../../ConfigStorage";
import {ConfigContext} from "../../context/ConfigContext";
import {backend} from "../../../../xconvert-backend";
import {authentication} from "../../../../authentication";
import {CompanyConfiguration} from "../../../../generated";


export const workingConfig: Signal<string | null> = signal(null)
export const companyIdSignal: Signal<string | null> = signal(null)

export async function updateConfigFromObject(config: CompanyConfiguration, updateStorage: boolean, alsoSaveAsUnchangedConfig: boolean) {
    console.log("[ConfigSignal] updateConfigFromObject")

    let newConfigStr = JSON.stringify(config, null, 4)
    if(newConfigStr == workingConfig.value) {
        console.log("[ConfigSignal] Config is the same, not updating")
        return
    }

    workingConfig.value = newConfigStr
    if(updateStorage) {
        await configStorage.updateConfig(companyIdSignal.value, config, alsoSaveAsUnchangedConfig)
    }
    console.log("[ConfigSignal] updateConfigFromObject successful")

}

export async function updateConfig(config: string, updateStorage: boolean, alsoSaveAsUnchangedConfig: boolean) {
    await updateConfigFromObject(JSON.parse(config), updateStorage, alsoSaveAsUnchangedConfig)
}

export async function loadWorkingConfig(companyId: string) {

    console.log("[ConfigSignal] loadWorkingConfig with companyId: " + companyId)

    if(workingConfig.value) {

        console.log("[ConfigSignal] workingConfig.value is not null, returning")
        return
    }
    let resultedConfig = null
    const configFromStorage = await configStorage.getElement(companyId)
    console.log("[ConfigSignal] configFromStorage: ", configFromStorage)


    // check if valid config is in storage
    let storageFailed = false;
    if(configFromStorage) {
        try {
            // check if config is valid JSON
            const configStr = JSON.stringify(configFromStorage.config, null, 4)
            const configObj = JSON.parse(configStr)
            console.log("[ConfigSignal] Config from storage is valid JSON, storing in workingConfig")
            await updateConfigFromObject(configObj, false, false)
            resultedConfig = configObj
        } catch (e) {
            console.error("[ConfigSignal] Config from storage is not valid JSON. e=" + e)
            storageFailed = true
        }
    } else {
        console.log("[ConfigSignal] No config in storage, loading from backend")
        storageFailed = true
    }

    // get it from backend if storage failed
    if (storageFailed) {
        let cId = companyId

        if (!cId) {
            const context = React.useContext(ConfigContext)
            cId = context.companyId
        }
        const auth = backend.withTokenAuthHeader(authentication.token)
        const configFromBackend = await backend.internalApi.fetchCompanyConfiguration(cId, auth)

        if(configFromBackend) {
            console.log("[ConfigSignal] Config from backend is valid JSON, storing in workingConfig")
            await updateConfigFromObject(configFromBackend, true, true)
            resultedConfig = configFromBackend
        } else {
            console.error("[ConfigSignal] No config from backend")
        }
    }

    return resultedConfig
}