import * as React from "react"
import {useEffect, useRef, useState} from "react"
import './Splitter.css'
import {Popup} from "semantic-ui-react"

interface SplitterProps {
    name: string,
    initiallyHorizontal?: boolean,
    topOrLeft: JSX.Element,
    bottomOrRight: JSX.Element
    onChange?: (leftWidth: number) => void
}

export function ChangeableSplitter(props: React.PropsWithChildren<SplitterProps>) {
    const parentRef = useRef<HTMLDivElement>(null)
    const offset = useRef({x: 0, y: 0})

    const [horizontal, setHorizontal] = useState(props.initiallyHorizontal)
    const storedDimension = parseFloat(window.localStorage.getItem(`splitter${props.name}_${horizontal ? 'top_height' : 'left_width'}`) ?? "30")
    const [dimension, setDimension] = useState(storedDimension)
    const [ownSize, setOwnSize] = useState({width: 500, height: 500})

    const updateDimensions = () => {
        if (parentRef.current) {
            const rect = parentRef.current.getBoundingClientRect()
            setOwnSize({width: rect.width, height: rect.height})
        }
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions)
        const timer = setTimeout(() => {
            updateDimensions()
            window.dispatchEvent(new UIEvent('resize')) //This is just to get the Ace-Editor to resize
        }, 0)  // Execute after the stack clears and the browser has a chance to paint
        return () => {
            clearTimeout(timer)
            window.removeEventListener("resize", updateDimensions)
        }
    }, [])
     useEffect(() => {
         return () => {
             window.dispatchEvent(new UIEvent('resize')) //This is just to get the Ace-Editor to resize
         }

     }, [horizontal])

    const mouseMove = (e: MouseEvent) => {
        if (horizontal) {
            const dy = e.clientY - offset.current.y
            let newHeight = dimension + (dy * 100 / ownSize.height)
            newHeight = Math.max(10, Math.min(newHeight, 90)) // Ensure the new height is within the range
            setDimension(newHeight)
            window.localStorage.setItem(`splitter${props.name}_top_height`, newHeight.toString())
        } else {
            const dx = e.clientX - offset.current.x
            let newWidth = dimension + (dx * 100 / ownSize.width)
            newWidth = Math.max(10, Math.min(newWidth, 90)) // Ensure the new width is within the range
            setDimension(newWidth)
            window.localStorage.setItem(`splitter${props.name}_left_width`, newWidth.toString())
        }
        props.onChange?.(dimension)
    }

    const onResize = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        offset.current = {x: e.clientX, y: e.clientY}
        document.addEventListener("mousemove", mouseMove)
        document.addEventListener("mouseup", onResizeStop)
    }

    const onResizeStop = () => {
        window.dispatchEvent(new UIEvent('resize')) // To resize any integrated components
        document.removeEventListener("mousemove", mouseMove)
        document.removeEventListener("mouseup", onResizeStop)
    }
    const onDoubleClick = () => {
        setHorizontal(!horizontal)
    }

    const splitterStyle = horizontal ? {height: `${dimension}%`} : {width: `${dimension}%`}
    const remainingStyle = horizontal ? {height: `${100 - dimension}%`} : {width: `${100 - dimension}%`}

    return (
        <div
            id={props.name}
            className={`splitter ${horizontal ? "horizontal" : ""}`}
            style={{display: "flex", flex: 3, minHeight: "100px", flexDirection: horizontal ? "column" : "row"}}
            ref={parentRef}
        >
            <div className="splitterHorizontalTopOrLeft" style={splitterStyle}>
                {props.topOrLeft}
            </div>
            <Popup
                content={"Double click to flip orientation"}
                trigger={<div
                    id={`ChangeableSplitterResizer${props.name}`}
                    className={"ChangeableSplitterResizer " + (horizontal ? "splitterResizerHorizontal" : "splitterResizer")}
                    onMouseDown={onResize}
                    onDoubleClick={onDoubleClick}
                >≡</div>}
                mouseEnterDelay={200}
            />
            <div className="splitterHorizontalBottomOrRight" style={remainingStyle}>
                {props.bottomOrRight}
            </div>
        </div>
    )
}