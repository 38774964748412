import * as React from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import {DiffWrapper} from "../../configChange/configEdit/diff/DiffWrapper";
import {CustomDiff} from "../../configChange/configEdit/diff/CustomDiff";

export interface MultiOrderScriptResultCompareModalProps {
    isOpen: boolean
    onClose: () => void
    before: any
    after: any
}

export interface MultiOrderScriptResultCompareModalState {

}

export class MultiOrderScriptResultCompareModal extends React.Component<MultiOrderScriptResultCompareModalProps, MultiOrderScriptResultCompareModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {

    }

    cleanFoundDifferences(foundDifferences: string[]) {
        let clean = []
        let regex = /(\[[0-9]*\]\.)/gm
        if (foundDifferences != null) {
            foundDifferences.map(d => {
                let c = d.replace(regex, "[*].")
                if (clean.includes(c) == false) {
                    clean.push(c)
                }
            })
            return clean
        }
        return []

    }

    handleClose = () => {
        this.props.onClose()
    }

    render() {
        let title = "Compare before and after of " + this.props.before._id
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="inverted"
            size='fullscreen'>
            <Header icon='browser' content={title}/>
            <Modal.Content>

                {this.drawDiff(JSON.stringify(this.props.before, null, 4), this.props.after)}
                <Button onClick={(evt) => this.handleClose()}>Close</Button>

            </Modal.Content>
        </Modal>
    }

    onCloseCompareModal() {
        this.setState({showConfigCompare: false})
    }

    drawDiff(before, after) {
        if (before) {
            return <CustomDiff
                oldJson={before}
                newJson={after}
            />
        } else {
            return <p>null</p>
        }
    }

}