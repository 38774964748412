import * as React from "react";
import {useContext} from "react";
import {PageWithSidebar} from "../../../style/page_with_sidebar";
import Notifications from 'react-notify-toast';
import {ConfigContext} from "../../context/ConfigContext";
import {Tab} from "semantic-ui-react";
import {ExportSimPage} from "./ExportSim/ExportSimPage";
import {ImportSimPage} from "./ImportSim/ImportSimPage";
import {TransportOrderConfigTests} from "./transportOrderConfigTests/TransportOrderConfigTests";

export interface ConverterPageProps {
}

export function ConverterPage() {
    const context = useContext(ConfigContext);

    return <PageWithSidebar activeMenu="Converter">
        <Tab
            id={"ConverterTabMenu"}
            className={"flexTab"}
            panes={[
                {pane: null, menuItem: 'Export', render: () => <Tab.Pane id='Export'><ExportSimPage/></Tab.Pane>},
                {pane: null, menuItem: 'Import', render: () => <Tab.Pane id='Import'><ImportSimPage/></Tab.Pane>},
                {pane: null, menuItem: 'Tests', render: () => <Tab.Pane id='Tests'><TransportOrderConfigTests/></Tab.Pane>},
            ]}
            defaultActiveIndex={context.tabSession.converterTab}
            onTabChange={(_, data) => {
                context.setSingleTabSession(
                    "converterTab",
                    parseInt(data.activeIndex.toString()),
                    null,
                    null
                )
            }}
        />
        <Notifications/>
    </PageWithSidebar>

}