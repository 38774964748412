import {IfBox} from "../../../../../style/if";
import * as React from "react";
import {Icon, Popup} from "semantic-ui-react";
import {decorators, Treebeard} from 'react-treebeard';


export interface CustomNavTreeProps {
    data: any
    onToggle: (node, toggled) => void
}

export function CustomNavTree(props: React.PropsWithChildren<CustomNavTreeProps>) {
    decorators.Header = CustomHeader

    if (props.data != null) {
        //console.log("drawTree: ", data)
        return (
            <Treebeard
                data={props.data}
                onToggle={props.onToggle}
                decorators={decorators}
            />
        );
    } else {
        return <h2>no config found</h2>
    }

}

function drawDeprecatedWarningIfNeededOnNode(node) {
    return <IfBox shouldShow={node.isDeprecated}><Popup content={"DEPRECATED: " + node.deprecatedMessage} trigger={
        <Icon name="exclamation triangle" color="yellow"/>
    }
    /></IfBox>
}

const displayName = (node) => {
    if(node.displayName != null) {
        return node.displayName
    }
    return node.name
}

const CustomHeader = ({node, style, prefix}) => (

    <div style={style.base}>
        <IfBox shouldShow={!node.active && !node.isEdited}>
            <div style={{...style.title, display: "flex"}}>
                {drawDeprecatedWarningIfNeededOnNode(node)}{displayName(node)}
            </div>
        </IfBox>
        <IfBox shouldShow={node.active && !node.isEdited}>
            <div style={{...style.title, display: "flex", fontWeight: 'bold', color: 'lightgreen'}}>
                {drawDeprecatedWarningIfNeededOnNode(node)}{displayName(node)}
            </div>
        </IfBox>
        <IfBox shouldShow={node.isEdited}>
            <div style={{...style.title, display: "flex", fontWeight: 'bold', color: 'orange'}}>
                {drawDeprecatedWarningIfNeededOnNode(node)}{displayName(node)}
            </div>
        </IfBox>
    </div>
);

