import * as React from "react";
import {Button, Grid, Header, Modal} from "semantic-ui-react";
import {ChangeEvent, Origin, TransportOrder, TransportOrderChange} from "../../../../../generated";
import {DiffWrapper} from "../../configChange/configEdit/diff/DiffWrapper";
import Notifications from 'react-notify-toast';
import {CustomDiff} from "../../configChange/configEdit/diff/CustomDiff";

export interface TransportOrderHistoryModalProps {
    isOpen: boolean
    onClose: (openRollback: boolean) => void
    transportOrder: TransportOrder
    followingConfig: Origin
    change: ChangeEvent
    previousChange: ChangeEvent
}

export class TransportOrderHistoryModal extends React.Component<TransportOrderHistoryModalProps, any> {

    constructor(props) {
        super(props)
        this.state = {
            showRollBackView: false,
            oldTo: null,
            newTo: null
        }
    }

    handleOpenRollback = (openRollback: boolean) => {

        this.props.onClose(openRollback)

    }

    handleClose = () => {
        this.props.onClose(false)
    }

    render() {
        console.log("rendering TransportOrderHistoryModal. change: ", this.props.change, "isOpen: ", this.props.isOpen, "transportOrder: ", this.props.transportOrder, "followingConfig: ", this.props.followingConfig)
        if (this.props.change != null) {
            return <Modal
                open={this.props.isOpen}
                onClose={this.handleClose}
                onMount={() => {
                }}
                dimmer="blurring"
                size='fullscreen'>
                <Header icon='browser' content='TransportOrder History (change view)'/>
                <Modal.Content>
                    <p>This view shows what did actually change with this history entry. It compares this history-state
                        with the previous history-state.</p>
                    <Grid padded="horizontally" stackable columns='equal' centered>
                        <Grid.Row>
                            <Grid.Column>Id</Grid.Column>
                            <Grid.Column>{this.props.change.eventType}</Grid.Column>
                        </Grid.Row><Grid.Row>
                        <Grid.Column>Timestamp</Grid.Column>
                        <Grid.Column>{this.props.change.timestamp}</Grid.Column>
                    </Grid.Row> <Grid.Row>
                        <Grid.Column>comment</Grid.Column>
                    </Grid.Row>
                        <Grid.Row>
                            <Grid.Column><h2>BEFORE</h2>({this.props.previousChange?.timestamp})</Grid.Column>
                            <Grid.Column><h2>{"--> compared to -->"}</h2></Grid.Column>
                            <Grid.Column><h2>AFTER</h2>({this.props.change.timestamp})</Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <CustomDiff
                        oldJson={JSON.stringify(this.props.previousChange?.transportOrderEvent?.transportOrder, null, 4)}
                        newJson={JSON.stringify(this.props.change.transportOrderEvent?.transportOrder, null, 4)}
                    />
                    <Button primary onClick={() => {
                        console.log("open Rollback view")
                        this.handleOpenRollback(true)
                    }}>rollback</Button>
                    <Button onClick={() => this.handleClose()}>cancel</Button>
                    <Notifications/>
                </Modal.Content>
            </Modal>
        } else {
            return <p/>
        }
    }

}