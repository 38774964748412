import * as React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import './box.css';

type BoxProps = {
    showLogo?: boolean
}

export class Box extends React.Component<BoxProps, any> {

    static defaultProps = {
        showLogo: false,
    }

    constructor(props) {
        super(props)

        this.state = {
            activeIndex: 0,
            isLoading: false
        }
    }

    renderLogo() {
        if (this.props.showLogo === true) {
            return (<Image src='/assets/logo-with-text-small.png' fluid className="image-large-padding" />)
        }
    }

    render() {

        return <div><Grid centered>
            <Grid.Row centered>
                <Grid.Column className="column-box">
                    {this.renderLogo()}
                    {this.props.children}
                </Grid.Column>
            </Grid.Row>
        </Grid></div>
    }
}

export default Box;