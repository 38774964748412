import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button, Segment} from 'semantic-ui-react';
import AceEditor from 'react-ace';
import {IfBox} from "../../../../style/if";
import {ImportSimSaveAsTestModal} from "./ImportSimSaveAsTestModal";
import {notify} from 'react-notify-toast';
import 'brace/ext/searchbox';
import {FileWithState, uploadedFiles} from "../../../../util/FileUploadMultiple";
import {importSimOutputs} from "./ImportSimTabMenu";
import {ConfigContext} from "../../../context/ConfigContext";

export interface ImportSimOutputProps {
    outputTO: string,
    selectedFileName: string
}

export function ImportSimOutput(props: React.PropsWithChildren<ImportSimOutputProps>) {

    const context = useContext(ConfigContext);
    const [showSaveTestModal, setShowSaveTestModal] = useState(false);
    const [outputTOs, setOutputTOs] = useState(null);


    useEffect(() => {
        console.log("props.outputTO: ", props.outputTO)

    }, [props.outputTO]);

    function onSave() {
        if (context.confSimInput === "" && uploadedFiles.value === null) {
            notify.show("ERROR: input must be set. Either text or file!", 'error', 5000, '#fc0303');
            return;
        }
        let output = null;
        if (importSimOutputs.value) {
            let foundOutput = findSelectedOutput();
            if (foundOutput !== null) {
                let TOs = foundOutput.TOs;
                output = JSON.parse(TOs)[0].transportOrders
            }
        }
        setOutputTOs(output);
        setShowSaveTestModal(true);
    }

    function findSelectedOutput() {
        return importSimOutputs.value.find((f: any) => f.fileName === props.selectedFileName);
    }

    function findSelectedInputFile() {
        return uploadedFiles.value.find((f: FileWithState) => f.file.name === props.selectedFileName)?.file;
    }

    return <>
        <Segment className={"flex3"} style={{padding: "1px", margin: 0}}>
            <div className={"rightFloatingBtn"} style={{top: "10px", right: "20px"}}>
                <Button
                    disabled={(uploadedFiles.value?.length ?? 0) !== 1}
                    onClick={onSave}>
                    Save as test
                </Button>
            </div>
            <AceEditor
                theme="monokai"
                mode="json"
                style={{flex: 1}}
                value={importSimOutputs.value.find((value) => value.selected)?.result.TOs}
                placeholder={'TransportOrder'}
                width="100%"
                readOnly={true}
            />
        </Segment>
        <IfBox shouldShow={showSaveTestModal}>
            <ImportSimSaveAsTestModal
                isOpen={showSaveTestModal}
                onClose={() => {
                    setShowSaveTestModal(false);
                    setOutputTOs(null);
                }}
                expectedResult={outputTOs}
                input={context.confSimInput}
                inputFile={findSelectedInputFile()}
                inputActive={context.confSimInputActive}
            />
        </IfBox>
    </>
}
