import {FTPApi} from "./generated-ftp/api";
import {v4 as uuid} from 'uuid';

const BASE_URL = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');


class FtpService {
    basePath = BASE_URL + "/ftp"

    ftpApi: FTPApi


    constructor() {
        this.ftpApi = this.getFtpApi()

    }

    withBasicAuthHeader(user: String, pass: String) {
        return {headers: {'Authorization': 'Basic ' + btoa(`${user}:${pass}`), 'Accept': 'application/json'}}
    }

    withTokenAuthHeader(token: String) {
        if (token && token.startsWith("Bearer"))
            return {
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json; charset=utf-8',
                    'x-request-id': uuid()
                }
            }
        return {
            headers: {
                "Authorization": "Bearer " + token,
                'x-request-id': uuid()
            }
        }
    }

    private getFtpApi(): FTPApi {
        return new FTPApi(null, this.basePath)
    }


}

export const ftp = new FtpService()