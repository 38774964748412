import * as React from "react";
import {
    Button,
    Checkbox,
    Divider,
    Dropdown,
    DropdownItemProps,
    Form,
    Grid,
    Icon,
    Input,
    Label,
    Segment
} from 'semantic-ui-react';
import {authentication} from "../../../../../authentication";
import {Company, SystemStatusMessage} from "../../../../../generated/api";
import {backend} from "../../../../../xconvert-backend";
import {ConfigContext} from "../../../context/ConfigContext";
import Notifications, {notify} from 'react-notify-toast';
import ReactTable from "react-table";
import {formatDate, isActive} from "../../../../../format";
import {SystemStatusMessageModal} from "./SystemStatusMessageModal";
import {IfBox} from "../../../../style/if";
import {SystemStatusMessageCompanyList} from "./SystemStatusMessageCompanyList";
import AceEditor from 'react-ace';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'brace/mode/groovy'
import 'brace/ext/searchbox'


export interface SystemStatusMessageState {
    isLoading: boolean
    title: string
    message: string
    startTime: Date
    take: number
    page: number
    messages: SystemStatusMessage[]
    sortBy: 'ID' | 'FOR_CUSTOMERS_TO_SEE' | 'START_TIME' | 'END_TIME' | 'TITLE' | 'CREATED_AT' | 'CREATED_BY' | 'LEVEL' | 'MESSAGE'
    sortDirection: 'ASC' | 'DESC'
    selectedMessage: SystemStatusMessage | null
    showMessage: boolean
    showSelectCompany: boolean
    selectedCompanies: any[]
    allCompanies: boolean
    level: SystemStatusMessage.LevelEnum
    forCustomerToSee: boolean,
    count: number,
    filterLevel: 'A_INFO' | 'B_WARNING' | 'C_ERROR' | null,
    searchQuery: string | null,
    activeOnly: boolean
    onlyOwnCompany: boolean
}


export class SystemStatusMessagePage extends React.Component<any, SystemStatusMessageState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading SystemStatusMessagePage")

        this.state = {
            isLoading: true,
            title: "",
            message: "",
            startTime: new Date(),
            take: 25,
            page: 1,
            messages: null,
            sortBy: "START_TIME",
            sortDirection: "DESC",
            selectedMessage: null,
            showMessage: false,
            showSelectCompany: false,
            selectedCompanies: [],
            allCompanies: true,
            level: SystemStatusMessage.LevelEnum.AINFO,
            forCustomerToSee: false,
            count: -1,
            filterLevel: null,
            searchQuery: "",
            activeOnly: false,
            onlyOwnCompany: false,
        }
    }

    async componentDidMount() {
        if (!authentication.isSuperUser) {
            this.setState({onlyOwnCompany: true})
            this.onlyOwnCompanyFixedSettings()
        }
        this.loadMessages()
        console.log("IDS : ", this.props.match.params.ids)

        if (this.props.match.params.ids && this.state.allCompanies && authentication.isSuperUser) {
            let paramsExist = JSON.parse(this.props.match.params.ids).length > 0
            this.setState({
                forCustomerToSee: paramsExist,
                allCompanies: !paramsExist
            })
            await this.fetchCompaniesByIds(JSON.parse(this.props.match.params.ids))
        }
    }

    onlyOwnCompanyFixedSettings() {
        let selectedCompanies = []
        selectedCompanies.push(authentication.company)
        this.setState({
            forCustomerToSee: true,
            allCompanies: false,
            selectedCompanies: selectedCompanies
        })
    }

    async loadMessages(take = this.state.take, page = this.state.page) {
        console.log("change page to ", page)
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let skip = (page - 1) * take

        let resp = (await backend.internalApi.fetchSystemStatusMessages(
            this.state.filterLevel,
            take,
            this.state.activeOnly,
            this.state.searchQuery,
            skip,
            this.state.sortBy,
            this.state.sortDirection,
            auth
        ))
        this.setState({
            messages: resp.messages,
            count: resp.count,
            take: take,
            page: page,
            isLoading: false
        })
        console.log("new page is: ", this.state.page)

    }


    async saveNewMessage() {
        this.setState({isLoading: true})
        let request = {} as SystemStatusMessage

        request.startTime = this.state.startTime
        request.message = {}

        request.message.title = this.state.title
        request.message.message = this.state.message
        request.level = this.state.level

        request.forCustomerToSee = this.state.forCustomerToSee

        if (!this.state.allCompanies) {
            request.companyIds = this.state.selectedCompanies.map((c: Company) => {
                return c._id
            })
        }


        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let response = (await backend.internalApi.saveSystemStatusMessages(request, auth))

        if (response.success) {
            notify.show('saved new message', 'success', 3000, '#28f751');
            this.loadMessages()
            this.setState({
                message: "",
                title: "",
                allCompanies: false,
                selectedCompanies: [],
                startTime: new Date(),
                level: SystemStatusMessage.LevelEnum.AINFO,
                isLoading: false
            })
        } else {
            notify.show("ERROR: " + response.error, 'error', 5000, '#fc0303')
        }

    }

    enumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(SystemStatusMessage.LevelEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }

    render() {
        let i = 0

        return <>

            <Form>
                <Grid padded="horizontally" stackable columns='equal' centered>
                    <Grid.Column width={6}>
                        <Grid.Row>
                            <Label>
                                Title
                            </Label>

                            <Input id='messageTitleInput' placeholder='Message Title' value={this.state.title}
                                   onChange={evt => this.setState({title: evt.target.value})}/>

                            <Divider hidden/>

                            <Label>
                                Message
                            </Label>
                            <AceEditor
                                theme="monokai"
                                mode='groovy'
                                value={this.state.message}
                                placeholder='Message'
                                width="100%"
                                onChange={(value) => this.setState({message: value})}
                                readOnly={false}
                            />
                        </Grid.Row>
                        <Grid.Row>
                            <Divider hidden/>


                            <Divider/>
                            <Grid padded="horizontally" stackable columns='equal' centered>
                                <Grid.Column>
                                    <Dropdown text={this.state.level} style={{align: 'right'}}>
                                        <Dropdown.Menu>

                                            {this.dropDownOptions().map((option, i) => {
                                                return (
                                                    <Dropdown.Item
                                                        key={i}
                                                        text={option.text}
                                                        onClick={(event, data) => {
                                                            console.log(data.text);
                                                            this.setState({level: data.text.toString() as SystemStatusMessage.LevelEnum})
                                                        }}
                                                    />
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    &emsp;
                                    <IfBox shouldShow={!this.state.onlyOwnCompany}>
                                        <p> Only for Superusers to see -
                                            <Checkbox
                                                type='checkbox'
                                                toggle
                                                checked={this.state.forCustomerToSee}
                                                onChange={() => this.setState({forCustomerToSee: !this.state.forCustomerToSee})}
                                            /> - For Customers to see
                                        </p>
                                    </IfBox>
                                </Grid.Column>
                                <Grid.Column>
                                    <Label>
                                        Start Time
                                    </Label>
                                    <DatePicker
                                        align='right'
                                        selected={this.state.startTime}
                                        onChange={time => this.setState({startTime: time})}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={10}
                                        timeCaption="time"
                                        dateFormat="dd.MM.yy hh:mm aa"
                                    />
                                </Grid.Column>
                            </Grid>
                            <Divider hidden/>


                            <Divider/>
                            <Divider hidden/>
                            <IfBox shouldShow={!this.state.onlyOwnCompany}>
                                <SystemStatusMessageCompanyList
                                    selectedCompanies={this.state.selectedCompanies}
                                    updateAllCompaniesSwitch={(allCompanies => {
                                        this.setState({allCompanies: allCompanies})
                                    })}
                                    updateSelectedCompanies={(c: Company[]) => {
                                        this.setState({
                                            selectedCompanies: c,
                                            allCompanies: (c == null || c.length == 0)
                                        })
                                    }}/>

                            </IfBox>
                            <Button
                                disabled={this.state.message == "" || this.state.title == ""}
                                onClick={(evt) => this.saveNewMessage()}
                                loading={this.state.isLoading}>
                                Save Message
                            </Button>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <Grid padded="horizontally">
                                <Grid.Column width={2}>
                                    <Input id='searchInput' placeholder='Search' value={this.state.searchQuery}
                                           onChange={evt => this.setState({searchQuery: evt.target.value})}/>
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    Active only: <Checkbox
                                    checked={this.state.activeOnly}
                                    onChange={() => this.setState({activeOnly: !this.state.activeOnly})}
                                />
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Dropdown
                                        selectOnBlur={false}
                                        clearable
                                        options={this.enumToArray()}
                                        selection
                                        value={this.state.filterLevel}
                                        onChange={(event, data) => {
                                            console.log(data.text);
                                            this.setState({filterLevel: data.value as SystemStatusMessage.LevelEnum})
                                        }}/>
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Button icon onClick={() => this.loadMessages()}><Icon name='sync'/></Button>
                                </Grid.Column>
                            </Grid>
                        </Segment>
                        {this.renderTable()}
                    </Grid.Column>
                </Grid>
            </Form>

            <Notifications/>

            <IfBox shouldShow={this.state.selectedMessage != null}>

                <SystemStatusMessageModal
                    isOpen={this.state.showMessage}
                    onClose={() => this.setState({showMessage: false, selectedMessage: null})}
                    message={this.state.selectedMessage}
                    onlyOwnCompany={this.state.onlyOwnCompany}
                />
            </IfBox>

        </>;
    }

    async fetchCompaniesByIds(ids: string[]) {

        let companies = []
        let auth = await backend.withTokenAuthHeader(authentication.token)
        for (const id of ids) {
            companies.push((await backend.companyApi.queryCompanies(id, false, auth)).results[0])
        }
        console.log("fetched companies")
        this.setState({selectedCompanies: companies, allCompanies: !(companies.length > 0)})
        return companies
    }

    dropDownOptions() {
        let opt = []
        Object.values(SystemStatusMessage.LevelEnum).forEach(value => {
            opt.push({text: value})
        })
        return opt
    }

    formatList(list: string[] | null) {
        let str = ""
        if (list == null) {
            return null
        }
        list.forEach(value => {
            if (str == "") {
                str = value
            } else {
                str = str + ", " + value
            }
        })
        return str
    }

    renderTable() {
        const columns = [
            {
                id: 'active',
                Header: 'Active',
                width: 50,
                accessor: (d: SystemStatusMessage) => d,
                Cell: (d: any) => {
                    if (d.value?.startTime != null && d.value?.startTime != undefined) {
                        return <span>{isActive(d.value.startTime, d.value.endTime)}</span>
                    } else {
                        return <span>unreadable</span>
                    }
                },
                sortField: 'ACTIVE'
            }, {
                id: '_id',
                Header: 'ID',
                width: 30,
                accessor: (d: SystemStatusMessage) => d._id,
                sortField: 'ID'
            }, {
                id: 'forCustomerToSee',
                Header: 'seen by',
                width: 100,
                accessor: (d: SystemStatusMessage) => {
                    if (d.forCustomerToSee) {
                        return <span>customers</span>
                    } else {
                        return <span>superUser</span>
                    }
                },
                sortField: 'FOR_CUSTOMERS_TO_SEE'
            }, {
                id: 'title',
                Header: 'title',
                width: 250,
                accessor: (d: SystemStatusMessage) => {
                    if (d.message) {
                        return d.message.title
                    } else {
                        return <span>unreadable</span>
                    }
                },
                sortField: 'TITLE'
            }, {
                id: 'startTime',
                Header: 'Start Time',
                width: 150,
                accessor: (d: SystemStatusMessage) => d.startTime,
                Cell: (d: any) => {
                    return <span>{formatDate(d.value)}</span>
                },
                sortField: 'START_TIME'
            }, {
                id: 'endTime',
                Header: 'End Time',
                width: 150,
                accessor: (d: SystemStatusMessage) => d.endTime,
                Cell: (d: any) => {
                    return <span>{formatDate(d.value)}</span>
                },
                sortField: 'END_TIME'
            }, {
                id: 'createdAt',
                Header: 'CreatedAt',
                width: 150,
                accessor: (d: SystemStatusMessage) => d.createdAt,
                Cell: (d: any) => {
                    return <span>{formatDate(d.value)}</span>
                },
                sortField: 'CREATED_AT'
            }, {
                id: 'createdBy',
                Header: 'CreatedBy',
                width: 150,
                accessor: (d: SystemStatusMessage) => {
                    if (d.createdBy != null && d.createdBy != undefined) {
                        return d.createdBy
                    } else {
                        return ""
                    }
                },
                Cell: (d: any) => {
                    return d.value
                },
                sortField: 'CREATED_BY'
            }, {
                id: 'level',
                Header: 'Level',
                width: 100,
                accessor: (d: SystemStatusMessage) => d.level,
                sortField: 'LEVEL'
            }, {
                id: 'message',
                Header: 'Message',
                width: 500,
                accessor: (d: SystemStatusMessage) => {
                    if (d.message) {
                        return d.message.message
                    } else {
                        return <span>unreadable</span>
                    }
                },
                sortField: 'MESSAGE'
            }, {
                id: 'affectedTransportOrders',
                Header: 'Affected TransportOrders',
                width: 300,
                accessor: (d: SystemStatusMessage) => {
                    return this.formatList(d.affectedTransportOrders)
                },
                sortField: 'NONE'
            }, {
                id: 'lastOccurrence',
                Header: 'lastOccurrence',
                width: 200,
                accessor: (d: SystemStatusMessage) => {
                    return formatDate(d.lastOccurrence)
                },
                sortField: 'NONE'
            }, {
                id: 'groupKey',
                Header: 'groupKey',
                width: 200,
                accessor: (d: SystemStatusMessage) => {
                    return d.groupKey
                },
                sortField: 'NONE'
            }, {
                id: 'isRelevantForLogeniosInfrastructureMonitoring',
                Header: 'isRelevantForLogeniosInfrastructureMonitoring',
                width: 300,
                accessor: (d: SystemStatusMessage) => {
                    if (d.isRelevantForLogeniosInfrastructureMonitoring) {
                        return "true"
                    } else {
                        return "false"
                    }
                },
                sortField: 'NONE'
            }

        ]

        if (this.state.messages == null) {
            return <p>Loading</p>
        }
        const data: any[] | null = this.fillDummyEntries(this.state.messages, this.state.count)

        return <ReactTable
            data={data}
            pages={Math.ceil(this.state.count / this.state.take)}
            columns={columns}
            sorted={[
                {
                    id: 'CREATED_AT',
                    desc: false
                }
            ]}
            onSortedChange={(newSorted, column, shiftKey) => {
                this.changeSort(column.sortField)
            }}

            defaultPageSize={this.state.take}
            className="-striped -highlight"
            loading={this.state.isLoading}
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: async () => {
                        if (rowInfo) {
                            await this.showMessage(rowInfo.row._id)
                        }
                    }
                };
            }}
            style={{cursor: "pointer"}}
            onPageChange={(pageIndex) => {
                console.log("changing pageIndex -> ", pageIndex)
                this.changePage(pageIndex)
            }}
            onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}
        />
    }

    fillDummyEntries(data: SystemStatusMessage[], totalAmount: number) {
        let dummy = {} as SystemStatusMessage
        let currentEntries: SystemStatusMessage[] = []
        console.log("found " + data.length + " entries of a total of " + totalAmount)

        let indexOffset = (this.state.page - 1) * this.state.take
        console.log("offset: " + indexOffset)

        let firstActualFileIndex = indexOffset
        console.log("firstActualFileIndex: " + firstActualFileIndex)

        let lastActualFileIndex = indexOffset + this.state.take - 1
        console.log("lastActualFileIndex: " + lastActualFileIndex)

        for (let i = 0; i < totalAmount; i++) {

            if (i < firstActualFileIndex) {
                currentEntries.push(dummy)
            } else if (i >= firstActualFileIndex) {
                if (i <= lastActualFileIndex) {
                    console.log("take index: " + (i - indexOffset))

                    currentEntries.push(data[i - indexOffset])
                    console.log("took: " + data[i - indexOffset])

                } else {
                    currentEntries.push(dummy)
                }

            }
        }

        while (currentEntries.length < totalAmount) {

            console.log("adding a dummy")
            currentEntries.push(dummy)
        }

        console.log("new total amount: " + currentEntries.length)

        return currentEntries
    }

    async showMessage(id) {
        let message = this.state.messages.find((m) => {
            if (m._id == id) return m
        })
        if (message != null) {
            this.setState({
                selectedMessage: message,
                showMessage: true
            })
        } else {
            console.log("something went wrong: selected message not found")
        }
    }

    changePage(pageIndex: number) {
        console.log("NEW PAGEINDEX: ", pageIndex)
        this.setState({isLoading: true})
        this.loadMessages(this.state.take, pageIndex + 1)
    }

    changePageSize(newSize: number) {
        this.setState({isLoading: true})
        this.loadMessages(newSize, this.state.page)
    }

    changeSort(sortBy: 'ID' | 'FOR_CUSTOMERS_TO_SEE' | 'START_TIME' | 'END_TIME' | 'TITLE' | 'CREATED_AT' | 'CREATED_BY' | 'LEVEL' | 'MESSAGE' | 'ACTIVE' | "NONE") {
        console.log("sort clicked!!!")
        if (sortBy == 'NONE' || sortBy == 'ACTIVE') {
            return
        }
        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({sortDirection: direction})
        } else {
            //change sortBy and reset direction
            this.setState({sortBy: sortBy, sortDirection: "ASC"})
        }

        //refresh
        this.loadMessages()
    }
}