import * as React from "react";
import {Accordion, Button, Checkbox, Icon, Segment, Table, TextArea} from "semantic-ui-react";
import {authentication} from "../../../../../../authentication";
import {ConfigFieldDescription, SaveConfigFieldDescriptionRequest} from "../../../../../../generated";
import {backend} from "../../../../../../xconvert-backend";
import {IfBox} from "../../../../../style/if";
import {notify} from 'react-notify-toast';
import {formatDate} from "../../../../../../format";
import ReactMarkdown from 'react-markdown'
import {fieldDescriptions} from "../../../../../style/page_with_sidebar";
import {focusesOnField} from "../newNavTree/NavTree";

export interface FieldDescriptionViewProps {

}


export function FieldDescriptionView(props: React.PropsWithChildren<FieldDescriptionViewProps>) {


    const [editMode, setEditMode] = React.useState(false)
    const [editDescription, setEditDescription] = React.useState("")
    const [directFieldDescription, setDirectFieldDescription] = React.useState<ConfigFieldDescription | null>(null)
    const [ancestorFieldDescriptions, setAncestorFieldDescriptions] = React.useState<ConfigFieldDescription[]>([])
    const [activeIndex, setActiveIndex] = React.useState(-1)

   /* React.useEffect(() => {
        console.log("Props changed to ", props.fieldPath)
        if (props.fieldPath == null) {
            return
        }

        findMatchingDescriptions()
    }, [props.fieldPath])*/

    function resetState() {
        setEditMode(false)
        setEditDescription("")
        setDirectFieldDescription(null)
        setAncestorFieldDescriptions([])
    }

    function findMatchingDescriptions() {
        resetState()
        let cleanPath = cleanFieldPath(focusesOnField.value.path)
        let ancestors = []
        fieldDescriptions.value.forEach(entry => {
            if (entry.fieldPath == cleanPath) {
                setDirectFieldDescription(entry)
                setEditDescription(entry.description)
            } else {
                if (entry.fieldPath.startsWith(cleanPath)) {
                    // it's an ancestor but we only want direct ancestors
                    if (entry.fieldPath.split(".").length == cleanPath.split(".").length + 1) {
                        ancestors.push(entry)
                    }
                }
            }
        })
        setAncestorFieldDescriptions(ancestors)

    }


    async function save() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let request = {} as SaveConfigFieldDescriptionRequest
        request.description = editDescription
        request.fieldPath = cleanFieldPath(focusesOnField.value.path)
        let response = await backend.internalApi.saveConfigFieldDescription(request, auth)
        if (response.successful) {
            notify.show('Saved description for .' + request.fieldPath, 'success', 3000, '#28f751');
            setEditMode(false)
            await loadFieldDescriptions()
        } else {
            notify.show("ERROR: couldn't save description", 'error', 5000, '#fc0303')
        }
    }

    function cleanFieldPath(fieldPath: string) {
        let specificConfigurationRegex = /\.specificConfiguration\.[0-9]*\.override\./gmi
        let specificConfigurationsRegex = /\.specificConfigurations\.[0-9]*\.override\./gmi
        let IndexRegex = /(\.[0-9]*\.)/gm

        let final = fieldPath?.replace(specificConfigurationRegex, ".")
        final = fieldPath?.replace(specificConfigurationsRegex, ".")
        final = final?.replace(IndexRegex, ".$.")
        final = final?.replace("$.CompanyConfiguration.", "$.")
        return final
    }

    function info() {
        return <Segment>
            <Accordion>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={() => setActiveIndex(activeIndex === 0 ? -1 : 0)}
                >
                    <Icon name='dropdown'/>how to use markdown:
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>

                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Element</Table.HeaderCell>
                                <Table.HeaderCell>Markdown Syntax</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Heading</Table.Cell>
                                <Table.Cell># H1, ## H2, ### H3</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Bold</Table.Cell>
                                <Table.Cell>**bold text**</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Italic</Table.Cell>
                                <Table.Cell>*italicized text*</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Blockquote</Table.Cell>
                                <Table.Cell>{"> blockquote"}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Ordered List</Table.Cell>
                                <Table.Cell>1. First item</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Unordered List</Table.Cell>
                                <Table.Cell>- First item</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Code</Table.Cell>
                                <Table.Cell>`code`</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Horizontal Rule</Table.Cell>
                                <Table.Cell>---</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Link</Table.Cell>
                                <Table.Cell>[title](https://www.example.com)</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Image</Table.Cell>
                                <Table.Cell>![alt text](image.jpg)</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                    <p>
                        <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">Markdown
                            Guide</a>
                    </p>
                </Accordion.Content>
            </Accordion>
        </Segment>
    }

    function preview() {
        return <>
            <h4>Preview:</h4>
            <Segment>
                <ReactMarkdown children={editDescription?.toString()}/>
            </Segment>
        </>
    }

    function drawFieldDescription(fieldDescription: ConfigFieldDescription, editable: boolean) {

        let content = <Segment>
            <ReactMarkdown children={fieldDescription.description?.toString()}/>
        </Segment>

        let style = {}

        if (editable) {
            content = <>
                Edit: <Checkbox
                    type='checkbox'
                    checked={editMode}
                    toggle
                    onChange={() => setEditMode(!editMode)}
                />

                <IfBox shouldShow={((!editMode) && editDescription != null)}>
                    <Segment>
                        <ReactMarkdown children={editDescription?.toString()}/>
                    </Segment>
                </IfBox>

                <IfBox shouldShow={editMode}>
                    <Button type="button" primary onClick={() => save()}>Save</Button>
                    <TextArea
                        style={{width: "100%", height: "220px"}}
                        readOnly={!editMode}
                        value={editDescription}
                        onChange={(event) => setEditDescription(event.currentTarget.value)}
                    />
                </IfBox>

                <IfBox shouldShow={editMode}>
                    {info()}
                    {preview()}


                </IfBox>

            </>
            style = {backgroundColor: "lightgrey"}
        }

        let fieldName = fieldDescription.fieldPath.split(".").pop()

        return <Segment style={style}>
            <div style={{display: "flex", flexDirection: "row"}}>
                <h4>{fieldName}</h4>
                <p style={{paddingLeft: 20}}>({cleanFieldPath(fieldDescription.fieldPath)})</p>
            </div>
            {content}
            <p>Edited by {fieldDescription.lastModifiedBy} at {formatDate(fieldDescription.lastModifiedAt)} </p>
        </Segment>

    }

    function drawAllFieldDescriptions() {
        if (focusesOnField.value == null) {
            return <>Please select a label or a headline in the NavTree.</>
        }
        let segments = []
        if (directFieldDescription) {
            segments.push(drawFieldDescription(directFieldDescription, true))
        } else {
            segments.push(drawFieldDescription({
                fieldPath: cleanFieldPath(focusesOnField.value.path),
                description: focusesOnField.value.description
            }, true))
        }
        ancestorFieldDescriptions.forEach(d => {
            segments.push(drawFieldDescription(d, false))
        })
        return <>{segments}</>
    }

    return <>
        {drawAllFieldDescriptions()}
    </>
}

export async function loadFieldDescriptions() {
    let auth = backend.withTokenAuthHeader(authentication.token)
    let response = await backend.internalApi.getConfigFieldDescriptions(auth)
    fieldDescriptions.value = response.fieldDescriptionMap
}