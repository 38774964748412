import * as React from "react";
import { PageWithSidebar } from "../../../style/page_with_sidebar";
import DocViewerTabView from "./docViewerTabView";
import {ConfigContext} from "../../context/ConfigContext";


export class DocViewer extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        console.log("render doc viewer")
        return <PageWithSidebar activeMenu="DocViewer">
            <DocViewerTabView context={this.context}/>
        </PageWithSidebar>
    }

}