import * as React from 'react';
import {SingleDatePicker} from "react-dates";
import 'react-dates/initialize';
import '../style/CustomDateRangePicker.css';
import * as moment from "moment";
import {Moment} from "moment";


export interface CustomSingleDatePickerProps {
    date?: Moment,
    id?: string,
    onDateSelected: (date?: Moment) => (void),
    onlyFutureDates?: boolean
}

export interface CustomSingleDatePickerState {
    focusedInput: boolean,
    date?: Moment,
    fullscreen: false,
    direction: 'left',
    dateFormat: 'DD.MM.YYYY',
    small: false,
    block: boolean,
    orientation: 'horizontal',
    numMonths: 1,
    minimumNights: 0,
    isOutsideRange: false,
    onlyFutureDates: boolean | undefined
}

export class CustomSingleDatePicker extends React.Component<CustomSingleDatePickerProps, CustomSingleDatePickerState> {

    constructor(props: CustomSingleDatePickerProps) {
        super(props)
        this.state = {
            focusedInput: false,
            date: this.props.date,
            fullscreen: false,
            direction: 'left',
            dateFormat: 'DD.MM.YYYY',
            small: false,
            block: false,
            orientation: 'horizontal',
            numMonths: 1,
            minimumNights: 0,
            isOutsideRange: false,
            onlyFutureDates: (this.props.onlyFutureDates || this.props.onlyFutureDates == undefined) ? true : false
        }


        this.handleDateChange = this.handleDateChange.bind(this)
        this.handleFocusChange = this.handleFocusChange.bind(this)
    }

    componentDidMount() {
        this.useEffect()
    }

    componentDidUpdate(prevProps: CustomSingleDatePickerProps) {
        if (prevProps.date != this.props.date) {
            this.setState({date: this.props.date})
        }
        this.changePopupPosition()
    }

    onClose = () => {
        setTimeout(() => {
            this.props.onDateSelected(this.state.date)
        }, 1)
    }

    handleDateChange(date: any) {
        let startMoment = null
        if (date != null) {
            startMoment = date
        }

        this.setState({date: startMoment})
    }

    handleFocusChange(focusedInput: any) {
        this.setState({focusedInput: focusedInput.focused})
    }

    returnYears = () => {
        let years = []
        for (let i = moment().year() - 5; i <= moment().year() + 10; i++) {
            years.push(<option value={i}>{i}</option>);
        }
        return years;
    }

    renderMonthElement = ({month, onMonthSelect, onYearSelect}: any) =>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div>
                <select
                    style={{width: 'auto', padding: '.2em 0.2em'}}
                    value={month.month()}
                    onChange={(e) => onMonthSelect(month, e.target.value)}
                >
                    {moment.months().map((label, value) => (
                        <option value={value}>{label}</option>
                    ))}
                </select>
            </div>
            <div>
                <select
                    style={{width: 'auto', padding: '.2em 0.2em'}}
                    value={month.year()}
                    onChange={(e) => onYearSelect(month, e.target.value)}>
                    {this.returnYears()}
                </select>
            </div>
        </div>

    checkDate = (e: KeyboardEvent): void => {
        const evt = e.target as HTMLInputElement;
        if (evt && evt.value) {
            const val = evt.value as string;
            const possibleDate = moment(val, 'DD.MM.YYYY');
            const strings = val.split('.');
            if (possibleDate.toString() !== 'Invalid date' && strings[2] && strings[2].length === 4) {
                console.log("setDate: ", possibleDate)
                this.handleDateChange(possibleDate.set("hour", 12))
                setTimeout(() => {
                    this.props.onDateSelected(this.state.date)
                }, 1)
            }
        }
    }

    useEffect = ((): (() => void) => {
        console.log("id: ", this.props.id)
        console.log(document)
        const datePicker = document.getElementById("editShipmentFormConfigModal_ValidUntilDate")
        console.log("useEffect, datePicker:", datePicker)


        if (datePicker) {
            console.log("addEventListener")
            datePicker.addEventListener('keyup', this.checkDate);
        }
        return (): void => {
            if (datePicker) {
                console.log("removeEventListener")
                datePicker.removeEventListener('keyup', this.checkDate);
            }
        }
    })

    changePopupPosition() {
        if (this.props.id) {
            let doc = document.getElementById(this.props.id)
            if (doc) {
                const boundingClientRect = doc.getBoundingClientRect()
                const offsetBottom = boundingClientRect.y + doc.offsetHeight
                const inputFieldsBottomPosition = window.innerHeight - offsetBottom
                let classesToAdd = ""
                if (inputFieldsBottomPosition < 350) {
                    // 350px is the height of the popup
                    classesToAdd += " topPopup"
                    let fang = document.getElementsByClassName("DateInput_fang")[0]
                    if (fang) {
                        fang.setAttribute("style", "top: -10px;");
                    }
                }
                const inputFieldsRightPosition = window.innerWidth - boundingClientRect.x
                if (inputFieldsRightPosition < 320) {
                    // 320px is the width of the popup
                    classesToAdd += " leftPopup"
                }
                let picker = document.getElementsByClassName("SingleDatePicker_picker")[0]
                if (picker) {
                    const classes = picker.getAttribute("class") + classesToAdd
                    picker.setAttribute("class", classes);
                }
            }
        }
    }

    render() {
        return <SingleDatePicker
            date={this.state.date} // momentPropTypes.momentObj or null
            onDateChange={this.handleDateChange} // PropTypes.func.isRequired
            focused={this.state.focusedInput} // PropTypes.bool
            onFocusChange={this.handleFocusChange} // PropTypes.func.isRequired,
            id={this.props.id} // PropTypes.string.isRequired,
            numberOfMonths={this.state.numMonths || 2}
            onClose={this.onClose}
            displayFormat={'DD.MM.YYYY HH:mm'}
            renderMonthElement={this.renderMonthElement}
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={this.state.onlyFutureDates ? undefined : () => this.state.isOutsideRange}
        />
    }
}