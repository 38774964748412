import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {TransportOrder} from "../../../../../generated";
import AceEditor from "react-ace";
import {Button, Icon, Label} from "semantic-ui-react";
import {TransportOrderSaveModal} from "../modals/TransportOrderSaveModal";
import {TransportOrderSourceAndTargetViewModal} from "../modals/TransportOrderSourceAndTargetViewModal";
import {IfBox} from "../../../../style/if";

export interface TransportOrderEditViewProps {
    reload: (reload: boolean) => void
    transportOrder: TransportOrder
}

export interface TransportOrderEditViewState {
    isLoading: boolean,
    transportOrderStr: string
    transportOrder: TransportOrder
    validJson: boolean
    showSaveModal: boolean
    showSourceAndTargetViewModal: boolean
}


export class TransportOrderEditView extends React.Component<TransportOrderEditViewProps, TransportOrderEditViewState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading HistoryConfig")

        this.state = {
            isLoading: true,
            transportOrderStr: "",
            transportOrder: null,
            validJson: true,
            showSaveModal: false,
            showSourceAndTargetViewModal: false,
        }
    }

    componentDidMount() {
        this.updateTransportOrder(this.props.transportOrder)
    }

    componentWillUpdate(nextProps: Readonly<TransportOrderEditViewProps>, nextState: Readonly<TransportOrderEditViewState>, nextContext: any) {
        if (nextProps.transportOrder != this.props.transportOrder && nextProps.transportOrder != null) {
            console.log("INCOMING TRANSPORT ORDER IS ", nextProps.transportOrder)

            this.updateTransportOrder(nextProps.transportOrder)
        }
    }

    handleTransportOrderChange(newEvent) {
        //this.context.setTransportOrder(newEvent)
        this.setState({transportOrderStr: newEvent})
        try {
            let to = JSON.parse(newEvent)
            this.setState({validJson: true, transportOrder: to})

        } catch (e) {
            this.setState({validJson: false})
        }
    }

    updateTransportOrder(to: TransportOrder) {
        if (to) {
            this.setState({transportOrderStr: JSON.stringify(to, null, 4)})
        } else {
            this.setState({transportOrderStr: ""})
        }
        console.log("TRANSPORT ORDER IS ", this.state.transportOrderStr)
    }

    render() {

        return <> {!this.state.validJson ?
            <Label size={'huge'} color='red' pointing="below">INVALID JSON</Label> : null}
            <AceEditor
                theme="monokai"
                mode="json"
                value={this.state.transportOrderStr}
                onChange={(e) => this.handleTransportOrderChange(e)}
                style={{flex: 1}}
                width="100%"
                readOnly={false}
            />
            <div>

                <Button icon disabled={!this.state.validJson} onClick={() => this.setState({showSaveModal: true})}><Icon
                    name='save'/>Save</Button>
                <Button icon disabled={!this.state.validJson}
                        onClick={() => this.setState({showSourceAndTargetViewModal: true})}>sorce- & target-view</Button>
            </div>

            <IfBox shouldShow={this.state.showSourceAndTargetViewModal}>
                <TransportOrderSourceAndTargetViewModal
                    isOpen={this.state.showSourceAndTargetViewModal}
                    onClose={() => {
                        this.setState({showSourceAndTargetViewModal: false})
                    }}
                    transportOrderId={this.getTransportOrderId()}
                />
            </IfBox>
            <TransportOrderSaveModal
                isOpen={this.state.showSaveModal}
                onClose={() => {
                    this.setState({showSaveModal: false})
                }}
                transportOrder={this.state.transportOrder}
                oldTransportOrder={this.props.transportOrder}
            />

        </>
    }

    getTransportOrderId() {
        try {
            return JSON.parse(this.state.transportOrderStr)?._id
        } catch (e) {
            return ""
        }
    }

}
