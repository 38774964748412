import * as React from "react";
import {Button, Grid, Header, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import * as AceDiff from 'ace-diff';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'

import "../../configChange/configEdit/merge/mergeStyle.css";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {AddDefaultsToConfigRequest} from "../../../../../generated";
import {workingConfig} from "../../configChange/ConfigSignal";

const ace = require('brace');

export interface TestCompareConfigModalProps {
    isOpen: boolean
    onClose: () => void
    configToCompare: any

}

export class TestCompareConfigModal extends React.Component<TestCompareConfigModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            configToCompare: "",
            propsFound: false,
            editors: null,
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    async checkForProps(i) {
        if (this.props.configToCompare == null) {
            console.log("check for props: ", i)

            this.sleep(1000).then(() => {
                this.checkForProps(i + 1)
            })
        } else {
            console.log("found props")

            let config = await (this.addDefaultsToConfig(workingConfig.value))
            let aceDiff = new AceDiff({
                ace,
                element: ".acediff",
                left: {
                    content: JSON.stringify(config, null, 4),
                    copyLinkEnabled: false
                },
                right: {
                    content: JSON.stringify(JSON.parse(this.props.configToCompare), null, 4),
                    editable: false
                },
            });
            if (aceDiff.editors) {
                console.log(aceDiff.getEditors().left.getValue())
                this.setState({ editors: aceDiff.getEditors() })
            }
            this.setState({ configToCompare: this.props.configToCompare, propsFound: true })

        }
    }

    async addDefaultsToConfig(config: string) {
        let auth = backend.withTokenAuthHeader(authentication.token)

        let orderCompanyId = null
        if (this.context.exportSimOrderId != null && this.context.exportSimOrderId != "") {
            orderCompanyId = this.context.exportSimOrderId
        }

        let request = {} as AddDefaultsToConfigRequest

        request.config = JSON.parse(config)

        let data = await backend.internalApi.addDefaultsToConfig(request, auth)
        return data.config
    }

    componentDidMount() {
        if (this.props.isOpen && !this.state.propsFound) {
            this.checkForProps(1)
        }
    }

    render() {
        if (this.props.isOpen && !this.state.propsFound) {
            this.checkForProps(1)
        }
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Test Compare Configs' />
            <Modal.Content>

                <Grid padded="horizontally" stackable columns='equal' centered>

                    <Grid.Column >
                        last successful tested config
                    </Grid.Column>
                    <Grid.Column >
                        your tested config
                    </Grid.Column>
                </Grid>
                <div className="acediff" />
                <Button onClick={() => this.handleClose()}>Discard</Button>

            </Modal.Content>
        </Modal>
    }

}