import * as React from "react";
import * as ReactDOM from "react-dom";
import AppRouter from "./router";
import { init } from '@sentry/browser';
import './main.css';

let env = ""
if (process.env.NODE_ENV !== 'production') {
    console.log("dev");
    env = "dev"
} else {
    console.log("prod");
    env = "prod"
    init({
        dsn: 'https://201ebbaa2c894c5392d7f6847cfa23ab@sentry.logenios.com/14',
        environment: env
    });
}

ReactDOM.render(<AppRouter />,
    document.getElementById("example")
);