import * as React from "react";
import {authentication} from "../../../../../authentication";
import {ProcessingInfo, QueuedEvent, StatusForwardRule, TransportOrderChange} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {ConfigContext} from "../../../context/ConfigContext";
import {formatDate} from "../../../../../format";
import ReactTable from "react-table";
import {Button, Checkbox, Grid, Icon, Segment} from "semantic-ui-react";
import AceEditor from 'react-ace';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {ChangeMultipleQueuedEventsModal} from "../modal/ChangeMultipleQueuedEventsModal";
import Notifications, {notify} from 'react-notify-toast';
import ChangeTypesEnum = StatusForwardRule.ChangeTypesEnum;
import TypeEnum = TransportOrderChange.TypeEnum;

export interface QueuedEventsErrorPageProps {

}


export interface QueuedEventsErrorPageState {
    isLoading: boolean,
    take: number
    page: number
    sortBy: 'ID' | 'QUEUE_ID' | 'QUEUE_TYPE' | 'EVENT_TYPE' | 'TIMESTAMP' | 'RATE_LIMITED' | 'SUPPRESSED' | 'PROCESSED' | 'PROCESSING_STATE'
    sortDirection: 'ASC' | 'DESC'
    count: number
    queuedEvents: QueuedEvent[]
    queueType: QueuedEvent.QueueTypeEnum | null
    eventType: QueuedEvent.EventTypeEnum | null
    changeType: ChangeTypesEnum | null
    processingState: ProcessingInfo.StateEnum | null
    processed: 'TRUE' | 'FALSE' | 'NONE'
    selectedEvent: QueuedEvent | null
    transportOrderId: string | null
    allSelected: boolean
    selected: string[]
    showChangeMultipleQueuedEvents: boolean
    queuedEventId: string
    companyMap: CompanyIdToName[]
    allCompanies: boolean
}


class CompanyIdToName {
    id: string
    name: string
}

export class QueuedEventsErrorPage extends React.Component<QueuedEventsErrorPageProps, QueuedEventsErrorPageState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading QueuedEventsPage")

        this.state = {
            isLoading: false,
            take: 20,
            page: 1,
            sortBy: 'TIMESTAMP',
            sortDirection: 'DESC',
            count: 0,
            queuedEvents: [],
            queueType: null,
            eventType: null,
            changeType: null,
            processingState: null,
            processed: null,
            selectedEvent: null,
            transportOrderId: null,
            allSelected: false,
            selected: [],
            showChangeMultipleQueuedEvents: false,
            queuedEventId: null,
            companyMap: [],
            allCompanies: false
        }
    }

    async componentDidMount() {
        this.loadQueuedEvents()
    }

    async loadQueuedEvents(take = this.state.take, page = this.state.page) {

        this.setState({isLoading: true})
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let skip = (page - 1) * take
        let processed = null
        if (this.state.processed == 'TRUE') {
            processed = true
        } else if (this.state.processed == 'FALSE') {
            processed = false
        }

        let companyId = null
        if (!this.state.allCompanies) {
            companyId = this.context.companyId
        }
        let resp = (await backend.internalApi.fetchQueuedEventsForCM(
            null,
            companyId,
            undefined,
            undefined,
            null,
            take,
            null,
            ProcessingInfo.StateEnum.ERROR,
            null,
            null,
            skip,
            this.state.sortBy,
            this.state.sortDirection,
            null,
            auth
        ))

        // get the companyNames
        let companyIdsDistinct = []
        resp.events.forEach(e => {
            if (!companyIdsDistinct.includes(e.companyId)) {
                companyIdsDistinct.push(e.companyId)
            }
        })

        let companyMap = []
        for (const cId of companyIdsDistinct) {
            let companyNameResp = await backend.companyApi.queryCompanies(cId, false, auth)
            companyMap.push({id: cId, name: companyNameResp.results[0].name} as CompanyIdToName)
        }

        this.setState({
            queuedEvents: resp.events,
            companyMap: companyMap,
            count: resp.count,
            take: take,
            page: page,
            isLoading: false
        })
    }

    render() {
        return <>
            <div className={"flexRow"}>
                <div className={"flex1"} style={{padding: "5px"}}>
                    <div>
                        All Companies -
                        <Checkbox
                            type='checkbox'
                            toggle
                            checked={!this.state.allCompanies}
                            onChange={() => {
                                this.setState({allCompanies: !this.state.allCompanies})
                                this.loadQueuedEvents()
                            }}
                        />
                        - this company
                    </div>
                    {this.renderTable()}
                    {this.renderSelectionOptions()}
                </div>
                <div className={"flex1"}>
                    {this.drawEditor()}
                </div>
                <ChangeMultipleQueuedEventsModal
                    isOpen={this.state.showChangeMultipleQueuedEvents}
                    onClose={(success) => {
                        this.setState({showChangeMultipleQueuedEvents: false})
                        if (success) {
                            notify.show('SUCCESS: updated ' + this.state.selected.length + ' queued events', 'success', 3000, '#28f751');
                            this.loadQueuedEvents()
                        }
                    }}
                    queuedEventIds={this.state.selected}
                />
            </div>
            <Notifications/>
        </>
    }

    drawEditor() {
        let content
        if (this.state.selectedEvent == null) {
            content = ""
        } else {
            content = JSON.stringify(this.state.selectedEvent, null, 4)
        }
        return <AceEditor
            theme="monokai"
            style={{flex: 1}}
            mode='groovy'
            value={content}
            placeholder='Message'
            width="100%"
            readOnly={true}
        />
    }

    switchSelectionStateOfItem(id: string) {
        this.setState({isLoading: true})

        let array = [...this.state.selected]; // make a separate copy of the array
        let index = array.indexOf(id)
        if (index == -1) {
            // id not found, adding Id

            this.setState({selected: array.concat(id)})
            console.log("Id added")


        } else {
            // Id found, removing it

            array.splice(index, 1);
            this.setState({selected: array})
            console.log("Id removed")

        }
        this.setState({isLoading: false})
    }

    selectOrUnselectAll() {
        this.setState({isLoading: true})

        if (this.state.allSelected) {
            console.log("select None")
            this.setState({selected: [], allSelected: false})
        } else {
            console.log("select All")
            this.setState({selected: this.state.queuedEvents.map((e) => e._id), allSelected: true})
        }

        this.setState({isLoading: false})

    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    renderSelectionOptions() {
        return <Segment>
            select all -<Checkbox
            checked={this.state.allSelected}
            onChange={() => {
                this.selectOrUnselectAll()
            }}
        />
            <Button
                disabled={this.state.selected.length == 0}
                icon
                onClick={() => this.setState({showChangeMultipleQueuedEvents: true})}>
                <Icon
                    name='pencil'/>
            </Button>


        </Segment>
    }


    renderTable() {
        const columns = [
            {
                id: 'select',
                Header: 'Select',
                width: 50,
                accessor: (d: any) => {
                    return <Checkbox
                        checked={this.state.selected.some(id => id === d._id)}
                        onChange={() => this.switchSelectionStateOfItem(d._id)}
                    />
                },
                sortField: 'NONE'
            }, {
                id: 'company_id',
                Header: 'companyId',
                width: 200,
                accessor: (d: any) => d.companyId,
                sortField: 'COMPANY_ID'
            }, {
                id: 'company_Name',
                Header: 'companyName',
                width: 200,
                accessor: (d: any) => {
                    return this.state.companyMap.find(c => c.id == d.companyId).name
                },
                sortField: 'COMPANY_ID'
            }, {
                id: 'queueType',
                Header: 'queueType',
                width: 150,
                accessor: (d: any) => d.queueType,
                sortField: 'QUEUE_TYPE'
            }, {
                id: 'eventType',
                Header: 'eventType',
                width: 200,
                accessor: (d: any) => d.eventType,
                sortField: 'EVENT_TYPE'
            },
            {
                id: 'orderId',
                Header: 'Order ID',
                width: 100,
                accessor: (d: QueuedEvent) => d.transportOrderEvent?.transportOrder?._id ?? d.positionEvent?.transportOrderId ?? d.etaEvent?.transportOrder?._id,
                Cell: (d: any) => {
                    return <span>{d.value}</span>
                }
            },
            {
                id: 'changeType',
                Header: 'Change Type',
                width: 150,
                accessor: (d: QueuedEvent) => d.transportOrderEvent?.change?.type ?? "",
                Cell: (d: any) => {
                    return <span>{d.value}</span>
                }
            },
            {
                id: 'status',
                Header: 'Change Value',
                width: 150,
                accessor: (d: QueuedEvent) => {
                    let obj = d.transportOrderEvent?.change?.modifiedObject
                    switch (d.transportOrderEvent?.change?.type) {
                        case TransportOrderChange.TypeEnum.STATUSUPDATEFREIGHT:
                            return obj.freight?.status?.statusCode?.toString()
                        case TypeEnum.STATUSUPDATETRANSPORTORDER:
                            return obj.transportOrder?.status?.statusCode?.toString()
                        case TypeEnum.STATUSUPDATESTOPLOCATION:
                            return obj.stop?.status?.statusCode?.toString()
                        case TypeEnum.STATUSUPDATESTOPACTION:
                            return obj.action?.status?.statusCode?.toString()
                        case TypeEnum.ASSIGNCOMPANY:
                            return d.transportOrderEvent?.transportOrder?.targetCompanyName
                        case TypeEnum.ATTACHMENTADD:
                            return obj.attachment?.name
                        case TypeEnum.ASSIGNVEHICLE:
                            return d.transportOrderEvent?.transportOrder?.assignedVehicle?.numberPlate
                        case TypeEnum.ASSIGNTRAILER:
                            return d.transportOrderEvent?.transportOrder?.assignedTrailer?.numberPlate
                        case TypeEnum.ASSIGNDEVICE:
                            let dev = d.transportOrderEvent?.transportOrder?.assignedDevice
                            return dev.emailAddress ?? dev.phoneNr
                        default:
                            return ""
                    }

                },
                Cell: (d: any) => {
                    return <span>{d.value}</span>
                }
            },
            {
                id: 'timestamp',
                Header: 'timestamp',
                width: 150,
                accessor: (d: any) => d.timestamp,
                Cell: (d: any) => {
                    return <span>{formatDate(d.value)}</span>
                },
                sortField: 'TIMESTAMP'
            }, {
                id: 'rateLimited',
                Header: 'rateLimited',
                width: 100,
                accessor: (d: any) => {
                    if (d.rateLimited) {
                        return <span>true</span>
                    } else {
                        return <span>false</span>
                    }
                },
                sortField: 'RATE_LIMITED'
            }, {
                id: 'suppressed',
                Header: 'suppressed',
                width: 100,
                accessor: (d: any) => {
                    if (d.suppressed) {
                        return <span>true</span>
                    } else {
                        return <span>false</span>
                    }
                },
                sortField: 'SUPPRESSED'
            }, {
                id: 'processed',
                Header: 'processed',
                width: 100,
                accessor: (d: any) => {
                    if (d.processed) {
                        return <span>true</span>
                    } else {
                        return <span>false</span>
                    }
                },
                sortField: 'SUPPRESSED'
            }, {
                id: 'processingState',
                Header: 'processingState',
                width: 120,
                accessor: (d: any) => d.processingInfo?.state,
                sortField: 'PROCESSING_STATE'
            }, {
                id: '_id',
                Header: 'ID',
                width: 200,
                accessor: (d: any) => d._id,
                sortField: 'ID'
            }, {
                id: 'queueId',
                Header: 'queueId',
                width: 200,
                accessor: (d: any) => d.queueId,
                sortField: 'QUEUE_ID'
            }
        ]

        if (this.state.queuedEvents == null) {
            return <p>Loading</p>
        }

        return <ReactTable
            data={this.state.queuedEvents}
            manual
            pages={Math.ceil(this.state.count / this.state.take)}
            columns={columns}
            sorted={[
                {
                    id: 'TIMESTAMP',
                    desc: false
                }
            ]}
            onSortedChange={(newSorted, column, shiftKey) => {
                this.changeSort(column.sortField)
            }}

            defaultPageSize={this.state.take}
            className="-striped -highlight"
            loading={this.state.isLoading}
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: () => {
                        this.setState({selectedEvent: rowInfo.row._original})
                    }
                };
            }}
            style={{cursor: "pointer"}}
            onPageChange={(pageIndex) => {
                console.log("changing pageIndex -> ", pageIndex)
                this.changePage(pageIndex)
            }}
            onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}
        />
    }


    changePage(pageIndex: number) {
        console.log("NEW PAGEINDEX: ", pageIndex)
        this.setState({isLoading: true})
        this.loadQueuedEvents(this.state.take, pageIndex + 1)
    }

    changePageSize(newSize: number) {
        this.setState({isLoading: true})
        this.loadQueuedEvents(newSize, this.state.page)
    }

    changeSort(sortBy: 'ID' | 'QUEUE_ID' | 'QUEUE_TYPE' | 'EVENT_TYPE' | 'TIMESTAMP' | 'RATE_LIMITED' | 'SUPPRESSED' |
        'PROCESSING_STATE' | 'NONE') {
        console.log("sort clicked!!!")
        if (sortBy == 'NONE') {
            return
        }
        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({sortDirection: direction})
        } else {
            //change sortBy and reset direction
            this.setState({sortBy: sortBy, sortDirection: "DESC"})
        }

        //refresh
        this.loadQueuedEvents()
    }
}