import * as React from "react";
import {Tab} from "semantic-ui-react";
import {ReportScriptResults} from "./ReportScriptResults";
import {LogView} from "../../logView/LogView";


const panes = [
    {menuItem: 'Results', render: props => <Tab.Pane>
            <ReportScriptResults
                response={props.response}
            />
    </Tab.Pane>},
    {
        menuItem: 'Logs', render: props => <Tab.Pane>
            <LogView
                query={props.xRequestId}
                origin={"reportScript"}
            />
        </Tab.Pane>
    },
]

const ReportScriptResultTabMenu = props => <Tab
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.reportScriptResultTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("reportScriptResultTab", data.activeIndex)
    }}
/>

export default ReportScriptResultTabMenu