import * as React from "react";
import {Button, Grid, Header, Modal} from "semantic-ui-react";
import {Origin, TransportOrder, TransportOrderChange} from "../../../../../../generated";
import Notifications from 'react-notify-toast';
import {CustomDiff} from "../../../configChange/configEdit/diff/CustomDiff";

export interface TransportOrderRollbackModal_OLDProps {
    isOpen: boolean
    onClose: (reload: boolean) => void
    transportOrder: TransportOrder
    followingConfig: Origin
    change: TransportOrderChange
}

export class TransportOrderRollbackModal_OLD extends React.Component<TransportOrderRollbackModal_OLDProps, any> {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    handleClose = () => {
        this.props.onClose(false)
    }

    buildOldTo() {
        // getting current To
        let to = this.props.transportOrder

        // iterating all patches from patchSet
        let jp = require('jsonpath')
        this.props.change?.patchSet.map((patch) => {
            // using JsonPath to insert "oldValue" into every changed path

            var corrected = patch.oldValue
            if (corrected == "null") {
                corrected = null
            }
            jp.value(to, patch.path, corrected)
        })
        // return oldTo
        return to
    }

    render() {
        if (this.props.change != null) {
            return <Modal
                open={this.props.isOpen}
                onClose={this.handleClose}
                onMount={() => {
                }}
                dimmer="blurring"
                size='fullscreen'>
                <Header icon='browser' content='TransportOrder History (rollback view)'/>
                <Modal.Content>
                    <p>This view shows what will change when you rollback to this state. It compares the current state
                        of the TransportOrder
                        with the state it was when this history was written.</p>
                    <Grid padded="horizontally" stackable columns='equal' centered>
                        <Grid.Row>
                            <Grid.Column>Id</Grid.Column>
                            <Grid.Column>{this.props.change.type}</Grid.Column>
                        </Grid.Row><Grid.Row>
                        <Grid.Column>Timestamp</Grid.Column>
                        <Grid.Column>{this.props.change.timestamp}</Grid.Column>
                    </Grid.Row> <Grid.Row>
                        <Grid.Column>comment</Grid.Column>
                    </Grid.Row>
                        <Grid.Row>
                            <Grid.Column><h2>CURRENT</h2></Grid.Column>
                            <Grid.Column><h2>{"--> compared to -->"}</h2></Grid.Column>
                            <Grid.Column><h2>AFTER ROLLBACK</h2></Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <CustomDiff
                        oldJson={JSON.stringify(this.props.transportOrder, null, 4)}
                        newJson={JSON.stringify(this.buildOldTo(), null, 4)}
                    />
                    <Button onClick={() => this.handleClose()}>cancel</Button>
                    <Notifications/>
                </Modal.Content>
            </Modal>
        } else {
            return <p/>
        }
    }

}