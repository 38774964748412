import * as React from "react";
import {
    Button,
    Checkbox,
    Divider,
    Dropdown,
    DropdownItemProps,
    Grid,
    Header,
    Input,
    Label,
    Modal,
    Segment
} from "semantic-ui-react";
import AceEditor from 'react-ace';
import {ConfigContext} from "../../../../context/ConfigContext";
import 'brace/ext/searchbox'
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {ftp} from "../../../../../../xconvert-ftp";
import {AddFileRequest, AddFilesRequest} from "../../../../../../generated-ftp/api";

export interface ExportSimAddFilesToFTPModalProps {
    isOpen: boolean
    onClose: (success: boolean, path: string | null) => void
}

export interface ExportSimAddFilesToFTPModalState {
    addFilePath: string
    files: any[]
    selectedFiles: any[]
    showFile: any
}

export class ExportSimAddFilesToFTPModal extends React.Component<ExportSimAddFilesToFTPModalProps, ExportSimAddFilesToFTPModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            addFilePath: "//ROOT/logenios2customer",
            files: [],
            selectedFiles: [],
            showFile: null
        }
    }

    async handleSave() {

        let request = {} as AddFilesRequest
        request.files = []
        Object.values(this.state.selectedFiles).forEach(file => {
            let fileRequest = {} as AddFileRequest
            fileRequest.base64Content = file.base64Content
            fileRequest.fileName = file.fileName
            fileRequest.encoding = file.encoding

            request.files.push(fileRequest)
        })


        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let response = (await ftp.ftpApi.addFiles(this.state.addFilePath, request, this.context.companyId, auth))

        console.log(response)
        this.props.onClose(response.success, this.state.addFilePath)
    }

    handleClose = () => {
        this.props.onClose(false, null)
    }

    componentDidMount() {
        if (this.context.exportSimOutput) {
            let files = JSON.parse(this.context.exportSimOutput).resultFiles
            this.setState({
                files: files,
                selectedFiles: files
            })

        }
    }

    drawFileSegment(file) {
        return <Segment>
            <Checkbox
                type='checkbox'
                checked={this.state.selectedFiles.find(f => {
                    return f == file
                }) != null}
                onChange={() => {
                    if (this.state.selectedFiles.find(f => {
                        return f == file
                    }) == null) {
                        let newSelectedFiles = this.state.selectedFiles
                        newSelectedFiles.push(file)
                        this.setState({selectedFiles: newSelectedFiles})
                    } else {
                        let newSelectedFiles = this.state.selectedFiles.filter(f => {
                            return f != file
                        })
                        this.setState({selectedFiles: newSelectedFiles})
                    }
                }}
            />

            <Input style={{width: 200}}
                   placeholder='FileName'
                   value={file.fileName}
                   onChange={newFileName => this.handleFileNameChange(file, newFileName.target.value)}
            />
            <Button color={this.isThisFileShown(file)} onClick={() => this.setState({showFile: file})}>show</Button>

        </Segment>
    }

    isThisFileShown(file){
        if(this.state.showFile && this.state.showFile.base64Content == file.base64Content) {
            return 'blue'
        } else {
            return 'grey'
        }
    }

    handleFileNameChange(file, newFileName) {

        let newFile = this.state.files.find(f => {
            return f == file
        })
        newFile.fileName = newFileName

        let newFiles = this.state.files.filter(f => {
            return f != file
        })
        newFiles.push(newFile)

        this.setState({files: newFiles})

        if (this.state.selectedFiles.find(f => {
            return f == file
        }) != null) {
            let newSelectedFiles = this.state.selectedFiles.filter(f => {
                return f != file
            })

            newSelectedFiles.push(newFile)
            this.setState({selectedFiles: newSelectedFiles})
        }

    }

    drawFileList() {
        if (this.state.showFile == null && this.state.files.length > 0) {
            this.setState({
                showFile: this.state.files[0]
            })
        }
        return <Grid>

            {Object.values(this.state.files).map(file => {
                return <Grid.Row>
                    {this.drawFileSegment(file)}
                </Grid.Row>
            })}

        </Grid>
    }

    showFileString() {
        if (this.state.showFile) {
            return atob(this.state.showFile.base64Content)
        } else {
            return ""
        }
    }

    options() {
        let enumValues: Array<DropdownItemProps> = [];
        enumValues.push({ key: "logenios2customer", value: "//ROOT/logenios2customer", text: "logenios2customer" })
        enumValues.push({ key: "EXPORTED", value: "//LOGS/EXPORTED", text: "EXPORTED" })

        return enumValues
    }

    render() {
        let options = this.options()
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Add File'/>
            <Modal.Content>

                {this.drawFileList()}


                <Divider/>
                <Label pointing='below'>File Content: </Label>
                <AceEditor
                    theme="monokai"
                    mode="json"
                    value={this.showFileString()}
                    width="100%"
                    height="800px"
                    readOnly={true}
                />

                <Dropdown
                    selectOnBlur={false}
                    selection
                    options={options}
                    defaultValue={options[0].value}
                    onChange={(event, {value}) => {
                        this.setState({addFilePath: value as string})
                    }}
                />

                <Button disabled={this.state.selectedFiles.length == 0} onClick={() => this.handleSave()}>save</Button>
                <Button onClick={() => this.handleClose()}>close</Button>

            </Modal.Content>
        </Modal>
    }

}