import * as React from "react";
import {PageWithSidebar} from "../../../style/page_with_sidebar";

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import Notifications from 'react-notify-toast';
import QueuesTabView from "./QueuesTabView";
import {ConfigContext} from "../../context/ConfigContext";

export class QueuesPage extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading QueuesPage")

        this.state = {}
    }


    render() {
        return <PageWithSidebar activeMenu="Queues">
            <QueuesTabView context={this.context}/>
            <Notifications/>
        </PageWithSidebar>
    }

}