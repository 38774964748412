import * as React from 'react';
import './box.css';

type IfBoxProps = {
    shouldShow: boolean
    else?: JSX.Element
}

export class IfBox extends React.Component<IfBoxProps, any> {
    render() {
        if (this.props.shouldShow) {
            return this.props.children
        } else if (this.props.else) {
            return this.props.else
        }
        return null
    }
}