import * as React from "react";
import {ConfigContext} from "../../../../context/ConfigContext";
import AceEditor from "react-ace";
import {Button, Form, Label} from "semantic-ui-react";
import {IfBox} from "../../../../../style/if";
import {ImportSimSaveAsTestModal} from "../../../converter/ImportSim/ImportSimSaveAsTestModal";
import { notify } from 'react-notify-toast';
import {TransportOrder} from "../../../../../../generated";

export interface MatrixDocOrderViewProps{

}

export interface MatrixDocOrderViewState{
    isConverting: boolean
    converted: boolean
    showSaveTestModal: boolean
    outputTO: [TransportOrder]
}

export class MatrixDocOrderView extends React.Component<MatrixDocOrderViewProps, MatrixDocOrderViewState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)

        this.state = {
            isConverting: false,
            converted: false,
            showSaveTestModal: false,
            outputTO: null
        }
    }
    componentDidMount() {
        this.setState({ converted: false })
    }

    onSave() {

        if (this.context.matrixDoc == "" && this.context.matrixDocFile == null) {
            notify.show("ERROR: input must be set. Either text or file!", 'error', 5000, '#fc0303')
            return
        }
        let output = null
        if (this.context.matrixDocOrders) {
            output = JSON.parse(this.context.matrixDocOrders)
        }
        this.setState({
            outputTO: output,
            showSaveTestModal: true
        })
    }

    render() {
        return <Form>

            <Label>
                TransportOrder
            </Label>
            <div style={{ float: 'right' }}>
                <Button onClick={() => this.onSave()}>
                    Save as test
                </Button>
            </div>
            <AceEditor
                theme="monokai"
                mode="json"
                value={this.context.matrixDocOrders}
                placeholder="TransportOrder"
                width="100%"
                height="900px"
                readOnly={true}
            />
            <IfBox shouldShow={this.state.showSaveTestModal}>
                <ImportSimSaveAsTestModal
                    isOpen={this.state.showSaveTestModal}
                    onClose={() => this.setState({ showSaveTestModal: false, outputTO: null })}
                    expectedResult={this.state.outputTO}
                    input={this.context.matrixDoc}
                    inputFile={this.context.matrixDocFile}
                    inputActive={this.context.matrixDocInputActive}
                />
            </IfBox>

        </Form>
    }


}