import * as React from "react";
import {Button, Checkbox, Dropdown, Grid, Icon, Input, Label, Popup, Segment} from 'semantic-ui-react';
import {Treebeard} from 'react-treebeard';
import {ConfigContext} from "../../../../context/ConfigContext";
import {IfBox} from "../../../../../style/if";
import {ScriptModal} from "../../../configChange/configEdit/modals/ScriptModal";
import {
    GetTypeRequest,
    GetTypeResponse,
    GetTypesRequest,
    UpdateCompanyConfigRequestWrapper
} from "../../../../../../generated";
import {authentication} from "../../../../../../authentication";
import {backend} from "../../../../../../xconvert-backend";
import {CreateDefaultContractModal} from "./modals/CreateDefaultContractModal";
import {CustomSingleDatePicker} from "../../../../../ui/CustomSingleDatePicker";
import * as moment from 'moment';
import {notify} from 'react-notify-toast';


const swagger = require("../../../../../../../../swaggerInternal.json")

export interface EditContractNavTreeProps {
    saveKeyListener: (e: any) => void
    fieldPathDispatcher: (e: string) => void
    readonly: boolean
}

export interface EditContractNavTreeState {
    isLoading: boolean,
    showScriptModal: boolean,
    showDiscardModal: boolean,
    showCreateConfigModal: boolean,
    showDescriptionFor: any,

    constantConfigForDifference: null,
    isValidConfig: boolean,

    testConfig: boolean,
    verifyResponse: any | null,
    reloadPage: boolean,

    currentScriptPath: any | null,
    currentScriptName: string | null,
    currentScript: string | null
    cancelButton: any | null,
    validationDispatch: any,
    scriptDispatch: any,
    failedTests: [],
    isLoadingSave: boolean,

    justClosed: boolean,
    cursor: any | null,
    data: any | null
    active: boolean,
    values: any[],
    enumValueList: any[],
    readonly: boolean,
    contractCompanyNames: LookedUpCompanyNameList[]
}

export interface LookedUpCompanyNameList {
    listName: string,
    companyNames: string[]
}

export class EditContractNavTree extends React.Component<EditContractNavTreeProps, EditContractNavTreeState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)

        console.log("loading NavTreeCompanyConfig")

        this.state = {
            isLoading: true,
            showScriptModal: false,
            showDiscardModal: false,
            showCreateConfigModal: false,
            showDescriptionFor: null,

            constantConfigForDifference: null,
            isValidConfig: true,

            testConfig: false,
            verifyResponse: null,
            reloadPage: false,

            currentScriptPath: null,
            currentScriptName: null,
            currentScript: "",
            cancelButton: null,
            validationDispatch: (validationObject) => {
                this.setState({
                    verifyResponse: validationObject.validation,
                    testConfig: true,
                    reloadPage: validationObject.validation.isValid
                })
            },
            scriptDispatch: (newScript) => {
                console.log("ScriptDispatcher")
                this.replaceScriptWithScriptModalResponse(newScript)
            },
            failedTests: [],
            isLoadingSave: false,

            justClosed: false,
            cursor: null,
            data: null,
            active: false,
            values: [],
            enumValueList: [],
            readonly: false,
            contractCompanyNames: [],
        }

        this.onToggle = this.onToggle.bind(this);
        this.getType = this.getType.bind(this)
        this.filterOnlyObjects = this.filterOnlyObjects.bind(this);

    }

    componentWillReceiveProps(nextProps: Readonly<EditContractNavTreeProps>, nextContext: any) {
        if (this.context.selectedContractIsExpired) {
            this.setState({readonly: true})
        } else {
            this.setState({readonly: false})
        }
        this.setData()

    }

    componentWillMount() {
        this.setState({cursor: this.context.contractNavTreeCursor})
        this.setData()
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    openScriptModal(data, currentScriptPath, currentScriptName) {
        console.log("opening Script modal")

        this.setState({
            currentScript: data,
            currentScriptPath: currentScriptPath,
            currentScriptName: currentScriptName,
            showScriptModal: true

        })

    }

    render() {
        console.log("RENDER NAV-TREE CONTRACT EDITOR")
        if (this.context.selectedContract == null) {
            return <h1>Select or create a contract</h1>
        } else {
            return <>
                {this.generateEditor()}
                <IfBox shouldShow={this.state.showScriptModal}>

                    <ScriptModal
                        isOpen={this.state.showScriptModal}
                        onClose={() => {
                            console.log("CLOSING ----> scriptModal")
                            this.setState({showScriptModal: false})
                        }}
                        script={this.state.currentScript}
                        dispatcher={this.state.scriptDispatch}
                        saveKeyListener={this.props.saveKeyListener}
                        path={this.state.currentScriptPath + '.' + this.state.currentScriptName}
                        readOnly={this.state.readonly}
                        fieldDescriptionEnabled={true}
                        defaultDescription={this.state.values.find(e => {
                            return e.name == this.state.currentScriptName && e.path == this.state.currentScriptPath
                        })?.defaultDescription}
                    />
                </IfBox>
            </>
        }
    }

    replaceScriptWithScriptModalResponse(newScript) {

        // change in currentJSON
        const jp = require('jsonpath');
        const jsonPath = this.state.currentScriptPath + '.' + this.state.currentScriptName;
        console.log("JSONPATH : ", jsonPath)

        let contract = JSON.parse(this.context.selectedContract)
        jp.value(contract, jsonPath, newScript)
        this.context.setSelectedContract(JSON.stringify(contract, null, 4))

        console.log("setting  showScriptModal to false")
        this.setState({
            showScriptModal: false,
            currentScript: null,
            currentScriptPath: null,
            currentScriptName: null,
            cancelButton: null,
            justClosed: true
        })

        this.setData()
    }

    async setData() {
        console.log("SETTING DATA")
        if (this.context.selectedContract) {
            let data = this.filterOnlyObjects("$", JSON.parse(this.context.selectedContract), [], this.context.contractNavTreeCursor)
            let formattedData = {
                name: "Contract",
                path: "$",
                toggled: true,
                children: data.objects
            }

            this.setState({data: formattedData, values: data.values})

            await this.onToggle(this.state.cursor, true)
        }
    }

    filterOnlyObjects(path, contract, previousValues, cursor): { objects, values } {
        let objects = [];
        let values = previousValues;

        let recursive = (p, c) => {
            return (this.filterOnlyObjects(p, c, values, cursor)).objects
        }

        Object.keys(contract).forEach(function (key) {
            let toggled = false
            if (cursor) {
                toggled = (cursor.path + "." + cursor.name).includes(key)
            }

            if (contract[key] == null) {
                // null
                values.push({
                    type: "null",
                    name: key,
                    path: path,
                    value: contract[key]
                })

            } else {
                let json = JSON.stringify(contract[key])

                if (json.startsWith("{")) {
                    // object

                    objects.push({
                        type: "object",
                        name: key,
                        toggled: toggled,
                        path: path,
                        children: recursive(path + "." + key, contract[key])
                    })

                } else if (json.startsWith("[")) {
                    objects.push({
                        type: "list",
                        name: key,
                        toggled: toggled,
                        path: path,
                        children: recursive(path + "." + key, contract[key])
                    })
                } else {
                    // value (String, number, boolean...)
                    if (json.startsWith("\"")) {
                        values.push({
                            type: "string",
                            name: key,
                            path: path,
                            value: contract[key]
                        })

                    } else if (json == "true" || json == "false") {
                        // boolean

                        values.push({
                            type: "boolean",
                            name: key,
                            path: path,
                            value: contract[key]
                        })

                    } else {
                        // number?!

                        values.push({
                            type: "number",
                            name: key,
                            path: path,
                            value: contract[key]
                        })
                    }
                }
            }
        });

        return {objects: objects, values: values}
    }

    async onToggle(node, toggled) {
        console.log("ON TOGGLE")
        this.setState({isLoading: true})
        const cursor = this.context.contractNavTreeCursor;
        if (cursor) {
            //this.setState({ cursor, active: false });
            this.context.setContractNavTreeCursor(cursor)
        }
        if (node == null) {
            node = this.context.contractNavTreeCursor
        }
        if (node == null) {
            return
        }
        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }

        let values = this.state.values
        if (values == null || values.length == 0) {
            values = this.filterOnlyObjects("$", JSON.parse(this.context.selectedContract), [], node).values
        }
        console.log("VALUES: ", values)
        let elements = await Promise.all(values.filter(value => {
            if (node.name == "Contract") {
                return value.path == node.path
            }
            return value.path == node.path + "." + node.name
        }))
        let responses = (await this.getTypes(elements)).responses

        elements.map(async (element) => {
            let response = responses.find((response) => {
                return response.path == element.path + "." + element.name
            })
            element.isCompanyId = response.isCompanyId
            await this.fixTypeDefinitionIfEnum(element, response)
        })

        this.state.values.filter(value => {
            if (node.name == "Contract") {
                return value.path == node.path
            }
            return value.path == node.path + "." + node.name
        }).map((value) => {
            value = elements.find((e) => e.path + "." + e.name == value.path + "." + value.name)
        })

        console.log("values : " + JSON.stringify(this.state.values, null, 4))
        this.setState({cursor: node});
        this.context.setContractNavTreeCursor(node)

    }

    drawTree() {
        let data = this.state.data
        if (data != null) {
            //console.log("drawTree: ", data)
            return (
                <Treebeard
                    data={data}
                    onToggle={this.onToggle}
                />
            );
        } else {
            return "no contract found"
        }
    }

    async getType(element): Promise<GetTypeResponse> {
        return await this.getTypeByPath(element.path + "." + element.name)
    }

    async getTypeByPath(path): Promise<GetTypeResponse> {
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let request = {} as GetTypeRequest
        request.contract = JSON.parse(this.context.selectedContract)
        request.path = path
        let response = (await backend.internalApi.getType(request, auth))
        return response
    }

    async getTypes(elements: any[]) {
        let paths = elements.map((element) => {

            return element.path + "." + element.name

        })
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let request = {} as GetTypesRequest
        request.contract = JSON.parse(this.context.selectedContract)
        request.paths = paths
        return (await backend.internalApi.getTypes(request, auth))

    }

    async fixTypeDefinitionIfEnum(element, response) {
        if (/^\d+$/.test(element.key)) {
            console.log("key is element of array ")
        } else {
            if (response.isEnum) {
                element.type = "enum"
                element.enumOptions = JSON.parse(response.defaultObject)
                //console.log("FIXED DEF FOR " + element.name)
            }
            if (response.isValueList) {
                element.type = "valueListElement"

                // get Type from Swagger
                let pathArray = element.path.split(".")
                let lastName = pathArray.pop()
                let path = pathArray.join(".")
                let previousResponse = (await this.getTypeByPath(path))

                let type = previousResponse.type.split("$").pop()

                var jp = require('jsonpath');
                var jsonPath = "$.definitions." + type + ".properties." + lastName + ".items.enum"
                let obj = jp.query(swagger, jsonPath);

                element.enumOptions = obj[0]

                //console.log("FIXED DEF FOR " + element.name)
            }
        }
        this.setState({isLoading: false})

    }

    async setValueInContractWithJsonPath(newValue, path, isNumber = false) {

        // change in currentJSON
        const jp = require('jsonpath');

        let contract = JSON.parse(this.context.selectedContract)
        let v = newValue
        if (isNumber) {
            v = Number(newValue)
            if (isNaN(v)) {
                console.log("unable to cast '" + newValue + "' to a number")
                return
            }
        }

        jp.value(contract, path, v)
        await this.context.setSelectedContract(JSON.stringify(contract, null, 4))
        //this.setData()
    }

    addMemberToList(listType) {

        let path = this.state.cursor.path + "." + this.state.cursor.name
        const jp = require('jsonpath');

        // get amount of members
        let config = JSON.parse(this.context.selectedContract)
        let obj = jp.value(config, path)
        let nextNumber = obj.length
        let newValue = {}
        if (listType == "java.lang.String") {
            newValue = ""
        }
        this.setValueInContractWithJsonPath(newValue, path + "." + nextNumber)

        this.formatContract()

    }

    async formatContract() {
        this.setState({isLoading: true})
        let request = {} as UpdateCompanyConfigRequestWrapper
        request.configAsJson = this.context.selectedContract
        let auth = backend.withTokenAuthHeader(authentication.token)

        let response = await backend.internalApi.formatContract(request, auth)

        if (response.errorMessage == null) {
            this.context.setSelectedContract(JSON.stringify(response.contract, null, 4))
        } else {
            notify.show("ERROR: " + response.errorMessage, 'error', 5000, '#fc0303')
        }

        await this.setData()
        this.setState({isLoading: false})

    }

    async createField(element, addMember: boolean) {
        let response = (await this.getType(element))

        let type = response.type;
        let defaultObject = JSON.parse(response.defaultObject);

        console.log("RESPONSE WAS : ", type)
        console.log("DEFAULT OBJECT WAS : ", defaultObject)

        element.isCompanyId = response.isCompanyId

        let refreshTree = false
        if (response.isEnum) {
            await this.fixTypeDefinitionIfEnum(element, response)
        } else if (type == "java.util.List") {
            element.type = "list"
            if (addMember) {
                this.addMemberToList(response.listType)
            }
            refreshTree = true
        } else if (type == "java.lang.String") {
            element.type = "string"
            console.log("create field: " + element.name)

        } else if (type == "java.lang.Boolean") {
            element.type = "boolean"
        } else {
            // object
            // add object to objects and to context contract
            refreshTree = true
        }
        this.onValueChange(defaultObject, element, !response.isEnum)

        if (refreshTree) {
            this.setData()
        }
        this.setState({isLoading: false})

    }

    drawNameLable(cursor, element) {
        return <Label onClick={() => {
            let newPath = cursor.path + "." + cursor.name + "." + element.name

            this.props.fieldPathDispatcher(newPath)

            this.setState({showDescriptionFor: newPath})

        }}>
            {element.name}
        </Label>
    }

    drawCompanyName(listElement, e) {
        if (e.isCompanyId) {
            let nameList = this.state.contractCompanyNames.find(ccn => ccn.listName == listElement.name)
            this.checkForCompany(listElement.name, e)
            if (nameList && nameList.companyNames[e.name]) {
                return <Label color={'green'}>COMPANY - {nameList.companyNames[e.name]}</Label>
            }
            return <Label>COMPANY - N/A</Label>

        }
        return <></>
    }

    async checkForCompany(listName, e) {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let res = await backend.companyApi.queryCompanies(e.value, false, auth)
        let company = res.results.find(c => c._id == e.value)

        let foundEntry = this.state.contractCompanyNames.find(ccn => ccn.listName == listName)
        if (!foundEntry) {
            foundEntry = {
                listName: listName,
                companyNames: []
            } as LookedUpCompanyNameList
        }
        let newList = JSON.parse(JSON.stringify(foundEntry))

        if (company) {
            newList.companyNames[e.name] = company.name
        } else {
            newList.companyNames[e.name] = null
        }
        let newCompleteList = [newList]
        this.state.contractCompanyNames.filter((l) => l.listName != listName).forEach((l) => newCompleteList.push(l))
        let oldList = this.state.contractCompanyNames.filter((l) => l.listName == listName).pop()

        let oldValue = oldList?.companyNames[e.name]
        let newValue = newList?.companyNames[e.name]
        if (oldValue != newValue) {
            this.setState({contractCompanyNames: newCompleteList})
        }
    }

    drawStringValueList(cursor, elements) {
        let pathArr = elements[0].path.split(".")

        let listElement = {
            name: pathArr.pop(),
            path: pathArr.join("."),
            isCompanyId: elements[0].isCompanyId
        }

        console.log("ELEMENTS: ", elements)
        return <div>
            <Grid padded="horizontally" stackable columns='equal' centered>
                {elements.map(e => {
                    return <Grid.Row>
                        <Grid.Column width={1}>
                            {e.name}
                        </Grid.Column>
                        <Grid.Column>
                            {this.drawCompanyName(listElement, e)}
                            <Input
                                readOnly={this.state.readonly}
                                style={{width: 300}} value={e.value}
                                onChange={async (evt) => {
                                    let currElem = elements[e.name]
                                    elements[e.name] = {
                                        name: currElem.name,
                                        path: currElem.path,
                                        type: currElem.type,
                                        isCompanyId: currElem.isCompanyId,
                                        value: evt.target.value
                                    }
                                    await this.onChangeStringList(elements, listElement)
                                }
                                }/>
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                readOnly={this.state.readonly}
                                icon onClick={(evt) => {
                                evt.preventDefault();
                                delete elements[e.name]
                                this.onChangeStringList(elements, listElement)
                            }}><Icon name='trash'/></Button>
                        </Grid.Column>
                    </Grid.Row>
                })}
                <Grid.Row>
                    <Grid.Column>
                        <Button icon onClick={(evt) => {
                            evt.preventDefault();
                            elements.push({
                                name: elements.size + 1,
                                path: elements[0].path,
                                type: "valueListElement",
                                isCompanyId: elements[0].isCompanyId,
                                value: ""
                            })
                            this.onChangeStringList(elements, listElement)

                        }}><Icon name='plus'/></Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>

    }


    drawEnumValueList(cursor, elements) {
        let pathArr = elements[0].path.split(".")
        let listElement = {
            name: pathArr.pop(),
            path: pathArr.join(".")
        }

        let valueList = []
        elements.forEach(e => {
            valueList.push(e.value)
        });
        if (this.state.enumValueList.length != valueList.length) {
            this.setState({enumValueList: valueList})
        }
        return <div>{elements[0].enumOptions.map(
            option => {
                console.log("draw options")
                let checked = this.state.enumValueList.find((it) => it == option) != null

                return <div><Checkbox
                    readOnly={this.state.readonly}
                    value={option}
                    checked={checked}
                    onChange={(event, data) => {
                        valueList = this.state.enumValueList
                        if (valueList.find(v => v == data.value)) {
                            valueList.splice(valueList.indexOf(data.value), 1);
                        } else {
                            valueList.push(data.value)
                        }

                        this.onValueChangeList(valueList, listElement, true, elements[0].enumOptions)
                        checked = !checked
                        this.setState({isLoading: false, enumValueList: valueList})

                    }}/>

                    {option}
                </div>
            }
        )}
        </div>


    }

    drawRightSide(cursor) {
        console.log("DRAW RIGHT SIDE")

        console.log("cursor", cursor)

        if (cursor == null) {
            return "nothing to see here"
        }


        let elements = this.state.values.filter(value => {
            if (cursor.name == "Contract") {
                return value.path == cursor.path
            }

            return value.path == cursor.path + "." + cursor.name
        })

        if (cursor.type == "list") {
            if (elements.length > 0 && elements[0].type == "valueListElement") {
                if (elements[0].enumOptions) {
                    return this.drawEnumValueList(cursor, elements)
                } else {
                    return this.drawStringValueList(cursor, elements)
                }
            }

            return <Grid.Row>
                <Grid.Column>
                    <h2>{cursor.name}</h2>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        readOnly={this.state.readonly}
                        onClick={async (e) => {
                            this.setState({isLoading: true})

                            e.preventDefault(); // prevents reloading the page by default
                            this.createField(cursor, true)
                        }
                        }>add Member</Button>
                </Grid.Column>
            </Grid.Row>

        }

        //TODO: if it's a List -> add/delete member (for value- & object-lists)

        return <div>
            <h2>{cursor.name}</h2>
            <Grid padded="horizontally" stackable columns='equal' centered>

                {Object.values(elements).map((element) => {
                    if (element.name == "validFrom") {
                        return <Grid.Row>
                            <Grid.Column>
                                {this.drawNameLable(cursor, element)}

                            </Grid.Column>
                            <Grid.Column>
                                <CustomSingleDatePicker
                                    date={this.getInitialDate(element.value)}
                                    id={'contract_datepicker'}
                                    onDateSelected={(data) => this.onValueChange(data, element)}
                                    onlyFutureDates={false}
                                >
                                </CustomSingleDatePicker>
                            </Grid.Column>
                        </Grid.Row>
                    } else if (element.type == "enum") {

                        return this.drawEnumDropDown(cursor, element)

                    } else if (element.value == null) {
                        //console.log("NULL VALUE: ", element.name)

                        return <Grid.Row>
                            <Grid.Column>
                                {this.drawNameLable(cursor, element)}
                            </Grid.Column>
                            <Grid.Column>
                                <Button
                                    disabled={this.state.readonly}
                                    onClick={async (e) => {
                                        this.setState({isLoading: true})

                                        e.preventDefault(); // prevents reloading the page by default
                                        await this.createField(element, false)
                                    }
                                    }>create</Button>
                            </Grid.Column>

                        </Grid.Row>


                    } else if (element.type == "number") {
                        return <Grid.Row>
                            <Grid.Column>
                                {this.drawNameLable(cursor, element)}

                            </Grid.Column>
                            <Grid.Column>
                                <Input
                                    readOnly={this.state.readonly}
                                    type='text'
                                    value={element.value}
                                    onChange={(evt) => this.onValueChange(evt.target.value, element)}/>
                            </Grid.Column>

                        </Grid.Row>

                    } else if (element.type == "string") {

                        return <Grid.Row>
                            <Grid.Column>
                                {this.drawNameLable(cursor, element)}

                            </Grid.Column>
                            <Grid.Column>

                                {this.drawTextInput(element)}
                            </Grid.Column>

                        </Grid.Row>

                    } else if (element.type == "boolean") {
                        return <Grid.Row>
                            <Grid.Column>
                                {this.drawNameLable(cursor, element)}

                            </Grid.Column>
                            <Grid.Column>
                                <Checkbox
                                    readOnly={this.state.readonly}
                                    checked={element.value == "true" || element.value == true}
                                    onChange={() => this.onValueChange(!(element.value == "true" || element.value == true), element)}/>
                            </Grid.Column>
                        </Grid.Row>
                    } else {
                        return <Grid.Row>
                            <Grid.Column>
                                {this.drawNameLable(cursor, element)}

                            </Grid.Column>
                            <Grid.Column>
                                Type: {element.type} not implemented
                            </Grid.Column>
                        </Grid.Row>
                    }


                })

                }
            </Grid>
        </div>
    }

    getInitialDate(value) {
        if (value) {
            return moment(new Date(value))
        }
        return moment()
    }

    formatDateTimeForPicker(dateTime) {
        var date = new Date();
        if (dateTime) {
            date = new Date(dateTime)
        } else {
            return ""
        }
    }

    drawTextInput(element) {
        if (element.name.includes("cript") || element.value.length > 40) {
            return <textarea

                id="textarea_scriptedit"
                value={element.value}
                cols={40}
                rows={5}
                readOnly
                onClick={(evt) => {
                    console.log("onClick triggered")
                    this.openScriptModal(evt.currentTarget.value, element.path, element.name)
                }
                }
            />
        } else {
            return <Input

                readOnly={this.state.readonly}
                type='text'
                value={element.value}
                onChange={(evt) => this.onValueChange(evt.target.value, element)}/>

        }
    }

    drawEnumDropDown(cursor, element) {
        let i = 0
        const options = element.enumOptions.map(option => {
            i++;
            return {key: i, text: option, value: option}
        })

        if (element.value == "") {
            element.value = null
        }
        return <Grid.Row>
            <Grid.Column>
                {this.drawNameLable(cursor, element)}

            </Grid.Column>
            <Grid.Column>
                <Dropdown
                    selectOnBlur={false}
                    readOnly={this.state.readonly}
                    clearable
                    options={options}
                    selection
                    value={element.value}
                    onChange={(evt, data) => this.onValueChange(data.value, element)}/>
            </Grid.Column>
        </Grid.Row>
    }

    onValueChange(newValue, element, setInContract = true) {
        console.log("changing value")
        var newValueFixed = newValue
        if (element.name == "validFrom") {
            newValueFixed = newValue.hour(12).minute(0).second(0).millisecond(0)
        }
        let newValues = this.state.values
        newValues.map((e) => {
            if (e.path == element.path && e.name == element.name) {
                e.value = newValueFixed
                e.isCompanyId = element.isCompanyId
            }
        })
        this.setState({values: newValues})

        if (setInContract) {
            // update context
            this.setValueInContractWithJsonPath(newValueFixed, element.path + "." + element.name)
        }
    }

    onValueChangeList(newValueList, element, setInContract = true, enumOptions = null) {
        console.log("changing value List")
        let allValues = this.state.values
        let newValues = allValues.filter((v) => v.path != element.path + "." + element.name)

        let counter = 0
        newValueList.map((nv) => {
            newValues.push({
                enumOptions: enumOptions,
                name: counter,
                path: element.path + "." + element.name,
                type: "valueListElement",
                value: nv.value
            })
            counter++
        })

        this.setState({values: newValues})

        if (setInContract) {
            // update context
            this.setValueInContractWithJsonPath(newValueList, element.path + "." + element.name)
        }
    }

    async onChangeStringList(newValueList, element, setInContract = true) {
        console.log("changing value string List")
        let allValues = this.state.values
        let newValues = allValues.filter((v) => v.path != element.path + "." + element.name)
        let planeNewValues = []
        let counter = 0
        newValueList.map((nv) => {
            newValues.push({
                name: counter,
                path: element.path + "." + element.name,
                type: "valueListElement",
                value: nv.value,
                isCompanyId: element.isCompanyId
            })
            planeNewValues.push(nv.value)
            counter++
        })

        this.setState({values: newValues})

        if (setInContract) {
            // update context
            await this.setValueInContractWithJsonPath(planeNewValues, element.path + "." + element.name)
        }
    }

    generateEditor() {

        let showCreateConfig = false
        if (this.context.selectedContract == "{}") {
            //config empty
            showCreateConfig = true
        }

        return <>
            <IfBox shouldShow={!showCreateConfig}>
                <IfBox shouldShow={this.state.showScriptModal}>
                    <ScriptModal
                        isOpen={this.state.showScriptModal}
                        onClose={() => {
                            console.log("CLOSING ----> scriptModal")
                            this.setState({showScriptModal: false})
                        }}
                        script={this.state.currentScript}
                        dispatcher={this.state.scriptDispatch}
                        saveKeyListener={this.props.saveKeyListener}
                        path={this.state.currentScriptPath + '.' + this.state.currentScriptName}
                        readOnly={this.state.readonly}
                        fieldDescriptionEnabled={true}
                        defaultDescription={this.state.values.find(e => {
                            return e.name == this.state.currentScriptName && e.path == this.state.currentScriptPath
                        })?.defaultDescription}
                    />
                </IfBox>
                <IfBox shouldShow={this.context.selectedContractIsExpired}>
                    <IfBox shouldShow={this.state.readonly}>
                        <Popup content='This is an old Contract and should not be changed anymore.' trigger={

                            <Button icon onClick={(e) => {
                                e.preventDefault();
                                this.setState({readonly: !this.state.readonly})
                            }}><Icon name='lock' color={"red"}/></Button>
                        }/>

                    </IfBox>
                    <IfBox shouldShow={!this.state.readonly}>
                        <Button icon onClick={(e) => {
                            e.preventDefault();
                            this.setState({readonly: !this.state.readonly})
                        }}><Icon name='unlock' color={"red"}/></Button>
                    </IfBox>

                </IfBox>
                <div className={"flexRow"}>
                    <Segment className={"flex"} style={{padding: "5px", margin: "0 5px 0 0", width: "350px"}}>
                        {this.drawTree()}
                    </Segment>
                    <Segment className={"flex3"} style={{padding: "5px", margin: "0"}}>
                        {this.drawRightSide(this.state.cursor)}
                    </Segment>
                </div>
            </IfBox>
            <IfBox shouldShow={showCreateConfig}>
                <Label color="yellow">There is no Configuration for this company, yet.</Label>
                <br/>
                <Button type="button" onClick={() => this.setState({showCreateConfigModal: true})}>Create new
                    config</Button>
                <IfBox shouldShow={this.state.showCreateConfigModal}>
                    <CreateDefaultContractModal isOpen={this.state.showCreateConfigModal}
                                                onClose={() => this.setState({showCreateConfigModal: false})}/>
                </IfBox>
            </IfBox>

        </>
    }

}