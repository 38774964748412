import * as React from "react";
import {useEffect} from "react";
import {Icon, Table} from "semantic-ui-react";


export interface ProxMoxStatusPageProps {

}

export function ProxMoxStatusPage(props: React.PropsWithChildren<ProxMoxStatusPageProps>) {


    const [response, setResponse] = React.useState(null)


    useEffect(() => {
        console.log("ProxMoxStatusPage useEffect")

        async function loadProxMoxStatus() {
            let response = await fetch("/internalMonitoring/storageHealth")
            let json = (await response.json())["diskStatus"]
            console.log("ProxMoxStatusPage response: ", json)
            setResponse(json)
        }


        loadProxMoxStatus()

    }, []);

    function drawRows() {

        let allItemRows = []
        if (response != null) {
            for (let item of response) {
                allItemRows.push(
                    <Table.Row>

                        <Table.Cell>{item.node}</Table.Cell>
                        <Table.Cell>{item.name}</Table.Cell>
                        <Table.Cell>
                            {item.health == "ONLINE" ? <Icon name='checkmark' color='green'/> :
                                <Icon name='close' color='red'/>}
                            {item.health}</Table.Cell>
                        <Table.Cell>{byteToReadable(item.size)}</Table.Cell>
                        <Table.Cell>{byteToReadable(item.free)} ({drawFreePercent(item)})</Table.Cell>
                        <Table.Cell>{byteToReadable(item.alloc)}</Table.Cell>
                        <Table.Cell>{item.frag}</Table.Cell>
                        <Table.Cell>{item.dedup}</Table.Cell>
                    </Table.Row>
                )
            }
        }
        return allItemRows
    }

    function drawFreePercent(item: any) {
        let color = "green"

        switch (true) {
            case item.freePercentage < 10:
                color = "red"
                break
            case item.freePercentage < 20:
                color = "orange"
                break
            case item.freePercentage < 30:
                color = "yellow"
                break
            case item.freePercentage < 40:
                color = "lightgreen"
                break
        }

        return <p style={{color: color, display: "inline"}}>{item.freePercentage}%</p>


    }

    function round(value) {
        return Math.round(value * 100) / 100
    }

    function pow(n) {
        return Math.pow(1024, n)
    }

    function byteToReadable(byte: number) {
        if (byte == null) {
            return "null"
        }

        switch (true) {
            case byte < 1024:
                return byte + " B"
            case byte < pow(2):
                return round(byte / 1024) + " KB"
            case byte < pow(3):
                return round(byte / pow(2)) + " MB"
            case byte < pow(4):
                return round(byte / pow(3)) + " GB"
            default:
                return round(byte / pow(4)) + " TB"

        }
    }

    return <>
        <h1>Storage Status</h1>
        <pre>
            <Table selectable striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Node</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Health</Table.HeaderCell>
                        <Table.HeaderCell>Size</Table.HeaderCell>
                        <Table.HeaderCell>Free</Table.HeaderCell>
                        <Table.HeaderCell>Alloc</Table.HeaderCell>
                        <Table.HeaderCell>Frag</Table.HeaderCell>
                        <Table.HeaderCell>dedup</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>{drawRows()}</Table.Body>
            </Table>
        </pre>
    </>

}
