import {useDispatch, useSelector} from "react-redux";
import * as React from "react";
import {getActivePage, getOpenedFiles, setActiveFile} from "../slices/tableSlice";
import "./FileTabBar.css"

export function FileTabBar(){
    const files = useSelector(getOpenedFiles)
    const currentlyVisibleFile = useSelector(getActivePage)
    const dispatch = useDispatch()

    const activePageButtons = files.map((fd,idx)=><div>
            <button
                className={currentlyVisibleFile.inputFileName==fd.inputFileName?"ActiveFile":undefined}
                onClick={()=>dispatch(setActiveFile(idx))}
            >
                {fd.inputFileName}
            </button>
        </div>
    )
    return <div className={"FileTabGrid"}>{activePageButtons}</div>
}