import * as React from "react";
import {IfBox} from "../../../style/if";
import AceEditor from 'react-ace';

import 'brace/ext/searchbox'

export interface LogTextOutputProps {
    logOutput: any[]
}

export interface LogTextOutputState {
}

export class LogTextOutput extends React.Component<LogTextOutputProps, LogTextOutputState> {

    constructor(props) {
        super(props)
        console.log("showing LogTextOutput")

        this.state = {}
    }

    componentDidMount() {
        let text = this.getPreparedOutput()
        console.log(text)
    }

    getPreparedOutput() {
        let regex = /(\[[a-zA-Z ]+\]) ([a-zA-Z.]+): \d\d\d\d-\d\d-\d\dT(\d\d:\d\d:\d\d.\d\d\d) \[X-Request-ID [a-z0-9A-Z-]+] (.+)/ig

        let outPut = this.props.logOutput.map(item => {
            return item.replace(regex, '$1 $3 $4')
        }).join("\n").split("\\n").join("\n")

        if (!outPut || outPut == '') {
            outPut = 'Nothing Found'
        }
        return outPut
    }

    render() {

        return <>
            <IfBox shouldShow={this.props.logOutput != null}>
                <AceEditor
                    theme="monokai"
                    style={{flex: 1}}
                    mode="json"
                    value={this.getPreparedOutput()}
                    placeholder={'Nothing found'}
                    width="100%"
                    readOnly={true}
                />
            </IfBox>
        </>

    }


}