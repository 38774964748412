import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {ReportScriptTransportOrderQuery} from "./ReportScriptTransportOrderQuery";
import AceEditor from "react-ace";
import {ResizeBoxHorizontalPair, ResizeBoxVerticalPair} from "../../../../util/ResizeBox";

import 'brace/theme/monokai'
import 'brace/ext/searchbox'
import ReportScriptResultTabMenu from "./ReportScriptResultTabMenu";
import {ConfigContext} from "../../../context/ConfigContext";
import {Button, Tab} from "semantic-ui-react";
import {OutputReport, QueryResponse, ReportRow} from "../../../../../generated";
import {ReportScriptSyncModal} from "./ReportScriptSyncModal";
import {authentication} from "../../../../../authentication";
import {v4 as uuid} from 'uuid';
import {TransportOrderSearch} from "../../transportOrders/TransportOrderSearch";

export interface ReportScriptPageProps {
    location: any
    match: any
}

export function ReportScriptPage(props: React.PropsWithChildren<ReportScriptPageProps>) {

    const context = useContext(ConfigContext)

    const [isLoading, setIsLoading] = useState(false)

    const [script, setScript] = React.useState(context.reportScript)
    const [xRequestId, setXRequestId] = React.useState("")

    const [transportOrders, setTransportOrders] = useState([])

    const [response, setResponse] = useState<OutputReport>(null)
    const [error, setError] = useState<string>(null)

    const [showSyncModal, setShowSyncModal] = useState(false)

    const [transportOrdersOfSearch, setTransportOrdersOfSearch] = useState([])

    useEffect(() => {
        setScript(context.reportScript)
        if (context.reportScriptResults != null) {
            setResponse(context.reportScriptResults)
        }
    }, [])

    async function execute() {
        setIsLoading(true)
        let url = '/converter/generateTransportOrderReport'

        let request = {
            orders: transportOrders,
            script: script,
            reportName: "TestReport",
            companyId: authentication.company._id
        }

        let response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                'x-request-id': uuid()
            },
            body: JSON.stringify(request)
        })
        setXRequestId(response.headers.get("x-request-id"))

        if (response.status == 200) {
            setError(null)
            let result = JSON.parse(await response.text())
            setResponse(result)
            context.setReportScriptResults(result)
        } else {
            let e = response.status + ": " + response.statusText
            setResponse(null)
            setError(e)
        }
        setIsLoading(false)
    }

    function jsonToCsv(items: any) {
        const header = Object.keys(items[0]);
        const headerString = header.join(';');
        // handle null or undefined values here
        const replacer = (key: string, value: string) => value ?? '';
        const rowItems = items.map((row: any) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(';')
        );
        // join header and body, and break into separate lines
        return [headerString, ...rowItems].join('\r\n');
    }

    async function download() {
        let currentRecords = response.pages[0].dataLines
        const headers = response.pages[0].header
        if (currentRecords && headers) {
            let data_to_download = [];
            for (let index = 0; index < currentRecords.length; index++) {
                let record_to_download: any = {};
                for (let colIndex = 0; colIndex < headers.length; colIndex++) {
                    let row: ReportRow = currentRecords[index]
                    let header = headers[colIndex].stringContent

                    let currentRecord = row.cells[colIndex].stringContent;

                    //if (currentRecord !== undefined) {
                    record_to_download[header as keyof any] = currentRecord
                    //}
                }
                data_to_download.push(record_to_download);
            }
            // console.log("data_to_download", data_to_download[0].toString())
            const csv = jsonToCsv(data_to_download);
            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8,'})
            const csvURL = window.URL.createObjectURL(blob);
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', `${response.pages[0].name}.csv`);
            tempLink.click();
            // console.log(csv);
        }

    }

    function onQueryUpdate(queryResponse: QueryResponse) {
        setTransportOrders(queryResponse.transportOrders)
    }

    function drawQueryBox() {

        const panes = [
            {
                menuItem: 'Query',
                render: () => <Tab.Pane>
                    <ReportScriptTransportOrderQuery
                        onUpdate={onQueryUpdate}
                    />
                </Tab.Pane>
            },
            {
                menuItem: 'SimpleSearch',
                render: () => <Tab.Pane>
                    <TransportOrderSearch
                        selectedOrderIdsChanged={(newSelectedOrderIds) => {
                            setTransportOrders(transportOrdersOfSearch.filter(to => newSelectedOrderIds.includes(to._id)))
                        }}
                        selectedOrderChanged={(newSelectedOrder) => {}}
                        foundTransportOrdersChanged={(newFoundOrders) => {setTransportOrdersOfSearch(newFoundOrders)}}
                        reload={(page, take) => {}}
                        history={history}
                        initialPageSize={5}
                        hideArchiveButton={true}
                        location={props.location}
                        match={props.match}
                    />
                </Tab.Pane>
            }]
        return <Tab
            id={"ReportScriptPageTab"}
            panes={panes}
            className={"flexTab"}
            defaultActiveIndex={0}
            onTabChange={(_, data) => {
                setTransportOrders([])
            }}
        />

    }

    function drawScriptBox() {
        return <>
            <AceEditor
                theme="monokai"
                mode="groovy"
                value={script}
                width={"100%"}
                height={"100%"}
                onChange={(e) => {
                    console.log(e)
                    context.setReportScript(e)
                    setScript(e)
                }}
            />
        </>
    }

    function drawTop() {
        return <>
            <ResizeBoxHorizontalPair
                leftWidthPercentage={0.3}
                height={window.innerHeight * 0.5 - 25}
                leftContent={drawQueryBox()}
                rightContent={drawScriptBox()}
            />
        </>
    }

    function drawBottom() {
        return <>
            <Button
                disabled={script == "" || transportOrders.length == 0}
                style={{
                    backgroundColor: "green",
                    width: "40%"
                }}
                onClick={execute}
                loading={isLoading}
            >
                Execute
            </Button>
            <Button
                disabled={response == null}
                style={{
                    backgroundColor: "green",
                    width: "30%"
                }}
                onClick={download}
            >
                Download
            </Button>
            <Button
                style={{
                    width: "29%"
                }}
                onClick={() => setShowSyncModal(true)}
            >
                Sync
            </Button>
            <ReportScriptResultTabMenu
                response={response}
                context={context}
                xRequestId={xRequestId}
            />
            <ReportScriptSyncModal
                script={script}
                isOpen={showSyncModal}
                onClose={() => setShowSyncModal(false)}
                loadedScript={(newScript) => {
                    console.log("loadedScript: ", newScript)
                    setScript(newScript)
                }}
            />
        </>
    }

    return <>
        <ResizeBoxVerticalPair
            width={window.innerWidth - 200}
            topHeightPercentage={0.5}
            topContent={drawTop()}
            bottomContent={drawBottom()}
        />
    </>
}