import * as React from "react";
import {Button, Grid, Header, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import * as AceDiff from 'ace-diff';
import {TransportOrderConfigTest} from "../../../../../generated";
import {UpdateTestModal} from "./UpdateTestModal";
import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import "../../configChange/configEdit/merge/mergeStyle.css";

const ace = require('brace');

export interface UpdateTestDiffPickerModalProps {
    isOpen: boolean
    onClose: (boolean) => void
    expectedResult: string
    newResult: string
    test: TransportOrderConfigTest
}

export class UpdateTestDiffPickerModal extends React.Component<UpdateTestDiffPickerModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            propsFound: false,
            editors: null,
            changingExpectedResult: null,
            oldExpectedResult: null,
            showUpdate: false,
        }
    }

    handleClose = (saved) => {
        this.props.onClose(saved)
    }

    componentDidMount() {

        let expectedResult = this.state.changingExpectedResult
        if (expectedResult == null) {
            expectedResult = this.props.expectedResult
            this.setState({changingExpectedResult: expectedResult, oldExpectedResult: expectedResult})
        }

        let aceDiff = new AceDiff({
            ace,
            element: ".acediff",
            left: {
                content: expectedResult,
                copyLinkEnabled: false
            },
            right: {
                content: this.props.newResult,
                editable: false
            },
        });
        if (aceDiff.editors) {
            console.log(aceDiff.getEditors().left.getValue())
            this.setState({editors: aceDiff.getEditors()})
        }
        this.setState({oldExpectedResult: aceDiff.getEditors().left.getValue().toString()})
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Update test result'/>
            <Modal.Content>

                <Grid padded="horizontally" stackable columns='equal' centered>

                    <Grid.Column>
                        expected result
                    </Grid.Column>
                    <Grid.Column>
                        new result
                    </Grid.Column>
                </Grid>
                <div className="acediff"/>
                <Button onClick={() => this.saveChanges()}>Save Changes</Button>
                <Button onClick={() => this.takeAll()}>take all</Button>
                <Button onClick={() => this.handleClose(false)}>Cancel</Button>
                <UpdateTestModal
                    isOpen={this.state.showUpdate}
                    onClose={(saved: boolean) => this.handleCloseUpdateModal(saved)}
                    expectedResult={this.state.oldExpectedResult}
                    newResult={this.state.changingExpectedResult}
                    test={this.props.test}/>
            </Modal.Content>
        </Modal>
    }

    handleCloseUpdateModal(saved: boolean) {
        this.setState({showUpdate: false})
        if (saved) {
            this.handleClose(saved)
        }
    }

    saveChanges() {
        let newExpected = this.state.editors.left.getValue().toString()
        let oldExpected = this.state.oldExpectedResult
        this.setState({
            changingExpectedResult: newExpected,
            oldExpectedResult: oldExpected,
            showUpdate: true
        })
    }

    takeAll() {
        this.setState({
            changingExpectedResult: this.props.newResult,
            oldExpectedResult: this.props.expectedResult,
            showUpdate: true
        })
    }

}