import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {GenericXlsConverterConfiguration, GenericXlsExtractionRule} from "../../../../../generated";
import InputFormatEnum = GenericXlsConverterConfiguration.InputFormatEnum;

// export type MatchWhenType = "EXACT_MATCH" | "PARTIAL_MATCH" | "REGEX_MATCH" | "MATCH_ON_LINE"
// export type MatchSelectorType =
//     "THIS_CELL"
//     | "RIGHT_CELL"
//     | "LEFT_CELL"
//     | "RECTANGLE_TILL"
//     | "ROWS_TILL"
//     | "THIS_COLUMN"
//     | "NEXT_ROW"
//     | "THIS_ROW"
//     | "PREV_ROW"
//     | "NEXT_CELL_DOWN"
//     | "NEXT_CELL_UP"
//     | "AREA_TILL_CELL"
//     | "ROWS_TILL_CELL"
//     | "ROWS_TILL_NEXT_MATCH"
//     | "SPLIT_ALONG_LINE_BEFORE"
//     | "SPLIT_ALONG_LINE_AFTER" /*Deprecated, don't use*/
//     | "RANGE_WITH_SIZE"
//     | "REMAINING_BUFFER"
//     | "REMAINING_BUFFER_SPLIT_EVERY"
//     | "EXCLUDE_ROWS_TILL_CELL"

// export type ActionType = "TAG" | "LABEL" | "SQUASH" | "REMOVE_EMPTY_ROWS_AND_COLUMNS"
// export type SplitPointSelection = "OFFSET_BASED" | "MATCHING_CELL_BASED"
// export type IfNotAvailableTriggerOptions = "DO_NOTHING" | "SHOW_STATUS_VALIDATION_WARNING" | "GO_TO_INPUT_MISSING"

// export type MatchingRule = {
//     ruleId: number
//     matchText: string
//     matchWhen: MatchWhenType
//     matchOnColumns: string | undefined
//     selector: MatchSelectorType
//     selectorMatchText: string | undefined
//     selectorMatchWhen: MatchWhenType
//     skipEmptyCells: boolean
//     action: ActionType
//     tagName: string | undefined
//     extractionRegex: string | undefined
//     selectionLabel: string | undefined
//     parentRuleId: number | undefined
//     selectNumLines: number | undefined
//     selectNumColumns: number | undefined
//     skipNumEntriesAtStart: number | undefined
//     skipNumEntriesAtEnd: number | undefined
//     splitEvery: number | undefined
//     skipNumColumns: number | undefined
//     splitLineOffset: number | undefined
//     offsetLines: number | undefined
//     offsetColumns: number | undefined
//     splitOnlyOnChange: boolean | undefined
//     comment: string | undefined
//     splitPointSelection: SplitPointSelection
//     ifNotAvailableTrigger: IfNotAvailableTriggerOptions
// }

export type AiPromptExample = {
    input: string | undefined
    output: string | undefined
}

export type AiPrompt = {
    id: number
    name: string | undefined,
    instructionText: string | undefined,
    examples: AiPromptExample[] | undefined
}

export type ParserOptions = {
    dateFormat: string | undefined,
    timeFormat: string | undefined,
    numberFormat: "german" | "english" | undefined,
    weightUnit: "kg" | "t" | undefined,
    lengthUnit: "mm" | "cm" | "m" | undefined,
    defaultArriveHour: number | undefined,
    defaultDepartHour: number | undefined,
    stopListMode: boolean | undefined,
    aiPrompts: AiPrompt[] | undefined
}

export type SplitterOptions = {
    segmentDocuments: boolean | undefined
    useElasticCellPlacement: boolean | undefined
    squashBuffer: boolean | undefined
    disableHeaderRemoval: boolean | undefined
    aggressiveLineMerging: boolean | undefined
    // ocrEngine: OcrEngineEnum | undefined
    insertEmptyLines: boolean | undefined
    splitOnOversizedWhitespaces: boolean | undefined
}

export type SplitterMode = {
    mode: InputFormatEnum
}

type RuleState = {
    activeConfig: string,
    activeRules: "ext" | "spt",
    activeSpecificRules: "spcExt" | "applyWhen",
    activeMode: InputFormatEnum,
    ruleList: GenericXlsExtractionRule[],
    parseOptions: ParserOptions,
    splitterOptions: SplitterOptions,
    saveToConfig: boolean,
}

export const initialState = {
    activeConfig: "default",
    activeRules: "ext",
    activeSpecificRules: "spcExt",
    activeMode: InputFormatEnum.PDFV2,
    ruleList: [],
    parseOptions: {},
    splitterOptions: {},
    saveToConfig: false,
} as RuleState

export type MoveRule = {
    ruleId: number,
    moveBelowRuleWithId: number
}

const ruleSlice = createSlice({
    name: "rules",
    initialState,
    reducers: {
        changeActiveRules(state, action: PayloadAction<"ext" | "spt">) {
            // console.log("action is", action.payload)
            state.activeRules = action.payload
        },
        changeSpecificActiveRules(state, action: PayloadAction<"spcExt" | "applyWhen">) {
            // console.log("action is", action.payload)
            state.activeSpecificRules = action.payload
        },
        changeActiveConfig(state, action: PayloadAction<string>) {
            // console.log("action is", action.payload)
            state.activeConfig = action.payload
        },
        changeActiveMode(state, action: PayloadAction<InputFormatEnum>) {
            // console.log("action is", action.payload)
            state.activeMode = action.payload
            // state.saveToConfig = true
        },
        addMany(state, action: PayloadAction<GenericXlsExtractionRule[]>) {
            state.ruleList=[]
            action.payload?.forEach(p => add(state, p))
        },
        addRule(state, action: PayloadAction<GenericXlsExtractionRule>) {
            add(state, action.payload)
        },
        setRules(state, action: PayloadAction<RuleState>) {
            // console.log("Set rules called", action.payload)
            const { splitterOptions } = state;
            state = {
                ...action.payload,
                splitterOptions: splitterOptions // Preserve the original splitterOptions
            }
        },
        reset(state) {
            const initialStateCopy: RuleState = {...initialState};
            console.log("initial is ", initialStateCopy)
            initialStateCopy.activeRules = state.activeRules
            initialStateCopy.activeSpecificRules = state.activeSpecificRules
            initialStateCopy.activeConfig = state.activeConfig
            Object.assign(state, initialStateCopy)
        },
        removeRule(state, action: PayloadAction<GenericXlsExtractionRule>) {
            removeRuleAndSubRules(state, action.payload)
            // state.saveToConfig = true
        },
        setParseOptions(state, action: PayloadAction<ParserOptions>) {
            // console.log("set parse options called with ", action.payload)
            state.parseOptions = action.payload
        },
        setSplitterOptions(state, action: PayloadAction<SplitterOptions>) {
            // console.log("set splitter options called with ", action.payload)
            state.splitterOptions = action.payload
            // state.saveToConfig = true
        },
        setSaveToConfig(state, action: PayloadAction<boolean>) {
            state.saveToConfig = action.payload
        },
        moveRule(state, action: PayloadAction<MoveRule>) {
            const indexOfElementToMove = state.ruleList.findIndex(r => r.ruleId == action.payload.ruleId)
            const ruleToMove = state.ruleList[indexOfElementToMove]
            state.ruleList.splice(indexOfElementToMove, 1)

            const destinationOfElement = state.ruleList.findIndex(r => r.ruleId == action.payload.moveBelowRuleWithId)
            state.ruleList.splice(destinationOfElement + 1, 0, ruleToMove)
            // state.saveToConfig = true
        }
    }
})

function removeRuleAndSubRules(state, r: GenericXlsExtractionRule) {
    if (r.ruleId) {
        const subRules = state.ruleList.filter(r => r.parentRuleId == r.ruleId)
        state.ruleList = state.ruleList.filter(cr => cr.ruleId != r.ruleId)

        subRules.forEach(subrule => removeRuleAndSubRules(state, subrule));
    }
}

function add(state: RuleState, payload: GenericXlsExtractionRule) {
    const ruleIdx = state.ruleList.findIndex(x => x.ruleId == payload.ruleId)
    if (ruleIdx >= 0) {
        state.ruleList[ruleIdx] = payload
    } else {
        state.ruleList.push(payload)
    }
}

export const {
    addRule,
    setRules,
    reset,
    addMany,
    removeRule,
    setParseOptions,
    moveRule,
    changeActiveRules,
    changeSpecificActiveRules,
    changeActiveConfig,
    setSplitterOptions,
    changeActiveMode,
    setSaveToConfig,
} = ruleSlice.actions
export default ruleSlice.reducer
export const selectRules = (state: RootState) => state.rules.ruleList;
export const getParseOptions = (state: RootState) => state.rules.parseOptions;
export const getSplitterOptions = (state: RootState) => state.rules.splitterOptions;
export const getActiveRules = (state: RootState) => state.rules.activeRules;
export const getSpecificActiveRules = (state: RootState) => state.rules.activeSpecificRules;
export const getActiveMode = (state: RootState) => state.rules.activeMode;
export const getActiveConfig = (state: RootState) => state.rules.activeConfig;
export const getSaveToConfig = (state: RootState) => state.rules.saveToConfig;
export const generateNextRuleId = (state: RootState) => {
    if (state.rules.ruleList.length == 0 && (!state.rightMenu.rulesToEdit || state.rightMenu.rulesToEdit.length == 0)) {
        return 1
    } else {
        return Math.max(...(state.rules.ruleList || []).map((x: any) => x.ruleId),
            ...(state.rightMenu.rulesToEdit || []).map((x: any) => x.ruleId)) + 1
    }
}