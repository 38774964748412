import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import Notifications from 'react-notify-toast';
import {Grid} from "semantic-ui-react";
import {ProjectWorkLogBookEntry} from "../../../../../generated";
import {EditProjectWorkLogEntry} from "./edit/EditProjectWorkLogEntry";
import {ProjectWorkLogList} from "./lists/ProjectWorkLogsList";

export interface ProjectWorkLogsProps {

}


export interface ProjectWorkLogsPageState {
    isLoading: boolean,
    selectedLogEntry: ProjectWorkLogBookEntry | null
    refreshTrigger: boolean
    readOnly: boolean
}


export class ProjectWorkLogsPage extends React.Component<ProjectWorkLogsProps, ProjectWorkLogsPageState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.logEntrySelected = this.logEntrySelected.bind(this);

        this.state = {
            isLoading: false,
            selectedLogEntry: null,
            refreshTrigger: false,
            readOnly: false,
        }
    }

    async componentDidMount() {

    }

    logEntrySelected(selectedLogEntry) {
        this.setState({selectedLogEntry: selectedLogEntry})
    }


    render() {
        return <span>
            <Grid padded="horizontally" stackable columns='equal' centered>

                <Grid.Column>
                    <Grid.Row>
                        <ProjectWorkLogList
                            logEntrySelected={this.logEntrySelected}
                            selectedLogEntry={this.state.selectedLogEntry}
                            refreshTrigger={this.state.refreshTrigger}
                        />
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                    <Grid.Row>
                        <EditProjectWorkLogEntry
                            saveKeyListener={null}
                            fieldPathDispatcher={null}
                            refreshList={() => this.setState({refreshTrigger: !this.state.refreshTrigger})}
                            readonly={this.state.readOnly}
                        />
                    </Grid.Row>
                </Grid.Column>


            </Grid>
            <Notifications/>
        </span>
    }


}