import * as React from "react";
import {Label, Message} from 'semantic-ui-react';
import {authentication} from "../../../../../authentication";
import {backend} from "../../../../../xconvert-backend";
import {ConfigContext} from "../../../context/ConfigContext";
import AceEditor from 'react-ace';
import {Base64} from 'js-base64';

import 'brace/ext/searchbox'
import {AddDefaultsToConfigRequest} from "../../../../../generated";
import {IfBox} from "../../../../style/if";
import {ConverterSendButton} from "../ConverterSendButton";
import {v4 as uuid} from 'uuid';
import {useEffect} from "react";
import {importSimOutputs} from "./ImportSimTabMenu";
import {updateConfig, workingConfig} from "../../configChange/ConfigSignal";


export interface ImportSimTextProps {
}

export function ImportSimText(props: ImportSimTextProps) {

    const context = React.useContext(ConfigContext)

    const [isConverting, setIsConverting] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)

    useEffect(() => {
        if (context.confSimInputLastType == "TEXT") {
            context.setConfSimInputActive("TEXT")
        }
    }, []);

    async function getConfigWithDefaults() {
        let auth = backend.withTokenAuthHeader(authentication.token)

        let request = {} as AddDefaultsToConfigRequest

        let configWithoutDefaults = workingConfig.value

        if (!configWithoutDefaults) {
            console.log("config in context is null. Loading config from backend")
            let auth = (await backend.withTokenAuthHeader(authentication.token))
            let currentCompanyConfig = (await backend.internalApi.fetchCompanyConfiguration(context.companyId, auth))
            configWithoutDefaults = JSON.stringify(currentCompanyConfig, null, 4)
            updateConfig(configWithoutDefaults, false, false)
        }

        request.config = JSON.parse(configWithoutDefaults)

        return (await backend.internalApi.addDefaultsToConfig(request, auth)).config;

    }

    async function doConvert() {
        setIsConverting(true)

        try {
            let config = await getConfigWithDefaults()

            let base64 = Base64.encode(context.confSimInput)
            let url = '/converter/v3/convertExternalFormatToTransportOrder'
            if (context.customConverterBasePath) {
                url = context.customConverterBasePath + url
            }

            let xReqId = uuid()

            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    'x-request-id': xReqId
                },
                body: JSON.stringify({
                    base64Data: base64,
                    config: config,
                    companyId: context.companyId
                })
            })

            if (response.status == 200) {
                context.setConfSimToSaveMode('REPLACE')
            } else if (response.status == 206) {
                context.setConfSimToSaveMode('PATCH')
            } else {
                context.setConfSimToSaveMode(null)
            }

            if (!response.ok) {
                setErrorMessage(response.statusText)
            } else {
                setErrorMessage(null)
            }

            let responseText = await response.text()
            context.setConfSimInputLastType("TEXT")

            importSimOutputs.value = [{
                result:{
                    xRequestId: xReqId,
                    fileName: "TEXT",
                    TOs: responseText
                },
                selected: true
        }]
        } finally {
            setIsConverting(false)

        }

    }


    return <>
        <div>
            <ConverterSendButton
                onClick={(evt) => doConvert()}
                loading={isConverting}
                disabled={isConverting}
            />
        </div>

        <IfBox shouldShow={errorMessage != null}>
            <Message negative={true} compact={true}>
                <Message.Header>
                    ERROR:
                </Message.Header>
                <Message.Content>
                    {errorMessage}
                </Message.Content>
            </Message>
        </IfBox>
        <Label>
            External format (e.g. LIS)
        </Label>
        <AceEditor
            theme="monokai"
            style={{flex: 1}}
            mode="json"
            value={context.confSimInput}
            placeholder={'Put external format here (e.g. LIS file)'}
            width="100%"
            onChange={(value) => context.setConfSimInput(value)}
            readOnly={false}
        />

    </>


}