import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {
    Accordion,
    Button,
    Checkbox, Form,
    Grid,
    Header,
    Icon,
    Input,
    Label,
    List,
    Modal,
    Popup,
    TextArea
} from "semantic-ui-react";
import {parseDiff} from 'react-diff-view';
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {UpdateCompanyConfigRequestWrapper} from "../../../../../../generated";
import {diffLines, formatLines} from 'unidiff';
import {DiffWrapper} from "../diff/DiffWrapper";
import {IfBox} from "../../../../../style/if";
import {FailedTestTabView} from "./FailedTestTabView";
import {ConfigContext} from "../../../../context/ConfigContext";
import {configStorage} from "../../../../../../ConfigStorage";
import {updateConfig} from "../../ConfigSignal";
import {CHANGE_WORK_TIME_KEY} from "../../../../../style/page_with_sidebar";

const YAML = require('yaml');

export interface ChangeConfigModalProps {
    isOpen: boolean;
    onClose: (updatedConfig: boolean) => void;
    oldConfig: string;
    newConfig: string;
    failedTests: any[];
    validationWarnings: any[] | null;
}

export function ChangeConfigModal(props: React.PropsWithChildren<ChangeConfigModalProps>) {
    const context = useContext(ConfigContext);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [comment, setComment] = useState("");
    const [response, setResponse] = useState<any | null>(null);
    const [hunks, setHunks] = useState<any | null>(null);
    const [testsFailed, setTestsFailed] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [yamlView, setYamlView] = useState(false);
    const [workTime, setWorkTime] = useState(getCurrentChangeWorkTime());

    function handleClose(resp = null) {
        let updatedConfig = resp != null && resp.isValid;
        props.onClose(updatedConfig);
    }

    function workTimeInFullMinutes(workTimeStr: string | null = null): string {
        let wt = parseFloat(workTimeStr ?? "0");
        if (wt < 0) {
            return "0";
        }
        return Math.ceil(wt).toString()
    }

    async function save() {
        setIsSubmitting(true);
        try {
            console.log("updating config in backend");
            let auth = backend.withTokenAuthHeader(authentication.token);
            let request = {} as UpdateCompanyConfigRequestWrapper;
            request.configAsJson = props.newConfig;
            let resp = await backend.internalApi.updateCompanyConfiguration(
                context.companyId,
                request,
                context.saveConfigComment,
                parseInt(workTime),
                auth
            );
            console.log("backend responded with: ", resp);
            setResponse(resp);
            if (resp.isValid) {
                context.setSaveConfigComment("");
                let key = CHANGE_WORK_TIME_KEY + context.companyId
                localStorage.setItem(key, "0")
            }

            await updateConfig(null, true, true);
            configStorage.deleteCompany(context.companyId).then(r => {
                setIsSubmitting(false);
                handleClose(resp);
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    function findChangedPathsLoop(
        oldPart: {},
        newPart: {},
        key: string,
        path: string
    ) {
        let changes = [];
        let o = oldPart[key];
        let n = newPart[key];
        if ((n == null || o == null) && n != o) {
            if (path != "") {
                path = path + ".";
            }
            let fullPath = path + "" + key;
            changes.push(fullPath);
        } else {
            let oldJson = JSON.stringify(o);
            let newJson = JSON.stringify(n);
            if (oldJson != newJson) {
                if (oldJson.startsWith("{") || oldJson.startsWith("[")) {
                    if (path != "") {
                        path = path + ".";
                    }
                    let fullPath = path + "" + key;
                    findChangedPaths(o, n, fullPath).map(change => {
                        changes.push(change);
                    });
                } else {
                    if (path != "") {
                        path = path + ".";
                    }
                    let fullPath = path + "" + key;
                    changes.push(fullPath);
                }
            }
        }
        return changes;
    }

    function findChangedPaths(oldPart: {}, newPart: {}, path: string) {
        let changes = [];
        let oldKeys = Object.keys(oldPart);
        let newKeys = Object.keys(newPart);

        if (oldKeys.length === newKeys.length || oldKeys.length > newKeys.length) {
            oldKeys.map(oldKey => {
                findChangedPathsLoop(oldPart, newPart, oldKey, path).map(change => {
                    changes.push(change);
                });
            });
        } else {
            newKeys.map(newKey => {
                findChangedPathsLoop(oldPart, newPart, newKey, path).map(change => {
                    changes.push(change);
                });
            });
        }

        return changes;
    }

    useEffect(() => {
        if (hunks == null && props.oldConfig != "null" && props.newConfig != "null") {
            let oldConfig = props.oldConfig;
            let newConfig = props.newConfig;

            const diffText = formatLines(diffLines(oldConfig, newConfig), {context: 3});
            const [diff] = parseDiff(diffText, {nearbySequences: 'zip'});

            let commentPreset = context.saveConfigComment;
            if (commentPreset == null || commentPreset == "") {
                let changedPaths = "";

                let changedValues = findChangedPaths(JSON.parse(oldConfig), JSON.parse(newConfig), "");

                changedValues.forEach(v => {
                    changedPaths = changedPaths + "\n -" + v;
                });
                commentPreset = "\n\n-changed fields:\n " + changedPaths;
            }

            if (props.failedTests != null) {
                setTestsFailed(props.failedTests.length != 0);
            }

            setHunks(diff.hunks);
            setComment(commentPreset);
        }
    }, []);

    const listFailedTests = () => {
        return <List bulleted>
            {Object.values(props.failedTests).map(test => {
                console.log("building list of failed tests. Item: " + test.test?.name);
                return <List.Item key={test.test?.name as string}>{test.test?.name}</List.Item>;
            })}
        </List>;
    };

    const handleClick = (e, titleProps) => {
        const {index} = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const toYaml = (obj: any) => {
        if (obj) {
            const doc = new YAML.Document();
            doc.contents = obj;
            return doc.toString();
        } else {
            return '';
        }
    };

    const drawDiff = () => {
        let a = cleanBackslashR(props.oldConfig);
        let b = cleanBackslashR(props.newConfig);
        let format = "json";
        if (yamlView) {
            a = toYaml(JSON.parse(props.oldConfig));
            b = toYaml(JSON.parse(props.newConfig));
            format = "yaml";
        }

        console.log("set format to ", format);
        return <DiffWrapper
            id={"diffWrapper"}
            oldText={a}
            newText={b}
            format={format}
        />;
    };

    const renderValidationWarnings = () => {
        if (props.validationWarnings?.length > 0) {
            return <List bulleted>
                {props.validationWarnings.map((warning) => {
                    return <List.Item key={warning.ruleName}>
                        <Popup content={
                            <div>
                                <p><a style={{fontWeight: "bold"}}> RuleName: </a>{warning.ruleName}</p>
                                <p><a style={{fontWeight: "bold"}}> Description: </a>{warning.description}</p>
                            </div>
                        } trigger={
                            <div>
                                <Icon name="exclamation triangle" color="yellow" size={"large"}/> {" - "}
                                {warning.warning}
                            </div>
                        }
                               on={'hover'}
                               wide={"very"}
                        />
                    </List.Item>;
                })}
            </List>;
        } else {
            return <p color={'green'}>No Warnings!</p>;
        }
    };

    const cleanBackslashR = (str: string): string => {
        return str.split("\\r").join("");
    };

    function getCurrentChangeWorkTime() {
        let key = CHANGE_WORK_TIME_KEY + context.companyId
        return workTimeInFullMinutes(localStorage.getItem(key))
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={handleClose}
            size='fullscreen'>
            <Header icon='browser' content='Config change'/>
            <Modal.Content>

                <IfBox shouldShow={testsFailed}>
                    <Grid padded="horizontally" stackable columns='5'>
                        <Grid.Row>
                            <Grid.Column>
                                <Icon name='warning sign' size='massive' color='yellow'/>
                            </Grid.Column>
                            <Grid.Column>
                                <Label color='red'>Tests have failed!</Label>
                                {listFailedTests()}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Accordion>
                                <Accordion.Title
                                    active={activeIndex === 0}
                                    index={0}
                                    onClick={handleClick}
                                >
                                    <Icon name='dropdown'/>
                                    show failed test-results
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 0}>
                                    <FailedTestTabView source={props.failedTests}/>
                                </Accordion.Content>
                            </Accordion>
                        </Grid.Row>
                    </Grid>
                </IfBox>

                {renderValidationWarnings()}

                <p>JSON - <Checkbox
                    type='checkbox'
                    toggle
                    checked={yamlView}
                    onChange={() => {
                        setYamlView(!yamlView);
                    }}
                /> - YAML </p>

                {drawDiff()}

                <h3>leave a comment</h3>
                <TextArea
                    id='CommentTextArea'
                    value={comment}
                    onChange={(event) => {
                        context.setSaveConfigComment(event.currentTarget.value);
                        setComment(event.currentTarget.value);
                    }}
                    cols={200}
                    rows={10}
                />
                <br/>
                <h3>how long did you work on that change?</h3>
                <Input
                    id='WorkTimeInput'
                    type='number'
                    label='minutes'
                    labelPosition={"right"}
                    placeholder='minutes'
                    value={workTime}
                    onChange={(event) => {
                        setWorkTime(event.currentTarget.value);
                    }}
                />
                <br/>
                <Button id='ChangeConfigModalSaveButton' primary loading={isSubmitting} onClick={() => {
                    save().then(_ => {});
                }}>Save</Button>
                <Button id='ChangeConfigModalCancelButton' onClick={() => handleClose()}>Cancel</Button>

            </Modal.Content>
        </Modal>
    );
}