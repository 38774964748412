import * as React from "react";
import { Tab } from "semantic-ui-react";
import { TransportOrderScriptView } from "./scriptViews/TransportOrderScriptView";
import { TransportScriptView } from "./scriptViews/TransportScriptView";
import { StopScriptView } from "./scriptViews/StopScriptView";
import { FreightScriptView } from "./scriptViews/FreightScriptView";

const panes = [
    { menuItem: 'TransportOrder', render: props => <Tab.Pane><TransportOrderScriptView /></Tab.Pane> },
    { menuItem: 'Transport', render: props => <Tab.Pane><TransportScriptView /></Tab.Pane> },
    { menuItem: 'Stop', render: props => <Tab.Pane><StopScriptView /></Tab.Pane> },
    { menuItem: 'Freight', render: props => <Tab.Pane><FreightScriptView /></Tab.Pane> },

]

const TabMenuMultiScript = props => <Tab
    {...props = props} panes={panes}
    defaultActiveIndex={props.context.tabSession.multiScriptTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("multiScriptTab", data.activeIndex)
    }}
/>

export default TabMenuMultiScript