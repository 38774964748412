import * as React from "react";
import {Tab} from 'semantic-ui-react'
import {LisDocViewer} from "./lisDocViewer";
import {EdiDocViewer} from "./edi/EdiDocViewer";
import {MatrixDocViewPage} from "./matrixDocViewer/MatrixDocViewPage";
import {FortrasDocViewer} from "./FortrasDocViewer";

const panes = [
    { menuItem: 'LIS', render: props => <Tab.Pane id='LisTab'><LisDocViewer output={props.externoutput}/></Tab.Pane> },
    { menuItem: 'EDI', render: props => <Tab.Pane id='EdiTab'><EdiDocViewer output={props.externoutput}/></Tab.Pane> },
    { menuItem: 'MATRIX', render: props => <Tab.Pane id='matrixTab'><MatrixDocViewPage output={props.externoutput}/></Tab.Pane> },
    { menuItem: 'FORTRAS', render: props => <Tab.Pane id='fortrasTab'><FortrasDocViewer output={props.externoutput}/></Tab.Pane> },
]

const DocViewerTabView = props => <Tab
    id={"DocViewTabView"}
    class={"flexTab"}
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.docViewerTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("docViewerTab", data.activeIndex)
    }}
/>

export default DocViewerTabView