import * as React from "react";
import {ChangeEvent, useEffect, useState} from "react";
import DialogModal from "./DialogModal";
import {AiPrompt} from "../slices/ruleSlice";
import "./AiPomptModal.css"
import {useSelector} from "react-redux";
import {getFlatOutputList} from "../slices/tableSlice";
import {AiPomptTester} from "./AiPomptTester";

export type AiPomptModalProps = {
    pompt: AiPrompt
    onSave: (pompt:AiPrompt)=>void
}
export const AiPromptModalButton = (props:React.PropsWithChildren<AiPomptModalProps>) => {
    const [isOpened, setIsOpened] = useState(false);

    const deriveState = () => {
        if(!props.pompt){
            console.log("********** Prompt is not set! **********")
        }
        return {
            name: props.pompt.name??"",
            instructionText: props.pompt?.instructionText??"",
            examples: props.pompt?.examples??[]
        }
    }

    const [formState, setFormState] = useState(deriveState())
    const selections = useSelector(getFlatOutputList).filter(s=>s.tagOrSelection == formState.name || s.tagOrSelection.endsWith("."+formState.name))


    useEffect(()=>{
        setFormState(deriveState())
    }, [props.pompt])


    const onProceed = () => {
        props.onSave({...props.pompt, ...formState})
    };

    const handleEvent = (evt:ChangeEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>)=>{
        let valueToSet : any = evt.target.value
        if(evt.target.type === 'checkbox' && "checked" in evt.target)
            valueToSet = evt.target.checked
        if(evt.target.type === 'number')
            valueToSet = parseFloat(evt.target.value)
        setFormState({
            ...formState,
            [evt.target.name]: valueToSet
        })
    }

    const handleEvent2 = (evt:ChangeEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>, idx: number)=>{
        let valueToSet : any = evt.target.value
        if(evt.target.type === 'checkbox' && "checked" in evt.target)
            valueToSet = evt.target.checked
        if(evt.target.type === 'number')
            valueToSet = parseFloat(evt.target.value)
        setFormState({
            ...formState,
            examples: formState.examples.map((e,currIdx)=> currIdx==idx?{...e, [evt.target.name]: valueToSet}:e)
        })
    }

    return (
        <div className={"aiPomptModal"}>
            <button onClick={() => setIsOpened(true)}>{props.children}</button>

            <DialogModal
                title="Modify AI Pompt"
                isOpened={isOpened}
                onProceed={onProceed}
                onClose={() => setIsOpened(false)}
            >
                <form>
                    <fieldset>
                        <legend>Pompt</legend>
                        <div className={"aiFormGrid"}>
                            <label>Name</label>
                            <input type={"text"} value={formState.name} name={"name"} onChange={handleEvent} className={"w100"}/>

                            <label>Instruction Text</label>
                            <textarea  value={formState.instructionText} name={"instructionText"} onChange={handleEvent} rows={10} className={"w100"}/>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Examples</legend>
                        <div className={"aiFormGrid2"}>
                            <span className={"subHeading"}>Example Input</span>
                            <span className={"subHeading"}>Expected Output (JSON)</span>
                            <span></span>
                            {formState.examples?.map((e,idx)=><>
                                <input type={"text"} value={e.input} name={"input"} onChange={evt=>handleEvent2(evt,idx)} className={"w100"}/>
                                <input type={"text"} value={e.output} name={"output"} onChange={evt=>handleEvent2(evt,idx)} className={"w100"}/>
                                <button type={"button"} onClick={()=>setFormState({...formState, examples: formState.examples.filter(x=>x != e)})}>X</button>
                            </>)}
                        </div>
                        <button type={"button"} onClick={()=>setFormState({...formState, examples: [...formState.examples, {input:undefined,output:undefined}]})}>+</button>
                    </fieldset>

                    <fieldset>
                        <legend>Usage & Test</legend>
                        <div className={"aiFormGrid2"}>
                            {
                                selections.map(match=>{
                                    return <AiPomptTester pompt={{id: props.pompt.id, ...formState}} exampleInput={match.data}/>
                                })
                            }
                        </div>
                    </fieldset>


                </form>
            </DialogModal>
        </div>
    );
};
