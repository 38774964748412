import * as React from "react";
import {Tab} from 'semantic-ui-react'
import {ExportSimOutput} from "./ExportSimOutput";
import {LogView} from "../../../logView/LogView";
import DocViewerTabView from "../../../docViewer/docViewerTabView";
import {SimpleTextBox} from "../../../../../util/SimpleTextBox";

const panes = [
    {
        menuItem: 'Extern Format', render: props => <Tab.Pane id='textOutputPane'>
            <ExportSimOutput placeholder='Extern (e.g. LIS)'/>
        </Tab.Pane>
    },
    {
        menuItem: 'Doc-Viewer', render: props => <Tab.Pane>
            <DocViewerTabView context={props.context} externoutput={props.externOutput}/>
        </Tab.Pane>
    },
    {
        menuItem: 'QueuedEvent', render: props => <Tab.Pane>
            <SimpleTextBox
                value={props.queuedEventOutput}
                placeholder={'QueuedEvent'}
                readOnly={true}
                onChange={() => {
                }}
            />

        </Tab.Pane>
    },
    {
        menuItem: 'Config', render: props => <Tab.Pane>
            <SimpleTextBox
                value={props.configOutput}
                placeholder={'Config'}
                readOnly={true}
                onChange={() => {
                }}
            />
        </Tab.Pane>
    },
    {
        menuItem: 'Logs', render: props => <Tab.Pane>
            <LogView query={props.xRequestId} origin={"exportSim"}/>
        </Tab.Pane>
    },
]

const ExportSimOutputTabMenu = props => <Tab
    className={"flexTab"}
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.exportSimOutputTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("exportSimOutputTab", data.activeIndex)
    }}
/>

export default ExportSimOutputTabMenu