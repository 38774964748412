import * as React from "react";
import { Modal, Header, Button, Label, Segment, Grid, Icon } from "semantic-ui-react";
import { FileSystemEntry } from "../../../../generated-ftp/api";

export interface FtpExplorerShowFileSystemEntryProps {
    file: FileSystemEntry
}

export class FtpExplorerShowFileSystemEntry extends React.Component<FtpExplorerShowFileSystemEntryProps, any> {

    constructor(props) {
        super(props)
        this.state = {
            file: null
        }
    }

    render() {

        let file: FileSystemEntry = this.props.file

        return <Segment>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Column>
                    <Grid.Row>
                        Name:
                    </Grid.Row>
                    <Grid.Row>
                        Size:
                    </Grid.Row>
                    <Grid.Row>
                        Created:
                    </Grid.Row>
                    <Grid.Row>
                        Updated:
                    </Grid.Row>
                    <Grid.Row>
                        Type:
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>

                    <Grid.Row>
                        {file.name}
                    </Grid.Row>
                    <Grid.Row>
                        {file.size}
                    </Grid.Row>
                    <Grid.Row>
                        {file.created}
                    </Grid.Row>
                    <Grid.Row>
                        {file.updated}
                    </Grid.Row>
                    <Grid.Row>
                        {this.drawTypeIcon(file.isDirectory)}
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </Segment>

    }

    drawTypeIcon(isDirectory: Boolean) {
        if (isDirectory == true) {
            return <p><Icon name='folder' /> Directory</p>
        } else {
            return <p><Icon name='file outline' /> File</p>
        }
    }

}