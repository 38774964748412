import * as React from "react";
import { ConfigContext } from "../../../../context/ConfigContext";
import AceEditor from 'react-ace';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'

export class TransportOrderScriptView extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading TransportOrderScriptView")

        this.state = {
        }
    }

    render() {
        return <AceEditor
            theme="monokai"
            mode='groovy'
            value={this.context.multiScriptExeToScript}
            placeholder='Groovy Script'
            width="100%"
            height="800px"
            onChange={(value) => this.context.setMultiScriptExeToScript(value)}
            readOnly={false}
        />
    }
}
