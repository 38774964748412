import * as React from "react";
import {Button, Dropdown, DropdownItemProps, Header, Modal} from "semantic-ui-react";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {ExtractToSpecificConfigRequest} from "../../../../../../generated";
import {ConfigContext} from "../../../../context/ConfigContext";
import {updateConfig, workingConfig} from "../../ConfigSignal";

export interface ExtractToSpecificConfigModalProps {
    isOpen: boolean
    onClose: () => void
}

export class ExtractToSpecificConfigModal extends React.Component
    <ExtractToSpecificConfigModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            specifificConfig: null
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    async save() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let request = {} as ExtractToSpecificConfigRequest
        request.config = JSON.parse(workingConfig.value)
        request.specifificConfig = this.state.specifificConfig
        let response = await backend.internalApi.extractToSpecificConfig(request, auth)
        updateConfig(JSON.stringify(response.newConfig, null, 4), true, false)
        this.handleClose()
    }

    enumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(ExtractToSpecificConfigRequest.SpecifificConfigEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Extract to specific config'/>
            <Modal.Content>

                <Dropdown
                    selectOnBlur={false}
                    readOnly={this.state.readonly}
                    clearable
                    options={this.enumToArray()}
                    selection
                    value={this.state.specifificConfig}
                    onChange={(evt, data) => this.setState({specifificConfig: data.value})}/>


                <Button disabled={this.state.specifificConfig == null} primary loading={this.state.isSubmitting}
                        onClick={() => {
                            this.save();
                            this.handleClose
                        }}>extract
                </Button>
                <Button onClick={() => this.handleClose()}>cancel
                </Button>

            </Modal.Content>
        </Modal>

    }

}