import * as React from "react";
import { Modal, Header, Button } from "semantic-ui-react";
import { LeaveWithChangeDecision } from "../../../../../ui/admin/select_company_modal";
import { ChangeConfigModal } from "./ChangeConfigModal";
import { CompanyConfiguration, TriggerTransportOrderConfigTestsRequest } from "../../../../../../generated";
import { backend } from "../../../../../../xconvert-backend";
import { IfBox } from "../../../../../style/if";
import { authentication } from "../../../../../../authentication";
import { ConfigContext } from "../../../../context/ConfigContext";
import { SaveAsDraftModal } from "./SaveAsDraftModal";
import {DiscardModal} from "./DiscardModal";
import {workingConfig} from "../../ConfigSignal";

export interface LeaveWithChangesModalProps {
    isOpen: boolean
    onClose: () => void
    currentCompanyConfig: CompanyConfiguration | null
    dispatcher: (decision: LeaveWithChangeDecision) => void
}

export interface LeaveWithChangesModalState {
    showChanges: boolean,
    showSaveAsDraft: boolean,
    showDiscardModal: boolean,
    failedTests: any[]

}

export class LeaveWithChangesModal extends React.Component<LeaveWithChangesModalProps, LeaveWithChangesModalState> {

    constructor(props) {
        super(props)
        this.state = {
            showChanges: false,
            showSaveAsDraft: false,
            showDiscardModal: false,
            failedTests: [],
        }
    }

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    componentDidMount() {
        this.setState({showDiscardModal: false})
    }

    handleClose = () => {
        this.props.onClose()
    }


    handleKeepInLocalStorage() {
        this.props.dispatcher(LeaveWithChangeDecision.KEEP_IN_STORAGE)

        this.handleClose()
    }

    handleDiscard() {
        this.setState({showDiscardModal: true})
    }

    handleSaveAsDraft() {
        this.setState({ showSaveAsDraft: true })
        //this.handleClose()
    }

    handleCancel() {
        this.props.dispatcher(LeaveWithChangeDecision.CANCEL)
        this.handleClose()
    }

    handleSave() {
        this.triggerActiveTest()

    }

    async triggerActiveTest() {
        // trigger all active transportOrderConfigTests



        // get all tests for this company
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let tests = (await backend.internalApi.listTransportOrderConfigTests(this.context.companyId, null, null, null, null, null, auth)).tests

        // make a list with the ids of the active tests
        let activeTests = tests.filter((test) => {
            return test.active
        })
        let ids = Object.values(activeTests).map(test => { return test._id })

        // trigger the active tests
        let request = {} as TriggerTransportOrderConfigTestsRequest
        request.config = JSON.parse(workingConfig.value)
        request.companyId = this.context.companyId
        request.testIds = ids

        let result = (await backend.internalApi.triggerTransportOrderTests(request, auth))

        // check if there are any results not positiv
        let failedTest = []
        Object.values(result.results).map(res => {
            if (!res.success) {
                console.log("Test: " + res.transportOrderConfigTestId + " failed!")
                failedTest.push({ "test": activeTests.find(test => { return test._id == res.transportOrderConfigTestId }), "result": res })
            } else {
                console.log("Test: " + res.transportOrderConfigTestId + " success!")
            }
        })

        console.log("tests failed: " + failedTest.length)
        this.setState({ failedTests: failedTest })
        this.setState({ showChanges: true })

    }

    render() {

        return <Modal
            id='ScriptModal'
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='There are changes in the config.' />
            <Modal.Content>
                <p>Es gibt noch ungespeicherte Änderungen an der Config.</p>
                <Button id='SaveButton' onClick={() => this.handleSave()}>Save</Button>
                <Button id='SaveAsDraftButton' onClick={() => this.handleSaveAsDraft()}>Save as Draft</Button>
                <Button id='KeepInLocalStorageButton' onClick={() => this.handleKeepInLocalStorage()}>Keep in local storage</Button>
                <Button id='DiscardButton' onClick={() => this.handleDiscard()}>Discard changes</Button>
                <Button id='CancelButton' onClick={() => this.handleCancel()}>Cancel</Button>
                <IfBox shouldShow={this.state.showChanges}>
                    <ChangeConfigModal
                        isOpen={this.state.showChanges}
                        onClose={((updatedConfig: boolean) => {
                            this.setState({ showChanges: false })
                            if (updatedConfig) {
                                this.props.dispatcher(LeaveWithChangeDecision.SAVE)
                                this.handleClose()
                            }
                        })}
                        oldConfig={JSON.stringify(this.props.currentCompanyConfig, null, 4)}
                        newConfig={workingConfig.value}
                        failedTests={this.state.failedTests}
                        validationWarnings={null}
                    />
                </IfBox>
                <IfBox shouldShow={this.state.showSaveAsDraft} >
                    <SaveAsDraftModal
                        isOpen={this.state.showSaveAsDraft}
                        onClose={() => this.setState({ showSaveAsDraft: false })}
                        oldConfig={JSON.stringify(this.props.currentCompanyConfig, null, 4)}
                        draft={workingConfig.value}
                        dispatch={() => this.props.dispatcher(LeaveWithChangeDecision.SAVE_AS_DRAFT)}
                    />
                </IfBox>
                <IfBox shouldShow={this.state.showDiscardModal} >
                    <DiscardModal
                        isOpen={this.state.showDiscardModal}
                        onClose={() => {
                            this.setState({ showDiscardModal: false })
                        }}
                        dispatch={() => this.props.dispatcher(LeaveWithChangeDecision.DISCARD)}
                    />
                </IfBox>
            </Modal.Content>
        </Modal>
    }

}