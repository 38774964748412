import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {LinkInfo, StopAction, Transport, TransportLinkingRequest, TransportOrder} from "../../../../../generated";
import {Button, Checkbox, Divider, Dropdown, DropdownItemProps, Grid, List, Segment} from "semantic-ui-react";
import ReactTable from "react-table";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import Notifications, {notify} from 'react-notify-toast';
import {IfBox} from "../../../../style/if";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";

export interface TransportOrderLinkViewProps {
    reload: (reload: boolean) => void
    transportOrders: TransportOrder[],
    linkingPartners: any[]
}

export interface TransportOrderLinkViewState {
    isLoading: boolean,
    sourceTransportOrder: TransportOrder | null,
    targetTransportOrder: TransportOrder | null

    sourceToId: string,
    targetToId: string,
    sourceSelectedTransport: Transport | null
    targetSelectedTransport: Transport | null

}


export class TransportOrderLinkView extends React.Component<TransportOrderLinkViewProps, TransportOrderLinkViewState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props: TransportOrderLinkViewProps) {
        super(props)
        // console.log("loading HistoryConfig")

        this.state = {
            isLoading: false,
            sourceToId: "",
            targetToId: "",
            sourceTransportOrder: null,
            targetTransportOrder: null,
            sourceSelectedTransport: null,
            targetSelectedTransport: null,
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Readonly<TransportOrderLinkViewProps>) {
        if(this.props.transportOrders.length != prevProps.transportOrders.length) {
            if(this.state.sourceTransportOrder == null && prevProps.transportOrders.length > 0) {
                this.setState({
                    sourceTransportOrder: prevProps.transportOrders[0],
                    sourceToId: prevProps.transportOrders[0]._id
                })
            }

            if(this.state.targetTransportOrder == null && prevProps.transportOrders.length > 1) {
                this.setState({
                    targetTransportOrder: prevProps.transportOrders[1],
                    targetToId: prevProps.transportOrders[1]._id
                })
            }

            if(prevProps.transportOrders.length < 2 && this.state.targetToId != "") {
                this.setState({
                    targetTransportOrder: null,
                    targetToId: ""
                })
            }

            if(prevProps.transportOrders.length < 1 && this.state.sourceToId != "") {
                this.setState({
                    sourceTransportOrder: null,
                    sourceToId: ""
                })
            }
        }
    }

    dropDownOptions() {
        let opt: Array<DropdownItemProps> = [];
        if (this.props.transportOrders != null) {
            this.props.transportOrders.forEach(to => opt.push({key: to._id, value: to._id, text: to._id}))
        }
        return opt
    }


    selectTo(id: string , source: boolean) {
        let to = this.props.transportOrders.find(to => {
            return to._id == id
        })
        if (source) {
            this.setState({
                sourceToId: id,
                sourceTransportOrder: to,
                sourceSelectedTransport: null
            })
        } else {
            this.setState({
                targetToId: id,
                targetTransportOrder: to,
                targetSelectedTransport: null
            })
        }

    }

    async onLink() {
        this.setState({isLoading: true})

        let auth = backend.withTokenAuthHeader(authentication.token)

        let request = {} as TransportLinkingRequest
        request.sourceTransportOrderId = this.state.sourceTransportOrder._id
        request.sourceTransportId = this.state.sourceSelectedTransport.transportId

        request.targetTransportOrderId = this.state.targetTransportOrder._id
        request.targetTransportId = this.state.targetSelectedTransport.transportId
        request.copyObserversFromSourceTransport = false
        let response = await backend.internalApi.linkTransports(request, auth)


        if (response.success) {
            notify.show('link established', 'success', 3000, '#28f751');
            this.props.reload(true)
        } else {
            notify.show("ERROR: unable to link the selected Transports: ", 'error', 5000, '#fc0303')
        }

        this.setState({isLoading: false})
    }

    displaySourceLinkInfo(linkInfo: LinkInfo) {

        let list = []

        linkInfo?.sourceLinks?.map(links => {
            list.push(links.transportOrderId + ":" + links.transportId)
        })

        return list

    }

    displayTargetLinkInfo(linkInfo: LinkInfo) {

        let list = []

        linkInfo?.targetLinks?.map(links => {
            list.push(links.transportOrderId + ":" + links.transportId)
        })

        return list

    }

    displayLinkingPartners() {
        // console.log("displayLinkingPartner: this.props.linkingPartners", this.props.linkingPartners)
        return <IfBox shouldShow={this.props.linkingPartners?.length > 0}>
            <Segment>
                <p>Here is a list of all partners that can be linked to or linked from:</p>
                <List bulleted>
                    {
                        this.props.linkingPartners.map(p => {
                            console.log("displayLinkingPartner: p = ", p)
                            return <List.Item>{p.name}</List.Item>
                        })
                    }
                </List>
            </Segment>
        </IfBox>
    }

    render() {

        const sourceColumns = [{
            id: 'select',
            Header: 'select',
            width: 50,
            accessor: (d: Transport) => <Checkbox
                checked={this.state.sourceSelectedTransport == d}
                onChange={() => this.setState({sourceSelectedTransport: d})}
            />
        }, {
            id: 'ID',
            Header: 'Id',
            width: 50,
            accessor: (d: Transport) => d.transportId,

            sortField: 'ID'
        }, {
            id: 'loadingLocation',
            Header: 'loadingLocation',
            width: 150,
            accessor: (d: Transport) => d.stops.find(stop => stop.actions.find(action => action.kind == StopAction.KindEnum.LOAD) != null)?.city,
            sortField: 'LOADING_LOCATION'
        }, {
            id: 'unloadingLocation',
            Header: 'unloadingLocation',
            width: 150,
            accessor: (d: Transport) => d.stops.find(stop => stop.actions.find(action => action.kind == StopAction.KindEnum.UNLOAD) != null)?.city,
            sortField: 'UNLOADING_LOCATION'
        }, {
            id: 'linked_with',
            Header: 'linked with',
            width: 150,
            accessor: (d: Transport) => this.displayTargetLinkInfo(d.linkInfo),
            sortField: 'UNLOADING_LOCATION'
        }
        ]

        const targetColumns = [{
            id: 'select',
            Header: 'select',
            width: 50,
            accessor: (d: Transport) => <Checkbox
                checked={this.state.targetSelectedTransport == d}
                onChange={() => {
                    this.setState({targetSelectedTransport: d})

                    console.log("check: ", d)
                    console.log("check_state: ", this.state)
                }}
            />
        }, {
            id: 'ID',
            Header: 'Id',
            width: 50,
            accessor: (d: Transport) => d.transportId,

            sortField: 'ID'
        }, {
            id: 'loadingLocation',
            Header: 'loadingLocation',
            width: 150,
            accessor: (d: Transport) => d.stops.find(stop => stop.actions.find(action => action.kind == StopAction.KindEnum.LOAD) != null)?.city,
            sortField: 'LOADING_LOCATION'
        }, {
            id: 'unloadingLocation',
            Header: 'unloadingLocation',
            width: 150,
            accessor: (d: Transport) => d.stops.find(stop => stop.actions.find(action => action.kind == StopAction.KindEnum.UNLOAD) != null)?.city,
            sortField: 'UNLOADING_LOCATION'
        }, {
            id: 'linked_with',
            Header: 'linked with',
            width: 150,
            accessor: (d: Transport) => this.displaySourceLinkInfo(d.linkInfo),
            sortField: 'UNLOADING_LOCATION'
        }
        ]

        let sourceTransports = this.props.transportOrders?.find(to => {
            return to._id == this.state.sourceToId
        })?.transports

        let targetTransports = this.props.transportOrders?.find(to => to._id == this.state.targetToId)?.transports

        let sourcePageSize = sourceTransports?.length ? sourceTransports?.length : 1
        let targetPageSize = targetTransports?.length ? targetTransports?.length : 1

        if (this.props.transportOrders?.length == 0) {
            return <>
                <p> Please select the transportOrders you want to link </p>
                {this.displayLinkingPartners()}
            </>
        }

        const disableLinkBtn = this.state.sourceSelectedTransport == null || this.state.targetSelectedTransport == null
            || this.state.sourceToId == "" || this.state.targetToId == ""

        return <div id={"linkView"} className={"flex1"}>
            <Segment className={"flex1"}>
                <h3 draggable>Companies with linking enabled:</h3>
                {this.displayLinkingPartners()}
            </Segment>
            <Segment className={"flex3"}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "2px",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <h3 style={{margin: "0"}}>Source TransportOrder:</h3>
                    <Dropdown
                        selectOnBlur={false}
                        style={{minWidth: "250px"}}
                        clearable
                        selection
                        options={this.dropDownOptions()}
                        value={this.state.sourceToId}
                        onChange={(_, data: DropdownProps) => this.selectTo(data.value as string, true)}
                    />
                </div>
                <ReactTable
                    data={sourceTransports}
                    pages={1}
                    page={0}
                    columns={sourceColumns}
                    sorted={[]}
                    defaultPageSize={sourcePageSize}
                    pageSize={sourcePageSize}
                    className="-striped -highlight"
                    loading={this.state.isLoading}
                    style={{cursor: "pointer"}}
                    showPagination={false}
                />

            </Segment>

            <Segment className={"flex3"}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "2px",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <h3 style={{margin: "0"}}>Target TransportOrder:</h3>
                    <Dropdown
                        selectOnBlur={false}
                        style={{minWidth: "250px"}}
                        clearable
                        selection
                        options={this.dropDownOptions()}
                        value={this.state.targetToId}
                        onChange={(_, data) => this.selectTo(data.value as string, false)}
                    />
                </div>
                <ReactTable
                    data={targetTransports}
                    pages={1}
                    page={0}
                    columns={targetColumns}
                    sorted={[]}
                    defaultPageSize={targetPageSize}
                    pageSize={targetPageSize}
                    className="-striped -highlight"
                    loading={this.state.isLoading}
                    style={{cursor: "pointer"}}
                    showPagination={false}
                />

            </Segment>

            <Button primary={!disableLinkBtn}
                    disabled={disableLinkBtn}
                    onClick={() => this.onLink()}
            >
                Link
            </Button>
            <Notifications/>
        </div>
    }
}
