import {Tab} from "semantic-ui-react";
import * as React from "react";
import {SystemStatusMessageEdit} from "./SystemStatusMessageEdit";
import {SystemStatusMessageRaw} from "./SystemStatusMessageRaw";

const panes = [
    {
        menuItem: 'mask', render: props => <Tab.Pane><SystemStatusMessageEdit
            isOpen={props.isOpen}
            onClose={props.onClose}
            message={props.message}
            onlyOwnCompany={props.onlyOwnCompany}
        /></Tab.Pane>
    },
    {
        menuItem: 'raw', render: props => <Tab.Pane><SystemStatusMessageRaw
            isOpen={props.isOpen}
            onClose={props.onClose}
            message={props.message}
            onlyOwnCompany={props.onlyOwnCompany}
        /></Tab.Pane>
    },
]

const SystemStatusMessageEditTab = props => <Tab
    {...props = props}
    panes={panes}
/>

export default SystemStatusMessageEditTab