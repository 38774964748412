import * as React from "react";
import {Button, Header, Icon, Label, List, Modal, Popup} from "semantic-ui-react";
import {ConfigValidationResponse} from "../../../../../../generated";

export interface TestConfigModalProps {
    isOpen: boolean
    onClose: () => void
    validationResponse: ConfigValidationResponse
    reloadPage: boolean
}

export class TestConfigModal extends React.Component<TestConfigModalProps, any> {

    constructor(props) {
        super(props)
        this.state = {}
    }

    handleClose = () => {
        if (this.props.reloadPage) {
            window.location.reload();
        }
        this.props.onClose()
    }

    render() {
        if (this.props.isOpen) {
            console.log("verifyResponse was : ", this.props.validationResponse)
        }
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}

            size='large'>
            <Header icon='browser' content='Test Config'/>
            <Modal.Content>
                <Label>verification: </Label>
                {this.renderVerificationResult()}

                <br/>
                <br/>

                <Label>Validation: </Label>
                {this.renderValidationWarnings()}

                <br/>

                <Button id='CloseValidateResponseModal' onClick={() => this.handleClose()}>Close</Button>

            </Modal.Content>
        </Modal>
    }

    renderValidationWarnings() {
        if (this.props.validationResponse?.warnings?.length > 0) {
            return <List bulleted>
                {this.props.validationResponse.warnings.map((warning) => {
                    return <List.Item key={warning.ruleName}>
                        <Popup content={
                            <div>
                                <p><a style={{fontWeight: "bold"}}> RuleName: </a>{warning.ruleName}</p>
                                <p><a style={{fontWeight: "bold"}}> Description: </a>{warning.description}</p>
                            </div>
                        } trigger={
                            <div>
                                <Icon name="exclamation triangle" color="yellow" size={"large"}/> {" - "}
                                {warning.warning}
                            </div>
                        }
                               on={'hover'}
                               wide={"very"}
                        />


                    </List.Item>
                })}
            </List>
        } else {
            return <p color={'green'}>No Warnings!</p>
        }

    }

    renderVerificationResult() {
        if (this.props.validationResponse != null) {
            return <div
                id='ValidateResponse'>{this.renderIcon()}{this.props.validationResponse.verifyResponse.message}</div>
        } else {
            return <div>{"NO DATA"}</div>
        }
    }

    renderIcon() {
        if (this.props.validationResponse.verifyResponse.isValid) {
            return <Icon id='ValidateResponseIcon' size='big' name='check square outline' color='green'/>
        } else {
            return <Icon id='ValidateResponseIcon' size='big' name='times' color='red'/>

        }
    }
}