import * as React from "react";
import {ConfigContext} from "../../context/ConfigContext";
import {PageWithSidebar} from "../../../style/page_with_sidebar";
import Notifications from 'react-notify-toast';
import CustomerAccountingTabMenu from "./CustomerAccountingTabMenu";

export class CustomerAccountingPage extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return <PageWithSidebar activeMenu="Contracts">
            <CustomerAccountingTabMenu context={this.context}/>

            <Notifications/>
        </PageWithSidebar>;
    }

}