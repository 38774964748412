import * as Leaflet from "leaflet";
import {Icon} from "leaflet";
import * as React from "react";
import {Map as LeafletMap, Marker, Polyline} from 'react-leaflet';

type DirectionPolylineProps = {
    positions: Leaflet.LatLngExpression[],
    mapRef: React.RefObject<LeafletMap>,
    distance?: number,
    color: string,
    onClick?: () => void
}

export const DirectionPolyline: React.FC<DirectionPolylineProps> = ({positions, mapRef, distance, color, onClick}) => {
    let prevPos: Leaflet.LatLngExpression
    let angle
    let arrowCount
    let index = 5
    let arrowFrequency = 10
    if(distance && distance >= 20000){
        arrowCount = Math.floor(distance/20000)
    }else if(distance && distance >= 5000){
        arrowCount = 1
    }
    if(arrowCount){
        index = 0
        arrowFrequency = Math.floor((positions.length)/(arrowCount+1))
    }

    return <React.Fragment>
        <Polyline positions={positions} onclick={onClick} color={color}>
        </Polyline>
        {positions.map((pos: Leaflet.LatLngExpression) => {
            const calcPos = Leaflet.latLng(pos)
            if(prevPos && index >= arrowFrequency && mapRef.current?.leafletElement.distance(prevPos, pos) && mapRef.current?.leafletElement.distance(prevPos, pos) > 1000){
                index = 0
                const prevCalcPos = Leaflet.latLng(prevPos)
                angle = Math.atan2((calcPos.lng - prevCalcPos.lng),(calcPos.lat - prevCalcPos.lat))
                let svgString = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><circle fill="${color}" r="10" cy="10" cx="10"/><path fill="white" d="M10 3 L16.5 14 L10 11 L3.5 14 Z" transform="rotate(${angle * 180/Math.PI}, 10, 10)"></path></svg>`
                // d="M10 3 L16.5 14 L13.5 14 L10 7 L6.5 14 L3.5 14 Z"
                let myIconUrl = "data:image/svg+xml," + encodeURIComponent(svgString)
                const arrowIcon = new Icon({
                    iconUrl: myIconUrl,
                    iconSize: [20, 20],
                    iconAnchor: [10, 10]
                })
                const markerPos = Leaflet.latLng((calcPos.lat + prevCalcPos.lat)/2,(calcPos.lng + prevCalcPos.lng)/2)
                prevPos = pos
                return<Marker icon={arrowIcon} position={markerPos} onclick={onClick} zindexOffset={-1000}/>
            }else{
                prevPos = pos
                index++
            }


        })}
    </React.Fragment>
}
