import * as React from "react";
import {Icon} from "semantic-ui-react";
import {CustomerReportDynamicSection, statusHighlighter} from "../CustomerReportDynamicSection";
import {useContext} from "react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest} from "../../../../../../generated";
import ServiceTypeEnum = AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest.ServiceTypeEnum;
import {customerReport} from "../../../../../style/page_with_sidebar";

export function CustomerReportSftpStatus({ reFetchReportData }: { reFetchReportData: () => Promise<void> }) {

    const context = useContext(ConfigContext)


    const columns = [{
        id: 'companyName',
        Header: 'companyName',
        width: 300,
        accessor: (d: any) => d.name,
    }, {
        id: 'icon',
        Header: 'sftp status',
        width: 100,
        accessor: (d: any) => <div>{statusHighlighter(d.status)} {d.status}</div>,
    },{
        id: 'responsibleManager',
        Header: 'Responsible manager',
        width: 100,
        accessor: (d: any) => d.responsibleManager,
    },
    ]
    return <CustomerReportDynamicSection
        columns={columns}
        data={customerReport.value?.fileSystemWorkerStatus?.sftpSyncResults}
        currentService={ServiceTypeEnum.SFTPREPORT}
        reFetchReportData={reFetchReportData}
    />
}