import * as React from "react";
import {Button, ButtonGroup, Header, Icon, List, Modal, Popup} from "semantic-ui-react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {FaCodeMerge} from "react-icons/fa6";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {CherryPickModal} from "../../CherryPickModal";
import {configStorage} from "../../../../../../ConfigStorage";
import {updateConfig, workingConfig} from "../../ConfigSignal";

export interface ConflictModalProps {
    isOpen: boolean
    onClose: () => void
    saveAsDraft: () => void
    discardConfig: () => void
    formatConfig: () => void
}

export function ConflictModal(props: React.PropsWithChildren<ConflictModalProps>) {


    let context = React.useContext(ConfigContext)

    const auth = backend.withTokenAuthHeader(authentication.token)

    const [showMergeModal, setShowMergeModal] = React.useState(false)

    const [newestConfig, setNewestConfig] = React.useState("")


    function handleClose() {
        props.onClose()
    }

    function draftConfigWrapper() {
        props.saveAsDraft()
        props.onClose()
    }

    function discardConfigWrapper() {
        props.discardConfig()
        props.onClose()
    }

    async function formatConfigWrapper() {
        props.formatConfig()
        props.onClose()
    }

    async function prepareMergeModal() {
        const currentCompanyConfig = await backend.internalApi.fetchCompanyConfiguration(context.companyId, auth)
        setNewestConfig(JSON.stringify(currentCompanyConfig, null, 4))
        console.log("prepareMergeModal, newestConfig: ", newestConfig)
        setShowMergeModal(true)
    }

    return <Modal
        id='ConflictModal'
        open={props.isOpen}
        onClose={handleClose}
        size='small'>
        <Header icon='browser' content='Conflict!'/>
        <Modal.Content>

            <p>Someone was faster! While you were working on this config, someone else changed it.</p>
            <p>To avoid overwriting the others config change(s), you can not save now.</p>
            <p>Here are the steps to merge: </p>
            <List>
                <List.Item>save your changes as Draft.</List.Item>
                <List.Item>discard your changes to load the fresh Config from the DB.</List.Item>
                <List.Item>open your draft and handpick your changes.</List.Item>

            </List>

            <ButtonGroup size='small' floated='left'>
                <Popup content='Saves config as draft' trigger={
                    <Button id='Save config as draft' icon
                            onClick={() => draftConfigWrapper()}
                    ><Icon name={'beer'} size={'large'}/></Button>
                }/>
                <Popup content='Discards the Config' trigger={
                    <Button id='DiscardConfigButtonTree' icon
                            onClick={() => discardConfigWrapper()}
                    ><Icon name={'undo'} size={'large'}/></Button>
                }/>
                <Popup content='Formats the Config' trigger={
                    <Button id='FormatConfigButtonTree' icon
                            onClick={() => formatConfigWrapper()}
                    ><Icon name={'band aid'} size={'large'}/></Button>
                }/>
                <Popup content='Merge the Configs' trigger={
                    <Button id='MergeConfigsButtonTree' disabled={!workingConfig.value} icon
                            onClick={() => prepareMergeModal()}
                    ><FaCodeMerge size={24}/></Button>
                }/>
            </ButtonGroup>
            <Button
                style={{marginBottom: '10px'}}
                floated='right'
                onClick={() => handleClose()}
            >
                Close
            </Button>

            {showMergeModal && <CherryPickModal

                isOpen={showMergeModal}
                left={workingConfig.value}
                right={newestConfig}
                onSave={(newConfig) => {

                    updateConfig(newConfig, true, false).then(r => {
                        configStorage.deliberatelyUpdateUnchangedConfig(context.companyId, JSON.parse(newestConfig)).then(r => {
                            console.log("configStorage.deliberatelyUpdateUnchangedConfig: ", r)
                            props.onClose()
                            window.location.reload();
                        })

                    })

                }}
                onClose={() => {
                    setShowMergeModal(false)
                }}
            />}

        </Modal.Content>
    </Modal>


}