import * as React from "react";
import {PageWithSidebar} from "../../../style/page_with_sidebar";
import TabMenuScriptExecution from "./TabMenuScriptExecution";
import {ConfigContext} from "../../context/ConfigContext";

export class ScriptExecution extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading ScriptExecution")
        this.state = {
        }
    }

    render() {
        return <PageWithSidebar activeMenu="Script Execution">

            <TabMenuScriptExecution
                context={this.context}
                history={this.props.history}
                location={this.props.location}
                match={this.props.match}
            />

        </PageWithSidebar >;
    }
}