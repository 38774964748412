import * as React from "react";
import {Feed, Icon, Popup, Segment} from "semantic-ui-react";
import ReactTable, {CellInfo, Column} from "react-table";
import {ConfigContext} from "../../../../context/ConfigContext";
import {
    ChangeEvent,
    ChangeEventsFilterRequest,
    ChangeEventsResult,
    MessageLog,
    MessageLogResponse,
    Origin,
    TransportOrder
} from "../../../../../../generated";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {TransportOrderRollbackModal} from "./TransportOrderRollbackModal";
import {TransportOrderHistoryModal} from "../../modals/TransportOrderHistoryModal";
import {v4 as uuid} from "uuid";
import {getColumnData, renderObjectAttributePopup} from "./TransportOrderHistoryService";

export interface TransportOrderHistoryTableProps {
    reload: (reload: boolean) => void
    transportOrder: TransportOrder
}

export interface TransportOrderHistoryTableState {
    isLoading: boolean,
    changeEvents: ChangeEvent[] | null,
    history: HistoryColumns[] | null,
    selectedHistory: HistoryColumns | null,
    origin: Origin | null,
    showChanges: boolean,
    showRollBackView: boolean,

    //Table
    take: number,
    page: number,
    sortBy: "TIMESTAMP" | "TYPE" | "NONE",
    sortDirection: "DESC" | "ASC",

    count: number
}


export class TransportOrderHistoryTable extends React.Component<TransportOrderHistoryTableProps, TransportOrderHistoryTableState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading HistoryConfig")

        this.state = {
            isLoading: true,
            changeEvents: null,
            history: null,
            selectedHistory: null,
            origin: null,
            showChanges: false,
            showRollBackView: false,

            //Table
            take: 10,
            page: 1,
            sortBy: "TIMESTAMP",
            sortDirection: "DESC",

            count: -1
        }
    }

    async componentDidMount() {

        await this.fetchHistory()
        console.log("THE COMPONENT DID MOUNT:", this.state.history)

    }

    componentDidUpdate(prevProps: Readonly<TransportOrderHistoryTableProps>, prevState: Readonly<TransportOrderHistoryTableState>, snapshot?: any) {
        if (prevProps.transportOrder?._id != this.props.transportOrder?._id) {
            this.fetchHistory()
        }
    }


    async changeSort(sortBy: 'TIMESTAMP' | 'TYPE' | 'NONE') {
        console.log("changing sort")
        this.setState({isLoading: true})

        if (sortBy == 'NONE') {
            return
        }

        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({sortDirection: direction})
        } else {
            //change sortBy and reset direction
            this.setState({sortBy: sortBy, sortDirection: "ASC"})
        }

        await this.fetchHistory()
        this.setState({isLoading: false})

    }

    async fetchHistory() {

        const auth = backend.withTokenAuthHeader(authentication.token);
        let filter: ChangeEventsFilterRequest = {
            transportOrderId: this.props.transportOrder._id,
            companyId: this.context.companyId,
            eventType: undefined,
            eventSourceCompanyId: undefined,
            eventSourceRequestId: undefined,
            from: undefined,
            to: undefined,
        }
        const result: ChangeEventsResult = await backend.changeEvents.fetchEventsByFilter(filter, auth)

        let data: (ChangeEvent | MessageLog)[] = []
        if (result.changeEvents) {
            data = result.changeEvents
        }

        const messageLogs: MessageLogResponse = await backend.internalApi.fetchTransportOrderMessageLog(this.props.transportOrder._id, auth);
        if (messageLogs.logs) {
            data = data.concat(messageLogs.logs)
            data.sort((a, b) => {
                const timeStamp1: Date | undefined = (a as MessageLog).sendTime ?? (a as ChangeEvent).creationTimestamp
                const timeStamp2: Date | undefined = (b as MessageLog).sendTime ?? (b as ChangeEvent).creationTimestamp
                if (timeStamp2 && timeStamp1) {
                    return (new Date(timeStamp1)?.getTime() ?? 0) - (new Date(timeStamp2)?.getTime() ?? 0)
                }
                return 0
            })
        }

        const historyColumns: HistoryColumns[] = getColumnData(data)

        this.setState({
            changeEvents: data,
            history: historyColumns,
            count: historyColumns?.length ? historyColumns?.length : 0,
            isLoading: false
        })
    }

    findPreviousChange = (change: ChangeEvent | MessageLog) => {
        const index = this.state.changeEvents?.findIndex(c => c._id == change._id)
        if (index != null && index > 0) {
            return this.state.changeEvents[index - 1]
        }
        return null
    }

    render() {
        if (this.state.history == null) {
            return <p>No histories found</p>
        } else {

            const history = this.state.history
            const columns: Column[] = [
                {
                    id: 'groupId',
                    Header: "",
                    width: 35,
                    show: true,
                    filterable: false,
                    resizable: true,
                    sortable: false,
                    PivotValue: null,
                    Pivot: (row: any) => row.subRows && row.subRows.length > 1 ? <div style={{textAlign: "center"}}>
                            <div className={"rt-expander " + (row.isExpanded && "-open")} style={{top: "-5px"}}/>
                        </div>
                        : null,
                    Cell: () => <></>,
                    accessor: (c: HistoryColumns) => c.groupId.value,
                    aggregate: () => <></>,
                    Aggregated: () => <></>,

                },
                {
                    id: 'timeStamp',
                    Header: "timestamp",
                    width: 150,
                    sortable: true,
                    accessor: (c: HistoryColumns) => c.timeStamp.value,
                    Cell: (cellInfo: CellInfo) => (cellInfo.original as HistoryColumns).timeStamp.jsx,
                    aggregate: (leafValues: any) => leafValues,
                    Aggregated: (cellInfo: CellInfo) => <>{(cellInfo.subRows[0]._original as HistoryColumns)?.timeStamp.jsx}</>,
                    sortMethod: (a, b) => {
                        console.log("sort by timestamp", a, b)
                        if (a < b) {
                            return 1;
                        } else if (a < b) {
                            return -1;
                        } else {
                            return 0;
                        }
                    },
                },
                {
                    id: 'trigger',
                    Header: "trigger",
                    width: 100,
                    sortable: true,
                    accessor: (c: HistoryColumns) => c.trigger.value,
                    Cell: (cellInfo: CellInfo) => (cellInfo.original as HistoryColumns).trigger.jsx,
                    aggregate: (leafValues: any) => leafValues,
                    Aggregated: (cellInfo: CellInfo) => {
                        const distinctValues: any = distinct(cellInfo.value.filter((x: any) => !!x))
                        return distinctValues.map((value: any, index: number) => {
                            return <>{index > 0 ? ", " : null}
                                <Feed.User>{value}</Feed.User></>
                        })
                    },
                },
                {
                    id: 'sourceOrTarget',
                    Header: "source or target",
                    width: 100,
                    sortable: true,
                    filterable: false,
                    accessor: (c: HistoryColumns) => c.sourceOrTarget.value,
                    Cell: (cellInfo: CellInfo) => (cellInfo.original as HistoryColumns).sourceOrTarget.jsx,
                    aggregate: (leafValues: any) => leafValues,
                    Aggregated: (aggregatedObj: any) => {
                        const distinctValues: any = distinct(aggregatedObj.value.filter((x: any) => !!x))
                        return distinctValues.map((value: any) => {
                            const isSource = value == "Source Company"
                            return <Popup content={value}
                                          trigger={<Icon className={"highlight"}
                                                         name={isSource ? "warehouse" : "road"}
                                                         color={isSource ? "teal" : "black"}/>}
                            />
                        })
                    }
                },
                {
                    id: 'changeEventType',
                    Header: "change event type",
                    width: 300,
                    sortable: true,
                    accessor: (c: HistoryColumns) => c.changeEventType.value,
                    aggregate: (leafValues: any) => <>{leafValues[0]}</>,
                    Cell: (cellInfo: CellInfo) => <Feed key={uuid()}>
                        <Feed.Event>
                            <Feed.Content style={{whiteSpace: "break-spaces"}}>
                                {(cellInfo.original as HistoryColumns).changeEventType.jsx}
                            </Feed.Content>
                        </Feed.Event>
                    </Feed>,
                    Aggregated: (cellInfo: CellInfo) => {
                        if (!cellInfo.subRows || cellInfo.subRows?.length == 0) {
                            return <></>
                        }
                        return <Feed key={uuid()}>
                            <Feed.Event>
                                <Feed.Content style={{whiteSpace: "break-spaces"}}>
                                    {(cellInfo.subRows[0]._original as HistoryColumns)?.changeEventType.jsx}
                                </Feed.Content>
                            </Feed.Event>
                        </Feed>
                    }
                },
                {
                    id: 'modification',
                    Header: "modification",
                    sortable: true,
                    width: 300,
                    accessor: (c: HistoryColumns) => c.modification.value,
                    aggregate: (leafValues: any) => <>{leafValues[0]}</>,
                    Cell: (cellInfo: CellInfo) => <Feed key={uuid()}>
                        <Feed.Event>
                            <Feed.Content style={{whiteSpace: "break-spaces"}}>
                                {(cellInfo.original as HistoryColumns).modification.jsx}
                            </Feed.Content>
                        </Feed.Event>
                    </Feed>,
                    Aggregated: (cellInfo: CellInfo) => {
                        if (!cellInfo.subRows || cellInfo.subRows?.length == 0) {
                            return <></>
                        }
                        return <Feed key={uuid()}>
                            <Feed.Event>
                                <Feed.Content style={{whiteSpace: "break-spaces"}}>
                                    {(cellInfo.subRows[0]._original as HistoryColumns)?.modification.jsx}
                                </Feed.Content>
                            </Feed.Event>
                        </Feed>
                    }

                },
                {
                    id: 'patch',
                    Header: "patch value",
                    width: 150,
                    show: authentication.isSuperUser,
                    accessor: (c: HistoryColumns) => {
                        return c.patch.value
                    },
                    aggregate: (leafValues: any) => leafValues,
                },
                {
                    id: 'xRequestId',
                    Header: "x-Request-Id",
                    width: 300,
                    sortable: true,
                    show: authentication.isSuperUser,
                    accessor: (c: HistoryColumns) => c.xRequestId.value,
                    Cell: (row: any) => {
                        const xRequestID = row.value
                        const xRequestIdPopupTrigger = <span className={"highlight"} onClick={() => {
                            navigator.clipboard.writeText(xRequestID)
                        }}>{xRequestID}</span>
                        return renderObjectAttributePopup(xRequestIdPopupTrigger, "xRequest-ID: ", xRequestID)
                    },
                    aggregate: (leafValues: any) => leafValues,
                    Aggregated: (cellInfo: CellInfo) => {
                        const distinctValues: any = [...new Map(cellInfo.subRows?.map(item =>
                            [item.xRequestId, item])).values()]
                        return distinctValues.map((value: any, index: number) => {
                            const data: HistoryColumns | undefined = value._original
                            if (!data) {
                                return <></>
                            }
                            return <>
                                {index > 0 ? ", " : null} {data.xRequestId.jsx}</>
                        })
                    }
                }
            ];

            return <>

                <ReactTable
                    data={history}
                    columns={columns}
                    sorted={[
                        {
                            id: 'timeStamp',
                            desc: true
                        }
                    ]}/*
                    onSortedChange={(newSorted, column) => {
                        this.changeSort(column.sortField).then()
                    }}*/
                    defaultPageSize={this.state.count}
                    showPagination={false}
                    className="-striped -highlight"
                    loading={this.state.isLoading}
                    getTdProps={(state, rowInfo) => {
                        return {
                            onClick: () => {
                                if (rowInfo) {
                                    console.log("Opening History-Entry with id", rowInfo.row)
                                    this.showChanges(rowInfo.row._original).then()
                                }
                            }
                        };
                    }}
                    style={{cursor: "pointer"}}


                />

                {this.state.selectedHistory && <TransportOrderHistoryModal
                    isOpen={this.state.showChanges}
                    onClose={async (openRollback: boolean) => {
                        if (openRollback) {
                            this.setState({showChanges: false, showRollBackView: true})
                        } else {
                            this.setState({showChanges: false})
                        }

                    }}
                    change={this.state.selectedHistory.data.data}
                    followingConfig={this.state.origin}
                    transportOrder={this.props.transportOrder}
                    previousChange={this.findPreviousChange(this.state.selectedHistory.data.data)}
                />}
                {this.state.selectedHistory &&
                    <TransportOrderRollbackModal
                        isOpen={this.state.showRollBackView}
                        onClose={async (reload: boolean) => {
                            this.setState({showRollBackView: false})

                            if (reload) {
                                this.setState({selectedHistory: null})
                                await this.fetchHistory()
                            }

                        }}
                        change={this.findPreviousChange(this.state.selectedHistory.data.data)}
                        followingConfig={this.state.origin}
                        transportOrder={this.props.transportOrder}
                    />
                }
            </>
        }
    }

    async showChanges(row) {
        this.setState({showChanges: true, selectedHistory: row, origin: row.origin})
    }

}

export interface HistoryColumns {
    // historyEventType: ColumnData,
    groupId: ColumnData,
    timeStamp: ColumnData,
    trigger: ColumnData,
    sourceOrTarget: ColumnData,
    changeEventType: ColumnData,
    modification: ColumnData,
    patch: ColumnData,
    xRequestId: ColumnData,
    actions: ColumnData,
    data: DataWithGroupId
}

export interface ColumnData {
    value: string,
    jsx: JSX.Element
}

export type DataWithGroupId = {
    groupId: number
    /**
     * Group with
     *      -Change Event Type ( Freight Status Update)
     *      -stop uniqueID
     *      -modification (  patch.value )
     * **/
    modification: string,
    data: ChangeEvent | MessageLog,
}

export function distinct<T>(input: T[]): T[] {
    return [...new Set(input)]
}
