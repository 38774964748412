import * as React from "react";

const {ResizableBox} = require('react-resizable');

export interface ResizeBoxProps {
    width: number,
    height: number,
}

export function ResizeBox(props: React.PropsWithChildren<ResizeBoxProps>) {

    return <ResizableBox
        className="custom-box box"
        width={props.width}
        height={props.height}
        handle={<span className="custom-handle custom-handle-se"/>}
        draggableOpts={{grid: [25, 25]}}
    >
        <div style={{overflow:"auto", padding: "2px", width: "100%", height: "100%"}}>
            {props.children}
        </div>
    </ResizableBox>
}


export interface ResizeBoxHorizontalPairProps {
    leftWidthPercentage: number,
    height: number,
    leftContent: any,
    rightContent: any
}

export function ResizeBoxHorizontalPair(props: React.PropsWithChildren<ResizeBoxHorizontalPairProps>) {
    return <div
        style={{
            flexDirection: "row",
            display: "flex"
        }}
    >
        <ResizeBox
            width={window.innerWidth * props.leftWidthPercentage}
            height={props.height}
        >
            {props.leftContent}
        </ResizeBox>

        <ResizeBox
            width={window.innerWidth * (1 - props.leftWidthPercentage)}
            height={props.height}
        >
            {props.rightContent}
        </ResizeBox>
    </div>
}


export interface ResizeBoxVerticalPairProps {
    width: number,
    topHeightPercentage: number,
    topContent: any,
    bottomContent: any
}

export function ResizeBoxVerticalPair(props: React.PropsWithChildren<ResizeBoxVerticalPairProps>) {

    return <div
        style={{
            flexDirection: "column",
            display: "flex"
        }}
    >
        <ResizeBox
            width={props.width}
            height={window.innerHeight * props.topHeightPercentage}
        >
            {props.topContent}
        </ResizeBox>

        <ResizeBox
            width={props.width}
            height={window.innerHeight * (1 - props.topHeightPercentage)}
        >
            {props.bottomContent}
        </ResizeBox>
    </div>
}