import * as React from "react";
import {Button, Header, Icon, Loader, Modal, Popup, Table} from 'semantic-ui-react';
import {PatchEntry, PatchEntryDescription} from "../../../../../../generated";
import {authentication} from "../../../../../../authentication";
import {backend} from "../../../../../../xconvert-backend";

export interface PatchsetInfoModalProps {
    isOpen: boolean
    patchEntry: PatchEntry[]
    onClose: () => void
}

export interface PatchsetInfoButtonProps {
    patchEntry: PatchEntry[]
}


export class PatchsetInfoButton extends React.Component<PatchsetInfoButtonProps, any> {
    constructor(props: PatchsetInfoButtonProps) {
        super(props)
        this.state = {
            isOpen: false
        }
    }

    render() {
        return <><PatchsetInfoModal patchEntry={this.props.patchEntry} isOpen={this.state.isOpen}
                                    onClose={() => this.setState({isOpen: false})}/>
            <Popup trigger={<Icon name="table" link onClick={() => this.setState({isOpen: true})}/>}
                content='View Modifications'
                position='right center'
            />
        </>
    }
}

export interface PatchsetInfoModalState {
    patchEntryDescription: PatchEntryDescription[]
    loading: boolean
}

export class PatchsetInfoModal extends React.Component<PatchsetInfoModalProps, PatchsetInfoModalState> {

    constructor(props: PatchsetInfoModalProps) {
        super(props)
        this.state = {
            patchEntryDescription: [],
            loading: false
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    async loadData() {
        this.setState({loading: true})
        let auth = backend.withTokenAuthHeader(authentication.token)
        const result = (await backend.internalApi.describePatchset({
            patchSet: this.props.patchEntry,
            languageCode: authentication.user?.preferredLanguage,
            shouldHighlightAsHtml: true
        }, auth));
        this.setState({patchEntryDescription: result.patchEntryDescription ?? [], loading: false})
    }

    componentDidUpdate(prevProps: Readonly<PatchsetInfoModalProps>, prevState: Readonly<PatchsetInfoModalState>, snapshot?: any) {
        if (this.props.isOpen && !prevProps.isOpen) {
            this.loadData()
        }
    }

    renderLoader() {
        return <Loader active inline='centered'/>
    }

    renderChangeTable() {
        return <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{"Eigenschaft"}</Table.HeaderCell>
                    <Table.HeaderCell>{"Alter Wert"}</Table.HeaderCell>
                    <Table.HeaderCell>{"Neuer Wert"}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {
                this.state.patchEntryDescription.map((entry, idx) => {
                    return <Table.Row key={idx}>
                        <Table.Cell>{entry.verboseName}</Table.Cell>
                        <Table.Cell negative>
                            <div dangerouslySetInnerHTML={{__html: entry.oldValue ?? ""}}/>
                        </Table.Cell>
                        <Table.Cell positive>
                            <div dangerouslySetInnerHTML={{__html: entry.newValue ?? ""}}/>
                        </Table.Cell>
                    </Table.Row>
                })
            }
        </Table>
    }

    render() {
        return <Modal
            closeOnDimmerClick={false} closeIcon open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='large'
        >
            <Header icon='browser' content={"Änderungen"}/>
            <Modal.Content>

                {this.state.loading ? this.renderLoader() : this.renderChangeTable()}

            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={() => this.props.onClose()}>
                    schließen
                </Button>
            </Modal.Actions>

        </Modal>
    }
}