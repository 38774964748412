import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setConversionOutput} from "../../slices/sourceCodeSlice";
import {getFlatOutputList} from "../../slices/tableSlice";
import {getParseOptions} from "../../slices/ruleSlice";
import {v4 as uuid} from 'uuid';

export interface ConvertImmediateFormatButtonProps{

}


export function ConvertImmediateFormatButton(props: React.PropsWithChildren<ConvertImmediateFormatButtonProps>){

    const selections = useSelector(getFlatOutputList)
    // console.log("selections are ", selections)
    const options = useSelector(getParseOptions)
    const dispatch = useDispatch()
    const[isLoading, setLoading] = React.useState(false)

    const runExtraction = async (evt: React.MouseEvent)=>{
        try{
            setLoading(true)

            const url = "/converter/convertMatrix2dImmediateFormatToTransportOrder"
            let xReqId = uuid()
            const response = await fetch(url,{
                method:"POST",
                body: JSON.stringify({
                    "commands":selections,
                    "options": options
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-request-id': xReqId
                }
            })
            const responseData = await response.json()

            dispatch(setConversionOutput(JSON.stringify(responseData, null, 2)))
        }catch (e) {
            console.log(e)
        }


        setLoading(false)
    }

    if(isLoading){
        return <span>Bitte warten, wird geladen...</span>
    }

    return <button onClick={runExtraction}>
        IF 2 TO
    </button>
}