import * as React from "react";
import {Checkbox} from "semantic-ui-react";
import {TransportOrder} from "../../../../generated";
import {
    determineDotColorForTransportOrder,
    EtaDateTimeDisplay, formatFreight, formatTransport,
    formatTransportOrderStatus,
    getNextStopByEta
} from "./Formatters";
import {formatDate} from "../../../../format";
import ReactTable from "react-table";

export interface  TransportOrderTableProps {
    transportOrders: TransportOrder[],
    transportOrderAmount: number,
    selectedOrderIdsChanged: (newSelectedOrderIds: string[]) => void,
    selectedOrderChanged: (newSelectedOrder: TransportOrder) => void,
    reload: (page: number, take: number) => void,
    initialPageSize: number,
}


export interface  TransportOrderTableState {
    isLoading: boolean,
    take: number,
    page: number,
    selectedTransportOrderIds: string[],
    allSelected: boolean
    sortBy: string,
    sortDirection: "ASC" | "DESC",
    transportOrderAmount: number,
    selectedTransportOrder: TransportOrder,
    highlightRowIndex: number
}

export class TransportOrderTable extends React.Component<TransportOrderTableProps, TransportOrderTableState> {


    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            take: 20,
            page: 0,
            selectedTransportOrderIds: [],
            allSelected: false,
            selectedTransportOrder: null,
            sortBy: 'NAME',
            sortDirection: "DESC",
            transportOrderAmount: 0,
            highlightRowIndex: null,
        }
    }

    componentDidMount() {
        this.setState({
            transportOrderAmount: this.props.transportOrders.length,
            take: this.props.initialPageSize
        })
    }

    componentDidUpdate(prevProps: Readonly<TransportOrderTableProps>, prevState: Readonly<TransportOrderTableState>, snapshot?: any) {
        if(prevProps.transportOrderAmount != this.props.transportOrderAmount) {
            this.setState({
                transportOrderAmount: this.props.transportOrders.length,
                isLoading: false
            })
        }
        if(prevProps.transportOrders != this.props.transportOrders) {
            this.setState({
                isLoading: false
            })
        }
    }

    switchSelectionStateOfAllItems() {
        if(this.state.allSelected) {
            let newIds = []
            this.setState({
                allSelected: false,
                selectedTransportOrderIds: newIds
            })
            this.props.selectedOrderIdsChanged(newIds)
        } else {
            let newIds = this.props.transportOrders.map(to => to._id)
            this.setState({
                allSelected: true,
                selectedTransportOrderIds: newIds
            })
            this.props.selectedOrderIdsChanged(newIds)
        }
    }

    switchSelectionStateOfItem(id: string) {
        this.setState({isLoading: true})

        let array = this.state.selectedTransportOrderIds;
        let index = array.indexOf(id);
        if (index == -1) {
            // id not found, adding Id
            let newArray = array.concat(id)
            this.setState({selectedTransportOrderIds: newArray})
            this.props.selectedOrderIdsChanged(newArray)
            console.log("Id added")
        } else {
            // Id found, removing it
            array.splice(index, 1);
            this.setState({selectedTransportOrderIds: array})
            this.props.selectedOrderIdsChanged(array)
            console.log("Id removed")
        }

        if(this.state.selectedTransportOrderIds.length == this.props.transportOrders.length) {
            this.setState({allSelected: true})
        } else {
            this.setState({allSelected: false})
        }

        this.setState({isLoading: false})
    }

    render() {
        const columns = [

            {
                id: 'select',
                Header:  <Checkbox
                        checked={this.state.allSelected}
                        onChange={() => this.switchSelectionStateOfAllItems()}
                    />,
                width: 30,
                accessor: (d: any) => {
                    return <Checkbox
                        checked={this.state.selectedTransportOrderIds.some(id => id === d._id)}
                        onChange={() => this.switchSelectionStateOfItem(d._id)}
                    />
                },
            }, {
                id: '_id',
                Header: 'Id',
                accessor: (d: TransportOrder) => d._id
            }, {
                id: 'status',
                Header: 'Status',
                accessor: (d: TransportOrder) => formatTransportOrderStatus(d.status),
            },
            {
                id: 'externalTransportOrderId',
                Header: 'ExternalOrderNo',
                accessor: (d: TransportOrder) => d.externalTransportOrderId,
                Cell: (props: any) => <span className='number'>{props.value}</span> // Custom cell components!
            }, {
                id: 'created', // Required because our accessor is not a string
                Header: 'Created',
                width: 140,
                accessor: (d: TransportOrder) => formatDate(d.created) // Custom value accessors!
            },
            {
                id: 'planStart',
                Header: 'PlanStart',
                width: 140,
                accessor: (d: TransportOrder) => formatDate(d.planStart),
            },
            {
                id: 'planEnd',
                Header: 'PlanEnd',
                width: 140,
                accessor: (d: TransportOrder) => formatDate(d.planEnd),
            },
            {
                id: 'targetCompanyName',
                Header: 'Contractor',
                accessor: (d: TransportOrder) => d.targetCompanyName
            },
            {
                id: 'is_subchartered',
                Header: "Via Sub?",
                sortable: false,
                accessor: (d: TransportOrder) => d.linkInfo != null && d.linkInfo.targetTransportOrderId != null ? "☑" : ""
            },
            {
                id: 'observer',
                sortable: false,
                Header: 'Observer',
                accessor: (d: TransportOrder) => d.observer && d.observer.length > 0 ? "☑ " + d.observer.length : ""
            },
            {
                id: 'original_source_company',
                sortable: false,
                Header: 'Consignor',
                accessor: (d: TransportOrder) => d.linkInfo != null ? d.linkInfo.sourceCompanyName : ""
            },
            {
                id: 'companyName',
                Header: 'Client',
                accessor: (d: TransportOrder) => d.companyName
            },
            {
                id: 'vehicle',
                Header: 'Vehicle',
                accessor: (d: TransportOrder) => {
                    if (d.assignedVehicle != null && d.assignedVehicle.numberPlate != null) {
                        return d.assignedVehicle.numberPlate
                    } else if (d.assignedDevice != null && d.assignedDevice.verboseName != null) {
                        return d.assignedDevice.verboseName
                    }
                    return ""
                }
            },
            {
                id: 'driver',
                Header: 'Driver',
                accessor: (d: TransportOrder) => {
                    if (d.assignedDriver != null && (d.assignedDriver.firstName != null || d.assignedDriver.firstName != null)) {
                        return `${d.assignedDriver.firstName} ${d.assignedDriver.lastName}`
                    } else if (d.assignedDriver != null && d.assignedDriver.externalDriverId != null) {
                        return d.assignedDriver.externalDriverId
                    }
                    return ""
                }
            },
            {
                id: 'trailer',
                Header: 'Trailer',
                accessor: (d: TransportOrder) => {
                    if (d.assignedTrailer?.numberPlate != null) {
                        return d.assignedTrailer.numberPlate
                    } else if (d.assignedTrailer?.externalVehicleId != null) {
                        return d.assignedTrailer.externalVehicleId
                    }
                    return ""
                }
            },
            {
                id: 'eta',
                Header: 'NextETA',
                sortable: false,
                width: 135,
                accessor: (d: TransportOrder) => {
                    let nextEta = getNextStopByEta(d)

                    if (nextEta?.eta) {
                        return <EtaDateTimeDisplay eta={nextEta.eta}/>
                    }
                    return ""
                },
                Cell: (row: any) => (
                    <span>
                    <span style={{
                        color: determineDotColorForTransportOrder(row.original),
                        transition: 'all .3s ease'
                    }}>&#x25cf;</span>
                        {row.value}
                </span>
                )
            },
            {
                id: 'from',
                Header: 'Stops',
                sortable: false,
                accessor: (d: TransportOrder) => d.transports != null && formatTransport(d.transports)
            },
            {
                id: 'freight',
                sortable: false,
                Header: 'Freight',
                accessor: (d: TransportOrder) => d.freight != null && formatFreight(d.freight)
            },
            {
                id: 'assignedDeviceMail',
                sortable: false,
                visible: false,
                Header: 'AssignedDeviceMail',
                accessor: (d: TransportOrder) => d.assignedDevice?.emailAddress
            },
            {
                id: 'assignedDevicePhone',
                sortable: false,
                visible: false,
                Header: 'AssignedDevicePhone',
                accessor: (d: TransportOrder) => d.assignedDevice?.phoneNr
            }
        ]


        return <ReactTable
            className="transportOrderTable -striped -highlight"
            data={this.props.transportOrders}
            pages={Math.ceil(this.props.transportOrderAmount / this.state.take)}
            page={this.state.page}
            manual
            columns={columns}
            onSortedChange={(newSorted, column, shiftKey) => {
                this.changeSort(column.sortField)
            }}
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: () => {
                        if (rowInfo) {
                            console.log("Opening TransportOrder with id", rowInfo.row)
                            this.setState({selectedTransportOrder: rowInfo.row._original, highlightRowIndex: rowInfo.index})
                            this.props.selectedOrderChanged(rowInfo.row._original)
                        }
                    },
                    style: {
                        background: rowInfo?.index === this.state.highlightRowIndex ? '#00afec' : 'white',
                        color: rowInfo?.index === this.state.highlightRowIndex ? 'white' : 'black'
                    }
                };
            }}
            defaultPageSize={this.props.initialPageSize}
            pageSizeOptions={[5, 25, 50, 100, 250, 500, 1000, 2500, 5000]}
            loading={this.state.isLoading}
            style={{cursor: "pointer"}}
            onPageChange={(pageIndex) => this.changePage(pageIndex)}
            onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}
        />
    }


    changePage(pageIndex: number) {
        console.log("changePage: pageIndex : ", pageIndex)
        this.setState({page: pageIndex, isLoading: true})
        this.props.reload(pageIndex, this.state.take)

    }

    changePageSize(newSize: number) {
        console.log("changePageSize newSize : ", newSize)
        this.setState({take: newSize, isLoading: true})
        this.props.reload(this.state.page, newSize)
    }

    changeSort(sortBy: 'ID' | 'NAME' | "NONE") {

        if (sortBy == 'NONE') {
            return
        }
        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({sortDirection: direction})
        } else {
            //change sortBy and reset direction
            this.setState({sortBy: sortBy, sortDirection: "ASC"})
        }

        //refresh
    }

}
