
import {Feed, Icon, Popup} from "semantic-ui-react";
import * as React from "react";
import {
    AssignedDevice,
    AssignedOrderBox,
    Attachment,
    AttachmentStatus,
    ChangeEvent,
    Company,
    Driver,
    Freight,
    FreightStatus,
    MessageLog,
    Origin,
    PatchEntry,
    StopAction,
    StopLocation,
    Transport,
    TransportOrder,
    TransportOrderChange,
    TransportOrderChangeEvent,
    TransportOrderStatus,
    Vehicle
} from "../../../../../../generated";
import * as moment from "moment";
import TypeEnum = TransportOrderChangeEvent.TypeEnum;
import KindEnum = StopAction.KindEnum;
import {
    formatFreightStatus,
    formatFreightStatusEnum,
    formatStopLocationStatus,
    formatStopLocationStatusEnum, formatTransportOrderTelematicStatus,
    formatVehicleTypeEnum,
    getFreightStatusEnumFromString, getStopLocationStatusEnumFromString,
    getTransportOrderStatusEnumFromString,
    prettyRelativeDate
} from "../../../../../../format";
import {formatTransportOrderStatus, formatTransportOrderStatusEnum} from "../../Formatters";
import {v4 as uuid} from "uuid";
import {IfBox} from "../../../../../style/if";
import {authentication} from "../../../../../../authentication";
import {backend} from "../../../../../../xconvert-backend";
import {openInNewTab} from "../../../../../../utils";
import {PatchsetInfoButton} from "./PatchsetInfoModal";
import {ShowAdminModifiedObjectInfoButton} from "./AdminModifiedObjectInfoModal";

export interface ColumnData {
    value: string,
    jsx: JSX.Element
}

export interface HistoryColumns {
    // historyEventType: ColumnData,
    groupId: ColumnData,
    timeStamp: ColumnData,
    trigger: ColumnData,
    sourceOrTarget: ColumnData,
    changeEventType: ColumnData,
    modification: ColumnData,
    patch: ColumnData,
    xRequestId: ColumnData,
    actions: ColumnData,
    data: DataWithGroupId
}

export type DataWithGroupId = {
    groupId: number
    /**
     * Group with
     *      -Change Event Type ( Freight Status Update)
     *      -stop uniqueID
     *      -modification (  patch.value )
     * **/
    modification: string,
    data: ChangeEvent | MessageLog,
}
export type UpdateEventModifyObj = {
    level?: "TransportOrder" | "Transport" | "StopLocation" | "Freight",
    operation?: "add" | "replace" | "remove",
    columnDataToReturn?: ColumnData,
    count?: number,
}
export type UniqueLinkedInfo = {
    transportId?: string,
    infoFromPatch: {
        transportOrderId: string,
        transportId: string
    }
}
export type UniqueStopLocationStatusPatch = {
    uniqueStopLocationId?: string,
    patch: PatchEntry
}

export type ModifiedObj = {
    obj: TransportOrder | Transport | StopLocation | StopAction | Freight | Attachment | Vehicle | Driver | AssignedDevice | AssignedOrderBox | undefined,
    level: "TransportOrder" | "Transport" | "StopLocation" | "Freight" | "Attachment" | undefined,
    patches: PatchEntry[] | undefined,
    parentObjs: { transport?: Transport, stop?: StopLocation, freight?: Freight }
}

export function getColumnData(data?: (ChangeEvent | MessageLog)[], showRaw: boolean = true): HistoryColumns[] {
    const eventsWithGroups: DataWithGroupId[] = cleanChangeEventsData(data, showRaw)

    return eventsWithGroups.map(groupedData => {
        const columns: HistoryColumns = {
            // historyEventType: {value: "", jsx: <></>},
            groupId: {value: groupedData.groupId + "_" + groupedData.modification, jsx: <></>},
            timeStamp: {value: "", jsx: <></>},
            trigger: {value: "", jsx: <></>},
            sourceOrTarget: {value: "", jsx: <></>},
            changeEventType: {value: "", jsx: <></>},
            modification: {value: "", jsx: <></>},
            patch: {value: "", jsx: <></>},
            xRequestId: {value: "", jsx: <></>},
            actions: {value: "", jsx: <></>},
            data: groupedData
        }
        const changeEvent: ChangeEvent = groupedData.data
        const messageLog: MessageLog = groupedData.data

        if (!!messageLog.sendTime || !!messageLog.sendCause) {
            /** timeStamp Column **/
            columns.timeStamp.value = messageLog.sendTime? (new Date(messageLog.sendTime))?.getTime().toString() : ""
            columns.timeStamp.jsx = <>{prettyRelativeDate(messageLog.sendTime)}</>

            /** changeEventType Column **/
            if (messageLog.sms) {
                columns.changeEventType.value = "Sms gesendet"
                columns.modification.value = messageLog.sms.phoneNr ?? ""
            } else if (messageLog.mail) {
                columns.changeEventType.value = "E-Mail gesendet"
                columns.modification.value = messageLog.mail.receiverAddresses?.join(", ") ?? ""
            }
            columns.changeEventType.jsx = <>{columns.changeEventType.value}</>
            columns.modification.jsx = <>{columns.modification.value}</>
            /** patch Column **/
            /** patch Column **/
            columns.patch.value = JSON.stringify(messageLog)
            columns.patch.jsx = <>{columns.patch.value}</>
            /** actions Column **/
            // columns.actions.jsx = renderMessageLogActionButtons(messageLog)
        } else if (!!changeEvent.transportOrderEvent || !!changeEvent.creationTimestamp) {
            const transportOrderChangeEvent: TransportOrderChangeEvent | undefined = changeEvent?.transportOrderEvent
            /** timeStamp Column **/
            if (changeEvent.creationTimestamp) {
                columns.timeStamp.value = changeEvent.creationTimestamp? (new Date(changeEvent.creationTimestamp))?.getTime().toString() : ""
                columns.timeStamp.jsx = <>{prettyRelativeDate(changeEvent.creationTimestamp)}</>

                if (changeEvent.timestamp) {
                    let ms = moment(changeEvent.timestamp).diff(moment(changeEvent.creationTimestamp));
                    const timeDifferenceInMinutes = moment.duration(ms).asMinutes()
                    if (timeDifferenceInMinutes > 1) {
                        columns.timeStamp.jsx = <>
                            {prettyRelativeDate(changeEvent.creationTimestamp)}
                            <Popup flowing
                                   trigger={<Icon className={"highlight"} name={"exclamation triangle"}
                                                  color={"yellow"}/>}
                                   content={<>
                                       <span>Creation timestamp: {prettyRelativeDate(changeEvent.creationTimestamp)}</span>
                                       <br/>
                                       <span>Timestamp: {prettyRelativeDate(changeEvent.timestamp)}</span>
                                   </>}/>
                        </>
                    }
                }
            }

            /** trigger Column **/
            if (transportOrderChangeEvent?.origin) {
                columns.trigger.value = renderChangeEventOrigin(transportOrderChangeEvent)
                columns.trigger.jsx = <Feed.User>{columns.trigger.value}</Feed.User>
            }

            /** sourceOrTarget Column **/
            if (transportOrderChangeEvent?.transportOrder) {
                const isSource: boolean = changeEvent?.eventSourceCompanyId == transportOrderChangeEvent?.transportOrder?.companyId
                columns.sourceOrTarget.value = isSource ? "Source Company" : "Target Company"
                columns.sourceOrTarget.jsx = <Popup content={columns.sourceOrTarget.value}
                                                    trigger={<Icon className={"highlight"}
                                                                   name={isSource ? "warehouse" : "road"}
                                                                   color={isSource ? "teal" : "black"}/>}/>
            }

            /** changeEventType Column **/
            if (transportOrderChangeEvent?.transportOrder) {
                columns.changeEventType = renderChangeEventCompactDescription(changeEvent)
            }

            /** modifications Column **/
            if (transportOrderChangeEvent?.transportOrder) {
                columns.modification = renderModification(changeEvent, showRaw)
            }

            /** patch Column **/
            changeEvent?.transportOrderEvent?.patchSet?.forEach(p => {
                columns.patch.value += p.path ? (p.path + "^") : ""
            })

            /** xRequestID Column **/
            columns.xRequestId.value = changeEvent.eventSourceRequestId?.replace("[X-Request-ID ", "").slice(0, -1) ?? ""
            const xRequestIdPopupTrigger = <span className={"highlight"} onClick={() => {
                navigator.clipboard.writeText(columns.xRequestId.value)
            }}>{columns.xRequestId.value}</span>
            columns.xRequestId.jsx = renderObjectAttributePopup(xRequestIdPopupTrigger, "xRequest-ID: ", columns.xRequestId.value)


            /** actions Column **/
            columns.actions.jsx = renderChangeEventActionButtons(changeEvent)
        }
        return columns
    })
}

export function renderChangeEventCompactDescription(c: ChangeEvent): ColumnData {
    switch (c.transportOrderEvent?.type) {
        case TypeEnum.CREATE:
            return {
                value: "Der Auftrag wurde"
                    + " " + formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.CREATED),
                jsx: <>Der Auftrag wurde
                    <strong>{" " + formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.CREATED)}</strong>
                </>
            }
        case TypeEnum.UPDATE:
            return {
                value: "Der Auftrag wurde bearbeitet",
                jsx: <>Der Auftrag wurde
                    bearbeitet</>
            }
        case TypeEnum.STORNO:
            return {
                value: "Der Auftrag wurde"
                    + " " + formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.CANCELED),
                jsx: <>
                    Der Auftrag wurde
                    <strong>{" " + formatTransportOrderStatusEnum(TransportOrderStatus.StatusCodeEnum.CANCELED)}</strong>
                </>
            }
        case TypeEnum.ATTACHMENTADD:
            return renderAttachmentAddDescription(c.transportOrderEvent)

        case TypeEnum.ATTACHMENTDELETE:
            return renderAttachmentDeleteDescription(c.transportOrderEvent)

        case TypeEnum.ATTACHMENTMODIFY:
            return renderAttachmentModifyDescription(c.transportOrderEvent)

        case TypeEnum.ASSIGNCOMPANY:
            return {
                value: "Firma zugewiesen: ",
                jsx: <>Firma zugewiesen:</>
            }

        case TypeEnum.ASSIGNVEHICLE:
            return {
                value: "Fahrzeug zugewiesen: ",
                jsx: <>Fahrzeug zugewiesen:</>
            }

        case TypeEnum.ASSIGNDEVICE:
            return {
                value: "Gerät zugewiesen: ",
                jsx: <>Gerät zugewiesen:</>
            }

        case TypeEnum.ASSIGNTRAILER:
            return {
                value: "Auflieger zugewiesen: ",
                jsx: <>Auflieger zugewiesen:</>
            }

        case TypeEnum.ASSIGNORDERBOX:
            return {
                value: "OrderBox zugewiesen: ",
                jsx: <>OrderBox zugewiesen:</>
            }

        case TypeEnum.STATUSUPDATETRANSPORTORDER:
            return {
                value: "Auftragsstatus aktualisiert ",
                jsx: <>Auftragsstatus
                    aktualisiert</>
            }

        case TypeEnum.STATUSUPDATESTOPLOCATION:
            return renderUpdateStopLocationStatusDescription(c.transportOrderEvent)

        case TypeEnum.STATUSUPDATESTOPACTION:
            return renderUpdateStopActionStatusDescription(c.transportOrderEvent)

        case TypeEnum.STATUSUPDATEFREIGHT:
            return renderUpdateFreightStatusDescription(c.transportOrderEvent)

        case TypeEnum.STATUSUPDATEATTACHMENT:
            return {value: "", jsx: <></>}

        case TypeEnum.LINKEVENT:
            return {
                value: "Tour war verbunden",
                jsx: <>Tour war verbunden</>
            }
        default:
            return {value: "", jsx: <></>}

    }
}

export function renderModification(c: ChangeEvent, showRaw: boolean = true): ColumnData {
    switch (c.transportOrderEvent?.type) {
        case TypeEnum.CREATE:
            return {value: "", jsx: <></>}
        //remaining todo
        case TypeEnum.UPDATE:
            return renderOrderUpdate(c, showRaw)

        case TypeEnum.STORNO:
            return {value: "", jsx: <></>}

        case TypeEnum.ATTACHMENTADD:
            return renderAttachmentAdd(c.transportOrderEvent)

        case TypeEnum.ATTACHMENTDELETE:
            return renderAttachmentDelete(c.transportOrderEvent)

        case TypeEnum.ATTACHMENTMODIFY:
            return renderAttachmentModify(c.transportOrderEvent)

        case TypeEnum.ASSIGNCOMPANY:
            return renderCompany(c.transportOrderEvent)

        case TypeEnum.ASSIGNVEHICLE:
            return renderVehicleAssignment(c.transportOrderEvent)

        case TypeEnum.ASSIGNTRAILER:
            return renderVehicleAssignment(c.transportOrderEvent)

        case TypeEnum.ASSIGNDEVICE:
            return renderDeviceAssignment(c.transportOrderEvent)

        case TypeEnum.ASSIGNORDERBOX:
            return renderOrderBoxAssignment(c.transportOrderEvent)

        case TypeEnum.STATUSUPDATETRANSPORTORDER:
            return renderTransportOrderStatus(c)

        case TypeEnum.STATUSUPDATESTOPLOCATION:
            return renderUpdateStopLocationStatus(c)

        // case TypeEnum.STATUSUPDATESTOPACTION:
        //     return renderUpdateStopActionStatus(c.transportOrderEvent)

        case TypeEnum.STATUSUPDATEFREIGHT:
            return renderUpdateFreightStatus(c.transportOrderEvent)

        case TypeEnum.STATUSUPDATEATTACHMENT:
            return {value: "", jsx: <></>}

        case TypeEnum.LINKEVENT:
            return renderLinkTransportOrder(c.transportOrderEvent)

        default:
            return {value: "", jsx: <></>}
    }
}

export function renderTimeChangeWarning(tc?: TransportOrderChangeEvent) {
    let changedPatches = tc?.patchSet?.map(value => value.path)
    let time_changes_regexp = /(planBegin|planEnd|planStart|planStop|planArrive|planDepart)/i;
    let found = changedPatches?.map(value => value?.match(time_changes_regexp)).filter(value => value != undefined)
    if (found && found.length > 0) {
        return <>
            <Popup
                content={"Planzeiten wurden geändert!"}
                trigger={<Icon className={"highlight"} name="clock outline" style={{color: "#891919"}}/>}/>
        </>
    }
}

export function renderChangeEventOrigin(tce?: TransportOrderChangeEvent) {
    let user = ""
    if (tce?.origin) {
        // console.log("origin:", origin)
        if (tce?.origin.source == Origin.SourceEnum.LOGENIOSAPIGATEWAY) {
            user = "API Gateway"
            tce?.patchSet?.forEach(p => {
                if (p.value?.includes("GeoStatus:")) {
                    user = "Geo Status"
                }
                // if (tce?.origin?.systemName?.includes("GeoStatus:") && tce?.origin?.userId == "5b99103d2c651b18b058c66a") {
                //     user = "Geo Status"
                // }
            })
        } else if (tce?.origin.source == Origin.SourceEnum.API && tce?.origin.userName && tce?.origin.userName.indexOf("API_GATEWAY") != -1) {
            user = "API Gateway"
        } else if (tce?.origin.source == Origin.SourceEnum.LOGENIOSBACKEND && tce?.origin.userName && tce?.origin.userName.indexOf("API_GATEWAY") != -1) {
            user = "API Gateway"
        } else if (tce?.origin.externalUserId) {
            user = tce?.origin.externalUserId
        } else if (tce?.origin.userName && tce?.origin.userName.includes("CONVERTER")) {
            user = "Converter"
        } else if (tce?.origin.userId && tce?.origin.userId == "5b99103d2c651b18b058c669") {
            user = "Converter"
        } else if (tce?.origin?.userId == "5e410d0cadfdd828c479abb2") {
            user = "Notification Worker"
        } else if (tce?.origin.userName) {
            user = `${tce?.origin.userName}`
        } else {
            user = tce?.origin.source ?? ""
        }
    }
    return user
}

export function renderObjectAttributePopup(trigger?: JSX.Element, attributeName?: string | JSX.Element, attribute?: string, showCopyText: boolean = true) {
    return <Popup flowing trigger={<span className={"textHighlight"}>{trigger}</span>}
                  content={<Feed>
                      <Feed.Event key={uuid()}>
                          <Feed.Content>
                              <Feed.Extra>{attributeName}<strong>{attribute}</strong></Feed.Extra>
                              <IfBox shouldShow={showCopyText}>
                                  <Feed.User>"Click to copy!"</Feed.User>
                              </IfBox>
                          </Feed.Content>
                      </Feed.Event>
                  </Feed>}
    />
}

export function cleanChangeEventsData(data?: (ChangeEvent | MessageLog)[], showRaw: boolean = false): DataWithGroupId[] {
    const uniqueStopLocationStatusPatch: UniqueStopLocationStatusPatch[] = []
    const changeEventsWithGroupId: DataWithGroupId[] = []
    let groupCount: number = 0;
    // let lastGroupID: string = ""
    data?.forEach((data, index) => {

        let objWithId: DataWithGroupId = {
            groupId: groupCount,
            modification: "",
            data: data
        }
        let changeEvent: ChangeEvent = data
        let messageLog: MessageLog = data
        if (!!messageLog.sendTime || !!messageLog.sendCause) {
            objWithId.groupId = ++groupCount
            objWithId.modification = "MessageLog"
            changeEventsWithGroupId.push(objWithId)
        } else if (!!changeEvent.transportOrderEvent || !!changeEvent.creationTimestamp) {
            switch (changeEvent?.transportOrderEvent?.type) {

                /** excluding following statuses from the rows
                 *      -STATUSUPDATESTOPACTION
                 * **/
                case TypeEnum.STATUSUPDATESTOPACTION: {
                    if (showRaw) {
                        changeEventsWithGroupId.push(objWithId)
                    }
                    break;
                }
                case TypeEnum.STATUSUPDATEFREIGHT: {
                    // console.log("STATUSUPDATEFREIGHT", changeEvent)
                    let shouldReturnChangeEvent: boolean = false
                    changeEvent?.transportOrderEvent?.patchSet?.forEach(p => {
                        if (!shouldReturnChangeEvent && p.path
                            && isPatchRelevantForHuman(p, changeEvent?.transportOrderEvent?.type, showRaw)
                            && changeEvent.transportOrderEvent?.transportOrder?.freight) {
                            // console.log("%cCE type","color: red" ,changeEvent?.transportOrderEvent?.type)
                            // console.log("%cp.path", "color: orange", p.path)
                            let patchedFreight: Freight | undefined
                            if (p.path.includes("$.freight[")) {
                                const patchFreightIndex: number = +p.path.split("$.freight[")[1].split("]")[0]
                                patchedFreight = changeEvent.transportOrderEvent?.transportOrder?.freight[patchFreightIndex]
                                // console.log("STATUSUPDATEFREIGHT", changeEvent)
                                // console.log("%cp.path", "color: orange", p.path)
                                //
                                // console.log("p value is ", p)
                            }

                            // console.log("%c patchedFreight", "color: orange", patchedFreight)
                            let patchedStop: StopLocation | undefined

                            changeEvent.transportOrderEvent.transportOrder?.transports?.forEach(t => {
                                if (patchedStop) {
                                    return
                                }
                                t.stops?.forEach(s => {
                                    if (patchedStop) {
                                        return
                                    }
                                    s.actions?.forEach(a => {
                                        if (patchedStop) {
                                            return
                                        }
                                        if (patchedFreight?.freightId && a.freightIds?.includes(patchedFreight?.freightId)) {
                                            if ((patchedFreight.status?.statusCode == FreightStatus.StatusCodeEnum.LOADING || patchedFreight.status?.statusCode == FreightStatus.StatusCodeEnum.LOADED) && a.kind == KindEnum.LOAD) {
                                                patchedStop = s
                                            }
                                            if ((patchedFreight.status?.statusCode == FreightStatus.StatusCodeEnum.UNLOADING || patchedFreight.status?.statusCode == FreightStatus.StatusCodeEnum.DELIVERED) && a.kind == KindEnum.UNLOAD) {
                                                patchedStop = s
                                            }
                                        }
                                    })
                                })
                            })
                            // objWithId.modification = patchedStop?.uniqueLocationId + "_" + patchedStop?.status?.statusCode + "_freight"
                            objWithId.modification = patchedStop?.uniqueLocationId + "_" + patchedStop?.status?.statusCode
                            const findSameModification: DataWithGroupId | undefined = changeEventsWithGroupId.find(c => c.modification == objWithId.modification)
                            if (findSameModification) {
                                objWithId.groupId = findSameModification.groupId
                            } else {
                                objWithId.groupId = ++groupCount
                            }


                            shouldReturnChangeEvent = true;
                        }
                    })
                    if (shouldReturnChangeEvent) {
                        changeEventsWithGroupId.push(objWithId)
                    }
                    break;
                }
                case TypeEnum.STATUSUPDATESTOPLOCATION: {
                    let shouldReturnChangeEvent: boolean = false
                    changeEvent?.transportOrderEvent?.patchSet?.filter(p => {
                        if (!shouldReturnChangeEvent) {
                            if (p.path && isPatchRelevantForHuman(p, changeEvent?.transportOrderEvent?.type, showRaw)) {
                                if (changeEvent.transportOrderEvent && changeEvent.transportOrderEvent.transportOrder?.transports) {
                                    // console.log("%cCE type","color: red" ,changeEvent?.transportOrderEvent?.type)
                                    // console.log("%cp.path","color: orange" ,p.path)
                                    const patchTransportIndex: number | null = p.path && p.path.includes("$.transports[")
                                        ? +p.path.replace("$.transports[", "").split("]")[0]
                                        : null;

                                    const patchStopIndex: number | null = p.path && p.path.includes(".stops[")
                                        ? +p.path.split(".stops[")[1].split("]")[0]
                                        : null;

                                    // Use patchTransportIndex and patchStopIndex only if they are not null.
                                    let patchedTransport: Transport | null = null;
                                    let patchedStop: StopLocation | null = null;

                                    if (patchTransportIndex !== null) {
                                        patchedTransport = changeEvent.transportOrderEvent.transportOrder?.transports[patchTransportIndex];
                                    }

                                    if (patchedTransport && patchedTransport.stops && patchStopIndex !== null) {
                                        patchedStop = patchedTransport.stops[patchStopIndex];
                                    }

                                    if (patchedStop && patchedStop.uniqueLocationId) {
                                        let found: UniqueStopLocationStatusPatch | undefined = uniqueStopLocationStatusPatch.find(stopPatch => {
                                            const foundStopLocationId: boolean = stopPatch.uniqueStopLocationId == patchedStop!.uniqueLocationId;
                                            const foundPatchValue: boolean = stopPatch.patch.value == p.value;
                                            const foundPatchOldValue: boolean = stopPatch.patch.oldValue == p.oldValue;
                                            if (foundStopLocationId && foundPatchValue && foundPatchOldValue) {
                                                return stopPatch;
                                            }
                                        });
                                        if (!found) {
                                            uniqueStopLocationStatusPatch.push({
                                                uniqueStopLocationId: patchedStop.uniqueLocationId,
                                                patch: p
                                            });
                                            objWithId.modification = patchedStop?.uniqueLocationId + "_" + patchedStop?.status?.statusCode;

                                            shouldReturnChangeEvent = true;
                                        }
                                    }

                                }
                            }
                        }
                    })
                    if (shouldReturnChangeEvent) {
                        const findSameModificationIndex: number = changeEventsWithGroupId.findIndex(c => c.modification == objWithId.modification)
                        if (findSameModificationIndex > -1) {
                            const findSameModification: DataWithGroupId = changeEventsWithGroupId[findSameModificationIndex]
                            if (findSameModification) {
                                objWithId.groupId = findSameModification.groupId
                                changeEventsWithGroupId.splice(findSameModificationIndex, 0, objWithId)
                            }
                        } else {
                            objWithId.groupId = ++groupCount
                            changeEventsWithGroupId.push(objWithId)

                        }
                    }
                    break;
                }

                default: {
                    let shouldReturnChangeEvent: boolean = false
                    changeEvent?.transportOrderEvent?.patchSet?.forEach(p => {
                        if (!shouldReturnChangeEvent && p.path
                            && isPatchRelevantForHuman(p, changeEvent?.transportOrderEvent?.type, showRaw)) {
                            objWithId.groupId = ++groupCount
                            shouldReturnChangeEvent = true;
                        }
                    })
                    if (shouldReturnChangeEvent) {
                        changeEventsWithGroupId.push(objWithId)
                    }
                }
            }
        }
    })

    return changeEventsWithGroupId
}

export function isPatchRelevantForHuman(patch: PatchEntry, type?: TypeEnum, showRaw: boolean = false): boolean {
    if (showRaw) {
        return true
    }
    if (patch?.oldValue === "null" && patch?.value === "") {
        return false
    }

    if (type == TypeEnum.STATUSUPDATESTOPLOCATION) {
        /** STATUSUPDATESTOPLOCATION type change events with statusCode**/
        const patchShouldInclude: string[] = [
            //** stop level Status patches to keep **/
            ".stops[", ".status.statusCode"
        ]
        const patchesToExclude: string[] = [
            //** stop level Status patches to ignore **/
            "$.freight[", ".actions[",
        ]
        return stringIncludes(patchShouldInclude, patch?.path, false) && !stringIncludes(patchesToExclude, patch?.path, true);
    }
    if (type == TypeEnum.STATUSUPDATEFREIGHT) {
        const patchesToExclude: string[] = [
            //** General patches to ignore **/
            "-lastVehiclePositions", ".status.location", ".status.origin",
            ".status.additionalData", ".status.loadingTimestamp",
            ".status.timestamp", ".status.deliveredTimestamp",
            ".status.sender", ".status.loadedTimestamp",
            ".status.telematicState"
        ]
        return !stringIncludes(patchesToExclude, patch?.path, true);

    }
    if (type == TypeEnum.ASSIGNCOMPANY) {
        const patchesToExclude: string[] = [
            //** General patches to ignore **/
            "$.-allowedViewers[",
        ]
        return !stringIncludes(patchesToExclude, patch?.path, true);

    }
    if (type == TypeEnum.UPDATE) {
        const patchesToExclude: string[] = [
            //** General patches to ignore **/
            "$.modified", ".-uniqueObjectId", ".attachments[", ".unit", ".onlyAtFreightIds[",
            "-converterInfo", "$.-allowedViewers[", "$.statusValidationResult", ".status.origin",
            ".coordinates",

            //** Transport level patches to ignore **/
            ".planEnd", ".planStart", ".additionalData[",

            //** stop level patches to ignore **/
            ".uniqueLocationId",

            //** stop action level patches to ignore **/
            ".freightIds[",
        ]

        let isPatchRelevant: boolean = !stringIncludes(patchesToExclude, patch?.path, true)
        let isStopLevelPatchRelevant: boolean = !stringIncludes([".stops[", ".status"], patch?.path, false)
        let isFreightLevelPatchRelevant: boolean = true
        if (patch?.path?.includes("$.freight[")) {
            isFreightLevelPatchRelevant = !stringIncludes([".additionalData[", ".-resolvedMapping", ".status"], patch?.path, true)
        }
        return isPatchRelevant && isStopLevelPatchRelevant && isFreightLevelPatchRelevant
    }
    return true
}

function renderAttachmentModifyDescription(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    // const attachment: Attachment | undefined = modifiedObj.obj as Attachment
    let parent: TransportOrder | Transport | StopLocation | Freight | undefined = undefined
    let parentId: string | undefined = undefined
    if (modifiedObj.parentObjs && modifiedObj.level) {
        switch (modifiedObj.level) {
            case "TransportOrder":
                parent = ce.transportOrder
                parentId = ce.transportOrder?._id
                break;
            case "Transport":
                parent = modifiedObj.parentObjs.transport
                parentId = (parent as Transport).transportId
                break;
            case "StopLocation":
                parent = modifiedObj.parentObjs.stop
                parentId = (parent as StopLocation).stopId
                break;
            case "Freight":
                parent = modifiedObj.parentObjs.freight
                parentId = (parent as Freight).freightId
                break;
        }
    }
    // console.log("parent : ", parent)

    const attachmentAddPopupTrigger = <strong onClick={() => {
        if (parentId) {
            navigator.clipboard.writeText(parentId)
        }
    }}>{modifiedObj.level}</strong>
    return {
        value: "Anhang geändert" + " " + modifiedObj.level,
        jsx: <>
            Anhang geändert :
            {parent ?
                renderObjectAttributePopup(attachmentAddPopupTrigger, modifiedObj.level + "-ID: ", parentId)
                : <strong>{" " + modifiedObj.level}</strong>}
        </>
    }
}

function renderUpdateFreightStatusDescription(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    if (modifiedObj.obj) {
        const freight: Freight = modifiedObj.obj as Freight
        const freightPopupTrigger = <strong onClick={() => {
            if (freight.freightId) {
                navigator.clipboard.writeText(freight.freightId)
            }
        }}>{" " + (freight.verboseName ? freight.verboseName : (freight.freightId ? freight.freightId : null))}</strong>

        return {
            value: "Aktualisierung des Frachtstatus"
                + " " + (freight.verboseName ? freight.verboseName : (freight.freightId ? freight.freightId : "")),
            jsx: <>
                Aktualisierung des
                    Frachtstatus:
                {renderObjectAttributePopup(freightPopupTrigger, modifiedObj.level + "-ID: ", freight.freightId)}
            </>
        }
    }
    return {value: "", jsx: <></>}
}

function renderUpdateStopLocationStatusDescription(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    if (modifiedObj.obj) {
        const stop: StopLocation = modifiedObj.obj as StopLocation
        if (!stop.stopId) {
            return {value: "", jsx: <></>}
        }
        const stopLocationPopupTrigger = <strong onClick={() => {
            if (stop.stopId) {
                navigator.clipboard.writeText(stop.stopId)
            }
        }}>{" " + stop.name}{stop.city ? ", " + stop.city : null}</strong>
        return {
            value: "Stoppstatus aktualisiert"
                + " " + stop.name + (stop.city ? ", " + stop.city : ""),
            jsx: <>
                Stoppstatus aktualisiert
                {renderObjectAttributePopup(stopLocationPopupTrigger, modifiedObj.level + "-ID: ", stop.stopId)}
            </>
        }
    }
    return {value: "", jsx: <></>}
}

function renderUpdateStopActionStatusDescription(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    if (modifiedObj.obj) {
        const action: StopAction = modifiedObj.obj as StopAction
        const stopActionPopupTrigger = <strong onClick={() => {
            if (action.actionId) {
                navigator.clipboard.writeText(action.actionId)
            }
        }}>{" " + modifiedObj.parentObjs.stop?.name}{modifiedObj.parentObjs.stop?.city ? ", " + modifiedObj.parentObjs.stop.city : null}</strong>
        return {
            value: "Aktualisierung des Aktionstatus"
                + " " + modifiedObj.parentObjs.stop?.name + (modifiedObj.parentObjs.stop?.city ? ", " + modifiedObj.parentObjs.stop.city : ""),
            jsx: <>
                Aktualisierung des
                    Aktionstatus:
                {renderObjectAttributePopup(stopActionPopupTrigger, modifiedObj.level + "-ID: ", modifiedObj.parentObjs.stop?.stopId)}
            </>
        }
    }
    return {value: "", jsx: <></>}
}

function renderAttachmentAddDescription(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    // const attachment: Attachment | undefined = modifiedObj.obj as Attachment
    let parent: TransportOrder | Transport | StopLocation | Freight | undefined = undefined
    let parentId: string | undefined = undefined
    if (modifiedObj.parentObjs && modifiedObj.level) {
        switch (modifiedObj.level) {
            case "TransportOrder":
                parent = ce.transportOrder
                parentId = ce.transportOrder?._id
                break;
            case "Transport":
                parent = modifiedObj.parentObjs.transport
                parentId = (parent as Transport).transportId
                break;
            case "StopLocation":
                parent = modifiedObj.parentObjs.stop
                parentId = (parent as StopLocation).stopId
                break;
            case "Freight":
                parent = modifiedObj.parentObjs.freight
                parentId = (parent as Freight).freightId
                break;
        }
    }
    const attachmentAddPopupTrigger = <strong onClick={() => {
        if (parentId) {
            navigator.clipboard.writeText(parentId)
        }
    }}>{modifiedObj.level}</strong>
    return {
        value: "Neuer Anhang wurde hinzugefügt: " + modifiedObj.level,
        jsx: <>
            Neuer Anhang wurde
                hinzugefügt:
            {parent ?
                renderObjectAttributePopup(attachmentAddPopupTrigger, modifiedObj.level + "-ID: ", parentId)
                : <strong>{modifiedObj.level}</strong>}
        </>
    }
}

function renderAttachmentDeleteDescription(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    let parent: TransportOrder | Transport | StopLocation | Freight | undefined = undefined
    let parentId: string | undefined = undefined
    if (modifiedObj.parentObjs && modifiedObj.level) {
        switch (modifiedObj.level) {
            case "TransportOrder":
                parent = ce.transportOrder
                parentId = ce.transportOrder?._id
                break;
            case "Transport":
                parent = modifiedObj.parentObjs.transport
                parentId = (parent as Transport).transportId
                break;
            case "StopLocation":
                parent = modifiedObj.parentObjs.stop
                parentId = (parent as StopLocation).stopId
                break;
            case "Freight":
                parent = modifiedObj.parentObjs.freight
                parentId = (parent as Freight).freightId
                break;
        }
    }
    const attachmentDeletePopupTrigger = <strong onClick={() => {
        if (parentId) {
            navigator.clipboard.writeText(parentId)
        }
    }}>{modifiedObj.level}</strong>

    return {
        value: "Anhang gelöscht: " + modifiedObj.level,
        jsx: <>
            Anhang gelöscht
            {renderObjectAttributePopup(attachmentDeletePopupTrigger, modifiedObj.level + "-ID: ", parentId)}
        </>
    }
}

function renderLinkTransportOrder(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    const uniqueLinkedInfos: UniqueLinkedInfo[] = getUniqueLinkedInfos(ce, modifiedObj)
    if (uniqueLinkedInfos && uniqueLinkedInfos.length > 0) {
        let valueData = ""
        let jsxData = uniqueLinkedInfos.map((info, index) => {

            const linkTransportOrderPopUpTrigger = <strong onClick={() => {
                if (info.transportId) {
                    navigator.clipboard.writeText(info.transportId)
                }
            }}>Transport</strong>
            const linkPatchTransportOrderPopUpTrigger = <strong onClick={() => {
                if (info.infoFromPatch?.transportId) {
                    navigator.clipboard.writeText(info.infoFromPatch.transportId)
                }
            }}>{info.infoFromPatch?.transportOrderId + " "}</strong>

            valueData += "Transport  is linked to " + info.infoFromPatch?.transportOrderId + " "
            // todo: add translation
            return <>
                {index > 0 ? <br/> : null}
                {renderObjectAttributePopup(linkTransportOrderPopUpTrigger, "Transport ID: ", info.transportId)}
                {" is linked to "}
                {renderObjectAttributePopup(linkPatchTransportOrderPopUpTrigger, "Linked Transport ID: ", info.infoFromPatch.transportId)}
                <Icon className={"highlight"} color={"teal"} name={"external"}
                      onClick={() => openInNewTab(window.location.origin + "/tour/" + info.infoFromPatch.transportOrderId)}/>
            </>
        })
        // console.log("return data is ", valueData)
        return {
            value: valueData,
            jsx: <>{jsxData}</>
        }
    }
    return {value: "", jsx: <></>}
}

function renderUpdateFreightStatus(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    if (modifiedObj.obj) {
        const freight: Freight = modifiedObj.obj as Freight
        const oldValue = modifiedObj.patches ? formatFreightStatusEnum(getFreightStatusEnumFromString(modifiedObj.patches[0]?.oldValue)) : null
        const newValue = formatFreightStatus(freight?.status)
        return {
            value: oldValue + " -> " + newValue,
            jsx: <strong>{oldValue + " -> " + newValue}</strong>
        }
    }
    return {value: "", jsx: <></>}

}

function renderVehicleAssignment(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    if (modifiedObj.obj) {
        const assignedObj: Vehicle = modifiedObj.obj as Vehicle

        let vehicle = ""
        if (assignedObj.numberPlate) {
            vehicle += assignedObj.numberPlate
        }
        if (assignedObj.externalVehicleId) {
            vehicle += " (" + assignedObj.externalVehicleId + ")"
        }
        const vehiclePopupTrigger = <strong onClick={() => {
            if (assignedObj._id) {
                navigator.clipboard.writeText(assignedObj._id)
            }
        }}>{vehicle}</strong>
        const vehiclePopupAttributeName = <>Type: <strong>{formatVehicleTypeEnum(assignedObj.vehicleType)}</strong>, <br/> ID:</>
        return {
            value: vehicle,
            jsx: renderObjectAttributePopup(vehiclePopupTrigger, vehiclePopupAttributeName, assignedObj._id)
        }
    }
    return {value: "", jsx: <></>}
}

function renderDeviceAssignment(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    if (modifiedObj.obj) {
        const assignedDevice: AssignedDevice = modifiedObj.obj as AssignedDevice
        let byText = ""
        let contactInfo = ""
        if (assignedDevice.emailAddress) {
            byText = "per E-Mail an"
            contactInfo = assignedDevice.emailAddress
        } else if (assignedDevice.phoneNr) {
            byText = "per SMS an"
            contactInfo = assignedDevice.phoneNr
        }
        return {
            value: "Der Auftrag wurde an " + assignedDevice.verboseName + byText + contactInfo + " zugewiesen",
            jsx: <>Der Auftrag wurde an <strong>{assignedDevice.verboseName}</strong> {byText} <strong>{contactInfo}</strong> zugewiesen</>
        }
    }
    return {value: "", jsx: <></>}
}

function renderOrderBoxAssignment(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    if (modifiedObj.obj) {
        const assignedOrderBox: AssignedOrderBox = modifiedObj.obj as AssignedOrderBox
        const orderBoxPopupTrigger = <strong onClick={() => {
            if (assignedOrderBox.imei) {
                navigator.clipboard.writeText(assignedOrderBox.imei)
            }
        }}>{" " + assignedOrderBox.tag}</strong>
        return {
            value: " " + assignedOrderBox.tag,
            jsx: renderObjectAttributePopup(orderBoxPopupTrigger, "IMEI: ", assignedOrderBox.imei)
        }
    }
    return {value: "", jsx: <></>}
}

function renderAttachmentAdd(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    const attachment: Attachment | undefined = modifiedObj.obj as Attachment

    return {
        value: 'Klicke zum Zeigen',
        jsx: <>
            <Popup content={'Klicke zum Zeigen'}
                   trigger={<strong className={"textHighlight"}
                                    onClick={async () => {
                                        if (attachment?.attachmentId && ce?.transportOrder?._id) {
                                            let auth = backend.withTokenAuthHeader(authentication.token)
                                            let downloadUrl = await backend.attachmentApi.getAttachmentInlineUrl(
                                                attachment.attachmentId,
                                                ce?.transportOrder?._id,
                                                attachment.name,
                                                auth
                                            )
                                            window.open(downloadUrl.preSignedUrl, '_blank')
                                        }
                                    }}>
                       <Icon className={"highlight"} name='paperclip' color={"teal"}/> {attachment.name}
                   </strong>}
            />
        </>
    }
}

function renderCompany(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    const targetCompany: Company | undefined = modifiedObj.obj as Company
    const CompanyPopupTrigger = <strong onClick={() => {
        if (targetCompany?._id) {
            navigator.clipboard.writeText(targetCompany?._id)
        }
    }}>{targetCompany.name}</strong>
    return {
        value: targetCompany.name ? targetCompany.name : "",
        jsx: <>
            {renderObjectAttributePopup(CompanyPopupTrigger, "Company-ID: ", targetCompany._id)}
        </>
    }
}

function renderAttachmentDelete(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    const attachment: Attachment | undefined = modifiedObj.obj as Attachment

    return {
        value: attachment.name ? attachment.name : "",
        jsx: <><span style={{textDecoration: "line-through"}}>
                <Icon name='paperclip' style={{color: "#891919"}}/> {attachment.name}
        </span></>
    }

}

function renderAttachmentModify(ce: TransportOrderChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce)
    let attachment: Attachment = modifiedObj.obj as Attachment
    const categoryPatch: PatchEntry | undefined = modifiedObj.patches?.find(p => p.path?.includes(".category"))
    let renderCategory = undefined;
    if (categoryPatch) {
        renderCategory = <div>
            Kategorie:
            <strong>{" " + categoryPatch.oldValue + " -> " + attachment.category}</strong>
        </div>
    }
    const statusCodePatch: PatchEntry | undefined = modifiedObj.patches?.find(p => p.path?.includes(".statusCode"))
    let renderStatusCode = undefined;
    if (statusCodePatch) {
        let rejectedReasonIcon = <></>
        if (attachment.status?.statusCode == AttachmentStatus.StatusCodeEnum.REJECTED) {
            rejectedReasonIcon = <>
                <Popup trigger={<Icon name={"exclamation triangle"} color={"yellow"}/>}
                       content={attachment.status.rejectedReason ? attachment.status.rejectedReason : attachment.status.rejectedCode}/>
            </>
        }
        renderStatusCode = <div>
            Statuscode :
            <strong>{" " + statusCodePatch.oldValue + " -> " + attachment.status?.statusCode}</strong>
            {rejectedReasonIcon}
        </div>
    }

    // const levelPatch: PatchEntry | undefined = modifiedObj.patches?.find(p => p.path?.includes(".level"))
    // let renderLevel = undefined;
    // if (modifiedObj.patches && levelPatch) {
    //     renderLevel = <div>
    //         <Trans i18nKey="TransportOrderNewHistoryComponent.Category">Kategorie</Trans> :
    //         <strong>{" " + levelPatch.oldValue + " -> " + attachment.status}</strong>
    //     </div>
    // }
    // Todo attachment level patch set ()
    // Todo attachment status and if rejected then reason
    // const oldValue = modifiedObj.patches ? formatTransportOrderStatusEnum(getTransportOrderStatusEnumFromString(modifiedObj.patches[0]?.oldValue)) : null
    // const newValue = formatTransportOrderStatus(attachment?.status)
    {/*<strong>{oldValue + " -> " + newValue}</strong>*/
    }

    return {
        value: "Kategorie: " + categoryPatch?.oldValue + " -> " + attachment?.category ?? "" + '\n'
            + "Statuscode: " + statusCodePatch?.oldValue + " -> " + attachment.status?.statusCode,
        jsx: <>
            {renderCategory}
            {renderStatusCode}
        </>
    }
}

function renderTransportOrderStatus(ce: ChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce.transportOrderEvent)
    let transportOrder: TransportOrder = ce.transportOrderEvent?.transportOrder as TransportOrder
    const lockPatch: PatchEntry | undefined = modifiedObj.patches?.find(p => p.path == "$.status.isLocked")
    let renderIsLockedStatus = undefined;
    if (modifiedObj.patches && lockPatch) {
        renderIsLockedStatus = <div>
            Der Auftrag wurde
            <strong>{" " + ((modifiedObj.patches[0].oldValue == "true") ? "Unlocked" : "Locked")}</strong>
        </div>
        if (modifiedObj.patches?.length == 1) {
            return {
                value: "Der Auftrag wurde" +
                    " " + ((modifiedObj.patches[0].oldValue == "true") ? "Unlocked" : "Locked"),
                jsx: <>{renderIsLockedStatus}</>
            }
        }
    }
    const oldStatusValue: TransportOrderStatus.StatusCodeEnum | undefined = getTransportOrderStatusEnumFromString(modifiedObj.patches ? modifiedObj.patches[0]?.oldValue : undefined)
    const oldStatusValueString: string = oldStatusValue ? formatTransportOrderStatusEnum(oldStatusValue) : ""
    const newValue: string = formatTransportOrderStatus(transportOrder?.status)
    if (modifiedObj.patches && lockPatch) {
        return {
            value: (oldStatusValue ? oldStatusValueString + " -> " : "") + newValue + '\n'
                + "Der Auftrag wurde" +
                " " + ((modifiedObj.patches[0].oldValue == "true") ? "Unlocked" : "Locked"),
            jsx: <>
                <strong>{(oldStatusValue ? oldStatusValueString + " -> " : "") + newValue}</strong>
                {renderIsLockedStatus}
                <IfBox shouldShow={formatTransportOrderTelematicStatus(transportOrder.status) != ""}>
                    <div>
                        Telematikstatus:
                        <strong>{transportOrder.status && formatTransportOrderTelematicStatus(transportOrder.status)}</strong>
                    </div>
                </IfBox>
            </>
        }
    } else {
        if (formatTransportOrderTelematicStatus(transportOrder.status) != "") {
            return {
                value: (oldStatusValue ? oldStatusValueString + " -> " : "") + newValue + '\n'
                    + "Telematikstatus: " + transportOrder.status && formatTransportOrderTelematicStatus(transportOrder.status),
                jsx: <>
                    <strong>{(oldStatusValue ? oldStatusValueString + " -> " : "") + newValue}</strong>
                    {renderIsLockedStatus}
                    <IfBox shouldShow={formatTransportOrderTelematicStatus(transportOrder.status) != ""}>
                        <div>
                            Telematikstatus
                            {": "}
                            <strong>{transportOrder.status && formatTransportOrderTelematicStatus(transportOrder.status)}</strong>
                        </div>
                    </IfBox>
                </>
            }
        } else {
            return {
                value: (oldStatusValue ? oldStatusValueString + " -> " : "") + newValue,
                jsx: <>
                    <strong>{(oldStatusValue ? oldStatusValueString + " -> " : "") + newValue}</strong>
                    {renderIsLockedStatus}
                </>
            }
        }

    }


}

function renderUpdateStopLocationStatus(ce: ChangeEvent): ColumnData {
    const modifiedObj: ModifiedObj = getModifiedObject(ce.transportOrderEvent)
    if (modifiedObj.obj) {
        const stop: StopLocation = modifiedObj.obj as StopLocation
        if (!stop.stopId) {
            return {value: "", jsx: <></>}
        }
        const oldValue = modifiedObj.patches ? formatStopLocationStatusEnum(getStopLocationStatusEnumFromString(modifiedObj.patches[0]?.oldValue)) : null
        const newValue = formatStopLocationStatus(stop.status)
        return {
            value: oldValue + " -> " + newValue,
            jsx: <strong>{oldValue + " -> " + newValue}</strong>
        }
    }
    return {value: "", jsx: <></>}
}

function renderOrderUpdate(event: ChangeEvent, showRaw: boolean = true): ColumnData {
    const updates: UpdateEventModifyObj[] = []
    // console.log("%c ==========renderOrderUpdatePatch ChangeEvent============", "color: red", event)
    const uniqueStopIndexes: number[] = []
    const uniqueFreightIndexes: number[] = []
    console.debug("event : ", event.timestamp)

    event.transportOrderEvent?.patchSet?.forEach((patch) => {
        /** Patches to ignore **/
        if (!isPatchRelevantForHuman(patch, TypeEnum.UPDATE, showRaw)) {
            console.debug("patch is not relevant for human", patch)
            return
        }

        console.debug("patch : ", patch)
        // console.log("%c renderOrderUpdatePatch relevantPatch", "color: orange", patch)
        const update: UpdateEventModifyObj = {}
        update.columnDataToReturn = {value: "", jsx: <></>}
        let objs: { transport?: Transport, stop?: StopLocation, freight?: Freight } = {}
        switch (patch.op) {
            case "add":
                update.operation = "add"
                break;
            case "replace":
                update.operation = "replace"
                break;
            case "remove":
                update.operation = "remove"
                break;
        }
        if (patch?.path?.includes(".transports[")) {
            const transportIndex: number = +patch?.path?.split("transports[")[1].split("]")[0]
            objs.transport = event.transportOrderEvent?.transportOrder?.transports ? event.transportOrderEvent?.transportOrder?.transports[transportIndex] : undefined
            if (patch?.path?.includes(".stops[")) {
                /** This is StopLocation Level **/
                const stopIndex: number = +patch?.path?.split(".stops[")[1].split("]")[0]
                if (uniqueStopIndexes.includes(stopIndex)) {
                    return;
                }
                uniqueStopIndexes.push(stopIndex)
                objs.stop = objs.transport?.stops ? objs.transport?.stops[stopIndex] : undefined
                const stopTrigger = <strong onClick={() => {
                    if (objs.stop?.stopId) {
                        navigator.clipboard.writeText(objs.stop?.stopId)
                    }
                }}>StopLocation ({objs.stop?.name}{objs.stop?.city ? ", " + objs.stop?.city : null})</strong>
                // if (patch?.path?.includes(".additionalData[")) {
                //     /** This is StopLocation additionalData Level **/
                //     if (patch?.path?.includes("].value")) {
                //         const additionalDataIndex: number = +patch?.path?.split(".additionalData[")[1].split("]")[0]
                //         const additionalData = objs.stop?.additionalData ? objs.stop?.additionalData[additionalDataIndex] : undefined
                //         // console.log("printed StopLocation additionalData: ", patch?.path, patch)
                //         updates.push(<div>
                //             {renderObjectAttributePopup(stopTrigger, "ID: ", objs.stop?.stopId)}
                //             {" additionalData " + operation + "."}
                //         </div>)
                //         return
                //     } else {
                //         // console.log("NOT printed StopLocation additionalData: ", patch?.path, patch)
                //         return
                //     }
                // }
                // console.log("printed StopLocation: ", patch?.path, patch)

                update.columnDataToReturn.value = "StopLocation" + objs.stop?.name + (objs.stop?.city ? ", " + objs.stop?.city : "") + " " + update.operation + "."
                update.columnDataToReturn.jsx = <>
                    {/*<strong>Stop</strong>*/}
                    {renderObjectAttributePopup(stopTrigger, "ID: ", objs.stop?.stopId)}
                    {" " + update.operation + "."}
                </>
                update.level = "StopLocation"
                // updates.filter(u => u.operation && update.level)
                updates.push(update)
                return


            }

            /** This is Transport Level **/
            const transportTrigger = <strong onClick={() => {
                if (objs.transport?.transportId) {
                    navigator.clipboard.writeText(objs.transport?.transportId)
                }
            }}>Transport</strong>
            // if (patch?.path?.includes(".additionalData[")) {
            //     if (patch?.path?.includes("].value")) {
            //         // This is Transport additionalData Level
            //         const additionalDataIndex: number = +patch?.path?.split(".additionalData[")[1].split("]")[0]
            //         const additionalData = objs.transport?.additionalData ? objs.transport?.additionalData[additionalDataIndex] : undefined
            //         // console.log("printed transport additionalData: ", patch?.path, patch)
            //         updates.push(<div>
            //             {renderObjectAttributePopup(transportTrigger, "ID: ", objs.transport?.transportId)}
            //             {" additionalData " + operation + "."}
            //         </div>)
            //         return
            //     } else {
            //         // console.log("NOT printed transport additionalData: ", patch?.path, patch)
            //         return
            //     }
            // }
            // console.log("printed Transport: ", patch?.path, patch)
            update.columnDataToReturn.value = "Transport " + update.operation + "."
            update.columnDataToReturn.jsx = <>
                {/*<strong>Transport</strong>*/}
                {renderObjectAttributePopup(transportTrigger, "ID: ", objs.transport?.transportId)}
                {" " + update.operation + "."}
            </>
            update.level = "Transport"
            updates.push(update)
            return
        } else {
            if (patch?.path?.includes("$.freight[")) {
                /** This is freight Level **/
                const freightIndex: number = +patch?.path?.split("freight[")[1].split("]")[0]
                if (uniqueFreightIndexes.includes(freightIndex)) {
                    return
                }
                uniqueFreightIndexes.push(freightIndex)
                objs.freight = event.transportOrderEvent?.transportOrder?.freight ? event.transportOrderEvent?.transportOrder?.freight[freightIndex] : undefined

                // const freightTrigger = <strong onClick={() => {
                //     if (objs.freight?.freightId) {
                //         navigator.clipboard.writeText(objs.freight?.freightId)
                //     }
                // }}>Freight</strong>

                // if (patch?.path?.includes(".additionalData[")) {
                //     /** This is freight additionalData Level **/
                //     if (patch?.path?.includes("].value")) {
                //         const additionalDataIndex: number = +patch?.path?.split(".additionalData[")[1].split("]")[0]
                //         const additionalData = objs.freight?.additionalData ? objs.freight?.additionalData[additionalDataIndex] : undefined
                //         // console.log("printed TransportOrder: ", patch?.path, patch)
                //         updates.push(<div>
                //             {renderObjectAttributePopup(freightTrigger, "ID: ", objs.freight?.freightId)}
                //             {" additionalData " + operation + "."}
                //         </div>)
                //         return
                //     } else {
                //         // console.log("NOT printed TransportOrder additionalData: ", patch?.path, patch)
                //         return
                //     }
                // }
                // console.log("printed freight: ", patch?.path, patch)
                update.columnDataToReturn.value = "Freight " + update.operation + "."
                update.columnDataToReturn.jsx = <>
                    <strong>Freight</strong>
                    {/*{renderObjectAttributePopup(freightTrigger, "ID: ", objs.freight?.freightId)}*/}
                    {" " + update.operation + "."}
                </>
                update.level = "Freight"
                updates.push(update)
                return
            }
            // if (patch?.path?.includes("$.additionalData[")) {
            //     /** This is TransportOrder additionalData Level **/
            //     if (patch?.path?.includes("].value")) {
            //         // const additionalDataIndex: number = +patch?.path?.split(".additionalData[")[1].split("]")[0]
            //         // const additionalData = transportOrder?.additionalData ? transportOrder?.additionalData[additionalDataIndex] : undefined
            //         // console.log("printed TransportOrder: ", patch?.path, patch)
            //         // const transportOrderTrigger = <strong>TransportOrder</strong>
            //         updates.push(<div>
            //             {/*{renderObjectAttributePopup(transportOrderTrigger, "ID: ", transportOrder?._id)}*/}
            //             {"Transport Order additionalData " + operation + "."}
            //         </div>)
            //         return
            //     } else {
            //         // console.log("NOT printed TransportOrder additionalData: ", patch?.path, patch)
            //         return
            //     }
            // }
            // console.log("Patches NOT printed: path", patch?.path)
            // console.log("Patches %cNOT printed: ", "color: red", patch, event)
        }
    })
    // console.log("uniqueFreightIndexes: ", uniqueFreightIndexes)
    // console.log("updates: ", updates)

    if (updates.length == 0) {
        return {
            value: "keine Änderung",
            jsx: <>keine Änderung</>
        }
    } else {
        const compactUpdates: UpdateEventModifyObj[] = []
        updates.filter((x: UpdateEventModifyObj) => {
            const found: UpdateEventModifyObj | undefined = compactUpdates.find(c => c.level == x.level && c.operation == x.operation)
            if (found && found.count != undefined) {
                found.count++
            } else {
                x.count = 1
                compactUpdates.push(x)
            }
        })
        // console.log("compactUpdates", compactUpdates)
        let valueToReturn: string = ""
        let returnJsxData = compactUpdates.map(u => {
            valueToReturn += u.columnDataToReturn?.value
            return <div>
                {(u.count && u.count > 1)
                    ? <><strong>{u.level}</strong> {" " + u.operation + ". (" + u.count + ")"} </>
                    : u.columnDataToReturn?.jsx
                }
            </div>
        })
        return {
            value: valueToReturn,
            jsx: <>{returnJsxData}</>
        }
    }
}

function getModifiedObject(event?: TransportOrderChangeEvent): ModifiedObj {
    const updatedObjectIndex: { freight?: number, stop?: number, transport?: number } = {}
    const returnObj: ModifiedObj = {
        level: undefined,
        obj: undefined,
        patches: undefined,
        parentObjs: {
            freight: event?.path?.freightId ? event.transportOrder?.freight?.find((f, index) => {
                updatedObjectIndex.freight = index
                return f.freightId == event.path?.freightId ? f : undefined
            }) : undefined,
            stop: undefined,
            transport: event?.path?.transportId ? event.transportOrder?.transports?.find((t, index) => {
                updatedObjectIndex.transport = index
                return t.transportId == event.path?.transportId ? t : undefined
            }) : undefined,
        }
    }
    if (event?.path?.stopId) {
        returnObj.parentObjs.stop = returnObj.parentObjs.transport?.stops?.find((s, index) => {
            updatedObjectIndex.stop = index
            return s.stopId == event.path?.stopId ? s : undefined
        })
    }
    // if (event?.path?.actionId) {
    //     returnObj.parentObjs.action = returnObj.parentObjs.stop?.actions?.find((s, index) => {
    //         updatedObjectIndex.action = index
    //         return s.actionId == event.path?.actionId ? s : undefined
    //     })
    // }
    let pathToCompare = ""
    switch (event?.type) {
        case TypeEnum.STATUSUPDATESTOPLOCATION:
            returnObj.level = "StopLocation"
            returnObj.obj = returnObj.parentObjs.stop
            if (event.path?.transportId && event.path?.stopId) {
                pathToCompare = "$.transports[" + updatedObjectIndex.transport + "].stops[" + updatedObjectIndex.stop + "].status.statusCode"
                returnObj.patches = event.patchSet?.filter(p => p.path?.includes(pathToCompare))
            }
            return returnObj

        case TypeEnum.STATUSUPDATETRANSPORTORDER:
            returnObj.level = "TransportOrder"
            returnObj.obj = event.transportOrder
            if (event.path) {
                pathToCompare = "$.status.statusCode"
                returnObj.patches = event.patchSet?.filter(p => p.path?.includes(pathToCompare))
                const lockStatusPatches: PatchEntry[] | undefined = event.patchSet?.filter(p => p.path?.includes("$.status.isLocked"))
                if (lockStatusPatches && lockStatusPatches.length > 0) {
                    returnObj.patches = returnObj.patches?.concat(lockStatusPatches)
                }
            }
            return returnObj

        case TypeEnum.ASSIGNCOMPANY:
            returnObj.level = "TransportOrder"
            returnObj.obj = {
                _id: event.transportOrder?.targetCompanyId,
                name: event.transportOrder?.targetCompanyName
            } as Company
            pathToCompare = "$.assignedCompany"
            returnObj.patches = event.patchSet?.filter(p => p.path?.includes(pathToCompare))
            return returnObj

        case TypeEnum.LINKEVENT:
            returnObj.level = "TransportOrder"
            // returnObj.obj = {}
            pathToCompare = "].linkInfo.targetLinks"
            returnObj.patches = event.patchSet?.filter(p => {
                if (p.path?.includes(pathToCompare)) {
                    return p
                    // } else {
                    //     console.log("LINKEVENT not printed:", p)
                }
            })
            return returnObj

        case TypeEnum.ASSIGNVEHICLE:
            returnObj.level = "TransportOrder"
            returnObj.obj = event.transportOrder?.assignedVehicle
            pathToCompare = "$.assignedVehicle"
            returnObj.patches = event.patchSet?.filter(p => p.path?.includes(pathToCompare))
            return returnObj

        case TypeEnum.ASSIGNTRAILER:
            returnObj.level = "TransportOrder"
            returnObj.obj = event.transportOrder?.assignedTrailer
            pathToCompare = "$.assignedTrailer"
            returnObj.patches = event.patchSet?.filter(p => p.path?.includes(pathToCompare))
            return returnObj

        case TypeEnum.ASSIGNDEVICE:
            returnObj.level = "TransportOrder"
            returnObj.obj = event.transportOrder?.assignedDevice
            pathToCompare = "$.assignedDevice"
            returnObj.patches = event.patchSet?.filter(p => p.path?.includes(pathToCompare))
            return returnObj

        case TypeEnum.ASSIGNORDERBOX:
            returnObj.level = "TransportOrder"
            returnObj.obj = event.transportOrder?.assignedOrderBox
            pathToCompare = "$.assignedOrderBox"
            returnObj.patches = event.patchSet?.filter(p => p.path?.includes(pathToCompare))
            return returnObj

        case TypeEnum.ATTACHMENTADD: {
            if (event.path?.freightId) {
                returnObj.level = "Freight"
                returnObj.obj = returnObj.parentObjs.freight?.attachments?.find(a => a.attachmentId == event.path?.attachmentId)
                return returnObj
            }
            if (event.path?.stopId) {
                returnObj.level = "StopLocation"
                returnObj.obj = returnObj.parentObjs.stop?.attachments?.find(a => a.attachmentId == event.path?.attachmentId)
                return returnObj
            }
            if (event.path?.transportId) {
                returnObj.level = "Transport"
                returnObj.obj = returnObj.parentObjs.transport?.attachments?.find(a => a.attachmentId == event.path?.attachmentId)
                return returnObj
            }
            if (event.path?.transportOrderId) {
                returnObj.level = "TransportOrder"
                returnObj.obj = event.transportOrder?.attachments?.find(a => a.attachmentId == event.path?.attachmentId)
                return returnObj
            }
            return returnObj
        }

        case TypeEnum.ATTACHMENTDELETE:
            if (event.patchSet) {
                pathToCompare = ".attachments["
                const patch: PatchEntry | undefined = event.patchSet?.find(p => p.path?.includes(pathToCompare) && p.op == "remove")
                if (patch) {
                    returnObj.patches = []
                    returnObj.patches.push(patch)
                    const patchPath = patch?.path
                    if (patch.oldValue) {
                        returnObj.obj = JSON.parse(patch.oldValue)
                    }
                    if (patchPath?.includes("$.attachments[")) {
                        returnObj.level = "TransportOrder"
                        return returnObj
                    }

                    if (patchPath?.includes("transports")) {
                        const transportIndex: number = +patchPath.split("transports[")[1].split("]")[0]
                        returnObj.level = "Transport"
                        returnObj.parentObjs.transport = event.transportOrder?.transports ? event.transportOrder?.transports[transportIndex] : undefined
                    }
                    if (patchPath?.includes("stops")) {
                        returnObj.level = "StopLocation"
                        const stopIndex: number = +patchPath.split("stops[")[1].split("]")[0]
                        returnObj.parentObjs.stop = returnObj.parentObjs.transport?.stops ? returnObj.parentObjs.transport?.stops[stopIndex] : undefined
                    }
                    if (patchPath?.includes("freights")) {
                        returnObj.level = "Freight"
                        const freightIndex: number = +patchPath.split("freights[")[1].split("]")[0]
                        returnObj.parentObjs.freight = event.transportOrder?.freight ? event.transportOrder?.freight[freightIndex] : undefined
                    }
                }
            }
            return returnObj
        case TypeEnum.ATTACHMENTMODIFY:
            if (event.patchSet) {
                pathToCompare = ".attachments["
                const patch: PatchEntry[] | undefined = event.patchSet?.filter(p => p.path?.includes(pathToCompare))
                if (patch?.length > 0) {
                    returnObj.patches = patch
                    const patchPath = patch[0]?.path

                    if (patchPath?.includes("$.attachments[")) {
                        returnObj.level = "TransportOrder"
                        returnObj.obj = event.transportOrder?.attachments?.find(a => a.attachmentId == event.path?.attachmentId)
                        return returnObj
                    }

                    if (patchPath?.includes("transports")) {
                        const transportIndex: number = +patchPath.split("transports[")[1].split("]")[0]
                        returnObj.level = "Transport"
                        returnObj.parentObjs.transport = event.transportOrder?.transports ? event.transportOrder?.transports[transportIndex] : undefined
                        returnObj.obj = returnObj.parentObjs.transport?.attachments?.find(a => a.attachmentId == event.path?.attachmentId)
                    }
                    if (patchPath?.includes("stops")) {
                        returnObj.level = "StopLocation"
                        const stopIndex: number = +patchPath.split("stops[")[1].split("]")[0]
                        returnObj.parentObjs.stop = returnObj.parentObjs.transport?.stops ? returnObj.parentObjs.transport?.stops[stopIndex] : undefined
                        returnObj.obj = returnObj.parentObjs.stop?.attachments?.find(a => a.attachmentId == event.path?.attachmentId)
                    }
                    if (patchPath?.includes("freights")) {
                        returnObj.level = "Freight"
                        const freightIndex: number = +patchPath.split("freights[")[1].split("]")[0]
                        returnObj.parentObjs.freight = event.transportOrder?.freight ? event.transportOrder?.freight[freightIndex] : undefined
                        returnObj.obj = returnObj.parentObjs.freight?.attachments?.find(a => a.attachmentId == event.path?.attachmentId)
                    }
                }
            }
            return returnObj

        case TypeEnum.STATUSUPDATEFREIGHT:
            pathToCompare = "$.freight[" + updatedObjectIndex.freight + "].status.statusCode"
            returnObj.level = "Freight"
            returnObj.obj = returnObj.parentObjs.freight
            returnObj.patches = event.patchSet?.filter(p => p.path?.includes(pathToCompare))
            return returnObj

        case TypeEnum.STATUSUPDATESTOPACTION:
            returnObj.level = "StopLocation"
            let actionIndex = -1;
            if (event?.path?.actionId) {
                returnObj.obj = returnObj.parentObjs.stop?.actions?.find((action, index) => {
                    if (action.actionId == event.path?.actionId) {
                        actionIndex = index
                        return action
                    }
                    return undefined
                })
            }
            if (actionIndex > -1) {
                pathToCompare = "$.transports[" + updatedObjectIndex.transport + "].stops[" + updatedObjectIndex.stop + "].actions[" + actionIndex + "].status.statusCode"
                returnObj.patches = event.patchSet?.filter(p => p.path?.includes(pathToCompare))
            }
            return returnObj

        default:
            return returnObj

    }
}

function getUniqueLinkedInfos(ce: TransportOrderChangeEvent, modifiedObj: ModifiedObj) {
    const uniqueLinkedInfos: UniqueLinkedInfo[] = []
    console.log("modified object", modifiedObj)
    modifiedObj.patches?.forEach(p => {
        if (p.value && p.path) {
            console.log("ce", ce)
            console.log("p", p)
            console.log("p value", p.value)
            const uniqueStopLocationIds: string[] = []
            let patchValue = JSON.parse(p.value)[0]
            if (!patchValue) {
                patchValue = JSON.parse(p.value)
            }
            console.log("patch value", patchValue)
            const patchTransportIndex: number = +p.path.replace("$.transports[", "").split("]")[0]
            if (ce.transportOrder?.transports && ce.transportOrder?.transports[patchTransportIndex]) {
                const patchedTransport = ce.transportOrder?.transports[patchTransportIndex]
                patchedTransport.stops?.forEach((s: StopLocation) => {
                    if (s.uniqueLocationId && uniqueStopLocationIds?.findIndex((id: string) => id === s.uniqueLocationId) == -1) {
                        uniqueStopLocationIds.push(s.uniqueLocationId)
                        const transportAlreadyExists: boolean = uniqueLinkedInfos.findIndex(info => patchedTransport.transportId == info.transportId) == -1
                        const transportFromPatchAlreadyExists: boolean = uniqueLinkedInfos.findIndex(info => (patchValue.transportId == info.infoFromPatch.transportId) && (patchValue.transportOrderId == info.infoFromPatch.transportOrderId)) == -1
                        if (transportAlreadyExists && transportFromPatchAlreadyExists) {
                            /** check if this specific combination already exists **/
                            uniqueLinkedInfos.push({
                                transportId: patchedTransport.transportId,
                                infoFromPatch: {
                                    transportId: patchValue.transportId,
                                    transportOrderId: patchValue.transportOrderId
                                }
                            })
                        }
                    }
                })
            }
        }
    })
    return uniqueLinkedInfos
}

function renderChangeEventActionButtons(event: ChangeEvent) {
    const targetUrl = "http://" + window.location.host + "/tour-history/" + event._id
    const xRequestId = event.eventSourceRequestId?.replace("[X-Request-ID ", "").slice(0, -1) ?? ""
    const greyLogUrl = "https://graylog.logenios.com/search?q=\"" + xRequestId + "\"&rangetype=relative&from=604800"
    return <>
        <IfBox shouldShow={authentication.isSuperUser}>
            <ShowAdminModifiedObjectInfoButton tc={event}/>
            <a href={targetUrl} target="_blank"><Icon name="external alternate"/></a>
            <IfBox shouldShow={!!xRequestId}>
                <Popup trigger={<a href={greyLogUrl} target="_blank"><Icon name="search"/></a>}
                       content={"Im Graylog anzeigen"}
                       position='right center'
                />
            </IfBox>
        </IfBox>
        <IfBox shouldShow={event?.transportOrderEvent?.type == TransportOrderChange.TypeEnum.UPDATE}>
            <PatchsetInfoButton patchEntry={event?.transportOrderEvent?.patchSet ?? []}/>
            {renderTimeChangeWarning(event?.transportOrderEvent)}
        </IfBox>
    </>
}

export function stringIncludes(searchStrings: string[], mainString?: string, findAny?: boolean): boolean {
    let numberOfFoundStrings: number = 0
    let foundOne: boolean = false
    if (mainString) {
        for (let stringToFind of searchStrings) {
            if (mainString.includes(stringToFind)) {
                if (findAny) {
                    foundOne = true
                    return true
                }
                numberOfFoundStrings++
            }
        }
    }
    return foundOne || searchStrings.length == numberOfFoundStrings;
}
