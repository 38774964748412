import * as React from "react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {ParseTextRequest} from "../../../../../generated";
import {Accordion, Button, Dropdown, Header, Icon, Modal} from "semantic-ui-react";
import AceEditor from "react-ace";
import {useEffect} from "react";
import {CustomDiff} from "../configEdit/diff/CustomDiff";
import * as AceDiff from 'ace-diff';

import 'ace-diff/dist/ace-diff-dark.min.css';
import 'brace/mode/groovy'
import 'brace/ext/searchbox'

const ace = require('brace');

export interface ChatGptHelpModalProps {
    isOpen: boolean,
    onClose: (take: Boolean, newScript: String | null) => void,
    script: string,
    errorLogs: string,

}

interface ChatGptParseErrorResponse{
    lineFrom: number,
    lineTill: number,
    errorMessage: string
}

export function ChatGptHelpModal(props: React.PropsWithChildren<ChatGptHelpModalProps>) {
    const stopSequence = "'''"
    const scriptMarker = "// SCRIPTMARKER"
    let greeting = "Hello\n"
    let scriptIntro = "I have the following script: \n"
    let errorLogsIntro = "\nWhen i run the script, i get the following errors: \n"
    let task = "\n\nCan you please fix it and give me just the fixed script, and add the marker '"+scriptMarker+"' at the beginning and at the end of the script?\n"

    const [input, setInput] = React.useState("")
    const [parsedError, setParsedError] = React.useState<ChatGptParseErrorResponse|undefined>(undefined)
    const [output, setOutput] = React.useState("Click on 'Query ChatGPT' to get a suggestion")
    const [activeIndex, setActiveIndex] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [model, setModel] = React.useState("GPT4" as ParseTextRequest.ModelEnum)
    const [editors, setEditors] = React.useState<any>()


    useEffect(() => {
        console.log("[ChatGptHelpModal] - mounting")
        console.log("[ChatGptHelpModal] - script: " + props.script)
        console.log("[ChatGptHelpModal] - errorLogs: " + props.errorLogs)
        buildInput()


    }, [props]);

    useEffect(() => {
        let suggestedScript = output
        console.log("suggestedScript: " + suggestedScript)

        let aceDiff = new AceDiff({
            ace,
            element: ".acediff",
            left: {
                content: props.script,
                copyLinkEnabled: false
            },
            right: {
                content: suggestedScript,
                editable: false
            },
        });
        if (aceDiff.editors) {
            setEditors(aceDiff.getEditors())
        }
    }, [output]);

    function handleClose() {
        props.onClose(false, null)
    }

    function buildInput() {
        setInput(
            greeting
            + scriptIntro + "\n\n"
            + stopSequence + "\n"
            + props.script + "\n"
            + stopSequence + "\n"
            + errorLogsIntro
            + stopSequence + "\n"
            + props.errorLogs + "\n"
            + stopSequence + "\n"
            + task
        )
    }

    async function callChatGPTToParseErrorMsg() {
        setLoading(true)

        const input = `
        Hello

        i tried to run a script and got the following error:
        
        
        '''
        ${props.errorLogs}
        '''
        
        The script is too long to be provided. 

        In what lines of script do i need to search for the most important error based on the error message above? Please take 5 
        Lines before  and after the error. Can you filter out the relevant error message? Respond as json in the format {"lineFrom":<number>,"lineTill":<number>, "errorMessage":<message>}. Don't give any other response or explaination.
        `

        let auth = backend.withTokenAuthHeader(authentication.token)
        let request = {} as ParseTextRequest
        request.model = model
        request.input = input
        request.rawInput = props.errorLogs
        //request.stopSequence = stopSequence //we better not provide a stop sequence
        request.type = "cmParseScriptError"

        let response = await backend.internalApi.aiParseText(request, auth)

        let jsonStrStart = response.output.indexOf("{")
        let jsonStrEnd = response.output.indexOf("}", jsonStrStart)

        if(jsonStrStart>=0 && jsonStrEnd>=0){
            let jsonPart = response.output.substring(jsonStrStart, jsonStrEnd+1)
            setParsedError(JSON.parse(jsonPart))

        }
        setOutput(response.output)
        //setActiveIndex(1)
        setLoading(false)
    }

    async function callChatGPTToFixScript() {
        setLoading(true)

        const theScriptLines = props.script.split("\n")
        const theScriptPreExcerpt = theScriptLines.filter((ln,idx)=>{
            return idx+1<parsedError.lineFrom
        }).join("\n")
        const theScriptExcerpt = theScriptLines.filter((ln,idx)=>{
            return idx+1>=parsedError.lineFrom && idx+1<parsedError.lineTill
        }).join("\n")
        const theScriptPostExcerpt = theScriptLines.filter((ln,idx)=>{
            return idx+1>parsedError.lineTill
        }).join("\n")

        const input = `
        Given is a excerpt form a script beginning in line ${parsedError.lineFrom}:
        \`\`\`
        ${theScriptExcerpt}
        \`\`\`
        
        The script fails due to the following error:
        ${parsedError.errorMessage}
        
        Please fix the script. Leave the formatting of the script as it is. Dont remove whitespaces to tab chars unless it is required to fix the script
        `

        setInput(input)

        let auth = backend.withTokenAuthHeader(authentication.token)
        let request = {} as ParseTextRequest
        request.model = model
        request.input = input
        request.rawInput = props.errorLogs
        //request.stopSequence = stopSequence //we better not provide a stop sequence
        request.type = "cmFixPartialScript"

        let response = await backend.internalApi.aiParseText(request, auth)

        let scriptStart = response.output.indexOf('```')+3
        let scriptEnd = response.output.indexOf('```', scriptStart)

        let splittedOutput = response.output.substring(scriptStart, scriptEnd).split("\n").slice(1).join("\n")
        console.log("splittedOutput: " + splittedOutput)

        //setOutput(response.output)

        setOutput(theScriptPreExcerpt + '\n' + splittedOutput + theScriptPostExcerpt)
        setActiveIndex(2)
        setLoading(false)
    }


    function handleClickSetState(e, titleProps) {
        const {index} = titleProps
        const newIndex = activeIndex === index ? -1 : index

        setActiveIndex(newIndex)
    }

    function drawInput() {
        return <Accordion>
            <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClickSetState}
            >
                <Icon name='dropdown'/>
                Input Prompt
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
                <AceEditor
                    name='scriptAce'
                    style={{flex: "1"}}
                    theme="monokai"
                    mode="groovy"
                    value={input}
                    width="100%"
                    onChange={(e) => {
                        setInput(e)
                    }}
                />
                {!props.errorLogs || props.errorLogs.length==0 && <>
                    <div>Please load Error logs</div>
                </>
                }
                <Dropdown
                    selectOnBlur={false}
                    placeholder='Select Model'
                    selection
                    defaultValue={model}
                    options={[
                        {key: 'GPT35', value: 'GPT35', text: 'GPT35'},
                        {key: 'GPT4', value: 'GPT4', text: 'GPT4'},
                        {key: 'GPT4_TURBO', value: 'GPT4_TURBO', text: 'GPT4-Turbo'},
                    ]}
                    onChange={(e, data) => {
                        setModel(data.value as ParseTextRequest.ModelEnum)
                    }}
                />
                { !parsedError &&
                    <Button
                        loading={loading}
                        onClick={callChatGPTToParseErrorMsg}
                    >
                        Parse Error-Msg
                    </Button>
                }
                {
                    parsedError &&
                    <Button
                        loading={loading}
                        onClick={callChatGPTToFixScript}
                    >
                        Fix script
                    </Button>
                }
            </Accordion.Content>
        </Accordion>
    }

    function drawOutput() {
        return <Accordion>
            <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleClickSetState}
            >
                <Icon name='dropdown'/>
                Output
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
                <AceEditor
                    name='scriptAce'
                    style={{flex: "1"}}
                    theme="monokai"
                    mode="groovy"
                    value={output}
                    width="100%"
                    readOnly={true}
                />
            </Accordion.Content>
        </Accordion>
    }

    function drawComparison() {
        return <Accordion>
            <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={handleClickSetState}
            >
                <Icon name='dropdown'/>
                Compare
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
                {drawDiff()}
            </Accordion.Content>
        </Accordion>
    }

    function drawDiff() {


        return <>
            <div className="acediff"/>
            <Button
                onClick={() => {
                    console.log("editors.left: ", editors.left.getValue())
                    props.onClose(true, editors.left.getValue())

                }}>take fixed Script</Button>
        </>
    }


    return <Modal
        open={props.isOpen}
        onClose={handleClose}
        size='fullscreen'>
        <Header icon='browser' content='GPT Help'/>
        <Modal.Content>
            {drawInput()}
            {drawOutput()}
            {drawComparison()}

        </Modal.Content>
    </Modal>

}