import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import ReactTable from "react-table";
import {QueuedEvent} from "../../../../../generated";
import {Button, Checkbox, Segment} from "semantic-ui-react";
import {formatDate} from "../../../../../format";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {IfBox} from "../../../../style/if";
import {QueuedEventModal} from "../modals/QueuedEventModal";
import {ChangeMultipleQueuedEventsModal} from "../../queues/modal/ChangeMultipleQueuedEventsModal";
import Notifications, {notify} from 'react-notify-toast';


export interface QueuedEventsListProps {
    transportOrderId: string | null
}


export interface QueuedEventsListState {
    isLoading: boolean
    queuedEvents: QueuedEvent[]
    selectedEvents: QueuedEvent[]
    clickedEvent: QueuedEvent
    count: number
    pageSize: number
    showChangeMultipleQueuedEvents: boolean
    allSelected: boolean
}

export class QueuedEventsList extends React.Component<QueuedEventsListProps, QueuedEventsListState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading QueuedEventsList")

        this.state = {
            isLoading: false,
            queuedEvents: [],
            selectedEvents: [],
            clickedEvent: null,
            count: 0,
            pageSize: 25,
            showChangeMultipleQueuedEvents: false,
            allSelected: false,
        }
    }

    componentDidMount() {
        this.loadQueuedEvents()
    }

    componentDidUpdate(prevProps: Readonly<QueuedEventsListProps>, prevState: Readonly<QueuedEventsListState>, snapshot?: any) {
        if (prevProps.transportOrderId != this.props.transportOrderId) {
            this.loadQueuedEvents()
        }
    }

    async loadQueuedEvents() {
        if (this.props.transportOrderId == null) {
            return
        }
        this.setState({isLoading: true})
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let resp = (await backend.internalApi.fetchQueuedEventsForCM(
            null,
            null,
            undefined,
            undefined,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this.props.transportOrderId,
            auth
        ))
        this.setState({
            queuedEvents: resp.events,
            count: resp.count,
            isLoading: false
        })
    }

    render() {
        const columns = [
            {
                id: 'select',
                Header: '',
                width: 50,
                accessor: (d: any) => {
                    return <Checkbox
                        checked={this.state.selectedEvents.some(event => event._id === d._id)}
                        onChange={() => this.switchSelectionStateOfItem(d)}
                    />
                },
                sortField: 'NONE'
            }, {
                id: '_id',
                Header: 'ID',
                width: 200,
                accessor: (d: any) => d._id,
                sortField: 'ID'
            }, {
                id: 'queueId',
                Header: 'queueId',
                width: 200,
                accessor: (d: any) => d.queueId,
                sortField: 'QUEUE_ID'
            }, {
                id: 'queueType',
                Header: 'queueType',
                width: 200,
                accessor: (d: any) => d.queueType,
                sortField: 'QUEUE_TYPE'
            }, {
                id: 'eventType',
                Header: 'eventType',
                width: 200,
                accessor: (d: any) => d.eventType,
                sortField: 'EVENT_TYPE'
            }, {
                id: 'timestamp',
                Header: 'timestamp',
                width: 150,
                accessor: (d: any) => d.timestamp,
                Cell: (d: any) => {
                    return <span>{formatDate(d.value)}</span>
                },
                sortField: 'TIMESTAMP'
            }, {
                id: 'rateLimited',
                Header: 'rateLimited',
                width: 100,
                accessor: (d: any) => {
                    if (d.rateLimited) {
                        return <span>true</span>
                    } else {
                        return <span>false</span>
                    }
                },
                sortField: 'RATE_LIMITED'
            }, {
                id: 'suppressed',
                Header: 'suppressed',
                width: 100,
                accessor: (d: any) => {
                    if (d.suppressed) {
                        return <span>true</span>
                    } else {
                        return <span>false</span>
                    }
                },
                sortField: 'SUPPRESSED'
            }, {
                id: 'processed',
                Header: 'processed',
                width: 100,
                accessor: (d: any) => {
                    if (d.processed) {
                        return <span>true</span>
                    } else {
                        return <span>false</span>
                    }
                },
                sortField: 'SUPPRESSED'
            }, {
                id: 'processingState',
                Header: 'processingState',
                width: 120,
                accessor: (d: any) => d.processingInfo?.state,
                sortField: 'PROCESSING_STATE'
            }

        ]

        if (this.state.queuedEvents == null) {
            return <p>Loading</p>
        }
        return <>
            {this.renderSelectionOptions()}
            <ReactTable
                data={this.state.queuedEvents}
                manual
                pages={Math.ceil(this.state.count / this.state.pageSize)}
                columns={columns}
                sorted={[
                    {
                        id: 'TIMESTAMP',
                        desc: false
                    }
                ]}
                defaultPageSize={this.state.pageSize}
                className="-striped -highlight"
                loading={this.state.isLoading}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        onClick: () => {
                            if (rowInfo) {
                                if (column.id != 'select') {
                                    this.setState({clickedEvent: rowInfo.original})
                                }
                            }
                        }
                    };
                }}
                style={{cursor: "pointer"}}

            />
            <IfBox shouldShow={this.state.clickedEvent != null}>
                <QueuedEventModal
                    isOpen={this.state.clickedEvent != null}
                    onClose={() => this.setState({clickedEvent: null})}
                    queuedEvent={this.state.clickedEvent}
                />

            </IfBox>

            <ChangeMultipleQueuedEventsModal
                isOpen={this.state.showChangeMultipleQueuedEvents}
                onClose={(success) => {
                    this.setState({showChangeMultipleQueuedEvents: false})
                    if (success) {
                        notify.show('SUCCESS: updated ' + this.state.selectedEvents.length + ' queued events', 'success', 3000, '#28f751');
                        this.loadQueuedEvents()
                    }
                }}
                queuedEventIds={this.state.selectedEvents.map(event => {
                    return event._id
                })}
            />
            <Notifications/>

        </>
    }

    renderSelectionOptions() {
        return <div style={{marginBottom: "2px"}}>
            <Segment compact style={{margin: 0, padding: "6px 6px 6px 0", display: "inline"}}>
                <Checkbox label="select all" checked={this.state.allSelected} style={{marginLeft: "5px"}} onChange={() => this.selectOrUnselectAll()}/>
            </Segment>
            <Button icon={"pencil"} style={{marginLeft: "5px"}}
                    disabled={this.state.selectedEvents.length == 0}
                    onClick={() => this.setState({showChangeMultipleQueuedEvents: true})}/>
        </div>
    }

    selectOrUnselectAll() {
        this.setState({isLoading: true})

        if (this.state.allSelected) {
            console.log("select None")
            this.setState({selectedEvents: [], allSelected: false})
        } else {
            console.log("select All")
            this.setState({selectedEvents: this.state.queuedEvents, allSelected: true})
        }

        this.setState({isLoading: false})

    }

    switchSelectionStateOfItem(event: QueuedEvent) {
        this.setState({isLoading: true})

        let array = [...this.state.selectedEvents]; // make a separate copy of the array
        let index = array.indexOf(event)
        if (index == -1) {
            // id not found, adding Id

            this.setState({selectedEvents: array.concat(event)})
            console.log("Id added")


        } else {
            // Id found, removing it

            array.splice(index, 1);
            this.setState({selectedEvents: array})
            console.log("Id removed")

        }
        this.setState({isLoading: false})
    }


}