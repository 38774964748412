import * as React from "react";
import {useRef} from "react";
import './Splitter.css'

interface SplitterProps {
    name: string,
    left: JSX.Element,
    right: JSX.Element,
    onChange?: (leftWidth: number) => void,
}

export function Splitter(props: React.PropsWithChildren<SplitterProps>) {

    const parentRef = React.useRef(null);
    const offset = useRef({
        x: 0,
        y: 0,
    })

    // Defaulting to 30% of window width
    const storedWidth = parseFloat(window.localStorage.getItem("splitter" + props.name + "_left_width") ?? "30")
    const [leftWidth, setLeftWidth] = React.useState(storedWidth)
    const [ownWidth, setOwnWidth] = React.useState(500)

    const ResizeListener = () => {
        if (parentRef.current) {
            setOwnWidth(parentRef.current.getBoundingClientRect().width)
        }
    }

    React.useEffect(() => {
        setLeftWidth(storedWidth)
        window.addEventListener("resize", ResizeListener)
        const timer = setTimeout(() => {
            window.dispatchEvent(new UIEvent('resize')) //This is just to get the Ace-Editor to resize
        }, 0)
        return () => {
            clearTimeout(timer)
            window.removeEventListener("resize", ResizeListener)
        }
    }, []);

    React.useEffect(() => {
        if (parentRef.current) {
            setOwnWidth(parentRef.current.getBoundingClientRect().width)
        }
    }, [leftWidth, parentRef.current]);

    const mouseUp = (e: MouseEvent) => {
        document.removeEventListener("mousemove", mouseMove)
    }

    const mouseMove = (e: MouseEvent) => {
        const dx = e.clientX - offset.current.x

        let newWidth = (leftWidth + (dx * 100 / ownWidth))

        if (newWidth < 10) newWidth = 10 // Minimum 10% to prevent it from disappearing
        if (newWidth > 90) newWidth = 90 // Maximum 90% to prevent it from covering whole window

        setLeftWidth(newWidth)
        window.localStorage.setItem("splitter" + props.name + "_left_width", newWidth.toString())
        window.dispatchEvent(new UIEvent('resize')) //This is just to get the Ace-Editor to resize
        props.onChange?.(newWidth)
    }

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()

        offset.current.x = e.clientX
        offset.current.y = e.clientY

        document.addEventListener("mousemove", mouseMove)
        document.addEventListener("mouseup", mouseUp)
    }

    return (
        <div id={props.name} className="splitter" ref={parentRef}
             style={{display: "flex", flex: 3, minHeight: "100px"}}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100px",
                width: leftWidth + "%",
                overflow: "hidden"
            }}>
                {props.left}
            </div>

            <div className="splitterResizer" id="dragMe" onMouseDown={onMouseDown}>≡</div>

            <div style={{
                display: "flex",
                flex: "1",
                flexDirection: "column",
                minHeight: "100px",
                overflow: "hidden",
                maxWidth: ownWidth - (leftWidth / 100 * ownWidth) + "px",
            }}>
                {props.right}
            </div>
        </div>
    );
}
