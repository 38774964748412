import * as React from "react";
import AceEditor from 'react-ace';
import {Label} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";

import 'brace/ext/searchbox'
import {ConverterSendButton} from "../ConverterSendButton";

export interface ExportSimQueuedEventEditorProps {
    triggerQueuedEventDirectly: () => void
}

export class ExportSimQueuedEventEditor extends React.Component<ExportSimQueuedEventEditorProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("showing ExportSimQueuedEventEditor")

        this.state = {
            valid: true,
            isLoading: false
        }
    }

    handleQueuedEventChange(value) {
        let valid = null
        this.context.setExportSimQe
        try {
            JSON.parse(value)
            valid = true
        } catch (e) {
            valid = false
        }

        this.context.setExportSimQe(value)
        this.context.setExportSimQeValid(valid)

        // just for a stateUpdate that triggers a rerender
        if (valid != this.state.valid) {
            this.setState({valid: valid})
        }

    }

    async handleTrigger() {
        this.setState({isLoading: true})
        await this.props.triggerQueuedEventDirectly()
        this.setState({isLoading: false})
    }

    render() {
        return <>
            <div style={{marginBottom: "5px"}}>
                <ConverterSendButton
                    disabled={!this.context.exportSimQeValid}
                    onClick={() => this.handleTrigger()}
                    loading={this.state.isLoading}
                />
            </div>


            {!this.context.exportSimQeValid ?
                <Label size={'huge'} color='red' pointing="below">INVALID JSON</Label> : null}
            <AceEditor
                theme="monokai"
                style={{flex: 1}}
                mode='json'
                value={this.context.exportSimQe}
                placeholder='QueuedEvent'
                width="100%"
                onChange={(value) => this.handleQueuedEventChange(value)}
                readOnly={false}
            />
        </>
    }

}