import * as React from "react";
import {Accordion, Button, Grid, Header, Icon, Label, Modal} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {Queue, QueueWithEvent} from "../../../../../generated";
import AceEditor from "react-ace";
import {LogView} from "../../logView/LogView";
import TestSuppressionScriptModalTabView from "./TestSuppressionScriptModalTabView";

export interface TestSuppressionScriptModalProps {
    isOpen: boolean
    onClose: (success) => void
    queue: Queue

}

export interface TestSuppressionScriptModalState {

}

export class TestSuppressionScriptModal extends React.Component
    <TestSuppressionScriptModalProps, TestSuppressionScriptModalState> {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    handleClose = (success) => {

        this.props.onClose(success)
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Test Suppression Script'/>
            <Modal.Content>
                <TestSuppressionScriptModalTabView queue={this.props.queue}/>

                <Button onClick={() => this.handleClose(false)}>Close</Button>

            </Modal.Content>
        </Modal>

    }

}

