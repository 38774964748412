import * as React from "react";
import {Button, Header, Icon, Label, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import {FtpExplorerShowFileSystemEntry} from "../FtpExplorerShowFileSystemEntry";
import {DeleteFileResponse, FileSystemEntry} from "../../../../../generated-ftp/api";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {ftp} from "../../../../../xconvert-ftp";
import {IfBox} from "../../../../style/if";

export interface FtpExplorerFileDeleteModalProps {
    isOpen: boolean
    onClose: (success: Boolean) => void
    file: FileSystemEntry
    path: string
}

export class FtpExplorerFileDeleteModal extends React.Component<FtpExplorerFileDeleteModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            response: null
        }
    }

    handleClose = () => {
        this.props.onClose(false)
    }

    async handleDelete() {
        let path = this.props.path + "/" + this.props.file.name

        let auth = backend.withTokenAuthHeader(authentication.token)

        let response: DeleteFileResponse
        try {
            response = (await ftp.ftpApi.deleteFile(path, this.context.companyId, auth))
        } catch (ex) {
            response = {}
            response.success = false
            response.errorMessage = "Exception occured while call: " + ex.statusText

        }

        if (response.success == true) {
            this.props.onClose(true)

        } else {
            this.setState({response: response.errorMessage})
        }

    }


    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Delete File'/>
            <Modal.Content>
                <p>Are you sure you want to delete the following file?</p>
                <p>This action can't be reversed!</p>
                <FtpExplorerShowFileSystemEntry file={this.props.file}/>
                <Button onClick={() => this.handleDelete()}><Icon name='trash' color='red'/> delete</Button>
                <Button onClick={() => this.handleClose()}>cancel</Button>
                <IfBox shouldShow={this.state.response != null}>
                    <Label color='red'>{this.state.response}</Label>
                </IfBox>
            </Modal.Content>
        </Modal>
    }

}