import * as React from "react";
import Box from "../style/box";
import {Input, Label, Segment} from "semantic-ui-react";


export interface SecondFactorAuthProps {
    validateTotp: (totp: string) => (void)
}

export function SecondFactorAuth(props: SecondFactorAuthProps) {

    const [totpSent, setTotpSent] = React.useState(false)
    const [totpValid, setTotpValid] = React.useState(false)
    const [totp, setTotp] = React.useState("")


    function drawTotpInput() {
        return <div style={{flex: 1, flexDirection: "column"}}>
            <Input
                onKeyPress={(event: any) => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                placeholder='6-digit code'
                value={totp}
                autoFocus={true}
                onChange={(event) => {
                    let newTotp = event.target.value
                    setTotp(newTotp)
                    if (newTotp.length == 6) {
                        props.validateTotp(newTotp)
                    }
                    if (newTotp.length < 6) {
                        setTotpSent(false)
                        setTotpValid(false)
                    }
                }}
            />
            <br/>
            {totpSent && totpValid && <>
                <Label
                    pointing={"above"}
                    color={"green"}
                    size={"large"}
                >
                    Valid
                </Label>
                <p>Der Code ist correct. Multi-Faktor-Authentifizierung (MFA) wurde für Sie aktiviert! Sie können diese
                    Fenster nun schließen.</p>
            </>
            }
            {totpSent && !totpValid && <Label
                pointing={"above"}
                color={"red"}
                size={"large"}
            >
                Error
            </Label>
            }

        </div>
    }


    return <Segment stacked>
            <h1>2FA</h1>
            {drawTotpInput()}
        </Segment>

}