import * as React from "react";
import {useState} from "react";
import {OutputReport, ReportCell, ReportRow} from "../../../../../generated";
import {Dropdown, Grid, Label} from "semantic-ui-react";

export interface ReportScriptResultsProps {
    response: OutputReport
}

export function ReportScriptResults(props: React.PropsWithChildren<ReportScriptResultsProps>) {

    const [page, setPage] = useState<number>(0)
    const [response, setResponse] = useState<OutputReport | null>(null)

    React.useEffect(() => {
        setPage(0)
        setResponse(props.response)
    }, [props.response])

    function pageDropDownOpts() {
        let opt = []
        let i = 0

        response?.pages.forEach((page) => {
            opt.push({key: i, text: i +": " + page.name, value: i})
            i++;
        })

        return opt
    }


    function drawHeader(header: Array<ReportCell>) {
        let columns = []
        header.forEach(h => {columns.push(<Grid.Column>{h.stringContent}</Grid.Column>)})
        return <Grid.Row style={{fontWeight: "bold"}}>
            {columns}
        </Grid.Row>
    }

    function drawDataLines(dataLines: Array<ReportRow>) {
        let rows = []
        dataLines.forEach(dl => {
            let columns = []
            dl.cells.forEach(c => {
                let cellStyle = {}
                if(c.color) {
                    cellStyle = {
                        backgroundColor: c.color
                    }
                }
                columns.push(<Grid.Column style={cellStyle}>{c.stringContent}</Grid.Column>)

            })
            let rowStyle = {}
            if(dl.color) {
                rowStyle = {
                    backgroundColor: dl.color
                }
            }
            rows.push(<Grid.Row style={rowStyle}>
                {columns}
            </Grid.Row>)
        })
        return rows

    }

    function drawPage() {
        if (response && response.pages.length >= (page + 1)) {

            let currentPage = response.pages[page]
            let header = currentPage.header
            let dataLines = currentPage.dataLines

            return <div style={{width: "99%"}}>
                <h3>{currentPage.name}</h3>
                <Grid padded="horizontally" stackable columns='equal' centered celled>
                    {drawHeader(header)}
                    {drawDataLines(dataLines)}
                </Grid>
            </div>
        } else {
            return <p>No such page!</p>
        }
    }

    return <>
        <Label>Page: </Label>
        <Dropdown
            selectOnBlur={false}
            options={pageDropDownOpts()}
            selection
            value={page}
            onChange={(evt, data) => setPage(data.value as number)}
        />

        {drawPage()}
    </>
}