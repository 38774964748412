import * as React from "react";
import {useEffect} from "react";
import {CreateOrModifyTransportOrderDryRunRequest, TransportOrder} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {Dimmer, Loader, Modal} from "semantic-ui-react";
import DiffViewer from "react-diff-viewer";
import {configStorage} from "../../../../../ConfigStorage";
import {ConfigContext} from "../../../context/ConfigContext";
import {CustomDiff} from "../../configChange/configEdit/diff/CustomDiff";


export interface ImportSimCompareOrderChangeModalProps {
    isOpen: boolean
    onClose: () => void
    order: TransportOrder
}

export function ImportSimCompareOrderChangeModal(props: React.PropsWithChildren<ImportSimCompareOrderChangeModalProps>) {

    const context = React.useContext(ConfigContext)

    const [auth, setAuth] = React.useState<any>(backend.withTokenAuthHeader(authentication.token))

    const [loadingOriginal, setLoadingOriginal] = React.useState<boolean>(true)
    const [loadingCleaned, setLoadingCleaned] = React.useState<boolean>(true)

    const [originalOrder, setOriginalOrder] = React.useState<TransportOrder>(null)
    const [cleanedOrder, setCleanedOrder] = React.useState<TransportOrder>(null)

    const [noOriginal, setNoOriginal] = React.useState<boolean>(false)

    useEffect(() => {
        console.log("loading ")

        getOriginal()
        getCleaned()

    }, [props.isOpen]);

    async function getOriginal() {

        try {
            let response = await backend.transportOrderApi.fetchTransportOrderByExternalId(props.order.externalTransportOrderId, auth)

            setOriginalOrder(response)
            setLoadingOriginal(false)

        } catch (e) {
            console.error(e)
            if(e.status === 404) {
                setNoOriginal(true)
            } else {
                throw e
            }
            setLoadingOriginal(false)
        }
    }

    async function getCleaned() {
        let request = {} as CreateOrModifyTransportOrderDryRunRequest
        request.orders = [props.order]
        request.config =  (await configStorage.getElement(context.companyId)).config
        let rawResp = await fetch("/api/internal/createOrModifyDryRun", {
            method: 'POST',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8",
                "x-company-id": context.companyId,
            },
            body: JSON.stringify(request)
        })

        let responseText = await rawResp.text()
        let response = JSON.parse(responseText)

        setCleanedOrder(response.transportOrderResponses[0].transportOrder)
        setLoadingCleaned(false)
    }

    function drawDiff() {
        let newOrder = JSON.stringify(cleanedOrder, null, 4)
        let original = JSON.stringify(originalOrder, null, 4)
        if(noOriginal) {
            original = "No original order found"
        }

        return <CustomDiff
            oldJson={original}
            newJson={newOrder}
        />
    }

    function loadingDone() {
        return !loadingOriginal && !loadingCleaned
    }

    return <Modal
        open={props.isOpen}
        onClose={props.onClose}
        size={"fullscreen"}
        closeIcon
    >
        <Modal.Header>Changes to Order {props.order._id}</Modal.Header>
        <Modal.Content>
            <Modal.Description>

                {!loadingDone() && <Dimmer active inverted>
                    <Loader inverted content='Loading'/>
                </Dimmer>}

                {loadingDone() && drawDiff()}

            </Modal.Description>
        </Modal.Content>
    </Modal>


}