import * as React from "react";
import { useEffect, useState } from 'react';
import { marked } from 'marked';
import {authentication} from "../../../../../authentication";
import {backend} from "../../../../../xconvert-backend";

export function ReleaseNotePage() {
    const [content, setContent] = useState<string>('<html><body><h1>Loading...</h1></body></html>');

    useEffect(() => {
        async function markingContent(releaseNotes: string) {
            setContent(await marked(releaseNotes));
        }

        fetchReleaseNotes().then((releaseNotes) => {
            markingContent(releaseNotes).then(r => {});
        })
    }, []);

    function getStage() {
        let url = window.location.href

        if(url.includes("cm.logenios")) {
            return "Prod"
        } else if(url.includes("cm.beta")) {
            return "Beta"
        }
        return "Alpha"

    }

    async function fetchReleaseNotes() {

        let auth = backend.withTokenAuthHeader(authentication.token)
        return backend.internalApi.fetchReleaseNotesFromGitlabWiki(getStage(), auth).then((response) => {
            return response.content
        })

    }

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </>
    );
}
