import * as React from "react";
import { Tab } from "semantic-ui-react";
import { LogView } from "../../logView/LogView";
import {ImportSimTransportOrderOutputTable} from "./ImportSimTransportOrderOutputTable";
import {ImportSimOutput} from "./ImportSimOutput";

const panes = [
    { menuItem: 'Output', render: props => <Tab.Pane><ImportSimOutput outputTO={props.outputTO} selectedFileName={props.selectedFileName}/></Tab.Pane> },
    { menuItem: 'TransportOrders', render: props => <Tab.Pane><ImportSimTransportOrderOutputTable outputTO={props.outputTO}/></Tab.Pane> },
    { menuItem: 'Logs', render: props => <Tab.Pane><LogView query={props.xRequestId} origin={"importSim"}/></Tab.Pane> },
]

const ImportSimOutputTabMenu = props => <Tab
    className="flexTab"
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.importSimOutputTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("importSimOutputTab", data.activeIndex)
    }}
/>

export default ImportSimOutputTabMenu