import * as polyline from "@mapbox/polyline";
import * as Leaflet from "leaflet";
import {Icon, LatLng, LatLngBounds, LatLngExpression} from "leaflet";
import * as React from "react";
import {FeatureGroup, Map as LeafletMap, Marker, Polyline, Popup, ScaleControl, TileLayer} from 'react-leaflet';
import {Button, Checkbox, Divider, Grid, Icon as SemanticIcon, Label, Loader, Segment} from 'semantic-ui-react';
import {v4 as uuid} from 'uuid';
import {
    FetchHistoryRequest,
    StopLocation, TourPolylineResponse,
    TransportOrder,
    VehiclePolyline,
    VehicleTrackSegment
} from "../../../../../generated";
import SortDirectionEnum = FetchHistoryRequest.SortDirectionEnum;
import EstimatedStateEnum = VehicleTrackSegment.EstimatedStateEnum;

import {authentication} from "../../../../../authentication";
import {backend} from "../../../../../xconvert-backend";
import {fmtDatetime} from "../../../../../utils";
import {IfBox} from "../../../../style/if";
import {getFormattedActionKindStatusEnumByIndex} from "../../../../../format";
import {CustomTableComponent, FetchResult, TableDataSource} from "../../../../ui/customTable/customTableComponent";
import {TILE_SERVER_ACCESS_TOKEN, TILE_SERVER_URL, TILE_STYLE} from "../../../../../mapUtils";
import {StopListMarkers} from "../../../../ui/map/stop_list_markers";
import {DirectionPolyline} from "../../../../ui/map/DirectionPolyline";
import {AddNewPositionModal} from "../modals/AddNewPositionModal";
import Notifications, {notify} from 'react-notify-toast';


class TransportOrderMapFetcher implements TableDataSource<VehicleTrackSegmentWithPreviousCity> {

    vehicleTrackSegments: VehicleTrackSegmentWithPreviousCity[]
    resetPagination: boolean = false

    constructor(segments: VehicleTrackSegmentWithPreviousCity[], resetPagination = false) {
        this.vehicleTrackSegments = segments
        this.resetPagination = resetPagination;
    }

    async fetchData(currentPage: number, numRowsToTake: number, sortColumn: any = 'created', sortDirection: SortDirectionEnum = SortDirectionEnum.DESC): Promise<FetchResult<VehicleTrackSegmentWithPreviousCity>> {
        let data: VehicleTrackSegmentWithPreviousCity[] = this.vehicleTrackSegments
        try {

            this.resetPagination = false;
            return {
                totalRecords: data.length ?? 0,
                data: data
            };
        } catch (ex) {
            console.log(ex)
        }
        return {
            totalRecords: 0,
            data: []
        };
    }
}

export interface TransportOrderMapProps {
    order: TransportOrder,
    polyline: TourPolylineResponse | null,
    predefinedPolyline: boolean,
}

export interface TransportOrderMapState {
    positions: number[][] | null
    isTrackDataLoading: boolean
    vehiclePolylines: any | null
    selectedVehiclePositionTimestamp: Date | undefined
    selectedVehicle: string | null
    selectedVehiclePlate: string | null
    selectedSegment: VehicleTrackSegment | null
    selectedSegmentIndex: number | undefined
    segments: VehicleTrackSegment[] | null
    fetcher: TransportOrderMapFetcher
    autoZoom: boolean
    arrivalRadius?: number
    newPosition: LatLng | null
}


class VehicleTrackSegmentWithPreviousCity {
    startCity?: string
    endCity?: string
    segment: VehicleTrackSegment

    constructor(segment: VehicleTrackSegment, startCity?: string, endCity?: string) {
        this.startCity = startCity
        this.endCity = endCity
        this.segment = segment
    }
}

export class TransportOrderMapComponent extends React.Component<TransportOrderMapProps, TransportOrderMapState> {

    segmentPolylineRef = React.createRef<FeatureGroup>();
    mapRef = React.createRef<LeafletMap>();
    defaultZoomGroup = React.createRef<FeatureGroup>();
    gridRef = React.createRef<CustomTableComponent<any>>();


    constructor(props: TransportOrderMapProps) {
        super(props)

        this.state = {
            positions: null,
            isTrackDataLoading: false,
            vehiclePolylines: null,
            selectedVehicle: null,
            selectedVehiclePlate: null,
            segments: null,
            selectedSegment: null,
            selectedSegmentIndex: undefined,
            selectedVehiclePositionTimestamp: undefined,
            fetcher: new TransportOrderMapFetcher([]),
            autoZoom: false,
            newPosition: null
        }
    }

    defaultPos(): LatLngExpression {
        let urlParameters = this.fetchUrlParameters()

        if (urlParameters.lat && urlParameters.lng) {
            return new LatLng(urlParameters.lat, urlParameters.lng)
        } else {
            return this.calculateMapCenterPosition()
        }
    }

    getZoomLevelFromUrlOrDefault(): number {
        return this.fetchUrlParameters()?.zoom ?? 7
    }

    onZoomOrCenterUpdate() {
        let zoomValue = this.mapRef.current?.leafletElement?.getZoom()
        let lat = this.mapRef.current?.leafletElement?.getCenter().lat
        let lng = this.mapRef.current?.leafletElement?.getCenter().lng

        let tempUrl = window.location.href.split("#")[0]
        // window.history.pushState(name, name, `${tempUrl}#map#${zoomValue}#${lat}#${lng}`)
        window.history.replaceState(null, "map", `${tempUrl}#map#${zoomValue}#${lat}#${lng}`)

    }

    fetchUrlParameters() {
        let url: string[] = window.location.href.split("#")
        return {
            "zoom": url[2] ? +url[2] : undefined,
            "lat": url[3] ? +url[3] : undefined,
            "lng": url[4] ? +url[4] : undefined
        };
    }

    async componentDidMount() {
        await this.fetchTrackingPolyLine()
        await this.fetchGeoFenceRadius()
    }

    async componentDidUpdate(prevProps: Readonly<TransportOrderMapProps>, prevState: Readonly<TransportOrderMapState>, snapshot?: any) {
        if (prevProps.order._id !== this.props.order._id) {
            await this.fetchTrackingPolyLine()
            await this.fetchGeoFenceRadius()
        }
    }

    componentWillUnmount() {
    }

    async fetchGeoFenceRadius() {
        try {
            if (this.props.order._id) {
                const auth = backend.withTokenAuthHeader(authentication.token)
                let geoStatusInformation = await backend.internalApi.fetchGeoStatusInformation(this.props.order._id, auth)
                this.setState({arrivalRadius: geoStatusInformation.statusByVehiclePositionsConfig?.arrivalRadiusInMeter})
            } else {
                throw "No TransportOrder._id"
            }
        } catch (ex) {
            console.error("Failed to fetch arrival radius")
            this.setState({arrivalRadius: null})
        }

    }

    async fetchTrackingPolyLine() {

        const header = backend.withTokenAuthHeader(authentication.token)

        try {
            if (this.props.order._id) {
                this.setState({isTrackDataLoading: true})

                var lineData = this.props.polyline

                if (!this.props.predefinedPolyline) {
                    lineData = await backend.trackingApi.getTourPolyline(this.props.order._id, 0, true, true, header)
                }

                lineData.vehiclePolylines = lineData.vehiclePolylines?.filter(vp => vp.vehicle)
                let firstVehiclePolyLine = lineData.vehiclePolylines?.find(vp => {
                    if (vp.vehicle?._id == this.props.order.assignedVehicle?._id) {
                        return vp
                    }
                })
                if (firstVehiclePolyLine == undefined) {
                    firstVehiclePolyLine = lineData.vehiclePolylines?.[0]
                }

                if (firstVehiclePolyLine?.polyline && firstVehiclePolyLine.vehicle?._id && firstVehiclePolyLine.vehicleTrackSegments) {
                    const polyLineData = polyline.decode(firstVehiclePolyLine.polyline)
                    console.log("Loaded polyline")
                    if (polyLineData.length > 0) {

                        lineData.vehiclePolylines?.forEach((vp) => {
                            if (vp.vehicleTrackSegments) {
                                vp.vehicleTrackSegments
                            }
                        })
                        this.setState({
                            positions: polyLineData,
                            isTrackDataLoading: false,
                            vehiclePolylines: lineData.vehiclePolylines,
                            selectedVehicle: firstVehiclePolyLine.vehicle._id,
                            selectedVehiclePlate: firstVehiclePolyLine.vehicle.numberPlate ?? firstVehiclePolyLine.vehicle.externalVehicleId ?? "Unbekannt",
                            selectedVehiclePositionTimestamp: firstVehiclePolyLine.to,
                            segments: this.filterSegments(firstVehiclePolyLine.vehicleTrackSegments),
                            fetcher: new TransportOrderMapFetcher(this.getSegmentTableData(firstVehiclePolyLine.vehicleTrackSegments))
                        })
                    } else {
                        throw "Polyline has no data"
                    }
                } else {
                    throw "Necessary polyline data not available"
                }
            } else {
                throw "No TransportOrder._id"
            }
        } catch (ex) {
            console.log(ex);
            this.setState({positions: null, isTrackDataLoading: false})
        }
    }

    toggleVehicleTrack(vp: VehiclePolyline) {
        if (vp.polyline && vp.vehicle?._id && vp.vehicle?.numberPlate && vp.vehicleTrackSegments) {
            const polyLineData = polyline.decode(vp.polyline)
            if (polyLineData.length > 0) {

                this.setState({
                    positions: polyLineData,
                    selectedVehicle: vp.vehicle._id,
                    selectedVehiclePlate: vp.vehicle.numberPlate,
                    segments: this.filterSegments(vp.vehicleTrackSegments),
                    selectedSegment: null,
                    fetcher: new TransportOrderMapFetcher(this.getSegmentTableData(vp.vehicleTrackSegments))
                }, () => {
                    console.log(this.defaultZoomGroup.current)
                    if (this.defaultZoomGroup.current) {
                        this.mapRef.current?.leafletElement.fitBounds(this.defaultZoomGroup.current.leafletElement.getBounds().pad(1.1));
                        console.log("Fitting bounds to ", this.defaultZoomGroup.current.leafletElement.getBounds().pad(1.1))
                    } else {
                        console.log("Cannot fitBounds, no defaultZoomGroup.current");
                    }
                })
            } else {
                console.log("Polyline has no data");
            }
        } else {
            console.log("Necessary polyline data not available");
        }
    }

    filterSegments(segments: VehicleTrackSegment[]) {
        return segments.filter(segment => {
            if (segment.estimatedState != EstimatedStateEnum.ARRIVED) {
                return segment
            }
        })
    }

    getSegmentTableData(segments: VehicleTrackSegment[]): VehicleTrackSegmentWithPreviousCity[] {
        let filterSegments = segments.filter(segment => {
            if (segment.estimatedState != EstimatedStateEnum.ARRIVED) {
                return segment
            }
        })
        let segmentsWithCities: VehicleTrackSegmentWithPreviousCity[] = []
        let previousSegment: VehicleTrackSegmentWithPreviousCity | undefined = undefined
        filterSegments.forEach(segment => {
            let segmentWithCity = new VehicleTrackSegmentWithPreviousCity(segment)
            if (segment.estimatedState == EstimatedStateEnum.DRIVING) {
                if (previousSegment && previousSegment.segment.estimatedState == EstimatedStateEnum.STANDING) {
                    segmentWithCity.startCity = previousSegment.segment.reverseResolvedAddresses ? previousSegment.segment.reverseResolvedAddresses[previousSegment.segment.reverseResolvedAddresses.length - 1]?.city : undefined
                    segmentsWithCities.push(previousSegment)
                }
            } else {
                if (previousSegment && previousSegment.segment.estimatedState == EstimatedStateEnum.DRIVING) {
                    previousSegment.endCity = segmentWithCity.segment.reverseResolvedAddresses ? segmentWithCity.segment.reverseResolvedAddresses[segmentWithCity.segment.reverseResolvedAddresses.length - 1]?.city : undefined
                    segmentsWithCities.push(previousSegment)
                }
            }
            previousSegment = segmentWithCity
        })
        if (previousSegment) segmentsWithCities.push(previousSegment)

        return segmentsWithCities
    }

    calculateMapBounds() {
        let calcbounds = {lat1: 90, lat2: 0, long1: 180, long2: 0}

        this.state.positions?.forEach((pos: any) => {
            if (calcbounds.lat1 == 90) {
                calcbounds.lat1 = pos[0]
                calcbounds.lat2 = pos[0]
                calcbounds.long1 = pos[1]
                calcbounds.long2 = pos[1]
            }
            if (pos[0] < calcbounds.lat1) {
                calcbounds.lat1 = pos[0]
            }
            if (pos[0] > calcbounds.lat2) {
                calcbounds.lat2 = pos[0]
            }
            if (pos[1] < calcbounds.long1) {
                calcbounds.long1 = pos[1]
            }
            if (pos[1] > calcbounds.long2) {
                calcbounds.long2 = pos[1]
            }
        });
        this.props.order.transports?.forEach(function (transport) {
            transport.stops?.forEach(function (stop) {
                if (stop?.coordinates?.latitude && stop.coordinates?.longitude) {
                    if (stop.coordinates.latitude < calcbounds.lat1) {
                        calcbounds.lat1 = stop.coordinates.latitude
                    }
                    if (stop.coordinates.latitude > calcbounds.lat2) {
                        calcbounds.lat2 = stop.coordinates.latitude
                    }
                    if (stop.coordinates.longitude < calcbounds.long1) {
                        calcbounds.long1 = stop.coordinates.longitude
                    }
                    if (stop.coordinates.longitude > calcbounds.long2) {
                        calcbounds.long2 = stop.coordinates.longitude
                    }
                }
            })
        })
        console.log(calcbounds);

        return calcbounds
    }

    calculateMapCenterPosition(): Leaflet.LatLngExpression {
        const calcbounds = this.calculateMapBounds()
        return [(calcbounds.lat1 + calcbounds.lat2) / 2, (calcbounds.long1 + calcbounds.long2) / 2]
    }

    renderCurrentPosition() {
        if (!this.state.positions) {
            return null
        }
        const pos = this.state.positions[this.state.positions.length - 1]
        const positionIcon = new Icon({
            iconUrl: "/assets/position_marker.svg",
            iconSize: [40, 60],
            iconAnchor: [20, 60],
            popupAnchor: [0, -40]
        })

        return (< Marker position={pos as Leaflet.LatLngTuple} icon={positionIcon}>
            <Popup>
                <strong>{this.state.selectedVehiclePlate && this.state.selectedVehiclePlate}</strong><br/>
                <p>current vehicle positions</p>
                {fmtDatetime(this.state.selectedVehiclePositionTimestamp)}
            </Popup>
        </Marker>)
    }

    renderFirstPosition() {
        if (!this.state.positions) {
            return null
        }
        const pos = this.state.positions[0]
        const startIcon = new Icon({
            iconUrl: "/assets/start_marker.svg",
            iconSize: [40, 60],
            iconAnchor: [20, 60],
            popupAnchor: [0, -40]
        })

        return (< Marker position={pos as Leaflet.LatLngTuple} icon={startIcon}>
            <Popup>
                <p>first vehicle position</p> {this.state.selectedVehiclePlate && this.state.selectedVehiclePlate}
            </Popup>
        </Marker>)
    }

    renderAllStops() {

        let stops: StopLocation[] = []
        this.props.order.transports?.forEach(function (transport) {
            transport.stops?.forEach(function (stop) {
                stops.push(stop)
            })
        })

        return <StopListMarkers stops={stops} arrivalRadius={this.state.arrivalRadius}/>
    }

    renderPolyline() {
        let color = "#29968B"

        return (this.state.segments?.map((segment, idx) => {
            let standingLine
            let drivingLine
            let lineRef
            if (segment == this.state.selectedSegment || !this.state.selectedSegment) {
                color = "#29968B"
                lineRef = this.segmentPolylineRef
            } else {
                color = "#9c9c9c"
            }
            if (segment.estimatedState == EstimatedStateEnum.DRIVING && segment.polyline) {
                let polylinePositions = polyline.decode(segment.polyline) as Leaflet.LatLngExpression[]
                standingLine = (
                    <DirectionPolyline positions={polylinePositions} mapRef={this.mapRef} distance={segment.distanceInM}
                                       color={color} onClick={() => this.selectSegment(idx, segment, true)}/>)
            }
            if (segment.estimatedState == EstimatedStateEnum.STANDING && segment.polyline) {
                let polylinePositions = polyline.decode(segment.polyline) as Leaflet.LatLngExpression[]
                drivingLine = (<Polyline positions={polylinePositions} color={color}
                                         onclick={() => this.selectSegment(idx, segment, true)}>
                </Polyline>)

            }
            return <FeatureGroup ref={lineRef}>{standingLine}{drivingLine}</FeatureGroup>
        }))
    }

    adjustBounds(bounds: LatLngBounds): LatLngBounds {
        let center = bounds.getCenter()
        let x1 = Leaflet.latLng(center.lat + 0.001, center.lng + 0.001)
        let x2 = Leaflet.latLng(center.lat - 0.001, center.lng - 0.001)
        bounds.extend(x1)
        bounds.extend(x2)

        return bounds
    }

    onFeatureGroupAdd(e: Leaflet.LayerEvent, calledFrom: string) {
        console.log("onFeatureGroupAdd called from ", calledFrom)

        let bounds = e.target.getBounds()
        if (bounds.isValid()) {

            bounds = this.adjustBounds(bounds);
            console.log(calledFrom, "fitting to bounds", bounds, "Original bounds were ", e.target.getBounds())

            // @ts-ignore
            this.mapRef.current.leafletElement.fitBounds(bounds);
        }
    }

    renderStopNotifications() {
        let stops: StopLocation[] = [];
        this.props.order.transports?.forEach(function (transport) {
            transport.stops?.forEach(function (stop, i) {
                if (!stop.coordinates?.latitude && !stop.coordinates?.longitude) {
                    stops.push(stop)
                }
            })
        })
        let index = 0
        return stops.map((stop: StopLocation) => {
            index++
            return <Label key={stop.stopId}>
                {index + '.'}
                <IfBox shouldShow={stop.name != null}>
                    {stop.name}<br/>
                </IfBox>
                <IfBox shouldShow={stop.name2 != null}>
                    {stop.name2}<br/>
                </IfBox>
                <IfBox shouldShow={stop.street != null}>
                    {stop.street}<br/>
                </IfBox>
                <IfBox shouldShow={stop.zipCode != null || stop.city != null}>
                    {stop.zipCode} {stop.city}<br/>
                </IfBox>
                <br/>
                Action: {getFormattedActionKindStatusEnumByIndex(stop.actions, 0)}<br/>
                Error: <span style={{color: 'red'}}> No coordinates found!</span>
            </Label>
        });
    }

    selectSegment(idx: number, segment: VehicleTrackSegment, fromPolyline: boolean) {
        this.setState({selectedSegment: segment, selectedSegmentIndex: idx}, () => {
            console.log("Ref is ", this.segmentPolylineRef.current?.leafletElement.getBounds())
            const bounds = this.segmentPolylineRef.current?.leafletElement.getBounds();
            if (fromPolyline == true) {
                document.getElementById(idx.toString())?.scrollIntoView(false)
            }
            if (this.state.autoZoom == true && bounds) {
                // @ts-ignore
                this.mapRef.current.leafletElement.fitBounds(this.adjustBounds(bounds));
            }
        })
    }

    calcTimeDiff(startDate: Date, endDate: Date) {
        let diffInMins = Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60))
        const hours = Math.floor(diffInMins / (60))
        const minutes = diffInMins - (hours * 60)
        return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`
    }

    renderSegment() {
        const columns = [
            {
                id: 'from',
                width: 117,
                Header: 'from',
                accessor: (d: VehicleTrackSegmentWithPreviousCity) => d.segment.from ? new Date(d.segment.from).toLocaleString() : null
            }, {
                id: 'duration',
                width: 50,
                Header: 'duration',
                accessor: (d: VehicleTrackSegmentWithPreviousCity) => d.segment.from && d.segment.to ? this.calcTimeDiff(d.segment.from, d.segment.to) : null
            }, {
                id: 'status',
                width: 50,
                Header: 'status',
                accessor: (d: VehicleTrackSegmentWithPreviousCity) => d.segment.estimatedState == EstimatedStateEnum.STANDING ?
                    <SemanticIcon className="coffee" color={"grey"}></SemanticIcon> :
                    <SemanticIcon className="truck" color={"grey"}></SemanticIcon>
            }, {
                id: 'description',
                width: 125,
                Header: 'description',
                accessor: (d: VehicleTrackSegmentWithPreviousCity) => {
                    if (d.segment.estimatedState == EstimatedStateEnum.STANDING) {
                        let location = d.segment.reverseResolvedAddresses ? d.segment.reverseResolvedAddresses[d.segment.reverseResolvedAddresses.length - 1]?.city : null
                        return location ? "standing in " + location : 'standing'
                    } else {
                        let startLocationString = d.startCity ? "from " + d.startCity + " " : ""
                        let endLocationString = d.endCity ? "to " + d.endCity : ""
                        let distance = d.segment.distanceInM ? Math.round(d.segment.distanceInM / 100) / 10 + " km" : null

                        return distance + " " + startLocationString + endLocationString
                    }
                }
            }
        ]
        let take = this.state.segments?.length
        return <CustomTableComponent
            ref={this.gridRef}
            cols={columns}
            name={'VehicleTrackSegments'}
            fetcher={this.state.fetcher}
            ignorePagination={true}
            selectedRowIndex={this.state.selectedSegmentIndex}
            configs={
                {
                    take: take,
                    page: 0,
                    pages: -1,
                    sortBy: "created",
                    sortOrder: "desc"
                }
            }
            onRowClick={(rowInfo) => {
                if (rowInfo && rowInfo.original) {
                    this.selectSegment(rowInfo.index, rowInfo.original.segment, false)
                    this.setState({selectedSegmentIndex: rowInfo.index})
                }
            }}
            noFixedHeight={true}
        >
        </CustomTableComponent>
    }

    render() {


        if (this.state.isTrackDataLoading) {
            return <Loader active inline='centered'/>
        }

        if (this.state.positions == null && this.props.order == null)
            return <Segment><p>no map data available</p></Segment>

        return <>
            <div style={{marginBottom: "2px"}}>
                {this.renderStopNotifications()}
            </div>

            <div className={"flex1"} style={{flexDirection: "row"}}>
                <div className={"flex1"}>
                    <LeafletMap key={uuid()} center={this.defaultPos()} style={{height: "100%"}} ref={this.mapRef}
                                zoom={this.getZoomLevelFromUrlOrDefault()}
                                onzoomend={() => this.onZoomOrCenterUpdate()}
                                ondragend={() => this.onZoomOrCenterUpdate()}
                                onclick={(event) => this.setState({newPosition: event.latlng})}>
                        <TileLayer url={TILE_SERVER_URL} id={TILE_STYLE} accessToken={TILE_SERVER_ACCESS_TOKEN}
                                   tileSize={512} zoomOffset={-1}
                                   attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                        />
                        <ScaleControl metric/>
                        <FeatureGroup key={this.state.selectedVehicle ?? uuid()} ref={this.defaultZoomGroup}
                                      onlayeradd={(e) => {/*this.onFeatureGroupAdd(e, "RenderFullPolyline")}*/
                                      }}>
                            {this.renderAllStops()}
                            {this.state.positions && this.renderFirstPosition()}
                            {this.state.positions && this.renderCurrentPosition()}
                            {this.state.positions && this.renderPolyline()}
                        </FeatureGroup>
                    </LeafletMap>
                </div>
                <div className={"flex1"} style={{marginLeft: "5px"}}>
                    <Segment style={{marginBottom: "5px", padding: "5px"}}>
                        <Checkbox toggle={true} checked={this.state.autoZoom} size="mini" key="autozoombutton"
                                  onClick={() => {
                                      this.setState({autoZoom: !this.state.autoZoom})
                                  }} floated="left" label={"Auto-Zoom"}></Checkbox>
                        <IfBox shouldShow={this.state.vehiclePolylines != null}>
                            <Button.Group size="mini" floated="right">
                                {this.state.vehiclePolylines &&
                                    this.state.vehiclePolylines.map((v: VehiclePolyline) => {
                                        return <Button key={v.vehicle?.numberPlate ?? v.vehicle?.externalVehicleId ?? "?"}
                                            onClick={() => this.toggleVehicleTrack(v)}
                                            active={this.state.selectedVehicle == v.vehicle?._id}>
                                            {v.vehicle?.numberPlate ?? v.vehicle?.externalVehicleId ?? "?"}
                                        </Button>
                                    })
                                }
                            </Button.Group>
                        </IfBox>
                    </Segment>
                    {/*<Divider clearing={true} hidden={true} />*/}
                    <IfBox shouldShow={this.state.vehiclePolylines == null}>
                        <Segment style={{margin:"0", marginBottom: "5px", padding: "5px"}}>
                            {this.state.isTrackDataLoading ? <p>loading track data</p> : <p>no track data available</p>}
                        </Segment>
                    </IfBox>
                    <Segment style={{margin:"0", marginBottom: "5px", padding: "5px"}}>
                        {this.renderSegment()}
                    </Segment>
                    {/*<List selection verticalAlign='middle'>
                            {this.state.segments?.map((segment, idx) => this.renderSegment(idx, segment, this.state.segments?.length || 0))}
                        </List>*/}
                </div>
            </div>
            <AddNewPositionModal isOpen={this.state.newPosition != null} onClose={(updated) => {
                if (updated == true) {
                    notify.show('added Position', 'success', 3000, '#28f751');
                }
                if (updated == false) {
                    notify.show("ERROR: unable to add Position, please check the logs.", 'error', 5000, '#fc0303')
                }
                this.setState({newPosition: null})
            }} position={this.state.newPosition} order={this.props.order}/>
            <Notifications/>
        </>
    }
}
