import * as React from "react";
import {Button, Checkbox, Divider, Dropdown, DropdownItemProps, Header, Modal} from "semantic-ui-react";
import {
    FreightStatus,
    StopActionStatus,
    StopLocationStatus,
    TelematicState,
    TransportOrderChange,
    TransportOrderStatus
} from "../../../../../generated";
import {ConfigContext} from "../../../context/ConfigContext";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {IfBox} from "../../../../style/if";

export interface StatusUpdateModalProps {
    isOpen: boolean
    onClose: (status: string, changeType: TransportOrderChange.TypeEnum, telematicStateCode: TelematicState.StatusCodeEnum) => void
}
export interface StatusUpdateModalState {
    type: TransportOrderChange.TypeEnum
    dropdownItems: Array<DropdownItemProps>,
    status: string
    config: any
    telematicState: boolean
    telematicStateCode: TelematicState.StatusCodeEnum
}

export enum DropDownEnum {
    TRANSPORT_ORDER = "transportOrder",
    STOP_LOCATION = "stop location",
    STOP_ACTION = "stop action",
    FREIGHT = "freight",
}

export class StatusUpdateModal extends React.Component<StatusUpdateModalProps, StatusUpdateModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            type: null,
            dropdownItems: [],
            status: null,
            config: null,
            telematicState: false,
            telematicStateCode: null
        }
    }

    onSubmit() {
        console.log("submitting status: " + this.state.status + " telematicState: " + this.state.telematicStateCode)
        this.props.onClose(this.state.status, this.state.type, this.state.telematicStateCode)
    }

    handleClose = () => {
        this.props.onClose(null, null, null)
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    loadConfigSyncronosly() {
        // this one is a bit confusing, but it works dispite the async manner in a sync environment
        if (this.state.config == null) {
            let auth = backend.withTokenAuthHeader(authentication.token)

            return backend.internalApi.fetchCompanyConfiguration(this.context.companyId, auth).then((res) => {
                this.setState({ config: res })
                return res
            })

        } else {
            return this.state.config
        }

    }

    statusEnumToArray() {

        let enumValues: Array<DropdownItemProps> = [];

        switch (this.state.type) {
            case TransportOrderChange.TypeEnum.STATUSUPDATETRANSPORTORDER: {
                if (this.state.telematicState) {
                    Object.values(TelematicState.StatusCodeEnum).forEach(value => {
                        enumValues.push({
                            key: value,
                            value: value,
                            text: value
                        });
                    })
                } else {
                    Object.values(TransportOrderStatus.StatusCodeEnum).forEach(value => {
                        enumValues.push({
                            key: value,
                            value: value,
                            text: value,
                        });
                    })
                }
                break;
            }
            case TransportOrderChange.TypeEnum.STATUSUPDATESTOPLOCATION: {
                Object.values(StopLocationStatus.StatusCodeEnum).forEach(value => {
                    enumValues.push({
                        key: value,
                        value: value,
                        text: value,
                    });
                })
                break;
            }
            case TransportOrderChange.TypeEnum.STATUSUPDATESTOPACTION: {
                Object.values(StopActionStatus.StatusCodeEnum).forEach(value => {
                    enumValues.push({
                        key: value,
                        value: value,
                        text: value,
                    });
                })
                break;
            }
            case TransportOrderChange.TypeEnum.STATUSUPDATEFREIGHT: {
                Object.values(FreightStatus.StatusCodeEnum).forEach(value => {
                    enumValues.push({
                        key: value,
                        value: value,
                        text: value,
                    });

                })
                break;
            }
        }
        return enumValues

    }

    getStatus = (event, { value }) => {
        console.log(value);
        let status = event.target.textContent;
        console.log(status);
        if (this.state.telematicState) {
            this.setState({ telematicStateCode: status, status: null })
        } else {
            this.setState({ status: status, telematicStateCode: null })
        }
    }

    enumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(DropDownEnum).forEach(value => {
            enumValues.push({ key: value, value: value, text: value });
        })
        return enumValues
    }

    setStatus = (event, { value }) => {
        console.log(value);

        switch (event.target.textContent) {
            case DropDownEnum.TRANSPORT_ORDER:
                this.setState({ type: TransportOrderChange.TypeEnum.STATUSUPDATETRANSPORTORDER })
                break;

            case DropDownEnum.STOP_LOCATION:
                this.setState({ type: TransportOrderChange.TypeEnum.STATUSUPDATESTOPLOCATION })
                break;

            case DropDownEnum.STOP_ACTION:
                this.setState({ type: TransportOrderChange.TypeEnum.STATUSUPDATESTOPACTION })
                break;

            case DropDownEnum.FREIGHT:
                this.setState({ type: TransportOrderChange.TypeEnum.STATUSUPDATEFREIGHT })
                break;
        }
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="inverted"
            size='small'>
            <Header icon='browser' content='StatusUpdate' />
            <Modal.Content>
                <p>Update
                <Dropdown
                    selectOnBlur={false}
                        placeholder='Select'
                        search
                        selection
                        options={this.enumToArray()}
                        clearable
                        onChange={this.setStatus}
                    />
                Status</p>

                <IfBox shouldShow={this.state.type == TransportOrderChange.TypeEnum.STATUSUPDATETRANSPORTORDER} >
                    <p> Status
                    <Checkbox
                            disabled={this.state.type != TransportOrderChange.TypeEnum.STATUSUPDATETRANSPORTORDER}
                            type='checkbox'
                            toggle
                            onChange={() => this.setState({ telematicState: !this.state.telematicState })}
                        />
                    TelematicState
                    </p>
                </IfBox>

                <Divider />

                <Dropdown
                    selectOnBlur={false}
                    disabled={this.state.type == null}
                    placeholder='Select Status'
                    search
                    selection
                    options={this.statusEnumToArray()}
                    clearable
                    onChange={this.getStatus}
                />

                <Divider />

                <Button
                    disabled={this.state.status == null && this.state.telematicStateCode == null}
                    onClick={(evt) => this.onSubmit()} >Submit</Button>
                <Button onClick={(evt) => this.handleClose()} >Cancel</Button>


            </Modal.Content>
        </Modal >
    }
}