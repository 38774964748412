import * as React from "react";
import {DiscoveryServiceRequest, QueryRequest, QueryResponse} from "../../../../generated";
import {
    Button,
    Checkbox,
    Dropdown,
    DropdownItemProps,
    Grid,
    Header,
    Icon,
    Input,
    Popup,
    Segment
} from "semantic-ui-react";
import AceEditor from "react-ace";
import {backend} from "../../../../xconvert-backend";
import {authentication} from "../../../../authentication";
import QueryBuilderSemantic from 'react-query-builder-semantic/QueryBuilderSemantic';
import {ConfigContext} from "../../context/ConfigContext";

export interface DbQueryBuilderProps {
    onUpdate: (queryResponse: QueryResponse, tabCollection: string) => void
    take: number,
    page: number,
    sortBy: string,
    sortDirection: 'ASC' | 'DESC',
    restrictToCollections: string[],
}

export interface DbQueryBuilderState {
    readonly: boolean
    collection: string
    finalQuery: string
    queryResponse: QueryResponse
    discoveryServiceResponse: any
    isLoading: boolean,

    bypassID: boolean
    hiddenFields: boolean
    waitTime: number
    wantedFields: string[]


}

export class DbQueryBuilder extends React.Component<DbQueryBuilderProps, DbQueryBuilderState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {

        super(props)
        console.log("loading DbQueryBuilder")

        this.state = {
            readonly: false,
            collection: '',
            finalQuery: '',
            queryResponse: null,
            discoveryServiceResponse: null,
            isLoading: false,

            bypassID: false,
            hiddenFields: false,
            waitTime: 5,
            wantedFields: null,


        }
        this.onQueryChange = this.onQueryChange.bind(this);
        this.customValueEditor = this.customValueEditor.bind(this);
    }

    componentDidMount() {
        if (this.props.restrictToCollections && this.props.restrictToCollections.length > 0) {
            this.onValidate(this.props.restrictToCollections[0])
        }
    }

    onQueryChange(newQuery: any) {
        if(newQuery) {
            console.log("[CONTEXT] - onQueryChange", newQuery)
            this.context.setDbQuery(newQuery)
            this.setState({})
        }
    }

    customValueEditor() {
        let customValue = class CustomValue extends React.Component<any, any> {
            constructor(props) {
                super(props);
            }

            render() {
                if (this.props.operator === 'null' || this.props.operator === 'notNull') {
                    return null;
                }
                return <Input error={!this.props.value} value={this.props.value}
                              onChange={(e, {value}) => this.props.handleOnChange(value)}/>
            }
        };
        return customValue;
    }

    render() {
        return <>
            {this.drawVisualQueryBuilder()}
            {this.drawJsonQueryBuilder()}
        </>
    }

    dropDownCollectionOptions() {
        let opt = [
            {key: 'Companies', text: 'Companies', value: 'Companies'},
            {key: 'TransportOrders', text: 'TransportOrders', value: 'TransportOrders'},
            {key: 'History', text: 'History', value: 'History'},
            {key: 'Vehicles', text: 'Vehicles', value: 'Vehicles'},
            {key: 'QueuedEvents', text: 'QueuedEvents', value: 'QueuedEvents'},
            {key: 'Drivers', text: 'Drivers', value: 'Drivers'},
            {key: 'Queues', text: 'Queues', value: 'Queues'},
            {key: 'Users', text: 'Users', value: 'Users'},
            {key: 'UserRoles', text: 'UserRoles', value: 'UserRoles'},
            {key: 'SystemStatusMessage', text: 'SystemStatusMessage', value: 'SystemStatusMessage'},
            {key: 'SchedulerTaskConfigs', text: 'SchedulerTaskConfigs', value: 'SchedulerTaskConfigs'},
            {key: 'ProjectWorkLogBookEntries', text: 'ProjectWorkLogBookEntries', value: 'ProjectWorkLogBookEntries'},
            {key: 'Partners', text: 'Partners', value: 'Partners'},
            {key: 'MessageLog', text: 'MessageLog', value: 'MessageLog'},
            {
                key: 'CompanyAddressMappingGroups',
                text: 'CompanyAddressMappingGroups',
                value: 'CompanyAddressMappingGroups'
            },
            {key: 'CompanyAddressMappings', text: 'CompanyAddressMappings', value: 'CompanyAddressMappings'},
            {key: 'CompanyFreightMapping', text: 'CompanyFreightMapping', value: 'CompanyFreightMapping'},
            {
                key: 'CompanyFreightMappingGroups',
                text: 'CompanyFreightMappingGroups',
                value: 'CompanyFreightMappingGroups'
            },
            {key: 'CompanyHistory', text: 'CompanyHistory', value: 'CompanyHistory'},
            {key: 'CompanyConfigurationDraft', text: 'CompanyConfigurationDraft', value: 'CompanyConfigurationDraft'},
            {key: 'ChangeEvents', text: 'ChangeEvents', value: 'ChangeEvents'},
            {key: 'Positions', text: 'Positions', value: 'Positions'},
        ]
        if (this.props.restrictToCollections != null) {
            let newOpts = []
            opt.forEach(o => {
                if (this.props.restrictToCollections.includes(o.key)) {
                    newOpts.push(o)
                }
            })
            return newOpts
        }
        return opt
    }

    dropDownPropertyNameOptions() {
        let opt: Array<DropdownItemProps> = [];
        if (this.state.discoveryServiceResponse != null) {
            this.state.discoveryServiceResponse.forEach(value => {
                opt.push({key: value, value: value.toString(), text: value});
            })
        }
        return opt
    }

    drawVisualQueryBuilder() {
        let controlElements = {
            valueEditor: this.customValueEditor()
        };
        return <div>
            <Segment fluid={true}>
                <Dropdown
                    selectOnBlur={false}
                    style={{width: "240px"}}
                    readOnly={this.state.readonly}
                    placeholder={"Select a Collection"}
                    clearable
                    options={this.dropDownCollectionOptions()}
                    selection
                    value={this.state.collection}
                    onChange={(evt, data) => this.onValidate(data.value.toString())
                    }/>
                &nbsp;&nbsp;&nbsp;
                <Button style={{width: "150px"}} onClick={(evt) => this.onClearQuery()}> <Icon name='erase'/>Clear Query</Button>
                &nbsp;
                <Button
                    style={{width: "150px"}}
                    loading={this.state.isLoading}
                    onClick={(evt) => this.onRunQuery()}
                >
                    <Icon name='play' color='green'/> Run Query
                </Button>
                <Popup trigger={<Button floated={"right"} className="circular ui icon button">
                    <Icon name='settings'/></Button>} flowing hoverable>
                    <Grid centered divided columns={3}>
                        <Grid.Column style={{width: "180px"}} textAlign='center'>
                            <Header as='h4'>WAIT TIME</Header>
                            <Input label={'Time'}
                                   fluid
                                   min={5}
                                   max={30}
                                   placeholder='Month' value={this.state.waitTime}
                                   type="number"
                                   onChange={evt => this.setState({waitTime: Number(evt.target.value)})}
                            />
                        </Grid.Column>
                        <Grid.Column style={{width: "180px"}} textAlign='center'>
                            <Header as='h4'>Query all Companies</Header>
                            <p>Strict - <Checkbox
                                type='checkbox'
                                toggle
                                checked={this.state.bypassID}
                                onChange={() => {
                                    this.setState({bypassID: !this.state.bypassID})
                                }}
                            /> - Bypass </p>
                        </Grid.Column>
                        <Grid.Column style={{width: "180px"}} textAlign='center'>
                            <Header as='h4'>Show hidden fileds</Header>
                            <p>Hide - <Checkbox
                                type='checkbox'
                                toggle
                                checked={this.state.hiddenFields}
                                onChange={() => {
                                    this.setState({hiddenFields: !this.state.hiddenFields})
                                }}
                            /> - Show </p>
                        </Grid.Column>
                        <Grid.Column style={{width: "260px"}} textAlign='center'>
                            <Header as='h4'>Select wanted fields</Header>
                            <Dropdown
                                selectOnBlur={false}
                                style={{width: "240px"}}
                                readOnly={this.state.readonly}
                                placeholder={"Fields"}
                                fluid multiple selection
                                clearable
                                options={this.dropDownPropertyNameOptions()}
                                value={this.state.wantedFields}
                                onChange={(evt, data) => this.selectedFields(data.value as string[])
                                }/>
                        </Grid.Column>
                    </Grid>
                </Popup>

            </Segment>
            &emsp;
            <div className="flex-box">
                <div className="scroll">
                    <QueryBuilderSemantic
                        operators={[{value: 'Equal', text: '='}, {value: 'NotEqual', text: '!='}, {
                            value: 'Contains',
                            text: 'contains'
                        }, {
                            value: 'IsNull',
                            text: 'Is Null'
                        }, {value: 'NotNull', text: 'Is Not Null'}, {value: 'in', text: 'In'}, {
                            value: 'notIn',
                            text: 'Not In'
                        }, {
                            value: 'LowerThan',
                            text: '<'
                        }, {value: 'GreaterThan', text: '>'}, {
                            value: 'LowerThanEq',
                            text: '<='
                        }, {value: 'GreaterThanEq', text: '>='}]}
                        fields={this.dropDownPropertyNameOptions()}
                        query={this.context.dbQuery}
                        controlElements={controlElements}
                        onQueryChange={this.onQueryChange}/>
                </div>
            </div>
        </div>
    }

    drawJsonQueryBuilder() {
        return <div className="shrink query-log scroll">
            <AceEditor
                theme="monokai"
                style={{flex: 1}}
                mode='groovy'
                value={
                    JSON.stringify(
                        JSON.parse(
                            JSON.stringify(
                                this.context.dbQuery,
                                null,
                                2
                            )
                        .replace(/"id": ".*",/gi, '')
                        .replace(/"type": ".*",/gi, '')
                        ),
                        null,
                        2
                    )
                }
                onChange={(value) => {
                    this.context.setDbQuery(JSON.parse(value))
                }}
                placeholder='Query request'
                width="100%"
                readOnly={false}
            />
        </div>
    }

    onValidate(collection: string) {
        this.setState({collection: collection})
        this.setState({wantedFields: null})
        this.dicoveryServiceExecuteCall(collection)
        //this.onClearQuery()
    }

    selectedFields(wantedFields: string[]) {
        this.setState({wantedFields: wantedFields})
        console.log("wantedFields are : ", wantedFields)
    }

    onClearQuery() {
        console.log("[CONTEXT] - onClearQuery")
        this.context.setDbQuery(null)
        let reg = /^[0-9a-f]{24}$/i
        let regs = new RegExp(reg)
        let subject = "11234567906557$883030833";

        if (subject.match(regs)) {
            // Successful match
            console.log("security true")
        } else {
            // Match attempt failed
            console.log("security false")
        }
    }

    onRunQuery() {
        var finalQuery = JSON.stringify(this.context.dbQuery, null, 2)
            .replace(/"id": ".*",/gi, '')
            .replace(/"type": ".*",/gi, '')
        console.log(finalQuery)
        this.setState({finalQuery: finalQuery})
        this.queryExecuteCall()
    }

    async dicoveryServiceExecuteCall(collection: string) {
        let request = {collection: collection} as DiscoveryServiceRequest

        let auth = await backend.withTokenAuthHeader(authentication.token)

        let response = await backend.queryApi.runDiscoveryService(request, auth)

        this.setState({discoveryServiceResponse: response.filtrableProperties})
    }

    async queryExecuteCall(take = this.props.take, page = this.props.page) {
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        console.log("QueryENGINE requested with: ")
        var finalQuery = JSON.stringify(this.context.dbQuery, null, 2)
            .replace(/"id": ".*",/gi, '')
            .replace(/"type": ".*",/gi, '')

        this.setState({isLoading: true})

        let params = {
            timeLimit: this.state.waitTime,
            bypassID: this.state.bypassID,
            showhidden: this.state.hiddenFields,
            wantedFields: this.state.wantedFields
        }

        let pagination = {
            limit: take,
            skip: (page - 1) * take,
            sortBy: this.props.sortBy,
            sortDirection: this.props.sortDirection
        }
        console.log(pagination)
        let request = {query: finalQuery, paginate: pagination, params: params} as QueryRequest
        try{
            let response = await backend.queryApi.runQuery(this.state.collection, request, auth)
            console.log(response)
            this.props.onUpdate(
                response,
                this.state.collection
            )
            this.setState({
                queryResponse: response,
                isLoading: false,
            })
        } catch (e) {


            this.setState({
                isLoading: false,
            })
        }

    }


}
