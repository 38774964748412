import * as React from "react";
import {
    addMany,
    ParserOptions,
    reset, setParseOptions,
} from "../../slices/ruleSlice";
import {useDispatch} from "react-redux";
import "./LoadRulesButton.css"
import {GenericXlsExtractionRule} from "../../../../../../generated";

interface LoadRulesButtonProps {
    ruleJson: string | null
    optionsJson: string|null
    label?: string
    onLoad?: () => void;
}

export function LoadRulesButton(props:React.PropsWithChildren<LoadRulesButtonProps>){
    const dispatch = useDispatch()

    function load(){
        console.log("Loading rules")
        props.onLoad?.();
        if(props.ruleJson){
            const rules :GenericXlsExtractionRule[] = JSON.parse(props.ruleJson)
            dispatch(reset())
            dispatch(addMany(rules))
        }
        if(props.optionsJson){
            const options:ParserOptions = JSON.parse(props.optionsJson)
            dispatch(setParseOptions(options))
        }
    }

    return <button onClick={load} className={"loadButton"}>{props.label??"Load Rules"}</button>
}