import * as React from "react";

import {
    CompanyConfigHistory,
    Contract,
    OutputReport,
    ProjectWorkLogBookEntry,
    TransportOrderConfigTest
} from "../../../generated";
import TabSession from "./TabSession";
import QueuedEventFilters from "./QueuedEventFilters";
import {LogSession} from "./LogSession";
import {configStorage} from "../../../ConfigStorage";
import {CustomerReport} from "./CustomerReport";

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

export type ContextProps = {

    companyId: string,
    setCompanyId: (companyId: string) => void,

    companyName: string,
    setCompanyName: (companyName: string) => void,

    companyTag: string,
    setCompanyTag: (companyTag: string) => void,

    responsibleManager: string,
    setResponsibleManager: (responsibleManager: string) => void,

    healthReport: any[],
    setHealthReport: (healthReport: []) => void

    scriptConverterPlaygroundScriptPath: string,
    setScriptConverterPlaygroundScriptPath: (scriptConverterPlaygroundScriptPath: string) => void,

    //
    // config
    //

    //config: string | null,
    //setConfig: (config: string, updateStorage: boolean, alsoSaveAsUnchangedConfig: boolean) => Promise<void>,

    navTreeCursor: any | null,
    setNavTreeCursor: (cursor: any) => void,

    latestDraft: string | null,
    setLatestDraft: (latestDraft: string) => void,

    treeSearchQuery: string | null,
    setTreeSearchQuery: (treeSearchQuery: string) => void,

    saveConfigComment: string | null,
    setSaveConfigComment: (saveConfigComment: string) => void,

    scriptInfo: any[] | null,
    setScriptInfo: (scriptInfo: any[]) => void,

    //
    // simulator
    //

    confSimInput: string | null,
    setConfSimInput: (confSimInput: string) => void,

    confSimInputFiles: File[] | null,
    setConfSimInputFiles: (confSimInputFiles: File[]) => void,

    confSimInputActive: string,
    setConfSimInputActive: (confSimInputActive: string) => void,

    confSimInputLastType: string,
    setConfSimInputLastType: (confSimInputLastType: string) => void,

    confSimToSaveMode: 'REPLACE' | 'PATCH' | null,
    setConfSimToSaveMode: (confSimToSaveMode: 'REPLACE' | 'PATCH' | null) => void,

    //
    // export simulator
    //

    exportSimToId: string | null,
    setExportSimToId: (exportSimToId: string) => void

    exportSimTo: string | null,
    setExportSimTo: (exportSimTo: string) => void

    exportSimToValid: boolean | null,
    setExportSimToValid: (exportSimToValid: string) => void

    exportSimQe: string | null,
    setExportSimQe: (exportSimQe: string) => void

    exportSimQeValid: boolean | null,
    setExportSimQeValid: (exportSimQeValid: string) => void

    exportSimOrderId: string | null,
    setExportSimOrderId: (exportSimOrderId: string) => void

    exportSimOutput: string | null,
    setExportSimOutput: (exportSimOutput: string) => void

    exportSimOutputEncoding: string | null,
    setExportSimOutputEncoding: (exportSimOutputEncoding: string) => void

    customConverterBasePath: string | null,
    setCustomConverterBasePath: (customConverterBasePath: string) => void

    //
    // scripting execution
    //

    scriptExeToOutput: string | null,
    setScriptExeToOutput: (scriptExeToOutput: string) => void

    scriptExeToInput: string | null,
    setScriptExeToInput: (scriptExeToInput: string) => void

    scriptExeScript: string | null,
    setScriptExeScript: (scriptExeScript: string) => void

    scriptExeValid: boolean | null,
    setScriptExeValid: (scriptExeValid: string) => void

    scriptExeToId: string | null,
    setScriptExeToId: (scriptExeToId: string) => void

    multiOrderScript: string | null,
    setMultiOrderScript: (multiOrderScript: string) => void

    multiOrderScriptResults: any[] | null,
    setMultiOrderScriptResults: (multiOrderScriptResults: any[]) => void



    //
    // multi scripting execution
    //

    multiScriptExeToOutput: string | null,
    setMultiScriptExeToOutput: (multiScriptExeToOutput: string) => void

    multiScriptExeToInput: string | null,
    setMultiScriptExeToInput: (multiScriptExeToInput: string) => void

    multiScriptExeToScript: string | null,
    setMultiScriptExeToScript: (multiScriptExeToScript: string) => void

    multiScriptExeTransportScript: string | null,
    setMultiScriptExeTransportScript: (multiScriptExeTransportScript: string) => void

    multiScriptExeFreightScript: string | null,
    setMultiScriptExeFreightScript: (multiScriptExeFreightScript: string) => void

    multiScriptExeStopScript: string | null,
    setMultiScriptExeStopScript: (multiScriptExeStopScript: string) => void

    multiScriptExeValid: boolean | null,
    setMultiScriptExeValid: (multiScriptExeValid: string) => void

    multiScriptExeToId: string | null,
    setMultiScriptExeToId: (multiScriptExeToId: string) => void


    //
    // Script runner
    //

    scriptRunnerScript: string | null,
    setScriptRunnerScript: (scriptRunnerScript: string) => void,

    scriptRunnerQueuedEvent: string | null,
    setScriptRunnerQueuedEvent: (scriptRunnerQueuedEvent: string) => void,

    scriptRunnerQueuedEventId: string | null,
    setScriptRunnerQueuedEventId: (scriptRunnerQueuedEventId: string) => void,


    //
    // DbQuery
    //

    dbQuery: any | null
    setDbQuery: (dbQuery: any) => void


    //
    // report script
    //

    reportScriptQueryLimit: number,
    setReportScriptQueryLimit: (reportScriptQueryLimit: number) => void

    reportScriptOrderResponse: any
    setReportScriptOrderResponse: (reportScriptOrderResponse: any) => void

    reportScript: string | null
    setReportScript: (reportScript: string) => void

    reportScriptResults: OutputReport | null
    setReportScriptResults: (reportScriptResults: OutputReport) => void


    //
    // telematic mapping
    //

    telMapToOutput: string | null,
    setTelMapToOutput: (telMapToOutput: string) => void

    telMapOutput: string | null,
    setTelMapOutput: (telMapOutput: string) => void

    telMapScript: string | null,
    setTelMapScript: (telMapScript: string) => void

    telMapCustomerId: string | null,
    setTelMapCustomerId: (telMapCustomerId: string) => void

    telMapTourId: string | null,
    setTelMapTourId: (telMapTourId: string) => void

    //
    // DocViewer
    //

    lisDoc: string | null
    setLisDoc: (lisDoc: string) => void

    fortrasDoc: string | null
    setFortrasDoc: (lisDoc: string) => void

    ediDoc: string | null
    setEdiDoc: (ediDoc: string) => void

    matrixDoc: string | null
    setMatrixDoc: (matrixDoc: string) => void

    matrixDocFile: File | null
    setMatrixDocFile: (matrixDoc: File) => void

    matrixDocInputActive: string | null
    setMatrixDocInputActive: (matrixDocInputActive: String) => void

    matrixDocResult: any | null
    setMatrixDocResult: (matrixDocResult: any) => void

    matrixDocOrders: any | null
    setMatrixDocOrders: (matrixDocOrders: any) => void


    //
    // ftp-explorer
    //
    ftpRegex: string | null,
    setFtpRegex: (ftpRegex: string) => void

    ftpFileSearchRegex: string | null,
    setFtpFileSearchRegex: (ftpRegex: string) => void

    ftpPath: string | null,
    setFtpPath: (ftpPath: string) => void

    //
    // tests
    //
    tests: TransportOrderConfigTest[]
    setTests: (tests: TransportOrderConfigTest[]) => void

    selectedTests: string[]
    setSelectedTests: (selectedTests: string[]) => void

    //
    // History
    //
    history: CompanyConfigHistory | null
    setHistory: (history: CompanyConfigHistory) => void

    //
    // SearchView
    //
    searchViewCompanyIds: string[],
    setSearchViewCompanyIds: (searchViewCompanyIds: string[]) => void

    //
    // contracts
    //

    selectedContract: string,
    setSelectedContract: (selectedContract: string) => void

    selectedContractIndex: number,
    setSelectedContractIndex: (selectedContractIndex: number) => void

    oldContract: string,
    setOldContract: (selectedContract: string) => void

    contractNavTreeCursor: any | null,
    setContractNavTreeCursor: (cursor: any) => void,

    contracts: Contract[],
    setContracts: (contracts: Contract[]) => void

    selectedContractIsExpired: boolean,
    setSelectedContractIsExpired: (selectedContractIsExpired: boolean) => void

    //
    // ProjectWorkLogs
    //

    projectWorkLogs: ProjectWorkLogBookEntry[],
    setProjectWorkLogs: (projectWorkLogs: ProjectWorkLogBookEntry[]) => void

    selectedProjectWorkLogBookEntry: ProjectWorkLogBookEntry,
    setSelectedProjectWorkLogBookEntry: (selectedProjectWorkLog: ProjectWorkLogBookEntry) => void

    selectedProjectWorkLogIndex: number,
    setSelectedProjectWorkLogIndex: (selectedProjectWorkLogIndex: number) => void

    oldProjectWorkLogBookEntry: ProjectWorkLogBookEntry,
    setOldProjectWorkLogBookEntry: (selectedProjectWorkLog: ProjectWorkLogBookEntry) => void

    //
    // queues
    //

    queueWorkingCopies: QueueWorkingCopy[],
    setQueueWorkingCopies: (queueWorkingCopies: QueueWorkingCopy[]) => void,
    updateQueueWorkingCopy: (queueWorkingCopy: QueueWorkingCopy) => void,

    selectedQueueId: string,
    setSelectedQueueId: (selectedQueueId: string) => void,

    selectedQueuedEventId: string,
    setSelectedQueuedEventId: (selectedQueuedEventId: string) => void,

    queuedEventFilters: QueuedEventFilters,
    setQueuedEventFilters: (queuedEventFilters: QueuedEventFilters) => void,
    setSingleQueuedEventFilter: (filterName: string, value: string) => void,

    //
    // TabSession
    //

    tabSession: TabSession,
    setTabSession: (tabSession: TabSession) => void,
    setSingleTabSession: (tabName: string, activeIndex: number, activeItemName: string | null, checked: boolean | null) => void,
    tabByName: (tabName: string) => number,

    //
    // LogSession
    //

    logSession: LogSession,
    setLogSession: (logSession: LogSession) => void,
    setSingleLogSession: (logName: string, query: string, logs: string[]) => void,

    //
    // System Status
    //

    customerReportXReqId: string,
    setCustomerReportXReqId: (customerReportXReqId: string) => void,

};

export const ConfigContext = React.createContext<Partial<ContextProps>>({});

export const ConfigProvider = (props) => {
    console.log("configProvider initialized")

    // @ts-ignore
    let state = {

        companyId: "",
        setCompanyId: null,

        companyName: "",
        setCompanyName: null,

        companyTag: "",
        setCompanyTag: null,

        responsibleManager: "",
        setResponsibleManager: null,

        healthReport: [],
        setHealthReport: null,

        scriptConverterPlaygroundScriptPath: null,
        setScriptConverterPlaygroundScriptPath: null,

        //
        // config
        //

        //@ts-ignore
        //config: JSON.stringify(configStorage.getElement(this.companyId)?.config, null, 4),
        //setConfig: null,

        navTreeCursor: {active: true, name: "CompanyConfiguration", path: "$", toggled: true},
        setNavTreeCursor: null,

        latestDraft: null,
        setLatestDraft: null,

        treeSearchQuery: null,
        setTreeSearchQuery: null,

        saveConfigComment: null,
        setSaveConfigComment: null,

        scriptInfo: null,
        setScriptInfo: null,

        //
        // import simulator
        //

        confSimInput: "",
        setConfSimInput: null,

        confSimInputFiles: null,
        setConfSimInputFiles: null,

        confSimInputActive: "TEXT",
        setConfSimInputActive: null,

        confSimInputLastType: null,
        setConfSimInputLastType: null,

        confSimToSaveMode: null,
        setConfSimToSaveMode: null,

        //
        // export simulator
        //

        exportSimToId: "",
        setExportSimToId: null,

        exportSimTo: "",
        setExportSimTo: null,

        exportSimToValid: false,
        setExportSimToValid: null,

        exportSimQe: "",
        setExportSimQe: null,

        exportSimQeValid: true,
        setExportSimQeValid: null,

        exportSimOrderId: "",
        setExportSimOrderId: null,

        exportSimOutput: "",
        setExportSimOutput: null,

        exportSimOutputEncoding: "",
        setExportSimOutputEncoding: null,

        customConverterBasePath: null,
        setCustomConverterBasePath: null,

        //
        // scripting execution
        //

        scriptExeToOutput: "",
        setScriptExeToOutput: null,

        scriptExeToInput: "",
        setScriptExeToInput: null,

        scriptExeScript: "",
        setScriptExeScript: null,

        scriptExeValid: null,
        setScriptExeValid: null,

        scriptExeToId: null,
        setScriptExeToId: null,

        multiOrderScript: null,
        setMultiOrderScript: null,

        multiOrderScriptResults: [],
        setMultiOrderScriptResults: null,


        //
        // multi scripting execution
        //

        multiScriptExeToOutput: "",
        setMultiScriptExeToOutput: null,

        multiScriptExeToInput: "",
        setMultiScriptExeToInput: null,

        multiScriptExeToScript: "",
        setMultiScriptExeToScript: null,

        multiScriptExeTransportScript: "",
        setMultiScriptExeTransportScript: null,

        multiScriptExeFreightScript: "",
        setMultiScriptExeFreightScript: null,

        multiScriptExeStopScript: "",
        setMultiScriptExeStopScript: null,

        multiScriptExeValid: null,
        setMultiScriptExeValid: null,

        multiScriptExeToId: "",
        setMultiScriptExeToId: null,


        //
        // Script runner
        //

        scriptRunnerScript: "",
        setScriptRunnerScript: null,

        scriptRunnerQueuedEvent: "",
        setScriptRunnerQueuedEvent: null,

        scriptRunnerQueuedEventId: "",
        setScriptRunnerQueuedEventId: null,


        //
        // DbQuery
        //

        dbQuery: null,
        setDbQuery: null,


        //
        // report script
        //

        reportScriptQueryLimit: 1000,
        setReportScriptQueryLimit: null,

        reportScriptOrderResponse: null,
        setReportScriptOrderResponse: null,

        reportScript: "",
        setReportScript: null,

        reportScriptResults: null,
        setReportScriptResults: null,


        //
        // telematic mapping
        //

        telMapToOutput: "",
        setTelMapToOutput: null,

        telMapOutput: "",
        setTelMapOutput: null,

        telMapScript: "",
        setTelMapScript: null,

        telMapCustomerId: null,
        setTelMapCustomerId: null,

        telMapTourId: null,
        setTelMapTourId: null,

        //
        // DocViewer
        //

        lisDoc: "",
        setLisDoc: null,

        fortrasDoc: "",
        setFortrasDoc: null,

        ediDoc: "",
        setEdiDoc: null,

        matrixDoc: "",
        setMatrixDoc: null,

        matrixDocFile: null,
        setMatrixDocFile: null,

        matrixDocInputActive: null,
        setMatrixDocInputActive: null,

        matrixDocResult: null,
        setMatrixDocResult: null,

        matrixDocOrders: null,
        setMatrixDocOrders: null,

        //
        // ftp-explorer
        //
        ftpRegex: null,
        setFtpRegex: null,

        ftpFileSearchRegex: "/",
        setFtpFileSearchRegex: null,

        ftpPath: null,
        setFtpPath: null,

        //
        // tests
        //
        tests: [],
        setTests: null,

        selectedTests: [],
        setSelectedTests: null,

        //
        // History
        //
        history: null,
        setHistory: null,

        //
        // SearchView
        //
        searchViewCompanyIds: [],
        setSearchViewCompanyIds: null,

        //
        // contracts
        //

        selectedContract: null,
        setSelectedContract: null,

        selectedContractIndex: null,
        setSelectedContractIndex: null,

        oldContract: null,
        setOldContract: null,

        contractNavTreeCursor: null,
        setContractNavTreeCursor: null,

        contracts: [],
        setContracts: null,

        selectedContractIsExpired: false,
        setSelectedContractIsExpired: null,

        //
        // ProjectWorkLogs
        //

        projectWorkLogs: [],
        setProjectWorkLogs: null,

        selectedProjectWorkLogBookEntry: null,
        setSelectedProjectWorkLogBookEntry: null,

        oldProjectWorkLogBookEntry: null,
        setOldProjectWorkLogBookEntry: null,

        selectedProjectWorkLogIndex: null,
        setSelectedProjectWorkLogIndex: null,


        //
        // queues
        //

        queueWorkingCopies: [],
        setQueueWorkingCopies: null,
        updateQueueWorkingCopy: null,

        selectedQueueId: null,
        setSelectedQueueId: null,

        selectedQueuedEventId: null,
        setSelectedQueuedEventId: null,

        queuedEventFilters: {
            queuedEventId: null,
            queueType: null,
            eventType: null,
            changeType: null,
            transportOrderId: null,
            processingState: null,
            processed: null,

        } as QueuedEventFilters,
        setQueuedEventFilters: null,
        setSingleQueuedEventFilter: null,


        //
        // Tabs
        //

        tabSession: {
            configTab: 0,
            editConfigMenuTab: 0,
            configViewActiveItem: "NavTree",
            configRightSideTab: 0,

            converterTab: 0,
            exportSimPreEditTab: 0,
            exportSimOutputTab: 0,
            importSimOutputTab: 0,
            importSimTab: 0,

            scriptExecutionTab: 0,
            multiScriptTab: 0,
            scriptRunnerTypeIsCron: false,

            docViewerTab: 0,
            matrixDocTab: 0,
            matrixDocInputTab: 0,
            ediOutputTab: 0,

            queuesTab: 0,

            contractsTab: 0,

            transportOrderRightSideTab: 0,
            reportScriptResultTab: 0
        } as TabSession,
        setTabSession: null,
        setSingleTabSession: null,
        tabByName: null,

        //
        // logs
        //

        logSession: {
            exportSimQuery: null,
            exportSimLogs: null,

            importSimQuery: null,
            importSimLogs: null,

            transportOrderConfigTestQuery: null,
            transportOrderConfigTestLogs: null,

            matrixDocQuery: null,
            matrixDocLogs: null,

            multiScriptQuery: null,
            multiScriptLogs: null,

            scriptRunnerQuery: null,
            scriptRunnerLogs: null,

            singleScriptQuery: null,
            singleScriptLogs: null,

            telematicMappingQuery: null,
            telematicMappingLogs: null,

            suppressionScriptModalQuery: null,
            suppressionScriptModalLogs: null,

        } as LogSession,
        setLogSession: null,
        setSingleLogSession: null,

        customerReportXReqId: null,
        setCustomerReportXReqId: null,
    }

    state.setCompanyId = (companyId: string) => {
        state.companyId = companyId
    }

    state.setCompanyName = (companyName: string) => {
        state.companyName = companyName
    }

    state.setCompanyTag = (companyTag: string) => {
        state.companyTag = companyTag
    }

    state.setResponsibleManager = (responsibleManager: string) => {
        state.responsibleManager = responsibleManager
    }

    state.setHealthReport = (healthReport: []) => {
        state.healthReport = healthReport
    }

    state.setScriptConverterPlaygroundScriptPath = (scriptConverterPlaygroundScriptPath: string) => {
        state.scriptConverterPlaygroundScriptPath = scriptConverterPlaygroundScriptPath
    }

    //
    // config
    //

    //state.setConfig = async (config: string, updateStorage: boolean, alsoSaveAsUnchangedConfig: boolean = false) => {
    //    state.config = config
    //    if (updateStorage) {
    //        await configStorage.updateConfig(state.companyId, JSON.parse(config), alsoSaveAsUnchangedConfig)
    //    }
    //}

    state.setNavTreeCursor = (cursor: any) => {
        state.navTreeCursor = cursor
        console.log("NavTreeCursor is set to " + JSON.stringify(cursor, null, 4))
    }

    state.setLatestDraft = (latestDraft: string) => {
        state.latestDraft = latestDraft
    }

    state.setTreeSearchQuery = (treeSearchQuery: null) => {
        state.treeSearchQuery = treeSearchQuery
    }

    state.setSaveConfigComment = (saveConfigComment: string) => {
        state.saveConfigComment = saveConfigComment
    }

    state.setScriptInfo = (scriptInfo: any[]) => {
        state.scriptInfo = scriptInfo
    }

    //
    // import simulator
    //

    state.setConfSimInput = (confSimInput: string) => {
        state.confSimInput = confSimInput
    }

    state.setConfSimInputFiles = (confSimInputFiles: File[]) => {
        state.confSimInputFiles = confSimInputFiles
    }

    state.setConfSimInputActive = (confSimInputActive: string) => {
        state.confSimInputActive = confSimInputActive
    }

    state.setConfSimInputLastType = (confSimInputLastType: string) => {
        state.confSimInputLastType = confSimInputLastType
    }

    state.setConfSimToSaveMode = (confSimToSaveMode: 'REPLACE' | 'PATCH' | null) => {
        state.confSimToSaveMode = confSimToSaveMode
    }

    //
    // export simulator
    //

    state.setExportSimToId = (exportSimToId: string) => {
        state.exportSimToId = exportSimToId
    }

    state.setExportSimTo = (exportSimTo: string) => {
        state.exportSimTo = exportSimTo
    }

    state.setExportSimToValid = (exportSimToValid: boolean) => {
        state.exportSimToValid = exportSimToValid
    }

    state.setExportSimQe = (exportSimQe: string) => {
        state.exportSimQe = exportSimQe
    }

    state.setExportSimQeValid = (exportSimQeValid: boolean) => {
        state.exportSimQeValid = exportSimQeValid
    }

    state.setExportSimOrderId = (exportSimOrderId: string) => {
        state.exportSimOrderId = exportSimOrderId
    }

    state.setExportSimOutput = (exportSimOutput: string) => {
        state.exportSimOutput = exportSimOutput
    }

    state.setExportSimOutputEncoding = (exportSimOutputEncoding: string) => {
        state.exportSimOutputEncoding = exportSimOutputEncoding
    }

    state.setCustomConverterBasePath = (customConverterBasePath: string) => {
        state.customConverterBasePath = customConverterBasePath
    }


    //
    // scripting execution
    //

    state.setScriptExeToOutput = (scriptExeToOutput: string) => {
        state.scriptExeToOutput = scriptExeToOutput
    }

    state.setScriptExeToInput = (scriptExeToInput: string) => {
        state.scriptExeToInput = scriptExeToInput
    }

    state.setScriptExeScript = (scriptExeScript: string) => {
        state.scriptExeScript = scriptExeScript
    }

    state.setScriptExeValid = (scriptExeValid: boolean) => {
        state.scriptExeValid = scriptExeValid
    }

    state.setScriptExeToId = (scriptExeToId: string) => {
        state.scriptExeToId = scriptExeToId
    }

    state.setMultiOrderScript = (multiOrderScript: string) => {
        state.multiOrderScript = multiOrderScript
    }

    state.setMultiOrderScriptResults = (multiOrderScriptResults: any[]) => {
        state.multiOrderScriptResults = multiOrderScriptResults
    }

    //
    // multi scripting execution
    //

    state.setMultiScriptExeToOutput = (multiScriptExeToOutput: string) => {
        state.multiScriptExeToOutput = multiScriptExeToOutput
    }
    state.setMultiScriptExeToInput = (multiScriptExeToInput: string) => {
        state.multiScriptExeToInput = multiScriptExeToInput
    }
    state.setMultiScriptExeToScript = (multiScriptExeToScript: string) => {
        state.multiScriptExeToScript = multiScriptExeToScript
    }
    state.setMultiScriptExeTransportScript = (multiScriptExeTransportScript: string) => {
        state.multiScriptExeTransportScript = multiScriptExeTransportScript
    }
    state.setMultiScriptExeFreightScript = (multiScriptExeFreightScript: string) => {
        state.multiScriptExeFreightScript = multiScriptExeFreightScript
    }
    state.setMultiScriptExeStopScript = (multiScriptExeStopScript: string) => {
        state.multiScriptExeStopScript = multiScriptExeStopScript
    }
    state.setMultiScriptExeValid = (multiScriptExeValid: boolean) => {
        state.multiScriptExeValid = multiScriptExeValid
    }
    state.setMultiScriptExeToId = (multiScriptExeToId: string) => {
        state.multiScriptExeToId = multiScriptExeToId
    }


    //
    // Script runner
    //

    state.setScriptRunnerScript = (scriptRunnerScript: string) => {
        state.scriptRunnerScript = scriptRunnerScript
    }

    state.setScriptRunnerQueuedEvent = (scriptRunnerQueuedEvent: string) => {
        state.scriptRunnerQueuedEvent = scriptRunnerQueuedEvent
    }

    state.setScriptRunnerQueuedEventId = (scriptRunnerQueuedEventId: string) => {
        state.scriptRunnerQueuedEventId = scriptRunnerQueuedEventId
    }


    //
    // DbQuery
    //

    state.setDbQuery = (dbQuery: any) => {
        console.log("[CONTEXT] - dbQuery: ", dbQuery)
        state.dbQuery = dbQuery
    }


    //
    // report script
    //

    state.setReportScriptQueryLimit = (reportScriptQueryLimit: number) => {
        state.reportScriptQueryLimit = reportScriptQueryLimit
    }

    state.setReportScriptOrderResponse = (reportScriptOrderResponse: any) => {
        state.reportScriptOrderResponse = reportScriptOrderResponse
    }

    state.setReportScript = (reportScript: string) => {
        state.reportScript = reportScript
    }

    state.setReportScriptResults = (reportScriptResults: OutputReport) => {
        state.reportScriptResults = reportScriptResults
    }


    //
    // telematic mapping
    //

    state.setTelMapToOutput = (telMapToOutput: string) => {
        state.telMapToOutput = telMapToOutput
    }

    state.setTelMapOutput = (telMapOutput: string) => {
        state.telMapOutput = telMapOutput
    }

    state.setTelMapScript = (telMapScript: string) => {
        state.telMapScript = telMapScript
    }

    state.setTelMapCustomerId = (telMapCustomerId: boolean) => {
        state.telMapCustomerId = telMapCustomerId
    }

    state.setTelMapTourId = (telMapTourId: string) => {
        state.telMapTourId = telMapTourId
    }


    //
    // docViewer
    //

    state.setLisDoc = (lisDoc: string) => {
        state.lisDoc = lisDoc
    }

    state.setFortrasDoc = (fortrasDoc: string) => {
        state.fortrasDoc = fortrasDoc
    }

    state.setEdiDoc = (ediDoc: string) => {
        state.ediDoc = ediDoc
    }

    state.setMatrixDoc = (matrix: string) => {
        state.matrixDoc = matrix
    }

    state.setMatrixDocFile = (matrix: File) => {
        state.matrixDocFile = matrix
    }

    state.setMatrixDocInputActive = (matrixDocInputActive: string) => {
        state.matrixDocInputActive = matrixDocInputActive
    }

    state.setMatrixDocResult = (matrixDocResult: any) => {
        state.matrixDocResult = matrixDocResult
    }

    state.setMatrixDocOrders = (matrixDocOrders: any) => {
        state.matrixDocOrders = matrixDocOrders
    }

    //
    // ftp-explorer
    //

    state.setFtpRegex = (ftpRegex: string) => {
        state.ftpRegex = ftpRegex
    }

    state.setFtpFileSearchRegex = (ftpFileSearchRegex: string) => {
        state.ftpFileSearchRegex = ftpFileSearchRegex
    }

    state.setFtpPath = (ftpPath: string) => {
        state.ftpPath = ftpPath
    }

    //
    // tests
    //
    state.setTests = (tests: TransportOrderConfigTest[]) => {
        state.tests = tests
    }

    state.setSelectedTests = (selectedTests: string[]) => {
        state.selectedTests = selectedTests
    }

    //
    // History
    //
    state.setHistory = (history: CompanyConfigHistory) => {
        state.history = history
    }

    //
    // SearchView
    //
    state.setSearchViewCompanyIds = (searchViewCompanyIds: string[]) => {
        state.searchViewCompanyIds = searchViewCompanyIds
    }

    //
    // contracts
    //

    state.setSelectedContract = (selectedContract: string) => {
        state.selectedContract = selectedContract
    }

    state.setSelectedContractIndex = (selectedContractIndex: string) => {
        state.selectedContractIndex = selectedContractIndex
    }

    state.setOldContract = (oldContract: string) => {
        state.oldContract = oldContract
    }

    state.setContractNavTreeCursor = (contractNavTreeCursor: any) => {
        state.contractNavTreeCursor = contractNavTreeCursor
    }

    state.setContracts = (contracts: Contract[]) => {
        state.contracts = contracts
    }


    state.setSelectedContractIsExpired = (selectedContractIsExpired: boolean) => {
        state.selectedContractIsExpired = selectedContractIsExpired
    }

    //
    // ProjectWorkLogs
    //

    state.setProjectWorkLogs = (projectWorkLogs: ProjectWorkLogBookEntry[]) => {
        state.projectWorkLogs = projectWorkLogs
    }

    state.setSelectedProjectWorkLogBookEntry = (selectedProjectWorkLog: ProjectWorkLogBookEntry) => {
        state.selectedProjectWorkLogBookEntry = selectedProjectWorkLog
    }

    state.setOldProjectWorkLogBookEntry = (oldProjectWorkLog: ProjectWorkLogBookEntry) => {
        state.oldProjectWorkLogBookEntry = oldProjectWorkLog
    }

    state.setSelectedProjectWorkLogIndex = (selectedProjectWorkLogIndex: string) => {
        state.selectedProjectWorkLogIndex = selectedProjectWorkLogIndex
    }

    //
    // queues
    //

    state.setQueueWorkingCopies = (queueWorkingCopies: QueueWorkingCopy[]) => {
        state.queueWorkingCopies = queueWorkingCopies
    }

    state.updateQueueWorkingCopy = (queueWorkingCopy: QueueWorkingCopy) => {
        console.log("Update working copy: ", queueWorkingCopy)
        let newCopies = []
        let found = false
        state.queueWorkingCopies.map(c => {
            if (c.id === queueWorkingCopy.id) {
                c.json = queueWorkingCopy.json
                found = true
            }
            newCopies.push(c)
        })
        if (found == false) {
            newCopies.push(queueWorkingCopy)
        }
        console.log("New working copies: ", newCopies)
        state.queueWorkingCopies = newCopies

    }

    state.setSelectedQueueId = (selectedQueueId: string) => {
        state.selectedQueueId = selectedQueueId
    }

    state.setSelectedQueuedEventId = (selectedQueuedEventId: string) => {
        state.selectedQueuedEventId = selectedQueuedEventId
    }

    state.setQueuedEventFilters = (queuedEventFilters: QueuedEventFilters) => {
        state.queuedEventFilters = queuedEventFilters
    }

    state.setSingleQueuedEventFilter = (filterName: string, value: string) => {
        let filter = state.queuedEventFilters
        switch (filterName) {
            case "queuedEventId": {
                filter.queuedEventId = value
                break;
            }
            case "queueType": {
                filter.queueType = value
                break;
            }
            case "eventType": {
                filter.eventType = value
                break;
            }
            case "changeType": {
                filter.changeType = value
                break;
            }
            case "transportOrderId": {
                filter.transportOrderId = value
                break;
            }
            case "processingState": {
                filter.processingState = value
                break;
            }
            case "processed": {
                filter.processed = value
                break;
            }
            case "createdAt": {
                filter.createdAt = value
            }
        }
        state.queuedEventFilters = filter

    }

    //
    // TabSession
    //

    state.setTabSession = (tabSession: TabSession) => {
        state.tabSession = tabSession
    }

    state.setSingleTabSession = (
        tabName: string,
        activeIndex: number,
        activeItemName: string | null = null,
        checked: boolean | null = null
    ) => {
        let session = state.tabSession
        switch (tabName) {

            // config
            case "configTab": {
                session.configTab = activeIndex
                break;
            }
            case "editConfigMenuTab": {
                session.editConfigMenuTab = activeIndex
                break;
            }
            case "configViewActiveItem": {
                session.configViewActiveItem = activeItemName // custom Menu (not the usual Tab menu)
                break;
            }
            case "configRightSideTab": {
                session.configRightSideTab = activeIndex
                break;
            }




            // converter
            case "converterTab": {
                session.converterTab = activeIndex
                break;
            }
            case "exportSimPreEditTab": {
                session.exportSimPreEditTab = activeIndex
                break;
            }
            case "exportSimOutputTab": {
                session.exportSimOutputTab = activeIndex
                break;
            }
            case "importSimOutputTab": {
                session.importSimOutputTab = activeIndex
                break;
            }
            case "importSimTab": {
                session.importSimTab = activeIndex
                break;
            }


            // scriptExecution
            case "scriptExecutionTab": {
                session.scriptExecutionTab = activeIndex
                break;
            }
            case "multiScriptTab": {
                session.multiScriptTab = activeIndex
                break;
            }
            case "scriptRunnerTypeIsCron": {
                session.scriptRunnerTypeIsCron = checked // CheckBox
                break;
            }
            case "reportScriptResultTab": {
                session.reportScriptResultTab = activeIndex
                break;
            }


            // docViewer
            case "docViewerTab": {
                session.docViewerTab = activeIndex
                break;
            }
            case "matrixDocTab": {
                session.matrixDocTab = activeIndex
                break;
            }
            case "matrixDocInputTab": {
                session.matrixDocInputTab = activeIndex
                break;
            }
            case "ediOutputTab": {
                session.ediOutputTab = activeIndex
                break;
            }

            // queues
            case "queuesTab": {
                session.queuesTab = activeIndex
                break;
            }

            // contracts
            case "contractsTab": {
                session.contractsTab = activeIndex
                break;
            }

            case "contractListTab": {
                session.contractListTab = activeIndex
                break;
            }

            case "customerAccountingTab": {
                session.customerAccountingTab = activeIndex
                break;
            }

            // systemStatus
            case "systemStatusTab": {
                session.systemStatusTab = activeIndex
                break;
            }

            // transportOrder
            case "transportOrderRightSideTab": {
                session.transportOrderRightSideTab = activeIndex
                break;
            }
        }
        state.tabSession = session
    }

    state.tabByName = (tabName: string) => {
        switch (tabName) {
            case "configTab": {
                return state.tabSession.configTab
            }
            case "editConfigMenuTab": {
                return state.tabSession.editConfigMenuTab
            }
            case "configViewActiveItem": {
                return state.tabSession.configViewActiveItem
            }
            case "configRightSideTab": {
                return state.tabSession.configRightSideTab
            }
            case "converterTab": {
                return state.tabSession.converterTab
            }
            case "exportSimPreEditTab": {
                return state.tabSession.exportSimPreEditTab
            }
            case "exportSimOutputTab": {
                return state.tabSession.exportSimOutputTab
            }
            case "importSimOutputTab": {
                return state.tabSession.importSimOutputTab
            }
            case "importSimTab": {
                return state.tabSession.importSimTab
            }
            case "scriptExecutionTab": {
                return state.tabSession.scriptExecutionTab
            }
            case "multiScriptTab": {
                return state.tabSession.multiScriptTab
            }
            case "scriptRunnerTypeIsCron": {
                return state.tabSession.scriptRunnerTypeIsCron
            }
            case "docViewerTab": {
                return state.tabSession.docViewerTab
            }
            case "matrixDocTab": {
                return state.tabSession.matrixDocTab
            }
            case "matrixDocInputTab": {
                return state.tabSession.matrixDocInputTab
            }
            case "ediOutputTab": {
                return state.tabSession.ediOutputTab
            }
            case "queuesTab": {
                return state.tabSession.queuesTab
            }
            case "contractsTab": {
                return state.tabSession.contractsTab
            }
            case "contractListTab": {
                return state.tabSession.contractListTab
            }
            case "customerAccountingTab": {
                return state.tabSession.customerAccountingTab
            }
            case "systemStatusTab": {
                return state.tabSession.systemStatusTab
            }
            case "transportOrderRightSideTab": {
                return state.tabSession.transportOrderRightSideTab
            }
            default: {
                return 0
            }
        }
    }
    //
    // LogSession
    //

    state.setLogSession = (logSession: LogSession) => {
        state.logSession = logSession
    }

    state.setSingleLogSession = (origin: string, query: string, logs: string[], extension: string) => {
        let session = state.logSession
        switch (origin) {


            case "exportSim": {
                session.exportSimQuery = query
                session.exportSimLogs = logs
                session.exportSimExtension = extension
                break;
            }
            case "importSim": {
                session.importSimQuery = query
                session.importSimLogs = logs
                session.importSimExtension = extension
                break;
            }
            case "transportOrderConfigTest": {
                session.transportOrderConfigTestQuery = query
                session.transportOrderConfigTestLogs = logs
                session.transportOrderConfigTestExtension = extension
                break;
            }
            case "matrixDoc": {
                session.matrixDocQuery = query
                session.matrixDocLogs = logs
                session.matrixDocExtension = extension
                break;
            }
            case "multiScript": {
                session.multiScriptQuery = query
                session.multiScriptLogs = logs
                session.multiScriptExtension = extension
                break;
            }
            case "singleScript": {
                session.singleScriptQuery = query
                session.singleScriptLogs = logs
                session.singleScriptExtension = extension
                break;
            }
            case "scriptRunner": {
                session.scriptRunnerQuery = query
                session.scriptRunnerLogs = logs
                session.scriptRunnerExtension = extension
                break;
            }
            case "telematicMapping": {
                session.telematicMappingQuery = query
                session.telematicMappingLogs = logs
                session.telematicMappingExtension = extension
                break;
            }
            case "suppressionScriptModal": {
                session.suppressionScriptModalQuery = query
                session.suppressionScriptModalLogs = logs
                session.suppressionScriptModalExtension = extension
                break;
            }
            case "customerReport": {
                session.customerReportQuery = query
                session.customerReportLogs = logs
                session.customerReportExtension = extension
                break;
            }
            case "transportOrderCreateOrModify": {
                session.transportOrderCreateOrModifyQuery = query
                session.transportOrderCreateOrModifyLogs = logs
                session.transportOrderCreateOrModifyExtension = extension
                break;
            }
        }
        state.logSession = session
    }

    state.setCustomerReportXReqId = (customerReportXReqId: string) => {
        state.customerReportXReqId = customerReportXReqId
    }

    return (
        <ConfigContext.Provider value={state}>
            {props.children}
        </ConfigContext.Provider>
    );


}

interface QueueWorkingCopy {
    id: string,
    json: string
}