import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router';

export interface LogeniosContextState {
}

export interface LogeniosContextProps { }

class LogeniosContextR extends React.Component<LogeniosContextProps & RouteComponentProps<any>, LogeniosContextState> {

    constructor(props) {
        super(props);
    }

    render() {
        return this.props.children
    }
}

export const LogeniosContext = withRouter(LogeniosContextR)