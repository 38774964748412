import * as React from "react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {authentication} from "../../../../../../authentication";
import {Button, Label} from "semantic-ui-react";
import AceEditor from "react-ace";
import {Base64} from 'js-base64';
import Notifications, {notify} from 'react-notify-toast';
import {backend} from "../../../../../../xconvert-backend";
import {AddDefaultsToConfigRequest} from "../../../../../../generated";
import {v4 as uuid} from 'uuid';
import { workingConfig } from "../../../configChange/ConfigSignal";

export interface MatrixDocInputTextViewProps {
    changeXRequestId: (string) => void
    output: string
}

export interface MatrixDocInputTextViewState {
    dictionary: string
    isConverting: boolean
    xIdMatrix: string
    xIdTO: string
}

export class MatrixDocInputTextView extends React.Component<MatrixDocInputTextViewProps, MatrixDocInputTextViewState> {
    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading MatrixDocInputTextView")

        this.state = {
            dictionary: "",
            isConverting: false,
            xIdMatrix: "",
            xIdTO: "",
        }
    }

    componentDidMount() {
        if(this.props.output) {
            this.context.setMatrixDoc(this.props.output)
        }
        this.context.setMatrixDocInputActive("TEXT")
    }

    async sendToConverter() {
        this.setState({isConverting: true})

        await this.letConverterCreateMatrix()
        await this.convertToTransportOrders()
        this.props.changeXRequestId("'" + this.state.xIdMatrix + "'" + " OR " + "'" + this.state.xIdTO + "'")

        this.setState({isConverting: false})

    }

    async convertToTransportOrders() {

        let auth = backend.withTokenAuthHeader(authentication.token)

        let request = {} as AddDefaultsToConfigRequest

        let configWithoutDefaults = workingConfig.value

        request.config = JSON.parse(configWithoutDefaults)

        let config = await (await backend.internalApi.addDefaultsToConfig(request, auth)).config
        try {
            let xReqId = uuid()
            this.setState({xIdMatrix: xReqId})

            let response = await fetch('/converter/v3/convertExternalFormatToTransportOrder', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    'x-request-id': xReqId
                },
                body: JSON.stringify({
                    base64Data: Base64.encode(this.context.matrixDoc),
                    config: config,
                    companyId: this.context.companyId
                })
            }).then((response) => {
                if (response.ok) {
                    return response.text()
                } else {
                    throw new Error(response.statusText)
                }
            })
            this.context.setMatrixDocOrders(response)

        } catch (ex) {
            this.context.setMatrixDocOrders(ex)
            notify.show('Error while converting, see Grid!', 'error', 5000, '#fc0303');

        }


    }

    async letConverterCreateMatrix() {
        this.setState({isConverting: true})

        let request = {} as AddDefaultsToConfigRequest
        request.config = JSON.parse(workingConfig.value)

        try {
            let xReqId = uuid()
            this.setState({xIdTO: xReqId})
            let response = await fetch('/converter/csvDocumentToMatrix/true', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    'x-request-id': xReqId
                },
                body: JSON.stringify({
                    base64Data: Base64.encode(this.context.matrixDoc),
                    config: JSON.parse(workingConfig.value),
                    companyId: this.context.companyId,
                })
            }).then((response) => {
                if(response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.statusText)
                }
            })
            console.log("INFO RESPONSE: ", response)
            this.setState({dictionary: JSON.stringify(response, null, 4)})
            this.context.setMatrixDocResult(response)
            notify.show('Successfully converted, check Logs!', 'success', 3000, '#28f751');

        } catch (ex) {
            this.setState({dictionary: ex})
            this.context.setMatrixDocResult(ex)
            notify.show('Error while converting, see Grid!', 'error', 5000, '#fc0303');

        }
    }

    render() {

        return <p>
            <Label>
                External format (e.g. LIS)
            </Label>
            <AceEditor
                theme="monokai"
                mode="json"
                value={this.context.matrixDoc}
                placeholder={'Put external format here (e.g. LIS file)'}
                width="100%"
                height="850px"
                onChange={(value) => this.context.setMatrixDoc(value)}
                readOnly={false}
            />
            <Button onClick={(evt) => {
                this.sendToConverter()
            }}>Convert</Button>
            <Notifications/>

        </p>
    }

}