import * as React from "react";
import { Tab } from 'semantic-ui-react'
import {EditContractRaw} from "./EditContractRaw";
import {EditContractNavTree} from "./EditContractNavTree";

const panes = [
    { menuItem: 'NavTree', render: props => <Tab.Pane id='navTreeTab'><EditContractNavTree saveKeyListener={props.saveKeyListener} fieldPathDispatcher={props.fieldPathDispatcher} readonly={props.readonly}/></Tab.Pane> },
    { menuItem: 'Raw', render: props => <Tab.Pane id='rawTab'><EditContractRaw jsonValidation={props.jsonValidation}/></Tab.Pane> },
]

const EditContractTabView = props => <Tab
    id={"EditContractTabView"}
    className={"flexTab"}
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.contractsTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("contractsTab", data.activeIndex)
    }}
/>

export default EditContractTabView