import * as React from "react";
import {Accordion, Grid, Header, Icon, Modal} from "semantic-ui-react";
import {RawRequest, User} from "../../../../../generated";
import {formatDate} from "../../../../../format";
import AceEditor from "react-ace";

import 'brace/mode/groovy'
import 'brace/ext/searchbox'

interface RawRequestModalProps {
    isOpen: boolean
    onClose: () => void
    rawRequest: RawRequest
}

function RawRequestModal(props: RawRequestModalProps) {

    const [activeIndex, setActiveIndex] = React.useState(0)

    function handleClose() {
        props.onClose()
    }

    function renderInfoGridRow(key: string, content: any) {
        return <Grid.Row>
            <Grid.Column textAlign={"right"} width={2}>
                <h4>{key}</h4>
            </Grid.Column>
            <Grid.Column>
                <p>{content}</p>
            </Grid.Column>
        </Grid.Row>
    }

    function displayUser(user: User | undefined) {
        if (user) {
            let user = props.rawRequest.user
            return user?.email + " (" + user?.firstName + " " + user?.lastName + ")"
        }

        return "user not found"
    }

    function BodyBox(body: string) {

        let beautyBody = body

        try {
            beautyBody = JSON.stringify(JSON.parse(body), null, 4)
        } catch (e) {
            console.log("unable to parse body")
        }

        return  <AceEditor
            theme="monokai"
            mode="json"
            value={beautyBody}
            placeholder={'Empty Body'}
            width="100%"
            height="850px"
            readOnly={true}
        />

    }

    function renderInfoGrid() {

        return <Grid padded="horizontally" columns='equal' centered>
            {renderInfoGridRow("Id", props.rawRequest._id)}
            {renderInfoGridRow("xRequestId", props.rawRequest.xRequestId)}
            {renderInfoGridRow("user", displayUser(props.rawRequest.user))}
            {renderInfoGridRow("Timestamp", formatDate(props.rawRequest.timeOfAttempt))}
            {renderInfoGridRow("Method", props.rawRequest.harRequest?.method)}
            {renderInfoGridRow("Url", props.rawRequest.harRequest?.url)}
            {renderInfoGridRow("Headers", renderHeadersGrid(props.rawRequest.harRequest?.headers))}
            {renderInfoGridRow("Body", BodyBox(props.rawRequest.harRequest?.postData?.text))}
        </Grid>
    }

    function renderHeadersGrid(headers: any[] | undefined) {
        return <Accordion fluid styled>
            <Accordion.Title active={activeIndex === 1} index={1} onClick={() => {
                if (activeIndex == 0) {
                    setActiveIndex(1)
                } else {
                    setActiveIndex(0)
                }
            }
            }>
                <Icon name='dropdown'/>
                <p>Headers</p>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
                <Grid padded="horizontally" stackable columns='equal' centered>
                    {
                        headers?.map((header) => {
                            return <Grid.Row>
                                <Grid.Column>
                                    <h4>{header.name}</h4>
                                </Grid.Column>
                                <Grid.Column>
                                    {header.value}
                                </Grid.Column>
                            </Grid.Row>
                        })
                    }
                </Grid>
            </Accordion.Content>
        </Accordion>

    }

    console.log("RAW REQUEST: ", props.rawRequest)
    return <Modal
        closeOnDimmerClick={false} closeIcon
        open={props.isOpen}
        onClose={handleClose}
        dimmer="blurring"
        size='fullscreen'
    >
        <Header icon='browser' content={'Raw Request'}/>
        <Modal.Content>
            {renderInfoGrid()}
        </Modal.Content>
    </Modal>
}

export default RawRequestModal