import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSourceCode, setConversionLogs, setConversionOutput} from "../../slices/sourceCodeSlice";
import {getActivePage} from "../../slices/tableSlice";
import {tableStateToMatrix2d} from "../../misc/matrix2d";
import {v4 as uuid} from 'uuid';

export interface ExecuteScriptButtonProps{
    lang: ScriptLang
}

export type ScriptLang = "GROOVY" | "KOTLIN"

export function ExecuteScriptButton(props: React.PropsWithChildren<ExecuteScriptButtonProps>){

    const page = useSelector(getActivePage)
    const source = useSelector(getSourceCode)
    const dispatch = useDispatch()
    const[isLoading, setLoading] = React.useState(false)

    const runExtraction = async (evt: React.MouseEvent)=>{
        try{
            setLoading(true)

            const url = "/converter/convertMatrix2dToTransportOrder"
            let xReqId = uuid()
            const response = await fetch(url,{
                method:"POST",
                body: JSON.stringify({
                    "matrix2d": tableStateToMatrix2d(page),
                    "script": source,
                    "inputFileName": page.inputFileName,
                    "scriptLang": props.lang
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'x-request-id': xReqId
                }
            })
            const responseData = await response.json()

            dispatch(setConversionOutput(JSON.stringify(responseData.orders, null, 2)))
            dispatch(setConversionLogs(responseData.logs))
        }catch (e) {
            console.log(e)
        }


        setLoading(false)
    }

    if(isLoading){
        return <span>Bitte warten, wird geladen...</span>
    }

    return <button onClick={runExtraction}>
        Run Script
    </button>
}