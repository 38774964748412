import * as React from "react";
import {Button, Form, FormGroup, Header, Icon, Input, Modal, ModalActions} from 'semantic-ui-react';
import {ModifyFilterName, UserFilter, UserFilterList} from "../../../../../generated";
import {authentication} from "../../../../../authentication";
import {backend} from "../../../../../xconvert-backend";
import {IfBox} from "../../../../style/if";



export interface UserFilterAddProps {
    open: boolean
    onModalClose: () => void
    userFilter: UserFilter | undefined
    filterId: string | undefined
    addFilter: boolean
    editFilter: boolean
    onSave: (fl: UserFilterList)  => Promise<any>
}

export interface UserFilterAddState {
    open: boolean
    isLoading: boolean
    filterName: string | undefined
}

export class UserFilterAddModal extends React.Component<UserFilterAddProps, UserFilterAddState> {

    constructor(props: UserFilterAddProps) {
        super(props)
        this.state = {
            open: this.props.open ?? false,
            isLoading: false,
            filterName: undefined
        }
    }

    componentDidUpdate(prevProps: Readonly<UserFilterAddProps>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.open != this.state.open) {
            this.setState({open: this.props.open})
        }
    }

    onModalClose = () => {
        this.setState({open: false},
            () => {
                this.props.onModalClose()
            })
    }

    async handleFilterNameEdit() {
        this.setState({isLoading: true})

        try {
            console.log("Updating filter")
            let authHeader = backend.withTokenAuthHeader(authentication.token)
            let modifiedFilter = {
                filterId: this.props.filterId,
                filterName: this.state.filterName,
                userId: authentication.user?._id
            } as ModifyFilterName
            if (this.props.filterId) {
               let updatedList = await backend.companyApi.editFilterName(modifiedFilter, authHeader)
                await this.props.onSave(updatedList)
            }

        } catch (e) {
            console.log(e)
        }

        this.setState({isLoading: false})
        this.onModalClose()
    }

    async handleFilterSubmit() {
        this.setState({isLoading: true})

        try {
            console.log("Saving filter")
            let authHeader = backend.withTokenAuthHeader(authentication.token)
            let userFilter = this.props.userFilter
            if (userFilter) {
                userFilter.name = this.state.filterName
               let latestList = await backend.companyApi.addFilters(userFilter, authHeader)
                await this.props.onSave(latestList)

            }

        } catch (e) {
            console.log(e)
        }

        this.setState({isLoading: false})
        this.onModalClose()
    }

    render() {
        return <Modal closeOnDimmerClick={false} closeIcon dimmer="blurring" open={this.state.open}
                      onClose={this.onModalClose}
                      size='small'>
            <Header>
                <IfBox shouldShow={this.props.addFilter}>
                    Add New Filter
                </IfBox>
                <IfBox shouldShow={this.props.editFilter}>
                    Edit Filter
                </IfBox>
            </Header>
            <Modal.Content>
                <Form>
                    <FormGroup>
                        <Form.Field control={Input} label='Filter Name' placeholder='Filter Name' required
                                    value={this.state.filterName}
                                    onChange={(evt: any, data: any) => this.setState({filterName: data.value})}
                                    width={8}/>
                    </FormGroup>
                </Form>

            </Modal.Content>
            <Modal.Actions>
                <Button color='green' id='saveButton'
                        onClick={(event) => this.props.addFilter ? this.handleFilterSubmit() : this.handleFilterNameEdit()}
                        loading={this.state.isLoading}
                >
                    <Icon name='save'/>
                    <IfBox shouldShow={this.props.addFilter}>
                        Save
                    </IfBox>
                    <IfBox shouldShow={this.props.editFilter}>
                        Update
                    </IfBox>
                </Button>
            </Modal.Actions>
        </Modal>
    }
}
