import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";

import MultiOrderScriptTabMenu from "./MultiOrderScriptTabMenu";

export interface MultiOrderScriptExecutionState {
    xRequestId: string
    originalOrders: any[]
}

export class MultiOrderScriptExecution extends React.Component<any, MultiOrderScriptExecutionState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)

        this.state = {
            xRequestId: null,
            originalOrders: []
        }
    }

    render() {
        return <MultiOrderScriptTabMenu
            context={this.context}
            originalOrders={this.state.originalOrders}
            updateOriginalOrders={(originalOrders: any[]) => this.setState({originalOrders: originalOrders})}
            updateXRequestId={(xRequestId: string) => this.setState({xRequestId: xRequestId})}
            xRequestId={this.state.xRequestId}
            history={this.props.history}
        />

    }


}