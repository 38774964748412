import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {Button, ButtonGroup} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {IfBox} from "../../../../style/if";
import {InputFile} from "../../../../util/InputFile";
import {v4 as uuid} from 'uuid';
import {DownloadButton} from "../../../../util/react-download-link/DownloadButton";

export interface InvoicePdfSplittingProps {

}

export interface InvoicePdfSplittingState {
    inputFile: File | undefined
    zipFile: any
    isLoading: boolean
    errorMessage: string | undefined
}

const urlMap = {
    invoiceUrl: '/converter/splitAndRenameZohoInvoiceFile',
    creditNoteUrl: '/converter/splitAndRenameZohoCreditNoteFile'
}

export class InvoicePdfSplitting extends React.Component<InvoicePdfSplittingProps, InvoicePdfSplittingState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;


    constructor(props) {
        super(props)
        this.state = {
            inputFile: undefined,
            isLoading: false,
            zipFile: undefined,
            errorMessage: undefined
        }
    }

    async componentDidMount() {

        let now = new Date()
        let year = now.getFullYear()
        let month = now.getMonth()

        if (month == 0) {
            year = year - 1
            month = 12
        }

        try {


        } catch (e) {
            this.setState({isLoading: false})
        }
    }

    handleUploadedFile(file: File) {
        console.log("handleChange file: ", file)
        this.setState({isLoading: true})
        this.setState({inputFile: file})

        this.setState({isLoading: false})
    }

    getBase64(file: File): Promise<string | undefined> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const blob: string = reader.result as string;
                const base64: string = blob.split("base64,")[1];
                resolve(base64)
            };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }


    async sendToConverter(inFile: File, url: RequestInfo) {
        try {


            let auth = (await backend.withTokenAuthHeader(authentication.token))

            let xReqId = uuid()

            let invoiceAsBase64: string | undefined = await this.getBase64(inFile);
            console.log("invoiceAsBase64: ", invoiceAsBase64)

            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Authorization": auth.headers.Authorization.toString(),
                    "Content-Type": "application/json; charset=utf-8",
                    'x-request-id': xReqId
                },
                body: JSON.stringify({
                    base64Data: invoiceAsBase64
                })
            })

            if (!response.ok) {
                this.setState({errorMessage: response.statusText})
            } else {
                this.setState({errorMessage: undefined})
            }

            console.log(response)

            let file = new Blob([await response.arrayBuffer()], {type: 'application/zip'})
            this.setState({zipFile: file})
        } catch (e) {
            console.error(e)
        } finally {
            this.setState({isLoading: false})
        }
    }

    getFileName(file: File) {
        if (file == null) {
            return ""
        } else {
            return file.name
        }
    }

    render() {

        return <span><InputFile
            button={{}}
            input={{
                id: 'input-control-id',
                onChange: (evt) => this.handleUploadedFile(evt.target.files[0])
            }}
            multiple={false}
        />
            <IfBox shouldShow={this.state.inputFile != null}>
                <ButtonGroup>
                    <Button
                        onClick={() => this.sendToConverter(this.state.inputFile, urlMap.invoiceUrl)}> Split Invoice</Button>
                    <Button onClick={() => this.sendToConverter(this.state.inputFile, urlMap.creditNoteUrl)}> Split Credit Note </Button>
                </ButtonGroup>
            </IfBox>
            <IfBox shouldShow={this.state.zipFile != null}>
                <DownloadButton
                    filename={"splittedFiles.zip"}
                    exportFile={this.state.zipFile}
                />

            </IfBox>
        </span>
    }
}