import React from 'react';
import { diffLines, formatLines } from 'unidiff';
import { parseDiff, Diff, Hunk } from 'react-diff-view';
import tokenize from './tokenize';

import 'react-diff-view/style/index.css';

const EMPTY_HUNKS = [];


const renderToken = (token, defaultRender, i) => {
    switch (token.type) {
        case 'space':
            return (
                <span key={i} className="space">
                    {token.children && token.children.map((token, i) => renderToken(token, defaultRender, i))}
                </span>
            );
        default:
            return defaultRender(token, i);
    }
}


export class DiffWrapper extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            oldText: null,
            newText: null,
            hunks: [],
            tokens: null
        }
    }

    componentDidMount() {
       this.update(this.props.oldText, this.props.newText)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.oldText !== this.state.oldText || nextProps.newText !== this.state.newText) {
            this.update(nextProps.oldText, nextProps.newText)
        }
    }

    update(oldText, newText) {


        if(oldText == null) {
            oldText = ""
        }

        if(newText == null) {
            newText = ""
        }

        const diffText = formatLines(diffLines(oldText, newText), { context: 5 });
        const [diff] = parseDiff(diffText, { nearbySequences: 'zip' });
        this.setState({
            hunks: diff.hunks,
            tokens: tokenize(diff.hunks),
            oldText: oldText,
            newText: newText
        })
    }

    render() {
        console.log(this.state.tokens)

        let format = this.props.format
        if(format == null) {
            format = "yaml"
        }
        console.log("FORMAT (used)", format)

        return (
            <Diff
                highlight
                language={format}
                optimizeSelection
                viewType="split"
                diffType="modify"
                tokens={this.state.tokens}
                renderToken={renderToken}
                hunks={this.state.hunks || EMPTY_HUNKS}>
                {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk}
                />)}
            </Diff>
        );
    }
}