import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getParseOptions, setParseOptions} from "../slices/ruleSlice";
import {ChangeEvent, useEffect, useState} from "react";
import "./OptionsForm.css"

interface OptionsEditFormProps{

}

export function OptionsEditForm(props:React.PropsWithChildren<OptionsEditFormProps>){
    const options = useSelector(getParseOptions)
    const dispatch = useDispatch()

    const deriveState = () => {
        if(!options){
            console.log("********** General Options are not set! **********")
        }
        return {
            dateFormat: options?.dateFormat??"dd.MM.yyyy",
            timeFormat: options?.timeFormat??"hh:mm",
            defaultArriveHour: options?.defaultArriveHour??0,
            defaultDepartHour: options?.defaultDepartHour??23,
            lengthUnit: options?.lengthUnit ?? "m",
            weightUnit: options?.weightUnit ?? "kg",
            numberFormat: options?.numberFormat ?? "german",
            stopListMode: options?.stopListMode ?? false,
            aiPrompts: options?.aiPrompts
        }
    }

    const [formState, setFormState] = useState(deriveState())

    useEffect(()=>{
        setFormState(deriveState())
    }, [options])

    const handleEvent = (evt:ChangeEvent<HTMLInputElement|HTMLSelectElement>)=>{
        let valueToSet : any = evt.target.value
        if(evt.target.type === 'checkbox' && "checked" in evt.target)
            valueToSet = evt.target.checked
        if(evt.target.type === 'number')
            valueToSet = parseFloat(evt.target.value)
        setFormState({
            ...formState,
            [evt.target.name]: valueToSet
        })
    }


    return <>
        <form>
            <fieldset>
                <legend>Date & Time</legend>
                <div className="formGrid">
                    <label>Date Format</label>
                    <input type={"text"} value={formState.dateFormat} name={"dateFormat"} onChange={handleEvent}/>

                    <label>Time Format</label>
                    <input type={"text"} value={formState.timeFormat} name={"timeFormat"} onChange={handleEvent}/>

                    <label>Default Arrive At Hour</label>
                    <input min={0} max={23} type={"number"} value={formState.defaultArriveHour} name={"defaultArriveHour"} onChange={handleEvent}/>

                    <label>Default Depart At Hour</label>
                    <input min={0} max={23} type={"number"} value={formState.defaultDepartHour} name={"defaultDepartHour"} onChange={handleEvent}/>
                </div>
            </fieldset>
            <fieldset>
                <legend>Number Format</legend>
                <div className="formGrid">
                    <label>Number Format</label>
                    <select value={formState.numberFormat} name={"numberFormat"} onChange={handleEvent}>
                        <option key={"german"} value={"german"}>German (, as Decimal separator)</option>
                        <option key={"english"} value={"english"}>English (. as Decimal separator)</option>
                        <option key={"french"} value={"french"}>French</option>
                        <option key={"auto"}>Auto Detect / Handle with care</option>
                    </select>
                </div>
            </fieldset>
            <fieldset>
                <legend>Units</legend>
                <div className="formGrid">
                    <label>Length Unit</label>
                    <select value={formState.lengthUnit} name={"lengthUnit"} onChange={handleEvent}>
                        <option key={"mm"} value={"mm"}>mm</option>
                        <option key={"cm"} value={"cm"}>cm</option>
                        <option key={"m"} value={"m"}>m</option>
                    </select>

                    <label>Weight Unit</label>
                    <select value={formState.weightUnit} name={"weightUnit"} onChange={handleEvent}>
                        <option key={"g"} value={"g"}>g</option>
                        <option key={"kg"} value={"kg"}>kg</option>
                        <option key={"t"} value={"t"}>t</option>
                    </select>
                </div>
            </fieldset>
            <fieldset>
                <legend>Other</legend>
                <div className={"formGrid"}>
                    <label>Allow more than one loading/unloading stop per transport</label>
                    <input name={"stopListMode"} type="checkbox" onChange={handleEvent} checked={formState.stopListMode}/>
                </div>
            </fieldset>
        </form>

        <button onClick={()=>{dispatch(setParseOptions(formState))}}>Save</button>
    </>
}