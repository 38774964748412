import * as React from "react";
import { Modal, Header, Button, Label, Input, Dropdown, DropdownItemProps } from "semantic-ui-react";
import {
    TransportOrder,
    ExpectedResult,
    SaveTransportOrderConfigTestRequest,
    TransportOrderTestEventRequest,
    TransportOrderConfigTestInput,
    TransportOrderConfigTestInputExport,
    TelematicState,
    QueuedEventConversionFileResult
} from "../../../../../generated";
import { ConfigContext } from "../../../context/ConfigContext";
import { backend } from "../../../../../xconvert-backend";
import { authentication } from "../../../../../authentication";

export interface ExportSimSaveAsTestModalProps {
    isOpen: boolean
    onClose: (success: boolean) => void
    eventType: TransportOrderTestEventRequest.EventTypeEnum
    expectedResult: string
    newStatus: string | null
    transportOrder: TransportOrder | null
    telematicState: TelematicState | null
}
export interface ExportSimSaveAsTestModalState {
    testName: string | null
    expectedType: ExpectedResult.TypeEnum | null

}


export class ExportSimSaveAsTestModal extends React.Component<ExportSimSaveAsTestModalProps, ExportSimSaveAsTestModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            testName: "",
            expectedType: null,
        }
    }

    async onSave() {

        let request = {} as SaveTransportOrderConfigTestRequest
        request.companyId =  this.context.companyId
        request.testName = this.state.testName
        request.type = SaveTransportOrderConfigTestRequest.TypeEnum.EXPORT

        let resultList = []
        JSON.parse(this.props.expectedResult).resultFiles.map(result => {
                let expData = {} as QueuedEventConversionFileResult
                expData.base64Content = result.base64Content
                expData.encoding = this.context.exportSimOutputEncoding

                resultList.push(expData)
            })

        request.expectedResult = {} as ExpectedResult
        request.expectedResult.exportDataList = resultList

        request.expectedResult.type = this.state.expectedType

        request.input = {} as TransportOrderConfigTestInput
        request.input.exportData = {} as TransportOrderConfigTestInputExport
        request.input.exportData.transportOrder = this.props.transportOrder
        if(this.context.exportSimQe != null && this.context.exportSimQe != "") {
            request.input.exportData.queuedEvent = JSON.parse(this.context.exportSimQe)
        }

        request.input.exportData.eventType = this.props.eventType
        request.input.exportData.newStatus = this.props.newStatus
        request.input.exportData.telematicState = this.props.telematicState

        console.log("sending the following: " + request)

        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let response = (await backend.internalApi.saveTransportOrderConfigTest(request, auth))
        this.props.onClose(false)

    }

    handleClose = () => {
        this.props.onClose(false)
    }

    enumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(ExpectedResult.TypeEnum).forEach(value => {
            enumValues.push({ key: value, value: value, text: value });
        })
        return enumValues
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="inverted"
            size='small'>
            <Header icon='browser' content='Save as Test' />
            <Modal.Content>

                <Label >Test Name</Label>
                <br />
                <Input style={{ width: 220 }} placeholder='TestName'
                    value={this.state.testName}
                    onChange={(evt) => this.setState({ testName: evt.target.value })}
                />

                <Label >expected data type</Label>
                <br />

                <Dropdown
                    selectOnBlur={false}
                    placeholder='Select Type'
                    fluid
                    search
                    selection
                    options={this.enumToArray()}
                    clearable
                    onChange={(event, { value }) => {
                        console.log(value);
                        let type = event.target;
                        console.log(type);
                        this.setState({ expectedType: value as ExpectedResult.TypeEnum })
                    }}
                />


                <Button onClick={(evt) => this.onSave()} >Save</Button>
                <Button onClick={(evt) => this.handleClose()} >Cancel</Button>


            </Modal.Content>
        </Modal >
    }
}