import * as React from "react";
import {Tab} from 'semantic-ui-react'
import {HistoryTable} from "./HistoryTable";
import {SearchView} from "./SearchView";
import {DraftTable} from "./DraftTable";
import {authentication} from "../../../../../../authentication";
import {FieldDescriptionView} from "./FieldDescriptionView";
import {FieldChangesView} from "./FieldChangesView";

const panesForCompanyAdmins = [
    { pos: 1, menuItem: 'History', render: (props: any) => <Tab.Pane><HistoryTable reloadFromContext={props.reloadFromContext}/></Tab.Pane> },
    { pos: 3, menuItem: 'Drafts', render: (props: any) => <Tab.Pane><DraftTable reloadFunction={props.onCloseCompareModal} /></Tab.Pane> },
    { pos: 4, menuItem: 'Description', render: () => <Tab.Pane><FieldDescriptionView /></Tab.Pane> },
    { pos: 5, menuItem: 'Changes', render: () => <Tab.Pane><FieldChangesView /></Tab.Pane> },
]

function panesForRole() {
    let panes = panesForCompanyAdmins

    if(authentication.isSuperUser &&
    panes.find(pane => pane.menuItem === 'Search') === undefined) {
        panes.push(
            {
                pos: 2,
                menuItem: 'Search',
                render: props => <Tab.Pane>
                    <SearchView
                        auth={props.auth}
                        searchPath={props.fieldPath}
                        onCloseCompareModal={props.onCloseCompareModal}
                    />
                </Tab.Pane>
            }
        )
    }
    return panes.sort((a, b) => a.pos - b.pos)
}

const RightSideTabView = (props: any) => <Tab
        id={"RightSideTabView"}
        className={"flexTab"}
        {...props = props}
        panes={panesForRole()}
        defaultActiveIndex={props.context.tabSession.configRightSideTab}
        onTabChange={(_, data) => {
          props.context.setSingleTabSession("configRightSideTab", data.activeIndex)
        }}

/>

export default RightSideTabView