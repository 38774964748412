import * as React from "react";
import {configStorage} from "../../../../../../ConfigStorage";
import {CompanyConfiguration, GenericXlsConverterConfiguration,} from "../../../../../../generated";
import {ConfigContext} from "../../../../../main/context/ConfigContext";
import {useDispatch, useSelector} from "react-redux";
import {
    getActiveConfig,
    getActiveMode,
    getActiveRules,
    getParseOptions,
    getSaveToConfig,
    getSpecificActiveRules,
    getSplitterOptions,
    selectRules,
    setSaveToConfig
} from "../../slices/ruleSlice";
import "./AutoSaveRulesToConfig.css"
import {updateConfig, workingConfig} from "../../../../../main/pages/configChange/ConfigSignal";


interface LoadRulesFromConfigButtonProps {
}

export function AutoSaveRulesToConfig(props: React.PropsWithChildren<LoadRulesFromConfigButtonProps>) {
    const dispatch = useDispatch()
    const context = React.useContext(ConfigContext)
    const [config, setConfig] = React.useState<CompanyConfiguration>(null)
    const rules = useSelector(selectRules)
    const options = useSelector(getParseOptions)
    const activeConfig = useSelector(getActiveConfig)
    const activeRules = useSelector(getActiveRules)
    const specificActiveRules = useSelector(getSpecificActiveRules)
    const activeMode = useSelector(getActiveMode)
    const updatedSplitterOptions = useSelector(getSplitterOptions)
    const autoSaveConfig = useSelector(getSaveToConfig)

    React.useEffect(() => {
        // console.log("AutoSaveRulesToConfig useEffect");
        if (workingConfig.value) {
            setConfig(JSON.parse(workingConfig.value));
        } else if (context.companyId) {
            // console.log("Fetching config from configStorage");
            configStorage.getElement(context.companyId).then((c) => {
                setConfig(c.config);
                updateConfig(JSON.stringify(c.config, null, 4), false, false);
            }).catch(error => {
                console.error("Error fetching config from configStorage:", error);
            });
        }
    }, [context.companyId]); // added context.companyId to the dependency array


    React.useEffect(() => {
        if (autoSaveConfig){
            saveToConfig().then()
            dispatch(setSaveToConfig(false))
        }
    }, [autoSaveConfig, updatedSplitterOptions])

    async function saveToConfig() {
           if (!config) {
            console.error('No config available.');
            return;
        }

        // Check the loaded config name
        if (activeConfig === "default") {
            // Default config saving logic
            if (!config.genericXlsConfiguration) {
                config.genericXlsConfiguration = {} as GenericXlsConverterConfiguration;
            }
            // Updating input format
            config.genericXlsConfiguration.inputFormat = activeMode

            // Updating splitter options
            // if (activeRules!=="spt") {
                Object.keys(updatedSplitterOptions)?.forEach(key => {
                    if (key != "mode") {
                        if (!config.genericXlsConfiguration.splitterOptions) {
                            config.genericXlsConfiguration.splitterOptions = {
                                aggressiveLineMerging: null,
                                disableHeaderRemoval: null,
                                insertEmptyLines: null,
                                squashBuffer: null,
                                segmentDocuments: null,
                                useElasticCellPlacement: null,
                                splitOnOversizedWhitespaces: null,

                            }
                        }
                        if (config.genericXlsConfiguration.splitterOptions[key] !== updatedSplitterOptions[key]) {
                            config.genericXlsConfiguration.splitterOptions[key] = updatedSplitterOptions[key];
                        }
                    }
                })
            // }

            //Update extraction rules
            if (activeRules == "ext" && rules && rules.length > 0) {
                config.genericXlsConfiguration.extractionRules = JSON.parse(JSON.stringify(rules));
            }

            if (activeRules == "spt" && rules && rules.length > 0) {
                config.genericXlsConfiguration.splittingRules = JSON.parse(JSON.stringify(rules));
            }

            // Update immediate format parser options
            Object.keys(options).forEach(key => {
                if (options[key]) {
                    if (!config.genericXlsConfiguration.immediateFormatParserOptions) {
                        config.genericXlsConfiguration.immediateFormatParserOptions = {
                            dateFormat: null,
                            timeFormat: null,
                            numberFormat: null,
                            weightUnit: null,
                            lengthUnit: null,
                            defaultArriveHour: null,
                            defaultDepartHour: null,
                            stopListMode: null,
                            aiPompts: null
                        }
                    }
                    if (config.genericXlsConfiguration.immediateFormatParserOptions[key] !== options[key]) {
                        config.genericXlsConfiguration.immediateFormatParserOptions[key] = options[key];
                    }
                }
            });
        } else {
            // Specific config saving logic based on loadedConfigName
            // const specificConfig = config.genericXlsConfiguration?.specificConfiguration?.find(sc => sc.verboseName === props.loadedConfigName);
            config.genericXlsConfiguration?.specificConfiguration?.forEach(sc => {
                if (sc.verboseName == activeConfig) {
                    if (specificActiveRules=="spcExt"){
                    sc.override = {
                        ...sc.override, // Spread operator to copy all existing properties
                        extractionRules: JSON.parse(JSON.stringify(rules)),
                        immediateFormatParserOptions: JSON.parse(JSON.stringify(options)),
                        splitterOptions: {
                            ...sc.override.splitterOptions, // Copy existing splitterOptions
                            aggressiveLineMerging: updatedSplitterOptions?.aggressiveLineMerging,
                            disableHeaderRemoval: updatedSplitterOptions?.disableHeaderRemoval,
                            insertEmptyLines: updatedSplitterOptions?.insertEmptyLines,
                            squashBuffer: updatedSplitterOptions?.squashBuffer,
                            segmentDocuments: updatedSplitterOptions?.segmentDocuments,
                            useElasticCellPlacement: updatedSplitterOptions?.useElasticCellPlacement,
                            splitOnOversizedWhitespaces: updatedSplitterOptions?.splitOnOversizedWhitespaces
                        },
                        inputFormat: activeMode
                    };
                    } else{
                        sc.applyWhen = {
                            ...sc.applyWhen,
                            onRuleMatch: JSON.parse(JSON.stringify(rules)),
                        }
                    }
                }
            })
        }

        await updateConfig(JSON.stringify(config, null, 4), true, false);
    }
    // setTimeout(()=>saveToConfig(),1000)
    return <></>
}