import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {Button, Checkbox, Header, Icon, Input, List, ListItem, Modal} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {
    BillingRequest,
    BillWorkLogBookEntriesRequest,
    ProjectWorkLogBookEntry,
    UploadInvoiceToZohoRequest,
    ZohoResponse,
} from "../../../../../generated";
import {IfBox} from "../../../../style/if";
import ReactTable from "react-table";
import Notifications, {notify} from 'react-notify-toast';
import {formatDateFromStringNoTime} from "../../../../../format";
import {DownloadButton} from "../../../../util/react-download-link/DownloadButton";
import {PreviewModal} from "./PreviewModal";

export interface BillingToolPageProps {
}

export function BillingToolPage(props: BillingToolPageProps) {
    const context = useContext(ConfigContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCreation, setIsLoadingCreation] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [exportCurrentCompanyOnly, setExportCurrentCompanyOnly] = useState(false);
    const [exportCreditNotesOnly, setExportCreditNotesOnly] = useState(false);
    const [isLoadingCreatingInvoices, setIsLoadingCreatingInvoices] = useState(false);
    const [isLoadingUploadCreditNotes, setIsLoadingUploadCreditNotes] = useState(false);
    const [maxNumberOfInvoices, setMaxNumberOfInvoices] = useState<number | undefined>(undefined);
    const [numberUploadedToZoho, setNumberUploadedToZoho] = useState<number | undefined>(undefined);
    const [year, setYear] = useState<number | null>(null);
    const [month, setMonth] = useState<number | null>(null);
    const [zipFile, setZipFile] = useState<any>(null);
    const [logEntries, setLogEntries] = useState<ProjectWorkLogBookEntry[]>([]);
    const [allSelected, setAllSelected] = useState(false);
    const [selected, setSelected] = useState<ProjectWorkLogBookEntry[]>([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(25);
    const [sortBy, setSortBy] = useState<'CREATED_DATE' | 'COMPANY_NAME' | 'SHORT_DESCRIPTION' | 'TIME_SPEND_ON_TASK' | 'BILLING_COST'>('CREATED_DATE');
    const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');

    useEffect(() => {
        async function fetchInitialLogEntries() {
            const now = new Date();
            let currentYear = now.getFullYear();
            let currentMonth = now.getMonth();

            if (currentMonth === 0) {
                currentYear -= 1;
                currentMonth = 12;
            }

            setYear(currentYear);
            setMonth(currentMonth);

            try {
                const logEntries = await fetchLogEntries(currentYear, currentMonth);
                setLogEntries(logEntries);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        }

        fetchInitialLogEntries();
    }, []);

    async function fetchLogEntries(y = year, m = month) {
        setIsLoading(true);
        const auth = backend.withTokenAuthHeader(authentication.token);

        const req: BillingRequest = {year: y, month: m};

        const resp = await backend.internalApi.fetchUnbilled(
            req,
            take,
            (page - 1) * take,
            sortBy,
            sortDirection,
            auth
        );

        return resp.workLogEntries;
    }

    async function createBill(currentCompanyOnly: boolean) {
        setIsLoadingCreation(true);
        const auth = await backend.withTokenAuthHeader(authentication.token);

        const req: BillingRequest = {month, year};
        if (currentCompanyOnly) {
            req.companyIdFilter = [context.companyId];
        }

        const response = await fetch('/api/internal/createBilling', {
            method: 'POST',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(req)
        });

        if (response.status !== 200) {
            notify.show("ERROR: Backend responded with status:" + response.status + ": " + response.statusText, 'error', 5000, '#fc0303');
            setIsLoadingCreation(false);
        } else {
            const file = new Blob([await response.arrayBuffer()], {type: 'application/zip'});
            setZipFile(file);
            setIsLoadingCreation(false);
        }
    }

    async function createInvoicesAndUploadToZoho(currentCompanyOnly: boolean, onlyCreditNotes: boolean) {
        let response;
        const auth = await backend.withTokenAuthHeader(authentication.token);
        const req: BillingRequest = {month, year};
        const failedReqs: ZohoResponse[] = [];

        setIsLoadingCreation(true);
        setNumberUploadedToZoho(0);
        setIsLoadingUploadCreditNotes(true);

        if (currentCompanyOnly) {
            req.companyIdFilter = [context.companyId];
        }

        if (!onlyCreditNotes) {
            setIsLoadingCreatingInvoices(true);
            try {
                response = await backend.internalApi.createInvoicesAndSaveToDB(req, auth);
            } catch (e) {
                console.error(e);
            }
            setIsLoadingCreatingInvoices(false);

            if (response.invoiceResponses) {
                let numberUploadedToZoho = 0;
                const invoices = response.invoiceResponses;
                setMaxNumberOfInvoices(invoices.length);
                setNumberUploadedToZoho(numberUploadedToZoho);

                for (const invoice of invoices) {
                    try {
                        const req: UploadInvoiceToZohoRequest = {invoiceId: invoice.invoiceId};
                        const invoiceResponse = await backend.internalApi.uploadInvoiceToZoho(req, auth);
                        failedReqs.push(...invoiceResponse.zohoResponses.filter(resp => resp.statusCode < 200 || resp.statusCode > 201));
                    } catch (e) {
                        console.error(e);
                        const response: ZohoResponse = {
                            statusCode: -1,
                            resultBody: "Exception occurred, check in Zoho if creditNotes worked"
                        };
                        failedReqs.push(response);
                    } finally {
                        numberUploadedToZoho += 1;
                        setNumberUploadedToZoho(numberUploadedToZoho);
                    }
                }
            }
        }

        if (!currentCompanyOnly) {
            try {
                const creditNoteResponse = await backend.internalApi.uploadCreditNotesToZoho(req, auth);
                failedReqs.push(...creditNoteResponse.zohoResponses.filter(resp => resp.statusCode < 200 || resp.statusCode > 201));
                setIsLoadingCreatingInvoices(false);
            } catch (e) {
                console.error(e);
                const response: ZohoResponse = {
                    statusCode: -1,
                    resultBody: "Exception occurred, check in Zoho if creditNotes worked"
                };
                failedReqs.push(response);
            }
        }

        if (failedReqs.length > 0) {
            console.log("failed Requests: ", failedReqs);
            notify.show(
                <div>
                    <List>
                        {failedReqs.map(req => (
                            <ListItem key={req.statusCode}>{req.resultBody}</ListItem>
                        ))}
                    </List>
                    <button onClick={notify.hide}>close</button>
                </div>, "error", -1, '#fc0303'
            );
            setIsLoadingCreation(false);
        } else {
            setIsLoadingCreation(false);
        }
        setIsLoadingCreation(false);
        setIsUploadModalOpen(false);
        setMaxNumberOfInvoices(undefined);
    }

    async function loadLogEntries() {
        try {
            const logEntries = await fetchLogEntries();
            setLogEntries(logEntries);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    function selectOrUnselectAll() {
        if (allSelected) {
            setAllSelected(false);
            setSelected([]);
        } else {
            setAllSelected(true);
            setSelected(logEntries);
        }
    }

    async function billSelected() {
        setIsLoading(true);
        const auth = await backend.withTokenAuthHeader(authentication.token);

        const request: BillWorkLogBookEntriesRequest = {
            logEntryIds: selected.map(s => s._id),
            year,
            month
        };

        const res = await backend.internalApi.billWorkLogBookEntries(request, auth);

        if (res.errors.size > 0) {
            notify.show("ERROR: The following errors appeared: " + JSON.stringify(res.errors, null, 4), 'error', 30000, '#fc0303');
        } else {
            notify.show('successfully billed ' + request.logEntryIds.length + " entries.", 'success', 3000, '#28f751');
        }

        loadLogEntries();
    }

    function switchSelectionStateOfItem(entry: any) {
        setIsLoading(true);
        const array = [...selected];
        const index = array.indexOf(entry);

        if (index === -1) {
            setSelected(array.concat(entry));
            console.log("entry added");
        } else {
            array.splice(index, 1);
            setSelected(array);
            console.log("entry removed");
        }
        setIsLoading(false);
    }

    function changeSort(newSortField: 'CREATED_DATE' | 'COMPANY_NAME' | 'SHORT_DESCRIPTION' | 'TIME_SPEND_ON_TASK' | 'BILLING_COST') {
        let newSortDirection = sortDirection;
        if (sortBy === newSortField) {
            newSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
        }
        setIsLoading(true);
        setSortBy(newSortField);
        setSortDirection(newSortDirection);
        loadLogEntries();
    }

    function changePage(newPageIndex: number) {
        setIsLoading(true);
        setPage(newPageIndex);
        loadLogEntries();
    }

    function changePageSize(newPageSize: number) {
        setIsLoading(true);
        setTake(newPageSize);
        loadLogEntries();
    }

    const columns = [{
        id: 'select',
        Header: 'select',
        width: 50,
        accessor: (d: any) => <Checkbox
            checked={selected.includes(d)}
            onChange={() => switchSelectionStateOfItem(d)}
        />
    }, {
        id: 'createdDate',
        Header: 'createdDate',
        width: 120,
        accessor: (d: any) => formatDateFromStringNoTime(d.createdDate),
    }, {
        id: 'companyName',
        Header: 'companyName',
        width: 300,
        accessor: (d: any) => d.companyName,
    }, {
        id: 'shortDescription',
        Header: 'shortDescription',
        width: 300,
        accessor: (d: any) => d.shortDescription,
    }, {
        id: 'timeSpentOnTask',
        Header: 'timeSpentOnTask',
        width: 130,
        accessor: (d: any) => d.timeSpentOnTask,
    }, {
        id: 'billingCost',
        Header: 'billingCost',
        width: 100,
        accessor: (d: any) => d.billingCost,
    }];

    function filterInput() {
        return <>
            <Input label={'Year'}
                   placeholder='Year' value={year ?? ''}
                   type="number"
                   onChange={evt => {
                       setYear(Number(evt.target.value));
                       setZipFile(null);
                   }}
            />
            <Input label={'Month'}
                   placeholder='Month' value={month ?? ''}
                   type="number"
                   onChange={evt => {
                       setMonth(Number(evt.target.value));
                       setZipFile(null);
                   }}
            />
        </>
    }

    function buttons() {

        return <>
            <Button icon loading={isLoadingCreation} onClick={() => createBill(false)}><Icon
                name={'file excel'}/> Create Bill for all</Button>
            <Button icon loading={isLoadingCreation} onClick={() => createBill(true)}><Icon name={'file excel'}/> Create
                Bill for current company</Button>

            <Button icon loading={isLoadingCreation}
                    onClick={() => {
                        setIsUploadModalOpen(true);
                        setExportCurrentCompanyOnly(false);
                        setExportCreditNotesOnly(false);
                    }}><Icon name={'file excel'}/> Create all and Upload </Button>
            <Button icon loading={isLoadingCreation}
                    onClick={() => {
                        setIsUploadModalOpen(true);
                        setExportCurrentCompanyOnly(true);
                        setExportCreditNotesOnly(false);
                    }}><Icon name={'file excel'}/> Create Invoice for current Company and Upload </Button>
            <Button icon loading={isLoadingCreation}
                    onClick={() => {
                        setIsUploadModalOpen(true);
                        setExportCurrentCompanyOnly(false);
                        setExportCreditNotesOnly(true);
                    }}><Icon name={'file excel'}/> Create CreditNotes and Upload </Button>

            <IfBox shouldShow={zipFile != null}>
                <DownloadButton
                    filename={"billing_" + year + "-" + month + ".zip"}
                    exportFile={zipFile}
                />
            </IfBox>
        </>
    }

    function ZohoConfirmModal() {
        return <Modal
            id='ZohoConfirmModal'
            open={isUploadModalOpen}
            closeOnDimmerClick={false}
            onClose={() => setIsUploadModalOpen(false)}
            dimmer="blurring"
            size='small'>
            <Header icon='send' content='Export to Zoho'/>
            <Modal.Content>
                {maxNumberOfInvoices == null && !isLoadingCreation ?
                    exportCurrentCompanyOnly ? "Export to Zoho for current company?" : exportCreditNotesOnly ? "Export only credit notes?" : "Export to Zoho for all companies?"
                    :
                    <div>
                        <div>
                            <IfBox shouldShow={!exportCreditNotesOnly}>
                                {isLoadingCreatingInvoices ? <Icon loading name='spinner'/> :
                                    <Icon color={"green"} name={'checkmark'}/>}
                                {"Create Invoices"}
                            </IfBox>
                        </div>
                        {!exportCreditNotesOnly ? <div>
                            {numberUploadedToZoho !== maxNumberOfInvoices ?
                                <Icon loading name='spinner'/> : <Icon color={"green"} name={'checkmark'}/>}
                            {numberUploadedToZoho} {" von "} {maxNumberOfInvoices ?? "?"} {" Invoices uploaded to Zoho"}
                        </div> : <></>}
                        {exportCurrentCompanyOnly ? <></> : <div>
                            {isLoadingUploadCreditNotes ? <Icon loading name='spinner'/> :
                                <Icon color={"green"} name={'checkmark'}/>}
                            {"Uploading Credit Notes"}
                        </div>}
                    </div>}
            </Modal.Content>
            <Modal.Actions>
                <Button icon color={'green'} loading={isLoadingCreation}
                        disabled={isLoadingCreation}
                        onClick={() => createInvoicesAndUploadToZoho(exportCurrentCompanyOnly, exportCreditNotesOnly)}><Icon
                    name={'check'}/> Submit</Button>
                <Button icon color={'red'} loading={isLoadingCreation}
                        onClick={() => setIsUploadModalOpen(false)}><Icon
                    name={'close'}/> Close</Button>
            </Modal.Actions>
        </Modal>

    }

    function previewModal() {
        return <PreviewModal
            isOpen={zipFile != null}
            onClose={() => setZipFile(null)}
            zipFile={zipFile}
            year={year}
            month={month}
        />
    }

    return (
        <span>
            {filterInput()}
            {buttons()}
            <br/>

            <Button icon loading={isLoading} onClick={() => loadLogEntries()}><Icon name='sync'/> Reload </Button>

            <br/>
            <span style={{paddingRight: 20}}>
                <Checkbox
                    checked={allSelected}
                    onChange={() => selectOrUnselectAll()}
                /> - select all
            </span>
            <Button icon loading={isLoading} onClick={() => billSelected()}><Icon name='money bill alternate'/> bill selected </Button>

            <ReactTable
                data={logEntries}
                pages={Math.ceil(count / take)}
                columns={columns}
                sorted={[
                    {
                        id: 'TIMESTAMP',
                        desc: false
                    }
                ]}
                onSortedChange={(newSorted, column) => {
                    changeSort(column.sortField);
                }}
                defaultPageSize={take}
                className="-striped -highlight"
                loading={isLoading}
                style={{cursor: "pointer"}}
                onPageChange={(pageIndex) => changePage(pageIndex)}
                onPageSizeChange={(pageSize) => changePageSize(pageSize)}
            />

            <Notifications/>
            {ZohoConfirmModal()}
            {previewModal()}
        </span>
    );
}