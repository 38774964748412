import * as React from "react";
import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {ConfigContext} from "../../../../context/ConfigContext";
import {formatDateFromStringNoTime} from "../../../../../../format";
import ReactTable from "react-table";
import {ProjectWorkLogBookEntry} from "../../../../../../generated";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {Icon} from "semantic-ui-react";

// TODO Fehler bei mehr als 20 Einträgen
export interface ProjectWorkLogListProps {
    logEntrySelected: (projectWorkLogBookEntry: ProjectWorkLogBookEntry) => void
    selectedLogEntry: ProjectWorkLogBookEntry | null
    refreshTrigger: boolean
}


export interface ProjectWorkLogListState {
    isLoading: boolean,
    take: number
    page: number
    sortBy: 'ALREADY_BILLED' | 'CREATED_DATE' | 'SHORT_DESCRIPTION' | 'TIME_SPENT_ON_TASK' | 'BILLING_COST' | 'USER_NAME' | 'BILLING_MODE'
    sortDirection: 'ASC' | 'DESC'
    count: number
    data: any[]
}


export class ProjectWorkLogList extends React.Component<ProjectWorkLogListProps, ProjectWorkLogListState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading ProjectWorkLogList")

        this.state = {
            isLoading: false,
            take: 20,
            page: 0,
            sortBy: 'CREATED_DATE',
            sortDirection: 'ASC',
            count: 0,
            data: []
        }
    }

    async componentDidMount() {
        await this.fetchProjectWorkLogBookEntries()
        const data: any[] | null = this.fillDummyEntries(this.context.projectWorkLogs, this.state.count)
        this.setState({data: data})

    }

    async componentWillReceiveProps(nextProps: Readonly<ProjectWorkLogListProps>, nextContext: any) {

        if (this.props.refreshTrigger != nextProps.refreshTrigger) {
            await this.fetchProjectWorkLogBookEntries()
        }

    }

    async fetchProjectWorkLogBookEntries(take = this.state.take, page = this.state.page): Promise<void> {
        this.setState({isLoading: true})
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        console.log("FetchParameters:", page, take)
        let response = await backend.internalApi.getProjectWorkLogBookEntries(
            null,
            [this.context.companyId],
            take,
            (page) * take,
            this.state.sortBy,
            this.state.sortDirection,
            auth
        )
        this.context.setProjectWorkLogs(response.projectWorkLogBookEntries)
        console.log("Respone: ", response.projectWorkLogBookEntries)

        const data: any[] | null = this.fillDummyEntries(response.projectWorkLogBookEntries, response.total)
        this.setState({count: response.total, data: data, page: page})
        this.setState({isLoading: false})
    }

    unselectRow() {
        this.context.setSelectedProjectWorkLogIndex(null)
    }

    colorRows(index) {
        if (index % 2) {
            return 'white'
            // return '#f7f7f7'
        }
        // return 'white'
        return '#f7f7f7'
    }

    render() {
        const data = this.state.data
        const columns = [{
            id: 'ALREADY_BILLED',
            Header: 'billed',
            width: 50,
            accessor: (d: any) => d.billed,
            Cell: (d: any) => {
                return <span>{this.formatBilled(d.value)}</span>
            },
            sortField: 'ALREADY_BILLED'
        }, {
            id: 'CREATED_DATE',
            Header: 'createDate',
            width: 150,
            accessor: (d: any) => formatDateFromStringNoTime(d.projectWorkLogBookEntry.createdDate),
            sortField: 'CREATED_DATE'
        }, {
            id: 'SHORT_DESCRIPTION',
            Header: 'shortDescription',
            accessor: (d: any) => (d.projectWorkLogBookEntry?.shortDescription),
            sortField: 'SHORT_DESCRIPTION'
        }, {
            id: 'BILLING_MODE',
            Header: 'billingMode',
            accessor: (d: any) => (d.projectWorkLogBookEntry?.billingMode),
            sortField: 'BILLING_MODE'
        }, {
            id: 'TIME_SPENT_ON_TASK',
            Header: 'timeSpentOnTask',
            accessor: (d: any) => (d.projectWorkLogBookEntry?.timeSpentOnTask),
            sortField: 'TIME_SPENT_ON_TASK'
        }, {
            id: 'BILLING_COST',
            Header: 'billingCost',
            accessor: (d: any) => (d.projectWorkLogBookEntry?.billingCost),
            sortField: 'BILLING_COST'
        }, {
            id: 'USER_NAME',
            Header: 'userName',
            accessor: (d: any) => d.userName,
            sortField: 'USER_NAME'
        }
        ]

        return <p>

            <ReactTable
                data={data}
                pages={Math.ceil(this.state.count / this.state.take)}
                page={this.state.page}
                columns={columns}
                sorted={[]}
                onSortedChange={(newSorted, column, shiftKey) => {
                    this.changeSort(column.sortField)
                }}

                defaultPageSize={this.state.take}
                className="-striped -highlight"
                loading={this.state.isLoading}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (rowInfo && rowInfo.row) {
                        return {

                            onClick: (e) => {

                                this.context.setSelectedProjectWorkLogIndex(rowInfo.index)

                                this.selectProjectLogBookEntry(rowInfo.row._original.projectWorkLogBookEntry)
                            },
                            style: {
                                background: rowInfo.index === this.context.selectedProjectWorkLogIndex ? '#00afec' : this.colorRows(rowInfo.index),
                                color: rowInfo.index === this.context.selectedProjectWorkLogIndex ? 'white' : 'black'
                            }
                        }
                    } else {
                        return {}
                    }

                }}
                style={{cursor: "pointer"}}
                onPageChange={(page) => this.changePage(page)}
                onPageSizeChange={(pageSize) => this.changePageSize(pageSize)}

            />

        </p>

    }

    formatBilled(billedAtTime: BilledEnum) {
        if (billedAtTime == undefined) {
            return <Icon name='x' color='red'/>
        } else if (billedAtTime == BilledEnum.BILLED) {
            return <Icon name='check' color='green'/>
        } else {
            return <Icon name='x' color='red'/>
        }
    }

    selectProjectLogBookEntry(logEntry) {
        this.setState({isLoading: true})
        this.context.setSelectedProjectWorkLogBookEntry(logEntry)
        this.props.logEntrySelected(logEntry)


        this.fetchProjectWorkLogBookEntries()
        // this.setState({isLoading: false})

    }

    async changeSort(sortBy: 'ALREADY_BILLED' | 'CREATED_DATE' | 'SHORT_DESCRIPTION' | 'TIME_SPENT_ON_TASK' | 'BILLING_COST' | 'USER_NAME' | 'BILLING_MODE'
    ) {
        //TODO doesn't do anything
        console.log("changing sort")
        this.setState({isLoading: true})


        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({sortDirection: direction})
        } else {
            //change sortBy and reset direction
            this.setState({sortBy: sortBy, sortDirection: "ASC"})
        }

        await this.fetchProjectWorkLogBookEntries()
        // this.setState({ isLoading: false })

    }

    async changePage(pageIndex: number) {
        /*        console.log("changePage: ", pageIndex)
                this.setState({isLoading: true })
                await this.fetchProjectWorkLogBookEntries(this.state.take, pageIndex)
                this.setState({page: pageIndex > 0 ? pageIndex : 0 })
                console.log("data after change page: ", this.state.data)*/

        // this.setState({isLoading: true})


        // await  this.delay(3000)

        this.fetchProjectWorkLogBookEntries(this.state.take, pageIndex)

        // await  this.delay(3000)

        // this.setState({
        //     page: pageIndex > 0 ? pageIndex : 0,
        // }/*, this.fetchProjectWorkLogBookEntries*/)


        console.log("Data after changePage: ", this.state.data)
    }

    /*delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }*/

    changePageSize(newSize: number) {
        this.setState({take: newSize, isLoading: true})
        this.fetchProjectWorkLogBookEntries(newSize, this.state.page)
    }


    fillDummyEntries(logBookEntries: ProjectWorkLogBookEntry[], totalAmount: number) {
        let dummy = {} as ProjectWorkLogBookEntry
        let currentEntries: ProjectWorkLogBookEntry[] = []

        let indexOffset = (this.state.page) * this.state.take
        console.log("Index Offset: ", indexOffset)
        let lastActualFileIndex = indexOffset + this.state.take - 1

        console.log("totalAmount: ", totalAmount)
        for (let i = 0; i < totalAmount; i++) {

            if (i < indexOffset) {
                currentEntries.push(dummy)
            } else if (i >= indexOffset) {
                if (i <= lastActualFileIndex) {
                    console.log("take index: " + (i - indexOffset))

                    currentEntries.push(logBookEntries[i - indexOffset])
                    console.log("took: " + logBookEntries[i - indexOffset])

                } else {
                    currentEntries.push(dummy)
                }

            }
        }

        /*for (let i = 0; i < logBookEntries.length; i++) {
            currentEntries.push(logBookEntries[i])
        }*/
        while (currentEntries.length < totalAmount) {

            console.log("adding a dummy")
            currentEntries.push(dummy)
        }

        let entriesWithBilled = []
        let currentActiveIndex = 0
        currentEntries.map((entry) => {
            let billed = undefined
            let name
            let entryWithBilled = {} as ProjectWorkLogBookEntryWithBilled
            console.log("entry_in_map:", entry)
            if (entry != undefined) {
                if (entry.userId != undefined) {
                    name = entry.userFirstName + " " + entry.userLastName
                    if (entry.billedAtTime != undefined) {
                        billed = BilledEnum.BILLED
                    } else {
                        billed = BilledEnum.NOT_BILLED
                    }
                }
            }
            entryWithBilled.projectWorkLogBookEntry = entry
            entryWithBilled.billed = billed
            entryWithBilled.userName = name
            entriesWithBilled.push(entryWithBilled)
        })

        console.log("EntriesWithBilled: ", entriesWithBilled)
        return entriesWithBilled
    }


}

class ProjectWorkLogBookEntryWithBilled {
    projectWorkLogBookEntry: ProjectWorkLogBookEntry | undefined
    billed: BilledEnum | undefined
    userName: String | undefined
}

enum BilledEnum {
    BILLED,
    NOT_BILLED
}