import * as React from "react";
import {Dropdown} from "semantic-ui-react";


export let EncodingsUTF = ["UTF-8"]
export let EncodingsISO = ["ISO-8859-1", "ISO-8859-2", "ISO-8859-3", "ISO-8859-4", "ISO-8859-5", "ISO-8859-6", "ISO-8859-7", "ISO-8859-8", "ISO-8859-9", "ISO-8859-13", "ISO-8859-15"]
export let EncodingsWindows = ["windows-1250", "windows-1251", "windows-1252", "windows-1253", "windows-1254", "windows-1257", "windows-1255", "windows-1256", "windows-1258", "windows-31j"]
export let EncodingsOthers = ["KOI8-R", "KOI8-U"]

export interface EncodingSelectorProps {
    encoding: string
    onChange: (encoding: string) => void
}

export function EncodingSelector(props: React.PropsWithChildren<EncodingSelectorProps>) {

    function toOptions(encodings: string[]) {
        let opt = []
        Object.values(encodings).forEach(value => {
            opt.push({ text: value })
        })
        return opt
    }

    function dropDownOptions() {

        const utfOptions = toOptions(EncodingsUTF)
        const isoOptions = toOptions(EncodingsISO)
        const windowsOptions = toOptions(EncodingsWindows)
        const otherOptions = toOptions(EncodingsOthers)

        let options = []


        Object.values(utfOptions).forEach(value => {
            options.push(value)
        })
        options.push({ divider: true })
        Object.values(isoOptions).forEach(value => {
            options.push(value)
        })
        options.push({ divider: true })
        Object.values(windowsOptions).forEach(value => {
            options.push(value)
        })
        options.push({ divider: true })
        Object.values(otherOptions).forEach(value => {
            options.push(value)
        })

        return options
    }

    return <Dropdown text={props.encoding} style={{ align: 'right' }}>
        <Dropdown.Menu>

            {dropDownOptions().map((option, i) => {
                if (option.divider === true) return (<Dropdown.Divider key={i} />);
                return (
                    <Dropdown.Item
                        key={i}
                        text={option.text}
                        onClick={(event, { text }) => {
                            props.onChange(text.toString());
                        }}
                    />
                );
            })}
        </Dropdown.Menu>
    </Dropdown>
}