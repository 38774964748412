/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * XConvert API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://localhost:9800/ftp".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *  
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AddFileRequest
 */
export interface AddFileRequest {
    /**
     * 
     * @type {string}
     * @memberof AddFileRequest
     */
    base64Content?: string;
    /**
     * 
     * @type {string}
     * @memberof AddFileRequest
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof AddFileRequest
     */
    encoding?: string;
    /**
     * 
     * @type {string}
     * @memberof AddFileRequest
     */
    fileName?: string;
}

/**
 * 
 * @export
 * @interface AddFilesRequest
 */
export interface AddFilesRequest {
    /**
     * 
     * @type {Array<AddFileRequest>}
     * @memberof AddFilesRequest
     */
    files?: Array<AddFileRequest>;
}

/**
 * 
 * @export
 * @interface AddFilesResponse
 */
export interface AddFilesResponse {
    /**
     * 
     * @type {string}
     * @memberof AddFilesResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddFilesResponse
     */
    success?: boolean;
}

/**
 * 
 * @export
 * @interface CreateZipResponse
 */
export interface CreateZipResponse {
    /**
     * 
     * @type {FileSystemEntry}
     * @memberof CreateZipResponse
     */
    file?: FileSystemEntry;
    /**
     * 
     * @type {boolean}
     * @memberof CreateZipResponse
     */
    success?: boolean;
}

/**
 * 
 * @export
 * @interface DeleteFileResponse
 */
export interface DeleteFileResponse {
    /**
     * 
     * @type {string}
     * @memberof DeleteFileResponse
     */
    deletedPath?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFileResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteFileResponse
     */
    success?: boolean;
}

/**
 * 
 * @export
 * @interface DownloadFilesAsZipRequest
 */
export interface DownloadFilesAsZipRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DownloadFilesAsZipRequest
     */
    fileNames?: Array<string>;
}

/**
 * 
 * @export
 * @interface FileHashResponse
 */
export interface FileHashResponse {
    /**
     * 
     * @type {string}
     * @memberof FileHashResponse
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof FileHashResponse
     */
    md5Hash?: string;
}

/**
 * 
 * @export
 * @interface FileSystemEntry
 */
export interface FileSystemEntry {
    /**
     * 
     * @type {Date}
     * @memberof FileSystemEntry
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof FileSystemEntry
     */
    fileKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FileSystemEntry
     */
    isDirectory?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FileSystemEntry
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof FileSystemEntry
     */
    size?: number;
    /**
     * 
     * @type {Date}
     * @memberof FileSystemEntry
     */
    updated?: Date;
}

/**
 * Arbitrary key value pairs
 * @export
 * @interface HashMap
 */
export interface HashMap {
}

/**
 * 
 * @export
 * @interface ListFilesResponse
 */
export interface ListFilesResponse {
    /**
     * 
     * @type {Array<FileSystemEntry>}
     * @memberof ListFilesResponse
     */
    files?: Array<FileSystemEntry>;
    /**
     * 
     * @type {number}
     * @memberof ListFilesResponse
     */
    page?: number;
    /**
     * 
     * @type {string}
     * @memberof ListFilesResponse
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof ListFilesResponse
     */
    take?: number;
    /**
     * 
     * @type {number}
     * @memberof ListFilesResponse
     */
    totalFiles?: number;
}

/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    status?: ModelFile.StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    prefixLength?: number;
    /**
     * 
     * @type {Path}
     * @memberof ModelFile
     */
    filePath?: Path;
}

/**
 * @export
 * @namespace ModelFile
 */
export namespace ModelFile {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        INVALID = 'INVALID',
        CHECKED = 'CHECKED'
    }
}

/**
 * 
 * @export
 * @interface MonitoredFileOldResponse
 */
export interface MonitoredFileOldResponse {
    /**
     * 
     * @type {string}
     * @memberof MonitoredFileOldResponse
     */
    companyId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitoredFileOldResponse
     */
    files?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoredFileOldResponse
     */
    old?: boolean;
}

/**
 * 
 * @export
 * @interface MoveFileRequest
 */
export interface MoveFileRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MoveFileRequest
     */
    copy?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MoveFileRequest
     */
    fileNames?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MoveFileRequest
     */
    targetFileNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MoveFileRequest
     */
    targetPath?: string;
}

/**
 * 
 * @export
 * @interface MoveFileResponse
 */
export interface MoveFileResponse {
    /**
     * 
     * @type {string}
     * @memberof MoveFileResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof MoveFileResponse
     */
    newPath?: string;
    /**
     * 
     * @type {string}
     * @memberof MoveFileResponse
     */
    oldPath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MoveFileResponse
     */
    success?: boolean;
}

/**
 * 
 * @export
 * @interface OpenFileRequest
 */
export interface OpenFileRequest {
    /**
     * 
     * @type {string}
     * @memberof OpenFileRequest
     */
    fileName?: string;
}

/**
 * 
 * @export
 * @interface Path
 */
export interface Path {
}

/**
 * 
 * @export
 * @interface RenameFileRequest
 */
export interface RenameFileRequest {
    /**
     * 
     * @type {string}
     * @memberof RenameFileRequest
     */
    newName?: string;
    /**
     * 
     * @type {string}
     * @memberof RenameFileRequest
     */
    oldName?: string;
}

/**
 * 
 * @export
 * @interface RenameFileResponse
 */
export interface RenameFileResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RenameFileResponse
     */
    success?: boolean;
}

/**
 * 
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    newPath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadFileResponse
     */
    success?: boolean;
    /**
     * 
     * @type {any}
     * @memberof UploadFileResponse
     */
    uploadedFile?: any;
}


/**
 * FTPApi - fetch parameter creator
 * @export
 */
export const FTPApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add text as file to a given location.
         * @summary Add file
         * @param {string} path path
         * @param {AddFileRequest} AddFileRequest AddFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFile(path: string, AddFileRequest: AddFileRequest, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling addFile.');
            }
            // verify required parameter 'AddFileRequest' is not null or undefined
            if (AddFileRequest === null || AddFileRequest === undefined) {
                throw new RequiredError('AddFileRequest','Required parameter AddFileRequest was null or undefined when calling addFile.');
            }
            const localVarPath = `/files/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddFileRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(AddFileRequest || {}) : (AddFileRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add text as file to a given location.
         * @summary Add files
         * @param {string} path path
         * @param {AddFilesRequest} AddFilesRequest AddFilesRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFiles(path: string, AddFilesRequest: AddFilesRequest, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling addFiles.');
            }
            // verify required parameter 'AddFilesRequest' is not null or undefined
            if (AddFilesRequest === null || AddFilesRequest === undefined) {
                throw new RequiredError('AddFilesRequest','Required parameter AddFilesRequest was null or undefined when calling addFiles.');
            }
            const localVarPath = `/files/addMultipleFiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddFilesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(AddFilesRequest || {}) : (AddFilesRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gives back the content of a file
         * @summary opens a file
         * @param {string} file file
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFileHashFromRoot(file: string, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling calculateFileHashFromRoot.');
            }
            const localVarPath = `/file-hash/{file}`
                .replace(`{${"file"}}`, encodeURIComponent(String(file)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Move a file to another location.
         * @summary create directory
         * @param {string} path path
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectory(path: string, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling createDirectory.');
            }
            const localVarPath = `/files/createDirectory`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * packs files as zip
         * @summary packs files as zip
         * @param {string} path path
         * @param {DownloadFilesAsZipRequest} DownloadFilesAsZipRequest DownloadFilesAsZipRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createZip(path: string, DownloadFilesAsZipRequest: DownloadFilesAsZipRequest, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling createZip.');
            }
            // verify required parameter 'DownloadFilesAsZipRequest' is not null or undefined
            if (DownloadFilesAsZipRequest === null || DownloadFilesAsZipRequest === undefined) {
                throw new RequiredError('DownloadFilesAsZipRequest','Required parameter DownloadFilesAsZipRequest was null or undefined when calling createZip.');
            }
            const localVarPath = `/createZip`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DownloadFilesAsZipRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(DownloadFilesAsZipRequest || {}) : (DownloadFilesAsZipRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a file based on path and filename.
         * @summary Delete file
         * @param {string} path path
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(path: string, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling deleteFile.');
            }
            const localVarPath = `/files`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finds if a company has files in the ROOT/companyId/logenios2customer directory that are older than 5 minutes.
         * @summary get files from the ROOT/logenios2customer directories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogenios2CustomerForAllCompanies(options: any = {}): FetchArgs {
            const localVarPath = `/monitoring/logenios2customer/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finds if a company has files in the LOGS/companyId/ERRORS directory that are older than 10 minutes in centralized way.
         * @summary get old files from the LOGS/ERRORS directories centralized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOldErrorFilesForAllCompanies(options: any = {}): FetchArgs {
            const localVarPath = `/monitoring/errorfiles/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finds if a company's status.json file, if it exists, has a LastRun value in the last 5 minutes.
         * @summary get old status.json
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOldStatusJsonForAllCompanies(options: any = {}): FetchArgs {
            const localVarPath = `/monitoring/status/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists all files inside the given path. Directories will be listed as files.
         * @summary list files of directory
         * @param {string} [companyId] companyId
         * @param {Date} [fromTime] fromTime
         * @param {number} [page] page
         * @param {string} [path] path
         * @param {string} [query] query
         * @param {boolean} [queryContent] queryContent
         * @param {'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED'} [sortBy] sortBy
         * @param {'ASC' | 'DESC'} [sortDirection] sortDirection
         * @param {number} [take] take
         * @param {Date} [toTime] toTime
         * @param {boolean} [useModifiedTime] useModifiedTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFiles(companyId?: string, fromTime?: Date, page?: number, path?: string, query?: string, queryContent?: boolean, sortBy?: 'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED', sortDirection?: 'ASC' | 'DESC', take?: number, toTime?: Date, useModifiedTime?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/files`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any).toISOString();
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (queryContent !== undefined) {
                localVarQueryParameter['queryContent'] = queryContent;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any).toISOString();
            }

            if (useModifiedTime !== undefined) {
                localVarQueryParameter['useModifiedTime'] = useModifiedTime;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Move a file to another location.
         * @summary Move file
         * @param {string} path path
         * @param {MoveFileRequest} MoveFileRequest MoveFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFile(path: string, MoveFileRequest: MoveFileRequest, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling moveFile.');
            }
            // verify required parameter 'MoveFileRequest' is not null or undefined
            if (MoveFileRequest === null || MoveFileRequest === undefined) {
                throw new RequiredError('MoveFileRequest','Required parameter MoveFileRequest was null or undefined when calling moveFile.');
            }
            const localVarPath = `/files/move`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoveFileRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(MoveFileRequest || {}) : (MoveFileRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gives back the content of a text file
         * @summary opens a file
         * @param {string} path path
         * @param {OpenFileRequest} OpenFileRequest OpenFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openFile(path: string, OpenFileRequest: OpenFileRequest, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling openFile.');
            }
            // verify required parameter 'OpenFileRequest' is not null or undefined
            if (OpenFileRequest === null || OpenFileRequest === undefined) {
                throw new RequiredError('OpenFileRequest','Required parameter OpenFileRequest was null or undefined when calling openFile.');
            }
            const localVarPath = `/openFile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OpenFileRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(OpenFileRequest || {}) : (OpenFileRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gives back the content of a file
         * @summary opens a file
         * @param {string} file file
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openFileFromRoot(file: string, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling openFileFromRoot.');
            }
            const localVarPath = `/files/{file}`
                .replace(`{${"file"}}`, encodeURIComponent(String(file)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add text as file to a given location.
         * @summary Rename file
         * @param {string} path path
         * @param {RenameFileRequest} RenameFileRequest RenameFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFile(path: string, RenameFileRequest: RenameFileRequest, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling renameFile.');
            }
            // verify required parameter 'RenameFileRequest' is not null or undefined
            if (RenameFileRequest === null || RenameFileRequest === undefined) {
                throw new RequiredError('RenameFileRequest','Required parameter RenameFileRequest was null or undefined when calling renameFile.');
            }
            const localVarPath = `/files/rename`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RenameFileRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(RenameFileRequest || {}) : (RenameFileRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload file to a given location.
         * @summary Upload file
         * @param {string} path path
         * @param {any} file file to upload
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(path: string, file: any, companyId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling uploadFile.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadFile.');
            }
            const localVarPath = `/files/upload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication basicAuth required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarHeaderParameter["Authorization"] = "Basic " + btoa(configuration.username + ":" + configuration.password);
            }

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FTPApi - functional programming interface
 * @export
 */
export const FTPApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Add text as file to a given location.
         * @summary Add file
         * @param {string} path path
         * @param {AddFileRequest} AddFileRequest AddFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFile(path: string, AddFileRequest: AddFileRequest, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadFileResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).addFile(path, AddFileRequest, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Add text as file to a given location.
         * @summary Add files
         * @param {string} path path
         * @param {AddFilesRequest} AddFilesRequest AddFilesRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFiles(path: string, AddFilesRequest: AddFilesRequest, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AddFilesResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).addFiles(path, AddFilesRequest, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gives back the content of a file
         * @summary opens a file
         * @param {string} file file
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFileHashFromRoot(file: string, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FileHashResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).calculateFileHashFromRoot(file, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Move a file to another location.
         * @summary create directory
         * @param {string} path path
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectory(path: string, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoveFileResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).createDirectory(path, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * packs files as zip
         * @summary packs files as zip
         * @param {string} path path
         * @param {DownloadFilesAsZipRequest} DownloadFilesAsZipRequest DownloadFilesAsZipRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createZip(path: string, DownloadFilesAsZipRequest: DownloadFilesAsZipRequest, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateZipResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).createZip(path, DownloadFilesAsZipRequest, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Delete a file based on path and filename.
         * @summary Delete file
         * @param {string} path path
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(path: string, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeleteFileResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).deleteFile(path, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Finds if a company has files in the ROOT/companyId/logenios2customer directory that are older than 5 minutes.
         * @summary get files from the ROOT/logenios2customer directories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogenios2CustomerForAllCompanies(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MonitoredFileOldResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).getLogenios2CustomerForAllCompanies(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Finds if a company has files in the LOGS/companyId/ERRORS directory that are older than 10 minutes in centralized way.
         * @summary get old files from the LOGS/ERRORS directories centralized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOldErrorFilesForAllCompanies(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MonitoredFileOldResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).getOldErrorFilesForAllCompanies(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Finds if a company's status.json file, if it exists, has a LastRun value in the last 5 minutes.
         * @summary get old status.json
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOldStatusJsonForAllCompanies(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MonitoredFileOldResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).getOldStatusJsonForAllCompanies(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Lists all files inside the given path. Directories will be listed as files.
         * @summary list files of directory
         * @param {string} [companyId] companyId
         * @param {Date} [fromTime] fromTime
         * @param {number} [page] page
         * @param {string} [path] path
         * @param {string} [query] query
         * @param {boolean} [queryContent] queryContent
         * @param {'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED'} [sortBy] sortBy
         * @param {'ASC' | 'DESC'} [sortDirection] sortDirection
         * @param {number} [take] take
         * @param {Date} [toTime] toTime
         * @param {boolean} [useModifiedTime] useModifiedTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFiles(companyId?: string, fromTime?: Date, page?: number, path?: string, query?: string, queryContent?: boolean, sortBy?: 'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED', sortDirection?: 'ASC' | 'DESC', take?: number, toTime?: Date, useModifiedTime?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListFilesResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).listFiles(companyId, fromTime, page, path, query, queryContent, sortBy, sortDirection, take, toTime, useModifiedTime, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Move a file to another location.
         * @summary Move file
         * @param {string} path path
         * @param {MoveFileRequest} MoveFileRequest MoveFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFile(path: string, MoveFileRequest: MoveFileRequest, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoveFileResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).moveFile(path, MoveFileRequest, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gives back the content of a text file
         * @summary opens a file
         * @param {string} path path
         * @param {OpenFileRequest} OpenFileRequest OpenFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openFile(path: string, OpenFileRequest: OpenFileRequest, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).openFile(path, OpenFileRequest, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gives back the content of a file
         * @summary opens a file
         * @param {string} file file
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openFileFromRoot(file: string, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).openFileFromRoot(file, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Add text as file to a given location.
         * @summary Rename file
         * @param {string} path path
         * @param {RenameFileRequest} RenameFileRequest RenameFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFile(path: string, RenameFileRequest: RenameFileRequest, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RenameFileResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).renameFile(path, RenameFileRequest, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Upload file to a given location.
         * @summary Upload file
         * @param {string} path path
         * @param {any} file file to upload
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(path: string, file: any, companyId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadFileResponse> {
            const localVarFetchArgs = FTPApiFetchParamCreator(configuration).uploadFile(path, file, companyId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FTPApi - factory interface
 * @export
 */
export const FTPApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Add text as file to a given location.
         * @summary Add file
         * @param {string} path path
         * @param {AddFileRequest} AddFileRequest AddFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFile(path: string, AddFileRequest: AddFileRequest, companyId?: string, options?: any) {
            return FTPApiFp(configuration).addFile(path, AddFileRequest, companyId, options)(fetch, basePath);
        },
        /**
         * Add text as file to a given location.
         * @summary Add files
         * @param {string} path path
         * @param {AddFilesRequest} AddFilesRequest AddFilesRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFiles(path: string, AddFilesRequest: AddFilesRequest, companyId?: string, options?: any) {
            return FTPApiFp(configuration).addFiles(path, AddFilesRequest, companyId, options)(fetch, basePath);
        },
        /**
         * gives back the content of a file
         * @summary opens a file
         * @param {string} file file
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFileHashFromRoot(file: string, companyId?: string, options?: any) {
            return FTPApiFp(configuration).calculateFileHashFromRoot(file, companyId, options)(fetch, basePath);
        },
        /**
         * Move a file to another location.
         * @summary create directory
         * @param {string} path path
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectory(path: string, companyId?: string, options?: any) {
            return FTPApiFp(configuration).createDirectory(path, companyId, options)(fetch, basePath);
        },
        /**
         * packs files as zip
         * @summary packs files as zip
         * @param {string} path path
         * @param {DownloadFilesAsZipRequest} DownloadFilesAsZipRequest DownloadFilesAsZipRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createZip(path: string, DownloadFilesAsZipRequest: DownloadFilesAsZipRequest, companyId?: string, options?: any) {
            return FTPApiFp(configuration).createZip(path, DownloadFilesAsZipRequest, companyId, options)(fetch, basePath);
        },
        /**
         * Delete a file based on path and filename.
         * @summary Delete file
         * @param {string} path path
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(path: string, companyId?: string, options?: any) {
            return FTPApiFp(configuration).deleteFile(path, companyId, options)(fetch, basePath);
        },
        /**
         * Finds if a company has files in the ROOT/companyId/logenios2customer directory that are older than 5 minutes.
         * @summary get files from the ROOT/logenios2customer directories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogenios2CustomerForAllCompanies(options?: any) {
            return FTPApiFp(configuration).getLogenios2CustomerForAllCompanies(options)(fetch, basePath);
        },
        /**
         * Finds if a company has files in the LOGS/companyId/ERRORS directory that are older than 10 minutes in centralized way.
         * @summary get old files from the LOGS/ERRORS directories centralized
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOldErrorFilesForAllCompanies(options?: any) {
            return FTPApiFp(configuration).getOldErrorFilesForAllCompanies(options)(fetch, basePath);
        },
        /**
         * Finds if a company's status.json file, if it exists, has a LastRun value in the last 5 minutes.
         * @summary get old status.json
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOldStatusJsonForAllCompanies(options?: any) {
            return FTPApiFp(configuration).getOldStatusJsonForAllCompanies(options)(fetch, basePath);
        },
        /**
         * Lists all files inside the given path. Directories will be listed as files.
         * @summary list files of directory
         * @param {string} [companyId] companyId
         * @param {Date} [fromTime] fromTime
         * @param {number} [page] page
         * @param {string} [path] path
         * @param {string} [query] query
         * @param {boolean} [queryContent] queryContent
         * @param {'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED'} [sortBy] sortBy
         * @param {'ASC' | 'DESC'} [sortDirection] sortDirection
         * @param {number} [take] take
         * @param {Date} [toTime] toTime
         * @param {boolean} [useModifiedTime] useModifiedTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFiles(companyId?: string, fromTime?: Date, page?: number, path?: string, query?: string, queryContent?: boolean, sortBy?: 'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED', sortDirection?: 'ASC' | 'DESC', take?: number, toTime?: Date, useModifiedTime?: boolean, options?: any) {
            return FTPApiFp(configuration).listFiles(companyId, fromTime, page, path, query, queryContent, sortBy, sortDirection, take, toTime, useModifiedTime, options)(fetch, basePath);
        },
        /**
         * Move a file to another location.
         * @summary Move file
         * @param {string} path path
         * @param {MoveFileRequest} MoveFileRequest MoveFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveFile(path: string, MoveFileRequest: MoveFileRequest, companyId?: string, options?: any) {
            return FTPApiFp(configuration).moveFile(path, MoveFileRequest, companyId, options)(fetch, basePath);
        },
        /**
         * gives back the content of a text file
         * @summary opens a file
         * @param {string} path path
         * @param {OpenFileRequest} OpenFileRequest OpenFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openFile(path: string, OpenFileRequest: OpenFileRequest, companyId?: string, options?: any) {
            return FTPApiFp(configuration).openFile(path, OpenFileRequest, companyId, options)(fetch, basePath);
        },
        /**
         * gives back the content of a file
         * @summary opens a file
         * @param {string} file file
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openFileFromRoot(file: string, companyId?: string, options?: any) {
            return FTPApiFp(configuration).openFileFromRoot(file, companyId, options)(fetch, basePath);
        },
        /**
         * Add text as file to a given location.
         * @summary Rename file
         * @param {string} path path
         * @param {RenameFileRequest} RenameFileRequest RenameFileRequest
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFile(path: string, RenameFileRequest: RenameFileRequest, companyId?: string, options?: any) {
            return FTPApiFp(configuration).renameFile(path, RenameFileRequest, companyId, options)(fetch, basePath);
        },
        /**
         * Upload file to a given location.
         * @summary Upload file
         * @param {string} path path
         * @param {any} file file to upload
         * @param {string} [companyId] companyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(path: string, file: any, companyId?: string, options?: any) {
            return FTPApiFp(configuration).uploadFile(path, file, companyId, options)(fetch, basePath);
        },
    };
};

/**
 * FTPApi - object-oriented interface
 * @export
 * @class FTPApi
 * @extends {BaseAPI}
 */
export class FTPApi extends BaseAPI {
    /**
     * Add text as file to a given location.
     * @summary Add file
     * @param {string} path path
     * @param {AddFileRequest} AddFileRequest AddFileRequest
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public addFile(path: string, AddFileRequest: AddFileRequest, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).addFile(path, AddFileRequest, companyId, options)(this.fetch, this.basePath);
    }

    /**
     * Add text as file to a given location.
     * @summary Add files
     * @param {string} path path
     * @param {AddFilesRequest} AddFilesRequest AddFilesRequest
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public addFiles(path: string, AddFilesRequest: AddFilesRequest, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).addFiles(path, AddFilesRequest, companyId, options)(this.fetch, this.basePath);
    }

    /**
     * gives back the content of a file
     * @summary opens a file
     * @param {string} file file
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public calculateFileHashFromRoot(file: string, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).calculateFileHashFromRoot(file, companyId, options)(this.fetch, this.basePath);
    }

    /**
     * Move a file to another location.
     * @summary create directory
     * @param {string} path path
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public createDirectory(path: string, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).createDirectory(path, companyId, options)(this.fetch, this.basePath);
    }

    /**
     * packs files as zip
     * @summary packs files as zip
     * @param {string} path path
     * @param {DownloadFilesAsZipRequest} DownloadFilesAsZipRequest DownloadFilesAsZipRequest
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public createZip(path: string, DownloadFilesAsZipRequest: DownloadFilesAsZipRequest, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).createZip(path, DownloadFilesAsZipRequest, companyId, options)(this.fetch, this.basePath);
    }

    /**
     * Delete a file based on path and filename.
     * @summary Delete file
     * @param {string} path path
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public deleteFile(path: string, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).deleteFile(path, companyId, options)(this.fetch, this.basePath);
    }

    /**
     * Finds if a company has files in the ROOT/companyId/logenios2customer directory that are older than 5 minutes.
     * @summary get files from the ROOT/logenios2customer directories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public getLogenios2CustomerForAllCompanies(options?: any) {
        return FTPApiFp(this.configuration).getLogenios2CustomerForAllCompanies(options)(this.fetch, this.basePath);
    }

    /**
     * Finds if a company has files in the LOGS/companyId/ERRORS directory that are older than 10 minutes in centralized way.
     * @summary get old files from the LOGS/ERRORS directories centralized
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public getOldErrorFilesForAllCompanies(options?: any) {
        return FTPApiFp(this.configuration).getOldErrorFilesForAllCompanies(options)(this.fetch, this.basePath);
    }

    /**
     * Finds if a company's status.json file, if it exists, has a LastRun value in the last 5 minutes.
     * @summary get old status.json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public getOldStatusJsonForAllCompanies(options?: any) {
        return FTPApiFp(this.configuration).getOldStatusJsonForAllCompanies(options)(this.fetch, this.basePath);
    }

    /**
     * Lists all files inside the given path. Directories will be listed as files.
     * @summary list files of directory
     * @param {string} [companyId] companyId
     * @param {Date} [fromTime] fromTime
     * @param {number} [page] page
     * @param {string} [path] path
     * @param {string} [query] query
     * @param {boolean} [queryContent] queryContent
     * @param {'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED'} [sortBy] sortBy
     * @param {'ASC' | 'DESC'} [sortDirection] sortDirection
     * @param {number} [take] take
     * @param {Date} [toTime] toTime
     * @param {boolean} [useModifiedTime] useModifiedTime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public listFiles(companyId?: string, fromTime?: Date, page?: number, path?: string, query?: string, queryContent?: boolean, sortBy?: 'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED', sortDirection?: 'ASC' | 'DESC', take?: number, toTime?: Date, useModifiedTime?: boolean, options?: any) {
        return FTPApiFp(this.configuration).listFiles(companyId, fromTime, page, path, query, queryContent, sortBy, sortDirection, take, toTime, useModifiedTime, options)(this.fetch, this.basePath);
    }

    /**
     * Move a file to another location.
     * @summary Move file
     * @param {string} path path
     * @param {MoveFileRequest} MoveFileRequest MoveFileRequest
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public moveFile(path: string, MoveFileRequest: MoveFileRequest, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).moveFile(path, MoveFileRequest, companyId, options)(this.fetch, this.basePath);
    }

    /**
     * gives back the content of a text file
     * @summary opens a file
     * @param {string} path path
     * @param {OpenFileRequest} OpenFileRequest OpenFileRequest
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public openFile(path: string, OpenFileRequest: OpenFileRequest, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).openFile(path, OpenFileRequest, companyId, options)(this.fetch, this.basePath);
    }

    /**
     * gives back the content of a file
     * @summary opens a file
     * @param {string} file file
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public openFileFromRoot(file: string, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).openFileFromRoot(file, companyId, options)(this.fetch, this.basePath);
    }

    /**
     * Add text as file to a given location.
     * @summary Rename file
     * @param {string} path path
     * @param {RenameFileRequest} RenameFileRequest RenameFileRequest
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public renameFile(path: string, RenameFileRequest: RenameFileRequest, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).renameFile(path, RenameFileRequest, companyId, options)(this.fetch, this.basePath);
    }

    /**
     * Upload file to a given location.
     * @summary Upload file
     * @param {string} path path
     * @param {any} file file to upload
     * @param {string} [companyId] companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public uploadFile(path: string, file: any, companyId?: string, options?: any) {
        return FTPApiFp(this.configuration).uploadFile(path, file, companyId, options)(this.fetch, this.basePath);
    }

}

