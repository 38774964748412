import * as React from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import {FtpExplorerShowFileSystemEntry} from "../FtpExplorerShowFileSystemEntry";
import {FileSystemEntry} from "../../../../../generated-ftp/api";
import {DownloadButton} from "../../../../util/react-download-link/DownloadButton";

export interface FtpExplorerFileDownloadModalProps {
    isOpen: boolean;
    onClose: (success: boolean) => void;
    file: any;
    fileEntry: FileSystemEntry;
    path: string;
}

export function FtpExplorerDownloadFileModal(props: FtpExplorerFileDownloadModalProps) {

    async function handleClose() {
        props.onClose(false);
    }

    return (
        <Modal
            open={props.isOpen}
            onClose={handleClose}
            dimmer="blurring"
            size="small"
        >
            <Header icon="browser" content="Download File"/>
            <Modal.Content>
                <p>Downloading this file?</p>
                <FtpExplorerShowFileSystemEntry file={props.fileEntry}/>
                <DownloadButton
                    filename={props.fileEntry.name}
                    exportFile={props.file}
                />
                <Button onClick={handleClose}>close</Button>
            </Modal.Content>
        </Modal>
    );
}
