import * as React from "react";
import {useContext} from "react";
import {Button, Checkbox, Dropdown, Grid, Icon, Input, Label, Popup, Segment} from "semantic-ui-react";
import {ConfigContext} from "../../../../context/ConfigContext";
import ReactMarkdown from "react-markdown";
import {fieldDescriptions} from "../../../../../style/page_with_sidebar";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {LookedUpCompanyNameList} from "../../../contracts/contracts/edit/EditContractNavTree";
import {GetTypeResponse, UpdateCompanyConfigRequestWrapper} from "../../../../../../generated";
import {notify} from 'react-notify-toast';
import {IfBox} from "../../../../../style/if";
import {ScriptModal} from "../modals/ScriptModal";
import {focusesOnField, openInModalPaths} from "./NavTree";
import {updateConfig, workingConfig} from "../../ConfigSignal";

export interface NavTreeDetailsProps {
    cursor: any,
    values: any[],
    refreshTree: () => Promise<void>
    updateValues: (values: any[]) => void
    fixTypeDefinitionIfEnum: (cursor, response) => Promise<void>
    getType: (cursor) => Promise<GetTypeResponse>
    saveKeyListener: (e) => void
}

export function NavTreeDetails(props: React.PropsWithChildren<NavTreeDetailsProps>) {

    const context = useContext(ConfigContext);
    let auth = backend.withTokenAuthHeader(authentication.token)

    const [isLoading, setIsLoading] = React.useState(false)
    const [enumValueList, setEnumValueList] = React.useState([])
    const [lookedUpCompanyNames, setLookedUpCompanyNames] = React.useState<any[]>([])
    const [changes, setChanges] = React.useState([])
    const [scriptModal, setScriptModal] = React.useState(false)
    const [currentScript, setCurrentScript] = React.useState("")
    const [currentScriptPath, setCurrentScriptPath] = React.useState("")
    const [currentScriptName, setCurrentScriptName] = React.useState("")

    async function onValueChangeList(newValueList, element, setInConfig = true, enumOptions = null) {
        console.log("onValueChangeList. props.values: ", props.values)
        let allValues = props.values
        let newValues = allValues.filter((v) => v.path != element.path + "." + element.name)

        let counter = 0
        newValueList.map((nv) => {
            newValues.push({
                enumOptions: enumOptions,
                name: counter,
                path: element.path + "." + element.name,
                type: "valueListElement",
                value: nv?.value ? nv?.value : [null],
                isEdited: true
            })
            counter++
        })

        //setState({values: newValues})
        props.updateValues(newValues)

        if (setInConfig) {
            // update context
            await setValueInConfigWithJsonPath(newValueList, element.path + "." + element.name)
        }
    }

    async function setValueInConfigWithJsonPath(
        newValue: any,
        path: String,
        isNumber = false
    ) {

        // change in currentJSON
        const jp = require('jsonpath');

        let config = JSON.parse(workingConfig.value)
        let v = newValue
        if (isNumber) {
            v = Number(newValue)
            if (isNaN(v)) {
                console.log("unable to cast '" + newValue + "' to a number")
                return
            }
        }

        jp.value(config, path, v)
        await updateConfig(JSON.stringify(config, null, 4), true, false)

    }

    function drawEnumValueList(cursor, elements) {
        let pathArr = elements[0].path.split(".")
        let listElement = {
            name: pathArr.pop(),
            path: pathArr.join(".")
        }

        let valueList = []
        elements.forEach(e => {
            valueList.push(e.value)
        });
        if (enumValueList.length != valueList.length) {
            setEnumValueList(valueList)
        }
        return <Grid>
            <Grid.Row>
                <Grid.Column>
                    {drawTitle(cursor)}
                </Grid.Column>
            </Grid.Row>
            {elements[0].enumOptions.map(
                option => {
                    console.log("draw options")
                    let checked = enumValueList.find((it) => it == option) != null

                    return <Grid.Row>
                        <Grid.Column>
                            <Checkbox
                                value={option}
                                checked={checked}
                                onChange={async (event, data) => {
                                    valueList = enumValueList
                                    if (valueList.find(v => v == data.value)) {
                                        valueList.splice(valueList.indexOf(data.value), 1);
                                    } else {
                                        valueList.push(data.value)
                                    }

                                    await onValueChangeList(valueList, listElement, true, elements[0].enumOptions)
                                    checked = !checked

                                    setEnumValueList(valueList)
                                    setIsLoading(false)

                                }}/>
                        </Grid.Column>
                        <Grid.Column>
                            {option}
                        </Grid.Column>
                    </Grid.Row>
                }
            )}
        </Grid>


    }

    function findDescriptionForPath(path: string) {
        return fieldDescriptions.value.find(entry => {
            return entry.fieldPath == path
        })?.description
    }

    function drawTitle(cursor) {
        let path = cursor.path + "." + cursor.name
        let description = findDescriptionForPath(path)

        let rawTitle = <h2
            id='withCustomContextMenu'
            onClick={() => {
                focusesOnField.value = {path: path, description: description}
            }}>
            {cursor.name}
        </h2>


        if (description) {
            return <Popup
                content={<ReactMarkdown children={description}/>}
                trigger={<div style={{display: "flex", flexDirection: "row"}}>{rawTitle}<Icon size={"small"}
                                                                                              color={"blue"}
                                                                                              name={"info circle"}/>
                </div>}
            />
        } else {
            return rawTitle
        }
    }

    function drawCompanyName(e, listElement) {
        if (e.isCompanyId) {
            let name = e.name
            if (listElement) {
                name = listElement.name
            }
            let nameList = lookedUpCompanyNames.find(ccn => ccn.listName == name)
            checkForCompany(name, e)
            if (nameList && nameList.companyNames[e.name]) {
                return <>
                    <Label color={'green'}>COMPANY - {nameList.companyNames[e.name]}</Label>
                    <br/>
                </>
            }
            return <>
                <Label>COMPANY - N/A</Label>
                <br/>
            </>

        }
        return <></>
    }

    async function checkForCompany(listName, e) {

        let res = await backend.companyApi.queryCompanies(e.value, false, auth)
        let company = res.results.find(c => c._id == e.value)

        let foundEntry = lookedUpCompanyNames.find(ccn => ccn.listName == listName)
        if (!foundEntry) {
            foundEntry = {
                listName: listName,
                companyNames: []
            } as LookedUpCompanyNameList
        }
        let newList = JSON.parse(JSON.stringify(foundEntry))

        if (company) {
            newList.companyNames[e.name] = company.name
        } else {
            newList.companyNames[e.name] = null
        }
        let newCompleteList = [newList]
        lookedUpCompanyNames.filter((l) => l.listName != listName).forEach((l) => newCompleteList.push(l))
        let oldList = lookedUpCompanyNames.filter((l) => l.listName == listName).pop()

        let oldValue = oldList?.companyNames[e.name]
        let newValue = newList?.companyNames[e.name]
        if (oldValue != newValue) {
            setLookedUpCompanyNames(newCompleteList)
        }
    }

    async function onChangeStringList(newValueList, element, setInConfig = true) {
        console.log("[sourceCompanyTag] onChangeStringList ")
        let allValues = props.values
        let newValues = allValues.filter((v) => v.path != element.path + "." + element.name)
        let planeNewValues = []
        let counter = 0
        newValueList.map((nv) => {
            newValues.push({
                name: counter,
                path: element.path + "." + element.name,
                type: "valueListElement",
                value: nv.value,
                isEdited: true
            })
            planeNewValues.push(nv.value)
            counter++
        })

        props.updateValues(newValues)

        if (setInConfig) {
            // update context
            await setValueInConfigWithJsonPath(planeNewValues, element.path + "." + element.name)
        }
    }

    function drawStringValueList(cursor: any, elements: any[]) {
        let pathArr = elements[0].path.split(".")

        let listElement = {
            name: pathArr.pop(),
            path: pathArr.join(".")
        }

        console.log("ELEMENTS: ", elements)
        return <div>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Row>
                    <Grid.Column>
                        {drawTitle(cursor)}
                    </Grid.Column>
                </Grid.Row>
                {elements.map(e => {
                    return <Grid.Row>
                        <Grid.Column width={1}>
                            {e.name}
                        </Grid.Column>
                        <Grid.Column>
                            {drawCompanyName(e, listElement)}
                            <Input
                                style={{width: 300}}
                                value={e.value}
                                onChange={async (evt) => {
                                    let currElem = elements[e.name]
                                    elements[e.name] = {
                                        name: currElem.name,
                                        path: currElem.path,
                                        type: currElem.type,
                                        isCompanyId: currElem.isCompanyId,
                                        value: evt.target.value
                                    }
                                    await onChangeStringList(elements, listElement)
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Button icon onClick={async (evt) => {
                                evt.preventDefault();
                                delete elements[e.name]
                                await onChangeStringList(elements, listElement)
                            }}><Icon name='trash'/></Button>
                        </Grid.Column>
                    </Grid.Row>
                })}
                <Grid.Row>
                    <Grid.Column>
                        <Button icon onClick={async (evt) => {
                            evt.preventDefault();
                            elements.push({
                                name: elements.length + 1,
                                path: elements[0].path,
                                type: "valueListElement",
                                value: ""
                            })
                            await onChangeStringList(elements, listElement)

                        }}><Icon name='plus'/></Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>

    }

    async function addMemberToList(listType: string) {

        let path = props.cursor.path + "." + props.cursor.name
        const jp = require('jsonpath');

        // get amount of members
        let config = JSON.parse(workingConfig.value)
        let obj = jp.value(config, path)
        let nextNumber = obj.length
        let newValue = {}
        if (listType == "java.lang.String" || listType == "java.lang.Integer") {
            newValue = ""
        }
        await setValueInConfigWithJsonPath(newValue, path + "." + nextNumber)

        await formatConfig()
    }

    async function formatConfig() {

        setIsLoading(true)
        let request = {} as UpdateCompanyConfigRequestWrapper
        request.configAsJson = workingConfig.value
        let auth = backend.withTokenAuthHeader(authentication.token)

        let response = await backend.internalApi.formatConfig(request, auth)

        if (response.errorMessage == null) {
            await updateConfig(JSON.stringify(response.config, null, 4), true, false)
        } else {
            notify.show("ERROR: " + response.errorMessage, 'error', 5000, '#fc0303')
        }

        setIsLoading(false)

    }

    async function createField(cursor, addMember: boolean) {
        let response = (await props.getType(cursor))
        let type = response.type;
        let defaultObject = JSON.parse(response.defaultObject);

        console.log("RESPONSE WAS : ", type)
        console.log("DEFAULT OBJECT WAS : ", defaultObject)


        let refreshTree = false
        if (response.isEnum) {
            await props.fixTypeDefinitionIfEnum(cursor, response)
        } else if (type == "java.util.List") {
            cursor.type = "list"
            if (addMember) {
                await addMemberToList(response.listType)
            }
            refreshTree = true
        } else if (type == "java.lang.String") {
            cursor.type = "string"
            console.log("create field: " + cursor.name)

        } else if (type == "java.lang.Boolean") {
            cursor.type = "boolean"
        } else {
            // object
            // add object to objects and to context config
            refreshTree = true
        }

        if (!addMember) {
            await onValueChange(defaultObject, cursor, !response.isEnum)
        }

        if (refreshTree) {
            await props.refreshTree()
        }

        setIsLoading(false)

    }

    async function onValueChange(newValue, element, setInConfig = true) {
        console.log("changing value")
        let oldValue = element.value
        let newValues = props.values
        let isNumber = false
        newValues.map((e) => {
            if (e.path == element.path && e.name == element.name) {
                if (element.type == "number" && newValue != null) {
                    isNumber = true
                    let v = Number(newValue)
                    if (!isNaN(v)) {
                        e.value = Number(newValue)
                    }
                } else {
                    e.value = newValue
                }
                e.isEdited = true
                console.log("DEFAULT OBJECT WAS -> ", e)
            }
        })
        props.updateValues(newValues)

        if (setInConfig) {
            // update context
            await setValueInConfigWithJsonPath(newValue, element.path + "." + element.name, isNumber)
        }

        // ---  Skip redraw if no newly changed field

        // find existing change
        let previousChange = changes.find(change => {
            return change.path == element.path
        });

        console.log("check previous changes")

        if (previousChange == null) {
            // no previous change, saving this change and redraw with setData()
            console.log("change was first for this path")

            let newChanges = changes
            newChanges.push({path: element.path, originalValue: oldValue})

            setChanges(newChanges)

            await props.refreshTree()

        } else {
            console.log("change already exists")

            if (previousChange.originalValue == newValue) {
                // previous change, but now it's back to the original value.
                // Deleting previous change and redraw with setData()

                console.log("changes rolled back, deleting previous changes and redraw")
                let newChanges = changes.filter(change => {
                    return change.path != element.path
                });

                setChanges(newChanges)
                element.isEdited = false
                await props.refreshTree()
            }
        }


    }

    function render() {
        //console.log("DRAW RIGHT SIDE")
        let cursor = props.cursor
        if (cursor == null) {
            return "nothing to see here"
        }


        let elements = props.values?.filter(value => {
            if (cursor.name == "CompanyConfiguration") {
                return value.path == cursor.path
            }
            if (context.treeSearchQuery && context.treeSearchQuery != "") {
                value.active = value.name.toLowerCase().includes(context.treeSearchQuery?.toLowerCase())
            } else {
                value.active = false
            }
            return value.path == cursor.path + "." + cursor.name
        })

        if (cursor.type == "list") {
            if (elements?.length > 0 && elements[0].type == "valueListElement") {
                if (elements[0].enumOptions) {
                    return drawEnumValueList(cursor, elements)
                } else {
                    return drawStringValueList(cursor, elements)
                }
            }

            return <Grid.Row>
                <Grid.Column>
                    {drawTitle(cursor)}
                </Grid.Column>
                <Grid.Column>
                    <Button loading={isLoading} onClick={async (e) => {

                        setIsLoading(true)

                        e.preventDefault(); // prevents reloading the page by default
                        await createField(cursor, true)
                        await props.refreshTree()

                    }
                    }>add Member</Button>
                </Grid.Column>
            </Grid.Row>

        }

        //TODO: if it's a List -> delete member function(for value- & object-lists)

        function drawEnumDropDown(cursor, element) {
            let i = 0
            const options = element.enumOptions.map(option => {
                i++;
                return {key: i, text: option, value: option}
            })
            if (element.value == "") {
                element.value = null
            }
            return <Dropdown
                selectOnBlur={false}
                clearable
                options={options}
                selection
                value={element.value}
                onChange={async (evt, data) => await onValueChange(data.value, element)}/>

        }

        function getLabelColor(active: boolean, isEdited?: boolean) {
            if (isEdited) {
                return 'orange'
            }
            if (active) {
                return 'green'
            } else {
                return 'grey'
            }
        }

        function drawDeprecatedWarningIfNeeded(element) {
            return <IfBox shouldShow={element.isDeprecated}>
                <Popup content={"DEPRECATED: " + element.deprecatedMessage} trigger={
                    <Icon name="exclamation triangle" color="yellow"/>
                }
                />
            </IfBox>
        }

        function drawField(cursor, element) {
            if (element.type == "enum") {
                return drawEnumDropDown(cursor, element)
            } else if (element.value == null) {
                return <Button loading={isLoading} onClick={async (e) => {
                    setIsLoading(true)
                    e.preventDefault(); // prevents reloading the page by default
                    await createField(element, false)
                }
                }>create</Button>
            } else if (element.type == "number") {
                return <>
                    <Input
                        type='text'
                        value={element.value}
                        onChange={async (evt) => {
                            await onValueChange(evt.target.value, element)
                        }}/>
                    {drawDeleteButton(element)}
                </>
            } else if (element.type == "string") {
                return <>
                    {drawTextInput(element)}
                    {drawDeleteButton(element)}
                </>
            } else if (element.type == "boolean") {
                return <>
                    <Checkbox
                        checked={element.value == "true" || element.value == true}
                        onChange={() => onValueChange(!(element.value == "true" || element.value == true), element)}/>
                    {drawDeleteButton(element)}
                </>
            } else {
                return <>
                    Type: {element.type} not implemented
                </>
            }

        }

        function drawNameLabel(cursor, element) {
            let path = cursor.path + "." + cursor.name + "." + element.name
            let description = findDescriptionForPath(path)

            let label = <Label
                id='withCustomContextMenu'
                className={"configLabels"}
                color={getLabelColor(element.active, element.isEdited)}
                basic={focusesOnField.value?.path == path}
                onClick={() => {
                    focusesOnField.value = {path: path, description: description}
                }}
            >
                {element.name}
                {drawDeprecatedWarningIfNeeded(element)}
            </Label>

            if (description) {
                return <Popup
                    content={<ReactMarkdown children={description}/>}
                    trigger={<div>{label}<Icon size={"small"} color={"blue"} name={"info circle"}/></div>}
                />
            } else {
                return label
            }
        }

        function drawDeleteButton(element) {
            return <span><Icon name='x' color={"red"} onClick={() => onValueChange(null, element)}/></span>
        }

        function openScriptModal(data, currentScriptPath, currentScriptName) {
            console.log("opening Script modal")

            setCurrentScript(data)
            setCurrentScriptPath(currentScriptPath)
            setCurrentScriptName(currentScriptName)
            setScriptModal(true)

        }

        function drawTextInput(element) {
            if (element.name == "converterVersion") {
                console.log("drawTextInput - converterVersion")
            }
            if (openInModalPaths.value.includes(element.path + "." + element.name)) {
                return <textarea
                    id="textarea_scriptedit"
                    value={element.value}
                    cols={40}
                    rows={5}
                    readOnly
                    onClick={(evt) => {
                        console.log("onClick triggered")
                        openScriptModal(evt.currentTarget.value, element.path, element.name)
                    }
                    }
                />
            } else {
                return <>
                    {drawCompanyName(element, null)}
                    <Input
                        type='text'
                        value={element.value}
                        onChange={async (evt) => {
                            await onValueChange(evt.target.value, element)
                        }}/>
                </>

            }
        }


        return <>
            {drawTitle(cursor)}
            <Grid celled={"internally"} stackable columns='equal' className={"companyConfigGrid"}>
                {elements && Object.values(elements).map((element) => {
                    return <Grid.Row>
                        <Grid.Column style={{overflow: "hidden", padding: "5px", alignContent: "center"}}>
                            {drawNameLabel(cursor, element)}
                        </Grid.Column>
                        <Grid.Column style={{padding: "5px", alignContent: "center"}}>
                            {drawField(cursor, element)}
                        </Grid.Column>
                    </Grid.Row>
                })}
            </Grid>
        </>
    }

    function replaceScriptWithScriptModalResponse(newScript) {

        // change in currentJSON
        const jp = require('jsonpath');
        const jsonPath = currentScriptPath + '.' + currentScriptName;
        console.log("JSONPATH : ", jsonPath)

        let config = JSON.parse(workingConfig.value)
        jp.value(config, jsonPath, newScript)
        updateConfig(JSON.stringify(config, null, 4), true, false)

        let values = props.values
        let value = values.find(v => {
            return v.path == currentScriptPath
        })
        value.isEdited = true

        props.updateValues(values)

        console.log("setting  showScriptModal to false")

        setScriptModal(false)
        setCurrentScript(null)
        setCurrentScriptPath(null)
        setCurrentScriptName(null)

        props.refreshTree().then(_ => {
        })
    }

    return <Segment className={"flex3"} style={{padding: "5px", margin: "0"}}>
        {render()}
        {scriptModal &&
            <ScriptModal
                isOpen={scriptModal}
                onClose={() => {
                    console.log("CLOSING ----> scriptModal")
                    setScriptModal(false)
                }}
                script={currentScript}
                dispatcher={replaceScriptWithScriptModalResponse}
                saveKeyListener={props.saveKeyListener}
                path={currentScriptPath + '.' + currentScriptName}
                readOnly={false}
                fieldDescriptionEnabled={true}
                defaultDescription={props.values?.find(e => {
                    return e.name == currentScriptName && e.path == currentScriptPath
                })?.defaultDescription}
            />
        }
    </Segment>
}
