import * as React from "react";
import { Tab } from 'semantic-ui-react'
import {EdiDocViewerOutput} from "./EdiDocViewerOutput";


const panes = [
    { menuItem: 'Tree', render: props => <Tab.Pane id='navTreeTab'><EdiDocViewerOutput output={props.treeOutput} /></Tab.Pane> },
    { menuItem: 'Flat', render: props => <Tab.Pane id='treeTab'><EdiDocViewerOutput output={props.flatOutput} /></Tab.Pane> },
]

const TabEditConfigMenu = props => <Tab
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.ediOutputTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("ediOutputTab", data.activeIndex)
    }}
/>

export default TabEditConfigMenu