import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button, Dropdown, Grid, Icon, Input, Label, TextArea} from 'semantic-ui-react';
import {ConfigContext} from "../../../../context/ConfigContext";
import {IfBox} from "../../../../../style/if";
import {ProjectWorkLogBookEntry} from "../../../../../../generated";
import {backend} from "../../../../../../xconvert-backend";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import * as moment from 'moment';
import {authentication} from "../../../../../../authentication";
import {UnsavedChangesDetectedModal} from "./UnsavedChangesDetectedModal";
import BillingModeEnum = ProjectWorkLogBookEntry.BillingModeEnum;


export function getBillingTypes() {
    return [
        {key: 'fixPrice', text: 'fixPrice', value: 'fixPrice'},
        {key: 'hourly', text: 'hourly', value: 'hourly'},
        {key: 'daily', text: 'daily', value: 'daily'}
    ]
}

export interface EditProjectWorkLogEntryProps {
    saveKeyListener: (e: any) => void
    fieldPathDispatcher: (e: string) => void
    refreshList: (e: boolean) => void
    readonly: boolean
}

export function EditProjectWorkLogEntry(props: EditProjectWorkLogEntryProps) {
    const context = useContext(ConfigContext);
    const [projectWorkLogBookEntry, setProjectWorkLogBookEntry] = useState(null);
    const [showUnsavedChangesDetectedModal, setShowUnsavedChangesDetectedModal] = useState(false);


    useEffect(() => {
        console.log("SETTING DATA")
        if (context.selectedProjectWorkLogBookEntry) {
            setProjectWorkLogBookEntry(context.selectedProjectWorkLogBookEntry)
        }
    }, [props]);

    function handleInputChange(evt: React.ChangeEvent<HTMLInputElement>) {
        let value: any = evt.target.value;

        if (evt.target.name == "timeSpentOnTask") {
            value = value.replace(",", ".")
            value = value.replace(/[^0-9.]/g, '')
        }

        let f = {...projectWorkLogBookEntry, [evt.target.name]: value}

        setProjectWorkLogBookEntry(f)
        console.log("handleInputChange done")
    }

    function handleDropdownChange(fieldName: string, data: DropdownProps) {
        console.log(`Dropdown field ${fieldName} has value ${data.value}`)

        setProjectWorkLogBookEntry({
            ...projectWorkLogBookEntry,
            [fieldName]: data.value?.toString()
        })
    }

    async function save() {
        let auth = backend.withTokenAuthHeader(authentication.token)

        if (projectWorkLogBookEntry._id == undefined) {
            try {
                console.log("creating a new projectWorkLogBookEntry in backend")
                let response = (await backend.internalApi.createProjectWorkLogBookEntry(projectWorkLogBookEntry, auth))
                console.log("backend responded with: ", response)
            } catch (ex) {
                console.log(ex);
            }
        } else {
            try {
                console.log("updating a projectWorkLogBookEntry in backend")
                let response = (await backend.internalApi.modifyProjectWorkLogBookEntryById(projectWorkLogBookEntry._id, projectWorkLogBookEntry, auth))
                console.log("backend responded with: ", response)
            } catch (ex) {
                console.log(ex);
            }
        }
        context.setSelectedProjectWorkLogBookEntry(projectWorkLogBookEntry)
        props.refreshList(true);
    }

    async function remove() {
        let auth = backend.withTokenAuthHeader(authentication.token)

        if (projectWorkLogBookEntry._id != undefined) {
            try {
                console.log("removing a projectWorkLogBookEntry in backend")
                let response = (await backend.internalApi.deleteProjectWorkLogBookEntryById(projectWorkLogBookEntry._id, auth))
                console.log("backend responded with: ", response)
            } catch (ex) {
                console.log(ex);
            }
        }
        context.setSelectedProjectWorkLogBookEntry(null)
        props.refreshList(true);
    }

    async function addProjectWorkLogBookEntry() {

        setProjectWorkLogBookEntry(null)

        context.setOldProjectWorkLogBookEntry(null)
        await fetchEmptyProjectWorkLogBookEntry()
        context.setSelectedProjectWorkLogIndex(null)
        props.refreshList(true);
    }

    async function fetchEmptyProjectWorkLogBookEntry() {
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let response = (await backend.internalApi.fetchEmptyProjectWorkLogBookEntry(auth))
        context.setSelectedProjectWorkLogBookEntry(response)
        setProjectWorkLogBookEntry(response)
    }

    function checkForChanges() {
        return context.selectedProjectWorkLogBookEntry != projectWorkLogBookEntry
    }

    function isAlreadyBilled() {
        return projectWorkLogBookEntry?.billedAtTime != undefined;
    }

    function printTimestamp(t: Date | undefined, withTime: boolean) {
        let formatString = 'DD.MM.YYYY'
        if (withTime) {
            formatString = 'DD.MM.YYYY HH:mm'
        }
        if (t != undefined) {
            return moment(t).format(formatString)
        }
        return ""
    }

    function calculateRate() {
        let billingCost = projectWorkLogBookEntry?.billingCost
        let timeSpentOnTask = projectWorkLogBookEntry?.timeSpentOnTask
        if (billingCost == undefined || timeSpentOnTask == undefined) {
            return "Angaben fehlen"
        }
        return billingCost / timeSpentOnTask
    }

    function renderLogBookFixedValues(labelName: string, value: string | number) {
        return <Grid.Row>
            <Grid.Column>
                <Label>
                    {labelName}
                </Label>
            </Grid.Column>
            <Grid.Column>
                <Input
                    value={value}
                    disabled={true}
                    onChange={(evt) => handleInputChange(evt)}/>
            </Grid.Column>
        </Grid.Row>
    }

    function renderLogBookInput(labelName: string, valueName: string, value: string | number) {
        return <Grid.Row>
            <Grid.Column>
                <Label>
                    {labelName}
                </Label>
            </Grid.Column>
            <Grid.Column>
                <Input
                    value={value}
                    name={valueName}
                    disabled={isAlreadyBilled()}
                    onChange={(evt) => handleInputChange(evt)}/>
            </Grid.Column>
        </Grid.Row>

    }

    function renderBillingCostInput() {
        return <Grid.Row>
            <Grid.Column>
                <Label>
                    Total BillingCost (€)
                </Label>
            </Grid.Column>
            <Grid.Column>
                <Input
                    value={(projectWorkLogBookEntry?.billingCost ?? "")}
                    name={'billingCost'}
                    disabled={isAlreadyBilled()}
                    type="number"
                    onChange={(evt) => handleInputChange(evt)}/>
            </Grid.Column>
        </Grid.Row>
    }

    function generateEditor() {

        let showCreateConfig = false
        if (context != null) {
            if (context.selectedProjectWorkLogBookEntry != null) {
                if (context.selectedProjectWorkLogBookEntry._id == null) {
                    //config empty
                    showCreateConfig = true
                }
            } else {
                showCreateConfig = true
            }
        } else {
            showCreateConfig = true
        }

        return <div>
            <IfBox shouldShow={showCreateConfig}>
                <Label color="green">New, unsaved LogBookEntry</Label>
                <br/>
            </IfBox>
            <IfBox shouldShow={!showCreateConfig}>
                {isAlreadyBilled() &&
                <Label color="red">
                    Already billed, changes are not allowed
                </Label>
                }
                {!isAlreadyBilled() &&
                    <Label color="yellow">
                        Edit existing LogBookEntry
                    </Label>
                }
                <br/>
            </IfBox>


            <Grid padded="vertically" stackable columns='equal'>
                <Grid.Row>
                    <Grid.Column>
                        <Label>
                            Already billed
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <div className="ui grid">

                            <div className="one wide column">
                                <IfBox shouldShow={projectWorkLogBookEntry?.billedAtTime != undefined}>
                                    <Icon name='check' color='green'/>
                                </IfBox>
                                <IfBox shouldShow={projectWorkLogBookEntry?.billedAtTime == undefined}>
                                    <Icon name='x' color='red'/>
                                </IfBox>
                            </div>
                            <div className="five wide column">
                                <IfBox shouldShow={projectWorkLogBookEntry?.billedAtTime != undefined}>
                                    <Input
                                        value={printTimestamp(projectWorkLogBookEntry?.billedAtTime, false)}
                                        disabled={true}
                                        onChange={(evt) => handleInputChange(evt)}/>
                                </IfBox>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                {renderLogBookInput("Short Description", "shortDescription", (projectWorkLogBookEntry?.shortDescription ?? ""))}
                <Grid.Row>
                    <Grid.Column>
                        <Label>
                            {"Comment"}
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea
                            value={projectWorkLogBookEntry?.comment ?? ""}
                            name={"comment"}
                            disabled={isAlreadyBilled()}
                            onChange={(evt) => setProjectWorkLogBookEntry({
                                ...projectWorkLogBookEntry,
                                comment: evt.currentTarget.value
                            })}/>
                    </Grid.Column>
                </Grid.Row>
                {/*{renderLogBookInput("Comment", "comment", (projectWorkLogBookEntry.comment ?? ""))}*/}

                <Grid.Row>
                    <Grid.Column>
                        <Label>
                            Billing Mode
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Dropdown
                            selectOnBlur={false}
                            value={projectWorkLogBookEntry?.billingMode ?? ""}
                            selection
                            disabled={isAlreadyBilled()}
                            options={getBillingTypes()}
                            onChange={(evt, data) => handleDropdownChange("billingMode", data)}/>
                    </Grid.Column>
                </Grid.Row>

                {renderBillingCostInput()}

                <IfBox shouldShow={projectWorkLogBookEntry?.billingMode == BillingModeEnum.Hourly}>
                    {renderLogBookFixedValues("Price per Hour:", (calculateRate()))}
                    {renderLogBookInput("Time spent on task (hours)", "timeSpentOnTask", (projectWorkLogBookEntry?.timeSpentOnTask ?? ""))}
                </IfBox>
                <IfBox shouldShow={projectWorkLogBookEntry?.billingMode == BillingModeEnum.Daily}>
                    {renderLogBookFixedValues("Daily Rate:", (calculateRate()))}
                    {renderLogBookInput("Time spent on task (hours)", "timeSpentOnTask", (projectWorkLogBookEntry?.timeSpentOnTask ?? ""))}
                </IfBox>

                {renderLogBookFixedValues("User Name", (projectWorkLogBookEntry?.userFirstName) + " " + (projectWorkLogBookEntry?.userLastName))}
                {renderLogBookFixedValues("Company", (projectWorkLogBookEntry?.companyName))}
                {renderLogBookFixedValues("Created Date", (printTimestamp(projectWorkLogBookEntry?.createdDate, true)))}
            </Grid>
        </div>
    }

    return <Grid>
        <Grid.Column>
            <IfBox shouldShow={context.selectedProjectWorkLogBookEntry == null}>
                <Grid.Row>
                    <h1>Select or create a ProjectWorkLogEntry</h1>
                    <Button id='EditProjectWorkLogEntryCreateButton' icon onClick={() => {
                        if (checkForChanges()) {
                            setShowUnsavedChangesDetectedModal(true)
                        } else {
                            addProjectWorkLogBookEntry().then(() => {
                            })
                        }
                    }}><Icon name='plus'/></Button>
                </Grid.Row>
            </IfBox>
            <IfBox shouldShow={context.selectedProjectWorkLogBookEntry != null}>
                <Grid.Row>
                    <h1>Edit a ProjectWorkLogEntry</h1>
                    <form>{generateEditor()}</form>
                </Grid.Row>
            
                <Grid.Row>
                    <UnsavedChangesDetectedModal
                        isOpen={showUnsavedChangesDetectedModal}
                        onClose={() => {
                            setShowUnsavedChangesDetectedModal(false)
                        }}
                        newObject={projectWorkLogBookEntry}
                        oldObject={context.selectedProjectWorkLogBookEntry}
                        dispatcher={() => addProjectWorkLogBookEntry()}

                    />
                    <Button id='EditProjectWorkLogEntryCreateButton' icon onClick={() => {
                        if (checkForChanges()) {
                            setShowUnsavedChangesDetectedModal(true)
                        } else {
                            addProjectWorkLogBookEntry().then(() => {
                            })
                        }
                    }}><Icon name='plus'/></Button>
                    {!isAlreadyBilled() && <>
                        <Button id='EditProjectWorkLogEntrySaveButton' icon onClick={() => save()}><Icon
                            name='save'/></Button>
                        <Button id='EditProjectWorkLogEntrySaveButton' icon onClick={() => remove()}><Icon
                            name='trash'/></Button>
                    </>}
                </Grid.Row>
            </IfBox>
        </Grid.Column>

    </Grid>
}