import * as React from "react";
import {Button, Divider, Form, Icon, Input, Popup, TextArea} from 'semantic-ui-react';
import {Address, ContactInfo, ParseTextRequest} from "../../../../generated";
import {FormErrorMessage, FormGroup, validateLength} from "../../../forms/form-group";
import {backend} from "../../../../xconvert-backend";
import {authentication} from "../../../../authentication";

export interface AddressEditFormProps {
    loading?: boolean
    // address: Address
    // contact: ContactInfo
    onSave: (u: Address, c: ContactInfo) => Promise<any>
}

export interface AddressEditState {
    form: FormGroup
    address: Address
    contact: ContactInfo
    loading: boolean
    addressString: string,
}

export class AddressEditForm extends React.Component<AddressEditFormProps, AddressEditState> {

    minLengthName = 2
    minLengthStreet = 3
    minLengthHouseNumber = 1
    minLengthCity = 3
    minLengthZipCode = 4
    minLengthCountry = 2
    defaultContact = {verboseName: null, email: null, phoneNr: null}

    constructor(props: AddressEditFormProps) {
        super(props)

        this.state = {
            form: this.buildFormGroup(),
            address: {},
            contact: this.defaultContact,
            loading: this.props.loading || false,
            addressString: "",
        }
    }

    async componentDidUpdate(prevProps: Readonly<AddressEditFormProps>, prevState: Readonly<AddressEditState>, snapshot?: any) {
        if (this.props.loading != prevProps.loading) {
            this.setState({loading: this.props.loading})
        }
    }

    buildFormGroup() {
        let forms = [
            {name: "name", validationRules: [validateLength("required", this.minLengthName)]},
            {name: "street", validationRules: [validateLength("required", this.minLengthStreet)]},
            {name: "city", validationRules: [validateLength("required", this.minLengthCity)]},
            {name: "zipCode", validationRules: [validateLength("required", this.minLengthZipCode)]},
            {name: "country", validationRules: [validateLength("required", this.minLengthCountry)]},
            {name: "phoneNumber", validationRules: []},
            {name: "eMail", validationRules: []},

        ]
        let grp = new FormGroup(forms);
        grp.onValidationFinished = () => {this.setState({})}
        return grp
    }

    handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.state.form.handleInputChange(event)
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            address: {...this.state.address, [name]: value},
            contact: {...this.state.contact, [name]: value}
        })
    }

    async handleSubmitClick(): Promise<void> {

        this.setState({loading: true})
        console.log("Handle submit")
        const hasError = await this.state.form.hasError()
        try {
            if (!hasError) {
                const name = this.state.form.getValue("name")
                const street = this.state.form.getValue("street")
                const houseNumber = this.state.form.getValue("houseNumber")
                const city = this.state.form.getValue("city")
                const zipCode = this.state.form.getValue("zipCode")
                const country = this.state.form.getValue("country")
                const phoneNumber = this.state.form.getValue("phoneNumber")
                const eMail = this.state.form.getValue("eMail")

                const address = {...this.state.address, name: name, street: street, houseNumber: houseNumber, city: city, zipCode: zipCode, country: country}
                const contact = {...this.state.contact, displayName: name, email: eMail, phoneNr: phoneNumber}

                await this.props.onSave(address, contact)
            }
        } catch (ex) {
            console.log(ex)
        }

        this.setState({loading: false})
    }

    async addressToObject() {

        this.setState({loading: true})
        let input = "Input | Output as JSON |\n" +
            "Mercedes Benz AG, Stuttgarter Str. 14, 72622, Nürtingen | {\"name\":\"Mercedes Benz AG\", \"city\": \"Nürtingen\", \"street\":\"Stuttgarter Str.\", \"houseNumber\": \"14\", \"zipCode\": \"72622\", \"country\": \"DE\"} |\n" +
            "Edeka Minden-Hannover, EDEKA-LAUE Peugeotstraße 1, D-31867 Lauenau | {\"name\":\"Edeka Minden-Hannover\", \"city\": \"Lauenau\", \"street\":\"Peugeotstraße\", \"houseNumber\": \"1\", \"zipCode\": \"31867\", \"country\": \"DE\"} |\n" +
            this.state.addressString.replace("|","/").replace("\n","; ") + "|"

        console.log("Ai Parse command is")
        console.log(input)

        let aiRequest = {
            input: input,
            rawInput: this.state.addressString,
            type: "cm:address",
            stopSequence: "|"
        } as ParseTextRequest
        const auth = backend.withTokenAuthHeader(authentication.token)
        const aiResponse = await backend.internalApi.aiParseText(aiRequest, auth)

        this.setState({address: JSON.parse(aiResponse.output), loading: false}, async () => {
            this.state.form.cleanFields = []
            this.state.form.setInitialData(this.state.address || {})
            await this.state.form.forceValidation()
        })

    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    render() {

        return <Form error>
            <Form.Field>
                <Form.Field>
                    <label>AI Address Parser</label>
                    <TextArea style={{width: "50%", marginBottom: -10, marginRight: "10px"}}
                              rows={5}
                              value={this.state.addressString} disbaled={this.state.loading}
                              onChange={(e) => this.setState({addressString: e.target.value})}/>
                    <Popup content='Ai Parser'
                           trigger={
                               <Button icon={"microchip"} loading={this.state.loading} onClick={async () => await this.addressToObject()}/>
                           }/>
                    {/*<Popup trigger={<Icon name='warning sign' color={"yellow"}/>}*/}
                    {/*            content='The TextField content must be parsed by an AI. Or you can use the Detailed Form Model.'/>*/}
                </Form.Field>
                <Divider/>
                <label>Name</label>
                <Input
                    id='companycreate_name'
                    placeholder='Name'
                    name="name"
                    value={this.state.address?.name || ''}
                    disabled={this.state.loading}
                    onChange={evt => this.handleInputChange(evt)}
                />
                <FormErrorMessage group={this.state.form} field="name" tag="required">
                    Dieses Feld wird benötigt. Mindestlänge {this.minLengthName}.
                </FormErrorMessage>
            </Form.Field>
            <Form.Group>
                <Form.Field width={12}>
                    <label>Straße</label>
                    <Input id='companycreate_street' placeholder={"street"} name="street"
                           value={this.state.address?.street || ''} disabled={this.state.loading}
                           onChange={evt => this.handleInputChange(evt)}

                    />
                    <FormErrorMessage group={this.state.form} field="street" tag="required">
                        Dieses Feld wird benötigt. Mindestlänge {this.minLengthStreet}.
                    </FormErrorMessage>
                </Form.Field>
                <Form.Field width={4}>
                    <label>Hausnummer</label>
                    <Input id='companycreate_number' placeholder='Nr' name="houseNumber"
                           value={this.state.address?.houseNumber || ''} disabled={this.state.loading}
                           onChange={evt => this.handleInputChange(evt)}
                    />
                    <FormErrorMessage group={this.state.form} field="houseNumber" tag="required">
                        Dieses Feld wird benötigt. Mindestlänge {this.minLengthHouseNumber}.
                    </FormErrorMessage>
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={6}>
                    <label>PLZ</label>
                    <Input id='companycreate_zip' placeholder={'Postleitzahl'} name="zipCode"
                           value={this.state.address?.zipCode || ''} disabled={this.state.loading}
                           onChange={evt => this.handleInputChange(evt)}
                    />
                    <FormErrorMessage group={this.state.form} field="zipCode" tag="required">
                        Dieses Feld wird benötigt. Mindestlänge {this.minLengthZipCode}.
                    </FormErrorMessage>
                </Form.Field>
                <Form.Field width={10}>
                    <label>Ort</label>
                    <Input id='companycreate_city' placeholder={'Ort'} name="city"
                           value={this.state.address?.city || ''} disabled={this.state.loading}
                           onChange={evt => this.handleInputChange(evt)}
                    />
                    <FormErrorMessage group={this.state.form} field="city" tag="required">
                        Dieses Feld wird benötigt. Mindestlänge {this.minLengthCity}.
                    </FormErrorMessage>
                </Form.Field>
            </Form.Group>
            <Form.Field>
                <label>Land</label>
                <Input id='companycreate_country' placeholder={'Land'} name="country"
                       value={this.state.address.country || ''} disabled={this.state.loading}
                       onChange={evt => this.handleInputChange(evt)}
                />
                <FormErrorMessage group={this.state.form} field="country" tag="required">
                    Dieses Feld wird benötigt. Mindestlänge {this.minLengthCountry}.
                </FormErrorMessage>
            </Form.Field>
            <Form.Group>
                <Form.Field width={6}>
                    <label>
                        Telefonnummer
                        <Popup trigger={<Icon name={"info"}></Icon>}>
                            Diese Nummer wird mit ihren Partnern und Sub-Unternehmern verwendet
                        </Popup>
                    </label>
                    <Input id='companycreate_phoneNumber' placeholder={'Telefonnummer'} name="phoneNr"
                           value={this.state.contact?.phoneNr || ''} disabled={this.state.loading}
                           onChange={evt => this.handleInputChange(evt)}
                    />
                </Form.Field>
                <Form.Field width={10}>
                    <label>
                        E-Mail
                        <Popup trigger={<Icon name={"info"}></Icon>}>
                            Diese E-Mail wird mit ihren Partnern und Sub-Unternehmern verwendet
                        </Popup>
                    </label>
                    <Input id='companycreate_eMail' placeholder={'E-Mail'} name="email"
                           value={this.state.contact?.email || ''} disabled={this.state.loading}
                           onChange={evt => this.handleInputChange(evt)}
                    />
                </Form.Field>
            </Form.Group>

            <Button id='companycreate_button_save' color='teal' onClick={() => this.handleSubmitClick()} loading={this.state.loading}>
                <Icon name='checkmark'/>
                Speichern
            </Button>

        </Form>
    }
}