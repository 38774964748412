import * as React from "react";
        import {Modal, Header, Button, Input} from "semantic-ui-react";
        import { ConfigContext } from "../../../context/ConfigContext";

        import 'brace/mode/groovy'
        import 'brace/ext/searchbox'
        import {FileSystemEntry, RenameFileRequest} from "../../../../../generated-ftp/api";
        import {backend} from "../../../../../xconvert-backend";
        import {authentication} from "../../../../../authentication";
        import {ftp} from "../../../../../xconvert-ftp";

        export interface FtpExplorerRenameFileModalProps {
        isOpen: boolean
        onClose: (boolean) => void
        file: FileSystemEntry,
        path: string
        }


        export class FtpExplorerRenameFileModal extends React.Component
<FtpExplorerRenameFileModalProps, any> {

        static contextType = ConfigContext
        context!: React.ContextType<typeof ConfigContext>;

constructor(props) {
super(props)
this.state = {
newName: ""
}
}

handleClose = (change) => {
this.props.onClose(change)
}

async handleSave() {
let auth = backend.withTokenAuthHeader(authentication.token)
let request = {} as RenameFileRequest
request.newName = this.state.newName
request.oldName = this.props.file.name
let response
try {
response = (await ftp.ftpApi.renameFile(this.props.path, request, this.context.companyId, auth))
} catch (ex) {
response = {}
response.success = false
response.errorMessage = "Exception occured while call: " + ex.statusText

}
this.handleClose(true)
}

handleDiscard() {
this.handleClose(false)
}

componentDidMount() {
this.setState({newName: this.props.file.name})
}

render() {

return<Modal
        open={this.props.isOpen}
onClose={this.handleClose}
dimmer="blurring"
size='small'>
<Header icon='browser' content='Rename File'/>
<Modal.Content>
    <Input style={{ width: 220 }}
    value={this.state.newName}
    onChange={(evt, data) => {
    this.setState({newName: data.value})
    }}
    />

    <Button onClick={() => this.handleDiscard()}>close
</Button>
<Button onClick={() => this.handleSave()}>save
</Button>

        </Modal.Content>
        </Modal>
        }

        }