import * as React from "react";
import ReactTable from "react-table";
import {formatDate} from "../../../../../../format";
import {ConfigContext} from "../../../../context/ConfigContext";
import {CompanyConfigHistory, CompanyHistory, Contract} from "../../../../../../generated";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {ContractHistoryModal} from "../edit/modals/ContractHistoryModal";

export interface ContractsHistoryListProps {

}

export interface ContractsHistoryListState {
    isLoading: boolean,
    history: CompanyHistory[] | null,
    selectedHistory: CompanyHistory | null,
    followingContract: Contract | null,
    showChanges: boolean,
    showRollBackView: boolean,

    //Table
    take: number,
    page: number,
    sortBy: "TIMESTAMP" | "CHANGED_BY" | "COMMENT",
    sortDirection: "DESC" | "ASC",

    count: number
}


export class ContractsHistoryList extends React.Component<ContractsHistoryListProps, ContractsHistoryListState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading ContractsHistoryList")

        this.state = {
            isLoading: true,
            history: null,
            selectedHistory: null,
            followingContract: null,
            showChanges: false,
            showRollBackView: false,

            //Table
            take: 10,
            page: 1,
            sortBy: "TIMESTAMP",
            sortDirection: "DESC",

            count: -1
        }
    }

    async componentDidMount() {

        await this.fetchHistory()
        console.log("THE COMPONENT DID MOUNT:", this.state.history)

    }

    async fetchHistory(take = this.state.take, page = this.state.page) {
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let history: CompanyConfigHistory = (await backend.internalApi.fetchCompanyConfigurationHistory(
            this.context.companyId,
            take,
            (page - 1) * take,
            this.state.sortBy,
            this.state.sortDirection,
            null,
            'CONTRACT_ONLY',
            auth
        ))
        console.log(history)
        this.setState({
            history: history.results,
            count: history.count,
            isLoading: false,
            take: take,
            page: page
        })
        this.context.setHistory(history)

    }

    async changeSort(sortBy: 'TIMESTAMP' | 'CHANGED_BY' | 'COMMENT' | 'NONE') {
        console.log("changing sort")
        this.setState({ isLoading: true })

        if (sortBy == 'NONE') {
            return
        }

        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({ sortDirection: direction })
        } else {
            //change sortBy and reset direction
            this.setState({ sortBy: sortBy, sortDirection: "ASC" })
        }

        await this.fetchHistory()
        this.setState({ isLoading: false })

    }
    changePage(pageIndex: number) {
        this.setState({ isLoading: true })
        this.fetchHistory(this.state.take, pageIndex + 1)
    }

    changePageSize(newSize: number) {
        this.setState({ isLoading: true })
        this.fetchHistory(newSize, this.state.page)
    }

    fillDummyEntries(history: CompanyHistory[]) {
        const totalAmount = history.length
        let dummy = {} as CompanyHistory
        let currentEntries: CompanyHistory[] = []
        console.log("found " + history.length + " histories of a total of " + totalAmount)

        let indexOffset = (this.state.page - 1) * this.state.take
        console.log("offset: " + indexOffset)

        let firstActualFileIndex = indexOffset
        console.log("firstActualFileIndex: " + firstActualFileIndex)

        let lastActualFileIndex = indexOffset + this.state.take - 1
        console.log("lastActualFileIndex: " + lastActualFileIndex)

        for (let i = 0; i < totalAmount; i++) {

            if (i < firstActualFileIndex) {
                currentEntries.push(dummy)
            } else if (i >= firstActualFileIndex) {
                if (i <= lastActualFileIndex) {
                    console.log("take index: " + (i - indexOffset))

                    currentEntries.push(history[i - indexOffset])
                    console.log("took: " + history[i - indexOffset])

                } else {
                    currentEntries.push(dummy)
                }

            }
        }

        while (currentEntries.length < totalAmount) {

            console.log("adding a dummy")
            currentEntries.push(dummy)
        }

        console.log("new total amount: " + currentEntries.length)

        return currentEntries
    }

    render() {
        if (this.state.history == null) {
            return <p>No histories found</p>
        } else {
            const history: any[] | null = this.fillDummyEntries(this.state.history)
            const columns = [{
                id: 'ID',
                Header: 'id',
                width: 270,
                accessor: (d: any) => d.contract._id,
                sortField: 'ID'
            }, {
                id: 'VALID_FROM',
                Header: 'valid from',
                width: 180,
                accessor: (d: any) => d.contract.validFrom,
                sortField: 'VALID_FROM'
            }, {
                id: 'TIMESTAMP',
                Header: 'timestamp',
                width: 150,
                accessor: (d: any) => d.timestamp,
                Cell: (d: any) => { return <span>{formatDate(d.value)}</span> },
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                    if (a1 < b1) {
                        return 1;
                    }
                    else if (a1 > b1) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                },
                sortField: 'TIMESTAMP'

            }, {
                id: 'CHANGED_BY',
                Header: 'changedBy',
                width: 200,
                accessor: (d: any) => d.changedBy,
                sortField: 'CHANGED_BY'
            }, {
                id: 'COMMENT',
                Header: 'comment',
                accessor: (d: any) => d.changeComment,
                sortField: 'COMMENT'
            }]

            return <>
                <ReactTable
                    data={history}
                    pages={Math.ceil(this.state.count / this.state.take)}
                    columns={columns}
                    sorted={[
                        {
                            id: 'TIMESTAMP',
                            desc: false
                        }
                    ]}
                    onSortedChange={(newSorted, column, shiftKey) => { this.changeSort(column.sortField) }}

                    defaultPageSize={this.state.take}
                    className="-striped -highlight"
                    loading={this.state.isLoading}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: () => {
                                if (rowInfo) {
                                    console.log("Opening History-Entry with id", rowInfo.row)
                                    this.showChanges(rowInfo.row._original)
                                }
                            }
                        };
                    }}
                    style={{ cursor: "pointer" }}
                    onPageChange={(pageIndex) => this.changePage(pageIndex)}
                    onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}

                />

                <ContractHistoryModal
                    isOpen={this.state.showChanges}
                    onClose={async (openRollback: boolean, commentDidChange: boolean) => {
                        if (openRollback) {
                            this.setState({ showChanges: false, showRollBackView: true })
                        } else {
                            this.setState({ showChanges: false })
                        }

                        if (commentDidChange) {
                            await this.fetchHistory()
                        }
                    }}
                    history={this.state.selectedHistory}
                    followingContractVersion={this.state.followingContract}
                />
</>
        }
    }

    async showChanges(row: CompanyHistory) {

        let auth = await backend.withTokenAuthHeader(authentication.token)
        let response = await backend.internalApi.fetchFollowingCompanyContractHistory(this.context.companyId, row.contract._id, row._id, auth)
        let followingContract = response.followingContract
        this.setState({ showChanges: true, selectedHistory: row, followingContract: followingContract })
    }

}
