import * as React from "react";
import {PageWithSidebar} from "../../../../style/page_with_sidebar";
import {ConfigContext} from "../../../context/ConfigContext";
import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import Notifications, {notify} from 'react-notify-toast';
import ContractsListTabView from "./lists/ContractsListTabView";
import {Grid, Modal} from "semantic-ui-react";
import {EditContract} from "./edit/EditContract";
import {Contract} from "../../../../../generated";

export interface ContractsPageProps {

}


export interface ContractsPageState {
    isLoading: boolean,
    selectedContract: Contract | null
    refreshTrigger: boolean
    readOnly: boolean
}


export class ContractsPage extends React.Component<ContractsPageProps, ContractsPageState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading QueuedEventsPage")
        this.contractSelected = this.contractSelected.bind(this);

        this.state = {
            isLoading: false,
            selectedContract: null,
            refreshTrigger: false,
            readOnly: false,
        }
    }

    async componentDidMount() {

    }

    contractSelected(selectedContract) {
        this.setState({selectedContract: selectedContract})
    }


    render() {
        // return <PageWithSidebar activeMenu="Contracts">
        return <>
            <div className={"flexRow"}>
                <ContractsListTabView
                    context={this.context}
                    contractSelected={this.contractSelected}
                    selectedContract={this.state.selectedContract}
                    refreshTrigger={this.state.refreshTrigger}
                />
                <EditContract
                    selectedContract={this.state.selectedContract}
                    unselectDispatch={(newContract) => this.setState({selectedContract: newContract})}
                    refreshList={() => this.setState({refreshTrigger: !this.state.refreshTrigger})}
                    readOnly={this.state.readOnly}
                />
            </div>
            <Notifications/>
        </>
    }


}