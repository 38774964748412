import * as React from "react";
import {Tab} from 'semantic-ui-react'
import {TransportOrderEditView} from "./TransportOrderEditView";
import {QueuedEventsList} from "./QueuedEventsList";
import {TransportOrderMapComponent} from "./TransportOrderMap";
import {TransportOrderLinkView} from "./TransportOrderLinkView";
import {TransportOrderCreateOrModify} from "./TransportOrderCreateOrModify";
import {TransportOrderHistoryTable} from "./history/TransportOrderHistoryTable";
import {TransportOrderHistoryOldTable} from "./history/TransportOrderHistoryTable_OLD";

const panes = [
    {
        menuItem: 'Edit',
        render: props =>
            <Tab.Pane id='EditTab' className={"flex1"} style={{padding: "5px"}}>
                <TransportOrderEditView
                    transportOrder={props.transportOrder}
                    reload={props.reload}
                />
            </Tab.Pane>
    },
    {
        menuItem: 'History',
        render: props => <Tab.Pane id='HistoryTab' className={"flex1"}>
            <TransportOrderHistoryTable
                transportOrder={props.transportOrder}
                reload={props.reload}
            />
        </Tab.Pane>
    },
    {
        menuItem: 'History OLD',
        render: props => <Tab.Pane id='HistoryTab' className={"flex1"}>
            <TransportOrderHistoryOldTable
                transportOrder={props.transportOrder}
                reload={props.reload}
            />
        </Tab.Pane>
    },
    {
        menuItem: 'QueuedEvents',
        render: props => <Tab.Pane id='QueuedEventsTab' className={"flex1"}>
            <QueuedEventsList
                transportOrderId={props.transportOrder?._id}/>
        </Tab.Pane>
    },
    {
        menuItem: 'Map',
        render: props => <Tab.Pane id='MapTab' className={"flex1"}>
            <TransportOrderMapComponent
                order={props.transportOrder}
                polyline={null}
                predefinedPolyline={false}
            />
        </Tab.Pane>
    },
    {
        menuItem: 'Link',
        render: props => <Tab.Pane id='LinkTab' className={"flex1"}>
            <TransportOrderLinkView
                transportOrders={props.selectedTransportOrders}
                reload={props.reload}
                linkingPartners={props.linkingPartners}
            />
        </Tab.Pane>
    },
    {
        menuItem: 'CreateOrModify',
        render: props => <Tab.Pane id='CreateOrModifyTab' className={"flex1"}>
            <TransportOrderCreateOrModify transportOrders={props.selectedTransportOrders}/>
        </Tab.Pane>
    },
]

const TransportOrderRightSideTabView = props => <Tab
    id={"transportOrderRightSideTab"}
    className={"flex1"}
    style={{width: "100%"}}
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.transportOrderRightSideTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("transportOrderRightSideTab", data.activeIndex)
    }}
/>

export default TransportOrderRightSideTabView