import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {Company} from "../../../../../generated";
import {Button, Checkbox, Icon, Segment} from "semantic-ui-react";
import ReactTable from "react-table";
import {IfBox} from "../../../../style/if";
import {authentication} from "../../../../../authentication";
import {SelectCompanyModal} from "../../../../ui/admin/select_company_modal";


export interface SystemStatusMessageCompanyListProps {
    selectedCompanies: Company[]
    updateAllCompaniesSwitch: (allCompanies: boolean) => void
    updateSelectedCompanies: (c: Company[]) => void
}

export interface SystemStatusMessageCompanyListState {
    isLoading: boolean
    take: number
    page: number
    selectedCompanies: Company[]
    sortBy: 'ID' | 'NAME' | 'NONE'
    sortDirection: 'ASC' | 'DESC'
    allCompanies: boolean
    showSelectCompany: boolean
}

export class SystemStatusMessageCompanyList extends React.Component<SystemStatusMessageCompanyListProps, SystemStatusMessageCompanyListState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading SystemStatusMessagePage")

        this.state = {
            isLoading: false,
            take: 5,
            page: 1,
            selectedCompanies: [],
            sortBy: 'NAME',
            sortDirection: "DESC",
            allCompanies: true,
            showSelectCompany: false
        }
    }

    componentDidMount() {
        this.setState({
            selectedCompanies: this.props.selectedCompanies,
            allCompanies: this.props.selectedCompanies.length == 0
        })
        console.log("this.props.selectedCompanies: ", this.props.selectedCompanies)

    }

    componentDidUpdate(prevProps: Readonly<SystemStatusMessageCompanyListProps>, prevState: Readonly<SystemStatusMessageCompanyListState>, snapshot?: any) {
        if(prevProps.selectedCompanies != this.props.selectedCompanies) {
            this.setState({
                selectedCompanies: this.props.selectedCompanies,
                allCompanies: this.props.selectedCompanies.length == 0
            })
        }
    }

    componentWillReceiveProps(nextProps: Readonly<SystemStatusMessageCompanyListProps>, nextContext: any) {
        if ((nextProps.selectedCompanies.length == 0) != this.state.allCompanies) {
            this.setState({
                allCompanies: nextProps.selectedCompanies.length == 0
            })
        }
    }

    changeAllCompanies() {
        this.props.updateAllCompaniesSwitch(!this.state.allCompanies)
        this.props.updateSelectedCompanies(this.state.selectedCompanies)
        this.setState({allCompanies: !this.state.allCompanies})
    }

    render() {

        console.log("AllCompanies set to :", this.state.allCompanies)
        console.log("selectedCompanies set to :", this.props.selectedCompanies)

        return <Segment>
            <p> All Companies -
                <Checkbox
                    type='checkbox'
                    toggle
                    checked={!this.state.allCompanies}
                    onChange={() => {
                        this.changeAllCompanies()
                    }}
                />
                - Specific Companies
            </p>
            <IfBox shouldShow={!this.state.allCompanies}>
                <Button onClick={() => this.setState({showSelectCompany: true})}>add company</Button>
                <Button onClick={() => this.onSelect(authentication.company)}>add current company</Button>

                {this.drawCompanyTable()}
                <SelectCompanyModal onSelect={this.onSelect}
                                    onClose={() => this.setState({showSelectCompany: false})}
                                    open={this.state.showSelectCompany}
                                    forCompanyChange={false}
                />

            </IfBox>
        </Segment>
    }

    drawCompanyTable() {
        const columns = [
            {
                id: '_id',
                Header: 'ID',
                width: 200,
                accessor: (d: Company) => d._id

            }, {
                id: 'name',
                Header: 'name',
                width: 500,
                accessor: (d: Company) => d.name
            }, {
                id: 'remove',
                Header: 'remove',
                width: 70,
                accessor: (d: Company) => <Button icon
                                                  onClick={() => this.removeCompany(d)}>
                    <Icon name='trash'/>
                </Button>
            }

        ]

        let data = this.props.selectedCompanies

        if (data == null) {
            return <p>Loading</p>
        }

        return <ReactTable
            data={data}
            pages={Math.ceil(data.length / this.state.take)}
            columns={columns}
            onSortedChange={(newSorted, column, shiftKey) => {
                this.changeSort(column.sortField)
            }}
            defaultPageSize={5}
            className="-striped -highlight"
            loading={this.state.isLoading}
            style={{cursor: "pointer"}}
            onPageChange={(pageIndex) => this.changePage(pageIndex)}
            onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}
        />
    }

    removeCompany = (c: Company) => {
        let newSelectedCompanies = this.props.selectedCompanies
        newSelectedCompanies.splice(newSelectedCompanies.indexOf(c), 1)
        this.setState({selectedCompanies: newSelectedCompanies})
        this.props.updateSelectedCompanies(newSelectedCompanies)

    }

    onSelect = async (c: Company) => {
        let newSelectedCompanies = this.props.selectedCompanies
        let foundCompany = newSelectedCompanies.find((cmpy: Company) => {
            return cmpy._id == c._id
        })
        if (foundCompany == null) {
            newSelectedCompanies.push(c)
            this.setState({selectedCompanies: newSelectedCompanies})
            this.props.updateSelectedCompanies(newSelectedCompanies)
        } else {
            console.log("company already in list")
        }
    }

    changePage(pageIndex: number) {
        this.setState({page: pageIndex})
    }

    changePageSize(newSize: number) {
        this.setState({take: newSize})
    }

    changeSort(sortBy: 'ID' | 'NAME' | "NONE") {

        if (sortBy == 'NONE') {
            return
        }
        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({sortDirection: direction})
        } else {
            //change sortBy and reset direction
            this.setState({sortBy: sortBy, sortDirection: "ASC"})
        }

        //refresh
    }

}