import * as React from "react";
import {Button, Checkbox, Form, Input} from "semantic-ui-react";
import {backend} from "../../../../../../xconvert-backend";
import {AddDefaultsToConfigRequest, ConfigMatch, SearchConfigsRequest} from "../../../../../../generated";
import ReactTable from "react-table";
import AceEditor from 'react-ace';
import './styles.css';
import ace from 'ace-builds'
import extendYamlMode from '../../../../../../components/util/YamlValidator/extendYamlMode.js';
import 'brace/ext/searchbox'
import {ConfigContext} from "../../../../context/ConfigContext";
import {authentication} from "../../../../../../authentication";
import {CherryPickModal} from "../../CherryPickModal";
import {updateConfig, workingConfig} from "../../ConfigSignal";

const YAML = require('yaml');

extendYamlMode(ace)

interface SearchViewProps {
    auth: any
    onCloseCompareModal: () => void
    searchPath: string
}

interface SearchViewState {
    isLoading: boolean,
    searchTerm: string,
    matches: ConfigMatch[],
    showMatch: boolean,
    selectedConfig: ConfigMatch,
    take: number,
    page: number,
    count: number,
    markers: [],
    configToCompare: string,
    currentConfig: string,
    showCompareModal: boolean,
    selectedMatches: string[],
    displayFormat: 'yaml' | 'json',
}

export class SearchView extends React.Component<SearchViewProps, SearchViewState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading SearchView")

        this.state = {
            isLoading: false,
            searchTerm: "",
            matches: [],
            showMatch: false,
            selectedConfig: null,
            take: 10,
            page: 1,
            count: -1,
            markers: [],
            configToCompare: "",
            currentConfig: "",
            showCompareModal: false,
            selectedMatches: [],
            displayFormat: 'yaml',
        }
    }

    async performSearch(searchTerm = this.state.searchTerm, take = this.state.take, page = this.state.page) {
        console.log("process search for ", this.state.searchTerm)
        this.setState({isLoading: true})
        let request = {} as SearchConfigsRequest
        request.searchTerm = searchTerm
        let response = await backend.internalApi.searchConfigs(request, this.props.auth)
        this.setState({
            matches: response.matches,
            count: response.matches.length,
            take: take,
            page: page,
            isLoading: false,
        })
    }

    async componentDidMount() {
        this.findMatchingPathName(this.props.searchPath)
    }


    componentWillReceiveProps(next) {
        //console.log("Props changed to ", next.searchPath)
        this.findMatchingPathName(next.searchPath)

    }

    findMatchingPathName(searchPath) {
        if (searchPath) {
            let cleanedSearchPath = searchPath.split(".").pop()
            console.log("cleanedSearchPath", cleanedSearchPath)
            let searchTerm = "\"" + cleanedSearchPath + "\": (?!null)"
            this.setState({searchTerm: searchTerm})
            this.performSearch(searchTerm)
        }
    }

    createMarkers(selectedConfig = this.state.selectedConfig, displayFormat: "yaml" | "json" = this.state.displayFormat) {
        let markers = [];

        let re = /\s+(?=([^"]*"[^"]*")*[^"]*$)/
        let searchTerms = this.state.searchTerm.split(re)

        for (const [index, value] of searchTerms.entries()) {
            if (value != undefined) {
                this.findMatch(value, markers, selectedConfig, displayFormat)
            }
        }

    }

    findMatch(searchTerm, markers, selectedConfig, displayFormat: "yaml" | "json" = this.state.displayFormat) {
        console.log("find Match")
        let config = selectedConfig.config;
        let startRow = 0;
        let startCol = 0;
        let row = 0;
        if (displayFormat == 'yaml') {
            config = this.jsonToYaml(config).toString();
        }
        config.split("\n").map((line, key) => {
            const first = line.indexOf(searchTerm);

            if (first != -1) {
                startCol = first
                startRow = row
            }
            row++
        })

        markers.push({
            startRow: startRow,
            startCol: startCol,
            endRow: startRow,
            endCol: (startCol + (searchTerm).length),
            className: "mymarker",
            type: "text"
        });

        this.setState({markers: markers})
        console.log('Markers are : ', markers)
    }

    async compare(configToCompare: any) {
        console.log("COMPARE")
        // let left = await this.prepareConfig(workingConfig.value)
        // let right = await this.prepareConfig(configToCompare)
        let left = workingConfig.value
        let right = await this.prepareConfig(configToCompare)

        if(this.state.displayFormat != "json" && left != undefined && right != undefined) {
                left = this.jsonToYaml(left).toString()
                right = this.jsonToYaml(right).toString()
        }

        this.setState({
            currentConfig: left,
            configToCompare: right,
            showCompareModal: true
        })
    }

    onCloseCompareModal() {
        this.props.onCloseCompareModal()
        this.setState({showCompareModal: false})
    }

    jsonToYaml(json: string) {
        if (json) {
            const doc = new YAML.Document();
            doc.contents = JSON.parse(json);
            return doc;
        } else {
            return '';
        }
    }

    yamlToJson(yaml: string) {
        return JSON.stringify(YAML.parse(yaml), null, 4)
    }

    searchViewType() {
        if (!this.state.selectedConfig) {
            return null;
        }
        if (this.state.displayFormat == 'yaml') {
            return this.jsonToYaml(this.state.selectedConfig.config).toString();

        }
        return this.state.selectedConfig.config;

    }

    switchDisplayFormat() {
        if (this.state.displayFormat == 'json') {
            return 'yaml'
        }
        return 'json'
    }

    render() {
        return <Form>
            <Input style={{width: 220}} placeholder='Search for...' value={this.state.searchTerm}
                   onChange={(evt) => this.setState({searchTerm: evt.target.value})}/>
            <Button onClick={
                () => {
                    this.performSearch()
                }
            }>search</Button>

            <Button onClick={() => this.switchToSystemStatusMessageSide()}
                    disabled={this.state.selectedMatches.length == 0}
            >add SystemStatusMessage to selected Companies</Button>

            <ReactTable
                data={this.state.matches}
                columns={this.createColumns()}
                className="-striped -highlight"
                loading={this.state.isLoading}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        onClick: () => {
                            if (rowInfo) {
                                console.log("Opening match with id", rowInfo.row)
                                this.setState({selectedConfig: rowInfo.row._original})
                                this.createMarkers(rowInfo.row._original)
                            }
                        }
                    };
                }}
                style={{cursor: "pointer"}}
                defaultPageSize={this.state.take}
            />
            <p style={{fontSize: "16px"}}>Yaml - <Checkbox
                type='checkbox'
                toggle
                checked={this.state.displayFormat == 'json'}
                onChange={() => {
                    let displayFormat: "yaml" | "json" = this.switchDisplayFormat()
                    this.setState({
                        displayFormat: displayFormat
                    })
                    this.createMarkers(this.state.selectedConfig, displayFormat)
                }}
            /> - Json </p>
            <AceEditor
                theme="monokai"
                mode={this.state.displayFormat}
                value={this.searchViewType()}
                width="100%"
                height="900px"
                markers={this.state.markers}
                readOnly
            />
            {this.state.showCompareModal && <CherryPickModal
                isOpen={this.state.showCompareModal}
                left={this.state.currentConfig}
                right={this.state.configToCompare}
                onSave={(newConfig) => {
                    let c = newConfig
                    if(this.state.displayFormat != "json") {
                        c = this.yamlToJson(newConfig)
                    }
                    updateConfig(c, true, false).then(r => {
                        this.onCloseCompareModal()
                    })

                }}
                onClose={() => {
                    this.setState({showCompareModal: false})
                }}
            />}
        </Form>
    }

    async prepareConfig(config: string) {
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let request = {} as AddDefaultsToConfigRequest
        request.config = JSON.parse(config)

        let response = await backend.internalApi.addDefaultsToConfig(request, auth)
        return JSON.stringify(response.config, null, 4)
    }

    createColumns() {
        return [{
            id: 'id',
            Header: 'id',
            width: 220,
            accessor: (d: any) => d.id,
        }, {
            id: 'companyName',
            Header: 'companyName',
            width: 220,
            accessor: (d: any) => d.name,
        }, {
            id: 'compare',
            Header: 'compare',
            accessor: (d: any) => <Button onClick={() => this.compare(d.config)}>compare</Button>
        }, {
            id: 'select',
            Header: 'select',
            accessor: (d: any) => <Checkbox onChange={(event, data) => {
                let selectedMatches = this.state.selectedMatches
                if (data.checked) {
                    selectedMatches.push(d.id)
                } else {
                    selectedMatches.splice(selectedMatches.indexOf(d.id), 1)
                }
                this.setState({selectedMatches: selectedMatches})

            }}/>
        }]
    }

    switchToSystemStatusMessageSide() {
        this.context.setSearchViewCompanyIds(this.state.selectedMatches)

        const link = window.location.origin + "/systemStatusMessage/" + JSON.stringify(this.state.selectedMatches)
        window.location.replace(link)
    }
}