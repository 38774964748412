import * as React from "react";
import {
    Modal,
    Header,
    Button,
    Dropdown,
    DropdownItemProps,
    Grid,
    Checkbox,
    Accordion, Icon
} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {UpdateQueuedEventsRequest} from "../../../../../generated";

export interface ChangeMultipleQueuedEventsModalProps {
    isOpen: boolean
    onClose: (success) => void
    queuedEventIds: string[]

}

export interface ChangeMultipleQueuedEventsModalState {
    newState: UpdateQueuedEventsRequest.NewProcessingStateEnum
    newProcessed: boolean
    setState: boolean
    setProcessed: boolean
    activeIndexS: number
    activeIndexP: number

}

export class ChangeMultipleQueuedEventsModal extends React.Component
    <ChangeMultipleQueuedEventsModalProps, ChangeMultipleQueuedEventsModalState> {

    constructor(props) {
        super(props)
        this.state = {
            setState: false,
            setProcessed: false,
            newState: null,
            newProcessed: false,
            activeIndexS: -1,
            activeIndexP: -1

        }
    }

    handleClose = (success) => {

        this.props.onClose(success)
    }

    async save() {
        let auth = backend.withTokenAuthHeader(authentication.token)

        let request = {} as UpdateQueuedEventsRequest
        request.queuedEventIds = this.props.queuedEventIds
        if (this.state.setState) {
            request.newProcessingState = this.state.newState
        }
        if (this.state.setProcessed) {
            request.newProcessed = this.state.newProcessed
        }
        request.companyId = this.context.companyId

        let response = await backend.internalApi.updateQueuedEvents(request, auth)
        this.handleClose(response.successful)
    }

    componentDidMount() {

    }

    enumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(UpdateQueuedEventsRequest.NewProcessingStateEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }

    handleClickSetState = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndexS} = this.state
        const newIndex = activeIndexS === index ? -1 : index

        this.setState({activeIndexS: newIndex, setState: !this.state.setState})
    }

    handleClickSetProcessed = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndexP} = this.state
        const newIndex = activeIndexP === index ? -1 : index

        this.setState({activeIndexP: newIndex, setProcessed: !this.state.setProcessed})
    }

    render() {
        const {activeIndexS} = this.state
        const {activeIndexP} = this.state
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Change Multiple Queued Events'/>
            <Modal.Content>
                <Grid padded="horizontally" stackable columns='equal' centered>
                    <Grid.Column>
                        <Accordion>
                            <Accordion.Title
                                active={activeIndexS === 0}
                                index={0}
                                onClick={this.handleClickSetState}
                            >
                                <Icon name='dropdown'/>
                                set state
                            </Accordion.Title>
                            <Accordion.Content active={activeIndexS === 0}>
                                <Dropdown
                                    selectOnBlur={false}
                                    placeholder='Select State'
                                    fluid
                                    search
                                    selection
                                    options={this.enumToArray()}
                                    clearable
                                    onChange={(event, {value}) => {
                                        this.setState({newState: value as UpdateQueuedEventsRequest.NewProcessingStateEnum})
                                    }}
                                />

                            </Accordion.Content>
                        </Accordion>


                    </Grid.Column>
                    <Grid.Column>
                        <Accordion>
                            <Accordion.Title
                                active={activeIndexP === 0}
                                index={0}
                                onClick={this.handleClickSetProcessed}
                            >
                                <Icon name='dropdown'/>
                                set processed
                            </Accordion.Title>
                            <Accordion.Content active={activeIndexP === 0}>
                                processed -
                                <Checkbox
                                    type='checkbox'
                                    checked={this.state.newProcessed}
                                    onChange={() => this.setState({newProcessed: !this.state.newProcessed})}
                                />
                            </Accordion.Content>
                        </Accordion>

                    </Grid.Column>
                </Grid>
                <Button onClick={() => this.save()}>Update</Button>

                <Button onClick={() => this.handleClose(false)}>Cancel</Button>

            </Modal.Content>
        </Modal>

    }

}

