import * as React from "react";
import { useState, useContext } from "react";
import { Button, Header, Icon, Label, List, Modal } from "semantic-ui-react";
import { ConfigContext } from "../../../context/ConfigContext";
import { backend } from "../../../../../xconvert-backend";
import { authentication } from "../../../../../authentication";
import { ftp } from "../../../../../xconvert-ftp";
import { IfBox } from "../../../../style/if";
import { v4 as uuid } from 'uuid';
import {DeleteFileResponse} from "../../../../../generated-ftp/api";

export interface FtpExplorerMultiFileDeleteModalProps {
    isOpen: boolean;
    onClose: (success: boolean) => void;
    files: string[];
    path: string;
}

export function FtpExplorerMultiFileDeleteModal(props: FtpExplorerMultiFileDeleteModalProps) {
    const context = useContext(ConfigContext);
    const [response, setResponse] = useState<string | null>(null);

    function handleClose() {
        props.onClose(false);
    }

    async function handleDelete() {
        let allSuccessful = true;
        for (const file of props.files) {
            const path = `${props.path}/${file}`;
            const auth = backend.withTokenAuthHeader(authentication.token);

            let response: DeleteFileResponse;
            try {
                response = await ftp.ftpApi.deleteFile(path, context.companyId, auth);
            } catch (ex) {
                response = { success: false, errorMessage: `Exception occurred while call: ${ex.statusText}` };
            }

            if (!response.success) {
                allSuccessful = false;
                setResponse(response.errorMessage);
            }
        }

        if (allSuccessful) {
            props.onClose(true);
        }
    }

    function renderListItems() {
        if (!props.files || props.files.length === 0) {
            return (
                <List.Item key={0}>
                    <List.Content>No Files selected</List.Content>
                </List.Item>
            );
        }

        return props.files.map((file: string) => (
            <List.Item key={uuid()}>{file}</List.Item>
        ));
    }

    return (
        <Modal open={props.isOpen} onClose={handleClose} dimmer="blurring" size="small">
            <Header icon="browser" content="Delete Files" />
            <Modal.Content>
                <p>Are you sure you want to delete the following files?</p>
                <p>This action can't be reversed!</p>
                <List divided verticalAlign="middle" bulleted>
                    {renderListItems()}
                </List>
                <Button onClick={handleDelete}>
                    <Icon name="trash" color="red" /> delete
                </Button>
                <Button onClick={handleClose}>cancel</Button>
                <IfBox shouldShow={response != null}>
                    <Label color="red">{response}</Label>
                </IfBox>
            </Modal.Content>
        </Modal>
    );
}
