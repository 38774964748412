import DownloadLink from "./index";
import * as React from "react";
import {Icon} from "semantic-ui-react";

export interface DownloadButtonProps {
    filename: any;
    exportFile: any;
    label?: string;
    icon?: JSX.Element;
}

export function DownloadButton(props: DownloadButtonProps) {

    const label = props.label || "Download";
    const icon = props.icon || <Icon name={"download"} />;

    const buttonStyle: React.CSSProperties = {
        backgroundColor: '#4CAF50',
        border: 'none',
        color: 'white',
        padding: '9px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '15px',
        borderRadius: '5px'
    };

    return <DownloadLink
        tagName="button"
        label={label}
        icon={icon}
        style={buttonStyle}
        filename={props.filename}
        exportFile={() => props.exportFile}
    />
}