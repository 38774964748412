import * as React from "react";
import {Button, Dropdown, Input, Segment} from "semantic-ui-react";
import {enumToDropdownItemProps} from "../../../../../utils";
import {RawRequest} from "../../../../../generated";
import ReactTable, {Column} from "react-table";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import RawRequestModal from "./RawRequestModal";
import {useEffect} from "react";

export enum Method {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"

}

interface RawRequestFilter {
    endTime?: Date,
    limit?: number,
    queryId?: string,
    queryMethod?: string,
    queryUrl?: string,
    queryUserMail?: string,
    queryXRequestId?: string,
    skip?: number,
    startTime?: Date,
    sortBy: 'ID' | 'TIMESTAMP' | 'USER_MAIL' | 'ENDPOINT',
    sortDirection?: "ASC" | "DESC"
}

export interface RawRequestsPageProps {

}

export function RawRequestsPage(props: React.PropsWithChildren<RawRequestsPageProps>) {

    const [filter, setFilter] = React.useState<RawRequestFilter>({} as RawRequestFilter)
    const [data, setData] = React.useState<RawRequest[]>([])
    const [totalRecords, setTotalRecords] = React.useState(0)
    const [showModal, setShowModal] = React.useState(false)
    const [rawRequestToShow, setRawRequestToShow] = React.useState<RawRequest>(null)
    const [id, setId] = React.useState<string | undefined>("")
    const [url, setUrl] = React.useState<string | undefined>("")
    const [user, setUser] = React.useState<string | undefined>("")
    const [method, setMethod] = React.useState<Method | undefined>(undefined)
    const [isLoading, setLoading] = React.useState(false)

    useEffect(() => {
        fetch()
    }, [])

    async function fetch(take = filter.limit, skip = filter.skip) {
        let auth = backend.withTokenAuthHeader(authentication.token)

        let response = await backend.internalApi.fetchRawRequests(
            filter.endTime,
            take,
            filter.queryId,
            filter.queryMethod,
            filter.queryUrl,
            filter.queryUserMail,
            filter.queryXRequestId,
            skip,
            filter.sortBy,
            filter.sortDirection,
            filter.startTime,
            auth
        )

        setData(response.requests ?? [])
        setTotalRecords(response.count)
        setLoading(false)
    }

    function clearFilter() {
        setFilter({} as RawRequestFilter)
    }

    function drawFilterInputs() {
        return <Segment>
            <Input
                label={"Id"}
                type={"text"}
                value={id}
                onChange={(event) => {
                    setId(event.target.value)
                }}
            />

            <Input
                label={"Url"}
                type={"text"}
                value={url}
                onChange={(event) => {
                    setUrl(event.target.value)
                }}
            />

            <Input
                label={"user"}
                type={"text"}
                value={user}
                onChange={(event) => {
                    setUser(event.target.value)
                }}
            />

            <Dropdown
                selectOnBlur={false}
                id={"methodDropdown"}
                placeholder='Select'
                search
                selection
                options={enumToDropdownItemProps(Method)}
                clearable
                value={method}
                onChange={(event, {value}) => {
                    console.log(event.target)
                    // @ts-ignore
                    setMethod(event.target.textContent)
                }}
            />

            <Button
                icon={'search'}
                onClick={() => fetch()}
            />
            <Button
                icon={'trash'}
                onClick={() => clearFilter()}
            />
        </Segment>
    }

    const columns: Column[] = [
        {
            id: 'ID',
            Header: 'Id',
            resizable: true,
            width: 220,
            accessor: (row: RawRequest) => <>{row._id}</>,
        },
        {
            id: 'ENDPOINT',
            Header: 'Url',
            resizable: true,
            width: 400,
            accessor: (row: RawRequest) => <>{row.harRequest?.url}</>,
        },
        {
            id: 'METHOD',
            Header: 'Method',
            resizable: true,
            width: 120,
            accessor: (row: RawRequest) => <>{row.harRequest?.method}</>,
        },
        {
            id: 'TIMESTAMP',
            Header: 'Timestamp',
            resizable: true,
            width: 200,
            accessor: (row: RawRequest) => <>{row.timeOfAttempt}</>,
        },
        {
            id: 'USER_MAIL',
            Header: 'tried by user',
            resizable: true,
            width: 200,
            accessor: (row: RawRequest) => <>{row.user?.email}</>,
        }
    ]

    function drawTable() {
        let pages = Math.ceil(totalRecords / filter.limit)
        return <ReactTable
            data={data}
            pages={pages}
            columns={columns}
            manual
            sorted={[
                {
                    id: 'createdAt',
                    desc: false
                }
            ]}
            defaultPageSize={filter.limit}
            className="-striped -highlight"
            loading={isLoading}
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: () => {
                        if (rowInfo) {
                            setRawRequestToShow(rowInfo.row._original)
                            setShowModal(true)
                        }
                    }
                };
            }}
            style={{cursor: "pointer"}}
            onPageChange={(page) => changePage(page)}
            onPageSizeChange={(pageSize) => changePageSize(pageSize)}
        />
    }

    return <>
        {drawFilterInputs()}
        {drawTable()}
        {rawRequestToShow && <RawRequestModal
            isOpen={showModal}
            onClose={() => {
                setShowModal(false)
            }}
            rawRequest={rawRequestToShow}
        />}
    </>

    function changePage(pageIndex: number) {
        setLoading(true)
        let newSkip = pageIndex * filter.limit
        filter.skip = newSkip
        fetch(filter.limit, newSkip)
    }

    function changePageSize(newSize: number) {
        setLoading(true)
        filter.limit = newSize
        fetch(newSize, filter.skip)
    }
}
