import * as React from "react";
import {Button, Grid, Header, Modal} from "semantic-ui-react";
import {ChangeEvent, Origin, RollbackTransportOrderRequest, TransportOrder} from "../../../../../../generated";
import Notifications, {notify} from 'react-notify-toast';
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {CustomDiff} from "../../../configChange/configEdit/diff/CustomDiff";

export interface TransportOrderRollbackModalProps {
    isOpen: boolean
    onClose: (reload: boolean) => void
    transportOrder: TransportOrder
    followingConfig: Origin
    change: ChangeEvent
}

export class TransportOrderRollbackModal extends React.Component<TransportOrderRollbackModalProps, any> {

    constructor(props) {
        super(props)
        this.state = {
            showRollBackView: false,
        }
    }


    async handleRollback() {
        let auth = backend.withTokenAuthHeader(authentication.token)

        let request = {} as RollbackTransportOrderRequest
        request.transportOrderId = this.props.transportOrder._id
        request.changeId = this.props.change._id

        try {
            await backend.internalApi.rollbackTransportOrder(
                request,
                auth
            )

        } catch (e) {
            notify.show("ERROR: unable to rollback transportOrder!", 'error', 5000, '#fc0303')
        }

        this.props.onClose(true)
    }

    handleClose = () => {
        this.props.onClose(false)
    }

    render() {
        if (this.props.change != null) {
            return <Modal
                open={this.props.isOpen}
                onClose={this.handleClose}
                onMount={() => {
                }}
                dimmer="blurring"
                size='fullscreen'>
                <Header icon='browser' content='TransportOrder History (rollback view)'/>
                <Modal.Content>
                    <p>This view shows what will change when you rollback to this state. It compares the current state
                        of the TransportOrder
                        with the state it was when this history was written.</p>
                    <Grid padded="horizontally" stackable columns='equal' centered>
                        <Grid.Row>
                            <Grid.Column>Id</Grid.Column>
                            <Grid.Column>{this.props.change._id ?? "N/A"}</Grid.Column>
                        </Grid.Row><Grid.Row>
                        <Grid.Column>Timestamp</Grid.Column>
                        <Grid.Column>{this.props.change.timestamp}</Grid.Column>
                    </Grid.Row> <Grid.Row>
                        <Grid.Column>comment</Grid.Column>
                    </Grid.Row>
                        <Grid.Row>
                            <Grid.Column><h2>CURRENT</h2></Grid.Column>
                            <Grid.Column><h2>{"--> compared to -->"}</h2></Grid.Column>
                            <Grid.Column><h2>AFTER ROLLBACK</h2></Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <CustomDiff
                        oldJson={JSON.stringify(this.props.transportOrder, null, 4)}
                        newJson={JSON.stringify(this.props.change.transportOrderEvent.transportOrder, null, 4)}
                    />
                    <Button primary onClick={() => {
                        console.log("open Rollback view")
                        this.handleRollback().then()
                    }}>save Rollback</Button>
                    <Button onClick={() => this.handleClose()}>cancel</Button>
                    <Notifications/>
                </Modal.Content>
            </Modal>
        } else {
            return <p/>
        }
    }

}