import * as moment from 'moment';
import {v4 as uuid} from 'uuid';
import {LiteEvent} from "./event";
import {Company, User} from "./generated";
import {backend} from "./xconvert-backend";
import PermissionsEnum = User.PermissionsEnum;

export interface AuthenticationResult {
    successful: boolean
}

interface UserAccount {
    isAuthenticated: boolean
    token: string
    validUntil: Date
    user: User
    company: Company,
    isSuperuser: boolean | null
    canSwitchCompany: boolean | null
    hasCmUsePermission: boolean | null
}

const LOGENIOS_ACCOUNT_STORAGE_KEY = "logenios_account"
const LOGENIOS_DEVICE_ID_KEY = "device_identifier"

class UserAuthentication {

    isAuthenticated: boolean
    token: string
    validUntil: Date
    user: User
    company: Company
    deviceId: string | null
    isSuperUser: boolean | null
    hasCmUsePermission: boolean | null
    canSwitchCompany: boolean | null

    constructor() {
        try {
            this.deviceId = localStorage.getItem(LOGENIOS_DEVICE_ID_KEY)
            if (this.deviceId == null) {
                this.deviceId = uuid()
                localStorage.setItem(LOGENIOS_DEVICE_ID_KEY, this.deviceId)
            }

            console.log("Device id is " + this.deviceId);

            let usr = localStorage.getItem(LOGENIOS_ACCOUNT_STORAGE_KEY)
            let usrObj: UserAccount = JSON.parse(usr)

            let tokenValidUntil = moment(usrObj.validUntil)
            let isTokenStillValid = tokenValidUntil > moment()

            this.isAuthenticated = (usrObj.isAuthenticated || false) && isTokenStillValid
            console.log("is Authenticated: " + this.isAuthenticated + " Token valid until " + usrObj.validUntil)
            this.onUserAuthenticated.trigger(this.isAuthenticated)
            this.token = usrObj.token || null
            this.validUntil = new Date(usrObj.validUntil)
            this.user = usrObj.user
            this.company = usrObj.company
            this.isSuperUser = usrObj.isSuperuser
            this.canSwitchCompany = usrObj.canSwitchCompany
            this.hasCmUsePermission = usrObj.hasCmUsePermission

            var t = this.validUntil.getTime() - (new Date()).getTime();
            if (this.isAuthenticated && !isNaN(t) && t > 1000) {
                setTimeout(() => { this.isAuthenticated = false; location.reload(); }, t);
            }
        } catch (ex) {
            this.isAuthenticated = false
            this.token = null
        }

    }

    saveLocalStorage() {
        localStorage.setItem(LOGENIOS_ACCOUNT_STORAGE_KEY, JSON.stringify({
            isSuperuser: this.isSuperUser,
            hasCmUsePermission: this.hasCmUsePermission,
            isAuthenticated: true,
            token: this.token,
            user: this.user,
            company: this.company,
            validUntil: this.validUntil,
            canSwitchCompany: this.isSuperUser || this.user.availableCompanies.length > 1
        }))
    }

    async doAuthenticate(userName: String, password: String, totp: String | null): Promise<AuthenticationResult> {
        try {
            const authHeader = backend.withBasicAuthHeader(userName, password, totp)

            await backend.accountApi.checkAuthentication(authHeader)

            const isSuperuserPromise = backend.internalApi.isSuperuser(authHeader)
            this.isSuperUser = (await isSuperuserPromise).isSuperuser

            this.user = await backend.accountApi.fetchCurrentUser(authHeader)

            this.hasCmUsePermission = this.user.permissions.find(value => value == PermissionsEnum.CMUSE) != null
            if (!this.isSuperUser && !this.hasCmUsePermission ) {
                console.log("No superuser, nor CM_USE permission available.");
                return { "successful": false }
            }

            const companyPromise = backend.companyApi.fetchCompanyOfUser(authHeader)

            let token = await backend.accountApi.generateAuthToken(
                {
                    name: "Logenios CONFIGMANAGER (" + this.deviceId + ")",
                    expires: "7d"
                },
                authHeader
            )

            this.token = token.token
            this.validUntil = new Date(token.expiryDate)

            this.isAuthenticated = true

            this.company = await companyPromise

            this.saveLocalStorage()

            this.onUserAuthenticated.trigger(this.isAuthenticated)

            console.log("Company is ", this.company)
            console.log("User is ", this.user)

            return { "successful": true }
        } catch (error) {
            if(error.status == 420){
                throw error
            }
            console.error(error)
            return { "successful": false }
        }
    }

    private readonly onUserAuthenticated = new LiteEvent()
    public get authenticated() { return this.onUserAuthenticated.expose() }

    logout() {
        console.log("Perform logout")
        this.isAuthenticated = false
        this.onUserAuthenticated.trigger(this.isAuthenticated)

        this.token = null

        localStorage.removeItem(LOGENIOS_ACCOUNT_STORAGE_KEY)
        // DELETE THE FOLLOWING
        localStorage.removeItem("config_storage_key")
    }

    hasAnyPermission(...permissions: User.PermissionsEnum[]) {
        return this.isSuperUser || this.user.permissions.filter((it) => permissions.indexOf(it) > -1).length > 0
    }

    hasAllPermissions(...permissions: User.PermissionsEnum[]) {
        return this.isSuperUser || this.user.permissions.filter((it) => permissions.indexOf(it) > -1).length == permissions.length
    }

    hasPermission(permission: User.PermissionsEnum) {
        return this.isSuperUser || this.user.permissions.indexOf(permission) >= 0
    }

}

export const authentication = new UserAuthentication()