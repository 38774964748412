import {backend} from "../../../xconvert-backend";
import {authentication} from "../../../authentication";
import {GraylogRequest} from "../../../generated";

export class GrayLogApi {

    public async search(range: number, query: string) {
        let auth = backend.withTokenAuthHeader(authentication.token)

        let request = {} as GraylogRequest
        request.query = query
        request.range = range

        return await backend.internalApi.searchGraylog(request, auth)

    }

}

export const grayLogApi = new GrayLogApi