import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {GenericXlsExtractionRule} from "../../../../../generated";



type RightMenuState = {
    selectedRule: GenericXlsExtractionRule|null
    rulesToEdit: GenericXlsExtractionRule[]|null
    editSelectedRuleMode: boolean
}

const initialState = {
    selectedRule: null,
    editSelectedRuleMode: false
} as RightMenuState


const rightMenuSlice = createSlice({
    name:"rightMenu",
    initialState,
    reducers: {
        setRuleToDisplay(state, action: PayloadAction<GenericXlsExtractionRule|null>){
            state.selectedRule = action.payload
            if(action.payload)
                state.rulesToEdit = [action.payload]
            else
                state.rulesToEdit = []

            if(state.selectedRule == null){
                state.editSelectedRuleMode = false
            }
        },
        setEditSelectedRuleMode(state, action: PayloadAction<boolean>){
            state.editSelectedRuleMode = action.payload
        },
        addRuleToEdit(state, action: PayloadAction<GenericXlsExtractionRule>){
            state.rulesToEdit = [...(state.rulesToEdit||[]), action.payload]
        },
        clearRulesToEdit(state){
            state.rulesToEdit = null
        }
    }
})

export const {setRuleToDisplay, setEditSelectedRuleMode,addRuleToEdit,clearRulesToEdit} = rightMenuSlice.actions
export default rightMenuSlice.reducer
export const getRuleToDisplay = (state: RootState) => state.rightMenu.selectedRule;
export const getRulesToEdit = (state: RootState) => state.rightMenu.rulesToEdit;
export const isEditSelectedRuleModeActive = (state:RootState)=> state.rightMenu.editSelectedRuleMode;
export const getRuleIdOffset = (state:RootState)=>state.rightMenu.rulesToEdit.length