import * as React from "react";
import {Tab} from 'semantic-ui-react'
import {ContractsPage} from "./contracts/ContractsPage";
import {ProjectWorkLogsPage} from "./projectWorkLogs/ProjectWorkLogsPage";
import {BillingToolPage} from "./billing/BillingToolPage";
import {InvoicePdfSplitting} from "./invoicePdfSplitting/InvoicePdfSplitting";
import {InvoiceList} from "./invoiceList/InvoiceList";

const panes = [
    { menuItem: 'Contracts', render: props => <Tab.Pane><ContractsPage /></Tab.Pane> },
    { menuItem: 'ProjectWorkLog', render: props => <Tab.Pane id='treeTab'><ProjectWorkLogsPage /></Tab.Pane> },
    { menuItem: 'Billing', render: props => <Tab.Pane><BillingToolPage /></Tab.Pane> },
    { menuItem: 'InvoicePdfSplitting', render: props => <Tab.Pane><InvoicePdfSplitting /></Tab.Pane> },
    { menuItem: 'invoiceList', render: props => <Tab.Pane><InvoiceList /></Tab.Pane> },
]

const CustomerAccountingTabMenu = props => <Tab
    id={"CustomerAccountingTabManu"}
    className={"flexTab"}
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.customerAccountingTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("customerAccountingTab", data.activeIndex)
    }}
/>

export default CustomerAccountingTabMenu