import * as React from "react";
import {Tab} from "semantic-ui-react";
import {MultiOrderScriptEdit} from "./MultiOrderScriptEdit";
import {MultiOrderScriptResult} from "./MultiOrderScriptResult";

let panes = [
    {menuItem: 'Script Edit',
        render: props => <Tab.Pane><MultiOrderScriptEdit
            updateOriginalOrders={props.updateOriginalOrders}
            updateXRequestId={props.updateXRequestId}
            history={props.history}
            location={props.location}
            match={props.match}
        /></Tab.Pane>
    },
    {menuItem: 'Result',
        render: props => <Tab.Pane><MultiOrderScriptResult
            xRequestId={props.xRequestId}
            originalOrders={props.originalOrders}
        /></Tab.Pane>
    },

]


const MultiOrderScriptTabMenu = props => <Tab
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.multiOrderScriptTabMenu}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("multiOrderScriptTabMenu", data.activeIndex)
    }}
/>

export default MultiOrderScriptTabMenu