import * as React from "react";
import { Modal, Header, Button, Label, Input, Divider, Grid } from "semantic-ui-react";
import { ConfigContext } from "../../../context/ConfigContext";
import { backend } from "../../../../../xconvert-backend";
import { authentication } from "../../../../../authentication";

import 'brace/ext/searchbox'
import {InputFile} from "../../../../util/InputFile";

export interface FtpExplorerUploadFileModalProps {
    isOpen: boolean
    onClose: (success: boolean) => void
    path: string
}

export const FtpExplorerUploadFileModal: React.FC<FtpExplorerUploadFileModalProps> = ({isOpen, onClose, path}) => {
    const context = React.useContext(ConfigContext);
    const [files, setFiles] = React.useState<File[]>([]);
    const [names, setNames] = React.useState<string[]>([]);
    const [sizes, setSizes] = React.useState<number[]>([]);

    const handleSave = async () => {
        const auth: any = backend.withTokenAuthHeader(authentication.token)

        var data = new FormData()
        files.forEach(file => {
            data.append('file', file)
        })

        let response = await fetch('/ftp/files/upload?path=' + path + '&companyId=' + context.companyId, {
            method: 'POST',
            body: data,
            headers: auth.headers
        })

        console.log(response)
        onClose(response.ok)
    }

    const handleClose = () => {
        onClose(false)
    }

    const handleChange = (files: FileList) => {
        console.log("uploaded files")
        setFiles(Array.from(files));
        setNames(Array.from(files).map(file => file.name));
        setSizes(Array.from(files).map(file => file.size));
    }

    const getReadableFileSizeString = (fileSizeInBytes: number) => {
        let i = -1;
        let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            dimmer="blurring"
            size='small'>
            <Header
                icon='browser'
                content='Upload File'
            />
            <Modal.Content>

                <InputFile
                    button={{}}
                    input={{
                        id: 'input-control-id',
                        onChange: (evt) => handleChange(evt.target.files)
                    }}
                    multiple={true}
                />

                <Divider hidden/>

                <ul>
                    {names.map((name, index) => (
                        <li key={index}>
                            <Label>{name}</Label><Label size={"small"}>({getReadableFileSizeString(sizes[index])})</Label>
                        </li>
                    ))}
                </ul>


                <Divider hidden/>

                <Button disabled={names.length === 0} onClick={() => handleSave()}>save</Button>
                <Button onClick={() => handleClose()}>close</Button>

            </Modal.Content>
        </Modal>
    )
}