import * as React from 'react';
import {Header, Modal} from "semantic-ui-react";
import {AddressMappingGeofenceForm} from "./address_mapping_geofence_form";
import {GlobalGeofence} from "../../../../../generated";

export interface GlobalGeofenceCreateModalProps {
    isOpen: boolean,
    onClose: () => void
    onSave: (newGlobalGeofence: GlobalGeofence) => Promise<any>
    onPendingChange: () => void
    globalGeofence: GlobalGeofence
}

export function GlobalGeofenceUpdateModal(props: React.PropsWithChildren<GlobalGeofenceCreateModalProps>) {

    // Darmstadt is default
    let initLat = 49.882452
    let initLng = 8.644958
    let geofenceStr = props.globalGeofence.resolvingGeofence.geofence
    if(geofenceStr) {
        let geofence = JSON.parse(geofenceStr)

        initLat = geofence[0][0]?.lat
        initLng = geofence[0][0]?.lng
    }
    return <Modal
        open={props.isOpen}
        onClose={props.onClose}
        dimmer="blurring"
        size='fullscreen'>
        <Header icon='browser' content='Create Global Geofence'/>
        <Modal.Content>

            <AddressMappingGeofenceForm
                mapping={props.globalGeofence}
                initialLatitude={initLat}
                initialLongitude={initLng}
                onSave={props.onSave}
                saveButtonText={"save"}
                onPendingChange={props.onPendingChange}
                onClose={props.onClose}
            />

        </Modal.Content>
    </Modal>

}