import * as React from "react";
import {Accordion, AccordionProps, Button, Grid, Header, Icon, Input, Message, Modal} from "semantic-ui-react";
import {
    CompanyConfiguration,
    TransportOrderConfigTest,
    TransportOrderConfigTestResult,
    TransportOrderConfigTestUpdate,
    UpdateTransportOrderConfigTestsRequest
} from "../../../../../generated";
import {ConfigContext} from "../../../context/ConfigContext";
import {IfBox} from "../../../../style/if";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {CustomDiff} from "../../configChange/configEdit/diff/CustomDiff";

export interface MultiTestResultModalProps {
    isOpen: boolean
    onClose: (success: boolean) => void
    testResults: TransportOrderConfigTestResult[]
    lastSuccessConfig: CompanyConfiguration
    tests: TransportOrderConfigTest[]
}

export interface MultiTestResultModalState {

    isLoading: boolean

    showConfigCompare: boolean
    showUpdateExpected: boolean

    responseMessage: string | null
    responseSuccess: boolean
    activeIndexes: string[]

    updatedTests: TransportOrderConfigTest[]
    combinedIgnoredFields: string[]
    combinedFoundDifferences: string[]
}

export class MultiTestResultModal extends React.Component<MultiTestResultModalProps, MultiTestResultModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {

            isLoading: true,

            showConfigCompare: false,
            showUpdateExpected: false,

            responseMessage: null,
            responseSuccess: false,
            activeIndexes: [],
            updatedTests: [],
            combinedIgnoredFields: [],
            combinedFoundDifferences: [],
        }
    }

    showResponseInfo() {
        if (this.state.responseMessage == null) {
            return <p/>
        }
        if (this.state.responseSuccess) {
            return <Message key={"test"}
                            success
                            content={this.state.responseMessage}
            />
        } else {
            return <Message key={"test"}
                            error
                            content={this.state.responseMessage}
            />
        }

    }

    testResultContentString(result: string, type: TransportOrderConfigTest.TypeEnum): string {
        if (type == TransportOrderConfigTest.TypeEnum.EXPORT) {
            let object = JSON.parse(result)
            return object.map(it => this.filesBase64ContentToString(it)).join("\n")
        } else {
            return result
        }
    }

    filesBase64ContentToString(file) {
        if (file) {
            return atob(file.base64Content)
        } else {
            return null
        }
    }

    componentDidMount() {
        let tests = this.props.tests.filter(t => {
            return t.success == false
        })
        let combinedFields = tests[0]?.expectedResult?.fieldsToIgnore
        tests.forEach(test => {
            test.expectedResult.fieldsToIgnore.forEach(field => {
                if (!combinedFields.includes(field)) {
                    combinedFields = combinedFields.filter(f => f != field)
                }
            })
        })

        let combinedFoundDifferences = []
        let results = this.props.testResults.filter(r => {
            return r.success == false
        })
        results.forEach(r => {
            r.foundDifferences?.forEach(d => {
                if (!combinedFoundDifferences.includes(d)) {
                    combinedFoundDifferences.push(d)
                }
            })
        })


        this.setState({
            updatedTests: tests,
            combinedIgnoredFields: combinedFields,
            combinedFoundDifferences: this.cleanFoundDifferences(combinedFoundDifferences)
        })
    }

    cleanFoundDifferences(foundDifferences: string[]) {
        let clean = []
        let regex = /(\[[0-9]*\]\.)/gm
        if (foundDifferences != null) {
            foundDifferences.map(d => {
                let c = d.replace(regex, "[*].")
                if (clean.includes(c) == false) {
                    clean.push(c)
                }
            })
            return clean
        }
        return []

    }

    handleClose = (success) => {
        this.props.onClose(success)
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    checkForProps(i: number) {
        if (this.state.isLoading && i < 50) {
            this.sleep(100).then(() => {
                console.log("checking for props: ", i)
                this.checkForProps(i + 1)
            })
        } else {
            console.log("found props")
        }
    }

    handleAccordionClick = (e: any, titleProps: AccordionProps) => {
        console.log("handleAccordionClick")
        const {index} = titleProps
        let newActiveIndexes
        if (this.state.activeIndexes.includes(index)) {
            newActiveIndexes = this.state.activeIndexes.filter(i => i != index)
        } else {
            newActiveIndexes = this.state.activeIndexes
            newActiveIndexes.push(index)
        }
        this.setState({activeIndexes: newActiveIndexes})
    }

    drawSingleTestResult(test: TransportOrderConfigTest, result: TransportOrderConfigTestResult) {
        let fieldsToIgnore = test.expectedResult.fieldsToIgnore
        if (fieldsToIgnore == null) {
            fieldsToIgnore = []
        }
        return <>
            <Accordion.Title
                active={this.state.activeIndexes.includes(test.name)}
                index={test.name}
                onClick={this.handleAccordionClick}
            >
                {this.drawSuccessIcon(result.success)} - {test.name}
            </Accordion.Title>
            <Accordion.Content
                active={this.state.activeIndexes.includes(test.name)}
            >
                {this.showResponseInfo()}

                {result.errorMessage}

                {this.drawDiffAndIgnoreFields(test, this.cleanFoundDifferences(result.foundDifferences), fieldsToIgnore)}
                {this.drawDiff(this.testResultContentString(result.fullResult, test.type), this.testResultContentString(result.expected, test.type))}
                <Button disabled={result.success}
                        onClick={() => this.setState({showConfigCompare: true})}>compare config with last
                    successful</Button>

            </Accordion.Content>
        </>
    }

    showCombinedIgnoredFields() {

        let results = this.props.testResults
        let differences = results[0]?.foundDifferences

        if (differences == null) {
            differences = []
        }

        results.forEach(result => {
            result.foundDifferences?.forEach(field => {
                if (!differences.includes(field)) {
                    differences = differences.filter(f => f != field)
                }
            })
        })

        return this.drawDiffAndIgnoreFields(null, this.state.combinedFoundDifferences, this.state.combinedIgnoredFields)
    }

    updateExpectedForAllFailed() {
        let tests = this.state.updatedTests
        let newTests = []

        tests.forEach(test => {
            let result = this.props.testResults.find(t => t.transportOrderConfigTestId == test._id)

            switch (test.type) {
                case TransportOrderConfigTest.TypeEnum.IMPORT:
                    test.expectedResult.importData = JSON.parse(result.fullResult)
                    break;
                case TransportOrderConfigTest.TypeEnum.EXPORT:
                    test.expectedResult.exportDataList = JSON.parse(result.fullResult)
                    break;

            }
            newTests.push(test)
        })

        this.setState({updatedTests: newTests})
        this.saveUpdatedTests(newTests)

    }

    async saveUpdatedTests(updatedTests) {
        console.log("saving changes")
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let updates: TransportOrderConfigTestUpdate[] = []

        updatedTests.forEach(test => {
            let update = {} as TransportOrderConfigTestUpdate
            update.testId = test._id
            update.testName = test.name
            update.input = test.input
            update.expectedResult = test.expectedResult

            updates.push(update)
        })
        let request = {} as UpdateTransportOrderConfigTestsRequest
        request.updates = updates
        let response = await backend.internalApi.updateTransportOrderConfigTests(request, auth)
        if (response.failedUpdateTestIds.length > 0) {
            // TODO: implement error handling
            console.log("Error saving changes")
        }

        this.handleClose(true)
    }

    render() {
        this.checkForProps(1)
        let title = "TestResult for Tests"
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="inverted"
            size='fullscreen'>
            <Header icon='browser' content={title}/>
            <Modal.Content>

                {this.showCombinedIgnoredFields()}
                <Accordion fluid styled>
                    {this.props.tests.map(test => {

                        let result = this.props.testResults.find(t => t.transportOrderConfigTestId == test._id)
                        return this.drawSingleTestResult(test, result)
                    })}
                </Accordion>
                <Button onClick={(evt) => this.handleClose(false)}>Close</Button>
                <Button disabled={this.state.updatedTests.length == 0}
                        onClick={(evt) => this.updateExpectedForAllFailed()}>update expected for all failed</Button>
            </Modal.Content>
        </Modal>
    }

    onCloseCompareModal() {
        this.setState({showConfigCompare: false})
    }

    drawDiffAndIgnoreFields(test, testResultFoundDifferences, fieldsToIgnore) {

        let differences = testResultFoundDifferences
        if (test == null) {
            differences = this.state.combinedFoundDifferences
        }
        return <Grid padded="horizontally" stackable columns='equal' centered>
            <Grid.Column>
                <ul>
                    {differences.map((value, index) => {
                        if (value != "" && value != null) {
                            return <li key={index}>{value}
                                <IfBox shouldShow={!fieldsToIgnore?.includes(value)}>
                                    <Button icon onClick={(evt) => {

                                        this.addFieldToIgnore(test, value)
                                    }}>
                                        <Icon name='arrow right'/>
                                    </Button>
                                </IfBox>
                            </li>
                        }
                    })}
                </ul>
            </Grid.Column>
            <Grid.Column>
                {this.drawFieldsToIgnore(test, fieldsToIgnore)}
            </Grid.Column>

        </Grid>
    }

    drawFieldsToIgnore(test, fieldsToIgnore) {
        let index = -1
        return <p>
            <h3>Fields to ignore: </h3>
            {
                fieldsToIgnore?.map(f => {
                    index++
                    return this.drawSingleFieldToIgnore(test, f, index)

                })
            }

            <Button icon onClick={() => this.handleChangeOnFieldToIgnore(test, "", index + 1)}><Icon
                name='plus'/></Button>
        </p>
    }

    handleChangeOnFieldToIgnore(test, newValue, index) {

        let tests = this.state.updatedTests
        let newTests = []

        if (test == null) {

            tests.forEach(t => {
                let test = t
                if (newValue == null) {
                    test.expectedResult.fieldsToIgnore.filter(d => d != newValue)
                } else {
                    test.expectedResult.fieldsToIgnore[index] = newValue
                }


                newTests.push(test)
            })
            let combinedFoundDifferences = this.state.combinedFoundDifferences
            if (!combinedFoundDifferences.includes(newValue)) {
                combinedFoundDifferences.push(newValue)
            }

            this.setState({combinedFoundDifferences: combinedFoundDifferences})


        } else {
            let foundTest = tests.find(t => t._id == test._id)
            foundTest.expectedResult.fieldsToIgnore[index] = newValue
            newTests = tests.filter(t => t._id != test._id)
            newTests.push(foundTest)


        }


        this.setState({updatedTests: newTests})
    }

    drawSingleFieldToIgnore(test, field, index) {
        return <p>
            <Input value={field} onChange={evt => this.handleChangeOnFieldToIgnore(test, evt.target.value, index)}/>
            <Button icon onClick={() => this.deleteFieldToIgnore(test, index)}><Icon name='trash'/></Button>
        </p>
    }

    deleteFieldToIgnore(test, index) {

        if (test == null) {

            let combined = this.state.combinedIgnoredFields
            let fieldToRemove = combined[index]

            let combinedDifferences = this.state.combinedFoundDifferences
            combinedDifferences.push(fieldToRemove)

            this.state.updatedTests.forEach(t => {
                t.expectedResult.fieldsToIgnore = t.expectedResult.fieldsToIgnore.filter(f => f != fieldToRemove)
            })
            this.setState({
                combinedIgnoredFields: combined.filter(f => f != fieldToRemove),
                combinedFoundDifferences: combinedDifferences
            })
        } else {
            let tests = this.state.updatedTests.filter(t => t._id != test._id)
            test.expectedResult.fieldsToIgnore.splice(index, 1)
            tests.push(test)
            this.setState({updatedTests: tests})
        }
    }

    addFieldToIgnore(test, newPath) {
        if (test == null) {
            let newCombinedDifferences = this.state.combinedFoundDifferences.filter(d => d != newPath)
            this.setState({
                combinedFoundDifferences: newCombinedDifferences
            })
            this.state.updatedTests.forEach(t => {
                this.handleChangeOnFieldToIgnore(t, newPath, t.expectedResult.fieldsToIgnore.length)
            })
        } else {

            this.handleChangeOnFieldToIgnore(test, newPath, test.expectedResult.fieldsToIgnore.length)

        }
    }

    drawSuccessIcon(testResultSuccess) {
        if (testResultSuccess) {
            return <span><Icon name='check' color='green' size='large'/></span>
        } else {
            return <span><Icon name='x' color='red' size='large'/></span>
        }
    }

    drawDiff(testResultActual, expected) {
        if (testResultActual) {
            return <CustomDiff
                oldJson={expected}
                newJson={testResultActual}
            />
        } else {
            return <p>null</p>
        }
    }

}