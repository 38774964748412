import * as React from "react";
import { Modal, Header, Button, List, Input, Popup, Grid } from "semantic-ui-react";
import { ConfigContext } from "../../../context/ConfigContext";
import { Company } from "../../../../../generated";
import { backend } from "../../../../../xconvert-backend";
import { authentication } from "../../../../../authentication";
import {updateConfig, workingConfig} from "../ConfigSignal";

export interface SwitchToPartnerCompanyModalProps {
    isOpen: boolean
    onClose: () => void
    company: Company
}

export class SwitchToPartnerCompanyModal extends React.Component<SwitchToPartnerCompanyModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            comment: "",
            response: null,
            hunks: null,
            type: null,
            draftName: "",
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    componentDidMount() {

    }

    async handleSwitch() {
        let c = this.props.company

        console.log("Switching to company " + c.name)
        this.setState({ loading: true })
        var authHeader = backend.withTokenAuthHeader(authentication.token)
        var cu = await backend.accountApi.fetchCurrentUser(authHeader)
        cu.companyId = c._id

        //switching company in CM
        authentication.user.companyId = c._id
        authentication.company = c
        authentication.saveLocalStorage()

        //switching company on backend/DB
        await backend.accountApi.updateAccountData(cu, authHeader)

        updateConfig(null, false, false)
        this.context.setTelMapCustomerId("")

        this.context.setLatestDraft(workingConfig.value)

        console.log("Done switching company")
        this.props.onClose()
        window.location.reload()

    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='large'>
            <Header icon='browser' content={'Switch to : ' + this.props.company.name} />
            <Modal.Content>
                Are you sure, you want to switch to {this.props.company.name}?

                <Button id='SwitchToPartnerCompanyModalSwitchButton' onClick={() => this.handleSwitch()}>Switch</Button>

                <Button id='SwitchToPartnerCompanyModalCancelButton' onClick={() => this.handleClose()}>Cancel</Button>

            </Modal.Content>
        </Modal>

    }

}

