import * as React from "react";
import {Button, Header, Icon, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import ReactTable from "react-table";
import {configStorage} from "../../../../../ConfigStorage";


export interface EdiDocumentDefinitionBuilderModalProps {
    isOpen: boolean
    onClose: () => void
    treeJson: string
}

export interface EdiDocumentDefinitionBuilderModalState {
    isSubmitting: boolean
    nodes: EdiDocumentDefinitionRow[]

    isLoadingLeft: boolean
    isLoadingRight: boolean
}

export class EdiDocumentDefinitionRow {
    index: number
    root: string
    sg_transport_order: string
    sg_transport: string
    sg_freight: string
}

export class EdiDocumentDefinitionNode {
    entries: []
    maxOccures: number
    name: string
    required: boolean
}

export class EdiDocumentDefinitionBuilderModal extends React.Component<EdiDocumentDefinitionBuilderModalProps, EdiDocumentDefinitionBuilderModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            nodes: [],
            isLoadingLeft: false,
            isLoadingRight: false
        }
    }

    componentWillReceiveProps(nextProps: Readonly<EdiDocumentDefinitionBuilderModalProps>, nextContext: any) {
        this.buildNodes(nextProps.treeJson)
    }

    componentDidMount() {
        this.buildNodes(this.props.treeJson)
    }

    buildNodes(treeJson: string) {
        let tree = JSON.parse(treeJson)
        let nodes = []
        tree.map(rootNode => {

            if (rootNode.name == "SG_TRANSPORT_ORDER") {
                rootNode.segmentType.childs.map(transportOrderNode => {

                    if (transportOrderNode.first == "SG_TRANSPORT") {
                        transportOrderNode.second.childs.map(transportNode => {

                            if (transportNode.first == "SG_FREIGHT") {
                                transportNode.second.childs.map(freightNode => {

                                    let newNode = {} as EdiDocumentDefinitionRow
                                    newNode.index = nodes.length
                                    newNode.sg_freight = freightNode.first
                                    nodes.push(newNode)
                                })
                            } else {

                                let newNode = {} as EdiDocumentDefinitionRow
                                newNode.index = nodes.length
                                newNode.sg_transport = transportNode.first
                                nodes.push(newNode)
                            }
                        })
                    } else {

                        let newNode = {} as EdiDocumentDefinitionRow
                        newNode.index = nodes.length
                        newNode.sg_transport_order = transportOrderNode.first
                        nodes.push(newNode)
                    }
                })
            } else {

                let newNode = {} as EdiDocumentDefinitionRow
                newNode.index = nodes.length
                newNode.root = rootNode.name
                nodes.push(newNode)
            }

        })
        this.setState({nodes: nodes})
    }

    handleClose = () => {
        this.props.onClose()
    }

    async save() {
        this.setState({isSubmitting: true})

       // let str = JSON.stringify(this.rowsToConfigNodes(this.state.nodes), null, 4)
        let newDefinition = this.rowsToConfigNodes(this.state.nodes)

        let config = (await configStorage.getElement(this.context.companyId)).config
        let jp = require('jsonpath')
        jp.value(config, "$.ediConverterConfiguration.documentDefinition.nodes", newDefinition)

        configStorage.updateConfig(this.context.companyId, config)
        this.setState({isSubmitting: false})
        this.handleClose()

    }

    rowsToConfigNodes(rows: EdiDocumentDefinitionRow[]) {

        let nodes = []
        let root = rows.filter(row => row.root != null)
        let transportOrder = rows.filter(row => row.sg_transport_order != null)
        let transport = rows.filter(row => row.sg_transport != null)
        let freight = rows.filter(row => row.sg_freight != null)


        let amount = rows.length

        let currentLayer = "ROOT"

        for (let i = 0; i < amount; i++) {
            let rootNode = root.find(n => n.index == i)
            if(rootNode != null) {

                //
                // ROOT
                //
                currentLayer = "ROOT"
                let newNode = {} as EdiDocumentDefinitionNode
                newNode.name = rootNode.root
                nodes.push(newNode)
            } else {

                //
                // SG_TRANSPORT_ORDER
                //

                let transportOrderNode = transportOrder.find(n => n.index == i)
                if(transportOrderNode != null) {

                    // check if "SG_TRANSPORT_ORDER" already exists
                    if(currentLayer == "ROOT") {
                        let newNode = {} as EdiDocumentDefinitionNode
                        newNode.name = "SG_TRANSPORT_ORDER"
                        newNode.entries = []
                        nodes.push(newNode)
                        currentLayer = "SG_TRANSPORT_ORDER"
                    }
                    // add this node as entry
                    let newNode = {} as EdiDocumentDefinitionNode
                    newNode.name = transportOrderNode.sg_transport_order
                    let transportOrderGroups = nodes.filter(n =>  n.name == "SG_TRANSPORT_ORDER")
                    transportOrderGroups[transportOrderGroups.length-1].entries.push(newNode)
                    currentLayer = "SG_TRANSPORT_ORDER"
                } else {

                    //
                    // SG_TRANSPORT
                    //

                    if(currentLayer == "ROOT") {
                        let newNode = {} as EdiDocumentDefinitionNode
                        newNode.name = "SG_TRANSPORT_ORDER"
                        newNode.entries = []
                        nodes.push(newNode)
                        currentLayer = "SG_TRANSPORT_ORDER"

                    }
                    let toNodes = nodes.filter(n =>  n.name == "SG_TRANSPORT_ORDER")
                    let toNodeEntries = toNodes[toNodes.length-1].entries
                    if(currentLayer == "SG_TRANSPORT_ORDER") {
                        let newNode = {} as EdiDocumentDefinitionNode
                        newNode.name = "SG_TRANSPORT"
                        newNode.entries = []
                        toNodeEntries.push(newNode)
                        currentLayer = "SG_TRANSPORT"

                    }

                    let trNodes = toNodeEntries.filter(n =>  n.name == "SG_TRANSPORT")
                    let trNodeEntries = trNodes[trNodes.length-1].entries

                    let transportNode = transport.find(n => n.index == i)
                    if(transportNode != null) {
                        let newNode = {} as EdiDocumentDefinitionNode
                        newNode.name = transportNode.sg_transport
                        trNodeEntries.push(newNode)
                        currentLayer = "SG_TRANSPORT"

                    } else {

                        //
                        // SG_FREIGHT
                        //
                     //  if(currentLayer == "ROOT") {
                     //      let newNode = {} as EdiDocumentDefinitionNode
                     //      newNode.name = "SG_TRANSPORT_ORDER"
                     //      newNode.entries = []
                     //      nodes.push(newNode)
                     //      currentLayer = "SG_TRANSPORT_ORDER"
                     //  }
                     //  if(currentLayer == "SG_TRANSPORT_ORDER") {
                     //      let newNode = {} as EdiDocumentDefinitionNode
                     //      newNode.name = "SG_TRANSPORT"
                     //      newNode.entries = []
                     //      let toNodes = nodes.filter(n =>  n.name == "SG_TRANSPORT_ORDER")
                     //      toNodes[toNodes.length-1].entries.push(newNode)
                     //      currentLayer = "SG_TRANSPORT"
                     //  }
                     //  let toNodes = nodes.filter(n =>  n.name == "SG_TRANSPORT_ORDER")
                     //  let trNodes = toNodes[toNodes.length-1].entries.filter(n =>  n.name == "SG_TRANSPORT")
                     //  let transportNodeEntries = trNodes[trNodes.length-1].entries
                        let freightNode = freight.find(n => n.index == i)
                        if(freightNode != null) {

                            // check if "SG_FREIGHT" already exists
                            if(currentLayer == "SG_TRANSPORT") {
                                let newNode = {} as EdiDocumentDefinitionNode
                                newNode.name = "SG_FREIGHT"
                                newNode.entries = []
                                trNodeEntries.push(newNode)
                                currentLayer = "SG_FREIGHT"
                            }
                            // add this node as entry
                            let newNode = {} as EdiDocumentDefinitionNode
                            newNode.name = freightNode.sg_freight
                            let freightNodes = trNodeEntries.filter(n =>  n.name == "SG_FREIGHT")
                            freightNodes[freightNodes.length-1].entries.push(newNode)
                            currentLayer = "SG_FREIGHT"

                        }
                    }
                }
            }
        }


        return nodes



    }


    shiftLeft(index: number) {


        let nodes = this.state.nodes
        let node = nodes[index]

        if (node.root != null) {
            // error
            console.log("cannot left-shift root node")
            return
        } else if (node.sg_transport_order != null) {

            node.root = node.sg_transport_order
            node.sg_transport_order = null

        } else if (node.sg_transport != null) {

            node.sg_transport_order = node.sg_transport
            node.sg_transport = null

        } else if (node.sg_freight != null) {

            node.sg_transport = node.sg_freight
            node.sg_freight = null

        } else {
            // error
            console.log("node has no position. It seems to be broken.")
            return
        }

        nodes[index] = node
        this.setState({nodes: nodes})


    }

    shiftRight(index: number) {


        let nodes = this.state.nodes
        let node = nodes[index]

        if (node.root != null) {

            node.sg_transport_order = node.root
            node.root = null

        } else if (node.sg_transport_order != null) {

            node.sg_transport = node.sg_transport_order
            node.sg_transport_order = null

        } else if (node.sg_transport != null) {

            node.sg_freight = node.sg_transport
            node.sg_transport = null

        } else if (node.sg_freight != null) {

            // error
            console.log("cannot right-shift sg_freight node")
            return

        } else {
            // error
            console.log("node has no position. It seems to be broken.")
            return
        }

        nodes[index] = node
        this.setState({nodes: nodes})

    }

    render() {
        const columns = [
            {
                id: 'root',
                Header: 'ROOT',
                width: 100,
                accessor: (d: EdiDocumentDefinitionRow) => d.root,
                sortField: 'NAME'
            },
            {
                id: 'sg_transport_order',
                Header: 'SG_TRANSPORT_ORDER',
                width: 100,
                accessor: (d: EdiDocumentDefinitionRow) => d.sg_transport_order,
                sortField: 'NAME'
            },
            {
                id: 'sg_transport',
                Header: 'SG_TRANSPORT',
                width: 100,
                accessor: (d: EdiDocumentDefinitionRow) => d.sg_transport,
                sortField: 'NAME'
            },
            {
                id: 'sg_freight',
                Header: 'SG_FREIGHT',
                width: 100,
                accessor: (d: EdiDocumentDefinitionRow) => d.sg_freight,
                sortField: 'NAME'
            },
            {
                id: 'shift',
                Header: 'SHIFT',
                width: 100,
                accessor: (d: EdiDocumentDefinitionRow) => {
                    return <p>
                        <Button size='tiny' icon disabled={d.root != null} onClick={() => this.shiftLeft(d.index)}
                                loading={this.state.isLoadingLeft}><Icon size='small' name='arrow left'/></Button>
                        <Button size='tiny' icon disabled={d.sg_freight != null}
                                onClick={() => this.shiftRight(d.index)}
                                loading={this.state.isLoadingRight}><Icon size='small' name='arrow right'/></Button>

                    </p>
                },
                sortField: 'NAME'
            },
        ]

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Edi Document Definition'/>
            <Modal.Content>

                <ReactTable
                    data={this.state.nodes}
                    columns={columns}
                    className="-striped -highlight"
                    style={{cursor: "pointer"}}
                />

                <Button id='ChangeConfigModalSaveButton' primary loading={this.state.isSubmitting} onClick={() => {
                    this.save();
                }}>Save</Button>
                <Button id='ChangeConfigModalCancelButton' onClick={() => this.handleClose()}>Close</Button>

            </Modal.Content>
        </Modal>

    }


}

