import * as React from "react";
import {List} from 'semantic-ui-react';
import {authentication} from "../../../authentication";
import {Company} from "../../../generated/api";
import {backend} from "../../../xconvert-backend";
import {ListSelectModal} from "../list_select_modal";
import {ConfigContext} from "../../main/context/ConfigContext";
import {LeaveWithChangesModal} from "../../main/pages/configChange/configEdit/modals/LeaveWithChangesModal";
import {workingConfig} from "../../main/pages/configChange/ConfigSignal";

export interface SelectCompanyModalProps {
    onClose: () => (void)
    onSelect: (c: Company) => (Promise<void>)
    open: boolean
    forCompanyChange: boolean
}

export class SelectCompanyModal extends React.Component<SelectCompanyModalProps, any> {
    constructor(props) {
        super(props)
        this.state = {
            isSelecting: false,
            showConfigChangedModal: false,
            selectedCompany: null,
            currentCompanyConfig: null,
            failedTests: []
        }
    }

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    renderItem = (c: Company) => {
        var adr = <List.Description>keine Addresse</List.Description>

        if (c.address != null) {
            adr = <List.Description>
                {c.address && c.address.street || ""} {c.address && c.address.houseNumber || ""}<br />
                {c.address && c.address.country || ""} - {c.address && c.address.city || ""}
            </List.Description>
        }

        return <List.Content>
            <List.Header>
                {c.name}
            </List.Header>
            {adr}
        </List.Content>
    }

    onSearch = async (q: string, includeArchived: boolean = false) => {
        var authHeader = backend.withTokenAuthHeader(authentication.token)
        var res = await backend.companyApi.queryCompanies(q, includeArchived, authHeader)
        return res.results
    }

    onClose = () => {
        this.props.onClose()
    }

    onSelect = async (c: Company) => {
        this.setState({ isSelecting: true })
        await this.props.onSelect(c)
        this.setState({ isSelecting: false })
    }

    checkIfConfigChanged = async (c: Company) => {
        console.log("checking if Config has changed")

        if (workingConfig.value == null) {
            // no changes possible!
            this.onSelect(c)
            return
        }

        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let currentCompanyConfig = (await backend.internalApi.fetchCompanyConfiguration(this.context.companyId, auth))



        // check if there are unsaved changes on the config
        if (workingConfig.value != JSON.stringify(currentCompanyConfig, null, 4)) {
            console.log("Config has changed")

            this.setState({
                showConfigChangedModal: this.props.forCompanyChange,
                selectedCompany: c,
                currentCompanyConfig: currentCompanyConfig
            })
        } else {
            this.onSelect(c)
        }
    }

    onDecision(decision: LeaveWithChangeDecision) {

        switch (decision) {
            case LeaveWithChangeDecision.SAVE:
                this.onSelect(this.state.selectedCompany)
                break;
            case LeaveWithChangeDecision.SAVE_AS_DRAFT:
                this.onSelect(this.state.selectedCompany)
                break;
            case LeaveWithChangeDecision.KEEP_IN_STORAGE:
                this.onSelect(this.state.selectedCompany)
                break;
            case LeaveWithChangeDecision.DISCARD:
                this.onSelect(this.state.selectedCompany)
                break;
            case LeaveWithChangeDecision.CANCEL:
                break;
            default:

        }
    }

    render() {
        return <div>
            <ListSelectModal<Company>
                open={this.props.open}
                renderItem={this.renderItem}
                onSearch={this.onSearch}
                onSelect={this.checkIfConfigChanged}
                onClose={this.onClose}
                isSelectingItem={this.state.isSelecting}
            />
            <LeaveWithChangesModal
                isOpen={this.state.showConfigChangedModal}
                onClose={() => { this.setState({ showConfigChangedModal: false }) }}
                dispatcher={(decision: LeaveWithChangeDecision) => { this.onDecision(decision) }}
                currentCompanyConfig={this.state.currentCompanyConfig}
            />

        </div>
    }
}

export enum LeaveWithChangeDecision {
    SAVE = "Save",
    SAVE_AS_DRAFT = "SaveAsDraft",
    CANCEL = "Cancel",
    KEEP_IN_STORAGE = "KeepInStorage",
    DISCARD = "Discard"
}
