import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import AceEditor from 'react-ace';


export interface EdiDocViewerJsonOutputProps {
    output: string
}

export interface EdiDocViewerJsonOutputState {
    output: string
}


export class EdiDocViewerOutput extends React.Component<EdiDocViewerJsonOutputProps, EdiDocViewerJsonOutputState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)

        this.state = {
            output: ""
        }
    }

    componentWillReceiveProps(nextProps: Readonly<EdiDocViewerJsonOutputProps>, nextContext: any) {
        this.setState({output: nextProps.output})
    }

    render() {
        return <AceEditor
            theme="monokai"
            mode='json'
            value={this.state.output}
            placeholder='Converter response (Dictionary)'
            width="100%"
            height="1100px"
            readOnly={true}
        />
    }

}