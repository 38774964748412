import * as React from "react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {useEffect, useState} from "react";
import {Grid, Icon, Label, Loader, Table} from "semantic-ui-react";
import {formatDate} from "../../../../../format";
import {v4 as uuid} from 'uuid';

export interface VersionReportFunctionProps {
    lightView: boolean,
    displayDetails: (details: string) => void
}

export function VersionReportFunction(props: React.PropsWithChildren<VersionReportFunctionProps>) {

    const [report, setReport] = useState<any[]>([])
    const [expandedRows, setExpandedRows] = useState<any[]>([])
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        callHealthReport()
    }, [])

    async function callHealthReport() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = await fetch('/internalMonitoring/healthReport', {
            method: 'GET',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8"
            }
        })

        if (response.status == 200) {

            setReport(await response.json())
        }

        setLoading(false)

    }

    function drawHealthyIcon(healthy) {
        if (healthy) {
            return <Icon name={'heartbeat'} color={'green'}/>
        }

        return <Icon name={'ambulance'} color={'red'}/>
    }

    // ################## FULL VIEW

    function drawFullView() {

        let newAllItemRows = []
        report?.forEach((item, index) => {
            const perItemRows = renderItem(item, index);
            console.log("[VersionReport] " + item.serviceName + " - perItemRows.length -> ", perItemRows.length)
            perItemRows.forEach((row) => {
                newAllItemRows.push(row)
            })
        });
        //setAllItemRows(newAllItemRows)
        console.log("[VersionReport] - allItemRows -> ", newAllItemRows.length)

        return <>
            <Table selectable striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>Healthy</Table.HeaderCell>
                        <Table.HeaderCell>Service Name</Table.HeaderCell>
                        <Table.HeaderCell>Version</Table.HeaderCell>
                        <Table.HeaderCell>Nodes</Table.HeaderCell>
                        <Table.HeaderCell>Last Call</Table.HeaderCell>
                        <Table.HeaderCell>pipelineCreationTime</Table.HeaderCell>
                        <Table.HeaderCell>commitTimestamp</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>{newAllItemRows}</Table.Body>
            </Table>
        </>
    }

    function renderItem(service, index) {

        const clickCallback = () => handleRowClick(index, service);
        const itemRows = [
            <Table.Row onClick={clickCallback} key={"row-data-" + index}>
                <Table.Cell>{renderItemCaret(index)}</Table.Cell>
                <Table.Cell>{drawHealthyIcon(service.allHealthy)}</Table.Cell>
                <Table.Cell>{service.serviceName}</Table.Cell>
                <Table.Cell>{service.version}</Table.Cell>
                <Table.Cell>{service.serviceNodes.length}</Table.Cell>
                <Table.Cell>{formatDate(service.lastCalled)}</Table.Cell>
                <Table.Cell>{formatDate(service.pipelineCreationTime)}</Table.Cell>
                <Table.Cell>{formatDate(service.commitTimestamp)}</Table.Cell>
            </Table.Row>
        ];

        console.log("[VersionReport] expandedRows -> ", expandedRows)
        if (expandedRows.includes(index)) {
            service.serviceNodes.map(node => {
                itemRows.push(
                    <Table.Row key={"row-expanded-" + index + "_" + node.address + "_" + uuid()}>
                        <Table.Cell>NODE:</Table.Cell>
                        <Table.Cell>{drawHealthyIcon(node.healthy)}</Table.Cell>
                        <Table.Cell>{node.address}</Table.Cell>
                        <Table.Cell>{node.version}</Table.Cell>
                        <Table.Cell/>
                        <Table.Cell/>
                        <Table.Cell>{formatDate(node.pipelineCreationTime)}</Table.Cell>
                        <Table.Cell>{formatDate(node.commitTimestamp)}</Table.Cell>
                        {node.errorResponse && <Table.Cell>{node.errorResponse}</Table.Cell>}
                    </Table.Row>
                )
            })
        }
        console.log("[VersionReport] " + service.serviceName + " - itemRows.length -> ", itemRows.length)

        return itemRows;
    }

    function handleRowClick(rowId, service) {

        setLoading(true)
        const isRowCurrentlyExpanded = expandedRows.includes(rowId);

        const newExpandedRows = isRowCurrentlyExpanded
            ? expandedRows.filter(id => id !== rowId)
            : expandedRows.concat(rowId);

        setExpandedRows(newExpandedRows);
        props.displayDetails(JSON.stringify(service, null, 4))
        setLoading(false)
    }

    function renderItemCaret(rowId) {

        const isRowCurrentlyExpanded = expandedRows.includes(rowId);

        if (isRowCurrentlyExpanded) {
            return <Icon name="caret down"/>;
        } else {
            return <Icon name="caret right"/>;
        }
    }

    // ################## LIGHT VIEW


    function drawLightView() {
        return <Grid style={{width: "800px"}}>
            {report?.map(service => {
                return <Grid.Row>

                    <Grid.Column width={2}>
                        {drawHealthyIcon(service.allHealthy)}
                    </Grid.Column>
                    <Grid.Column width={6}>
                        {service.serviceName}
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Label>{service.version}</Label>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Label>{formatDate(service.commitTimestamp)}</Label>
                    </Grid.Column>
                </Grid.Row>

            })
            }
        </Grid>
    }

    // ################## RENDER

    if (isLoading) {
        return <Loader active inline='centered'>Loading</Loader>
    }

    if (props.lightView) {
        return <p style={{width: "800px"}}>
            {drawLightView()}
        </p>
    }

    return <>{drawFullView()}</>


}