import * as React from "react";
import {Column} from "react-table";
import {Button, Grid, GridColumn, GridRow, Icon, Popup} from 'semantic-ui-react';

export interface FilterProps {
    onConfigChange: (cols: Column[], reset?: boolean) => void,
    cols: Column[],
    name: string
}

export class CustomTableSettingsComponent extends React.Component<FilterProps, any> {

    constructor(props: FilterProps) {
        super(props)
        this.state = {
            hoveredRow: -1
        }
    }

    render() {
        return <div>
            <Grid>
                {this.renderOptions()}
                <GridRow>
                    <GridColumn>
                        <div className={'text-align-right full-width'}>
                            <Button primary={!this.filterEmpty()} onClick={() => {
                                this.resetCols()
                            }}>
                                <p>Rollback</p>
                            </Button>
                        </div>
                    </GridColumn>
                </GridRow>
            </Grid>
        </div>
    }

    componentDidUpdate(prevProps: FilterProps) {
        if (prevProps.cols !== this.props.cols) {
            this.setState({columns: this.props.cols})

        }
    }

    private renderOptions() {
        let options: JSX.Element[] = [];
        if (this.props.cols && this.props.cols.length > 0) {
            this.props.cols.forEach((col, index) => {
                if (index != this.props.cols.length - 1) {
                    options.push(
                        <GridRow key={this.props.name + 'chkbox' + index}
                                 style={{
                                     background: (this.state.hoveredRow === index) ? 'rgba(0,0,0,0.05)' : 'none',
                                     paddingTop: '0',
                                     paddingBottom: '0'
                                 }}
                                 onMouseEnter={() => {
                                     this.toggleHover(index, true)
                                 }}
                                 onMouseLeave={() => {
                                     this.toggleHover(index, false)
                                 }}
                        >
                            <Grid.Column width={8}>
                                <Popup content={this.checkVisibility(col.id) ? 'Hide column' : 'Show column'
                                }
                                       trigger={
                                           <Icon link
                                                 name={this.checkVisibility(col.id) ? 'eye' : 'eye slash'}
                                                 style={{
                                                     paddingRight: '10px',
                                                     color: this.checkVisibility(col.id) ? '#0dc3c3' : '#791010'
                                                 }}
                                                 onClick={() => {
                                                         if (col.id) {
                                                             this.toggleCol(col.id)
                                                         }
                                                     }}
                                           />
                                       }
                                />
                                <label style={{
                                    fontWeight: "bold",
                                    fontSize: 'medium'
                                }}> {col.Header ? col.Header : ''}</label>

                            </Grid.Column>
                            <Grid.Column width={8}>
                                {
                                    (this.state.hoveredRow === index && index !== 0) ?
                                        <Icon link name='arrow up'
                                              style={{paddingRight: '10px', paddingLeft: '10px'}}
                                              onClick={() => {
                                                  this.moveColumn(index, index - 1)
                                              }}
                                        />
                                        : null
                                }
                                {
                                    (this.state.hoveredRow === index && index !== this.props.cols.length - 2) ?
                                        <Icon link name='arrow down'
                                              style={{paddingLeft: '10px'}}
                                              onClick={() => {
                                                  this.moveColumn(index, index + 1)
                                              }}/>
                                        : null

                                }
                            </Grid.Column>
                        </GridRow>
                    )
                }
            })
        }
        return options;
    }

    toggleHover(rowIndex: number, entered: boolean = false) {
        console.log("Hover")
        if (!entered) {
            this.setState({hoveredRow: -1})
            return
        }
        this.setState({hoveredRow: rowIndex})
    }

    filterEmpty() {
        let flag = true;
        this.props.cols.forEach((col) => {
            if (!col.show) {
                flag = false;
            }
        })
        return flag;
    }

    resetCols() {
        this.props.onConfigChange([], true)
    }

    toggleCol(colID: string) {
        let columns = [...this.props.cols];
        columns.forEach((col) => {
            if (col.id === colID) {
                col.show = (col.show == null) ? false : (!col.show);
            }
        })
        this.props.onConfigChange(columns)
    }

    private checkVisibility(colId: any) {
        let show = false;
        this.props.cols.forEach((col) => {
            if (col.id === colId) {
                show = col.show !== undefined ? col.show : true;
            }
        })
        return show
    }

    private moveColumn(index: number, newIndex: number) {
        let columns = [...this.props.cols];
        const temp = columns[newIndex];
        columns[newIndex] = columns[index];
        columns[index] = temp;
        this.props.onConfigChange(columns)
    }
}
