import * as React from "react";
import {Button, Divider, Grid, Segment} from "semantic-ui-react";
import {SystemStatusMessage} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {notify} from 'react-notify-toast';
import AceEditor from 'react-ace';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'

export interface SystemStatusMessageModalProps {
    isOpen: boolean
    onClose: () => void
    message: SystemStatusMessage
    onlyOwnCompany: boolean
}

export interface SystemStatusMessageModalState {
    message: string
    readOnly: boolean
}

export class SystemStatusMessageRaw extends React.Component<SystemStatusMessageModalProps, SystemStatusMessageModalState> {

    constructor(props) {
        super(props)
        this.state = {
            message: "",
            readOnly: false,
        }
    }

    handleClose = () => {

        this.props.onClose()
    }

    async handleUpdate() {

        let request = JSON.parse(this.state.message)

        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = await backend.internalApi.replaceSystemStatusMessage(this.props.message._id, request, auth)

        console.log(response)

        if (response.success) {
            notify.show('message successfully updated', 'success', 3000, '#28f751');

        } else {
            notify.show("ERROR: " + response.error, 'error', 5000, '#fc0303')

        }
        this.props.onClose()
    }

    async componentDidMount() {
        this.setState({
            message: JSON.stringify(this.props.message, null, 4),
            readOnly: this.props.onlyOwnCompany && this.props.message.companyIds == null,
        })
    }

    render() {
        return <Grid padded="horizontally" stackable columns='equal' centered>

            <Grid.Row>
                <AceEditor
                    theme="monokai"
                    mode='groovy'
                    value={this.state.message}
                    width="100%"
                    onChange={(value) => this.setState({message: value})}
                    readOnly={this.state.readOnly}
                />
            </Grid.Row>
            <Grid.Row>
                <Segment>
                    <Button id='SystemStatusMessageModalUpdateButton' disabled={this.state.readOnly}
                            onClick={() => this.handleUpdate()}>Update</Button>
                    <Divider hidden/>
                    <Button id='SystemStatusMessageModalCloseButton'
                            onClick={() => this.handleClose()}>Close</Button>
                </Segment>
            </Grid.Row>
        </Grid>

    }

}

