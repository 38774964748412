import * as React from "react";
import { authentication } from "../../../authentication";
import { Company } from "../../../generated/api";
import { backend } from "../../../xconvert-backend";
import { SelectCompanyModal } from "./select_company_modal";
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ConfigContext } from "../../main/context/ConfigContext";
import {workingConfig} from "../../main/pages/configChange/ConfigSignal";

export interface ChangeCompanyModalProps {
    onClose: () => (void)
    open: boolean
}

class ChangeCompanyModalR extends React.Component<ChangeCompanyModalProps & RouteComponentProps<any>, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
    }

    onSelect = async (c: Company) => {
        console.log("Switching to company " + c.name)
        this.setState({ loading: true })

        var authHeader = backend.withTokenAuthHeader(authentication.token)
        var cu = await backend.accountApi.fetchCurrentUser(authHeader)
        cu.companyId = c._id

        //switching company in CM
        authentication.user.companyId = c._id
        authentication.company = c
        authentication.saveLocalStorage()

        //switching company on backend/DB
        await backend.accountApi.updateAccountData(cu, authHeader)

        //updateConfig(configStorage.getElement(c._id).config)
        this.context.setTelMapCustomerId("")

        this.context.setLatestDraft(workingConfig.value)

        console.log("Done switching company")
        this.props.onClose()

        this.props.history.push('/'+c._id+'/config')
        window.location.reload()

    }

    render() {
        return <SelectCompanyModal onSelect={this.onSelect} onClose={this.props.onClose} open={this.props.open} forCompanyChange={true}></SelectCompanyModal>
    }
}

export const ChangeCompanyModal = withRouter(ChangeCompanyModalR)