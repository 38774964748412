import * as React from "react";
import { PageWithSidebar } from "../../../../style/page_with_sidebar";
import { ConfigContext } from "../../../context/ConfigContext";
import { PartnerCard } from "./PartnerCard";
import { ApiGatewayCard } from "./ApiGatewayCard";
import { ConverterCard } from "./ConverterCard";


export class ConfigDiagramPage extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading FtpExplorer Page")

        this.state = {
        }
    }

    render() {

        return <span>
            <ConverterCard />
            <ApiGatewayCard />
            <PartnerCard />
        </span>
    }
}
