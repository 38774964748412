import * as React from "react";
import AceEditor from "react-ace";


export interface SimpleTextBoxProps {
    value: string,
    placeholder: string,
    onChange: (value: string) => void,
    readOnly: boolean,
}

export function SimpleTextBox(props: React.PropsWithChildren<SimpleTextBoxProps>) {

    const [value, setValue] = React.useState(props.value)

    React.useEffect(() => {
        //console.log("[SimpleTextBox] - updating: " + props.value)
        if(props.value == null) {
            setValue("")
            return
        }
        setValue(props.value)
    }, [props.value])

    return <AceEditor
        theme="monokai"
        mode="json"
        style={{flex: 1}}
        value={value}
        placeholder={props.placeholder}
        width="100%"
        onChange={value => {props.onChange(value); setValue(value)}}
        readOnly={props.readOnly}
    />

}