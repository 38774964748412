import * as React from "react";
import {Button, Divider, Grid, Input, Label, Segment} from "semantic-ui-react";
import AceEditor from 'react-ace';
import {Company, SystemStatusMessage} from "../../../../../generated";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {notify} from 'react-notify-toast';
import {SystemStatusMessageCompanyList} from "./SystemStatusMessageCompanyList";
import {IfBox} from "../../../../style/if";

export interface SystemStatusMessageModalProps {
    isOpen: boolean
    onClose: () => void
    message: SystemStatusMessage
    onlyOwnCompany: boolean
}

export interface SystemStatusMessageModalState {
    endTime: Date | null
    message: string
    selectedCompanies: Company[],
    allCompanies: boolean,
    readOnly: boolean
    title: string
}

export class SystemStatusMessageEdit extends React.Component<SystemStatusMessageModalProps, SystemStatusMessageModalState> {

    constructor(props) {
        super(props)
        this.state = {
            endTime: null,
            message: "",
            selectedCompanies: [],
            allCompanies: true,
            readOnly: false,
            title: null
        }
    }

    handleClose = () => {

        this.props.onClose()
    }

    async handleUpdate() {

        let request = this.props.message
        request._id = this.props.message._id
        request.message.title = this.state.title
        request.endTime = this.state.endTime
        request.message.message = this.state.message

        if (this.state.allCompanies) {
            request.companyIds = null
        } else {
            request.companyIds = this.state.selectedCompanies.map((c: Company) => c._id)
        }

        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = await backend.internalApi.replaceSystemStatusMessage(this.props.message._id, request, auth)

        console.log(response)

        if (response.success) {
            notify.show('endTime has been changed to ' + this.state.endTime, 'success', 3000, '#28f751');

        } else {
            notify.show("ERROR: " + response.error, 'error', 5000, '#fc0303')

        }
        this.props.onClose()
    }

    async stopNow() {
        await this.setState({endTime: new Date()})
        await this.handleUpdate()
    }

    async componentDidMount() {

        await this.fetchCompaniesOfSelectedMessage(this.props.message)

        this.setState({
            endTime: this.props.message.endTime,
            message: this.props.message.message.message,
            readOnly: this.props.onlyOwnCompany && this.props.message.companyIds == null,
            title: this.props.message.message.title,
            allCompanies: !(this.props.message.companyIds?.length > 0)
        })
    }

    async fetchCompaniesOfSelectedMessage(message: SystemStatusMessage) {
        let authHeader = backend.withTokenAuthHeader(authentication.token)

        if (message.companyIds) {
            message.companyIds.map(async (q: string) => {
                let c = (await backend.companyApi.queryCompanies(q, false, authHeader)).results[0]
                let selectedCompanies = this.state.selectedCompanies
                selectedCompanies.push(c)
                this.setState({selectedCompanies: selectedCompanies})
            })
        }

    }

    render() {
        return <Grid padded="horizontally" stackable columns='equal' centered>

            <Grid.Column width={6}>
                <Input id='messageTitleInput' placeholder='Message Title' value={this.state.title}
                       onChange={evt => this.setState({title: evt.target.value})}
                       disabled={this.state.readOnly}
                />
                <AceEditor
                    theme="monokai"
                    mode='groovy'
                    value={this.state.message}
                    placeholder='Groovy Script'
                    width="100%"
                    onChange={(value) => this.setState({message: value})}
                    readOnly={this.state.readOnly}
                />
            </Grid.Column>

            <IfBox shouldShow={!this.props.onlyOwnCompany}>
                <Grid.Column>
                    <Label>
                        companies:
                    </Label>
                    <SystemStatusMessageCompanyList
                        selectedCompanies={this.state.selectedCompanies}
                        updateAllCompaniesSwitch={ac => {this.setState({allCompanies: ac})}}
                        updateSelectedCompanies={(c: Company[]) => {
                            this.setState({selectedCompanies: c})
                        }}/>
                </Grid.Column>
            </IfBox>
            <Grid.Column>

                <Label>
                    End Time
                </Label>
                <DatePicker
                    selected={this.state.endTime ? Date.parse(this.state.endTime?.toString()) : null}
                    onChange={time => this.setState({endTime: time})}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={10}
                    timeCaption="time"
                    dateFormat="dd.MM.yy hh:mm aa"
                />

                <Divider hidden/>

                <Segment>
                    <Button id='SystemStatusMessageModalStopNowButton' color='red' disabled={this.state.readOnly}
                            onClick={() => this.stopNow()}>StopMessageNow</Button>
                    <Divider hidden/>
                    <Button id='SystemStatusMessageModalUpdateButton' disabled={this.state.readOnly}
                            onClick={() => this.handleUpdate()}>Update</Button>
                    <Divider hidden/>
                    <Button id='SystemStatusMessageModalCloseButton'
                            onClick={() => this.handleClose()}>Close</Button>
                </Segment>
            </Grid.Column>
        </Grid>

    }

}

