import * as React from "react";
import {Button, Icon, Input, Segment} from "semantic-ui-react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {GetTypesRequest} from "../../../../../../generated";
import {CustomNavTree} from "./CustomNavTree";
import {workingConfig} from "../../ConfigSignal";


export interface NavTreeNavigationProps {
    cursor: string
    data: any
    values: any
    updateCursor: (cursor: any) => void
    updateValues: (values: any) => void
    updateData: (data: any) => void
    refreshTree: () => void
    fixTypeDefinitionIfEnum: (element: any, response: any) => void
}

export function NavTreeNavigation(props: React.PropsWithChildren<NavTreeNavigationProps>) {

    const context = React.useContext(ConfigContext)
    let auth = backend.withTokenAuthHeader(authentication.token)

    let arrowClassName = "css-1abq8k5"

    const [treeSearchQuery, setTreeSearchQuery] = React.useState<string>(context.treeSearchQuery)

    const [data, setData] = React.useState<any>(null)
    const [values, setValues] = React.useState<any>(null)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    React.useEffect(() => {
        console.log("NavTreeNavigation useEffect: props.cursor: ", props.cursor)
        let change = false

        if (props.data != data) {
            setData(props.data)
            change = true
        }
        if (props.values != values) {
            setValues(props.values)
            change = true
        }

        if (change) {
            onToggle(props.cursor, true) // TODO: TEST if this works
        }
    }, [props.cursor, props.data, props.values])

    async function onToggle(node, toggled) {
        let arrowElements = document.getElementsByClassName(arrowClassName)
        let element = Array.from(arrowElements).find((e) => {
            return e.parentNode.textContent == node.name
        })
        console.log("found " + arrowElements.length + " arrow elements")
        console.log("found element: " + element)

        console.log("ON TOGGLE")

        setIsLoading(true)

        const cursor = context.navTreeCursor;
        if (cursor) {
            //setState({ cursor, active: false });
            context.setNavTreeCursor(cursor)
        }
        if (node == null) {
            node = context.navTreeCursor
        }

        if (node.children) {
            node.toggled = toggled;
        }

        let elements = []
        if (values) {
            elements = await Promise.all(values?.filter(value => {
                if (node.name == "CompanyConfiguration") {
                    return value.path == node.path
                }
                return value.path == node.path + "." + node.name
            }))
        }
        let responses = (await getTypes(elements)).responses

        elements.map(async (element) => {
            let response = responses.find((response) => {
                return response.path == element.path + "." + element.name
            })
            element.isDeprecated = response.isDeprecated
            element.deprecatedMessage = response.deprecatedMessage
            element.defaultDescription = response.defaultDescription
            element.isCompanyId = response.isCompanyId

            await props.fixTypeDefinitionIfEnum(element, response)
        })

        values?.filter((value: any) => {
            if (node.name == "CompanyConfiguration") {
                return value.path == node.path
            }
            return value.path == node.path + "." + node.name
        }).map((value: any) => {
            //node.active = value.active
            value = elements.find((e) => e.path == value.path + "." + value.name)
        })

        props.updateCursor(node)
        context.setNavTreeCursor(node)


        // Tree
        if (data?.children) {


            let children = data?.children?.find(obj => {
                return (obj.type == "object" || obj.type == "list") && obj.path + "." + obj.name == node.path + "." + node.name
            })?.children

            if (children) {
                let objectGetTypeResponses = (await getTypes(children)).responses

                children.map(async (obj) => {
                    let response = objectGetTypeResponses.find((resp) => {
                        return resp.path == obj.path + "." + obj.name
                    })
                    if (obj.isDeprecated != response.isDeprecated) {
                        obj.isDeprecated = response.isDeprecated
                        obj.deprecatedMessage = response.deprecatedMessage
                        obj.defaultDescription = response.defaultDescription
                        //setState({isLoading: false}) // for rerender of the tree
                    }
                })

            }
        }

        setIsLoading(false)
    }

    async function getTypes(elements: any[]) {
        if (elements) {
            let paths = elements?.map((element) => {

                return element.path + "." + element.name

            })
            let request = {} as GetTypesRequest
            request.config = JSON.parse(workingConfig.value)
            request.paths = paths
            return (await backend.internalApi.getTypes(request, auth))
        }
    }

    function drawTreeSearch() {
        return <div style={{marginBottom: "5px"}}>
            <Input style={{width: 220}}
                   placeholder='search'
                   value={context.treeSearchQuery}
                   onChange={(evt) => {
                       context.setTreeSearchQuery(evt.target.value)
                       setTreeSearchQuery(evt.target.value)
                   }}
            />
            <Button icon onClick={(evt) => {
                evt.preventDefault()
                props.refreshTree()
            }}><Icon name='filter'/></Button>
        </div>
    }


    return <Segment className={"flex"} style={{padding: "5px", margin: "0 5px 0 0"}}>
        {drawTreeSearch()}
        <CustomNavTree
            data={data}
            onToggle={onToggle}
        />
    </Segment>
}