import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {GenericXlsExtractionRule} from "../../../../../generated";
import MatchWhenEnum = GenericXlsExtractionRule.MatchWhenEnum;
import SelectorMatchWhenEnum = GenericXlsExtractionRule.SelectorMatchWhenEnum;
import IfNotAvailableTriggerEnum = GenericXlsExtractionRule.IfNotAvailableTriggerEnum;

export type TableDataCellLabel = {
    label: string
    valueExtractionRegex?: string
}

export type TableDataCell = {
    text: string
    matchingRule?: GenericXlsExtractionRule
    selectionLabels?: TableDataCellLabel[]
}

export type TableDataLine = {
    cells: TableDataCell[]
    matchingRule?: GenericXlsExtractionRule
}

export type TableCellCoordinate = {
    lineIdx: number
    colIdx: number
}
export const withOffset = function (c: TableCellCoordinate | undefined, lineOffset?: number, colOffset?: number): TableCellCoordinate {
    if (c != undefined) {
        return {
            lineIdx: c.lineIdx + (lineOffset ?? 0),
            colIdx: c.colIdx + (colOffset ?? 0)
        }
    }
    return undefined
}


export type RangeFromToWithData = {
    from: TableCellCoordinate
    to: TableCellCoordinate | undefined
    data: TableDataLine[]
}

export type SelectedRange = {
    rule: GenericXlsExtractionRule
    from: TableCellCoordinate
    to: TableCellCoordinate | undefined
}

export type TablePage = {
    name: string,
    dataLines: TableDataLine[],
    selections: SelectedRange[],
    activeCells?: TableCellCoordinate[] | undefined
    pageIndex?: number;
    fileName?: string;
}

export type StateOfAllTables = {
    openFiles: TableState[],
    activeFile: number | undefined
}
export type TableState = {
    pages: TablePage[]
    inputFileName: string
}

export const initialState = {
    openFiles: [],
    activeFile: undefined
}


export type ActiveCell = {
    pageIdx: number,
    cell: TableCellCoordinate
}

const tableSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        setOpenFiles(state, action: PayloadAction<StateOfAllTables>) {
            state.openFiles = action.payload.openFiles
            state.activeFile = action.payload.activeFile
        },
        addActiveCell(state, action: PayloadAction<ActiveCell>) {
            if (!state.openFiles[state.activeFile].pages[action.payload.pageIdx].activeCells)
                state.openFiles[state.activeFile].pages[action.payload.pageIdx].activeCells = []
            state.openFiles[state.activeFile].pages[action.payload.pageIdx].activeCells.push(action.payload.cell)
        },
        clearActiveCells(state) {
            state.openFiles[state.activeFile].pages.forEach(p => p.activeCells = [])
        },
        setInputFileName(state, action: PayloadAction<string>) {
            state.openFiles[state.activeFile].inputFileName = action.payload
        },
        setActiveFile(state, action: PayloadAction<number>) {
            state.activeFile = action.payload
        },
        applyRules(state, action: PayloadAction<GenericXlsExtractionRule[]>) {
            const rules = action.payload
            console.log("rules in applyRules", rules)
            if (state.openFiles[state.activeFile] && state.openFiles[state.activeFile].length > 0) {
                state.openFiles[state.activeFile].pages = state.openFiles[state.activeFile]?.pages.map(p => {
                    const outputPage = applyAllRules(p, rules)
                    outputPage.selections = deriveSelection(outputPage, rules)
                    return outputPage
                })
                console.log("Did Apply all rules")
            }
            console.log("No File loaded!")
        }
    }
})

export type SelectedRangesOfMatchingRule = {
    rule: GenericXlsExtractionRule,
    selections: RangeFromToWithData[]
}


export const {setOpenFiles, applyRules, addActiveCell, clearActiveCells, setActiveFile} = tableSlice.actions
export default tableSlice.reducer


export const getActivePage = (state: RootState): TableState => state.table.openFiles[state.table.activeFile];
export const getFullTable = (state: RootState) => state.table
export const getOpenedFiles = (state: RootState) => state.table.openFiles as TableState[]


export const getFlatOutputList = (state: RootState) => {

    // console.log("getFlatOutputList called")

    const topLevelRules = state.rules.ruleList.filter(r => !r.parentRuleId)
    const stateCopy: TablePage[] = JSON.parse(JSON.stringify(state.table.openFiles[state.table.activeFile]?.pages ?? []))

    const result: OutputListEntry[] = []
    // console.log("result before", result)
    topLevelRules.flatMap(tlr => {
        const tree = buildOutputTree(state, stateCopy, tlr)
        // console.log("tree is ", tree)
        tree ? flattenOutputTree(tree, result) : []
    })
    // console.log("result after", result)

    return result
}

export type RuleMatchOutputTree = {
    //rule:MatchingRule,
    tag: string | undefined
    matches: RuleMatchOutput[]
}

export type RuleMatchOutput = {
    data: TablePage | undefined,
    children: RuleMatchOutputTree[]
}

function mergeTables(pages: TablePage[]): TablePage[] {

    const dataLinesOut = []
    const selectionsOut = []
    const activeCellsOut = []

    let offset = 0

    pages.forEach(p => {
        dataLinesOut.push(...p.dataLines)
        if (p.selections) {
            selectionsOut.push(...p.selections.map(s => {
                return {rule: s.rule, from: withOffset(s.from, offset), to: withOffset(s.to, offset)}
            }))
        }
        if (p.activeCells) {
            activeCellsOut.push(...p.activeCells.map(a => withOffset(a, offset)))
        }
        offset += p.dataLines.length
    })

    const mergedPage: TablePage = {
        name: pages.map(p => p.name).join(","),
        dataLines: dataLinesOut,
        selections: selectionsOut,
        activeCells: activeCellsOut
    }

    return [mergedPage]
}

function buildOutputTree(state: RootState, pages: TablePage[], rule: GenericXlsExtractionRule): RuleMatchOutputTree | undefined {
    // if (rule.tagName == "additionalData.borna"){
    if (rule.action == "TAG" || rule.action == "SQUASH" || rule.action == "REMOVE_EMPTY_ROWS_AND_COLUMNS") {
        let slicedPages = deriveSelectionForSingleRule(pages, rule)
        const childRules = state.rules.ruleList.filter(r => r.parentRuleId == rule.ruleId)

        if (rule.action == "SQUASH") {
            slicedPages = mergeTables(slicedPages)
        }
        if (rule.action == "REMOVE_EMPTY_ROWS_AND_COLUMNS") {
            slicedPages = cleanUpBuffer(slicedPages)
        }

        const matches: RuleMatchOutput[] = slicedPages.flatMap(p => {
                const c = childRules.flatMap(cr => buildOutputTree(state, [p], cr))
                const children: RuleMatchOutputTree[] = []
                c.forEach(item => {
                    if (item) {
                        children.push(item)
                    }
                })

                const hasTagName: boolean = !!rule.tagName && (rule.tagName.length > 0)
                const cellsWithLabels = p.dataLines.flatMap(l => l.cells.filter(c => c.selectionLabels && c.selectionLabels.length > 0))


                let selectedData = p
                if (rule.extractionRegex && rule.extractionRegex != "") {

                    console.log("Extraction regex is ${rule.extractionRegex}")

                    selectedData = JSON.parse(JSON.stringify(p))
                    selectedData.dataLines.forEach(dl => {
                        dl.cells.forEach(cell => {
                                const match = cell.text.match(rule.extractionRegex)
                                if (match) {
                                    cell.text = match[1]
                                } else {
                                    cell.text = ""
                                }
                            }
                        )
                    })
                }

                //Copy all labels that don't appear in children to the output as well
                const cellsWithLabelsNotAppearingInChildren: RuleMatchOutputTree[] = []
                cellsWithLabels.forEach(cell => {
                    cell.selectionLabels?.forEach(label => {
                        console.log("Checking if cell must be copied to output for rule ", rule.ruleId, "Is referenced in child", isTagReferencedInChildNodes(children, label.label))
                        if (!isTagReferencedInChildNodes(children, label.label)) {

                            let cellText = cell.text
                            if (label.valueExtractionRegex && label.valueExtractionRegex != "") {
                                const match = cellText.match(label.valueExtractionRegex)
                                if (match) {
                                    cellText = match[1]
                                } else {
                                    cellText = ""
                                }
                            }

                            const cellCopy = {...cell, selectionLabels: undefined, text: cellText}
                            cellsWithLabelsNotAppearingInChildren.push({
                                tag: label.label,
                                matches: [{
                                    data: {name: undefined, selections: [], dataLines: [{cells: [cellCopy]}]},
                                    children: []
                                }]
                            })
                        }
                    })
                })
                children.push(...cellsWithLabelsNotAppearingInChildren)

                // Output even if there is a PAGE_MARKER label
                const hasNoChildren: boolean = children.filter(x=>x.tag.indexOf("PAGE_MARKER_PAGE")==-1).length == 0

                return [{
                    data: ((hasTagName) && hasNoChildren) ? selectedData : undefined,
                    children: children
                }]
            }
        )
        if ((rule.ifNotAvailableTrigger && rule.ifNotAvailableTrigger != IfNotAvailableTriggerEnum.DONOTHING) && (matches.length === 0 || matches.every(m => !m.data))) {
            let tagName = "";
            switch (rule.ifNotAvailableTrigger) {
                case "SHOW_STATUS_VALIDATION_WARNING":
                    tagName = `missingFieldWarning.${rule.tagName}`;
                    break;
                case "GO_TO_INPUT_MISSING":
                    tagName = `inputMissing.${rule.tagName}`;
                    break;
                default:
                    break;
            }
            // console.log(`came inside of this `, rule, " with matches ",matches)

            return { tag: tagName, matches: [] };
        }

        return {
            //rule:rule,
            tag: rule.tagName,
            matches: matches
        }
    }
    // }
    if (rule.action == "LABEL") {
        appendLabelForSingleRule(pages, rule)
    }

    return undefined
}

function isTagReferencedInChildNodes(children: RuleMatchOutputTree[], tag: string): boolean {
    if (!children.find(c => c.tag == tag)) {
        const matches = children.flatMap(c => c.matches.map(m => isTagReferencedInChildNodes(m.children, tag)))

        return !!matches.find(x => x)
    }

    return true
}

export type OutputListEntry = {
    tagOrSelection: string,
    data: any
}

function flattenOutputTree(tree: RuleMatchOutputTree, outputList: OutputListEntry[]) {
    // console.log("flattenOutputTree")
    if(tree.matches.length==0){
        if (tree.tag.startsWith("inputMissing") || tree.tag.startsWith("missingFieldWarning")) {
            outputList.push({tagOrSelection: tree.tag, data: ""})
        }
    }else {
        tree.matches.map(match => {
            if (tree.tag && tree.tag != "") {
                if (tree.tag.startsWith("PAGE_MARKER_PAGE")) {
                    outputList.push({tagOrSelection: "pdf.takePage", data: tree.tag.split("_").reverse()[0]})
                }
                if (tree.tag.endsWith("__new__")) {
                    outputList.push({tagOrSelection: tree.tag, data: "{}"})
                } else {
                    outputList.push({
                        tagOrSelection: tree.tag,
                        data: match.data?.dataLines.map(l => l.cells.map(c => c.text).join(";")).join("\n")
                    })
                }
            }
            match.data?.dataLines?.forEach(dl => {
                dl.cells?.forEach(cell => {
                    cell.selectionLabels?.forEach(label => {
                        const lastElement = outputList[outputList.length - 1]


                        let data = cell.text

                        if (!lastElement || lastElement.tagOrSelection != label.label || lastElement.data != data) {
                            outputList.push({tagOrSelection: label.label, data: data})
                        }
                    })
                })
            })

            match.children.forEach(c => flattenOutputTree(c, outputList))
        })
    }
}


export const deriveViewForSelectedRule = (state: RootState): TablePage[] => {
    const activeRule = state.rightMenu.selectedRule

    if (activeRule != null) {

        const rulesToApply = [activeRule]
        if(state.rules.ruleList) {
            while (rulesToApply[0].parentRuleId) {
                const parentRule = state.rules.ruleList.find(r => r.ruleId == rulesToApply[0].parentRuleId)
                if (parentRule) {
                    rulesToApply.splice(0, 0, parentRule)
                }
            }
        }

        var output = state.table.openFiles[state.table.activeFile].pages
        rulesToApply.forEach(rule => {
            output = deriveSelectionForSingleRule(output, rule)

            if (rule.action == "SQUASH") {
                output = mergeTables(output)
            }
            if (rule.action == "REMOVE_EMPTY_ROWS_AND_COLUMNS") {
                output = cleanUpBuffer(output)
            }
        })

        const showRules = [activeRule, ...findAllSubrules(state, activeRule, 0)]
        showRules.sort((a, b) => (b.parentRuleId ?? 999) - (a.parentRuleId ?? 999))

        output = output.map(p => {
            const outputPage = applyAllRules(p, showRules)
            outputPage.selections = deriveSelection(outputPage, showRules)
            return outputPage
        })

        if (activeRule.action == "SQUASH") {
            output = mergeTables(output)
        }
        if (activeRule.action == "REMOVE_EMPTY_ROWS_AND_COLUMNS") {
            output = cleanUpBuffer(output)
        }

        return output
    }

    return state.table.openFiles[state.table.activeFile].pages
}

export const queryNextRule = (direction: "UP" | "DOWN") => (store: RootState) => {
    const idx = store.rules.ruleList.indexOf(store.rightMenu.selectedRule)
    // console.log("Next index is " , idx)

    if (direction == "UP" && idx > 0)
        return store.rules.ruleList[idx - 1]

    if (direction == "DOWN" && idx < store.rules.ruleList.length - 1)
        return store.rules.ruleList[idx + 1]

    return store.rightMenu.selectedRule
}

export const querySubrules = (rule: GenericXlsExtractionRule | null, depth: number, includeMatchingRule?: boolean) => (store: RootState) => {
    const result = []

    if (includeMatchingRule && rule)
        result.push(rule)

    if (!rule)
        return result

    return [...result, ...findAllSubrules(store, rule, depth)]
}

function findAllSubrules(state: RootState, rule: GenericXlsExtractionRule | null, depth: number): GenericXlsExtractionRule[] {
    if (rule == null)
        return []
    if (depth < 0)
        return []

    const childRules = state.rules.ruleList.filter(r => r.parentRuleId == rule.ruleId)
    return childRules.flatMap(x => [x, ...findAllSubrules(state, x, depth - 1)])
}

const deriveSelectionForSingleRule = (pages: TablePage[], activeRule: GenericXlsExtractionRule) => {
    const outputPages: TablePage[] = []
    pages.forEach(p => {

        const outputForThisPage: TablePage[] = []
//         console.log("single rule checking")
        const outputPage = applyAllRules(p, [activeRule])
        outputPage.selections = deriveSelection(outputPage, [activeRule])
        outputPage.selections.forEach(s => {

            let selectionLabel = undefined
            if (activeRule.selectionLabel) {
//                 console.log("Selection Label is $" + activeRule.selectionLabel)
                selectionLabel = {label: activeRule.selectionLabel, valueExtractionRegex: activeRule.extractionRegex}
            }

            const lines = getDataMatchingSelection(p.dataLines, s, selectionLabel)
            if (lines.length > 0) {
                outputForThisPage.push({...outputPage, selections: [], dataLines: lines})
            }
        })
        if (new Set(outputPage.selections.filter(s => s.rule.selector == "EXCLUDE_ROWS_TILL_CELL").map(r => r.rule.ruleId)).size == 1) {
            console.log("Merging output buffer for rule ")
            const mergedPage = {
                ...outputPage,
                selections: [],
                dataLines: outputForThisPage.map(o => o.dataLines).flat()
            }
            outputPages.push(mergedPage)
        } else {
            outputForThisPage.forEach(p => outputPages.push(p))
        }
    })
    return outputPages
}

const appendLabelForSingleRule = (pages: TablePage[], activeRule: GenericXlsExtractionRule) => {
//     console.log("append label checking")

    pages.forEach(p => {
        const outputPage = applyAllRules(p, [activeRule])
        outputPage.selections = deriveSelection(outputPage, [activeRule])


        outputPage.selections.forEach(s => {
            if (activeRule.selectionLabel && activeRule.selectionLabel != "") {
                console.log("Selection Label is $" + activeRule.selectionLabel)
                appendLabelToMatchingSelectionInplace(p.dataLines, s, {
                    label: activeRule.selectionLabel,
                    valueExtractionRegex: activeRule.extractionRegex
                })
            }
        })
    })
}

function getDataMatchingSelection(input: TableDataLine[], selection: SelectedRange, selectionLabel: TableDataCellLabel | undefined): TableDataLine[] {

    return input.map((ln, lineIdx) => {
        return {
            cells: ln.cells
                .filter((c, cIdx) => isSelected([selection], lineIdx, cIdx, selection.rule ? [selection.rule] : []))
                .map(c => {
                    return {text: c.text, selectionLabels: merge(selectionLabel, c.selectionLabels)}
                })
        }
    }).filter(x => x.cells.length > 0)
}

function appendLabelToMatchingSelectionInplace(input: TableDataLine[], selection: SelectedRange, label: TableDataCellLabel) {

    input.map((ln, lineIdx) => {
        ln.cells
            .filter((c, cIdx) => isSelected([selection], lineIdx, cIdx, selection.rule ? [selection.rule] : []))
            .forEach(c => {
                c.selectionLabels = merge(label, c.selectionLabels)
            })
    })
}

function merge<T>(entry: T | undefined, array: T[] | undefined): T[] | undefined {
    if (entry && array) {
        return [entry, ...array]
    }
    if (!entry && array) {
        return array
    }
    if (entry && !array) {
        return [entry]
    }
    return undefined
}

function applyAllRules(page: TablePage, rule: GenericXlsExtractionRule[]) {
    const lines = page.dataLines.map((line, lineIdx) => {
        const cells = line.cells.map((cell, cellIdx) => {
            const matchingRule = rule.find(rule => matches(rule.matchText, rule.matchWhen, rule.matchOnColumns, cell.text, lineIdx, cellIdx))
            return {...cell, matchingRule: matchingRule} as TableDataCell;
        })

        const matchingRowRule = rule.find(rule => matches(rule.matchText, rule.matchWhen, rule.matchOnColumns, undefined, lineIdx, undefined))

        return {...line, cells: cells, matchingRule: matchingRowRule} as TableDataLine
    });

    return {...page, dataLines: lines} as TablePage
}

function deriveSelection(page: TablePage, activeRules: GenericXlsExtractionRule[]): SelectedRange[] {

    const selection: SelectedRange[] = []

    for (var lineIdx = 0; lineIdx < page.dataLines.length; lineIdx++) {
        for (var cellIdx = 0; cellIdx < page.dataLines[lineIdx].cells.length; cellIdx++) {
            const rule = page.dataLines[lineIdx].cells[cellIdx].matchingRule
            if (rule) {
                resolveSelector(rule, selection, lineIdx, cellIdx, page)
            }
        }
        const matchingLineRule = page.dataLines[lineIdx]?.matchingRule
        if (matchingLineRule) {
            resolveSelector(matchingLineRule, selection, lineIdx, 0, page)
        }
    }

    const excludeRule = activeRules.find(r => r.selector == "EXCLUDE_ROWS_TILL_CELL")
    if (excludeRule && selection.length == 0) {
        selection.push({
            from: {lineIdx: 0, colIdx: 0},
            to: {lineIdx: page.dataLines.length, colIdx: 9999},
            rule: excludeRule
        })
    }

    const splitSelections = selection.filter(s => ["SPLIT_ALONG_LINE_BEFORE"].indexOf(s.rule.selector) != -1)

    new Set(splitSelections.map(x => x.rule)).forEach(rule => {
        if (rule.skipNumEntriesAtStart) {
            for (var i = 0; i < rule.skipNumEntriesAtStart; i++) {
                const matchingIndex = selection.findIndex(x => x.rule.ruleId == rule.ruleId)
                if (matchingIndex > -1) {
                    selection.splice(matchingIndex, 1)
                }
            }
        }
        if (rule.skipNumEntriesAtEnd) {
            for (var i = 0; i < rule.skipNumEntriesAtEnd; i++) {

                const matchingIndex = findLastIndex(selection, x => x.rule.ruleId == rule.ruleId)
                if (matchingIndex > -1) {
                    selection.splice(matchingIndex, 1)
                }
            }
        }
    })

    return selection
}

function isCellEmpty(text: string): boolean {
    return text == ""
}

function isTableCellEmpty(dataLines: TableDataLine[], lineIdx: number, cellIdx: number): boolean {
    const cellValue = dataLines[lineIdx]?.cells[cellIdx]?.text
    return isCellEmpty(cellValue)
}

function resolveSelector(rule: GenericXlsExtractionRule, selection: SelectedRange[], lineIdx: number, cellIdx: number, page: TablePage) {
    switch (rule.selector) {
        case "THIS_CELL":
            selection.push({rule: rule, from: {lineIdx: lineIdx, colIdx: cellIdx}, to: undefined})
            break;
        case "RANGE_WITH_SIZE":
            console.log("evaluating RANGE_WITH_SIZE")
            selection.push({
                rule: rule,
                from: {
                    lineIdx: lineIdx + (rule.skipNumEntriesAtStart ?? 0),
                    colIdx: cellIdx + (rule.skipNumColumns ?? 0)
                },
                to: {
                    lineIdx: lineIdx + ((rule.selectNumLines ?? 1)) + (rule.skipNumEntriesAtStart ?? 0),
                    colIdx: cellIdx + ((rule.skipNumColumns ?? 0) + (rule.selectNumColumns ?? 1) - 1)
                }
            })
            break;
        case "LEFT_CELL":
            var colIdx = cellIdx - 1;
            if (rule.skipEmptyCells) {
                while (colIdx > 0 && isTableCellEmpty(page.dataLines, lineIdx, colIdx)) {
                    colIdx -= 1
                }
            }
            selection.push({rule: rule, from: {lineIdx: lineIdx, colIdx: colIdx}, to: undefined})
            break;
        case "RIGHT_CELL":
            var colIdx = cellIdx + 1;
            if (rule.skipEmptyCells) {
                while (colIdx < page.dataLines[lineIdx].cells.length && (isTableCellEmpty(page.dataLines, lineIdx, colIdx))) {
                    colIdx += 1
                }
            }

            selection.push({rule: rule, from: {lineIdx: lineIdx, colIdx: colIdx}, to: undefined})
            break;
        case "NEXT_CELL_UP":
            var selectLineIdx = lineIdx - 1;
            if (rule.skipEmptyCells) {
                while (isTableCellEmpty(page.dataLines, selectLineIdx, cellIdx) && selectLineIdx > 0) {
                    selectLineIdx -= 1
                }
            }

            selection.push({rule: rule, from: {lineIdx: selectLineIdx, colIdx: cellIdx}, to: undefined})
            break;
        case "NEXT_CELL_DOWN":
            var selectLineIdx = lineIdx + 1;
            if (rule.skipEmptyCells) {
                while (page.dataLines[selectLineIdx].cells.length <= cellIdx || (isTableCellEmpty(page.dataLines, selectLineIdx, cellIdx)) && selectLineIdx < page.dataLines.length) {

                    selectLineIdx += 1
                }
            }

            selection.push({rule: rule, from: {lineIdx: selectLineIdx, colIdx: cellIdx}, to: undefined})
            break;
        case "THIS_COLUMN":
            selection.push({
                rule: rule,
                from: {lineIdx: lineIdx + 1 + (rule?.skipNumEntriesAtStart ?? 0), colIdx: cellIdx},
                to: {lineIdx: page.dataLines.length - (rule?.skipNumEntriesAtEnd ?? 0), colIdx: cellIdx}
            })
            break;
        case "THIS_ROW":
            console.log("Select this row")
            selection.push({
                rule: rule,
                from: {lineIdx: lineIdx, colIdx: 0},
                to: {lineIdx: lineIdx, colIdx: page.dataLines[lineIdx].cells.length - 1}
            })
            break;
        case "NEXT_ROW":
            let nextLineIdx = lineIdx + 1
            if (nextLineIdx < page.dataLines.length) {
                selection.push({
                    rule: rule,
                    from: {lineIdx: nextLineIdx, colIdx: 0},
                    to: {lineIdx: nextLineIdx, colIdx: page.dataLines[nextLineIdx].cells.length - 1}
                })
            }
            break;
        case "PREV_ROW":
            let prevRowIdx = lineIdx - 1
            if (prevRowIdx >= 0) {
                selection.push({
                    rule: rule,
                    from: {lineIdx: prevRowIdx, colIdx: 0},
                    to: {lineIdx: prevRowIdx, colIdx: page.dataLines[prevRowIdx].cells.length - 1}
                })
            }
            break;
        case "AREA_TILL_CELL":
//             console.log("AREA_TILL_CELL");
            var done = false
            for (var endLineIdx = lineIdx; endLineIdx < page.dataLines.length; endLineIdx++) {

                var startCellIndex = cellIdx

                for (var endCellIdx = startCellIndex; endCellIdx < page.dataLines[endLineIdx].cells.length; endCellIdx++) {
                    if (!done && rule.selectorMatchText && page.dataLines[endLineIdx].cells[endCellIdx].text && matches(rule.selectorMatchText, rule.selectorMatchWhen, undefined, page.dataLines[endLineIdx].cells[endCellIdx].text, endLineIdx, endCellIdx)) {
                        selection.push({
                            rule: rule,
                            from: {lineIdx: lineIdx + (rule?.skipNumEntriesAtStart ?? 0), colIdx: startCellIndex},
                            to: {lineIdx: endLineIdx - (rule?.skipNumEntriesAtEnd ?? 0), colIdx: endCellIdx}
                        })
                        done = true
                    }
                }
            }
            break;

        case "ROWS_TILL_CELL":
//             console.log(`Row Data is ${rule.selector}, ${selection}, ${lineIdx}, ${cellIdx}, ${page}`)

            console.log("ROWS_TILL_CELL")
            var done = false
            for (var endLineIdx = lineIdx; endLineIdx < page.dataLines.length; endLineIdx++) {

                //In the line where the first occurrence was found, don't start in the beginning of the line
                //But in the next line, start at column 0
                var startCellIndex = 0
                if (lineIdx == endLineIdx) {
                    startCellIndex = cellIdx;
                }

                for (var endCellIdx = startCellIndex; endCellIdx < page.dataLines[endLineIdx].cells.length; endCellIdx++) {
                    if (!done && rule.selectorMatchText && page.dataLines[endLineIdx].cells[endCellIdx].text && matches(rule.selectorMatchText, rule.selectorMatchWhen, undefined, page.dataLines[endLineIdx].cells[endCellIdx].text, endLineIdx, endCellIdx)) {
                        selection.push({
                            rule: rule,
                            from: {lineIdx: lineIdx + (rule?.skipNumEntriesAtStart ?? 0), colIdx: 0},
                            to: {lineIdx: endLineIdx - (rule?.skipNumEntriesAtEnd ?? 0), colIdx: 9999}
                        })
                        done = true
                    }
                }
            }
            break;
        case "EXCLUDE_ROWS_TILL_CELL":
            console.log("EXCLUDE_ROWS_TILL_CELL")

            // Search backwards if it is the first occurence
            if (!selection.find(r => r.rule.ruleId == rule.ruleId)) {
                var done = false
                for (var beginLineIdx = lineIdx; endLineIdx >= 0; beginLineIdx--) {
                    for (var endCellIdx = 0; endCellIdx < page.dataLines[endLineIdx].cells.length; endCellIdx++) {
                        if (!done && rule.selectorMatchText && page.dataLines[endLineIdx].cells[endCellIdx].text && matches(rule.selectorMatchText, rule.selectorMatchWhen, undefined, page.dataLines[endLineIdx].cells[endCellIdx].text, endLineIdx, endCellIdx)) {
                            selection.push({
                                rule: rule,
                                from: {lineIdx: beginLineIdx, colIdx: 0},
                                to: {lineIdx: lineIdx + (rule.skipNumEntriesAtStart ?? 0), colIdx: 9999}
                            })
                            done = true
                        }
                    }
                }
                if (!done) {
                    selection.push({
                        rule: rule,
                        from: {lineIdx: 0, colIdx: 0},
                        to: {lineIdx: lineIdx + (rule.skipNumEntriesAtStart ?? 0), colIdx: 9999}
                    })
                }
            }

            //search next take area
            var done2 = false
            var takeFromLine: number
            for (var endLineIdx = lineIdx; endLineIdx < page.dataLines.length; endLineIdx++) {
                for (var endCellIdx = 0; endCellIdx < page.dataLines[endLineIdx].cells.length; endCellIdx++) {
                    if (!takeFromLine && rule.selectorMatchText && page.dataLines[endLineIdx].cells[endCellIdx].text && matches(rule.selectorMatchText, rule.selectorMatchWhen, undefined, page.dataLines[endLineIdx].cells[endCellIdx].text, endLineIdx, endCellIdx)) {
                        takeFromLine = endLineIdx
                    }
                    if (takeFromLine && !done2 && rule.matchText && page.dataLines[endLineIdx].cells[endCellIdx].text && matches(rule.matchText, rule.matchWhen, undefined, page.dataLines[endLineIdx].cells[endCellIdx].text, endLineIdx, endCellIdx)) {
                        selection.push({
                            rule: rule,
                            from: {lineIdx: takeFromLine + (rule.skipNumEntriesAtEnd ?? 0), colIdx: 0},
                            to: {lineIdx: endLineIdx, colIdx: 9999}
                        })
                        done2 = true
                    }
                }
            }
            // Take the rest if we reached the end
            if (!done2 && takeFromLine) {
                selection.push({
                    rule: rule,
                    from: {lineIdx: takeFromLine + (rule.skipNumEntriesAtEnd ?? 0), colIdx: 0},
                    to: {lineIdx: page.dataLines.length - 1, colIdx: 9999}
                })
            }

            break;
        case "ROWS_TILL_NEXT_MATCH":
            console.log("ROWS_TILL_NEXT_MATCH")
            var done = false
            for (var endLineIdx = lineIdx + 1; endLineIdx < page.dataLines.length; endLineIdx++) {
                for (var endCellIdx = cellIdx; endCellIdx < page.dataLines[endLineIdx].cells.length; endCellIdx++) {
                    if (!done && rule.matchText && page.dataLines[endLineIdx].cells[endCellIdx].text && matches(rule.matchText, rule.matchWhen, undefined, page.dataLines[endLineIdx].cells[endCellIdx].text, endLineIdx, endCellIdx)) {
                        selection.push({
                            rule: rule,
                            from: {lineIdx: lineIdx + (rule?.skipNumEntriesAtStart ?? 0), colIdx: 0},
                            to: {lineIdx: endLineIdx - (rule?.skipNumEntriesAtEnd ?? 0), colIdx: 9999}
                        })
                        done = true
                    }
                }
            }
            break;
        case "SPLIT_ALONG_LINE_BEFORE":

            //First selection
            var accumulator: string = ""
            if (rule.splitOnlyOnChange) {
                accumulator = determineAccumulator(page.dataLines, lineIdx, cellIdx, rule)
            }
            console.log("Accumulator is ", accumulator)

            selection.reverse()
            const prevSelection = selection.find(s => s.rule.ruleId == rule.ruleId)
            selection.reverse()
            if (!prevSelection) {
                selection.push({
                    rule: rule,
                    from: {lineIdx: 0, colIdx: 0},
                    to: {lineIdx: lineIdx - 1 + determineSplitlineOffset(lineIdx, rule, page, "UP"), colIdx: 9999}
                })
            }

            if (prevSelection && prevSelection.to.lineIdx >= lineIdx+determineSplitlineOffset(lineIdx, rule, page, "UP")) {
                break;
            }


            var done = false
            for (var endLineIdx = lineIdx + 1; endLineIdx < page.dataLines.length; endLineIdx++) {

                if (!done) {
                    //console.log(`Testing split for line ${lineIdx} - ${endLineIdx}`, endLineIdx)
                    for (var endCellIdx = 0; endCellIdx < page.dataLines[endLineIdx].cells.length; endCellIdx++) {
                        if (
                            !done && rule.matchText
                            && page.dataLines[endLineIdx].cells[endCellIdx].text
                            && matches(rule.matchText, rule.matchWhen, rule.matchOnColumns, page.dataLines[endLineIdx].cells[endCellIdx].text, endLineIdx, endCellIdx)
                            && (rule.splitOnlyOnChange != true || determineAccumulator(page.dataLines, endLineIdx, endCellIdx, rule) != accumulator)
                        ) {
                            console.log("splitOnlyOnChange", rule.splitOnlyOnChange, "current accumulator", accumulator, " new accumulator", determineAccumulator(page.dataLines, endLineIdx, endCellIdx, rule))
                            const existingSelection = selection.find(s => s.rule.ruleId == rule.ruleId && s.from?.lineIdx == lineIdx && s.to?.lineIdx == (endLineIdx - 1))
                            if (!existingSelection) {
                                selection.push({
                                    rule: rule,
                                    from: {
                                        lineIdx: lineIdx + determineSplitlineOffset(lineIdx, rule, page, "UP"),
                                        colIdx: 0
                                    },
                                    to: {
                                        lineIdx: endLineIdx - 1 + determineSplitlineOffset(endLineIdx, rule, page, "UP"),
                                        colIdx: 9999
                                    }
                                })
                            }
                            done = true
                        }
                        if (!done && endLineIdx == page.dataLines.length - 1 && endCellIdx == page.dataLines[endLineIdx].cells.length - 1) {
                            const existingSelection = selection.find(s => s.rule.ruleId == rule.ruleId && s.from?.lineIdx == lineIdx && s.to?.lineIdx == (endLineIdx))
                            if (!existingSelection) {
                                selection.push({
                                    rule: rule,
                                    from: {
                                        lineIdx: lineIdx + determineSplitlineOffset(lineIdx, rule, page, "UP"),
                                        colIdx: 0
                                    },
                                    to: {lineIdx: endLineIdx, colIdx: 9999}
                                })
                            }
                            done = true
                        }
                    }
                }
            }
            if (lineIdx == page.dataLines.length - 1) {
                // We found a match in the last line
                selection.push({
                    rule: rule,
                    from: {lineIdx: lineIdx + determineSplitlineOffset(lineIdx, rule, page, "UP"), colIdx: 0},
                    to: {lineIdx: lineIdx, colIdx: 9999}
                })
            }

            break;
        case "REMAINING_BUFFER":
            selection.push({
                rule: rule,
                from: {lineIdx: lineIdx + (rule?.skipNumEntriesAtStart ?? 0), colIdx: 0},
                to: {lineIdx: page.dataLines.length - 1 - (rule?.skipNumEntriesAtEnd ?? 0), colIdx: 9999}
            })
            break;
        case "REMAINING_BUFFER_SPLIT_EVERY":
            for (var endLineIdx = lineIdx + (rule.skipNumEntriesAtStart ?? 0); endLineIdx < (page.dataLines.length - (rule.skipNumEntriesAtEnd ?? 0)); endLineIdx += (rule.splitEvery ?? 1)) {
                selection.push({
                    rule: rule,
                    from: {lineIdx: endLineIdx, colIdx: 0},
                    to: {lineIdx: endLineIdx + (rule.splitEvery ?? 1) - 1, colIdx: 9999}
                })
            }
            break;
    }
}

function determineSplitlineOffset(lineIdx: number, rule: GenericXlsExtractionRule, page: TablePage, direction: "UP" | "DOWN"): number {

    if (rule.splitLineOffset && rule.splitLineOffset != 0)
        return rule.splitLineOffset

    if (rule.splitPointSelection == "MATCHING_CELL_BASED" && rule.selectorMatchText && rule.selectorMatchWhen) {
        for (var scanLineIdx = lineIdx; scanLineIdx >= 0; scanLineIdx--) {
            for (var scanCellIdx = 0; scanCellIdx < page.dataLines[scanLineIdx].cells.length; scanCellIdx++) {
                if (page.dataLines[scanLineIdx].cells[scanCellIdx].text && matches(rule.selectorMatchText, rule.selectorMatchWhen, undefined, page.dataLines[scanLineIdx].cells[scanCellIdx].text, scanLineIdx, scanCellIdx)) {
                    return scanLineIdx - lineIdx
                }
            }
        }
    }

    return 0
}

function cleanUpBuffer(pageLst: TablePage[]): TablePage[] {
    return pageLst.map(page => {
            const outPage: TablePage = {
                ...page, dataLines: page.dataLines.map(dl => {
                    return {...dl, cells: dl.cells.map(x => x)}
                })
            }

            let nonEmptyRows = outPage.dataLines.filter(line =>
                line.cells.some(cell => cell.text.trim() !== '')
            );

            if (outPage.dataLines.length !== nonEmptyRows.length) {
                outPage.dataLines = nonEmptyRows;
            }

            removeEmptyColumns(outPage);
            return outPage
        }
    )

}

function removeEmptyColumns(page: TablePage) {
    if (page.dataLines.length > 0) {
        let maxColIdx = page.dataLines[0].cells.length;
        for (let colIdx = 0; colIdx < maxColIdx; colIdx++) {
            if (page.dataLines.every(line => line.cells[colIdx].text.trim() === '')) {
                page.dataLines.forEach(line => line.cells.splice(colIdx, 1));
                maxColIdx--;
                colIdx--;
            }
        }
    }
}


function determineAccumulator(dataLines: TableDataLine[], lineIdx: number, cellIdx: number, rule: GenericXlsExtractionRule): string | undefined {

    const accPosLine = lineIdx + (rule.offsetLines ?? 0)
    const accPosCol = cellIdx + (rule.offsetColumns ?? 0)

    const output = dataLines[accPosLine]?.cells[accPosCol]?.text

    if (rule.skipEmptyCells == true && (!output || output.length == 0)) {
        console.log("determine Accumulator - skipEmptyCells active")
        const vecLine = Math.sign(rule.offsetLines)
        const vecCol = Math.sign(rule.offsetColumns)
        if (vecLine != 0 && vecCol == 0 || vecCol != 0 && vecLine == 0) {
            var loopCount = 0
            while (
                (accPosLine + vecLine * loopCount) > 0
                && (accPosLine + vecLine * loopCount) < dataLines.length
                && (accPosCol + vecCol * loopCount) > 0
                && (accPosCol + vecCol * loopCount) < Math.max(...dataLines!!.map(x => x.cells.length))) {
                loopCount += 1
                const data = dataLines[accPosLine + vecLine * loopCount]?.cells[accPosCol + vecCol * loopCount]?.text
                if (data && data.length > 0) {
                    return data
                }
            }
        }
    }

    return output
}

function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array))
            return l;
    }
    return -1;
}

function matches(matchText: string, matchWhen: MatchWhenEnum | SelectorMatchWhenEnum, matchOnColumns: string | undefined, cellText: string | undefined, lineIdx: number, cellIdx: number | undefined) {
    if (!isColumnRelevantForMatch(cellIdx, matchOnColumns))
        return false
    switch (matchWhen) {
        case "EXACT_MATCH":
            return matchText === cellText
        case "PARTIAL_MATCH":
            return cellText && cellText.indexOf(matchText) != -1
        case "REGEX_MATCH":
            return cellText && new RegExp(matchText).test(cellText)
        case "MATCH_ON_LINE":
            return cellIdx == undefined && lineIdx == +matchText
    }
}

function isColumnRelevantForMatch(cellIdx: number | undefined, matchOnColumnsStr: string) {
    const options = parseMatchOnColumns(matchOnColumnsStr)

    if (options) {
        if (options.onlyColumns && options.onlyColumns.indexOf(cellIdx) == -1) {
            return false
        }

        if (options.from != undefined && cellIdx < options.from) {
            return false
        }

        if (options.to != undefined && cellIdx > options.to) {
            return false
        }

        return true
    } else {
        return true
    }
}

function parseMatchOnColumns(input: string | undefined): MatchOnColumnDescriptor | undefined {
    if (input === undefined || input === null || input.length == 0)
        return undefined

    if (input && input.split("-").length == 2) {
        const fromTo = input.split("-").map(x => x.trim())
        const from = Number.parseInt(fromTo[0])
        const to = Number.parseInt(fromTo[1])
        return {from: Number.isNaN(from) ? undefined : from, to: Number.isNaN(to) ? undefined : to}
    }
    if (input && input.indexOf(",") > -1) {
        const pages = input.split(",").map(x => Number.parseInt(x.trim())).filter(n => !Number.isNaN(n))
        return {onlyColumns: pages}
    }
    try {
        const number = parseInt(input.trim())
        if (number >= 0)
            return {onlyColumns: [number]}
    } catch (e) {

    }

    return undefined
}

type MatchOnColumnDescriptor = {
    from?: number
    to?: number
    onlyColumns?: number[]
}

export function getSelectionIndex(selection: SelectedRange[], lineIdx: number, cellIdx: number, matchingRuleSelectedByUser: GenericXlsExtractionRule[]): number {
    //console.log("getSelectionIndex called")
    const ruleIds = matchingRuleSelectedByUser.map(r => r.ruleId)
    return selection.findIndex((selection) => {
        if (
            ruleIds.indexOf(selection.rule.ruleId) != -1
            && selection.from?.lineIdx == lineIdx
            && selection.from?.colIdx == cellIdx
            && selection.to?.lineIdx == undefined
            && selection.to?.colIdx == undefined
        ) {
            return true
        }

        if (
            ruleIds.indexOf(selection.rule.ruleId) != -1
            && selection.from?.lineIdx <= lineIdx
            && selection.from?.colIdx <= cellIdx
            && selection.to
            && selection.to?.lineIdx >= lineIdx
            && selection.to?.colIdx >= cellIdx
        ) {
            return true
        }
    })
}

export function isSelected(selection: SelectedRange[], lineIdx: number, cellIdx: number, matchingRuleSelectedByUser: GenericXlsExtractionRule[]): boolean {
    const idx = getSelectionIndex(selection, lineIdx, cellIdx, matchingRuleSelectedByUser)
    return idx != -1
}