import * as React from "react";
import {useContext, useState} from "react";
import {Button, Header, Icon, Label, List, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {ftp} from "../../../../../xconvert-ftp";
import {IfBox} from "../../../../style/if";
import {v4 as uuid} from 'uuid';
import {
    CreateZipResponse,
    DeleteFileResponse,
    DownloadFilesAsZipRequest,
    FileSystemEntry, OpenFileRequest
} from "../../../../../generated-ftp/api";

export interface FtpExplorerMultiFileDownloadModalProps {
    isOpen: boolean;
    onClose: (success: boolean) => void;
    files: string[];
    path: string;
}

export function FtpExplorerMultiFileDownloadModal(props: FtpExplorerMultiFileDownloadModalProps) {
    const context = useContext(ConfigContext);
    const auth = backend.withTokenAuthHeader(authentication.token);
    const [response, setResponse] = useState<string | null>(null);


    function handleClose() {
        props.onClose(false);
    }

    function handleDownload() {
        handleZipping().then(async (response: CreateZipResponse) => {
            if (response.success && response.file) {
                setResponse("Files deleted successfully");
                let file = await openFileByFileSystemEntry(response.file)
                let blob = await file.blob()
                download(response.file.name, blob).then(() => {
                  deleteFileByFileSystemEntry(response.file).then(() => {
                    props.onClose(true);
                  })
                })
            } else {
                setResponse("Error deleting files");
            }
        })

    }

    async function deleteFileByFileSystemEntry(fileSystemEntry: FileSystemEntry) {

            let path = props.path + "/" + fileSystemEntry.name

            let response: DeleteFileResponse
            try {
                response = await ftp.ftpApi.deleteFile(path, context.companyId, auth)
            } catch (ex) {
                response = {}
                response.success = false
                response.errorMessage = "Exception occured while call: " + ex.statusText
            }



    }

    async function openFileByFileSystemEntry(entry: FileSystemEntry) {
        let pathParameter = "?path=" + props.path + "&companyId=" + context.companyId
        let request = {} as OpenFileRequest
        request.fileName = entry.name

        return await fetch('/ftp/openFile' + pathParameter, {
            method: 'POST',
            headers: {
                "Authorization": auth.headers.Authorization.toString(),
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(request)
        })
    }

    async function download(fileName: string, blob: Blob) {
        let downloadUrl = window.URL.createObjectURL(blob);

        let tempLink = document.createElement('a');
        tempLink.href = downloadUrl;
        tempLink.setAttribute('download', `${fileName}`);
        tempLink.click();
    }

    async function handleZipping() {
        let downloadFilesRequest = {} as DownloadFilesAsZipRequest
        downloadFilesRequest.fileNames = props.files

        return await ftp.ftpApi.createZip(
            props.path,
            downloadFilesRequest,
            context.companyId,
            auth
        )
    }

    function renderListItems() {
        if (!props.files || props.files.length === 0) {
            return (
                <List.Item key={0}>
                    <List.Content>No Files selected</List.Content>
                </List.Item>
            );
        }

        return props.files.map((file: string) => (
            <List.Item key={uuid()}>{file}</List.Item>
        ));
    }

    return (
        <Modal open={props.isOpen} onClose={handleClose} dimmer="blurring" size="small">
            <Header icon="browser" content="Download Files as Zip" />
            <Modal.Content>
                <p>This are the files you want to download in a zip file!</p>
                <List divided verticalAlign="middle" bulleted>
                    {renderListItems()}
                </List>
                <Button onClick={handleDownload}>
                    <Icon name="download" /> Download
                </Button>
                <Button onClick={handleClose}>cancel</Button>
                <IfBox shouldShow={response != null}>
                    <Label>{response}</Label>
                </IfBox>
            </Modal.Content>
        </Modal>
    );
}
