import * as React from "react";
import { Modal, Header, Button, Label } from "semantic-ui-react";
import AceEditor from 'react-ace';

import 'brace/ext/searchbox'
import {ConfigContext} from "../../../../../context/ConfigContext";
import {backend} from "../../../../../../../xconvert-backend";

export class CreateDefaultContractModal extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            defaultContract: ""
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    handleSave() {
        this.context.setSelectedContract(this.state.defaultConfig)
        this.handleClose()
    }

    async componentDidMount() {
        let defaultContract = (await backend.internalApi.fetchDefaultContract(this.props.auth))
        this.setState({ defaultContract: JSON.stringify(defaultContract, null, 4) })
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Create default contract' />
            <Modal.Content>
                <AceEditor
                    theme="monokai"
                    mode="json"
                    value={this.state.defaultContract}
                    width="100%"
                    height="900px"
                    readOnly={true}
                />
                <Button onClick={() => this.handleSave()}>Save</Button>
                <Button onClick={() => this.handleClose()}>Close</Button>

            </Modal.Content>
        </Modal>
    }

}