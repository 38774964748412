import * as React from "react";
import {useContext} from "react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {CustomerReportDynamicSection, healthyHighlighter} from "../CustomerReportDynamicSection";
import {AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest} from "../../../../../../generated";
import ServiceTypeEnum = AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest.ServiceTypeEnum;
import {customerReport} from "../../../../../style/page_with_sidebar";

export function CustomerReportErrorFilesCountStatus({ reFetchReportData }: { reFetchReportData: () => Promise<void> }) {
    const context = useContext(ConfigContext)
    const columns = [{
        id: 'name',
        Header: 'name',
        width: 300,
        accessor: (d: any) => d.name,
    }, {
        id: 'icon',
        Header: 'healthy',
        width: 70,
        accessor: (d: any) =>
            <div>{healthyHighlighter(d.filesOlderThanFiveMinutes == 0)}</div>,
    }, {
        id: 'id',
        Header: 'id',
        width: 210,
        accessor: (d: any) => d.id,
    }, {
        id: 'tag',
        Header: 'tag',
        width: 70,
        accessor: (d: any) => d.tag,
    }, {
        id: 'filesOlderThanFiveMinutes',
        Header: 'filesOlderThanFiveMinutes',
        width: 160,
        accessor: (d: any) => d.filesOlderThanFiveMinutes,
    }, {
        id: 'oldestFileInMinutes',
        Header: 'oldestFileInMinutes',
        width: 160,
        accessor: (d: any) => d.oldestFileInMinutes,
    }, {
        id: 'youngestFileInMinutes',
        Header: 'youngestFileInMinutes',
        width: 160,
        accessor: (d: any) => d.youngestFileInMinutes,
    },{
        id: 'responsibleManager',
        Header: 'Responsible manager',
        width: 100,
        accessor: (d: any) => d.responsibleManager,
    }
    ]
    return <CustomerReportDynamicSection
        columns={columns}
        data={customerReport.value?.errorFilesCount?.companies}
        currentService={ServiceTypeEnum.ERRORFILESREPORT}
        reFetchReportData={reFetchReportData}
    />

}