import * as React from "react";
import {Tab} from "semantic-ui-react";
import {ImportSimText} from "./ImportSimText";
import {ImportSimFile} from "./ImportSimFile";
import {signal} from "@preact/signals-react";

export const importSimOutputs = signal(null)

const panes = [
    {menuItem: 'Text', render: props => <Tab.Pane><ImportSimText/></Tab.Pane>},
    {menuItem: 'File', render: props => <Tab.Pane><ImportSimFile/></Tab.Pane>},
]

const ImportSimTabMenu = props => <Tab
    className="flexTab"
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.importSimTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("importSimTab", data.activeIndex)
    }}
/>

export default ImportSimTabMenu