import { Middleware } from 'redux'

import {RootState} from './store'
import {applyRules} from "./slices/tableSlice";

export const ApplyRuleMiddleware: Middleware<
    {}, // Most middleware do not modify the dispatch return value
    RootState
    > = storeApi => next => action => {

    const returnVal = next(action);

    if(action.type == "rules/addRule"){
        console.log("Dispatching applyRule")
        storeApi.dispatch(applyRules(storeApi.getState().rules.ruleList))
    }

    return returnVal
}