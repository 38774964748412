import * as React from "react";
import {Button, Dimmer, Grid, Header, Loader, Modal} from "semantic-ui-react";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {QueryRequest} from "../../../../../../generated";
import ReactTable from "react-table";
import {IfBox} from "../../../../../style/if";
import AceEditor from "react-ace";
import {SplitterHorizontal} from "../../../../../util/table-splitter-ui/src/components/Splitters/SplitterHorizontal";

export interface OtherConfigFieldImplementationsModalProps {
    isOpen: boolean
    onClose: () => void
    path: string
}

export interface OtherConfigFieldImplementationsModalState {
    take: number,
    page: number,
    count: number,

    query: string,
    isLoading: boolean,
    queryResponse: any,
    selected: string
}

export class OtherConfigFieldImplementationsModal extends React.Component<OtherConfigFieldImplementationsModalProps, OtherConfigFieldImplementationsModalState> {

    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            take: 25,
            count: 0,

            query: null,
            isLoading: true,
            queryResponse: null,
            selected: "",
        }
    }

    handleClose = () => {

        this.props.onClose()
    }

    componentDidMount() {
        let query = this.initiateQuery()
        this.queryExecuteCall(query)
    }

    fieldName() {
        let array = this.props.path.split(".")
        return array[array.length - 1]
    }

    readValue(d) {
        const jp = require('jsonpath');

        let config = JSON.parse(JSON.stringify(d).replace("-configuration", "CompanyConfiguration"))

        if(config.CompanyConfiguration != undefined) {
            console.log("CompanyConfiguration ->", config.CompanyConfiguration)
            config = config.CompanyConfiguration
        }


        let jsonPath = JSON.parse(this.state.query).rules[0].field

        let pathArray = jsonPath.split(".")
        if(pathArray[pathArray.length-1] == pathArray[pathArray.length-2] ) {
            pathArray.pop()
        }


        jsonPath = pathArray.join(".")
        jsonPath = "$." + jsonPath.replace(".CompanyConfiguration", "").replace("-configuration", "")
        console.log("[readValue] jsonPath -> ", jsonPath)
        console.log("[readValue] config -> ", config)

        try {
            let value = jp.value(config, jsonPath)
            console.log("[readValue] value -> ", value)
            console.log("[readValue] type -> ", typeof value)

            if(typeof value == "object") {
                return JSON.stringify(value, null, 4)
            }
            return value.toString()
        } catch (e) {
            console.log("EXCEPTION: ", e)
            return "N/A"
        }
    }

    getFixedPath() {
        let path = this.props.path
        if(path.includes("$.CompanyConfiguration")) {
            path = path.replace(".CompanyConfiguration", "")
        }
        path = path.replace("$","-configuration" ).replace(/\.\d+\./, ".*.")

        let pathArray = path.split(".")
        if(pathArray[pathArray.length-1] == pathArray[pathArray.length-2] ) {
            pathArray.pop()
        }

        path = pathArray.join(".")

        console.log("FixedPath : ", path)
        return path
    }
    
    initiateQuery() {
        let path = this.getFixedPath()
        console.log("QueryENGINE requested with: ")
        let query = {
            rules: [
                {
                    field: path,
                    value: "",
                    operator: "NotNull"
                }
            ],
            combinator: "and"
        }
        let queryStr = JSON.stringify(query, null, 4)
        this.setState({query: queryStr, isLoading: false})
        return queryStr
    }

    async queryExecuteCall(query = this.state.query, take = this.state.take, page = this.state.page) {
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let path = this.props.path.replace("$.CompanyConfiguration", "-configuration").replace(/[0-9]+/, "*")
        console.log("QueryENGINE requested with: ")

        var finalQuery = query
            .replace(/"id": ".*",/gi, '')
            .replace(/"type": ".*",/gi, '')

        this.setState({isLoading: true})

        let params = {
            timeLimit: 60,
            bypassID: true,
            showhidden: true,
            wantedFields: [this.getFixedPath(), "name", "tag"]
        }

        let pagination = {
            limit: take,
            skip: (page - 1) * take,
            sortBy: "_id",
            sortDirection: "DESC"
        }
        console.log(pagination)
        let request = {query: finalQuery, paginate: pagination, params: params} as QueryRequest
        let response = await backend.queryApi.runQuery('Companies', request, auth)
        console.log(response)
        this.setState({
            queryResponse: response.collectionWithPrivateFields.map(x => JSON.parse(x)),
            count: response.count,
            take: take,
            page: page,
            isLoading: false,
        })
    }

    renderQuery() {
        return <div id={"OtherImplementationsQuery"} style={{
            height: '100%',
            padding: "1px",
            flexDirection: "row-reverse",
            //flexWrap: "wrap",
            display: "flex",
        }}>
            <AceEditor
                style={{
                    flex: "1",
                    flexGrow: 1,
                    minHeight: "100px"
            }}
            theme="monokai"
            mode='json'
            value={this.state.query}
            width="100%"
            height="100%"
            onChange={(e) => {
                this.setState({query: e})
            }}
        />
        </div>
    }

    renderTable() {
        const columns = [

            {
                id: 'tag',
                Header: 'tag',
                width: 80,
                accessor: (d: any) => d.tag,
            },
            {
                id: 'companyName',
                Header: 'CompanyName',
                width: 300,
                accessor: (d: any) => d.name,
            },
            {
                id: 'field',
                Header: 'requested field',
                width: 500,
                accessor: (d: any) => this.readValue(d),
            },
        ]

        return <div style={{
            flex: 1,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            minHeight: "100px",

        }}>

            <Button style={{alignSelf: "center"}} onClick={() => this.queryExecuteCall()}>
                run query
            </Button>
            <IfBox shouldShow={this.state.queryResponse != null}>
                <ReactTable
                    data={this.state.queryResponse}
                    pages={Math.ceil(this.state.queryResponse?.length / this.state.count)}
                    columns={columns}
                    sorted={[
                        {
                            id: 'createdAt',
                            desc: false
                        }
                    ]}
                    defaultPageSize={this.state.take}
                    className="-striped -highlight"
                    loading={this.state.isLoading}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick: () => {
                                if (rowInfo) {
                                    this.setState({selected: rowInfo.row.field})
                                }
                            }
                        };
                    }}
                    style={{cursor: "pointer"}}
                    onPageChange={(page) => this.changePage(page)}
                    onPageSizeChange={(pageSize) => this.changePageSize(pageSize)}
                />

            </IfBox>
            <IfBox shouldShow={this.state.queryResponse == null}>

                <Dimmer active inverted>
                    <Loader inverted content='Loading'/>
                </Dimmer>

            </IfBox>
        </div>
    }

    render() {


        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            size='fullscreen'
            dimmer={{className: 'contextMenuModalDimmer'}}

        >
            <Header icon='browser' content={'Other Implementations of ' + this.fieldName()}/>

            <Modal.Content>
                <Grid padded="horizontally" stackable columns='equal' centered>
                    <Grid.Column>
                        <SplitterHorizontal
                            name={"DbQuerySplitter"}
                            top={this.renderQuery()}
                            bottom={this.renderTable()}
                        />

                    </Grid.Column>

                    <Grid.Column>
                        <AceEditor
                            theme="monokai"
                            mode='json'
                            value={this.state.selected}
                            width="100%"
                            height="100%"
                            readOnly={true}
                        />
                    </Grid.Column>
                </Grid>

            </Modal.Content>
        </Modal>
    }

    async changePage(pageIndex: number) {
        this.setState({
            isLoading: true,
            page: pageIndex
        })
        await this.queryExecuteCall(this.state.query, this.state.take, pageIndex)
    }

    async changePageSize(newSize: number) {
        this.setState({take: newSize, isLoading: true})
        await this.queryExecuteCall(this.state.query, newSize, this.state.page)
    }
}

