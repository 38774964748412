import * as React from "react";
import {useEffect} from "react";

import * as AceDiff from 'ace-diff';

import 'ace-diff/dist/ace-diff-dark.min.css';
import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {Button, Header, Modal} from "semantic-ui-react";

const ace = require('brace');


export interface CherryPickModalProps {
    left: string,
    right: string,
    isOpen: boolean,
    onClose: () => void
    onSave: (newLeft: string) => void
}

export function CherryPickModal(props: React.PropsWithChildren<CherryPickModalProps>) {


    const [editors, setEditors] = React.useState<any>()


    useEffect(() => {
        let aceDiff = new AceDiff({
            ace,
            element: ".acediff",
            left: {
                content: props.left,
                copyLinkEnabled: false
            },
            right: {
                content: props.right,
                editable: false
            },
        });
        if (aceDiff.editors) {
            //console.log(aceDiff.getEditors().left.getValue())
            setEditors(aceDiff.getEditors())

        }
    }, [])

    function onSave() {
        console.log("editors.left: ", editors.left.getValue())
        props.onSave(editors.left.getValue())
        props.onClose()
    }

    function handleClose() {
        props.onClose()
    }

    return <Modal
        open={props.isOpen}
        onClose={handleClose}
        dimmer="inverted"
        size='fullscreen'>
        <Header icon='browser' content='CherryPick'/>
        <Modal.Content>

            <div className="acediff"/>
            <Button
                onClick={(evt) => onSave()}>Save</Button>
            <Button onClick={(evt) => handleClose()}>Cancel</Button>


        </Modal.Content>
    </Modal>

}
