import * as React from "react";
import { Modal, Header, Button } from "semantic-ui-react";
import { ConfigContext } from "../../../../context/ConfigContext";
import localforage from "localforage";
import {configStorage} from "../../../../../../ConfigStorage";

export interface DiscardModalProps {
    isOpen: boolean
    onClose: (discard: boolean) => void
    dispatch: () => void
}

export class DiscardModal extends React.Component<DiscardModalProps, any> {

    static contextType = ConfigContext;
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {}
    }

    async handleDiscard() {
        //await updateConfig(null, true)
        //await updateConfigDataValues(null, true)
        await configStorage.deleteCompany(this.context.companyId)
        this.props.dispatch()
        this.props.onClose(true)
    }

    handleClose = () => {
        this.props.onClose(false)
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Discard Changes' />
            <Modal.Content>
                <h3>Are you sure you want to discard all your changes?</h3>
                <Button id='discardModalDiscardButton' onClick={() => this.handleDiscard()}>Discard</Button>
                <Button id='discardModalCancelButton' onClick={() => this.handleClose()}>Cancel</Button>
            </Modal.Content>
        </Modal>
    }

}