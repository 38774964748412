import * as React from "react";
import {useState} from "react";
import {Button, ButtonGroup, ButtonOr} from "semantic-ui-react";
import {
    changeActiveConfig,
    changeActiveRules,
    changeSpecificActiveRules,
    getActiveConfig,
    getActiveRules, getSpecificActiveRules
} from "../../slices/ruleSlice";
import {useDispatch, useSelector} from "react-redux";

interface SpecificRuleToggleProps{}

export function SpecificRuleToggle(props: React.PropsWithChildren<SpecificRuleToggleProps>) {
    const dispatch = useDispatch()

    const activeRules = useSelector(getActiveRules)
    const activeConfig = useSelector(getActiveConfig)
    const specificActiveRules = useSelector(getSpecificActiveRules)

    const toggle = (specificActiveRules: "spcExt" | "applyWhen") => {
        if (activeRules=="spt"){
            dispatch((changeActiveConfig("default")))
        }
        dispatch(changeSpecificActiveRules(specificActiveRules))
    };

    return (
        <ButtonGroup size={"mini"}>
            <Button style={{paddingLeft: '7px'}} active={specificActiveRules=="spcExt"} onClick={()=>toggle("spcExt")} >Ext-Rules</Button>
            <ButtonOr text='or' />
            <Button active={specificActiveRules=="applyWhen"} onClick={()=>toggle("applyWhen")}>App-When</Button>
        </ButtonGroup>
    );
}
