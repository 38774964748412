import * as React from "react";
import { Segment, Card, Label, Icon, Grid, List } from "semantic-ui-react";
import { ConfigContext } from "../../../context/ConfigContext";
import { backend } from "../../../../../xconvert-backend";
import { authentication } from "../../../../../authentication";
import { CompanyConfiguration, CompanyWithConfig } from "../../../../../generated";
import { IfBox } from "../../../../style/if";
import {updateConfig, workingConfig} from "../ConfigSignal";


export interface ConverterCardState {
    config: CompanyConfiguration
    drawn: boolean
}

export class ConverterCard extends React.Component<any, ConverterCardState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading ConverterCard")

        this.state = {
            config: null,
            drawn: false
        }
    }

    componentDidMount() {
        this.setState({ config: JSON.parse(workingConfig.value) })
    }

    fetchActiveConverter() {
        if (this.state.config != null) {
            return this.state.config.activeConverter
        } else {
            return "no activeConverter found"
        }
    }

    render() {
        console.log("Render Partners")

        return <Segment>
            <Label>Active Converter</Label>
            <Card.Group>
                <Card>
                    <Card.Content>
                        <Card.Header>{this.fetchActiveConverter()}</Card.Header>
                    </Card.Content>
                </Card>
            </Card.Group>

        </Segment>
    }
}
