import * as React from "react";
import {Button, Checkbox, Divider, Header, Input, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {CreateScriptRunnerUserRequest, UserCreateRequest} from "../../../../../generated";

export interface ScriptRunnerUserCreationModalProps {
    isOpen: boolean
    onClose: (success: boolean, reason: string | null) => void
}

export interface ScriptRunnerUserCreationModalState {
    emailMiddlePart: string
    companyAdmin: boolean
}

export class ScriptRunnerUserCreationModal extends React.Component<ScriptRunnerUserCreationModalProps, ScriptRunnerUserCreationModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    mailPrefix = "tmp+scriptrunner_"
    mailPostFix = "@mail.logenios.com"

    constructor(props) {
        super(props)
        this.state = {
            emailMiddlePart: "",
            companyAdmin: false
        }
    }

    componentDidMount() {
        this.setState({emailMiddlePart: this.context.companyTag})
    }

    handleClose = (success, reason) => {
        this.props.onClose(success, reason)
    }

    async handleSave() {
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let request = {} as CreateScriptRunnerUserRequest
        request.email = this.mailPrefix + this.state.emailMiddlePart + this.mailPostFix
        request.firstName = "Scriptrunner"
        request.lastName = this.context.companyTag
        request.preferredLanguage = UserCreateRequest.PreferredLanguageEnum.GERMAN
        request.companyAdmin = this.state.companyAdmin

        let response = await backend.internalApi.createScriptRunnerUser(this.context.companyId, request, auth)

        this.handleClose(response.success, response.reason)
    }

    handleDiscard() {
        this.handleClose(false, null)
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Create ScriptRunner User'/>
            <Modal.Content>

                {this.mailPrefix}
                <Input
                    value={this.state.emailMiddlePart}
                    onChange={evt => this.setState({emailMiddlePart: evt.target.value})}/>
                {this.mailPostFix}
                <Divider horizontal/>
                <Checkbox
                    checked={this.state.companyAdmin}
                    onChange={() => this.setState({companyAdmin: !this.state.companyAdmin})}
                /> - CompanyAdmin

                <Divider horizontal/>
                <Button onClick={() => this.handleSave()}>Save</Button>
                <Button onClick={() => this.handleDiscard()}>Discard</Button>

            </Modal.Content>
        </Modal>
    }

}