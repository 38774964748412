import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {Button, Checkbox, Grid, Input, Label, Segment} from "semantic-ui-react";
import AceEditor from 'react-ace';
import Notifications, {notify} from 'react-notify-toast';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {LogView} from "../../logView/LogView";
import {CompanyConfiguration, QueuedEvent} from "../../../../../generated";
import {authentication} from "../../../../../authentication";
import {IfBox} from "../../../../style/if";
import {backend} from "../../../../../xconvert-backend";
import {EventEnum, QueuedEventCreator} from "./QueuedEventCreator";
import {ScriptRunnerUserCreationModal} from "./ScriptRunnerUserCreationModal";
import { workingConfig } from "../../configChange/ConfigSignal";

export interface ScriptRunnerExecutionTabState {
    isLoading: boolean
    validJson: boolean
    xRequestId: string
    keepEvent: boolean
    eventView: boolean
    queuedEventId: string
    transportOrderId: string
    selectedEvent: EventEnum
    queuedEvent: string
    showCreateScriptRunnerUserButton: boolean
    showCreateScriptRunnerModal: boolean
}

export class ScriptRunnerExecutionTab extends React.Component<any, ScriptRunnerExecutionTabState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading TransportOrder ScriptExecution")

        this.state = {
            isLoading: false,
            validJson: true,
            xRequestId: "",
            keepEvent: true,
            eventView: false,
            queuedEventId: "",
            transportOrderId: "",
            selectedEvent: null,
            queuedEvent: "",
            showCreateScriptRunnerUserButton: false,
            showCreateScriptRunnerModal: false
        }
    }

    onComponentMount() {
        this.setState({
            queuedEventId: this.context.scriptRunnerQueuedEventId,
            queuedEvent: this.context.scriptRunnerQueuedEvent
        })
    }

    componentDidMount() {
        this.setState({
            queuedEventId: this.context.scriptRunnerQueuedEventId,
            queuedEvent: this.context.scriptRunnerQueuedEvent,
            eventView: this.context.tabSession.scriptRunnerTypeIsCron
        })
        this.queryBackendForScriptRunnerUser()
    }

    async queryBackendForScriptRunnerUser() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let response = await backend.internalApi.fetchScriptRunnerUser(this.context.companyId, auth)
        this.setState({showCreateScriptRunnerUserButton: !response.exists})
    }

    handleScriptChange(newScript) {
        this.context.setScriptRunnerScript(newScript)
    }

    handleQueuedEventChange(newEvent) {
        this.context.setScriptRunnerQueuedEvent(newEvent)
        this.setState({queuedEvent: newEvent})
        try {
            JSON.parse(newEvent)
            this.setState({validJson: true})

        } catch (e) {
            this.setState({validJson: false})
        }
    }

    triggerScript() {
        console.log("triggering script")

        let request = new ExecuteScriptRequest

        request.auth = authentication.token
        request.config = JSON.parse(workingConfig.value)
        request.script = this.context.scriptRunnerScript
        request.eventScript = this.state.eventView
        request.keepEvent = this.state.keepEvent

        if (this.state.eventView) {
            request.event = JSON.parse(this.context.scriptRunnerQueuedEvent)
        }

        this.scriptRunnerExecuteCall(request)

    }

    async scriptRunnerExecuteCall(request: ExecuteScriptRequest) {
        let response = await fetch('/script-runner/execute', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(request)
        })
        this.setState({xRequestId: response.headers.get("x-request-id")})
        console.log("SCRIPTRUNNER responded with: ", response)
    }

    async loadQueuedEvent() {
        let auth = await backend.withTokenAuthHeader(authentication.token)
        let event = await backend.eventsApi.fetchQueuedEventById(this.context.scriptRunnerQueuedEventId, auth)
        this.handleQueuedEventChange(JSON.stringify(event, null, 4))
    }

    setQueuedEvent(queuedEvent) {
        this.context.setScriptRunnerQueuedEvent(queuedEvent)
        this.setState({queuedEvent: queuedEvent})
    }

    render() {
        return <div>


            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Row>
                    <Grid.Column>
                        <IfBox shouldShow={this.state.showCreateScriptRunnerUserButton}>
                            <p>No ScriptRunner User has been detected for this company.</p>
                            <Button
                                onClick={(evt) => {
                                    this.setState({showCreateScriptRunnerModal: true})
                                }}
                            >
                                Create ScriptRunner User
                            </Button>
                        </IfBox>
                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>

                    <Grid.Column>
                        <Label>
                            Script
                        </Label>
                        <AceEditor
                            theme="monokai"
                            mode="json"
                            value={this.context.scriptRunnerScript}
                            placeholder='script'
                            width='100%'
                            height="1000px"
                            onChange={(e) => this.handleScriptChange(e)}
                            readOnly={false}
                        />
                    </Grid.Column>
                    <Grid.Column>


                        <Grid.Row>
                            <p style={{fontSize: "16px"}}>Cron - <Checkbox
                                type='checkbox'
                                toggle
                                checked={this.state.eventView}
                                onChange={() => {
                                    this.context.setSingleTabSession("scriptRunnerTypeIsCron", null, null, !this.state.eventView)
                                    this.setState({eventView: !this.state.eventView})
                                }}
                            /> - Event </p>
                        </Grid.Row>

                        <IfBox shouldShow={this.state.eventView}>
                            <Grid padded="horizontally" stackable columns='equal' centered>
                                <Grid.Row>
                                    <Grid.Column textAlign='center'>
                                        <Input style={{width: 220}} placeholder='QueuedEvent Id'
                                               value={this.state.queuedEventId}
                                               onChange={(evt) => {
                                                   this.setState({queuedEventId: evt.target.value})
                                                   this.context.setScriptRunnerQueuedEventId(evt.target.value)
                                               }}
                                        />
                                        <Button id='loadQueuedEvent'
                                                onClick={() => this.loadQueuedEvent()}>load</Button>
                                    </Grid.Column>
                                    <Grid.Column>

                                        Create QueuedEvent:
                                        <QueuedEventCreator dispatch={(queuedEvent) => {
                                            this.setQueuedEvent(queuedEvent)
                                            console.log("new QueuedEvent has been set: ", queuedEvent)
                                        }}/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <AceEditor
                                        theme="monokai"
                                        mode="json"
                                        value={this.state.queuedEvent}
                                        placeholder='QueuedEvent'
                                        width='100%'
                                        height="800px"
                                        onChange={(e) => this.handleQueuedEventChange(e)}
                                        readOnly={false}
                                        focus={this.state.isLoading}
                                    />

                                    {!this.state.validJson ?
                                        <Label size={'huge'} color='red' pointing>INVALID JSON</Label> : null}
                                </Grid.Row>
                                <Grid.Row>

                                    drop Event -
                                    <Checkbox
                                        type='checkbox'
                                        toggle
                                        checked={this.state.keepEvent}
                                        onChange={() => this.setState({keepEvent: !this.state.keepEvent})}
                                    /> - keep Event
                                    <br/>
                                </Grid.Row>
                            </Grid>
                        </IfBox>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button color='blue' onClick={() => this.triggerScript()}>trigger script</Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4>Logs:</h4>
                        <LogView query={this.state.xRequestId} origin={"scriptRunner"}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <IfBox shouldShow={this.state.showCreateScriptRunnerModal}>
                <ScriptRunnerUserCreationModal
                    isOpen={this.state.showCreateScriptRunnerModal}
                    onClose={(success, reason) => {
                        if(success == false && reason != null) {
                            // show error
                            notify.show("ERROR: " + reason, 'error', 5000, '#fc0303')
                        }
                        if(success == true) {
                            // show success Notification
                            notify.show('ScriptRunner user has been created', 'success', 3000, '#28f751');
                            this.queryBackendForScriptRunnerUser()
                        }
                        this.setState({showCreateScriptRunnerModal: false})
                    }}
                />
            </IfBox>
            <Notifications/>
        </div>
    }
}

class ExecuteScriptRequest {
    auth: String
    script: String
    eventScript: Boolean
    event: QueuedEvent
    keepEvent: Boolean
    config: CompanyConfiguration

}
