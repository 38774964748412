import * as React from "react";
import AceEditor from 'react-ace';
import {
    Button,
    Checkbox,
    Divider,
    Dropdown,
    DropdownItemProps,
    Grid,
    Input,
    Label,
    Popup,
    Segment
} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";

import 'brace/ext/searchbox'
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {IfBox} from "../../../../style/if";
import {
    AdditionalDataEntry,
    CompanyConfiguration,
    FreightStatus,
    StopActionStatus,
    StopLocationStatus,
    TelematicState,
    TransportOrder,
    TransportOrderChange,
    TransportOrderStatus
} from "../../../../../generated";
import {DropDownEnum} from "./StatusUpdateModal";
import {ConverterSendButton} from "../ConverterSendButton";
import StatusCodeEnum = TransportOrderStatus.StatusCodeEnum;
import {EventEnum} from "../../scriptExecution/scriptRunner/QueuedEventCreator";

export interface ExportSimTransportOrderEditorProps {
    sendEvent: (
        selectedEvent: EventEnum,
        type: TransportOrderChange.TypeEnum,
        status: string | null,
        telematicStateCode: TelematicState.StatusCodeEnum,
    ) => void
}

export interface ExportSimTransportOrderEditorState {
    validJson: boolean
    isLoadingTransportOrder: boolean
    isConverting: boolean
    exportSimToId: string
    exportSimOrderId: string
    selectedEvent: EventEnum
    type: TransportOrderChange.TypeEnum
    telematicState: boolean
    telematicStateCode: TelematicState.StatusCodeEnum
    status: string
    config: CompanyConfiguration
    showResolveStatusButton: boolean
    parsedTo: TransportOrder | null
}

export class ExportSimTransportOrderEditor extends React.Component<ExportSimTransportOrderEditorProps, ExportSimTransportOrderEditorState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("showing ExportSimTransportOrderEditor")

        this.state = {
            validJson: true,
            isLoadingTransportOrder: false,
            isConverting: false,
            exportSimToId: null,
            exportSimOrderId: null,
            selectedEvent: null,
            type: null,
            telematicState: false,
            telematicStateCode: null,
            status: null,
            config: null,
            showResolveStatusButton: false,
            parsedTo: null,
        }
    }

    handleTransportOrderChange(value) {
        let valid = null
        let parsedTo = null
        try {
            parsedTo = JSON.parse(value)
            valid = true
        } catch (e) {
            valid = false
        }

        if (parsedTo != null && parsedTo.status?.statusCode == TransportOrderStatus.StatusCodeEnum.INPUTMISSING) {
            this.setState({showResolveStatusButton: true})
        }

        this.context.setExportSimTo(value)
        this.context.setExportSimToValid(valid)
        this.setState({validJson: valid, isLoadingTransportOrder: false, parsedTo: parsedTo})
    }

    setExportSimToId(value) {
        this.context.setExportSimToId(value)

        this.setState({exportSimToId: value})
    }

    async handleLoadTransportOrder() {
        this.setState({isLoadingTransportOrder: true})
        let auth = backend.withTokenAuthHeader(authentication.token)
        let transportOrder = await backend.transportOrderApi.fetchTransportOrderById(this.context.exportSimToId?.trim(), auth)

        let toAsJson = JSON.stringify(transportOrder, null, 4)
        this.handleTransportOrderChange(toAsJson)
    }

    setExportSimOrderId(value) {
        this.setState({exportSimOrderId: value})
        this.context.setExportSimOrderId(value)
    }

    componentDidMount() {
        this.setState({selectedEvent: EventEnum.ASSIGN_COMPANY_EVENT})
    }

    drawInput() {
        return <>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                {this.drawCustomConverterBasePathInput()}

                <div style={{paddingRight:50}}>

                    <Popup trigger={
                    <Input style={{width: 220}} placeholder='TransportOrderId'
                           value={this.state.exportSimToId}
                           onChange={(evt) => this.setExportSimToId(evt.target.value?.trim())}
                           action={
                               <Button onClick={(evt) => this.handleLoadTransportOrder()}
                                       loading={this.state.isLoadingTransportOrder}>load</Button>
                           }
                    />
                    } content={"Load TransportOrder by Id"} position={"bottom center"}/>

                </div>

                <div>
                    <Popup trigger={
                    <Input style={{width: 220}} placeholder='OrderCompanyId'
                           value={this.state.exportSimOrderId}
                           onChange={(evt) => this.setExportSimOrderId(evt.target.value?.trim())}
                    />
                    } content={"CompanyId to override"} position={"bottom center"}/>
                </div>

            </div>
            <Divider/>
            {this.drawTypeSelection()}
        </>
    }

    render() {
        return <>
            {this.drawInput()}
            {!this.state.validJson ? <Label size={'huge'} color='red' pointing="below">INVALID JSON</Label> : null}
            {this.state.showResolveStatusButton ?
                <Segment textAlign={'center'}>
                    <Label color={'yellow'}>
                        The status is INPUT_MISSING. This won't work on the Converter.
                    </Label>
                    <br/>
                    <Button
                        onClick={() => this.resolveStatus()}
                    >
                        Resolve
                    </Button>
                </Segment> : null
            }
            <br/>
            <AceEditor
                style={{flex: 1}}
                theme="monokai"
                mode='json'
                value={this.context.exportSimTo}
                placeholder='Transport Order'
                width="100%"
                onChange={(value) => this.handleTransportOrderChange(value)}
                readOnly={false}
                // onPaste={()=>{console.log('Pasting content:', "testing");}}
            />
        </>
    }

    resolveStatus() {
        let parsedTo = this.state.parsedTo

        let kdnr = {} as AdditionalDataEntry
        kdnr.key = "kundenNr"
        kdnr.value = "7777777777777"

        parsedTo.transports.forEach(t => {
            t.stops.forEach(stop => {
                if (stop.additionalData != null) {
                    if (stop.additionalData.find(ad => ad.key == "kundenNr") == undefined) {
                        stop.additionalData.push(kdnr)
                    }
                } else {
                    stop.additionalData = [kdnr]
                }
            })
        })
        parsedTo.freight.forEach(f => {
            f.contractingParties.forEach(cp => {
                if (cp.additionalData != null) {
                    if (cp.additionalData.find(ad => ad.key == "kundenNr") == undefined) {
                        cp.additionalData.push(kdnr)
                    }
                } else {
                    cp.additionalData = [kdnr]
                }
            })
        })


        parsedTo.status.statusCode = StatusCodeEnum.ACCEPTED

        this.handleTransportOrderChange(JSON.stringify(parsedTo, null, 4))
        this.setState({showResolveStatusButton: false})
    }

    enumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(EventEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }

    drawTypeSelection() {
        let options = this.enumToArray()
        return <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
            <div style={{paddingLeft: 20}}>
                <Dropdown
                    selectOnBlur={false}
                    placeholder='Select Type'
                    search
                    selection
                    options={options}
                    clearable
                    defaultValue={EventEnum.ASSIGN_COMPANY_EVENT}
                    onChange={(event, {value}) => {
                        console.log(value);
                        this.setState({selectedEvent: value as EventEnum})
                    }}
                />
            </div>
            <IfBox shouldShow={this.state.selectedEvent == EventEnum.STATUS_UPDATE_EVENT}>
                <div style={{paddingLeft: 20}}>
                    <p>Update
                        <Dropdown
                            selectOnBlur={false}
                            placeholder='Select'
                            search
                            style={{marginLeft: 10, marginRight: 10}}
                            selection
                            options={this.statusTypeEnumToArray()}
                            clearable
                            onChange={this.setType}
                        />
                        Status</p>
                </div>

                <div style={{paddingLeft: 20, display: "flex", flexDirection: "column", flexWrap: "wrap"}}>

                    <p>To
                        <Dropdown
                            selectOnBlur={false}
                            disabled={this.state.type == null}
                            placeholder='Select Status'
                            search
                            style={{marginLeft: 10, marginRight: 10}}
                            selection
                            options={this.statusEnumToArray()}
                            clearable
                            onChange={this.setStatus}
                            value={this.getStatus()}
                        />
                    </p>
                    <IfBox
                        shouldShow={this.state.type == TransportOrderChange.TypeEnum.STATUSUPDATETRANSPORTORDER}>
                        <div style={{paddingLeft: 25}}>
                            Status -
                            <Checkbox
                                disabled={this.state.type != TransportOrderChange.TypeEnum.STATUSUPDATETRANSPORTORDER}
                                type='checkbox'
                                toggle
                                onChange={() => this.setState({
                                    telematicState: !this.state.telematicState,
                                    status: null,
                                    telematicStateCode: null
                                })}
                            />
                            - TelematicState
                        </div>
                    </IfBox>

                </div>

            </IfBox>
        </div>
    }

    drawCustomConverterBasePathInput() {
        return <div style={{paddingRight: 20}}>
            <ConverterSendButton
                disabled={this.state.selectedEvent == null}
                onClick={async (evt) => {
                    this.setState({isConverting: true})
                    await this.props.sendEvent(
                        this.state.selectedEvent,
                        this.state.type,
                        this.state.status,
                        this.state.telematicStateCode,
                    )
                    this.setState({isConverting: false})
                }
                }
                loading={this.state.isConverting}
            />
        </div>
    }

    // Status update

    statusTypeEnumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(DropDownEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }

    statusEnumToArray() {

        let enumValues: Array<DropdownItemProps> = [];

        switch (this.state.type) {
            case TransportOrderChange.TypeEnum.STATUSUPDATETRANSPORTORDER: {
                if (this.state.telematicState) {
                    Object.values(TelematicState.StatusCodeEnum).forEach(value => {
                        enumValues.push({key: value, value: value, text: value});
                    })
                } else {
                    Object.values(TransportOrderStatus.StatusCodeEnum).forEach(value => {
                        enumValues.push({
                            key: value,
                            value: value,
                            text: value,
                        });
                    })
                }
                break;
            }
            case TransportOrderChange.TypeEnum.STATUSUPDATESTOPLOCATION: {
                Object.values(StopLocationStatus.StatusCodeEnum).forEach(value => {
                    enumValues.push({
                        key: value,
                        value: value,
                        text: value,
                    });
                })
                break;
            }
            case TransportOrderChange.TypeEnum.STATUSUPDATESTOPACTION: {
                Object.values(StopActionStatus.StatusCodeEnum).forEach(value => {
                    enumValues.push({
                        key: value,
                        value: value,
                        text: value,
                    });
                })
                break;
            }
            case TransportOrderChange.TypeEnum.STATUSUPDATEFREIGHT: {
                Object.values(FreightStatus.StatusCodeEnum).forEach(value => {
                    enumValues.push({
                        key: value,
                        value: value,
                        text: value,
                    });

                })
                break;
            }
        }
        return enumValues
    }

    setType = (event, {value}) => {
        console.log(value);

        switch (event.target.textContent) {
            case DropDownEnum.TRANSPORT_ORDER:
                this.setState({type: TransportOrderChange.TypeEnum.STATUSUPDATETRANSPORTORDER})
                break;

            case DropDownEnum.STOP_LOCATION:
                this.setState({type: TransportOrderChange.TypeEnum.STATUSUPDATESTOPLOCATION})
                break;

            case DropDownEnum.STOP_ACTION:
                this.setState({type: TransportOrderChange.TypeEnum.STATUSUPDATESTOPACTION})
                break;

            case DropDownEnum.FREIGHT:
                this.setState({type: TransportOrderChange.TypeEnum.STATUSUPDATEFREIGHT})
                break;
        }
    }

    setStatus = (event, {value}) => {
        console.log(value);
        let status = event.target.textContent;
        console.log(status);
        if (this.state.telematicState) {
            this.setState({telematicStateCode: status, status: null})
        } else {
            this.setState({status: status, telematicStateCode: null})
        }
    }

    getStatus() {
        if (this.state.telematicState) {
            return this.state.telematicStateCode
        } else {
            return this.state.status
        }
    }

}