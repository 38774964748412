import * as React from "react";
import {Accordion, Button, Grid, Header, Icon, Label, Modal, Popup} from "semantic-ui-react";
import {parseDiff} from 'react-diff-view';
import {ConfigContext} from "../../../../context/ConfigContext";
import {diffLines, formatLines} from 'unidiff';
import {CompanyConfigurationDraft} from "../../../../../../generated";
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {notify} from 'react-notify-toast';
import {CherryPickModal} from "../../CherryPickModal";
import {CustomDiff} from "../diff/CustomDiff";
import {updateConfig, workingConfig} from "../../ConfigSignal";

export interface DraftModalProps {
    isOpen: boolean
    onClose: (refresh: boolean) => void
    draft: CompanyConfigurationDraft
}

export class DraftModal extends React.Component<DraftModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            comment: "",
            response: null,
            hunks: null,
            type: null,
            draftName: "",
            writable: true,
            activeIndex: 0,
        }
    }

    handleClose = (refresh) => {
        this.props.onClose(refresh)
    }

    override() {
        updateConfig(JSON.stringify(this.props.draft.config, null, 4), true, false)
        this.handleClose(true)
    }

    rebase() {
        let rebasedConfig = this.patchedConfig()
        updateConfig(rebasedConfig, true, false)
        this.handleClose(true)
    }

    async handleDelete() {
        let auth = await backend.withTokenAuthHeader(authentication.token)
        let response = await backend.internalApi.deleteDraftOfConfig(this.context.companyId, this.props.draft._id, auth)
        console.log("deleteDraftResponse was: ", response)
        if (response.status == 204) {
            notify.show('Deleted draft:' + this.props.draft.name, 'success', 3000, '#28f751');
        } else {
            notify.show("ERROR: " + response.status + " : " + response.statusText, 'error', 5000, '#fc0303')
        }
        this.handleClose(false)
    }

    async handleShare() {
        let auth = await backend.withTokenAuthHeader(authentication.token)
        let response = await backend.internalApi.updateConfigDraft(this.props.draft._id, !this.props.draft.shared, auth)

        if (response.newDraft != undefined) {
            notify.show('Draft share: ' + response.newDraft.shared, 'success', 3000, '#28f751');
        } else {
            notify.show("ERROR: couldn't update draft, check logs.", 'error', 5000, '#fc0303')
        }
        this.handleClose(false)

    }

    componentDidMount() {
        if (this.props.isOpen == true && this.state.hunks == null) {
            let currentConfig = workingConfig.value
            let newConfig = JSON.stringify(this.props.draft.config, null, 4)

            const diffText = formatLines(diffLines(currentConfig, newConfig), {context: 3});
            const [diff] = parseDiff(diffText, {nearbySequences: 'zip'});

            let writable = (this.props.draft.userId == "" || this.props.draft.userId == "you")
            this.setState({hunks: diff.hunks, writeable: writable});
        }
    }

    handleAccordionClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? 1 : index

        this.setState({activeIndex: newIndex})
    }

    drawOverride() {
        return <p>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Column>your current working copy:</Grid.Column>
                <Grid.Column>this draft:</Grid.Column>
            </Grid>

            <CustomDiff
                oldJson={workingConfig.value}
                newJson={JSON.stringify(this.props.draft.config, null, 4)}
            />

            <br/>
            <Popup content='overrides your current changes with this draft' trigger={
                <Button id='DraftModalSaveButton' primary loading={this.state.isSubmitting} onClick={() => {
                    this.override()
                }}>Override</Button>
            }/>
        </p>
    }

    patchedConfig() {
        let patchSet = this.props.draft.patchSet
        return this.applyPatchSet(workingConfig.value, patchSet)
    }

    applyPatchSet(config, patchSet) {
        const jp = require('jsonpath');
        let conf = JSON.parse(config)


        patchSet.map(patch => {
            let value = patch.value
            if (value) {
                if (value.startsWith("[") || value.startsWith("{")) {
                    try {
                        value = JSON.parse(patch.value)
                    } catch (e) {
                        // probably no object
                    }
                }
            }
            const jsonPath = patch.path

            jp.value(conf, jsonPath, value)
        })

        return JSON.stringify(conf, null, 4)
    }

    drawRebase() {
        if (this.props.draft.patchSet == undefined) {
            return <Label color={'red'}>No PatchSet found! Most likely an older Draft.</Label>
        }
        return <p>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Column>your current working copy:</Grid.Column>
                <Grid.Column>this draft:</Grid.Column>
            </Grid>

            <CustomDiff
                oldJson={workingConfig.value}
                newJson={this.patchedConfig()}
            />

            <br/>
            <Popup
                content="Rebase only overrides the fields in your config, that have been changed for this draft. It's patching the config with it's patchSet."
                trigger={
                    <Button id='DraftModalSaveButton' primary loading={this.state.isSubmitting} onClick={() => {
                        this.rebase()
                    }}>Rebase</Button>
                }/>
        </p>
    }

    render() {
        const {activeIndex} = this.state

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            size='fullscreen'>
            <Header icon='browser' content={'Draft: ' + this.props.draft.name}/>
            <Modal.Content>
                created at: {this.props.draft.createdAt}
                <br/>
                <br/>
                <Button id='DraftModalCancelButton'
                        onClick={() => this.setState({showCompareModal: true})}>CherryPick</Button>
                <br/>
                <br/>
                <Accordion styled fluid>
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={this.handleAccordionClick}
                    >
                        <Icon name='dropdown'/>
                        Override
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        {this.drawOverride()}
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={this.handleAccordionClick}
                    >
                        <Icon name='dropdown'/>
                        Rebase
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        {this.drawRebase()}
                    </Accordion.Content>
                </Accordion>

                <Button disabled={!this.state.writeable} id='ShareModalDeleteButton' onClick={() => this.handleShare()}
                        color='yellow'>share/unshare</Button>

                <Button disabled={!this.state.writeable} id='DraftModalDeleteButton' onClick={() => this.handleDelete()}
                        color='red'>Delete</Button>

                <Button id='DraftModalCancelButton' onClick={() => this.handleClose(false)}>Close</Button>

                {this.state.showCompareModal && <CherryPickModal

                    isOpen={this.state.showCompareModal}
                    left={workingConfig.value}
                    right={JSON.stringify(this.props.draft.config, null, 4)}
                    onSave={(newConfig) => {
                        updateConfig(newConfig, true, false).then(r => {
                            this.props.onClose(true)
                        })

                    }}
                    onClose={() => {
                        this.setState({showCompareModal: false})
                    }}
                />}
            </Modal.Content>
        </Modal>

    }

}

