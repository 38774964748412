import * as React from "react";
import {ConfigContext} from "../../../../context/ConfigContext";


import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import Notifications, {notify} from 'react-notify-toast';
import AceEditor from "react-ace";

export interface EditContractRawProps {
    jsonValidation: (json) => void
}


export interface EditContractRawState {
    isLoading: boolean
}


export class EditContractRaw extends React.Component<EditContractRawProps, EditContractRawState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading raw Contract")

        this.state = {
            isLoading: false
        }
    }

    async componentDidMount() {
    }


    render() {

        return <AceEditor
            theme="monokai"
            mode="json"
            value={this.context.selectedContract}
            width="100%"
            height="900px"
            onChange={(e) => {
                this.context.setSelectedContract(e);
                this.props.jsonValidation(e)
            }}
        />

    }


}