import * as React from "react";
import {useContext} from "react";
import {CustomerReportDynamicSection, healthyHighlighter} from "../CustomerReportDynamicSection";
import {ConfigContext} from "../../../../context/ConfigContext";
import {formatDate} from "../../../../../../format";
import {Label} from "semantic-ui-react";
import {AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest} from "../../../../../../generated";
import ServiceTypeEnum = AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest.ServiceTypeEnum;
import {customerReport} from "../../../../../style/page_with_sidebar";

export function CustomerReportEtaCalculatorStatus({ reFetchReportData }: { reFetchReportData: () => Promise<void> }) {

    const context = useContext(ConfigContext)

    const columns = [{
        id: 'name',
        Header: 'name',
        width: 300,
        accessor: (d: any) => d.name,
    }, {
        id: 'isHealthy',
        Header: 'isHealthy',
        width: 80,
        accessor: (d: any) => <div>{healthyHighlighter(d.isHealthy)}</div>,
    }, {
        id: 'numUnprocessedEvents',
        Header: 'numUnprocessedEvents',
        width: 170,
        accessor: (d: any) => d.numUnprocessedEvents,
    }, {
        id: 'numErrorsInLastCycle',
        Header: 'numErrorsInLastCycle',
        width: 170,
        accessor: (d: any) => d.numErrorsInLastCycle,
    }, {
        id: 'lastActivity',
        Header: 'lastActivity',
        width: 150,
        accessor: (d: any) => formatDate(d.lastActivity),
    },{
        id: 'responsibleManager',
        Header: 'Responsible manager',
        width: 100,
        accessor: (d: any) => d.responsibleManager,
    },
    ]
    return <>
        <Label
            color='blue'
            size='large'
            style={{margin: '1em'}}
        >
            DatabaseQuerySize: {customerReport.value?.etaCalculatorHealth?.databaseQueueSize}
        </Label>

        <CustomerReportDynamicSection
            columns={columns}
            data={customerReport.value?.etaCalculatorHealth?.workersHealthy}
            currentService={ServiceTypeEnum.ETACALCULATORREPORT}
            reFetchReportData={reFetchReportData}
        />
    </>
}