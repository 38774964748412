import * as React from "react";
import {useState} from "react";
import {Button, ButtonGroup, ButtonOr} from "semantic-ui-react";
import {changeActiveConfig, changeActiveRules} from "../../slices/ruleSlice";
import {useDispatch} from "react-redux";

interface RuleToggleProps{}

export function RuleToggle(props: React.PropsWithChildren<RuleToggleProps>) {
    const dispatch = useDispatch()

    const [activeRules, setActiveRules] = useState("ext");

    const toggle = (activeRule: "ext" | "spt") => {
        if (activeRules=="spt"){
            dispatch((changeActiveConfig("default")))
        }
        setActiveRules(activeRule);
        dispatch(changeActiveRules(activeRule))


    };
    React.useEffect(() => {
        if (activeRules=="spt"){
            dispatch((changeActiveConfig("default")))
        }
    },[activeRules])
    return (
        <ButtonGroup size={"mini"}>
            <Button style={{paddingLeft: '7px'}} active={activeRules=="ext"} onClick={()=>toggle("ext")} >Ext-Rules</Button>
            <ButtonOr text='or' />
            <Button active={activeRules=="spt"} onClick={()=>toggle("spt")}>Spt-Rules</Button>
        </ButtonGroup>
    );
}
