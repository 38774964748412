import * as React from "react"
import {useContext, useEffect, useState} from "react"
import {GeostatusWorkbenchTransportOrderQuery} from "./GeostatusWorkbenchTransportOrderQuery";
import {
    QueryResponse,
    ReplayGeoStatusTestDataRequest,
    TourPolylineResponse,
    TransportOrder,
    TransportOrderWithPolyline
} from "../../../../../generated";
import {TransportOrderTable} from "../../transportOrders/TransportOrderTable";
import {ResizeBox, ResizeBoxHorizontalPair} from "../../../../util/ResizeBox";
import {Accordion, Button, ButtonGroup, Icon, Menu} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import AceEditor from "react-ace";
import {TransportOrderMapComponent} from "../../transportOrders/rightSide/TransportOrderMap";
import {ConfigContext} from "../../../context/ConfigContext";
import {configStorage} from "../../../../../ConfigStorage";
import {TransportOrderSearch} from "../../transportOrders/TransportOrderSearch";
import { useHistory } from "react-router-dom";
import {LogView} from "../../logView/LogView";
import {updateConfig, workingConfig} from "../../configChange/ConfigSignal";

export interface GeostatusWorkbenchProps {
    location: any
    match: any
}

const GEO_STATUS_WORKBENCH_BEFORE_ORDERS = "GEO_STATUS_WORKBENCH_BEFORE_ORDERS"
const GEO_STATUS_WORKBENCH_AFTER_ORDERS = "GEO_STATUS_WORKBENCH_AFTER_ORDERS"
const GEO_STATUS_WORKBENCH_DUMP = "GEO_STATUS_WORKBENCH_DUMP"

export function GeostatusWorkbench(props: React.PropsWithChildren<GeostatusWorkbenchProps>) {

    const context = useContext(ConfigContext)
    const history = useHistory();

    const [auth, setAuth] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [xRequestId, setXRequestId] = useState("")

    const [activeTransportOrderSelector, setActiveTransportOrderSelector] = useState("simple")

    // Accordions
    const [openBeforeMap, setOpenBeforeMap] = useState(false)
    const [openAfterMap, setOpenAfterMap] = useState(false)
    const [openConfig, setOpenConfig] = useState(false)
    const [openTransportOrderSelection, setOpenTransportOrderSelection] = useState(true)
    const [openDumpInAce, setOpenDumpInAce] = useState(false)
    const [openTransportOrderSelectionAfterReplay, setOpenTransportOrderSelectionAfterReplay] = useState(true)

    const [transportOrders, setTransportOrders] = useState([])
    const [selectedTransportOrders, setSelectedTransportOrders] = useState([])
    const [selectedTransportOrder, setSelectedTransportOrder] = useState<TransportOrder>()

    const [dump, setDump] = useState<string>("")
    const [dumpDisplayPart, setDumpDisplayPart] = useState<string>("")
    const [dumpDisplayPartSelected, setDumpDisplayPartSelected] = useState<string>("Complete")

    const [newTransportOrdersWithPolyline, setNewTransportOrdersWithPolyline] = useState([])
    const [selectedNewTransportOrders, setSelectedNewTransportOrders] = useState([])
    const [selectedNewTransportOrder, setSelectedNewTransportOrder] = useState<TransportOrderWithPolyline>()


    useEffect(() => {

        if (!workingConfig.value) {
            const fetchData = async () => {
                let newConfig = await configStorage.getElement(context.companyId)
                let newConfigJson = JSON.stringify(newConfig.config, null, 4)
                await updateConfig(newConfigJson, false, false)
            }
            fetchData().catch((e) => {
                console.log(e)
            })

        }
        setAuth(backend.withTokenAuthHeader(authentication.token))

        let beforeOrders = localStorage.getItem(GEO_STATUS_WORKBENCH_BEFORE_ORDERS)
        if (beforeOrders && beforeOrders != "undefined") {
            setTransportOrders(JSON.parse(beforeOrders))
        }

        let afterOrders = localStorage.getItem(GEO_STATUS_WORKBENCH_AFTER_ORDERS)
        if (afterOrders && afterOrders != "undefined") {
            setNewTransportOrdersWithPolyline(JSON.parse(afterOrders))
        }

        setDump(localStorage.getItem(GEO_STATUS_WORKBENCH_DUMP))
        setDumpDisplayPart(localStorage.getItem(GEO_STATUS_WORKBENCH_DUMP))
        setDumpDisplayPartSelected("Complete")
    }, [])


    async function loadDump() {
        setLoading(true)

        try {

            let response = await backend.internalApi.fetchGeoStatusTestDataForTransportOrder(
                selectedTransportOrder._id,
                auth
            )

            processDump(response)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    function processDump(dump: any) {
        console.log("[processDump]");

        let companiesFromDump = dump.companyConfigurations.map((company: any) => {
            company["-configuration"] = JSON.parse(workingConfig.value)
            return company
        })
        console.log("[processDump] companiesFromDump", companiesFromDump);
        let transportOrders = dump.transportOrders
        let companyConfigs = companiesFromDump
        let vehiclesAndTrailers = dump.vehicles
        let allVehiclePositions = dump.positions

        let processedDump = {
            transportOrders: transportOrders,
            companyConfigurations: companyConfigs,
            vehicles: vehiclesAndTrailers,
            positions: allVehiclePositions
        }

        let processedDumpStr = JSON.stringify(processedDump, null, 4)
        setDump(processedDumpStr)
        localStorage.setItem(GEO_STATUS_WORKBENCH_DUMP, processedDumpStr)
        setDumpDisplayPart(JSON.stringify(processedDump, null, 4))
    }

    async function replayDump() {
        setLoading(true)
        try {

             let rawResponse = await fetch('/api/internal/dump/replayGeoStatusTestData', {
                method: 'POST',
                headers: {
                    "Authorization": auth.headers.Authorization.toString(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"dump": dump} as ReplayGeoStatusTestDataRequest)
                })

            setXRequestId(rawResponse.headers.get("x-request-id"))
            let response = await rawResponse.json()

            setNewTransportOrdersWithPolyline(response.ordersWithPolyline)
            localStorage.setItem(GEO_STATUS_WORKBENCH_AFTER_ORDERS, JSON.stringify(response.ordersWithPolyline, null, 4))

        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    //
    // Render parts
    //

    function renderTransportOrderQueryBox() {
        return <GeostatusWorkbenchTransportOrderQuery
            onUpdate={(queryResponse: QueryResponse) => {
                setTransportOrders(queryResponse.transportOrders)
                localStorage.setItem(GEO_STATUS_WORKBENCH_BEFORE_ORDERS, JSON.stringify(queryResponse.transportOrders, null, 4))
            }}
        />
    }

    function renderTransportOrderTable() {
        return <TransportOrderTable
            transportOrders={transportOrders}
            transportOrderAmount={transportOrders?.length}
            selectedOrderChanged={(newSelectedOrder) => {
                setSelectedTransportOrder(newSelectedOrder)
            }}
            selectedOrderIdsChanged={() => {
            }}
            reload={() => {
            }}
            initialPageSize={10}
        />
    }

    function renderTransportOrderTableForNewTransportOrders() {
        if (newTransportOrdersWithPolyline != null && newTransportOrdersWithPolyline.length > 0) {
            let element = <TransportOrderTable
                transportOrders={newTransportOrdersWithPolyline.map(owp => owp.order)}
                transportOrderAmount={newTransportOrdersWithPolyline?.length}
                selectedOrderChanged={(newSelectedOrder) => {
                    setSelectedNewTransportOrder(
                        newTransportOrdersWithPolyline.find(owp => owp.order._id == newSelectedOrder._id)
                    )
                }}
                selectedOrderIdsChanged={() => {
                }}
                reload={() => {
                }}
                initialPageSize={5}
            />

            return accordion(
                openTransportOrderSelectionAfterReplay,
                setOpenTransportOrderSelectionAfterReplay,
                "Select TransportOrder after replay",
                element
            )
        }
        return <></>
    }

    function renderQueryTransportOrderSelection() {
        return <ResizeBoxHorizontalPair
            leftWidthPercentage={0.5}
            height={350}
            leftContent={renderTransportOrderQueryBox()}
            rightContent={renderTransportOrderTable()}
        />
    }

    function renderSimpleTransportOrderSelection() {
        return <ResizeBox
            width={window.innerWidth -180}
            height={350}>
            <TransportOrderSearch
                selectedOrderIdsChanged={(newSelectedOrderIds) => {}}
                selectedOrderChanged={(newSelectedOrder) => setSelectedTransportOrder(newSelectedOrder)}
                foundTransportOrdersChanged={(newFoundOrders) => {
                    localStorage.setItem(GEO_STATUS_WORKBENCH_BEFORE_ORDERS, JSON.stringify(newFoundOrders, null, 4))
                    setTransportOrders(newFoundOrders)
                }}
                reload={(page, take) => {}}
                history={history}
                initialPageSize={5}
                hideArchiveButton={true}
                location={props.location}
                match={props.match}
            />
        </ResizeBox>
    }

    function renderTransportOrderSelection() {
        let element = <div><Menu attached='top' tabular>
            <Menu.Item
                name='simple'
                active={activeTransportOrderSelector === 'simple'}
                onClick={() => setActiveTransportOrderSelector("simple")}
            />
            <Menu.Item
                name='query'
                active={activeTransportOrderSelector === 'query'}
                onClick={() => setActiveTransportOrderSelector("query")}
            />
        </Menu>
            {activeTransportOrderSelector === 'simple' && renderSimpleTransportOrderSelection()}
            {activeTransportOrderSelector === 'query' && renderQueryTransportOrderSelection()}
        </div>
        return accordion(
            openTransportOrderSelection,
            setOpenTransportOrderSelection,
            "Select TransportOrder",
            element
        )
    }

    function renderDump() {
        let dumpObject = {
            transportOrders: [],
            companyConfigurations: [],
            vehicles: [],
            positions: []
        }
        if (dump != null && dump != "") {
            dumpObject = JSON.parse(dump)
        }

        let dumpElement = <>
            <div style={{marginBottom: "10px"}}>
            <Button
                style={{marginRight: "10px"}}
                disabled={selectedTransportOrder == null}
                loading={loading}
                onClick={loadDump}
            >
                load dump
            </Button>

            <Button
                disabled={dump == ""}
                loading={loading}
                onClick={replayDump}
            >
                replay dump
            </Button>
            </div>
            <ButtonGroup>
                <Button
                    primary={dumpDisplayPartSelected == "Complete"}
                    onClick={() => {
                        setDumpDisplayPart(JSON.stringify(dumpObject, null, 4))
                        setDumpDisplayPartSelected("Complete")
                    }}
                >Complete</Button>
                <Button
                    primary={dumpDisplayPartSelected == "transportOrders"}
                    onClick={() => {
                        setDumpDisplayPart(JSON.stringify(dumpObject.transportOrders, null, 4))
                        setDumpDisplayPartSelected("transportOrders")
                    }}
                >transportOrders</Button>
                <Button
                    primary={dumpDisplayPartSelected == "Configs"}
                    onClick={() => {
                        setDumpDisplayPart(JSON.stringify(dumpObject.companyConfigurations, null, 4))
                        setDumpDisplayPartSelected("Configs")
                    }}
                >Configs</Button>
                <Button
                    primary={dumpDisplayPartSelected == "vehicles"}
                    onClick={() => {
                        setDumpDisplayPart(JSON.stringify(dumpObject.vehicles, null, 4))
                        setDumpDisplayPartSelected("vehicles")
                    }}
                >vehicles</Button>
                <Button
                    primary={dumpDisplayPartSelected == "positions"}
                    onClick={() => {
                        setDumpDisplayPart(JSON.stringify(dumpObject.positions, null, 4))
                        setDumpDisplayPartSelected("positions")
                    }}
                >positions</Button>
            </ButtonGroup>
            <AceEditor
                theme="monokai"
                mode="json"
                value={dumpDisplayPart}
                placeholder={'Dump'}
                width="100%"
                height="900px"
            />

        </>

        let logElement = <LogView
            query={xRequestId}
            origin={"GeoStatusWorkbench"}
        />

        let element = <ResizeBoxHorizontalPair
            leftWidthPercentage={0.5}
            height={550}
            leftContent={dumpElement}
            rightContent={logElement}
        />
        return accordion(openDumpInAce, setOpenDumpInAce, "Dump", element)
    }

    function renderMap(
        isOpen: boolean,
        setOpen: (open) => void,
        name: string,
        order: TransportOrder,
        polyline: TourPolylineResponse | null,
        predefinedPolyline: boolean = false
    ) {
        let element = <TransportOrderMapComponent
            order={order}
            polyline={polyline}
            predefinedPolyline={predefinedPolyline}
        />

        return accordion(isOpen, setOpen, name, element)
    }

    function renderBeforeMap() {
        return selectedTransportOrder != null && renderMap(
            openBeforeMap,
            setOpenBeforeMap,
            "Before Map",
            selectedTransportOrder,
            null
        )
    }

    function renderAfterMap() {
        return selectedNewTransportOrder != null && renderMap(
            openAfterMap,
            setOpenAfterMap,
            "After Map",
            selectedNewTransportOrder.order,
            selectedNewTransportOrder.polyline,
            true
        )
    }

    function accordion(isOpen: boolean, setOpen: (open) => void, name: string, element: any) {
        return <Accordion>
            <Accordion.Title
                active={isOpen}
                index={0}
                onClick={() => {
                    if (isOpen) {
                        setOpen(false)
                    } else {
                        setOpen(true)
                    }
                }}
            >
                <Icon name='dropdown'/>
                {name}
            </Accordion.Title>
            <Accordion.Content active={isOpen}>
                {element}
            </Accordion.Content>
        </Accordion>
    }

    return <>

        {renderTransportOrderSelection()}

        {renderDump()}

        {renderBeforeMap()}

        {renderTransportOrderTableForNewTransportOrders()}

        {renderAfterMap()}

    </>

}
