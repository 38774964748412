import * as React from "react";
import { useState, useEffect } from 'react';
import { Icon, Input, Label, Popup, Segment, Table } from "semantic-ui-react";
import { v4 as uuid } from 'uuid';

export interface ExportSimLisDocTableProps {
    output: string,
    mode: "WinLager" | "WinSped",
    encoding: string
}

export class Date {
    year: number
    month: number
    day: number
}

export function ExportSimLisDocTable(props: React.PropsWithChildren<ExportSimLisDocTableProps>) {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [badFormat, setBadFormat] = useState(true);
    const [filters, setFilters] = useState([]);
    const [columnAmount, setColumnAmount] = useState(0);

    // Replace componentDidMount and componentWillReceiveProps with useEffect
    useEffect(() => {
        convertDataToArray().then(r => {});
    }, [props]);

    async function convertDataToArray() {
        let lisData = props.output

        try {
            let xReqId = uuid()
            let resp = await fetch('/converter/lisDocToRecordsWithDescription?mode=' + props.mode, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    'x-request-id': xReqId,
                    'encoding': props.encoding
                },
                body: lisData
            }).then((resp) => {
                return resp.json()
            })
            console.log("INFO RESPONSE: ", resp)


            let columnAmount = 0;

            resp.map((line: { map: { [s: string]: unknown; } | ArrayLike<unknown>; }) => {
                let length = Object.values(line.map).length
                if (columnAmount < length) {
                    columnAmount = length
                }
            })

            await filter(resp)
            setColumnAmount(columnAmount)
            setData(resp)
            setBadFormat(false)
        } catch (ex) {
            setBadFormat(true)
        }
    }

    async function filter(data) {

        let filtered = data

        for (let i = 0; i < columnAmount; i++) {
            let f = filters[i]
            if (f && f != "") {
                filtered = filtered.filter(d => {
                    let record = Object.values(d.recordDescription)[i]
                    if (record == undefined) {
                        return false
                    }
                    // @ts-ignore
                    let value = d.map[record.name]
                    if (value == undefined) {
                        return false
                    }

                    let formattedValue = formatValue(value)
                    if (formattedValue == "") {
                        return false
                    }

                    try {
                        let match = formattedValue?.match(f)
                        return match != null
                    } catch (e) {
                        return false
                    }

                })
            }

        }
        setFilteredData(filtered)
    }

    function filterChange(i, newValue) {
        let newFilters = filters
        newFilters[i] = newValue
        setFilters(newFilters)
        filter(data)

    }

    function filterRow() {
        let fields = []

        for (let i = -1; i < columnAmount; i++) {
            if (i == -1) {
                fields.push(
                    <Table.Cell key={"f#" + i} selectable style={{padding: '10px'}}>
                    </Table.Cell>)
            } else {
                fields.push(
                    <Table.Cell key={"f#" + i} selectable style={{padding: '0px'}}>
                        <Popup content={filters[i]} trigger={
                            <Input
                                label={<Label onClick={() => {
                                    filterChange(i, "")
                                }}><Icon name='trash'/></Label>}
                                labelPosition={"right corner"}
                                fluid
                                key={'filter#' + i}
                                name="filter"
                                style={{minWidth: '80px', padding: '0px', color: 'blue'}}
                                value={filters[i]}
                                onChange={evt => filterChange(i, evt.currentTarget.value)}/>
                        }/>
                    </Table.Cell>)
            }
        }


        return <Table.Row>
            {fields}
        </Table.Row>
    }

    function formatValue(value) {

        if (value.toString() != "null" && value.toString() != "undefined" && value.toString() != "") {


            if (typeof value === "object" && value?.length > 0) {
                // Value has been found and is not null or empty.
                // Since the Info-fields are unique, there only can be one match
                value = value[0]

            }

            if (typeof value === "object") {
                //check if the value is a date (dates are coming as an object, not as a string)
                if (Object.keys(value).includes("year")) {
                    let date: Date = value
                    return date.day + "." + date.month + "." + date.year
                }

            }


            if (typeof value === "boolean") {
                if (value) {
                    value = "J"
                } else {
                    value = "N"
                }
            }

            return value

        }
        return ""
    }

    function renderTable() {

        let lineNr = 0
        let data = filteredData
        let recordDescription = []
        let map = []


        try {
            return <Segment style={{overflow: 'scroll', maxWidth: '100%', maxHeight: '650px'}}>
                <Table celled striped singleLine>
                    <Table.Body>
                    {filterRow()}
                    {data.map((line) => {

                            // recordDescription contains all names of the fields, these will be the info
                            recordDescription = line.recordDescription

                            // map contains all values, but as an object, that only has the fields that are filled
                            map = line.map

                            // building up the table based on recordDescriptions, since they are complete and independent of values
                            if (recordDescription !== undefined) {
                                lineNr = lineNr + 1
                                return <Table.Row key={lineNr}>
                                    <Table.Cell selectable style={{padding: '10px'}}>
                                        {lineNr}
                                    </Table.Cell>
                                    {
                                        Object.values(recordDescription).map((entry) => {
                                            let info = entry.name

                                            // The map contains the values, with the info as fieldName.
                                            // JsonPath is used to query these values without reflection.
                                            let jp = require('jsonpath');
                                            let jsonPath = '$.' + info
                                            let value = jp.query(map, jsonPath)

                                            value = formatValue(value)

                                            if (info !== undefined) {
                                                // put the info in the popup and the value in the cell
                                                return <Popup
                                                    key={info}
                                                    content={info}
                                                    trigger={
                                                        <Table.Cell selectable style={{padding: '10px'}}>
                                                            {value}
                                                        </Table.Cell>
                                                    }
                                                />
                                            }
                                        })
                                    }
                                </Table.Row>
                            }

                        })}

                </Table.Body></Table></Segment>
        } catch (ex) {
            console.log("error", ex)
            return <Label color='red'>The data is in bad format.</Label>

        }
    }

    function render() {
        if (data === undefined || data?.length <= 1) {
            return <h3>LOADING</h3>

        } else {
            if (badFormat) {
                console.log("BAD FORMAT")
                return <Label color='red'>The data is in bad format.</Label>
            } else {
                return renderTable()
            }
        }
    }
    
    return render();

}