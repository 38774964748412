import * as React from "react";
import {ChangeEvent, Position, QueryResponse, TransportOrderChange} from "../../../../generated";
import {Header, Icon, Modal} from "semantic-ui-react";
import {IfBox} from "../../../style/if";
import AceEditor from "react-ace";
import ReactTable from "react-table";
import {
    determineDotColorForTransportOrder,
    EtaDateTimeDisplay,
    formatFreight,
    formatTransport,
    formatTransportOrderStatus,
    getNextStopByEta
} from "../transportOrders/Formatters";
import {formatDateFromStringNoTime, isActive} from "../../../../format";
import TypeEnum = TransportOrderChange.TypeEnum;

export interface DbQueryResultTableProps {
    queryResponse: QueryResponse
    updatePagination: (
        take: number,
        page: number,
        sortBy: string,
        sortDirection: 'ASC' | 'DESC',
    ) => void
    collection: string
}

export interface DbQueryResultTableState {

    //table
    isLoading: boolean,
    take: number,
    page: number,
    sortBy: string,
    sortDirection: 'ASC' | 'DESC',
    count: number
    workingCopy: string
    showRow: boolean


}

export class DbQueryResultTable extends React.Component<DbQueryResultTableProps, DbQueryResultTableState> {


    constructor(props) {

        super(props)
        console.log("loading DbQueryResultTable")

        this.state = {

            //table
            count: -1,
            isLoading: false,
            take: 25,
            page: 1,
            sortBy: '_id',
            sortDirection: 'DESC',
            workingCopy: "",
            showRow: false,


        }

    }

    render() {
        return <>

            {this.renderTable()}

            <IfBox shouldShow={this.state.workingCopy != null}>
                {this.renderPopup()}
            </IfBox>

        </>
    }

    async queryExecuteCall(take = this.state.take, page = this.state.page) {
        this.props.updatePagination(take, page, this.state.sortBy, this.state.sortDirection)
    }

    formatDate(date: Date | null): string {
        if (date) {
            return new Date(date).toLocaleString()
        }
        return ""
    }

    tabData() {
        if (this.props.queryResponse != null && this.props.queryResponse.collectionWithPrivateFields != null) {

            let list = this.props.queryResponse.collectionWithPrivateFields.map(x => JSON.parse(x));

            return list

        } else {
            switch (this.props.collection) {

                case "Companies": {
                    console.log(this.props.queryResponse.companies)
                    return this.props.queryResponse.companies

                    break;
                }

                case "TransportOrders": {
                    return this.props.queryResponse.transportOrders

                    break;
                }

                case "History": {
                    return this.props.queryResponse.history

                    break;
                }

                case "Vehicles": {
                    return this.props.queryResponse.vehicles

                    break;
                }

                case"QueuedEvents": {
                    return this.props.queryResponse.queuedEvents

                    break;
                }

                case"Drivers": {
                    return this.props.queryResponse.drivers

                    break;
                }

                case"Queues": {
                    return this.props.queryResponse.queues

                    break;
                }

                case"Users": {
                    return this.props.queryResponse.users

                    break;
                }
                case"UserRoles": {
                    return this.props.queryResponse.userRoles

                    break;
                }

                case"SystemStatusMessage": {
                    return this.props.queryResponse.systemStatusMessage

                    break;
                }

                case "SchedulerTaskConfigs": {
                    return this.props.queryResponse.schedulerTaskConfigs

                    break;
                }
                case "ProjectWorkLogBookEntries": {
                    return this.props.queryResponse.projectWorkLogBookEntries

                    break;
                }

                case "Partners": {
                    return this.props.queryResponse.partners

                    break;
                }

                case "MessageLog": {
                    return this.props.queryResponse.messageLog

                    break;
                }

                case "CompanyAddressMappingGroups": {
                    return this.props.queryResponse.companyAddressMappingGroups

                    break;
                }

                case "CompanyAddressMappings": {
                    return this.props.queryResponse.companyAddressMappings

                    break;
                }

                case "CompanyFreightMapping": {
                    return this.props.queryResponse.companyFreightMapping

                    break;
                }

                case "CompanyFreightMappingGroups": {
                    return this.props.queryResponse.companyFreightMappingGroups

                    break;
                }

                case "CompanyHistory": {
                    return this.props.queryResponse.companyHistory

                    break;
                }

                case "CompanyConfigurationDraft": {
                    return this.props.queryResponse.companyConfigurationDraft

                    break;
                }
                case "ChangeEvents": {
                    return this.props.queryResponse.changeEvents

                    break;
                }
                case "Positions": {
                    return this.props.queryResponse.positions

                    break;
                }


                default:
                    return null

            }
        }

    }

    renderTable() {
        const columns = this.tabColumns()

        if (this.tabData() == null) {
            return <p>Loading</p>
        } else {

            return <ReactTable
                manual
                data={this.tabData()}
                pages={Math.ceil(this.state.count / this.state.take)}
                columns={columns}
                sorted={[
                    {
                        id: '_id',
                        desc: false
                    }
                ]}
                onSortedChange={(newSorted, column, shiftKey) => {
                    this.changeSort(column.sortField)
                }}

                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        onClick: () => {
                            if (rowInfo) {
                                this.setState({
                                    workingCopy: JSON.stringify(rowInfo.row._original, null, 4),
                                    showRow: true,
                                })
                            }
                        }
                    };
                }}

                defaultPageSize={this.state.take}
                className="-striped -highlight"
                loading={this.state.isLoading}
                style={{cursor: "pointer"}}
                onPageChange={(pageIndex) => {
                    this.changePage(pageIndex)
                }}
                onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}
            />
        }
    }

    renderPopup() {
        return <Modal
            open={this.state.showRow}
            onClose={() => this.setState({showRow: false, workingCopy: null})}
            dimmer="blurring"
            size='fullscreen'>
            <Header content={this.props.collection}/>
            <Modal.Content>
                <AceEditor
                    theme="monokai"
                    mode='groovy'
                    value={this.state.workingCopy}
                    placeholder='Query response'
                    width="100%"
                    height="1000px"
                    readOnly={true}
                />
            </Modal.Content>
        </Modal>
    }

    renderParams() {
        return <Modal
            open={this.state.showRow}
            onClose={() => this.setState({showRow: false, workingCopy: null})}
            dimmer="blurring"
            size='fullscreen'>
            <Header content={this.props.collection}/>
            <Modal.Content>
                <AceEditor
                    theme="monokai"
                    mode='groovy'
                    value={this.state.workingCopy}
                    placeholder='Query response'
                    width="100%"
                    height="1000px"
                    readOnly={true}
                />
            </Modal.Content>
        </Modal>
    }


    changePage(pageIndex: number) {
        this.setState({isLoading: true})
        this.queryExecuteCall(this.state.take, pageIndex + 1)
    }

    changePageSize(newSize: number) {
        this.setState({isLoading: true})
        this.queryExecuteCall(newSize, this.state.page)
    }

    async changeSort(sortBy: string) {
        console.log("sort clicked!!!")
        if (sortBy == 'NONE') {
            return
        }
        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({sortDirection: direction})
        } else {
            //change sortBy and reset direction
            this.setState({sortBy: sortBy, sortDirection: "DESC"})
        }

        //refresh
        this.queryExecuteCall()
    }

    formatList(list: string[] | null) {
        let str = ""
        if (list == null) {
            return null
        }
        list.forEach(value => {
            if (str == "") {
                str = value
            } else {
                str = str + ", " + value
            }
        })
        return str
    }

    tabColumns() {
        switch (this.props.collection) {
            case"Companies": {
                return [
                    {
                        id: 'NAME',
                        Header: 'NAME',
                        width: 210,
                        accessor: (d: any) => d.name,
                        sortField: 'name'
                    },
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    }, {
                        id: 'TAG',
                        Header: 'TAG',
                        width: 200,
                        accessor: (d: any) => d.tag,
                        sortField: 'tag'
                    },
                    {
                        id: 'isShipmentCreateFormCompany',
                        Header: 'IsShipmentCreateFormCompany',
                        width: 200,
                        accessor: (d: any) => d.isShipmentCreateFormCompany,
                        sortField: 'isShipmentCreateFormCompany'
                    },
                ]
                break;
            }

            case "TransportOrders": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    }, {
                        id: 'status',
                        Header: 'Status',
                        accessor: (d: any) => formatTransportOrderStatus(d.status),
                        sortField: 'status'
                    },
                    {
                        id: 'externalTransportOrderId',
                        sortField: 'externalTransportOrderId',
                        Header: 'ExternalOrderNo',
                        accessor: (d: any) => d.externalTransportOrderId,
                        Cell: (props: any) => <span className='number'>{props.value}</span> // Custom cell components!

                    }, {
                        id: 'created', // Required because our accessor is not a string
                        sortField: 'created',
                        Header: 'Created',
                        width: 140,
                        accessor: (d: any) => this.formatDate(d.created) // Custom value accessors!
                    },
                    {
                        id: 'planStart',
                        Header: 'PlanStart',
                        width: 140,
                        accessor: (d: any) => this.formatDate(d.planStart),
                    },
                    {
                        id: 'planEnd',
                        Header: 'PlanEnd',
                        width: 140,
                        accessor: (d: any) => this.formatDate(d.planEnd),
                    },
                    {
                        id: 'targetCompanyName',
                        Header: 'Contractor',
                        accessor: (d: any) => d.targetCompanyName
                    },
                    {
                        id: 'is_subchartered',
                        Header: "Via Sub?",
                        sortable: false,
                        accessor: (d: any) => d.linkInfo != null && d.linkInfo.targetTransportOrderId != null ? "☑" : ""
                    },
                    {
                        id: 'observer',
                        sortable: false,
                        Header: 'Observer',
                        accessor: (d: any) => d.observer && d.observer.length > 0 ? "☑ " + d.observer.length : ""
                    },
                    {
                        id: 'original_source_company',
                        sortable: false,
                        Header: 'Consignor',
                        accessor: (d: any) => d.linkInfo != null ? d.linkInfo.sourceCompanyName : ""
                    },
                    {
                        id: 'companyName',
                        Header: 'Client',
                        accessor: (d: any) => d.companyName
                    },
                    {
                        id: 'vehicle',
                        Header: 'Vehicle',
                        accessor: (d: any) => {
                            if (d.assignedVehicle != null && d.assignedVehicle.numberPlate != null) {
                                return d.assignedVehicle.numberPlate
                            } else if (d.assignedDevice != null && d.assignedDevice.verboseName != null) {
                                return d.assignedDevice.verboseName
                            }
                            return ""
                        }
                    },
                    {
                        id: 'driver',
                        Header: 'Driver',
                        accessor: (d: any) => {
                            if (d.assignedDriver != null && (d.assignedDriver.firstName != null || d.assignedDriver.firstName != null)) {
                                return `${d.assignedDriver.firstName} ${d.assignedDriver.lastName}`
                            } else if (d.assignedDriver != null && d.assignedDriver.externalDriverId != null) {
                                return d.assignedDriver.externalDriverId
                            }
                            return ""
                        }
                    },
                    {
                        id: 'trailer',
                        Header: 'Trailer',
                        accessor: (d: any) => {
                            if (d.assignedTrailer?.numberPlate != null) {
                                return d.assignedTrailer.numberPlate
                            } else if (d.assignedTrailer?.externalVehicleId != null) {
                                return d.assignedTrailer.externalVehicleId
                            }
                            return ""
                        }
                    },
                    {
                        id: 'eta',
                        Header: 'NextETA',
                        sortable: false,
                        width: 135,
                        accessor: (d: any) => {
                            let nextEta = getNextStopByEta(d)

                            if (nextEta?.eta) {
                                return <EtaDateTimeDisplay eta={nextEta.eta}/>
                            }
                            return ""
                        },
                        Cell: (row: any) => (
                            <span>
                    <span style={{
                        color: determineDotColorForTransportOrder(row.original),
                        transition: 'all .3s ease'
                    }}>&#x25cf;</span>
                                {row.value}
                </span>
                        )
                    },
                    {
                        id: 'from',
                        Header: 'Stops',
                        sortable: false,
                        accessor: (d: any) => d.transports != null && formatTransport(d.transports)
                    },
                    {
                        id: 'freight',
                        sortable: false,
                        Header: 'Freight',
                        accessor: (d: any) => d.freight != null && formatFreight(d.freight)
                    },
                    {
                        id: 'assignedDeviceMail',
                        sortable: false,
                        visible: false,
                        Header: 'AssignedDeviceMail',
                        accessor: (d: any) => d.assignedDevice?.emailAddress
                    },
                    {
                        id: 'assignedDevicePhone',
                        sortable: false,
                        visible: false,
                        Header: 'AssignedDevicePhone',
                        accessor: (d: any) => d.assignedDevice?.phoneNr
                    }
                ]
                break;
            }

            case "History": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'TIMESTAMP',
                        Header: 'timestamp',
                        width: 150,
                        accessor: (d: any) => d.timestamp,
                        Cell: (d: any) => {
                            return <span>{this.formatDate(d.value)}</span>
                        },
                        sortMethod: (a, b) => {
                            var a1 = new Date(a).getTime();
                            var b1 = new Date(b).getTime();
                            if (a1 < b1) {
                                return 1;
                            } else if (a1 > b1) {
                                return -1;
                            } else {
                                return 0;
                            }
                        },
                        sortField: 'timestamp'

                    }, {
                        id: 'CHANGED_BY',
                        Header: 'changedBy',
                        width: 200,
                        accessor: (d: any) => d.changedBy,
                        sortField: 'changedBy'
                    }, {
                        id: 'COMMENT',
                        Header: 'comment',
                        accessor: (d: any) => d.changeComment,
                        sortField: 'changeComment'
                    }]

                break;
            }

            case "Vehicles": {

                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'companyId',
                        Header: 'companyId',
                        width: 210,
                        accessor: (d: any) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'externalVehicleId',
                        Header: 'externalVehicleId',
                        width: 210,
                        accessor: (d: any) => d.externalVehicleId,
                        sortField: 'externalVehicleId'
                    },
                    {
                        id: 'numberPlate',
                        Header: 'numberPlate',
                        width: 210,
                        accessor: (d: any) => d.numberPlate,
                        sortField: 'numberPlate'
                    },
                    {
                        id: 'vehicleType',
                        Header: 'vehicleType',
                        width: 210,
                        accessor: (d: any) => d.vehicleType,
                        sortField: 'vehicleType'
                    },
                ]

                break;
            }

            case"QueuedEvents": {
                return [
                    {
                        id: 'queueType',
                        Header: 'queueType',
                        width: 150,
                        accessor: (d: any) => d.queueType,
                        sortField: 'queueType'
                    }, {
                        id: 'eventType',
                        Header: 'eventType',
                        width: 200,
                        accessor: (d: any) => d.eventType,
                        sortField: 'eventType'
                    },
                    {
                        id: 'orderId',
                        Header: 'Order ID',
                        width: 100,
                        accessor: (d: any) => d.transportOrderEvent?.transportOrder?._id ?? d.positionEvent?.transportOrderId ?? d.etaEvent?.transportOrder?._id,
                        Cell: (d: any) => {
                            return <span>{d.value}</span>
                        }
                    },
                    {
                        id: 'changeType',
                        Header: 'Change Type',
                        width: 150,
                        accessor: (d: any) => d.transportOrderEvent?.change?.type ?? "",
                        Cell: (d: any) => {
                            return <span>{d.value}</span>
                        }
                    },
                    {
                        id: 'status',
                        Header: 'Change Value',
                        width: 150,
                        accessor: (d: any) => {
                            let obj = d.transportOrderEvent?.change?.modifiedObject
                            switch (d.transportOrderEvent?.change?.type) {
                                case TransportOrderChange.TypeEnum.STATUSUPDATEFREIGHT:
                                    return obj.freight?.status?.statusCode?.toString()
                                case TypeEnum.STATUSUPDATETRANSPORTORDER:
                                    return obj.transportOrder?.status?.statusCode?.toString()
                                case TypeEnum.STATUSUPDATESTOPLOCATION:
                                    return obj.stop?.status?.statusCode?.toString()
                                case TypeEnum.STATUSUPDATESTOPACTION:
                                    return obj.action?.status?.statusCode?.toString()
                                case TypeEnum.ASSIGNCOMPANY:
                                    return d.transportOrderEvent?.transportOrder?.targetCompanyName
                                case TypeEnum.ATTACHMENTADD:
                                    return obj.attachment?.name
                                case TypeEnum.ASSIGNVEHICLE:
                                    return d.transportOrderEvent?.transportOrder?.assignedVehicle?.numberPlate
                                case TypeEnum.ASSIGNTRAILER:
                                    return d.transportOrderEvent?.transportOrder?.assignedTrailer?.numberPlate
                                case TypeEnum.ASSIGNDEVICE:
                                    let dev = d.transportOrderEvent?.transportOrder?.assignedDevice
                                    return dev.emailAddress ?? dev.phoneNr
                                default:
                                    return ""
                            }

                        },
                        Cell: (d: any) => {
                            return <span>{d.value}</span>
                        }
                    },
                    {
                        id: 'timestamp',
                        Header: 'timestamp',
                        width: 150,
                        accessor: (d: any) => d.timestamp,
                        Cell: (d: any) => {
                            return <span>{this.formatDate(d.value)}</span>
                        },
                        sortField: 'timestamp'
                    }, {
                        id: 'rateLimited',
                        Header: 'rateLimited',
                        width: 100,
                        accessor: (d: any) => {
                            if (d.rateLimited) {
                                return <span>true</span>
                            } else {
                                return <span>false</span>
                            }
                        },
                        sortField: 'rateLimited'
                    }, {
                        id: 'suppressed',
                        Header: 'suppressed',
                        width: 100,
                        accessor: (d: any) => {
                            if (d.suppressed) {
                                return <span>true</span>
                            } else {
                                return <span>false</span>
                            }
                        },
                        sortField: 'suppressed'
                    }, {
                        id: 'processed',
                        Header: 'processed',
                        width: 100,
                        accessor: (d: any) => {
                            if (d.processed) {
                                return <span>true</span>
                            } else {
                                return <span>false</span>
                            }
                        },
                        sortField: 'processed'
                    }, {
                        id: 'processingState',
                        Header: 'processingState',
                        width: 120,
                        accessor: (d: any) => d.processingInfo?.state,
                        sortField: 'processingInfo.state'
                    }, {
                        id: 'ID',
                        Header: 'ID',
                        width: 200,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    }, {
                        id: 'queueId',
                        Header: 'queueId',
                        width: 200,
                        accessor: (d: any) => d.queueId,
                        sortField: 'queueId'
                    }
                ]
                break;
            }

            case "Drivers": {

                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'CompanyID',
                        Header: 'Company Id',
                        width: 210,
                        accessor: (d: any) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'firstName',
                        Header: 'firstName',
                        width: 210,
                        accessor: (d: any) => d.firstName,
                        sortField: 'firstName'
                    },
                    {
                        id: 'lastName',
                        Header: 'lastName',
                        width: 210,
                        accessor: (d: any) => d.lastName,
                        sortField: 'lastName'
                    },
                    {
                        id: 'contactPhoneNr',
                        Header: 'contactPhoneNr',
                        width: 210,
                        accessor: (d: any) => d.contactPhoneNr,
                        sortField: 'contactPhoneNr'
                    },
                ]

                break;
            }

            case"Queues": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    }, {
                        id: 'Company Id',
                        Header: 'companyId',
                        width: 210,
                        accessor: (d: any) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'NAME',
                        Header: 'NAME',
                        width: 210,
                        accessor: (d: any) => d.name,
                        sortField: 'name'
                    },
                    {
                        id: 'TYPE',
                        Header: 'TYPE',
                        width: 210,
                        accessor: (d: any) => d.type,
                        sortField: 'type'
                    },
                    {
                        id: 'enabled',
                        Header: 'enabled',
                        width: 210,
                        accessor: (d: any) => d.enabled,
                        sortField: 'enabled'
                    },
                ]

                break;
            }

            case"Users": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    }, {
                        id: 'firstName',
                        Header: 'firstName',
                        width: 210,
                        accessor: (d: any) => d.firstName,
                        sortField: 'firstName'
                    },
                    {
                        id: 'lastName',
                        Header: 'lastName',
                        width: 210,
                        accessor: (d: any) => d.lastName,
                        sortField: 'lastName'
                    },
                    {
                        id: 'companyId',
                        Header: 'companyId',
                        width: 210,
                        accessor: (d: any) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'state',
                        Header: 'state',
                        width: 210,
                        accessor: (d: any) => d.state,
                        sortField: 'state'
                    },
                ]

                break;
            }

            case"UserRoles": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    }, {
                        id: 'name',
                        Header: 'name',
                        width: 210,
                        accessor: (d: any) => d.name,
                        sortField: 'name'
                    },
                    {
                        id: 'companyId',
                        Header: 'companyId',
                        width: 210,
                        accessor: (d: any) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'permissions',
                        Header: 'permissions',
                        width: 300,
                        accessor: (d: any) => {
                            return this.formatList(d.permissions)
                        },
                        sortField: 'NONE'
                    },
                ]

                break;
            }

            case "SystemStatusMessage": {
                return [
                    {
                        id: 'Active',
                        Header: 'Active',
                        width: 50,
                        accessor: (d: any) => d,
                        Cell: (d: any) => {
                            if (d.value?.startTime != null && d.value?.startTime != undefined) {
                                return <span>{isActive(d.value.startTime, d.value.endTime)}</span>
                            } else {
                                return <span>unreadable</span>
                            }
                        },
                        sortField: 'ACTIVE'
                    }, {
                        id: 'ID',
                        Header: 'ID',
                        width: 30,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    }, {
                        id: 'forCustomerToSee',
                        Header: 'seen by',
                        width: 100,
                        accessor: (d: any) => {
                            if (d.forCustomerToSee) {
                                return <span>customers</span>
                            } else {
                                return <span>superUser</span>
                            }
                        },
                        sortField: 'forCustomerToSee'
                    }, {
                        id: 'title',
                        Header: 'title',
                        width: 250,
                        accessor: (d: any) => {
                            if (d.message) {
                                return d.message.title
                            } else {
                                return <span>unreadable</span>
                            }
                        },
                        sortField: 'title'
                    }, {
                        id: 'startTime',
                        Header: 'Start Time',
                        width: 150,
                        accessor: (d: any) => d.startTime,
                        Cell: (d: any) => {
                            return <span>{this.formatDate(d.value)}</span>
                        },
                        sortField: 'startTime'
                    }, {
                        id: 'endTime',
                        Header: 'End Time',
                        width: 150,
                        accessor: (d: any) => d.endTime,
                        Cell: (d: any) => {
                            return <span>{this.formatDate(d.value)}</span>
                        },
                        sortField: 'endTime'
                    }, {
                        id: 'createdAt',
                        Header: 'CreatedAt',
                        width: 150,
                        accessor: (d: any) => d.createdAt,
                        Cell: (d: any) => {
                            return <span>{this.formatDate(d.value)}</span>
                        },
                        sortField: 'CREATED_AT'
                    }, {
                        id: 'createdBy',
                        Header: 'CreatedBy',
                        width: 150,
                        accessor: (d: any) => {
                            if (d.createdBy != null && d.createdBy != undefined) {
                                return d.createdBy
                            } else {
                                return ""
                            }
                        },
                        Cell: (d: any) => {
                            return d.value
                        },
                        sortField: 'createdBy'
                    }, {
                        id: 'level',
                        Header: 'Level',
                        width: 100,
                        accessor: (d: any) => d.level,
                        sortField: 'level'
                    }, {
                        id: 'message',
                        Header: 'Message',
                        width: 500,
                        accessor: (d: any) => {
                            if (d.message) {
                                return d.message.message
                            } else {
                                return <span>unreadable</span>
                            }
                        },
                        sortField: 'message'
                    }, {
                        id: 'affectedTransportOrders',
                        Header: 'Affected TransportOrders',
                        width: 300,
                        accessor: (d: any) => {
                            return this.formatList(d.affectedTransportOrders)
                        },
                        sortField: 'NONE'
                    }, {
                        id: 'lastOccurrence',
                        Header: 'lastOccurrence',
                        width: 200,
                        accessor: (d: any) => {
                            return this.formatDate(d.lastOccurrence)
                        },
                        sortField: 'NONE'
                    }, {
                        id: 'groupKey',
                        Header: 'groupKey',
                        width: 200,
                        accessor: (d: any) => {
                            return d.groupKey
                        },
                        sortField: 'NONE'
                    }, {
                        id: 'isRelevantForLogeniosInfrastructureMonitoring',
                        Header: 'isRelevantForLogeniosInfrastructureMonitoring',
                        width: 300,
                        accessor: (d: any) => {
                            if (d.isRelevantForLogeniosInfrastructureMonitoring) {
                                return "true"
                            } else {
                                return "false"
                            }
                        },
                        sortField: 'NONE'
                    }

                ]

                break;
            }

            case "SchedulerTaskConfigs": {
                return [
                    {
                        id: 'taskName',
                        Header: 'taskName',
                        width: 300,
                        accessor: (d: any) => d.taskName,
                        sortField: 'taskName'
                    }, {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    }, {
                        id: 'enabled',
                        Header: 'enabled',
                        width: 100,
                        accessor: (d: any) => {
                            if (d.enabled) {
                                return <Icon name='check' color='green' size='large'/>
                            } else {
                                return <Icon name='x' color='red' size='large'/>
                            }
                        },
                        sortField: 'enabled'
                    }, {
                        id: 'state',
                        Header: 'state',
                        width: 100,
                        accessor: (d: any) => {
                            if (d.state == "SUCCESS") {
                                return <Icon id="success" size='big' name='check square outline' color='green'/>
                            } else {
                                if (d.state == "STARTED") {
                                    return <Icon id="started" size='big' name='ellipsis horizontal' color='yellow'/>
                                } else {
                                    return <Icon id="failed" size='big' name='times' color='red'/>
                                }
                            }
                        },
                        sortField: 'state'
                    }, {
                        id: 'intervalInSeconds',
                        Header: 'intervalInSeconds',
                        width: 200,
                        accessor: (d: any) => d.intervalInSeconds,
                        sortField: 'intervalInSeconds'
                    }, {
                        id: 'lastRun',
                        Header: 'lastRun',
                        width: 200,
                        accessor: (d: any) => d.lastRun,
                        Cell: (d: any) => {
                            return <span>{this.formatDate(d.value)}</span>
                        },
                        sortField: 'lastRun'
                    }, {
                        id: 'nextRun',
                        Header: 'nextRun',
                        width: 150,
                        accessor: (d: any) => d.nextRun,
                        Cell: (d: any) => {
                            return <span>{this.formatDate(d.value)}</span>
                        },
                        sortField: 'nextRun'
                    }
                ]

                break;
            }

            case "ProjectWorkLogBookEntries": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'CREATED_DATE',
                        Header: 'createDate',
                        width: 150,
                        accessor: (d: any) => formatDateFromStringNoTime(d.createdDate),
                        sortField: 'createdDate'
                    }, {
                        id: 'SHORT_DESCRIPTION',
                        Header: 'shortDescription',
                        accessor: (d: any) => (d.shortDescription),
                        sortField: 'shortDescription'
                    }, {
                        id: 'BILLING_MODE',
                        Header: 'billingMode',
                        accessor: (d: any) => (d.billingMode),
                        sortField: 'BILLING_MODE'
                    }, {
                        id: 'TIME_SPENT_ON_TASK',
                        Header: 'timeSpentOnTask',
                        accessor: (d: any) => (d.timeSpentOnTask),
                        sortField: 'billingMode'
                    }, {
                        id: 'BILLING_COST',
                        Header: 'billingCost',
                        accessor: (d: any) => (d.billingCost),
                        sortField: 'billingCost'
                    }, {
                        id: 'UserFirstName',
                        Header: 'User FirstName',
                        accessor: (d: any) => d.userFirstName,
                        sortField: 'userName'
                    }
                ]

                break;
            }

            case "Partners": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'CompanyID',
                        Header: 'CompanyID',
                        width: 210,
                        accessor: (d: any) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'PartnerCompanyID',
                        Header: 'PartnerCompanyID',
                        width: 210,
                        accessor: (d: any) => d.partnerCompanyId,
                        sortField: 'partnerCompanyId'
                    },
                    {
                        id: 'CreationTime',
                        Header: 'CreationTime',
                        width: 210,
                        accessor: (d: any) => formatDateFromStringNoTime(d.creationTime),
                        sortField: 'creationTime'
                    },
                ]

                break;
            }

            case "MessageLog": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'SendSuccessfully',
                        Header: 'SendSuccessfully',
                        width: 210,
                        accessor: (d: any) => d.sendSuccessfully,
                        sortField: 'sendSuccessfully'
                    },
                    {
                        id: 'SendTime',
                        Header: 'SendTime',
                        width: 210,
                        accessor: (d: any) => formatDateFromStringNoTime(d.sendTime),
                        sortField: 'sendTime'
                    },
                ]

                break;
            }

            case "CompanyAddressMappingGroups": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'CompanyID',
                        Header: 'CompanyID',
                        width: 210,
                        accessor: (d: any) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'NAME',
                        Header: 'NAME',
                        width: 210,
                        accessor: (d: any) => d.name,
                        sortField: 'name'
                    },
                ]

                break;
            }

            case "CompanyAddressMappings": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'CompanyID',
                        Header: 'CompanyID',
                        width: 210,
                        accessor: (d: any) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'GroupID',
                        Header: 'GroupID',
                        width: 210,
                        accessor: (d: any) => d.groupId,
                        sortField: 'groupId'
                    },
                    {
                        id: 'GroupName',
                        Header: 'GroupName',
                        width: 210,
                        accessor: (d: any) => d.groupName,
                        sortField: 'groupName'
                    },
                ]

                break;
            }

            case "CompanyFreightMapping": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'CompanyID',
                        Header: 'CompanyID',
                        width: 210,
                        accessor: (d: any) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'FreightMappingGroup',
                        Header: 'FreightMappingGroup',
                        width: 210,
                        accessor: (d: any) => d.freightMappingGroup,
                        sortField: 'freightMappingGroup'
                    },
                    {
                        id: 'FreightMappingGroupName',
                        Header: 'FreightMappingGroupName',
                        width: 210,
                        accessor: (d: any) => d.freightMappingGroupName,
                        sortField: 'freightMappingGroupName'
                    },
                ]

                break;
            }

            case "CompanyFreightMappingGroups": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'CompanyID',
                        Header: 'CompanyID',
                        width: 210,
                        accessor: (d: any) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'NAME',
                        Header: 'NAME',
                        width: 210,
                        accessor: (d: any) => d.name,
                        sortField: 'name'
                    }
                ]

                break;
            }

            case "CompanyHistory": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'CompanyID',
                        Header: 'CompanyID',
                        width: 210,
                        accessor: (d: any) => d.company._id,
                        sortField: 'company._id'
                    },
                    {
                        id: 'CompanyName',
                        Header: 'CompanyName',
                        width: 210,
                        accessor: (d: any) => d.company.name,
                        sortField: 'company.name'
                    },
                    {
                        id: 'TIMESTAMP',
                        Header: 'timestamp',
                        width: 150,
                        accessor: (d: any) => d.timestamp,
                        Cell: (d: any) => {
                            return <span>{this.formatDate(d.value)}</span>
                        },
                        sortMethod: (a, b) => {
                            var a1 = new Date(a).getTime();
                            var b1 = new Date(b).getTime();
                            if (a1 < b1) {
                                return 1;
                            } else if (a1 > b1) {
                                return -1;
                            } else {
                                return 0;
                            }
                        },
                        sortField: 'timestamp'

                    },
                ]

                break;
            }

            case "CompanyConfigurationDraft": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: any) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'NAME',
                        Header: 'NAME',
                        width: 210,
                        accessor: (d: any) => d.name,
                        sortField: 'name'
                    },
                    {
                        id: 'UserId',
                        Header: 'UserId',
                        width: 210,
                        accessor: (d: any) => d.userId,
                        sortField: 'userId'
                    },
                    {
                        id: 'created',
                        sortField: 'createdAt',
                        Header: 'Created',
                        width: 180,
                        accessor: (d: any) => this.formatDate(d.createdAt)
                    },
                ]

                break;
            }

            case "ChangeEvents": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: ChangeEvent) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'timestamp',
                        Header: 'timestamp',
                        width: 210,
                        accessor: (d: ChangeEvent) => this.formatDate(d.timestamp),
                        sortField: 'timestamp'
                    }, {
                        id: 'eventType',
                        Header: 'eventType',
                        width: 210,
                        accessor: (d: ChangeEvent) => d.eventType,
                        sortField: 'eventType'
                    },
                    {
                        id: 'companyId',
                        Header: 'companyId',
                        width: 210,
                        accessor: (d: ChangeEvent) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'eventSourceRequestId',
                        sortField: 'eventSourceRequestId',
                        Header: 'eventSourceRequestId',
                        width: 180,
                        accessor: (d: ChangeEvent) => d.eventSourceRequestId
                    },
                ]

                break;
            }

            case "Positions": {
                return [
                    {
                        id: 'ID',
                        Header: 'ID',
                        width: 210,
                        accessor: (d: Position) => d._id,
                        sortField: '_id'
                    },
                    {
                        id: 'companyId',
                        Header: 'companyId',
                        width: 210,
                        accessor: (d: Position) => d.companyId,
                        sortField: 'companyId'
                    },
                    {
                        id: 'timestamp',
                        Header: 'timestamp',
                        width: 210,
                        accessor: (d: Position) => this.formatDate(d.timestamp),
                        sortField: 'timestamp'
                    }, {
                        id: 'weight',
                        Header: 'weight',
                        width: 210,
                        accessor: (d: Position) => d.weight,
                        sortField: 'weight'
                    },
                    {
                        id: 'driverId',
                        Header: 'driverId',
                        width: 210,
                        accessor: (d: Position) => d.driverId,
                        sortField: 'driverId'
                    },
                    {
                        id: 'speed',
                        sortField: 'speed',
                        Header: 'speed',
                        width: 180,
                        accessor: (d: Position) => d.speed
                    },
                    {
                        id: 'odometer',
                        sortField: 'odometer',
                        Header: 'odometer',
                        width: 180,
                        accessor: (d: Position) => d.odometer
                    },
                    {
                        id: 'virtualOdometer',
                        sortField: 'virtualOdometer',
                        Header: 'virtualOdometer',
                        width: 180,
                        accessor: (d: Position) => d.virtualOdometer
                    },
                    {
                        id: 'transportIds',
                        sortField: 'transportIds',
                        Header: 'transportIds',
                        width: 180,
                        accessor: (d: Position) => d.transportIds
                    },
                    {
                        id: 'referencedTransportOrders',
                        sortField: 'referencedTransportOrders',
                        Header: 'referencedTransportOrders',
                        width: 180,
                        accessor: (d: Position) => d.referencedTransportOrders
                    },
                    {
                        id: 'vehicleId',
                        sortField: 'vehicleId',
                        Header: 'vehicleId',
                        width: 180,
                        accessor: (d: Position) => d.vehicleId
                    }
                ]

                break;
            }

            default:
                return []
        }


    }
}

enum BilledEnum {
    BILLED,
    NOT_BILLED
}