import * as React from "react";
import {
    Freight,
    TransportOrderStatus,
    Transport,
    DoubleWithUnit,
    TransportOrder,
    StopLocation, StopLocationStatus, Eta
} from "../../../../generated";
import moment = require("moment");


export const etaMomentFormat = "DD.MM.YY HH:mm"
export const timeFormat = "HH:mm"

export function formatTransportOrderStatus(t: TransportOrderStatus | undefined) {
    if (t?.statusCode) {
        return formatTransportOrderStatusEnum(t.statusCode)
    } else {
        return ""
    }
}

export function formatTransportOrderStatusEnum(t: TransportOrderStatus.StatusCodeEnum) {
    switch (t) {
        case TransportOrderStatus.StatusCodeEnum.ACCEPTED:
            return "Accepted"
        case TransportOrderStatus.StatusCodeEnum.ACKNOWLEDGING:
            return "WaitingForConfirmation"
        case TransportOrderStatus.StatusCodeEnum.CANCELED:
            return "Canceled"
        case TransportOrderStatus.StatusCodeEnum.CREATED:
            return "New"
        case TransportOrderStatus.StatusCodeEnum.DISPATCHING:
            return "WaitingForAssignment"
        case TransportOrderStatus.StatusCodeEnum.DONE:
            return "Finished"
        case TransportOrderStatus.StatusCodeEnum.EXECUTING:
            return "InProgress"
        case TransportOrderStatus.StatusCodeEnum.REJECTED:
            return "Declined"
        case TransportOrderStatus.StatusCodeEnum.INPUTMISSING:
            return "MissingEntries"
        default:
            return "Unknown"
    }
}

export function formatFreight(freights: Freight[]) {
    let data: string[] = []

    freights.forEach(function (f, index, array) {
        if (f.containmentType && f.totalQuantity && f.totalQuantity.value) {
            data.push(`${f.totalQuantity.value.toLocaleString('de-DE')} ${f.totalQuantity.unit || DoubleWithUnit.UnitEnum.Piece} ${f.verboseName} (Packmittel: ${f.containmentType})`)
        }
        else if (f.containmentName && f.totalQuantity && f.totalQuantity.value) {
            data.push(`${f.totalQuantity.value.toLocaleString('de-DE')} ${f.totalQuantity.unit || DoubleWithUnit.UnitEnum.Piece} ${f.verboseName} (Packmittel: ${f.containmentName})`)
        }
        else if (f.totalWeight && f.totalWeight.value && f.verboseName) {
            data.push(`${f.totalWeight.value.toLocaleString('de-DE')} ${f.totalWeight.unit || DoubleWithUnit.UnitEnum.Kg} ${f.verboseName}`)
        } else {
            data.push(f.verboseName ?? "")
        }
        if (index !== array.length - 1) {
            data.push(", ")
        }
    })
    return data
}

export function formatTransport(transports: Transport[]) {
    let data: string[] = []

    transports.forEach(function (t) {
        t.stops?.forEach(function (stop) {
            if (stop.city) {
                data.push(`${stop.city}`)
            } else if (stop.name) {
                data.push(`${stop.name}`)
            } else {

            }
        })
    })
    return [...new Set(data)].join(", ")
}

export function getNextStopByEta(transportOrder: TransportOrder) {
    if (transportOrder.status?.statusCode == TransportOrderStatus.StatusCodeEnum.EXECUTING) {
        let stops: StopLocation[] = []
        if (transportOrder.transports) {
            for (let index = 0; index < transportOrder.transports.length; index++) {
                const transport = transportOrder.transports[index];
                if (transport.stops) {
                    for (let index = 0; index < transport.stops.length; index++) {
                        const stop = transport.stops[index];
                        if (stop.eta && stop.eta.eta && stop.eta.calculationTimestamp && stop.eta.remainingDriveTime
                            && (stop.status?.statusCode == StopLocationStatus.StatusCodeEnum.APPROACHING ||
                                stop.status?.statusCode == StopLocationStatus.StatusCodeEnum.PLANNED)) {
                            stops.push(stop)
                        }
                    }
                }
            }
        }

        let sortedData = stops.sort(function (a, b) {
            let etaA = a.eta?.eta
            let etaB = b.eta?.eta
            if (etaA && etaB) {
                return new Date(etaA).getTime() - new Date(etaB).getTime()
            } else {
                return 0
            }
        })
        return sortedData[0]
    }
}


export type EtaDateTimeDisplayProps = {
    eta: Eta
}
export const EtaDateTimeDisplay: React.FunctionComponent<EtaDateTimeDisplayProps> = ({ eta }) => {
    if (eta == null)
        return <div></div>

    const age = determineEtaAge(eta)
    let style = {}
    let displayText = moment(eta.eta).format(etaMomentFormat)
    if (age == EtaUpdateTimeEnum.OlderThan15Min) {
        style = { color: "#A9A9A9" }
        displayText = moment().add(eta.remainingDriveTime, 'seconds').format(etaMomentFormat)
    }
    if (age == EtaUpdateTimeEnum.OlderThanOneDay) {
        displayText = "n.V."
    }

    return <div style={{ ...style, display: "inline-block" }}>{displayText}</div>
};

export enum EtaUpdateTimeEnum {
    InTime,
    OlderThan15Min,
    OlderThanOneDay
}

function determineEtaAge(eta: Eta) {
    let MINUTE_IN_MS = 1000 * 60
    let DAY_IN_MS = MINUTE_IN_MS * 60 * 24

    if (eta.calculationTimestamp) {
        if ((new Date().getTime() - new Date(eta.calculationTimestamp).getTime()) > 1 * DAY_IN_MS) {
            return EtaUpdateTimeEnum.OlderThanOneDay
        }
        if ((new Date().getTime() - new Date(eta.calculationTimestamp).getTime()) > 15 * MINUTE_IN_MS) {
            return EtaUpdateTimeEnum.OlderThan15Min
        }
    } else {
        console.log("No calculation timestamp to determine eta age");
    }

    return EtaUpdateTimeEnum.InTime
}

export function determineDotColorForTransportOrder(transportOrder: TransportOrder) {
    let stop = getNextStopByEta(transportOrder)
    let planArrive: Date | undefined = undefined
    if (stop?.planArrive) {
        planArrive = stop.planArrive
    }

    return getColor(planArrive, stop)
}

export function getColor(date?: Date, stop?: StopLocation) {
    if (stop && date) {
        if (!stop.eta || determineEtaAge(stop.eta) == EtaUpdateTimeEnum.OlderThanOneDay) {
            return '#ffffff00'
        }
        if (stop.eta.eta) {
            if (isEtaInTime(date, stop.eta.eta)) {
                return '#57D500'
            } else {
                return '#FFBF00'
            }
        }
    }
    return '#ffffff00'
}

export function isEtaInTime(date: Date, eta: Date) {
    if (moment(date).add(15, 'minutes').toDate().getTime() > moment(eta).toDate().getTime()) {
        return true
    } else {
        return false
    }
}