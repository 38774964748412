import * as React from "react";
import {Button, Header, Icon, Modal, Popup} from "semantic-ui-react";
import {ConfigContext} from "../../../../context/ConfigContext";


export interface UpAndDownloadModalProps {
    isOpen: boolean
    onClose: (upload: boolean, download: boolean) => void
}

export interface UpAndDownloadModalState {
}

export class UpAndDownloadModal extends React.Component<UpAndDownloadModalProps, UpAndDownloadModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    handleClose = (upload, download) => {
        this.props.onClose(upload, download)
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            size='small'>
            <Header icon='browser' content='Sync config with your computer'/>
            <Modal.Content>


                    To Download or upload the config, please select in both cases 'xconvert-cm-local-config-scripts\src\test\kotlin'.<br/>
                    This folder is ignored by git, it is your private workfolder for scripts.<br/>
                    If you configured your git & intellij the 'default' way, you should be able to copy the following path in the Directory selection of your browser:<br/>
                    <h3>C:\Users\%USERPROFILE%\git\xconvert\xconvert-cm-local-config-scripts\src\test\kotlin</h3>
                    ALWAYS use the 'kotlin' directory for both upload and download, no matter if you are working on kotlin script or groovy scripts!<br/>
                <br/>
                    After a download you can reach the desired script by right clicking on the label and click copy path.<br/>
                    If you combine the above mentioned path with the copied path from the label and enter the result in your windows explorer, the script should automatically open in intellij.<br/>



                <br/>
                <Popup content={"This will download the whole config as a directory-structure to your disk."}
                       trigger={
                           <Button icon onClick={() => this.handleClose(false, true)}><Icon name={'download'}
                                                                                            size={'large'}/>Download</Button>
                       }/>
                <Button icon onClick={() => this.handleClose(true, false)}><Icon name={'upload'} size={'large'}/>Upload</Button>

                <Button onClick={() => this.handleClose(false, false)}>Cancel</Button>

            </Modal.Content>
        </Modal>

    }


}

