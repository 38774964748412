import * as React from "react";
import {Button, Grid, Header, Modal} from "semantic-ui-react";
import {TransportOrder} from "../../../../../generated";
import {DiffWrapper} from "../../configChange/configEdit/diff/DiffWrapper";
import Notifications, {notify} from 'react-notify-toast';
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {CustomDiff} from "../../configChange/configEdit/diff/CustomDiff";

export interface TransportOrderSaveModalProps {
    isOpen: boolean
    onClose: () => void
    transportOrder: TransportOrder
    oldTransportOrder: TransportOrder
}

export class TransportOrderSaveModal extends React.Component<TransportOrderSaveModalProps, any> {

    constructor(props) {
        super(props)
        this.state = {}
    }

    handleClose = () => {
        this.props.onClose()
    }

    async handleSave() {
        let auth = await backend.withTokenAuthHeader(authentication.token)


        try {
            await backend.transportOrderApi.modifyTransportOrder(this.props.transportOrder._id, this.props.transportOrder, undefined,undefined,'REPLACE', undefined, auth)
            notify.show('successfully modified transportOrder', 'success', 3000, '#28f751');

            this.props.onClose()

        } catch (e) {
            notify.show("ERROR: unable to modify transportOrder!", 'error', 5000, '#fc0303')

        }

    }

    render() {
        if (this.props.transportOrder != null) {
            return <Modal
                open={this.props.isOpen}
                onClose={this.handleClose}
                onMount={() => {
                }}
                dimmer="blurring"
                size='fullscreen'>
                <Header icon='browser' content='TransportOrder Change'/>
                <Modal.Content>
                    <p>This view shows what did actually change with this history entry. It compares this history-state
                        with the previous history-state.</p>
                    <Grid padded="horizontally" stackable columns='equal' centered>

                        <Grid.Row>
                            <Grid.Column><h2>BEFORE</h2></Grid.Column>
                            <Grid.Column><h2>{"--> compared to -->"}</h2></Grid.Column>
                            <Grid.Column><h2>YOUR CHANGE</h2></Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <CustomDiff
                        oldJson={JSON.stringify(this.props.oldTransportOrder, null, 4)}
                        newJson={JSON.stringify(this.props.transportOrder, null, 4)}
                    />

                    <Button primary onClick={() => {
                        console.log("open Rollback view")
                        this.handleSave()
                    }}>save</Button>
                    <Button onClick={() => this.handleClose()}>cancel</Button>
                    <Notifications/>
                </Modal.Content>
            </Modal>
        } else {
            return <p/>
        }
    }

}