import * as React from "react";
import {grayLogApi} from "../../../util/grayLog/grayLogApi";
import {Button, Checkbox, Input} from "semantic-ui-react";
import {IfBox} from "../../../style/if";
import {LogTextOutput} from "./LogTextOutput";
import {ConfigContext} from "../../context/ConfigContext";

export interface LogViewProps {
    query: string
    origin: "exportSim"
        | "importSim"
        | "transportOrderConfigTest"
        | "matrixDoc"
        | "multiScript"
        | "singleScript"
        | "scriptRunner"
        | "telematicMapping"
        | "suppressionScriptModal"
        | "customerReport"
        | "transportOrderCreateOrModify"
        | "reportScript"
        | "GeoStatusWorkbench"
}

export interface LogViewState {
    isLoading: boolean,
    data: [],
    timespan: number,
    searchTerm: string,
    response: any,
    selectedLogEntry: any,
    showLog: boolean,
    filterError: boolean,
}

export class LogView extends React.Component<LogViewProps, LogViewState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("showing LogView")

        this.state = {
            isLoading: false,
            data: [],
            timespan: 300,
            searchTerm: "",
            response: null,
            selectedLogEntry: null,
            showLog: false,
            filterError: false,
        }
    }

    async searchLogs(searchTerm: string = this.state.searchTerm) {
        this.setState({isLoading: true})
        let query = '"' + searchTerm + '"'
        if (searchTerm.includes(" OR ")) {
            let terms = searchTerm.split(" OR ")
            if (terms.length == 2) {
                query = '"' + terms[0] + '" OR "' + terms[1] + '"'
            }
        }

        let response = await grayLogApi.search(this.state.timespan, query)

        let logs = response.messages.reverse()

        this.setState({response: logs})
        this.context.setSingleLogSession(this.props.origin, searchTerm, logs)
        console.log("responseMessage -> ", logs)
        this.setState({isLoading: false})
    }

    async componentDidMount() {
        let query = this.props.query
        if (!query) {
            switch (this.props.origin) {

                case "exportSim": {
                    if (this.context.logSession.exportSimQuery) {
                        this.setState({
                            searchTerm: this.context.logSession.exportSimQuery,
                            response: this.context.logSession.exportSimLogs
                        })
                    }
                    break;
                }
                case "importSim": {
                    if (this.context.logSession.importSimQuery) {
                        this.setState({
                            searchTerm: this.context.logSession.importSimQuery,
                            response: this.context.logSession.importSimLogs
                        })
                    }
                    break;
                }
                case "transportOrderConfigTest": {
                    if (this.context.logSession.transportOrderConfigTestQuery) {
                        this.setState({
                            searchTerm: this.context.logSession.transportOrderConfigTestQuery,
                            response: this.context.logSession.transportOrderConfigTestLogs
                        })
                    }
                    break;
                }
                case "matrixDoc": {
                    if (this.context.logSession.matrixDocQuery) {
                        this.setState({
                            searchTerm: this.context.logSession.matrixDocQuery,
                            response: this.context.logSession.matrixDocLogs
                        })
                    }
                    break;
                }
                case "multiScript": {
                    if (this.context.logSession.multiScriptQuery) {
                        this.setState({
                            searchTerm: this.context.logSession.multiScriptQuery,
                            response: this.context.logSession.multiScriptLogs
                        })
                    }
                    break;
                }
                case "singleScript": {
                    if (this.context.logSession.singleScriptQuery) {
                        this.setState({
                            searchTerm: this.context.logSession.singleScriptQuery,
                            response: this.context.logSession.singleScriptLogs
                        })
                    }
                    break;
                }
                case "scriptRunner": {
                    if (this.context.logSession.scriptRunnerQuery) {
                        this.setState({
                            searchTerm: this.context.logSession.scriptRunnerQuery,
                            response: this.context.logSession.scriptRunnerLogs
                        })
                    }
                    break;
                }
                case "telematicMapping": {
                    if (this.context.logSession.telematicMappingQuery) {
                        this.setState({
                            searchTerm: this.context.logSession.telematicMappingQuery,
                            response: this.context.logSession.telematicMappingLogs
                        })
                    }
                    break;
                }
                case "customerReport": {
                    if (this.context.logSession.customerReportQuery) {
                        this.setState({
                            searchTerm: this.context.logSession.customerReportQuery,
                            response: this.context.logSession.customerReportLogs
                        })
                    }
                    break;
                }
                case "transportOrderCreateOrModify": {
                    if (this.context.logSession.transportOrderCreateOrModifyQuery) {
                        this.setState({
                            searchTerm: this.context.logSession.transportOrderCreateOrModifyQuery,
                            response: this.context.logSession.transportOrderCreateOrModifyLogs
                        })
                    }
                    break;
                }

            }
            return
        } else {
            this.context.setSingleLogSession(this.props.origin, query, null)
        }
        this.setState({searchTerm: query})
    }

    componentDidUpdate() {
        if (this.props.query && this.state.searchTerm != this.props.query) {
            console.log("x-RequestId -> ", this.props.query)
            this.setState({searchTerm: this.props.query})
            this.context.setSingleLogSession(this.props.origin, this.props.query, null)
            this.searchLogs(this.props.query)
        }
    }

    filterErrors() {
        if (this.state.filterError && this.state.response) {
            return this.state.response.filter(log => {
                return log.includes("ERROR")
            })
        }
        return this.state.response
    }


    render() {

        return <>
            <div id={"LogViewFilters"} style={{marginBottom: "2px"}}>
                <Input placeholder='timespan' value={this.state.timespan}
                       onChange={(evt) => this.setState({timespan: Number(evt.target.value)})}/>
                <Input placeholder='searchterm' value={this.state.searchTerm} style={{width: "300px"}}
                       onChange={(evt) => this.setState({searchTerm: evt.target.value})}/>

                <Button loading={this.state.isLoading} onClick={evt => this.searchLogs()}>Search</Button>
                <span style={{paddingLeft: 50}}>
                    Filter Errors
                    <Checkbox
                        type='checkbox'
                        toggle
                        checked={this.state.filterError}
                        onChange={() => {
                            this.setState({filterError: !this.state.filterError})
                        }}
                    />
                </span>
            </div>
            <IfBox shouldShow={this.state.response != null}>
                <LogTextOutput logOutput={this.filterErrors()}/>
            </IfBox>
        </>

    }
}