import * as React from "react";
import {Button} from "semantic-ui-react";
import {
    CompanyConfiguration,
    FetchTransportOrderListFilterRequest,
    PartnerQueryObject,
    TransportOrder,
    TransportOrderStatus
} from "../../../../generated";
import {backend} from "../../../../xconvert-backend";
import {authentication} from "../../../../authentication";
import {Moment} from "moment";
import {
    DateRangeStr,
    FilterConditions,
    InTimeEnum,
    TransportOrderFilterComponent
} from "./TransportOrderFilterComponent";
import {ConfigContext} from "../../context/ConfigContext";
import {TransportOrderTable} from "./TransportOrderTable";
import {ArchiveTransportOrdersModal} from "./modals/ArchiveTransportOrdersModal";
import {workingConfig} from "../configChange/ConfigSignal";
import {RouteComponentProps} from "react-router";


export class Filter implements FilterConditions {
    dateRangeSelection: DateRangeStr | undefined;
    dateType: FetchTransportOrderListFilterRequest.DateTimeFieldEnum;
    fromDateTime: Date | undefined;
    inTime: InTimeEnum;
    partners: PartnerQueryObject[] | undefined;
    status: TransportOrderStatus.StatusCodeEnum
    term: string | undefined;
    toDateTime: Date | undefined;
    valid: "ONLY_VALID" | "ONLY_INVALID" | undefined;
}

export interface TransportOrderSearchState {
    take: number,
    page: number,
    selectedTransportOrderIds: string[],
    selectedTransportOrder: TransportOrder,
    sortBy: string,
    sortDirection: "ASC" | "DESC",
    transportOrders: TransportOrder[],
    transportOrderAmount: number,
    filter: FilterConditions
    startDate: Moment
    endDate: Moment
    showArchiveTransportOrdersModal: boolean
    linkingPartners: any[]
}

export interface TransportOrderSearchProps extends RouteComponentProps<any>{
    selectedOrderIdsChanged: (newSelectedOrderIds: string[]) => void,
    selectedOrderChanged: (newSelectedOrder: TransportOrder) => void,
    reload: (page: number, take: number) => void,
    history: any,
    initialPageSize: number,
    hideArchiveButton: boolean,
    foundTransportOrdersChanged: (newTransportOrders: TransportOrder[]) => void
}

export class TransportOrderSearch extends React.Component<TransportOrderSearchProps, TransportOrderSearchState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            take: 25,
            page: 0,
            selectedTransportOrderIds: [],
            selectedTransportOrder: null,
            sortBy: 'NAME',
            sortDirection: "DESC",
            transportOrders: [],
            transportOrderAmount: 0,
            filter: new Filter(),
            startDate: null,
            endDate: null,
            showArchiveTransportOrdersModal: false,
            linkingPartners: []
        }
    }

    componentDidMount() {
        this.setState({take: this.props.initialPageSize})
        this.fetchTransportOrders()
        this.findLinkingCompanies()
    }

    async findLinkingCompanies() {
        let auth = backend.withTokenAuthHeader(authentication.token)


        let resp = await backend.companyApi.linkingPartners(auth)
        let linking = resp.linkingPartners ?? []

        let config = JSON.parse(workingConfig.value) as CompanyConfiguration
        config.transportOrderLinkConfiguration?.companiesToLinkByKey?.map(async c => {
            if (c.active) {
                let response = await backend.companyApi.queryCompanies(c.companyId, false, auth)
                linking.push({name: response.results[0]?.name, id: c.companyId})
            }
        })

        this.setState({linkingPartners: linking})
    }

    async fetchTransportOrders(page: number = this.state.page, take: number = this.state.take) {
        console.log("fetching transportOrders")
        let auth = await backend.withTokenAuthHeader(authentication.token)

        let begin = this.state.filter.fromDateTime
        let dateTimeField = this.state.filter.dateType
        let end = this.state.filter.toDateTime
        let searchTerm = this.state.filter.term
        let sortBy = this.state.sortBy
        let sortOrder = this.state.sortDirection


        let request = {} as FetchTransportOrderListFilterRequest
        request.companyId = this.context.companyId
        request.begin = begin
        request.dateTimeField = dateTimeField
        request.end = end
        request.exactSearchTermMatch = false
        request.searchTerm = searchTerm
        request.skip = (page) * take
        request.sortBy = sortBy
        request.sortOrder = sortOrder
        request.take = take


        let response = await backend.transportOrderApi.fetchTransportOrdersWithFilter(
            request,
            auth
        )


        this.setState({
            transportOrders: response.transportOrders,
            transportOrderAmount: response.count,
        })
        this.props.foundTransportOrdersChanged(response.transportOrders)
    }

    drawFilters() {
        let initialFilter = {} as FilterConditions

        return <TransportOrderFilterComponent
            onFilterChange={(fc: FilterConditions, reset?: boolean) => {
                this.setState({page: 0, filter: fc}, this.fetchTransportOrders)
            }}
            initialFilter={initialFilter}
            exportButtonVisible={true}
            partnerSelectionVisible={true}
            statusFilterVisible={true}
            history={this.props.history}
            location={this.props.location}
            match={this.props.match}
        />
    }

    drawTable() {
        return <TransportOrderTable
            transportOrderAmount={this.state.transportOrderAmount}
            transportOrders={this.state.transportOrders}
            selectedOrderChanged={(newSelectedOrder) => {
                this.setState({selectedTransportOrder: newSelectedOrder})
                this.props.selectedOrderChanged(newSelectedOrder)
            }}
            selectedOrderIdsChanged={(newSelectedOrderIds) => {
                this.setState({selectedTransportOrderIds: newSelectedOrderIds})
                this.props.selectedOrderIdsChanged(newSelectedOrderIds)
            }}
            reload={(page, take) => {
                this.setState({page: page, take: take})
                this.fetchTransportOrders(page, take)
            }}
            initialPageSize={this.props.initialPageSize}
        />
    }

    modals() {
        return <ArchiveTransportOrdersModal
            isOpen={this.state.showArchiveTransportOrdersModal}
            onClose={() => {
                this.setState({showArchiveTransportOrdersModal: false})
                this.fetchTransportOrders()
            }}
            transportOrderIds={this.state.selectedTransportOrderIds}/>
    }

    render() {
        return <>
            {this.drawFilters()}
            {!this.props.hideArchiveButton &&
                <span style={{margin: "0 0 5px 0"}}>
                    <Button onClick={() => this.setState({showArchiveTransportOrdersModal: true})}>
                        Archive selected
                    </Button>
                </span>
            }
            {this.drawTable()}
            {this.modals()}
        </>
    }

}