import * as React from "react";
import {CustomerReportDynamicSection, healthyHighlighter} from "../CustomerReportDynamicSection";
import {useContext} from "react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest} from "../../../../../../generated";
import ServiceTypeEnum = AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest.ServiceTypeEnum;
import {customerReport} from "../../../../../style/page_with_sidebar";

export function CustomerReportLogeniosFileHealthStatus({ reFetchReportData }: { reFetchReportData: () => Promise<void> }) {
    const context = useContext(ConfigContext)
    const columns = [{
        id: 'companyName',
        Header: 'companyName',
        width: 300,
        accessor: (d: any) => d.companyName,
    }, {
        id: 'icon',
        Header: 'health',
        width: 50,
        accessor: (d: any) => <div>{healthyHighlighter(d.healthy)}</div>,
    }, {
        id: 'old file',
        Header: 'old files',
        width: 100,
        accessor: (d: any) => d.oldFileAmount,
    }, {
        id: 'pending in retry loop',
        Header: 'pending in retry loop',
        width: 100,
        accessor: (d: any) => d.pendingRetryLoopEntries?.length,
    }, {
        id: 'maxFileAgeBeforeWarningInSeconds',
        Header: 'maxFileAgeBeforeWarningInSeconds',
        width: 100,
        accessor: (d: any) => d.maxFileAgeBeforeWarningInSeconds,
    },
        {
        id: 'responsibleManager',
        Header: 'responsibleManager',
        width: 100,
        accessor: (d: any) => d.responsibleManager,
        }]

    return <CustomerReportDynamicSection
        columns={columns}
        data={customerReport.value?.fileSystemWorkerStatus?.logeniosOldFiles}
        currentService={ServiceTypeEnum.LOGENIOSFILEHEALTHREPORT}
        reFetchReportData={reFetchReportData}
    />

}