import * as React from "react";
import {authentication} from "../../../../../authentication";
import {ProcessingInfo, QueuedEvent, StatusForwardRule, TransportOrderChange} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {ConfigContext} from "../../../context/ConfigContext";
import {formatDate, formatDateNoTime} from "../../../../../format";
import ReactTable from "react-table";
import {
    Button,
    Checkbox,
    Dropdown,
    DropdownItemProps,
    Form,
    Grid,
    Icon,
    Input,
    List,
    Popup,
    Radio,
    Segment
} from "semantic-ui-react";

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {ChangeMultipleQueuedEventsModal} from "../modal/ChangeMultipleQueuedEventsModal";
import {notify} from 'react-notify-toast';
import {IfBox} from "../../../../style/if";
import {QueuedEventsSavedFilterModal} from "./QueuedEventsSavedFilterModal";
import {CustomDateRangePicker} from "../../../../util/dateRangePicker/CustomDateRangePicker";
import QueuedEventFilters from "../../../context/QueuedEventFilters";
import ChangeTypesEnum = StatusForwardRule.ChangeTypesEnum;
import TypeEnum = TransportOrderChange.TypeEnum;

export interface QueuedEventsTableProps {
    onClickedEvent: (event: QueuedEvent) => void // single selection to display editor
    onSelectionChanged: (newSelected: QueuedEvent[]) => void // multi selection with checkboxes

}


export interface QueuedEventsTableState {
    isLoading: boolean,
    take: number
    page: number
    sortBy: 'ID' | 'QUEUE_ID' | 'QUEUE_TYPE' | 'EVENT_TYPE' | 'TIMESTAMP' | 'RATE_LIMITED' | 'SUPPRESSED' | 'PROCESSED' | 'PROCESSING_STATE'
    sortDirection: 'ASC' | 'DESC'
    count: number
    queuedEvents: QueuedEvent[]
    selectedEvent: QueuedEvent | null
    allSelected: boolean
    selected: string[]
    showChangeMultipleQueuedEvents: boolean
    filterType: FilterEnum
    editorHeight: string,
    filter: QueuedEventFilter,
    displayFilterManageModal: boolean,
    filterName: string,
}

export enum FilterEnum {
    QUEUED_EVENT_ID = "QueuedEvent id",
    QUEUE_TYPE = "Queue type",
    EVENT_TYPE = "Event Type",
    CHANGE_TYPE = "Change Type",
    TRANSPORT_ORDER_ID = "TransportOrderId",
    PROCESSING_STATE = "Processing State",
    PROCESSED = "Processed",
    CREATED_AT = "Created at",
}

export class QueuedEventFilter {
    name: string | null
    queuedEventId: string | null
    queueType: QueuedEvent.QueueTypeEnum | null
    eventType: QueuedEvent.EventTypeEnum | null
    changeType: ChangeTypesEnum | null
    processingState: ProcessingInfo.StateEnum | null
    processed: 'TRUE' | 'FALSE' | 'NONE'
    transportOrderId: string | null
    createdAtFrom: Date | undefined
    createdAtTo: Date | undefined

    constructor() {
        this.name = null
        this.queuedEventId = null
        this.queueType = null
        this.eventType = null
        this.changeType = null
        this.processingState = null
        this.processed = null
        this.transportOrderId = null
        this.createdAtFrom = undefined
        this.createdAtTo = undefined
    }

    create(
        name: string | null,
        queuedEventId: string | null,
        queueType: QueuedEvent.QueueTypeEnum | null,
        eventType: QueuedEvent.EventTypeEnum | null,
        changeType: ChangeTypesEnum | null,
        processingState: ProcessingInfo.StateEnum | null,
        processed: 'TRUE' | 'FALSE' | 'NONE',
        transportOrderId: string | null,
        createdAtFrom: Date | undefined = undefined,
        createdAtTo: Date | undefined = undefined
    ) {
        this.name = name
        this.queuedEventId = queuedEventId
        this.queueType = queueType
        this.eventType = eventType
        this.changeType = changeType
        this.processingState = processingState
        this.processed = processed
        this.transportOrderId = transportOrderId
        this.createdAtFrom = createdAtFrom
        this.createdAtTo = createdAtTo
    }
}

export class QueuedEventsTable extends React.Component<QueuedEventsTableProps, QueuedEventsTableState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading QueuedEventsPage")

        this.state = {
            isLoading: false,
            take: 20,
            page: 1,
            sortBy: 'TIMESTAMP',
            sortDirection: 'DESC',
            count: 0,
            queuedEvents: [],
            selectedEvent: null,
            allSelected: false,
            selected: [],
            showChangeMultipleQueuedEvents: false,
            filterType: null,
            editorHeight: "100%",
            filter: new QueuedEventFilter(),
            displayFilterManageModal: false,
            filterName: ""
        }
    }

    async componentDidMount() {
        let filter = new QueuedEventFilter()
        let contextFilter = this.context.queuedEventFilters

        let from = undefined
        let to = undefined

        if (contextFilter.createdAt != null) {
            let dateRange = JSON.parse(contextFilter.createdAt)
            if (dateRange.length > 0) {
                let raw = dateRange[0]
                from = new Date(raw)
            }
            if (dateRange.length > 1) {
                to = new Date(dateRange[1])
            }
        }
        filter.create(
            null,
            contextFilter.queuedEventId,
            contextFilter.queueType as QueuedEvent.QueueTypeEnum,
            contextFilter.eventType as QueuedEvent.EventTypeEnum,
            contextFilter.changeType as ChangeTypesEnum,
            contextFilter.processingState as ProcessingInfo.StateEnum,
            contextFilter.processed as 'TRUE' | 'FALSE' | 'NONE',
            contextFilter.transportOrderId,
            from,
            to
        )

        this.setState({filter: filter})

        let events = await this.loadQueuedEvents()
        if (this.state.selectedEvent == null && this.context.selectedQueuedEventId != null) {
            let newSelectedEvent = events.find(evt => evt._id === this.context.selectedQueuedEventId)
            this.setState({selectedEvent: newSelectedEvent})
            this.props.onClickedEvent(newSelectedEvent)
        }
    }

    async loadQueuedEvents(take = this.state.take, page = this.state.page) {

        this.setState({isLoading: true})
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let skip = (page - 1) * take
        let filter = this.state.filter

        let processed = null
        if (filter.processed == 'TRUE') {
            processed = true
        } else if (this.state.filter.processed == 'FALSE') {
            processed = false
        }

        let resp = (await backend.internalApi.fetchQueuedEventsForCM(
            filter.changeType,
            this.context.companyId,
            filter.createdAtFrom,
            filter.createdAtTo,
            filter.eventType,
            take,
            processed,
            filter.processingState,
            filter.queueType,
            filter.queuedEventId?.trim(),
            skip,
            this.state.sortBy,
            this.state.sortDirection,
            filter.transportOrderId?.trim(),
            auth
        ))
        this.setState({
            queuedEvents: resp.events,
            count: resp.count,
            take: take,
            page: page,
            isLoading: false,
            selected: [],
            allSelected: false

        })
        this.props.onSelectionChanged([])
        return resp.events
    }

    render() {
        return <p>

            {this.drawFilter()}
            {this.renderSelectionOptions()}
            {this.renderTable()}

            <ChangeMultipleQueuedEventsModal
                isOpen={this.state.showChangeMultipleQueuedEvents}
                onClose={(success) => {
                    this.setState({showChangeMultipleQueuedEvents: false})
                    if (success) {
                        notify.show('SUCCESS: updated ' + this.state.selected.length + ' queued events', 'success', 3000, '#28f751');
                        this.loadQueuedEvents()
                    }
                }}
                queuedEventIds={this.state.selected}
            />
        </p>
    }

    filterEnumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(FilterEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }

    queueTypeEnumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(QueuedEvent.QueueTypeEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }

    eventTypeEnumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(QueuedEvent.EventTypeEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }

    changeTypeEnumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(ChangeTypesEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }

    processingStateEnumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(ProcessingInfo.StateEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }

    updateFilter(field: FilterEnum, value: any) {
        let filter = this.state.filter
        switch (field) {
            case FilterEnum.QUEUED_EVENT_ID: {
                filter.queuedEventId = value
                break
            }
            case FilterEnum.QUEUE_TYPE: {
                filter.queueType = value
                break
            }
            case FilterEnum.EVENT_TYPE: {
                filter.eventType = value
                break
            }
            case FilterEnum.CHANGE_TYPE: {
                filter.changeType = value
                break
            }
            case FilterEnum.PROCESSING_STATE: {
                filter.processingState = value
                break
            }
            case FilterEnum.PROCESSED: {
                filter.processed = value
                break
            }
            case FilterEnum.TRANSPORT_ORDER_ID: {
                filter.transportOrderId = value
                break
            }
            case FilterEnum.CREATED_AT: {
                filter.createdAtFrom = value ? value[0] : null
                filter.createdAtTo = (value && value.length > 1) ? value[1] : null
            }
        }
        this.setState({filter: filter})
        this.context.setQueuedEventFilters(this.queuedEventFilterConverter(filter))
    }

    queuedEventFilterConverter(filter: QueuedEventFilter) {
        let newFilters = {} as QueuedEventFilters
        newFilters.queuedEventId = filter.queuedEventId
        newFilters.queueType = filter.queueType
        newFilters.eventType = filter.eventType
        newFilters.changeType = filter.changeType
        newFilters.transportOrderId = filter.transportOrderId
        newFilters.processingState = filter.processingState
        newFilters.processed = filter.processed
        newFilters.createdAt = filter.createdAtFrom && filter.createdAtTo ? JSON.stringify([filter.createdAtFrom.valueOf(), filter.createdAtTo.valueOf()]) : null
        return newFilters
    }

    drawFilterValueInput(filterType: FilterEnum) {
        console.log("drawFilterValueInput: " + filterType)
        switch (filterType) {
            case FilterEnum.QUEUED_EVENT_ID:
                return <Input id="QueuedEventIdInput"
                              style={{width: 200}} placeholder='QueuedEventId'
                              value={this.state.filter.queuedEventId}
                              onChange={(evt) => {
                                  this.updateFilter(FilterEnum.QUEUED_EVENT_ID, evt.target.value)
                                  this.context.setSingleQueuedEventFilter("queuedEventId", evt.target.value)
                              }}
                />
            case FilterEnum.QUEUE_TYPE:
                return <Dropdown
                    selectOnBlur={false}
                    placeholder='Queue type'
                    search
                    selection
                    options={this.queueTypeEnumToArray()}
                    clearable
                    value={this.state.filter.queueType}
                    onChange={(event, {value}) => {
                        this.updateFilter(FilterEnum.QUEUE_TYPE, value as QueuedEvent.QueueTypeEnum)
                        this.context.setSingleQueuedEventFilter("queueType", value as string)
                    }
                    }
                />
            case FilterEnum.EVENT_TYPE:
                return <Dropdown
                    selectOnBlur={false}
                    placeholder='Select'
                    search
                    selection
                    options={this.eventTypeEnumToArray()}
                    clearable
                    value={this.state.filter.eventType}
                    onChange={(event, {value}) => {
                        this.updateFilter(FilterEnum.EVENT_TYPE, value as QueuedEvent.EventTypeEnum)
                        this.context.setSingleQueuedEventFilter("eventType", value as string)

                        if (value != 'TRANSPORT_ORDER_EVENT') {
                            this.updateFilter(FilterEnum.CHANGE_TYPE, null)
                            this.context.setSingleQueuedEventFilter("changeType", null)

                        }
                    }
                    }
                />
            case FilterEnum.CHANGE_TYPE:
                return <p><Dropdown
                    selectOnBlur={false}
                    value={this.state.filter.changeType}
                    placeholder='Select'
                    search
                    selection
                    options={this.changeTypeEnumToArray()}
                    clearable
                    disabled={this.state.filter.eventType != QueuedEvent.EventTypeEnum.TRANSPORTORDEREVENT}
                    onChange={(event, {value}) => {
                        this.updateFilter(FilterEnum.CHANGE_TYPE, value as ChangeTypesEnum)
                        this.context.setSingleQueuedEventFilter("changeType", value as string)

                    }
                    }
                />
                    <IfBox shouldShow={this.state.filter.eventType != QueuedEvent.EventTypeEnum.TRANSPORTORDEREVENT}>
                        <Popup content='changeType is only enabled when eventType is TRANSPORTORDEREVENT' trigger={
                            <Icon name={'info'}/>
                        }/>

                    </IfBox>
                </p>
            case FilterEnum.TRANSPORT_ORDER_ID:
                return <Input id="TransportOrderIdInput"
                              style={{width: 200}} placeholder='TransportOrderId'
                              value={this.state.filter.transportOrderId}
                              onChange={(evt) => {
                                  this.updateFilter(FilterEnum.TRANSPORT_ORDER_ID, evt.target.value)
                                  this.context.setSingleQueuedEventFilter("transportOrderId", evt.target.value)

                              }}
                />
            case FilterEnum.PROCESSING_STATE:
                return <Dropdown
                    selectOnBlur={false}
                    placeholder='Select'
                    search
                    selection
                    options={this.processingStateEnumToArray()}
                    clearable
                    value={this.state.filter.processingState}
                    onChange={(event, {value}) => {
                        this.updateFilter(FilterEnum.PROCESSING_STATE, value as ProcessingInfo.StateEnum)
                        this.context.setSingleQueuedEventFilter("processingState", value as string)
                    }
                    }
                />
            case FilterEnum.PROCESSED:
                return <Segment compact>

                    <Form>
                        <Form.Field>
                            Processed:
                            <b>{this.state.filter.processed}</b>
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='only processed'
                                name='radioGroup'
                                value='TRUE'
                                checked={this.state.filter.processed == 'TRUE'}
                                onChange={() => {
                                    this.updateFilter(FilterEnum.PROCESSED, 'TRUE')
                                    this.context.setSingleQueuedEventFilter("processed", 'TRUE')
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='only unprocessed'
                                name='radioGroup'
                                value='FALSE'
                                checked={this.state.filter.processed == 'FALSE'}
                                onChange={() => {
                                    this.updateFilter(FilterEnum.PROCESSED, 'FALSE')
                                    this.context.setSingleQueuedEventFilter("processed", 'FALSE')
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='none'
                                name='radioGroup'
                                value='NONE'
                                checked={this.state.filter.processed == 'NONE'}
                                onChange={() => {
                                    this.updateFilter(FilterEnum.PROCESSED, 'NONE')
                                    this.context.setSingleQueuedEventFilter("processed", 'NONE')
                                }}
                            />
                        </Form.Field>
                    </Form>
                </Segment>
            case FilterEnum.CREATED_AT:
                return <CustomDateRangePicker
                    id={"QueuedEventCreatedAt"}
                    startDate={null}
                    endDate={null}
                    onDatesSelected={(startDate, endDate) => this.onDatesSelected(startDate?.toDate(), endDate?.toDate())}
                />
            case null:
                return <p/>
        }
    }

    onDatesSelected(startDate?: Date, endDate?: Date) {
        console.log("onDatesSelected", startDate, endDate)
        // Swagger is stupid and only checks for undefined, not null
        let sd = startDate
        let ed = endDate
        if (startDate == null) {
            sd = undefined
        }
        if (endDate == null) {
            ed = undefined
        }
        this.updateFilter(FilterEnum.CREATED_AT, [sd, ed])
        this.context.setSingleQueuedEventFilter("createdAt", JSON.stringify([sd.valueOf(), ed.valueOf()], null, 4))
    }

    drawActiveFilters() {
        return <List bulleted>
            <IfBox shouldShow={this.state.filter.queuedEventId != null}>
                <List.Item>
                    <p onClick={() => {
                        this.setState({filterType: FilterEnum.QUEUED_EVENT_ID}, () => {
                            document.getElementById("QueuedEventIdInput").focus()
                        })
                    }}>{'QueuedEvent id: '} {this.state.filter.queuedEventId}
                        <Icon name={"x"} color={"red"} onClick={() =>
                            this.updateFilter(FilterEnum.QUEUED_EVENT_ID, null)
                        }/>
                    </p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={this.state.filter.queueType != null}>
                <List.Item>
                    <p onClick={() => this.setState({filterType: FilterEnum.QUEUE_TYPE})}>
                        {'Queue type: '} {this.state.filter.queueType}
                        <Icon name={"x"} color={"red"} onClick={() => {
                            this.updateFilter(FilterEnum.QUEUE_TYPE, null)
                        }}/></p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={this.state.filter.eventType != null}>
                <List.Item>
                    <p onClick={() => this.setState({filterType: FilterEnum.EVENT_TYPE})}>
                        {'Event Type: '} {this.state.filter.eventType}
                        <Icon name={"x"} color={"red"} onClick={() => {
                            this.updateFilter(FilterEnum.EVENT_TYPE, null)
                        }}/></p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={this.state.filter.changeType != null}>
                <List.Item>
                    <p onClick={() => this.setState({filterType: FilterEnum.CHANGE_TYPE})}>
                        {'Change Type: '} {this.state.filter.changeType}
                        <Icon name={"x"} color={"red"} onClick={() => {
                            this.updateFilter(FilterEnum.CHANGE_TYPE, null)
                        }}/>
                    </p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={this.state.filter.transportOrderId != null}>
                <List.Item>
                    <p onClick={() => {
                        this.setState({filterType: FilterEnum.TRANSPORT_ORDER_ID}, () => {
                            document.getElementById("TransportOrderIdInput").focus()
                        })
                    }}>
                        {'TransportOrderId: '} {this.state.filter.transportOrderId}
                        <Icon name={"x"} color={"red"} onClick={() => {
                            this.updateFilter(FilterEnum.TRANSPORT_ORDER_ID, null)
                        }}/>
                    </p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={this.state.filter.processingState != null}>
                <List.Item>
                    <p onClick={() => this.setState({filterType: FilterEnum.PROCESSING_STATE})}>
                        {'Processing State: '} {this.state.filter.processingState}
                        <Icon name={"x"} color={"red"} onClick={() => {
                            this.updateFilter(FilterEnum.PROCESSING_STATE, null)
                        }}/>
                    </p>
                </List.Item>
            </IfBox>

            <IfBox shouldShow={this.state.filter.processed != null}>
                <List.Item>
                    <p onClick={() => this.setState({filterType: FilterEnum.PROCESSED})}>
                        {'Processed: '} {this.state.filter.processed}
                        <Icon name={"x"} color={"red"} onClick={() => {
                            this.updateFilter(FilterEnum.PROCESSED, null)
                        }}/>
                    </p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={this.state.filter.createdAtFrom != null}>
                <List.Item>
                    <p onClick={() => this.setState({filterType: FilterEnum.CREATED_AT})}>
                        CreateAt: {
                        this.state.filter.createdAtFrom &&
                        formatDateNoTime(this.state.filter.createdAtFrom)
                    } - {
                        this.state.filter.createdAtTo &&
                        formatDateNoTime(this.state.filter.createdAtTo)
                    }
                        <Icon name={"x"} color={"red"} onClick={() => {
                            this.updateFilter(FilterEnum.CREATED_AT, null)
                        }}/>
                    </p>

                </List.Item>
            </IfBox>
        </List>
    }

    drawSavedFilters() {
        let allFilters: QueuedEventFilter[] = JSON.parse(localStorage.getItem("queuedEventFilters")) as QueuedEventFilter[]
        let filterDisplay = <div style={{float: "left"}}><p>no saved filters</p></div>
        if (allFilters != null) {
            filterDisplay = <div style={{float: "left"}}>
                {
                    allFilters.map((filter: QueuedEventFilter) => {
                        return <Button
                            size={"mini"}
                            onClick={() => {
                                this.setState({filter: filter})
                            }}>{filter.name}
                        </Button>
                    })}
            </div>
        }

        return <Segment
            style={{padding: "4px", width: "100%"}}
        >
            {filterDisplay}
            <Button
                floated={"right"}
                size={"mini"}
                style={{marginLeft: "80px"}}
                icon
                onClick={() => {
                    this.setState({displayFilterManageModal: true})
                }}
            >
                <Icon name={"pencil"}/>
            </Button>
        </Segment>
    }

    drawFilter() {
        return <Segment>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Row>
                    <Grid.Column width={6}>
                        select filter:
                        <br/>
                        <Dropdown
                            selectOnBlur={false}
                            placeholder='Select'
                            search
                            selection
                            options={this.filterEnumToArray()}
                            clearable
                            value={this.state.filterType}
                            onChange={(event, {value}) => {
                                this.setState({filterType: value as FilterEnum})
                            }}
                        /><br/>
                        {this.drawFilterValueInput(this.state.filterType)}
                    </Grid.Column>
                    <Grid.Column>
                        {this.drawActiveFilters()}
                    </Grid.Column>
                    <Grid.Column textAlign={"right"} width={1}>
                        <Button icon onClick={() => this.loadQueuedEvents()}>
                            <Icon name='sync'/>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {this.drawSavedFilters()}
                    <QueuedEventsSavedFilterModal
                        isOpen={this.state.displayFilterManageModal}
                        onClose={() => {
                            this.setState({displayFilterManageModal: false})
                        }}
                        currentFilter={this.state.filter}
                    />
                </Grid.Row>
            </Grid>
        </Segment>
    }

    switchSelectionStateOfItem(id: string) {
        this.setState({isLoading: true})

        let array = [...this.state.selected]; // make a separate copy of the array
        let index = array.indexOf(id)
        if (index == -1) {
            // id not found, adding Id

            let ids = array.concat(id)
            this.setState({selected: ids})
            let selectedObjects = this.state.queuedEvents.filter(e => ids.includes(e._id))
            this.props.onSelectionChanged(selectedObjects)
            console.log("Id added")


        } else {
            // Id found, removing it

            array.splice(index, 1);
            this.setState({selected: array})
            let selectedObjects = this.state.queuedEvents.filter(e => array.includes(e._id))
            this.props.onSelectionChanged(selectedObjects)
            console.log("Id removed")

        }
        this.setState({isLoading: false})
    }

    selectOrUnselectAll() {
        this.setState({isLoading: true})

        if (this.state.allSelected) {
            console.log("select None")
            this.setState({selected: [], allSelected: false})

            this.props.onSelectionChanged([])
        } else {
            console.log("select All")
            let newSelected = this.state.queuedEvents.map((e) => e._id)
            this.setState({selected: newSelected, allSelected: true})
            this.props.onSelectionChanged(this.state.queuedEvents)
        }

        this.setState({isLoading: false})

    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    renderSelectionOptions() {
        return <Segment>
            select all -<Checkbox
            checked={this.state.allSelected}
            onChange={() => {
                this.selectOrUnselectAll()
            }}
        />
            <Button
                disabled={this.state.selected.length == 0}
                icon
                onClick={() => this.setState({showChangeMultipleQueuedEvents: true})}>
                <Icon
                    name='pencil'/>
            </Button>


        </Segment>
    }


    renderTable() {
        const columns = [
            {
                id: 'select',
                Header: 'Select',
                width: 50,
                accessor: (d: any) => {
                    return <Checkbox
                        checked={this.state.selected.some(id => id === d._id)}
                        onChange={() => this.switchSelectionStateOfItem(d._id)}
                    />
                },
                sortField: 'NONE'
            }, {
                id: 'queueType',
                Header: 'queueType',
                width: 150,
                accessor: (d: any) => d.queueType,
                sortField: 'QUEUE_TYPE'
            }, {
                id: 'eventType',
                Header: 'eventType',
                width: 200,
                accessor: (d: any) => d.eventType,
                sortField: 'EVENT_TYPE'
            },
            {
                id: 'orderId',
                Header: 'Order ID',
                width: 100,
                accessor: (d: QueuedEvent) => d.transportOrderEvent?.transportOrder?._id ?? d.positionEvent?.transportOrderId ?? d.etaEvent?.transportOrder?._id,
                Cell: (d: any) => {
                    return <span>{d.value}</span>
                }
            },
            {
                id: 'changeType',
                Header: 'Change Type',
                width: 150,
                accessor: (d: QueuedEvent) => d.transportOrderEvent?.change?.type ?? (d.etaEvent?.nextStops && d.etaEvent?.nextStops[0]?.city),
                Cell: (d: any) => {
                    return <span>{d.value}</span>
                }
            },
            {
                id: 'status',
                Header: 'Change Value',
                width: 150,
                accessor: (d: QueuedEvent) => {
                    let obj = d.transportOrderEvent?.change?.modifiedObject
                    switch (d.transportOrderEvent?.change?.type) {
                        case TransportOrderChange.TypeEnum.STATUSUPDATEFREIGHT:
                            return obj.freight?.status?.statusCode?.toString()
                        case TypeEnum.STATUSUPDATETRANSPORTORDER:
                            return obj.transportOrder?.status?.statusCode?.toString()
                        case TypeEnum.STATUSUPDATESTOPLOCATION:
                            return obj?.stop?.status?.statusCode?.toString() + ` (${obj?.stop?.city})`
                        case TypeEnum.STATUSUPDATESTOPACTION:
                            return obj.action?.status?.statusCode?.toString()
                        case TypeEnum.ASSIGNCOMPANY:
                            return d.transportOrderEvent?.transportOrder?.targetCompanyName
                        case TypeEnum.ATTACHMENTADD:
                            return obj.attachment?.name
                        case TypeEnum.ASSIGNVEHICLE:
                            return d.transportOrderEvent?.transportOrder?.assignedVehicle?.numberPlate
                        case TypeEnum.ASSIGNTRAILER:
                            return d.transportOrderEvent?.transportOrder?.assignedTrailer?.numberPlate
                        case TypeEnum.ASSIGNDEVICE:
                            let dev = d.transportOrderEvent?.transportOrder?.assignedDevice
                            return dev.emailAddress ?? dev.phoneNr
                        default:
                            // If its a ETA Event, display the remaining eta time
                            return d.etaEvent?.nextStops && d.etaEvent?.nextStops[0]?.eta?.eta?.toString()
                    }

                },
                Cell: (d: any) => {
                    return <span>{d.value}</span>
                }
            },
            {
                id: 'timestamp',
                Header: 'timestamp',
                width: 150,
                accessor: (d: any) => d.timestamp,
                Cell: (d: any) => {
                    return <span>{formatDate(d.value)}</span>
                },
                sortField: 'TIMESTAMP'
            }, {
                id: 'rateLimited',
                Header: 'rateLimited',
                width: 100,
                accessor: (d: any) => {
                    if (d.rateLimited) {
                        return <span>true</span>
                    } else {
                        return <span>false</span>
                    }
                },
                sortField: 'RATE_LIMITED'
            }, {
                id: 'suppressed',
                Header: 'suppressed',
                width: 100,
                accessor: (d: any) => {
                    if (d.suppressed) {
                        return <span>true</span>
                    } else {
                        return <span>false</span>
                    }
                },
                sortField: 'SUPPRESSED'
            }, {
                id: 'processed',
                Header: 'processed',
                width: 100,
                accessor: (d: any) => {
                    if (d.processed) {
                        return <span>true</span>
                    } else {
                        return <span>false</span>
                    }
                },
                sortField: 'SUPPRESSED'
            }, {
                id: 'processingState',
                Header: 'processingState',
                width: 120,
                accessor: (d: any) => d.processingInfo?.state,
                sortField: 'PROCESSING_STATE'
            }, {
                id: '_id',
                Header: 'ID',
                width: 200,
                accessor: (d: any) => d._id,
                sortField: 'ID'
            }, {
                id: 'queueId',
                Header: 'queueId',
                width: 200,
                accessor: (d: any) => d.queueId,
                sortField: 'QUEUE_ID'
            }
        ]

        if (this.state.queuedEvents == null) {
            return <p>Loading</p>
        }

        return <><ReactTable
            data={this.state.queuedEvents}
            manual
            pages={Math.ceil(this.state.count / this.state.take)}
            columns={columns}
            sorted={[
                {
                    id: 'TIMESTAMP',
                    desc: false
                }
            ]}
            onSortedChange={(newSorted, column, shiftKey) => {
                this.changeSort(column.sortField)
            }}

            defaultPageSize={this.state.take}
            className="-striped -highlight"
            loading={this.state.isLoading}
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: () => {
                        this.context.setSelectedQueuedEventId(rowInfo.row._original._id)
                        this.setState({selectedEvent: rowInfo.row._original})
                        this.props.onClickedEvent(rowInfo.row._original)
                    },
                    style: {
                        background: rowInfo?.row._original._id != null && rowInfo?.row._original._id === this.context.selectedQueuedEventId ? '#00afec' : 'white',
                        color: rowInfo?.row._original._id != null && rowInfo?.row._original._id === this.context.selectedQueuedEventId ? 'white' : 'black'
                    }
                };
            }}
            style={{cursor: "pointer"}}
            onPageChange={(pageIndex) => {
                console.log("changing pageIndex -> ", pageIndex)
                this.changePage(pageIndex)
            }}
            onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}
        /></>
    }


    changePage(pageIndex: number) {
        console.log("NEW PAGEINDEX: ", pageIndex)
        this.setState({isLoading: true})
        this.loadQueuedEvents(this.state.take, pageIndex + 1)
    }

    async changePageSize(newSize: number) {
        this.setState({isLoading: true, editorHeight: "99%"}) // changing the editor height to trigger a reload of the editor
        await this.loadQueuedEvents(newSize, this.state.page)
        this.setState({editorHeight: "100%"})

    }

    changeSort(sortBy: 'ID' | 'QUEUE_ID' | 'QUEUE_TYPE' | 'EVENT_TYPE' | 'TIMESTAMP' | 'RATE_LIMITED' | 'SUPPRESSED' |
        'PROCESSING_STATE' | 'NONE') {
        console.log("sort clicked!!!")
        if (sortBy == 'NONE') {
            return
        }
        if (this.state.sortBy == sortBy) {
            //change direction
            let direction = this.state.sortDirection
            if (direction == "ASC") {
                direction = "DESC"
            } else {
                direction = "ASC"
            }
            this.setState({sortDirection: direction})
        } else {
            //change sortBy and reset direction
            this.setState({sortBy: sortBy, sortDirection: "DESC"})
        }

        //refresh
        this.loadQueuedEvents()
    }
}