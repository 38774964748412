import * as React from "react";
import {Button, Divider, Header, List, Modal} from "semantic-ui-react";
import {ArchiveTransportOrdersRequest} from "../../../../../generated";
import Notifications, {notify} from 'react-notify-toast';
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {ConfigContext} from "../../../context/ConfigContext";

export interface ArchiveTransportOrdersModalProps {
    isOpen: boolean
    onClose: () => void
    transportOrderIds: string[]
}

export class ArchiveTransportOrdersModal extends React.Component<ArchiveTransportOrdersModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {}
    }

    handleClose = () => {
        this.props.onClose()
    }

    async handleArchive() {
        let auth = await backend.withTokenAuthHeader(authentication.token)


        try {
            let req = {} as ArchiveTransportOrdersRequest
            req.transportOrderIds = this.props.transportOrderIds
            await backend.internalApi.archiveTransportOrders(this.context.companyId, req, auth)
            notify.show('successfully archived transportOrder', 'success', 3000, '#28f751');

            this.props.onClose()

        } catch (e) {
            notify.show("ERROR: unable to archive transportOrder!", 'error', 5000, '#fc0303')

        }
        this.handleClose()
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            onMount={() => {
            }}
            dimmer="blurring"
            size='large'>
            <Header icon='browser' content='Archive TransportOrders'/>
            <Modal.Content>
                <p>You are about to archive the listed TransportOrders. These TransportOrder won't be available for
                    further processing.
                </p>
                <p>ATTENTION: you can NOT revert this from this tool!
                    To revert it, the transportOrders need be manually copied over from the LongTermStorage into the active database!
                </p>
                <Divider/>
                {this.renderTable()}

                <Button primary onClick={() => {
                    this.handleArchive()
                }}>archive</Button>
                <Button onClick={() => this.handleClose()}>cancel</Button>
                <Notifications/>
            </Modal.Content>
        </Modal>

    }

    private renderTable() {
        return <List>
            {this.props.transportOrderIds.map(toId => {
                return <List.Item>{toId}</List.Item>
            })}
        </List>
    }
}