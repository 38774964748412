import * as React from "react";
import { ConfigContext } from "../../../context/ConfigContext";
import AceEditor from 'react-ace';

import 'brace/mode/json'
import 'brace/theme/monokai'
import 'brace/ext/searchbox'
import {updateConfig, workingConfig} from "../ConfigSignal";

export class EditRawCompanyConfig extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)

        console.log("loading raw CompanyConfig")

        this.state = {
            configStr: null
        }
    }

    componentDidMount() {
        if(this.state.config == null) {
            this.setState({config: workingConfig.value})
            this.props.jsonValidation(workingConfig.value)

        }
    }


    updateConfig(config) {
        this.setState({config: config})

        try {
            JSON.parse(config)
            updateConfig(config, true, false)
        } catch (ex) {
        }

        this.props.jsonValidation(config)

    }

    render() {
        if (workingConfig.value == null) {
            return <div>Loading</div>
        } else {
            return <AceEditor
                theme="monokai"
                style={{flex:"1"}}
                mode="json"
                value={this.state.config}
                width="100%"
                onChange={(e) => this.updateConfig(e)} // setConfig must be called here, because of a timing problem
            />
        }
    }
}