import * as React from "react";
import {Card, Divider, Header, Icon, Label, List, Segment} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {Company, CompanyConfiguration, PartnerCompanyWithPartnerObjects} from "../../../../../generated";
import {IfBox} from "../../../../style/if";
import {SwitchToPartnerCompanyModal} from "./SwitchToPartnerCompanyModal";
import {ftp} from "../../../../../xconvert-ftp";


export interface PartnerCardState {
    openSwitchModal: Company | null,
    partners: ConfigWithCompanyId[],
    scfCompanies: ConfigWithCompanyId[],
}

interface ClmcInfo {
    enabled: boolean,
    errorFilesMonitoringEnabled: boolean,
    shouldSilenceScriptErrorsEnabled: boolean
}

class ConfigWithCompanyId {
    partner: PartnerCompanyWithPartnerObjects
    config: CompanyConfiguration
    errorFilesExist: boolean
    verificationFilesExists: boolean
    clmcInfo: ClmcInfo
}

export class PartnerCard extends React.Component<any, PartnerCardState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading Partner")

        this.state = {
            openSwitchModal: null,
            partners: [],
            scfCompanies: [],
        }
    }

    async componentDidMount() {
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let partners = await this.fetchPartnersWithExportAndImport(auth)
        await this.fetchAdditionalInfoOfPartners(auth, partners)


    }

    async fetchAdditionalInfoOfPartners(auth, partners) {

        let newPartners = []
        for (const p of partners) {

            let partnerCompanyId = p.partner.company._id

            let errorFilesResponse = await this.fetchErrorFiles(partnerCompanyId, auth)
            p.errorFilesExist = errorFilesResponse.totalFiles > 0

            let needsVerificationResponse = await this.fetchVerificationFiles(partnerCompanyId, auth)
            p.verificationFilesExists = needsVerificationResponse.totalFiles > 0

            let clmc = p.config.companyLevelMonitoringConfiguration

            if (clmc) {
                p.clmcInfo = {
                    enabled: clmc.enabled,
                    errorFilesMonitoringEnabled: clmc.errorFilesMonitoring?.enabled,
                    shouldSilenceScriptErrorsEnabled: clmc.shouldSilenceScriptErrors?.enabled
                }
            } else {
                p.clmcInfo = {
                    enabled: false,
                    errorFilesMonitoringEnabled: false,
                    shouldSilenceScriptErrorsEnabled: false
                }
            }

            newPartners.push(p)
        }

        let scfCompanies = newPartners.filter(p => {
            return p.isShipmentCreateFormCompany == true
        })

        let actualPartners = newPartners.filter(p => {
            return p.isShipmentCreateFormCompany != true
        })
        this.setState({partners: actualPartners, scfCompanies: scfCompanies})
    }

    async fetchErrorFiles(companyId, auth) {
        return await ftp.ftpApi.listFiles(
            companyId,
            undefined,
            0,
            "//LOGS/ERRORS",
            null,
            false,
            'DIRECTORY',
            'ASC',
            1,
            undefined,
            null,
            auth
        )
    }

    async fetchVerificationFiles(companyId, auth) {
        return await ftp.ftpApi.listFiles(
            companyId,
            undefined,
            0,
            "//ROOT/NEEDSVERIFICATION",
            null,
            false,
            'DIRECTORY',
            'ASC',
            1,
            undefined,
            null,
            auth
        )
    }


    async fetchPartnersWithExportAndImport(auth): Promise<ConfigWithCompanyId[]> {
        let partnerResponse = (await backend.internalApi.fetchPartnersWithConfigAndExportAndImport(this.context.companyId, auth))
        return await Promise.all(partnerResponse.partners.map(async (partner) => {

            let configWithCompanyId = {} as ConfigWithCompanyId
            configWithCompanyId.partner = partner

            if (partner.company?._id) {
                configWithCompanyId.config = await backend.internalApi.fetchCompanyConfiguration(partner.company._id, auth)
                return configWithCompanyId;
            } else {
                return configWithCompanyId
            }

        }));

    }

    drawAutomaticImport(partner) {
        if(partner.isShipmentCreateFormCompany) {
            return this.displayBoolean(true)
        }
        return this.drawExternalCompanyIdOfPartner(partner.partner.importPartner?.externalPartnerIds)
    }

    drawPartners(partners) {
        if (partners.length > 0) {


            return partners.map((partner) => {
                    console.log("Partner: ", partner.partner.company?.name)

                    let listItems = []
                    listItems.push(
                        <span>
                            {'\u2022'} automatic export: {this.drawExternalCompanyIdOfPartner(partner.partner.exportPartner?.externalPartnerIds)}
                            <br/>
                        </span>)
                    listItems.push(
                        <span>
                            {'\u2022'} automatic import: {this.drawAutomaticImport(partner)}
                            <br/>
                        </span>)
                    listItems.push(
                        <span>
                            {'\u2022'} active Converter: {partner.config?.activeConverter}
                            <br/>
                        </span>)
                    listItems.push(
                        <span>
                            <Divider/>
                            <Header
                                size={'tiny'}>ApiGateway ConfigTypes</Header> {this.drawApiGatewayConfigList(partner.config)}
                            <br/>
                        </span>
                    )
                    listItems.push(
                        <span>
                            <Divider/>
                                 <Header
                                     size={'tiny'}>CompanyLevelMonitoring</Header> {this.drawCompanyLevelMonitoring(partner)}
                            <br/>
                             </span>
                    )
                    if (partner.errorFilesExist) {
                        listItems.push(<span><Divider/><Label color={'red'}>ERROR FILES EXISTS!</Label></span>)
                    }
                    if (partner.verificationFilesExists) {
                        listItems.push(<span><Divider/><Label color={'yellow'}>Some files need verification</Label></span>)
                    }


                    return <Card onClick={() => this.setState({openSwitchModal: partner.partner.company})}>
                        <Card.Content>
                            <Card.Header>{partner.partner.company?.name}</Card.Header>
                            <Card.Meta>{partner.partner.company?._id}</Card.Meta>

                            <Card.Description>
                                <List items={listItems}/>
                            </Card.Description>

                        </Card.Content>
                    </Card>

                }
            )

        } else {
            return "no companies found here!"
        }
    }

    drawApiGatewayConfigList(config: CompanyConfiguration) {
        return <List bulleted>
            {config?.apiGatewayConfigs.map((apiGatewayConfig) => {
                return <List.Item key={apiGatewayConfig.api}>
                    {apiGatewayConfig.api}: {this.displayBoolean(!apiGatewayConfig.disabled)}
                </List.Item>
            })}
        </List>
    }

    drawCompanyLevelMonitoring(partner: ConfigWithCompanyId) {
        return <List>

            <List.Item>
                {'enabled: '} {this.displayBoolean(partner.clmcInfo.enabled)}
            </List.Item>
            <List.Item>
                <List>
                    <List.Item>
                        {'errorFilesMonitoringEnabled: '} {this.displayBoolean(partner.clmcInfo.errorFilesMonitoringEnabled)}
                    </List.Item>

                    <List.Item>
                        {'shouldSilenceScriptErrorsEnabled:'} {this.displayBoolean(partner.clmcInfo.shouldSilenceScriptErrorsEnabled)}
                    </List.Item>
                </List>
            </List.Item>
        </List>

    }

    displayBoolean(bool: Boolean) {
        if (bool) {
            return <Icon name='check' color='green'/>
        } else {
            return <Icon name='x' color='red'/>
        }
    }


    drawExternalCompanyIdOfPartner(ids: string[]) {
        if (ids == null || ids.length == 0) {
            return <Icon name='x' color='red'/>
        } else {
            return <span><Icon name='check' color='green'/>{ids.join(", ")}</span>
        }
    }

    render() {
        console.log("Render Partners")

        return <Segment>
            <Label>Partner</Label>
            <IfBox shouldShow={this.state.partners != undefined}>
                <Card.Group>
                    {this.drawPartners(this.state.partners)}
                </Card.Group>
            </IfBox>
            <IfBox shouldShow={this.state.openSwitchModal != null}>
                <SwitchToPartnerCompanyModal
                    isOpen={this.state.openSwitchModal != null}
                    company={this.state.openSwitchModal}
                    onClose={() => this.setState({openSwitchModal: null})}
                />
            </IfBox>
            <br/>
            <Label>ShipmentCreateForm Companies</Label>
            <IfBox shouldShow={this.state.scfCompanies != undefined}>
                <Card.Group>
                    {this.drawPartners(this.state.scfCompanies)}
                </Card.Group>
            </IfBox>
        </Segment>
    }
}
