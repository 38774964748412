import * as React from "react";
import {authentication} from "../../../authentication";
import {Company} from "../../../generated/api";
import {backend} from "../../../xconvert-backend";
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {ConfigContext} from "../../main/context/ConfigContext";
import {List} from "semantic-ui-react";
import {ListCopyModal} from "../list_copy_modal";

export interface CopyCompanyModalProps {
    onClose: () => (void)
    open: boolean
}

class CompanyIdSearchModalR extends React.Component<CopyCompanyModalProps & RouteComponentProps<any>, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isSelecting: false,
            showConfigChangedModal: false,
            selectedCompany: null,
            currentCompanyConfig: null,
            failedTests: [],
            includeArchived: false
        }
    }

    renderItem = (c: Company) => {

        return <List.Content>
            <List.Header>{c.name}</List.Header>
            <List.Description>{c._id}</List.Description>
        </List.Content>
    }

    onSearch = async (q: string, includeArchived: boolean = false) => {
        var authHeader = backend.withTokenAuthHeader(authentication.token)
        var res = await backend.companyApi.queryCompanies(q, includeArchived, authHeader)
        return res.results
    }

    onClose = () => {
        this.props.onClose()
    }

    render() {
        return <div>
            <ListCopyModal<Company> open={this.props.open} renderItem={this.renderItem} onSearch={this.onSearch}
                                    onClose={this.onClose} isSelectingItem={this.state.isSelecting}/>
        </div>
    }
}

export const CompanyIdSearchModal = withRouter(CompanyIdSearchModalR)