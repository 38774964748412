import * as React from "react";
import {useState} from "react";
import {getActiveRules, moveRule, selectRules} from "../slices/ruleSlice";
import {useDispatch, useSelector} from "react-redux";
import "./RuleList.css"
import {getRuleToDisplay, setRuleToDisplay} from "../slices/rightMenuSlice";
import useKeypress from "../misc/hooks/use_keypress";
import {queryNextRule} from "../slices/tableSlice";
import {GenericXlsExtractionRule} from "../../../../../generated";

interface RuleListProps {

}

export function RuleList(props:React.PropsWithChildren<RuleListProps>){
    const rules = useSelector(selectRules).filter(r=>r.parentRuleId == undefined)
    const selectedRule: GenericXlsExtractionRule|null = useSelector(getRuleToDisplay)
    const nextRuleUp = useSelector(queryNextRule("UP"))
    const nextRuleDown = useSelector(queryNextRule("DOWN"))
    const activeRules = useSelector(getActiveRules)

    const dispatch = useDispatch()


    const  arrowUp = ()=>{
        nextRuleUp && dispatch(setRuleToDisplay(nextRuleUp))
    }

    const arrowDown = ()=>{
        nextRuleDown && dispatch(setRuleToDisplay(nextRuleDown))
    }

    // 38 == arrow up
    useKeypress({
        "ArrowUp": arrowUp,
        "ArrowDown": arrowDown
    })

    return <ul className="RuleList">
        <li className="RuleListHeader" onClick={()=>dispatch(setRuleToDisplay(null))}>{activeRules=="spt" ? "Split" : "Extraction"} Rules</li>
        <HierarchicalRuleList parent={null} level={0} rules={rules} selectedRule={selectedRule}/>
    </ul>
}

interface HierarchicalRuleListProps {
    level: number
    parent: GenericXlsExtractionRule|null
    rules: GenericXlsExtractionRule[]
    selectedRule: GenericXlsExtractionRule|null
}

function HierarchicalRuleList(props:React.PropsWithChildren<HierarchicalRuleListProps>){

    return <ul className={`RuleList level`}>
        {
            props.rules.map((x,idx)=>
                <RuleListItem key={idx} rule={x} selectedRule={props.selectedRule} level={props.level}/>
            )
        }
    </ul>
}

interface RuleListItemProps{
    rule: GenericXlsExtractionRule
    selectedRule: GenericXlsExtractionRule|null
    level: number
}

export function RuleListItem(props: React.PropsWithChildren<RuleListItemProps>){
    const isSelected = props.rule == props.selectedRule
    const dispatch = useDispatch()

    const [isDraggedOver,setDragOver] = useState(false)

    const subrules = useSelector(selectRules).filter(r=>r.parentRuleId == props.rule.ruleId)


    var ruleName = props.rule.matchText
    if(props.rule.matchWhen == "MATCH_ON_LINE"){
        ruleName = `Match line ${props.rule.matchText}`
    }

    var className = ""
    if(isSelected)
        className += "Selected "

    if(props.rule.action == "LABEL")
        className += "Label "

    if(props.rule.action == "TAG")
        className += "Tag "

    if(isDraggedOver)
        className += "DragOver"

    return <>
        <li draggable="true"
            onDragStart={(evt)=>{evt.dataTransfer.setData("text/plain", props.rule.ruleId.toString());}}
            onDragOver={(e)=>{e.preventDefault();setDragOver(true)}}
            onDragLeave={(e)=>{e.preventDefault(); setDragOver(false)}}
            onDrop={(evt)=>{
                const sourceRule = +evt.dataTransfer.getData("text/plain")
                setDragOver(false)
                dispatch(moveRule({ruleId:sourceRule, moveBelowRuleWithId:props.rule.ruleId}))
            }}
            className={className}
            title={props.rule.comment}
            onClick={()=>dispatch(setRuleToDisplay(props.rule))}>

            {props.rule.ruleId} - {ruleName}
        </li>
        {subrules.length>0?<HierarchicalRuleList level={props.level+1} parent={props.rule} rules={subrules} selectedRule={props.selectedRule}/>:undefined}
    </>
}
