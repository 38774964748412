import {Tab} from "semantic-ui-react";
import * as React from "react";
import {TestSuppressionScriptModalSingle} from "./TestSuppressionScriptModalSingle";
import {TestSuppressionScriptModalMulti} from "./TestSuppressionScriptModalMulti";

const panes = [
    { menuItem: 'Single', render: props => <Tab.Pane><TestSuppressionScriptModalSingle queue={props.queue}/></Tab.Pane> },
    { menuItem: 'Multi', render: props => <Tab.Pane><TestSuppressionScriptModalMulti queue={props.queue}/></Tab.Pane> },
]


const TestSuppressionScriptModalTabView = props => <Tab
    {...props = props}
    panes={panes}
/>

export default TestSuppressionScriptModalTabView