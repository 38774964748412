import * as React from "react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {Grid, Label, Popup, Segment, Sticky, Table} from "semantic-ui-react";
import './style.css';
import {createRef} from "react";

export interface MatrixDocGridViewProps {

}

export interface MatrixDocGridViewState {

}

export class MatrixDocGridView extends React.Component<MatrixDocGridViewProps, MatrixDocGridViewState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)

        this.state = {}
    }

    renderTable() {

        var lineNr = 0
        var columnNr = 0
        var type = ""
        var data = this.context.matrixDocResult
        var map = []

        let line = data?.[0]
        let object = line?.[0]
        let columnAmount = object?.items?.length
        try {
            return <Segment style={{overflow: 'scroll', maxWidth: '100%', maxHeight: '1100px'}}>
                <Table celled striped singleLine className='freeze-head-and-col'>
                    {this.addStickyHeader(columnAmount)}

                    <Table.Body>

                        {data.map(function (line) {

                            return line.map(function (obj) {
                                return <Table.Row> {
                                    obj.items.map(function (item) {
                                        return <Table.Cell selectable style={{padding: '10px'}}>
                                            {item}
                                        </Table.Cell>
                                    })
                                }
                                </Table.Row>
                            })
                        })}

                    </Table.Body>
                </Table>
            </Segment>
        } catch (ex) {
            console.log("error", ex)
            return <Label color='red'>The data is in bad format.</Label>

        }
    }

    addHeaderCells(columnAmount: number) {

        let rows = [];
        for (let i = 0; i < columnAmount; i++) {
            let char = (i + 10).toString(36).toUpperCase()
            rows.push(<Table.HeaderCell>{char}</Table.HeaderCell>);
        }
        return rows;

    }
    addStickyHeader(columnAmount: number) {

        return <Table.Header>
            <Table.Row>
                {this.addHeaderCells(columnAmount)}

            </Table.Row>
        </Table.Header>
    }

    render() {
        console.log("showing ExportSimLisDocTable")


        if (this.context.matrixDocResult === undefined || this.context.matrixDocResult === null) {
            return <h3>LOADING</h3>
        } else {
            return this.renderTable()
        }
    }

}