import * as React from "react";
import {SyncScriptModal} from "./SyncScriptModal";

export interface SyncScriptButtonProps {
    script: string
}


export function SyncScriptButton(props: React.PropsWithChildren<SyncScriptButtonProps>) {

    const [openModal, setOpenModal] = React.useState(false)


    return <>
        <button onClick={(e) => {
            e.preventDefault(); // reloads without this, for some reason...
            setOpenModal(true)
        }}>
            Sync
        </button>
        <SyncScriptModal
            isOpen={openModal}
            onClose={() => setOpenModal(false)}
            script={props.script}
        />
    </>
}