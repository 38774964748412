import * as React from "react";
import {renderToStaticMarkup} from 'react-dom/server';
import ReactTable, {
    Accessor,
    Column,
    DefaultFilterFunction,
    ExpandedChangeFunction,
    Filter,
    Resize,
    RowInfo,
    SubComponentFunction
} from "react-table";
import 'react-table/react-table.css';
import {Icon, Modal, Segment} from "semantic-ui-react";
import {FetchHistoryRequest} from "../../../generated";
import {CustomTableSettingsComponent} from "./filters/customTableSettingsComponent";
import SortDirectionEnum = FetchHistoryRequest.SortDirectionEnum;

const CustomTableConfig = "custom_table_config";

export interface FetchResult<T> {
    totalRecords: number
    data: T[]
}

export interface TableDataSource<T> {
    fetchData(currentPage: number, numRowsToTake: number, sortColumn?: string, sortDirection?: SortDirectionEnum): Promise<(FetchResult<T> | undefined)>,

    resetPagination: boolean
}

export interface CustomTableProps<T> {
    data?: T[],
    cols: any[],
    name: string,
    id?: string,
    fetcher?: TableDataSource<T>,
    configs?: {},
    onPageSizeChange?: () => void,
    onPageChange?: () => void,
    onRowClick?: (rowInfo: RowInfo, event?: any, state?: any, column?: Column) => void,
    onRowMiddleMouseDown?: (rowInfo: RowInfo, event?: any, state?: any) => void,
    onParentRowClick?: (rowInfo: RowInfo, event?: any, state?: any) => void,
    getTrProps?: (state: any, rowInfo?: RowInfo, instance?: any) => void,
    getTdProps?: (state: any, rowInfo?: RowInfo) => void,
    formatSortByEnumValue?: (sortByColumn: string) => any,
    selectedRowIndex?: number
    pivotBy?: string[]
    // globalColumnProps?: Partial<GlobalColumn>
    SubComponent?: SubComponentFunction
    collapseOnDataChange?: boolean
    ignorePagination?: boolean
    style?: any
    noFixedHeight?: boolean
    minRows?: number | undefined
    TheadComponent?: React.ReactType | undefined
    onExpandedChange?: ExpandedChangeFunction
    expanded?: any
    containerHeight?: string,
    filterable?: boolean | undefined
    manual?: boolean | undefined
    disableExport?: boolean | undefined
    defaultFilterMethod?: DefaultFilterFunction | undefined
    onFilteredChange?: (newFiltering: Filter[], column: any, value: any) => void
}

export interface CustomTableState<T> {
    data?: T[],
    isLoadingData: boolean,
    isLoadingConfigs: boolean,
    columns: Column[],
    showSettingsModal: boolean,
    take: number,
    page: number,
    limit: number,
    pages: number,
    sortByColumn?: string,
    sortDirection?: SortDirectionEnum,
    minRows: number,


}

export class CustomTableComponent<T> extends React.Component<CustomTableProps<T>, CustomTableState<T>> {

    private savedSettings: any = {}
    private defaultCols: any[] = [];
    private requestId: number = 0;

    constructor(props: CustomTableProps<T>) {
        super(props);
        this.state = {
            data: (this.props.data) ? this.props.data : [],
            isLoadingData: false,
            isLoadingConfigs: false,
            columns: [...this.props.cols],
            showSettingsModal: false,
            take: 25,
            page: 0,
            limit: 0,
            pages: 0,
            minRows: 20,
        }
        this.defaultCols = this.props.cols;

    }


    dispatch = (cols: Column[], reset = false) => {
        if (reset) {
            if (this.savedSettings[this.props.name + 'Comp']) {
                if (this.savedSettings[this.props.name + 'Comp']['col']) {
                    delete this.savedSettings[this.props.name + 'Comp']['col'];
                }
                if (this.savedSettings[this.props.name + 'Comp']['general']['colOrder']) {
                    delete this.savedSettings[this.props.name + 'Comp']['general']['colOrder'];
                }
                localStorage.setItem(CustomTableConfig, JSON.stringify(this.savedSettings))
            }
            this.setState({columns: this.defaultCols});
        } else {
            let visibilityForSaving: any = {};
            let orderForSaving: (string | undefined)[] = [];
            cols.forEach((col) => {
                if (col.id) {
                    visibilityForSaving[col.id] = col.show;
                    orderForSaving.push(col.id);
                }
            });
            this.saveTableConfigsToLocalStorage('col', 'show', visibilityForSaving);
            this.saveTableConfigsToLocalStorage('general', 'colOrder', orderForSaving);
            this.setState({columns: cols});
        }
    }

    async fetchData(resetPagination?: boolean): Promise<void> {
        if (this.props.fetcher) {
            try {
                await this.setState({isLoadingData: true}, async () => {
                    let result;
                    let sortByColumn = this.state.sortByColumn ?? "";
                    let sortDirection = this.state.sortDirection ?? SortDirectionEnum.DESC;
                    resetPagination = (resetPagination) ? resetPagination : this.props.fetcher?.resetPagination;
                    let ActivePage = (resetPagination) ? 0 : this.state.page;

                    this.requestId += 1;
                    const currentRequestId = this.requestId

                    result = await this.props.fetcher?.fetchData(ActivePage, this.state.take, sortByColumn, sortDirection)

                    if (currentRequestId < this.requestId) {
                        console.info(`%cDiscarding old fetch results as a newer request was made. Old Id ${currentRequestId} Newest id ${this.requestId}`, 'color: teal')
                        return;
                    }


                    // console.log("RESULT:", result)
                    let numberOfPages = (result?.totalRecords) ? Math.ceil(result?.totalRecords / this.state.take) : 1;
                    this.saveTableConfigsToLocalStorage('general', 'lastRequest', {
                        page: ActivePage,
                        take: this.state.take,
                        sortByColumn: sortByColumn,
                        sortDirection: sortDirection,

                    });
                    this.setState({
                        columns: this.savedSettings.updatedCols ? this.savedSettings.updatedCols : this.state.columns,
                        data: result?.data,
                        isLoadingData: false,
                        pages: numberOfPages,
                        page: ActivePage,
                    })
                });

            } catch (ex) {
                console.log(ex);
                this.setState({isLoadingData: false})
            }
            // console.log("DATA:", this.state.data)

        }

    }

    /*jsonToCsv(data: any): string {
        let result = '';

        if (Array.isArray(data)) {
            if (data.length === 0) {
                return result;
            }
            const headers = Object.keys(data[0]);
            result += headers.join(',') + '\n';
            result += data.map((row) => this.jsonToCsv(row)).join('');
            return result;
        }

        if (typeof data === 'object') {
            const values = Object.values(data);
            result += values.map((value) => this.jsonToCsv(value)).join(',') + '\n';
            return result;
        }

        return data + ',';
    }*/

    jsonToCsv(items: any) {
        const header = Object.keys(items[0]);
        const headerString = header.join(',');
        // handle null or undefined values here
        const replacer = (key: string, value: string) => value ?? '';
        const rowItems = items.map((row: any) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(',')
        );
        // join header and body, and break into separate lines
        const csv = [headerString, ...rowItems].join('\r\n');
        return csv;
    }

    async componentDidMount() {
        if (this.props.minRows) {
            this.setState({minRows: this.props.minRows})
        }
        this.readStoredConfigs(true);
        // console.log("CustomTableComponent::componentDidMount")
        //if (this.props.data && this.props.data.length > 0) {
        await this.fetchData()
        //}
    }

    componentDidUpdate(prevProps: CustomTableProps<T>) {
        if (this.props.minRows && this.state.minRows != this.props.minRows) {
            this.setState({minRows: this.props.minRows})
        }
        // console.log("CustomTableComponent::componentDidUpdate")
        if (prevProps.fetcher !== this.props.fetcher) {
            this.fetchData()
        }
        if (prevProps.data !== this.props.data) {
            this.setState({data: this.props.data})
        }
        if (prevProps.cols !== this.props.cols) {
            this.setState({columns: this.props.cols}, () => {
                this.readStoredConfigs()
            })
        }
    }

    pageSizeChange(pageSize: number) {
        this.setState({
            take: pageSize,
            limit: pageSize,
        }, () => {
            this.fetchData(true)
        });
        if (this.props.onPageSizeChange) {
            this.props.onPageSizeChange();
        }
    }

    pageChange(page: number) {
        this.setState({
            page: page > 0 ? page : 0,
        }, this.fetchData);
    }

    openSettingsModal = () => this.setState({showSettingsModal: true})
    closeSettingsModal = () => this.setState({showSettingsModal: false})

    colorRows(index: number) {
        if (index % 2) {
            return 'white'
            // return '#f7f7f7'
        }
        // return 'white'
        return '#f7f7f7'
    }

    download = async () => {

        const currentRecords = this.state.data;
        const columns = this.state.columns;
        if (currentRecords && columns) {
            var data_to_download = [];
            for (var index = 0; index < currentRecords.length; index++) {
                let record_to_download: any = {};
                for (var colIndex = 0; colIndex < columns.length - 2; colIndex++) {
                    var row: T = currentRecords[index]
                    var header = columns[colIndex].Header
                    var accessor = columns[colIndex].accessor

                    let currentRecord: any;
                    if (accessor) {
                        currentRecord = this.getValueAtAccessor(row, accessor) as string;
                    }


                    if (currentRecord !== undefined) {
                        record_to_download[header as keyof any] = currentRecord
                    }
                }
                data_to_download.push(record_to_download);
            }
            console.log("data_to_download", data_to_download[0].toString())
            const csv = this.jsonToCsv(data_to_download);
            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8,'})
            const csvURL = window.URL.createObjectURL(blob);
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', `${this.props.name}.csv`);
            tempLink.click();
            console.log(csv);
        }
    }

    getValueAtAccessor = (data: any, accessor: Accessor): any => {
        if (typeof accessor === 'function') {
            const value = accessor(data);
            if (React.isValidElement(value)) {
                const renderedValue = renderToStaticMarkup(value);
                const div = document.createElement('div');
                div.innerHTML = renderedValue;
                const elements = div.querySelectorAll('*');
                elements.forEach(element => {
                    if (!element.textContent) {
                        element.parentNode?.removeChild(element);
                    }
                });
                return div.textContent;
            }
            return value;
        } else if (typeof accessor === 'string') {
            return data[accessor];
        } else if (Array.isArray(accessor)) {
            let value = data;
            for (let i = 0; i < accessor.length; i++) {
                value = value[accessor[i]];
            }
            if (React.isValidElement(value)) {
                const renderedValue = renderToStaticMarkup(value);
                const div = document.createElement('div');
                div.innerHTML = renderedValue;
                const elements = div.querySelectorAll('*');
                elements.forEach(element => {
                    if (!element.textContent) {
                        element.parentNode?.removeChild(element);
                    }
                });
                return div.textContent;
            }
            return value;
        }
    }


    getPageSize() {
        let minRows = this.props.minRows ? this.props.minRows : 5
        if (this.props.ignorePagination != true) {
            return this.state.take
        } else {
            if (this.state.data && this.state.data.length > minRows) {
                return this.state.data.length
            } else {
                return minRows
            }
        }
    }

    render() {
        const columns = this.state.columns;
        if (columns.length > 0 && !columns.find(f => f.id == "settings")) {
            columns.push({
                id: 'settings',
                width: 40,
                sortable: false,
                resizable: false,
                filterable: false,
                Header: () => {
                    return <div className='text-align-right fullWidth'>
                        <span style={{background: 'white'}}>
                            <Icon link name='settings' onClick={this.openSettingsModal}/>
                        </span>
                    </div>
                }
            })
        }
        if (!this.props.disableExport && columns.length > 0 && !columns.find(f => f.id == "export")) {
            columns.push({
                id: 'export',
                width: 40,
                sortable: false,
                resizable: false,
                filterable: false,
                Header: () => {
                    return <div className='text-align-right fullWidth'>
                        <span style={{background: 'white'}}>
                            <Icon link name='file excel' onClick={this.download}/>
                        </span>
                    </div>
                }
            })
        }
        const containerHeight: string = this.props.noFixedHeight == true ? "unset" : this.props.containerHeight ?? "unset"

        return <div id={this.props.id}
                    style={{
                        minHeight: "100px",
                        height: containerHeight,
                        padding: 0,
                        marginTop: "5px"
                    }}>
            {!this.state.isLoadingConfigs && !this.state.isLoadingData ?
                <ReactTable
                    filterable={this.props.filterable}
                    collapseOnDataChange={this.props.collapseOnDataChange != undefined ? this.props.collapseOnDataChange : true}
                    data={this.state.data}
                    pages={this.state.pages}
                    page={this.state.page}
                    manual={!(this.props.manual === false)}
                    columns={columns}
                    showPagination={!this.props.ignorePagination}
                    pageSize={this.getPageSize()}
                    pageSizeOptions={[5, 25, 50, 100, 250, 500]}
                    className="-striped -highlight"
                    loading={this.state.isLoadingConfigs || this.state.isLoadingData}
                    onFetchData={(state, instance) => {
                        // console.log('STATE of The Table:', state)
                    }}
                    onResizedChange={(newResized, event) => {
                        this.onColumnResize(newResized)
                    }}
                    onSortedChange={(newSorted, column, shiftKey) => {
                        // console.log('newSorted', newSorted)
                        // console.log('column', column)
                        // console.log('shiftKey', shiftKey)
                        // this.saveTableConfigsToLocalStorage('general', 'sorted', newSorted);
                        this.setState({
                            sortByColumn: (this.props.formatSortByEnumValue) ? this.props.formatSortByEnumValue(newSorted[0].id) : newSorted[0].id,
                            sortDirection: newSorted[0].desc ? SortDirectionEnum.DESC : SortDirectionEnum.ASC
                        }, this.fetchData)
                    }}
                    onFilteredChange={this.props.onFilteredChange}
                    /*onExpandedChange={(newExpanded, index, event) => {
                        console.log("onExpandedChange")
                    }}*/
                    onPageChange={(pageIndex) => {
                        this.pageChange(pageIndex)
                    }}
                    onPageSizeChange={(newPageSize) => {
                        this.pageSizeChange(newPageSize)

                    }}
                    previousText={"Previous"}
                    nextText={"Next"}
                    loadingText={"Loading" + ' ...'}
                    noDataText={"NoRows"}
                    pageText={"Page"}
                    ofText={"Of"}
                    rowsText={"Rows"}
                    getTrProps={(state: any, rowInfo?: RowInfo, column?: undefined, instance?: any) => {
                        return () => {
                            // console.log("getTRPROPS: state", state)
                            // console.log("getTrGroupProps rowInfo", rowInfo)
                            // console.log("getTrGroupProps column", column)
                            // console.log("getTrGroupProps instance", instance)
                            if (this.props.getTrProps) {
                                this.props.getTrProps(state, rowInfo, instance);
                            }
                        }
                    }}
                    getTbodyProps={(finalState, rowInfo, column, instance) => {
                        return {style: {overflowX: "hidden"}}
                    }}
                    getTdProps={(state: any, rowInfo?: RowInfo, column?: Column) => {
                        if (rowInfo) {
                            return {
                                onMouseDown: (event: any, handleOriginal: any) => {
                                    if (event.button === 1 && this.props.onRowMiddleMouseDown) {
                                        event.preventDefault()
                                        this.props.onRowMiddleMouseDown(rowInfo, event, state)
                                    }
                                },
                                onClick: (event: any, handleOriginal: any) => {
                                    const classList: string = event.target.classList.value;
                                    if (classList.includes("rt-expander")) {
                                        handleOriginal()
                                    } else {
                                        if (rowInfo.groupedByPivot && this.props.onParentRowClick) {
                                            this.props.onParentRowClick(rowInfo, event, state);
                                        } else if (this.props.onRowClick) {
                                            this.props.onRowClick(rowInfo, event, state, column);
                                        }
                                    }
                                },
                                style: {
                                    background: rowInfo.index != undefined && rowInfo.index === this.props.selectedRowIndex ? '#00afec' : this.colorRows(rowInfo.viewIndex),
                                    color: rowInfo.index != undefined && rowInfo.index === this.props.selectedRowIndex ? 'white' : 'black'
                                }

                            };

                        } else {
                            return {
                                onClick: (event: any) => {
                                    if (rowInfo && this.props.onRowClick) {
                                        // console.log("RowClick", rowInfo.row)
                                        // console.log("RowClickEvent", event)
                                        this.props.onRowClick(rowInfo);
                                        // this.props.history.push("/tour/" + rowInfo.row.id)
                                    }
                                }
                            };
                        }
                    }}
                    pivotBy={this.props.pivotBy}
                    // getTrGroupProps={(finalState: any, rowInfo: undefined, column: undefined, instance?: any) => {
                    //     console.log("getTrGroupProps finalState", finalState)
                    //     console.log("getTrGroupProps rowInfo", rowInfo)
                    //     console.log("getTrGroupProps column", column)
                    //     console.log("getTrGroupProps instance", instance)
                    //     return undefined
                    // }
                    // }
                    // column={this.props.globalColumnProps ?? {}}
                    style={{
                        ...{
                            cursor: "pointer",
                            height: "100%",
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }, ...this.props.style
                    }}
                    defaultFilterMethod={this.props.defaultFilterMethod}
                    // getTheadFilterProps={(finalState: any, rowInfo: undefined, column: undefined, instance?: any) => {
                    // console.log("getTheadFilterProps finalState", finalState)
                    // console.log("getTheadFilterProps rowInfo", rowInfo)
                    // console.log("getTheadFilterProps column", column)
                    // console.log("getTheadFilterProps instance", instance)
                    // return {}
                    // }}
                    // getTheadFilterTrProps={(finalState: any, rowInfo?: RowInfo, column?: undefined, instance?: any) => {
                    // console.log("getTheadFilterTrProps finalState", finalState)
                    // console.log("getTheadFilterTrProps rowInfo", rowInfo)
                    // console.log("getTheadFilterTrProps column", column)
                    // console.log("getTheadFilterTrProps instance", instance)
                    // return {}
                    // }}
                    // getTheadFilterThProps={(finalState: any, rowInfo?: undefined, column?: Column, instance?: any) => {
                    // console.log("getTheadFilterThProps finalState", finalState)
                    // console.log("getTheadFilterThProps rowInfo", rowInfo)
                    // console.log("getTheadFilterThProps column", column)
                    // console.log("getTheadFilterThProps instance", instance)
                    // return {}
                    // }}
                    SubComponent={this.props.SubComponent}
                    TheadComponent={this.props.TheadComponent}
                    onExpandedChange={this.props.onExpandedChange}
                    expanded={this.props.expanded}
                    // resolveData={(data: any) => {
                    //
                    //     console.log("resolveDataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",data)
                    //     return data
                    // }}
                />
                : <ReactTable
                    style={{}}
                    defaultPageSize={25}
                    noDataText={""}
                    columns={columns}
                    pageSize={25}
                    showPagination={false}
                    loading
                />}
            <Modal
                open={this.state.showSettingsModal}
                onClose={this.closeSettingsModal}
                size={'tiny'}
                closeOnDimmerClick={false} closeIcon
            >
                <Modal.Header>
                    Settings
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <CustomTableSettingsComponent
                            onConfigChange={this.dispatch}
                            cols={[...this.state.columns]}
                            name={this.props.name}/>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </div>

    }

    private onColumnResize(newResized: Resize[]) {
        let newConfig: any = {};
        newResized.forEach((colConfig: Resize) => {
            newConfig[colConfig.id] = colConfig.value;
        });
        this.saveTableConfigsToLocalStorage('col', 'width', newConfig)
    }

    private saveTableConfigsToLocalStorage(settingOf: string, settingName: string, newConfigObj: any) {
        let CustomTableConfigString = localStorage.getItem(CustomTableConfig);
        let configs = CustomTableConfigString ? JSON.parse(CustomTableConfigString) : undefined;
        this.savedSettings = configs || {};
        if (!this.savedSettings[this.props.name + 'Comp']) {
            this.savedSettings[this.props.name + 'Comp'] = {};
        }
        if (!this.savedSettings[this.props.name + 'Comp'][settingOf]) {
            this.savedSettings[this.props.name + 'Comp'][settingOf] = {};
        }
        if (settingOf === 'col') {
            Object.keys(newConfigObj).forEach((colID) => {
                if (!this.savedSettings[this.props.name + 'Comp'][settingOf][colID]) {
                    this.savedSettings[this.props.name + 'Comp'][settingOf][colID] = {};
                }
                this.savedSettings[this.props.name + 'Comp'][settingOf][colID][settingName] = newConfigObj[colID];
            })
        } else {
            this.savedSettings[this.props.name + 'Comp'][settingOf][settingName] = newConfigObj;
        }
        localStorage.setItem(CustomTableConfig, JSON.stringify(this.savedSettings))
    }


    readStoredConfigs(updateData: boolean = false) {
        this.setState({isLoadingConfigs: true})
        let CustomTableConfigString = localStorage.getItem(CustomTableConfig);
        if (CustomTableConfigString) {
            let configs = JSON.parse(CustomTableConfigString);
            let newState: any = {};
            let updateState = false;
            let cols: Column[] = [...this.state.columns];
            if (configs && configs[this.props.name + 'Comp']) {
                this.savedSettings = configs[this.props.name + 'Comp'];
                if (this.savedSettings['col']) {
                    cols.forEach((col: Column) => {
                        // Check for stored col widths
                        if (col.id && this.savedSettings['col'][col.id]) {
                            if (this.savedSettings['col'][col.id].width) {
                                col.width = this.savedSettings['col'][col.id].width;
                            }
                            col.show = this.savedSettings['col'][col.id].show == null ? true : this.savedSettings['col'][col.id].show;
                            updateState = true;
                        }
                    });
                }
                if (this.savedSettings.general) {
                    if (this.savedSettings.general.colOrder) {
                        const reorderedCols: Column[] = [];
                        this.savedSettings.general.colOrder.forEach((colID: string) => {
                            cols.forEach((col) => {
                                if (colID === col.id) {
                                    reorderedCols.push(col);
                                }
                            })
                        })
                        cols = reorderedCols;
                        this.savedSettings.updatedCols = newState['columns'];
                        updateState = true;
                    }
                    if (this.savedSettings.general.lastRequest) {

                        newState['page'] = this.savedSettings.general.lastRequest.page;
                        newState['take'] = this.savedSettings.general.lastRequest.take;
                        newState['sortByColumn'] = this.savedSettings.general.lastRequest.sortByColumn;
                        newState['sortDirection'] = this.savedSettings.general.lastRequest.sortDirection;
                        updateState = true;
                    }
                }
            }
            if (this.state && updateState) {
                newState['columns'] = cols;
                this.setState(newState)
            }
        }
        this.setState({isLoadingConfigs: false})
    }
}
