import * as React from "react";
import {Dropdown} from "semantic-ui-react";
import {
    addMany,
    changeActiveConfig,
    changeActiveMode,
    getActiveConfig,
    getActiveMode,
    getActiveRules,
    getSaveToConfig,
    getSpecificActiveRules,
    ParserOptions,
    reset,
    selectRules,
    setParseOptions,
    setSplitterOptions,
    SplitterOptions
} from "../../slices/ruleSlice";
import {useDispatch, useSelector} from "react-redux";
import {ConfigContext} from "../../../../../main/context/ConfigContext";
import {
    GenericXlsConfigurationOverride,
    GenericXlsExtractionRule,
    GenericXlsSpecificConfiguration
} from "../../../../../../generated";
import {configStorage} from "../../../../../../ConfigStorage";
import InputFormatEnum = GenericXlsConfigurationOverride.InputFormatEnum;
import {setEditSelectedRuleMode, setRuleToDisplay} from "../../slices/rightMenuSlice";
import {updateConfig, workingConfig} from "../../../../../main/pages/configChange/ConfigSignal";

interface SelectConfigProps {
}

export function SelectConfig(props: React.PropsWithChildren<SelectConfigProps>) {
    const dispatch = useDispatch();
    const activeRules = useSelector(getActiveRules);
    const specificActiveRules = useSelector(getSpecificActiveRules);
    const activeConfig = useSelector(getActiveConfig);
    const activeMode = useSelector(getActiveMode);
    const context = React.useContext(ConfigContext);
    const [localConfig, setLocalConfig] = React.useState(null);
    const [specificConfigs, setSpecificConfigs] = React.useState<GenericXlsSpecificConfiguration[]>([]);
    const autoSaveConfig = useSelector(getSaveToConfig)
    const localRules = useSelector(selectRules)


// Load the configuration from context
    React.useEffect(() => {
        // console.log("effect 1");
        // console.log("localConfig in effect 1", localConfig);

        if (!localConfig && context.companyId) {
            // console.log("localConfig is null, calling loadConfig()");
            loadConfig();
        }
    }, [context.companyId]); // added context.companyId to the dependency array


    React.useEffect(() => {
        // console.log("effect 2");
        // console.log("context and autoSaveConfig in effect 2", context, autoSaveConfig);

        if (context.companyId) {
            loadConfig();
        }
    }, [context, autoSaveConfig]);

    const loadConfig = (): any => {
        if (workingConfig.value && workingConfig.value != "null") {
            const configObject = JSON.parse(workingConfig.value);
            setLocalConfig(configObject);
//             console.log("%c config is being updated", "color:yellow")
            setSpecificConfigs(configObject.genericXlsConfiguration?.specificConfiguration ?? []);
        } else {
            configStorage.getElement(context.companyId).then((c) => {
                setLocalConfig(c.config);
//                 console.log("%c specific config is being updated", "color:yellow")
                setSpecificConfigs(c.config.genericXlsConfiguration?.specificConfiguration ?? []);
                updateConfig(JSON.stringify(c.config, null, 4), false, false);
            });
        }
    }

    // Define loadRules inside a useCallback to ensure it has the most recent state
    React.useEffect(() => {
        let rules: GenericXlsExtractionRule[] = [];
        let parserOptions: ParserOptions | undefined = undefined;
        let splitterOptions: SplitterOptions | undefined = undefined;
        let splitterMode: InputFormatEnum | undefined = undefined;

        if (activeConfig !== "default") {
            const activeSpecificConfig = specificConfigs.find((config) => config.verboseName === activeConfig);
            if (activeSpecificConfig) {
                parserOptions = activeSpecificConfig.override.immediateFormatParserOptions as ParserOptions
                splitterOptions = (activeSpecificConfig.override.splitterOptions ?? {}) as SplitterOptions
                splitterMode = activeSpecificConfig.override.inputFormat
                if (specificActiveRules && specificActiveRules=="spcExt"){
                    rules = activeSpecificConfig.override.extractionRules
                } else{
                    rules = activeSpecificConfig.applyWhen.onRuleMatch
                }
            }
        } else {
            // Load the default rules if no specific configuration is selected
            rules = activeRules === "ext" ? localConfig?.genericXlsConfiguration?.extractionRules ?? [] : localConfig?.genericXlsConfiguration?.splittingRules ?? [];
            parserOptions = localConfig?.genericXlsConfiguration?.immediateFormatParserOptions ?? {}
            splitterOptions = localConfig?.genericXlsConfiguration?.splitterOptions ?? {}
            splitterMode = localConfig?.genericXlsConfiguration?.inputFormat
        }
        console.info("loadRules get called.")

        // dispatch(reset());
        // dispatch(setRuleToDisplay(null))
        dispatch(addMany(rules));
        dispatch(setParseOptions(parserOptions));
        dispatch(setSplitterOptions(splitterOptions));
        dispatch(changeActiveMode(splitterMode));
    }, [localConfig,activeConfig]);

    React.useEffect(() => {
        let rules = activeRules === "ext" ? localConfig?.genericXlsConfiguration?.extractionRules ?? [] : localConfig?.genericXlsConfiguration?.splittingRules ?? [];

        dispatch(setRuleToDisplay(null))
        dispatch(addMany(rules))


    }, [activeRules]);

    React.useEffect(() => {
        const activeSpecificConfig = specificConfigs.find((config) => config.verboseName === activeConfig);
        let rules: GenericXlsExtractionRule[]
        if (activeSpecificConfig) {
            if (specificActiveRules && specificActiveRules=="spcExt"){
                rules = activeSpecificConfig.override.extractionRules
            } else{
                rules = activeSpecificConfig.applyWhen.onRuleMatch
            }
        }
        dispatch(setRuleToDisplay(null))
        dispatch(addMany(rules));

    }, [specificActiveRules]);


    // Effect to load rules on mount and when activeConfig changes
    // React.useEffect(() => {
    //     if (config) {
    //         loadRules();
    //     }
    // }, [config]);

    // React.useEffect(() => {
    //    loadRules()
    // },[activeConfig])



    const configOptions = [{
        key: "default",
        text: "Default",
        value: "default",
    },
        ...specificConfigs.map(config => ({
            key: config.verboseName,
            text: config.verboseName,
            value: config.verboseName,
        }))]


    return (
        <Dropdown defaultValue={"default"}
                  size={"mini"}
                  value={activeConfig}
                  search selection options={configOptions}
                  onChange={(event, data)=> {
                      dispatch(changeActiveConfig(data.value.toString()))
                      dispatch(setEditSelectedRuleMode(false))
                  }

                }
                  disabled={activeRules=="spt"}/>
    );
}
