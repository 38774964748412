import * as React from "react";
import {Tab} from "semantic-ui-react";
import {MatrixDocInputTextView} from "./MatrixDocInputTextView";
import {MatrixDocInputFileView} from "./MatrixDocInputFileView";

const panes = [
    { menuItem: 'Text', render: props => <Tab.Pane><MatrixDocInputTextView changeXRequestId={props.changeXRequestId} output={props.output} /></Tab.Pane> },
    { menuItem: 'File', render: props => <Tab.Pane><MatrixDocInputFileView changeXRequestId={props.changeXRequestId} reload={props.reload}/></Tab.Pane> },
]

const MatrixDocInputTabMenu = props => <Tab
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.matrixDocInputTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("matrixDocInputTab", data.activeIndex)
    }}
/>

export default MatrixDocInputTabMenu