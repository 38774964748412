import * as React from 'react';
import {Message} from 'semantic-ui-react';
import {v4 as uuid} from "uuid";

export class ValidationResult {
    successful: boolean
    errorTag?: string

    constructor(success: boolean, tag?: string) {
        this.successful = success
        this.errorTag = tag
    }
}

export class ValidationError {
    tag: string

    constructor(tag: string) {
        this.tag = tag
    }
}

export interface FormField {
    name: string
    validationRules: Array<(val: any, form: FormGroup) => ValidationResult>
    asyncValidationRules?: Array<(val: any, form: FormGroup) => Promise<ValidationResult>>
    value?: any
    resetValue?: any
}

export function validatePasswordsMatch(tag: string, pw1Field: string) {
    return (val: any, form: FormGroup) => {
        if (val != form.getValue(pw1Field)) {
            return new ValidationResult(false, tag)
        }
        return new ValidationResult(true, undefined)
    }
}

export function validateLength(tag: string, min: number, max: number = 256): (val: any, form: any) => ValidationResult {
    return (val, form) => {

        if (val == undefined || val == null)
            return new ValidationResult(false, tag)

        if (val.length >= min) {
            if (max != null && val.length <= max) {
                return new ValidationResult(true, undefined)
            }
            return new ValidationResult(false, tag)
        }
        return new ValidationResult(false, tag)
    }
}

export function validateRequired(isRequiredFromConfig?: boolean): (val: any, form: any) => ValidationResult {
    return (val, form) => {
        if (isRequiredFromConfig === true && !val) {
            return new ValidationResult(false, "required")
        } else {
            return new ValidationResult(true, undefined)
        }
    }
}

export function validateLimit(fieldToCheck: string,limitFromConfig?: number): (val: any, form: any) => ValidationResult {
    return (val, form) => {
        console.log("val, form", val, form)
        console.log("form.getField(fieldToCheck)", form.getField(fieldToCheck))
        console.log("form.getField(fieldToCheck).value", form.getField(fieldToCheck).value)
        if (limitFromConfig && form.getField(fieldToCheck).value.length > limitFromConfig) {
            return new ValidationResult(false, "limit reached")
        } else {
            return new ValidationResult(true, undefined)
        }
    }
}

export function validateEmail(tag: string): (val: any, form: any) => ValidationResult {
    return (val, form) => {
        if (val == undefined || val == null)
            return new ValidationResult(false, tag)
        let emailValid = val.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (emailValid != null) {
            return new ValidationResult(true, undefined)
        }
        return new ValidationResult(false, tag)
    }
}

export class FormGroup {

    formDefinition: { [id: string]: FormField };
    errors: { [id: string]: ValidationError | undefined }
    cleanFields: Array<String> = []
    validationCallBack: { [id: string]: undefined | ((success: boolean) => void) }
    children: { [name: string]: FormGroup }

    onValidationFinished = () => {
    }

    constructor(forms: FormField[], children?: { [name: string]: FormGroup }) {

        let dict_: any = {}

        for (let i = 0; i < forms.length; i++) {
            const key = forms[i].name
            dict_[key] = forms[i]
        }

        this.formDefinition = dict_
        this.errors = {}
        this.validationCallBack = {}
        this.children = children ?? {}
    }

    getFormFieldsArray() {
        return Object.keys(this.formDefinition).map(k => {
            return {
                name: this.formDefinition[k].name,
                value: this.formDefinition[k].value,
                resetValue: this.formDefinition[k].resetValue
            }
        })
    }

    addValidationCallback(name: string, callback: (success: boolean) => void) {
        this.validationCallBack[name] = callback
    }

    removeValidationCallback(name: string) {
        this.validationCallBack[name] = undefined
    }

    getChildrenAsArray() {
        return Object.keys(this.children).map(k => {
            return {
                name: k,
                form: this.children[k],
            }
        })
    }

    getChildForm(name?: string): FormGroup | undefined {
        if (name && this.children[name]) {
            return this.children[name]
        } else {
            // console.log("Child form with name as '" + name + "' does not exist in this Form Group.")
        }
        return undefined
    }

    hasChildForm(childName?: string) {
        if (!childName) {
            // console.log("childName is undefined")
            return false
        }
        return !!this.children[childName]
    }

    addOrUpdateChildForm(name: string, form?: FormGroup) {
        this.children[name] = form ?? new FormGroup([])
    }

    setChildForm(name: string, form: FormGroup) {
        this.children[name] = form
    }

    removeChildForm(name: string) {
        delete this.children[name]
    }

    removeField(key: string) {
        if (this.formDefinition[key]) {
            delete this.formDefinition[key]
        }
        if (this.errors[key]) {
            delete this.errors[key]
        }
    }

    getValuesArray() {
        return Object.keys(this.formDefinition).map((key: string) => this.formDefinition[key].value)
    }

    async validateField(name: string): Promise<boolean> {
        let field = this.formDefinition[name]
        if (this.cleanFields.indexOf(name) != -1) {
            return true
        }
        let validationSuccessful = true
        if (field?.validationRules != null) {
            for (let i = 0; i < field.validationRules.length; i++) {
                let rule = field.validationRules[i]
                // console.log("FIELD validationRules rule: ", rule)

                let result = rule(field.value, this)

                if (!result.successful) {
                    validationSuccessful = false
                    this.errors[field.name] = new ValidationError(result.errorTag ?? "unknown")
                }
                // console.log("FIELD validation result: ", this.errors[field.name])
            }
        }

        if (field?.asyncValidationRules != null) {
            for (let i = 0; i < field.asyncValidationRules.length; i++) {
                let rule = field.asyncValidationRules[i]

                let result = await rule(field.value, this)

                if (!result.successful) {
                    validationSuccessful = false
                    this.errors[field.name] = new ValidationError(result.errorTag ?? "unknown")
                }
            }
        }

        if (validationSuccessful) {
            if (field?.name != null) {
                // this.errors[field.name] = undefined
                delete this.errors[field.name]
            }
        }

        this.cleanFields.push(name)

        this.onValidationFinished()
        const validationCallBack = this.validationCallBack[field.name]
        if (validationCallBack) {
            validationCallBack(validationSuccessful)
        }
        return validationSuccessful
    }

    async forceValidation(): Promise<void> {
        for (let entry of Object.keys(this.formDefinition)) {
            await this.validateField(entry)
        }
    }

    hasFieldError(field: string, tag?: string): boolean {
        if (tag) {
            return !!this.errors[field] && this.errors[field]?.tag == tag
        } else {
            return !!this.errors[field]
        }
    }

    async hasError(checkChildren?: boolean): Promise<boolean> {
        // checks the errors in children forms as well unless mentioned explicitly

        await this.forceValidation()
        let hasError = false
        for (let key of Object.keys(this.errors)) {
            if (this.errors[key] != null) {
                hasError = true
            }

        }
        if (checkChildren) {
            for (let keys of Object.keys(this.children)) {
                if (await this.children[keys].hasError(checkChildren)) {
                    hasError = true
                }
            }
        }
        return hasError
    }

    hasErrorWithOutFieldValidation(checkChildren: boolean = true): boolean {
        // Only works if fields have been validated beforehand.
        // If you need to check the fields for errors use "hasError()" function
        // checkChildren: checks the errors in children forms as well unless mentioned explicitly

        for (let key of Object.keys(this.errors)) {
            if (this.errors[key] != null) {
                // hasError = true
                return true
            }
            if (checkChildren) {
                for (let keys of Object.keys(this.children)) {
                    if (this.children[keys].hasErrorWithOutFieldValidation()) {
                        return true
                    }
                }
            }
        }
        return false
    }

    getValue(field: string) {
        if (this.formDefinition[field]) {
            return this.formDefinition[field].value
        } else {
            console.log("field:", field, "formDefinition:", this.formDefinition)
        }
    }

    getField(fieldKey: string) {
        return this.formDefinition[fieldKey]
    }

    hasField(fieldKey?: string) {
        if (!fieldKey) {
            console.log("fieldKey is undefined")
            return false
        }
        return !!this.formDefinition[fieldKey]
    }

    setField(fieldKey: string, fieldData: FormField) {
        if (this.formDefinition[fieldKey]) {
            this.formDefinition[fieldKey] = fieldData
        } else {
            this.formDefinition = {...this.formDefinition, [fieldKey]: fieldData}
        }
    }

    setFieldValue(fieldKey: string, fieldData?: any) {
        if (this.formDefinition) {
            if (this.formDefinition[fieldKey]) {
                this.handleInputChange({target: {value: fieldData, name: fieldKey}})
                // this.formDefinition[fieldKey].value = fieldData
            } else {
                this.formDefinition[fieldKey] = {
                    name: fieldKey,
                    value: undefined,
                    validationRules: []
                }
                this.handleInputChange({target: {value: fieldData, name: fieldKey}})
            }
        } else {
            console.log("FormDefinition is not defined.")
        }
    }

    setInitialData(obj: any) {
        let dict_: any = obj

        for (let key of Object.keys(dict_)) {
            this.formDefinition[key] = {...this.formDefinition[key], value: dict_[key]}
        }

    }

    getData(): any {
        let dict_: any = {}

        for (let key of Object.keys(this.formDefinition)) {

            dict_[key] = this.formDefinition[key].value
        }

        return dict_
    }

    handleInputChange(event: any, callback?: (success: boolean) => void) {
        const value = event.target.value;
        const name = event.target.name;

        if (this.formDefinition[name]){
            this.formDefinition[name].value = value
        }

        this.cleanFields = this.cleanFields.filter(x => x != name)
        setTimeout(() => {
            this.validateField(name).then((success) => {
                if (callback) {
                    callback(success)
                }
            })

        }, 0)
    }

    resetForm(resetChildren?: boolean) {
        this.cleanFields = []
        Object.keys(this.formDefinition).forEach(k => {
            this.formDefinition[k].value = "";
        })
        if (resetChildren) {
            for (let keys of Object.keys(this.children)) {
                this.children[keys].resetForm()
            }
        }
    }

    copyDataFromFormGroup(formToCopy: FormGroup) {
        this.formDefinition = formToCopy.formDefinition
        this.children = formToCopy.children
    }

}

type FormErrorProps = {
    group: FormGroup
    field: string
    tag: string
}

export class FormErrorMessage extends React.Component<FormErrorProps, any> {
    render() {
        if (this.props.group.hasFieldError(this.props.field, this.props.tag)) {
            return (<Message
                error
                content={this.props.children}
            />)
        }
        return (null)
    }
}
