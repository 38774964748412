import * as React from "react";
import {useContext} from "react";
import ExportSimOutputTabMenu from "./ExportSimOutputTabMenu";
import {ConfigContext} from "../../../../context/ConfigContext";
import {QueuedEvent} from "../../../../../../generated/index";
import {workingConfig} from "../../../configChange/ConfigSignal";

export interface ExportSimOutputSideProps {

    queuedEvent: QueuedEvent,
    xRequestId: string,
    response: string,
    config: string,
    encoding: string,
}

export function ExportSimOutputSide(props: React.PropsWithChildren<ExportSimOutputSideProps>) {

    const context = useContext(ConfigContext)

    const [selectedOutput, setSelectedOutput] = React.useState(null)
    const [selectedOutputBase64, setSelectedOutputBase64] = React.useState(null)
    const [selectedFile, setSelectedFile] = React.useState(null)
    const [outputFileIndex, setOutputFileIndex] = React.useState(0)


    React.useEffect(() => {
        setOutputFileIndex(0)
        selectOutputFile(0)
    }, [props.response])

    function outputEmpty() {
        return context.exportSimOutput == null || context.exportSimOutput == "" || context.exportSimOutput == "204 - No Content"
    }

    function getSelectedOutput() {
        if (outputEmpty()) {
            return context.exportSimOutput
        } else {
            console.log("getSelectedOutput: ", selectedOutput)
            return selectedOutput
        }
    }


    function getExportSimOutputFileByIndex(index: number = outputFileIndex) {
        if (context.exportSimOutput != "") {
            return JSON.parse(context.exportSimOutput).resultFiles[index]
        } else {
            return null
        }
    }

    function getExportSimOutputStringByIndex(index: number = outputFileIndex) {
        console.log("[getExportSimOutputStringByIndex]")
        let file = getExportSimOutputFileByIndex(index)
        let str = filesBase64ContentToString(file)

        if (str == null) {
            return JSON.parse(context.exportSimOutput).statusMessage
        }
        return filesBase64ContentToString(file)
    }

    function getExportSimOutputBase64ByIndex(index: number = outputFileIndex) {
        let file = getExportSimOutputFileByIndex(index)
        let str = filesBase64ContentToString(file)
        if (str == null) {
            return JSON.parse(context.exportSimOutput).statusMessage
        }
        return file?.base64Content
    }

    function filesBase64ContentToString(file) {
        if (file) {
            return atou(file)
        } else {
            return null
        }
    }

    function atou(file) {
        let b64 = file.base64Content
        if (file.encoding == "ISO-8859-1") {
            return atob(b64)
        }

        try {
            return decodeURIComponent(escape(atob(b64)));
        } catch (e) {
            console.log("Unable to decodeURIComponent. Exception: ", e)

            return atob(b64)
        }
    }

    async function selectOutputFile(newOutputFileIndex = outputFileIndex) {
        if (context.exportSimOutput != null && context.exportSimOutput != "" && context.exportSimOutput != "204 - No Content") {
            let result = JSON.parse(context.exportSimOutput).resultFiles
            console.log(result.length + " files responded")
            await setOutputFileIndex(newOutputFileIndex)
            setSelectedOutput(getExportSimOutputStringByIndex(outputFileIndex))
            setSelectedOutputBase64(getExportSimOutputBase64ByIndex(outputFileIndex))
            setSelectedFile(getExportSimOutputFileByIndex(outputFileIndex))
        }

    }


    return <ExportSimOutputTabMenu
        externOutput={getSelectedOutput()}
        queuedEventOutput={props.queuedEvent}
        configOutput={workingConfig.value}
        xRequestId={props.xRequestId}
        context={context}
        file={selectedOutputBase64}
        fileName={selectedFile?.fileName}
    />
}