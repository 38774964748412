import {LoadDataButton} from "./LoadDataButton";
import * as React from "react";
import {useSelector} from "react-redux";
import {getActivePage} from "../slices/tableSlice";
import {FileTabBar} from "./FileTabBar";
import {TableView} from "./TableView";
import {IfBox} from "../../../../style/if";
import {MainViewFloatingButtons} from "./buttons/MainViewFloatingButtons";

export interface MainViewProps {

}

export function MainView(props: React.PropsWithChildren<MainViewProps>) {
    const activePage = useSelector(getActivePage)

    return <div className={"flex1"} style={{position: "relative"}}>
        <IfBox shouldShow={activePage?.pages.length > 0}
               else={
                   <LoadDataButton/>
               }>
            <MainViewFloatingButtons/>
            <FileTabBar/>
            <div className={"flex1"}>
                <TableView/>
            </div>

        </IfBox>
    </div>

}