import { Icon } from "leaflet";
import * as React from "react";
import { Marker, Polygon, Popup, Circle } from "react-leaflet";
import { formatStopLocationStatus, getFormattedActionKindStatusEnumByIndex, formatDate } from "../../../format";
import {StopAction, StopLocation, StopLocationStatus} from "../../../generated/api";
import { IfBox } from "../../style/if";
import {mergeAndOrderStops} from "../../../utils";


interface StopMap {
    stopLocations: StopLocation[],
    text: string,
    status: StopLocationStatus[],
    actions: StopAction[],
    locationNumbers: number[]
}

type StopListMarkerProps = {
    stops: StopLocation[]
    arrivalRadius?: number
    departureRadius?: number
    earlyArrivalRadius?: number
}

function getGeofenceCenter(geofence: any) {
    let lat = 0
    let lng = 0
    if (geofence == null) {
        return { "lat": 0, "lng": 0 }
    }
    [...geofence].forEach(element => {
        lat = lat + element.lat
        lng = lng + element.lng
    });
    lat = lat / geofence.length
    lng = lng / geofence.length
    return { "lat": lat, "lng": lng }
}

export const StopListMarkers: React.FC<StopListMarkerProps> = ({ stops, arrivalRadius, departureRadius, earlyArrivalRadius}) => {
    let stopMap: StopMap[] = [] // Born, 1/4; Elsendorf, 2; Regensburg 3;
    stops = mergeAndOrderStops(stops)
    for (let index = 0; index < stops.length; index++) {
        const stop = stops[index];
        if (stopMap?.find(stopMapEntry => {
            return stopMapEntry?.stopLocations.find(stopLocation => {
                if (stopLocation.uniqueLocationId == stop.uniqueLocationId) {
                    stopMapEntry.stopLocations.push(stop)
                    stopMapEntry.text = `${stopMapEntry.text}/${index + 1}`
                    stopMapEntry.locationNumbers.push(index + 1)
                    if (stopLocation.status != undefined) {
                        stopMapEntry.status.push(stopLocation.status)
                    }
                    if (stopLocation.actions != undefined) {
                        stopMapEntry.actions.concat(stopLocation.actions)
                    }
                    return true
                }
            })
        })) {

        } else {
            const el = {} as StopMap
            el.stopLocations = []
            el.stopLocations.push(stop)
            el.text = `${index + 1}`
            el.locationNumbers = []
            el.locationNumbers.push(index + 1)
            el.status = []
            if (stop.status != undefined) {
                el.status.push(stop.status)
            }
            el.actions = []
            if (stop.actions != undefined) {
                el.actions.concat(stop.actions)
            }
            stopMap.push(el)
        }
    }
    let zindex = 0
    return <React.Fragment>{stopMap.map((element: StopMap) => {
        zindex = zindex - 1
        const stop: StopLocation = element.stopLocations[0]
        if (stop.coordinates?.latitude && stop.coordinates?.longitude) {
            let txt = element.text
            let texty = "31"
            let fontsize = "30px"
            if (txt.length > 5) {
                txt = txt.split("/", 1)[0] + "/..."
            }
            switch (txt.length) {
                case 1:
                    break
                case 2:
                    texty = "29"
                    fontsize = "22px"
                    break
                case 3:
                    texty = "27"
                    fontsize = "18px"
                    break
                case 4:
                    texty = "26"
                    fontsize = "14px"
                    break
                case 5:
                    texty = "25"
                    fontsize = "13px"
                    break
                default:
                    texty = "25"
                    fontsize = "13px"
                    break
            }
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext("2d");
            let textx = 0
            if (ctx) {
                ctx.font = fontsize + "px Arial";
                textx = 15 - ctx.measureText(txt).width / 2;
            }
            let markerColor = "#00aad4"
            let partlyDoneMarker = ``
            let departedAllStops: boolean = true
            let departedSomeStops: boolean = false
            element.status.forEach(element => {
                if (element?.statusCode == StopLocationStatus.StatusCodeEnum.DEPARTED) {
                    departedSomeStops = true
                } else {
                    departedAllStops = false
                }
            })
            if (departedAllStops == true) {
                markerColor = "#44aa00"
            } else if (departedSomeStops) {
                partlyDoneMarker = `<circle cx="20" cy="20" r="18.8" stroke="#44aa00" stroke-width="2.4" stroke-dasharray='59' stroke-dashoffset='88.5' fill="white" fill-opacity="0" /><path fill="#44aa00" d="M20 60 S20 44 7 35 Q15 40 20 38 L20 60 Z"></path>`
            }
            let stopActionLoad = element.actions.find(element => element.kind == StopAction.KindEnum.LOAD ) != undefined
            let stopActionUnload = element.actions.find(element => element.kind == StopAction.KindEnum.UNLOAD ) != undefined
            let loadMarker = ``
            let unloadMarker = ``
            if (stopActionLoad == true) {
                loadMarker = `<path fill="${markerColor}" d="M9 48 L15 48 L12 43 Z"></path>`
            }
            if (stopActionUnload) {
                unloadMarker = `<path fill="${markerColor}" d="M31 43 L25 43 L28 48 Z"></path>`
            }
            let svgString = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="60"><circle cx="20" cy="20" r="18.8" stroke="${markerColor}" stroke-width="2.4" fill="white" /><path fill="${markerColor}" d="M20 60 S20 44 7 35 Q20 44 33 35 S20 44 20 60 Z"></path>${partlyDoneMarker}${loadMarker}${unloadMarker}<text x="${textx}" y="${texty}" style="font-family: arial;font-size:${fontsize}; fill: grey;">${txt}</text></svg>`
            let myIconUrl = "data:image/svg+xml," + encodeURIComponent(svgString)
            const stopIcon = new Icon({
                iconUrl: myIconUrl,
                iconSize: [40, 60],
                shadowSize: [40, 60],
                iconAnchor: [20, 60],
                shadowAnchor: [20, 60],
                popupAnchor: [0, -40]
            })
            let lat = stop.coordinates?.latitude
            let long = stop.coordinates?.longitude
            let geofence
            let geofence2
            let geofence3
            if (stop.multiPolygonGeofence) {
                geofence = (<Polygon key={"Geofence for stop " + stop.stopId} positions={JSON.parse(stop.multiPolygonGeofence)} />)
                const latlng = getGeofenceCenter(JSON.parse(stop.multiPolygonGeofence)[0])
                lat = latlng.lat
                long = latlng.lng
            } else {
                if (stop.coordinates?.latitude && stop.coordinates?.longitude && arrivalRadius) {
                    geofence = (<Circle key={"ArrivalGeofence for stop " + stop.stopId}
                                        center={[stop.coordinates?.latitude, stop.coordinates?.longitude]}
                                        color="#3388ff"
                                        radius={arrivalRadius}/>)
                }
            }
            if (stop.coordinates?.latitude && stop.coordinates?.longitude && departureRadius) {
                geofence2 = (<Circle key={"DepartureGeofence for stop " + stop.stopId}
                                     center={[stop.coordinates?.latitude, stop.coordinates?.longitude]}
                                     color="#96ffa1"
                                     radius={departureRadius} />)
            }
            if (stop.coordinates?.latitude && stop.coordinates?.longitude && earlyArrivalRadius) {
                geofence3 = (<Circle key={"EarlyArrivalGeofence for stop " + stop.stopId}
                                     center={[stop.coordinates?.latitude, stop.coordinates?.longitude]}
                                     color="#96c2ff"
                                     radius={earlyArrivalRadius} />)
            }

            return (<div style={{zIndex:zindex}}>
                {geofence3}
                {geofence2}
                {geofence}
                < Marker key={stop.stopId} icon={stopIcon} position={{ lat: lat, lng: long }} >
                    <Popup>
                        {stop.name}<br />
                        <IfBox shouldShow={stop.name2 != null}>
                            {stop.name2}<br />
                        </IfBox>
                        {stop.street} <br />
                        {stop.zipCode} {stop.city}<br />
                        {element.stopLocations.map((tempStop, i) => {
                            return (<div>
                                <br />
                                <text>{element.locationNumbers[i]}</text>
                                <br />
                                <p>action</p>: {getFormattedActionKindStatusEnumByIndex(tempStop.actions, 0)}
                                <br />
                                Status: {formatStopLocationStatus(tempStop.status)}
                                <br />
                                <p>departed</p>: {formatDate(tempStop.status?.departedTimestamp)}
                            </div>)
                        })}

                    </Popup>
                </Marker >
            </div>)
        }
    })}</React.Fragment>
}