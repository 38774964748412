import {Company, CompanyConfiguration} from "./generated";
import {authentication} from "./authentication";
import * as localforage from "localforage";

class ConfigStorageElement {
    companyId: string
    company: Company
    config: CompanyConfiguration
    unchangedConfig: CompanyConfiguration
}

export const CONFIG_STORAGE_KEY = "config_storage_key"

class ConfigStorage {

    constructor() {
    }

    async getAllElements() {
        if (authentication?.isAuthenticated != true) {
            console.log("%cLOCAL FORAGE not authenticated",'color: teal')
            return
        }
        let keys = await localforage.keys()
        let result = [] as ConfigStorageElement[]
        for (let key of keys) {
            if (key.startsWith(CONFIG_STORAGE_KEY)) {
                let element = await localforage.getItem(key) as ConfigStorageElement
                result.push(element)
            }
        }
        return result
    }

    async getElement(companyId: string) {
        if (authentication?.isAuthenticated != true) {
            console.log("%cLOCAL FORAGE not authenticated",'color: teal')
            return
        }
        return await localforage.getItem(CONFIG_STORAGE_KEY + "_" + companyId) as ConfigStorageElement
    }

    async setElement(companyId: string, newElement: ConfigStorageElement) {
        await localforage.setItem(CONFIG_STORAGE_KEY + "_" + companyId, newElement)
    }

    async updateConfig(companyId: string, newConfig: CompanyConfiguration, alsoSaveAsUnchangedConfig: boolean = false) {
        console.log("%cLOCAL FORAGE updateConfig",'color: teal')

        let found = await this.getElement(companyId)
        if (found) {
            console.log("%cLOCAL FORAGE found",'color: teal')

            found.config = newConfig
        } else {
            console.log("%cLOCAL FORAGE creating new one",'color: teal')

            found = new ConfigStorageElement()
            found.config = newConfig
            found.companyId = companyId
            found.company = authentication.company

        }
        if (alsoSaveAsUnchangedConfig) {
            found.unchangedConfig = newConfig
        }

        await this.setElement(companyId, found)

    }

    async deleteCompany(companyId: string) {
        await localforage.removeItem(CONFIG_STORAGE_KEY + "_" + companyId)
    }

    async deliberatelyUpdateUnchangedConfig(companyId: string, newConfig: CompanyConfiguration) {
        let found = await this.getElement(companyId)
        if (found) {
            found.unchangedConfig = newConfig
            await this.setElement(companyId, found)
        }
    }

}

export const configStorage = new ConfigStorage()