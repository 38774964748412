import * as React from "react";
import {useEffect} from "react";
import {Button, Icon, Input, Label, Popup, TextArea} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {Address, ParseTextRequest} from "../../../../../generated";
import {CompanyArchiveModal} from "./CompanyArchiveModal";
import {CompanyAddressEditModal} from "./CompanyAddressEditModal";
import Notifications, {notify} from 'react-notify-toast';
import {ConfigContext} from "../../../context/ConfigContext";


export interface CompanyEditPageProps {
}

export function CompanyEditPage(props: React.PropsWithChildren<CompanyEditPageProps>) {

    const context = React.useContext(ConfigContext)

    const auth = backend.withTokenAuthHeader(authentication.token)
    const [company, setCompany] = React.useState(authentication.company)

    const [editName, setEditName] = React.useState(false)
    const [editAddress, setEditAddress] = React.useState(false)
    const [editTag, setEditTag] = React.useState(false)

    const [isSaving, setIsSaving] = React.useState(false)
    const [isResetting, setIsResetting] = React.useState(false)
    const [aiIsLoading, setAiIsLoading] = React.useState(false)

    const [showArchiveModal, setShowArchiveModal] = React.useState(false)
    const [showAddressEditModal, setShowAddressEditModal] = React.useState(false)

    const [name, setName] = React.useState(company.name)
    const [tag, setTag] = React.useState(company.tag)
    const [address, setAddress] = React.useState(addressToString(company.address) ?? "")
    const [addressObj, setAddressObj] = React.useState(company.address)


    useEffect(() => {
        backend.companyApi.fetchCompanyById(context.companyId, auth).then((result) => {
            setCompany(result)
            setName(result.name)
            setTag(result.tag)
            setAddress(addressToString(result.address) ?? "")
            setAddressObj(result.address)
        })

    }, []);

    async function reset() {
        setIsResetting(true)

        let currentCompany = await backend.companyApi.fetchCompanyById(context.companyId, auth)
        setCompany(currentCompany)
        setEditName(false)
        setEditAddress(false)
        setEditTag(false)

        setShowArchiveModal(false)
        setShowAddressEditModal(false)

        setName(currentCompany.name)
        setTag(currentCompany.tag)
        setAddress(addressToString(currentCompany.address) ?? "")
        setAddressObj(currentCompany.address)

        setIsResetting(false)

    }

    function addressToString(address: Address) {
        if (address) {
            let str = address.name + "\n"
            if (address.additionalInfo) {
                str = str + address.additionalInfo + "\n"
            }


            str = str + address.street + " " + address.houseNumber + "\n" +
                address.zipCode + " " + address.city + "\n" +
                address.country

            return str
        }
        return ""
    }

    async function addressToObject() {

        setAiIsLoading(true)
        let input = "Input | Output as JSON |\n" +
            "Mercedes Benz AG, Stuttgarter Str. 14, 72622, Nürtingen | {\"name\":\"Mercedes Benz AG\", \"city\": \"Nürtingen\", \"street\":\"Stuttgarter Str.\", \"houseNumber\": \"14\", \"zipCode\": \"72622\", \"country\": \"DE\"} |\n" +
            "Edeka Minden-Hannover, EDEKA-LAUE Peugeotstraße 1, D-31867 Lauenau | {\"name\":\"Edeka Minden-Hannover\", \"city\": \"Lauenau\", \"street\":\"Peugeotstraße\", \"houseNumber\": \"1\", \"zipCode\": \"31867\", \"country\": \"DE\"} |\n" +
            replaceAll(replaceAll(address, "\n", ", "), "|", ", ") + "|"

        let aiRequest = {
            input: input,
            rawInput: address,
            type: "address",
            stopSequence: "|"
        } as ParseTextRequest
        let aiResponse = await backend.internalApi.aiParseText(aiRequest, auth)
        setAddressObj(JSON.parse(aiResponse.output))
        setAiIsLoading(false)

    }

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    async function save() {
        setIsSaving(true)
        let request = await backend.companyApi.fetchCompanyById(authentication.company._id, auth)

        if (name != null && name != "" && name != company.name) {
            request.name = name
        }
        if (tag != null && tag != "" && tag != company.tag) {
            request.tag = tag
        }
        if (addressObj != null && addressObj != "" && addressObj != company.address) {
            request.address = addressObj
        }

        console.log(request)
        let currentCompany = await backend.companyApi.updateCompany(context.companyId, request, auth)

        authentication.company = currentCompany
        setCompany(currentCompany)
        setEditName(false)
        setEditAddress(false)
        setEditTag(false)

        setShowArchiveModal(false)
        setShowAddressEditModal(false)

        setName(currentCompany.name)
        setTag(currentCompany.tag)
        setAddress(addressToString(currentCompany.address) ?? "")
        setAddressObj(currentCompany.address)

        setIsSaving(false)
        notify.show('updated Company', 'success', 3000, '#28f751');
    }


    function hasChanges() {
        let hasChanges = false
        if (
            name != company.name ||
            tag != company.tag ||
            addressObj != (company.address)
        ) {
            hasChanges = true
        }
        return hasChanges

    }


    function drawNameField() {
        return <><Label style={{width: 100}}>Name</Label>
            <Input
                disabled={!editName}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            {!editName && <Button
                icon
                onClick={() => setEditName(true)}>
                <Icon
                    name='pencil'/>
            </Button>
            }
            {editName && <Button
                icon
                onClick={() => setEditName(false)}>
                <Icon
                    name='lock open'/>
            </Button>
            }
        </>
    }

    function drawTagField() {
        return <>
            <Label style={{width: 100}}>Tag</Label>
            <Input
                disabled={!editTag}

                value={tag}
                onChange={(e) => setTag(e.target.value)}
            />
            {!editTag && <Button
                icon
                onClick={() => setEditTag(true)}>
                <Icon
                    name='pencil'/>
            </Button>
            }
            {editTag && <Button
                icon
                onClick={() => setEditTag(false)}>
                <Icon
                    name='lock open'/>
            </Button>
            }
        </>
    }

    function drawAddressField() {
        return <>
            <Label style={{width: 100}}>Address</Label>
            <TextArea
                style={{marginBottom: -10}}
                disabled={!editAddress}
                rows={5}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
            />
            {!editAddress && <Button
                icon
                onClick={() => setEditAddress(true)}>
                <Icon
                    name='pencil'/>
            </Button>
            }
            {editAddress && <Button
                icon
                onClick={() => setEditAddress(false)}>
                <Icon
                    name='lock open'/>
            </Button>
            }

            {address != addressToString(addressObj) &&
                <><Popup content='Ai Parser' trigger={
                    <Button
                        loading={aiIsLoading}
                        icon
                        onClick={() => addressToObject()}>
                        <Icon
                            name='microchip'/>
                    </Button>
                }/>
                    <Popup
                        content='The TextField content must be parsed by an AI. Or you can use the Detailed Form Model.'
                        trigger={

                            <Icon
                                name='warning sign'
                                color={"yellow"}
                            />

                        }/>
                </>
            }
            <Button
                icon
                onClick={() => setShowAddressEditModal(true)}>
                <Icon
                    name='list'/>
            </Button>

        </>
    }

    function drawArchiveField() {
        return <>
            <Label style={{width: 100}}>Archive</Label>
            <Button
                icon
                onClick={() => setShowArchiveModal(true)}>
                <Icon
                    name='archive'/>
            </Button>
        </>
    }

    function drawButtons() {
        return <>
            <Button
                disabled={!hasChanges()}
                loading={isSaving}
                icon
                onClick={() => save()}>
                <Icon
                    name='save'/>
                Save
            </Button>

            <Button
                loading={isResetting}
                icon
                onClick={() => reset()}>
                <Icon
                    name='repeat'/>
                Reset
            </Button>
        </>
    }

    return <div>
        {drawNameField()}
        <br/>
        <br/>
        {drawTagField()}
        <br/>
        <br/>
        {drawAddressField()}
        <br/>
        <br/>
        {drawArchiveField()}
        <br/>
        <br/>
        {drawButtons()}

        <CompanyArchiveModal
            isOpen={showArchiveModal}
            onClose={(success) => {
                setShowArchiveModal(false)
                if (success == true) {
                    notify.show('archived Company', 'success', 3000, '#28f751');
                } else if (success == false) {
                    notify.show("ERROR: unable to archive company.", 'error', 5000, '#fc0303')
                }
            }}
        />

        {showAddressEditModal && <CompanyAddressEditModal
            isOpen={showAddressEditModal}
            onClose={(newAddress) => {

                setShowAddressEditModal(false)
                if (newAddress) {
                    setAddressObj(newAddress)
                    setAddress(addressToString(newAddress))
                    notify.show('updated address', 'success', 3000, '#28f751');
                }
            }}
            address={addressObj}
        />}
        <Notifications/>
    </div>
}
