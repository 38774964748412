import * as React from "react";
import {ChangeEvent} from "react";
import {Input} from "semantic-ui-react";
import {Signal, signal} from "@preact/signals-react";

export interface FileUploadMultipleProps {
}

export const uploadedFiles: Signal<FileWithState[]> = signal([])

export type FileProcessingState = "NOT_PROCESSED" | "PROCESSING" | "PROCESSED"

export interface FileWithState {
    file: File,
    fileName?: string,
    state: FileProcessingState
}

export function FileUploadMultiple(props: React.PropsWithChildren<FileUploadMultipleProps>) {

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        let files = [] as File[]
        for (let i = 0; i < e.target.files!.length; i++) {
            files.push(e.target.files![i])
        }
        uploadedFiles.value = files.map((file) => {
            return {
                file: file,
                fileName: file.name,
                state: "NOT_PROCESSED"
            } as FileWithState
        })
    };


    return <Input
        icon='upload'
        iconPosition='left'
        placeholder='Upload...'
        type="file"
        onChange={handleFileChange}
        multiple
    />
}
