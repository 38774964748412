import {Tab} from "semantic-ui-react";
import * as React from "react";
import {ContractList} from "./ContractsList";
import {ContractsHistoryList} from "./ContractsHistoryList";

const panes = [
    { menuItem: 'ContractList', render: props => <Tab.Pane><ContractList contractSelected={props.contractSelected} selectedContract={props.selectedContract} refreshTrigger={props.refreshTrigger}/></Tab.Pane> },
    { menuItem: 'History', render: props => <Tab.Pane><ContractsHistoryList /></Tab.Pane> },
   ]

const ContractsListTabView = props => <Tab
    id={"ContractsListTabView"}
    className={"flexTab"}
    {...props = props}
    panes={panes}
    defaultActiveIndex={props.context.tabSession.contractListTab}
    onTabChange={(_, data) => {
        props.context.setSingleTabSession("contractListTab", data.activeIndex)
    }} />

export default ContractsListTabView