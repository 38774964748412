import * as React from "react";
import { Modal, Header, Button, Grid, Icon, Segment } from "semantic-ui-react";
import { TransportOrderConfigTest } from "../../../../../generated";
import { ConfigContext } from "../../../context/ConfigContext";
import { backend } from "../../../../../xconvert-backend";
import { authentication } from "../../../../../authentication";
import AceEditor from 'react-ace';
import { notify } from 'react-notify-toast';

import 'brace/mode/json'
import 'brace/theme/monokai'
import 'brace/ext/searchbox'

export interface TestDeleteModalProps {
    isOpen: boolean
    onClose: () => void
    test: TransportOrderConfigTest
}
export interface TestDeleteModalState {
    test: TransportOrderConfigTest
}


export class TestDeleteModal extends React.Component<TestDeleteModalProps, TestDeleteModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            test: null
        }
    }


    componentDidMount() {
        if (this.props.test != null) {
            this.setState({ test: this.props.test })
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    async deleteTest() {
        console.log("delete Test : ", this.state.test.name)

        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let result = (await backend.internalApi.deleteTransportOrderConfigTest(this.state.test._id, auth))

        let success = result.successful

        notify.show('Deleted test ', 'success', 3000, '#28f751');
        this.props.onClose()

    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="inverted"
            size='fullscreen'>
            <Header icon='browser' content='Delete Test?' />
            <Modal.Content>


                {this.showTest()}

                <Button onClick={(evt) => this.deleteTest()} ><Icon name='x' color='red' />Delete</Button>

                <Button onClick={(evt) => this.handleClose()} >Cancel</Button>


            </Modal.Content>
        </Modal >
    }

    showTest() {
        let test = this.state.test
        if (test != null) {
            return <Segment><Grid padded="horizontally" stackable columns='equal' centered celled >
                <Grid.Column>
                    <Grid.Row>
                        TestName:
                    </Grid.Row>

                    <Grid.Row>
                        Active:
                    </Grid.Row>

                    <Grid.Row>
                        Success:
                    </Grid.Row>

                    <Grid.Row>
                        Last Run:
                    </Grid.Row>

                    <Grid.Row>
                        Last Successful Run:
                    </Grid.Row>

                    <Grid.Row>
                        Created:
                    </Grid.Row>

                    <Grid.Row>
                        Modified:
                    </Grid.Row>

                    <Grid.Row>
                        Type:
                    </Grid.Row>


                </Grid.Column>
                <Grid.Column>

                    <Grid.Row>
                        {test.name}
                    </Grid.Row>

                    <Grid.Row>
                        {test.active}
                    </Grid.Row>

                    <Grid.Row>
                        {test.success}
                    </Grid.Row>

                    <Grid.Row>
                        {test.lastRun}
                    </Grid.Row>

                    <Grid.Row>
                        {test.lastSuccessfulRun}
                    </Grid.Row>

                    <Grid.Row>
                        {test.created}
                    </Grid.Row>

                    <Grid.Row>
                        {test.modified}
                    </Grid.Row>

                    <Grid.Row>
                        {test.type}
                    </Grid.Row>



                </Grid.Column>
            </Grid>
                {this.drawInputAndOutput()}

            </Segment>
        } else {
            return <p>{"error: test is null"}</p>
        }
    }

    drawInputAndOutput() {

        let input = ""
        let expected = ""

        if (this.state.test.type == 'IMPORT') {
            if (this.state.test.input.importData.type == "TEXT") {
                input = atob(this.state.test.input.importData.importDoc)
            } else {
                input = "input is a file named: \"" + this.state.test.input.importData.fileName + "\". you can download it in the TestView."
            }
            expected = JSON.stringify(this.state.test.expectedResult.importData, null, 4)
        } else {
            input = JSON.stringify(this.state.test.input.exportData.transportOrder, null, 4)
            expected = this.state.test.expectedResult.exportData
        }

        return <Grid padded="horizontally" stackable columns="equal" centered>
            <Grid.Column>
                <Grid.Row>
                    Input
                </Grid.Row>
                <Grid.Row>
                    <AceEditor
                        theme="monokai"
                        mode="json"
                        value={input}
                        width="700px"
                        height="600px"
                    />
                </Grid.Row>

            </Grid.Column>
            <Grid.Column>
                <Grid.Row>
                    expected Result
                </Grid.Row>
                <Grid.Row>
                    <AceEditor
                        theme="monokai"
                        mode="json"
                        value={expected}
                        placeholder='expected result'
                        width="100%"
                        height="600px"
                    />
                </Grid.Row>

            </Grid.Column>

        </Grid>
    }
}