import * as React from "react";
import {authentication} from "../../../../authentication";
import {SchedulerTaskConfig, StatusForwardRule} from "../../../../generated";
import {backend} from "../../../../xconvert-backend";
import {PageWithSidebar} from "../../../style/page_with_sidebar";
import {ConfigContext} from "../../context/ConfigContext";
import {formatDate} from "../../../../format";
import ReactTable from "react-table";
import {Button, DropdownItemProps, Grid, Icon} from "semantic-ui-react";
import AceEditor from 'react-ace';
import Notifications, {notify} from 'react-notify-toast';
import {format} from "date-fns";

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import ChangeTypesEnum = StatusForwardRule.ChangeTypesEnum;

export interface SchedulerTasksPageProps {

}


export interface SchedulerTasksPageState {
    isLoading: boolean,
    take: number
    page: number
    count: number
    schedulerTasks: SchedulerTaskConfig[],
    selectedTask: SchedulerTaskConfig | null,
    selectedIndex: number | null,
    workingCopy: string
}


export class SchedulerTasksPage extends React.Component<SchedulerTasksPageProps, SchedulerTasksPageState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading SchedulerTasksPage")

        this.state = {
            isLoading: false,
            take: 20,
            page: 1,
            count: 0,
            schedulerTasks: [],
            selectedTask: null,
            selectedIndex: null,
            workingCopy: ""
        }
    }

    async componentDidMount() {
        await this.loadSchedulerTasks()
    }

    async loadSchedulerTasks(take = this.state.take, page = this.state.page) {

        this.setState({isLoading: true})
        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let skip = (page - 1) * take

        let resp = (await backend.internalApi.fetchSchedulerTasks(auth))
        this.setState({
            schedulerTasks: resp.configs,
            count: resp.configs.length,
            take: take,
            page: page,
            isLoading: false,
        })
    }

    render() {
        return <PageWithSidebar activeMenu="Scheduler">
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Column>
                    {this.renderTable()}
                </Grid.Column>
                <Grid.Column>
                    {this.drawEditor()}
                </Grid.Column>

            </Grid>
            <Notifications/>
        </PageWithSidebar>
    }

    async onSave() {
        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let newConfig = JSON.parse(this.state.workingCopy)
        let response = await backend.internalApi.updateOrInsertSchedulerTaskConfig(newConfig, auth)

        if (response) {

            notify.show('updated comment', 'success', 3000, '#28f751');
            await this.loadSchedulerTasks()
        } else {
            notify.show("ERROR: unable to change comment.", 'error', 5000, '#fc0303')
        }
    }

    async onRunNow() {
        let utcTime = new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime()
        let newConfig = JSON.parse(this.state.workingCopy)
        //"2021-03-10T07:57:16.778Z"
        newConfig.nextRun = format(utcTime, "YYYY-MM-DDTHH:mm:ss.SSS") + "Z"
        this.setState({workingCopy: JSON.stringify(newConfig, null, 4)})
        await this.onSave()
    }

    drawEditor() {

        return <p><AceEditor
            theme="monokai"
            mode='groovy'
            value={this.state.workingCopy}
            placeholder='Message'
            width="100%"
            height="800px"
            readOnly={false}
            onChange={(value) => this.setState({workingCopy: value})}

        />
            <Button disabled={this.state.workingCopy == JSON.stringify(this.state.selectedTask)}
                    onClick={(evt) => this.onSave()}>Save</Button>
            <Button disabled={this.state.workingCopy == JSON.stringify(this.state.selectedTask)}
                    onClick={(evt) => this.onRunNow()}>run now</Button>

        </p>
    }

    changeTypeEnumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(ChangeTypesEnum).forEach(value => {
            enumValues.push({key: value, value: value, text: value});
        })
        return enumValues
    }


    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    renderTable() {

        const columns = [
            {
                id: 'taskName',
                Header: 'taskName',
                width: 300,
                accessor: (d: any) => d.taskName,
                sortField: 'TASK_NAME'
            }, {
                id: '_id',
                Header: 'ID',
                width: 210,
                accessor: (d: any) => d._id,
                sortField: 'ID'
            }, {
                id: 'enabled',
                Header: 'enabled',
                width: 100,
                accessor: (d: any) => {
                    if (d.enabled) {
                        return <Icon name='check' color='green' size='large'/>
                    } else {
                        return <Icon name='x' color='red' size='large'/>
                    }
                },
                sortField: 'ENABLED'
            }, {
                id: 'state',
                Header: 'state',
                width: 100,
                accessor: (d: any) => {
                    if (d.state == "SUCCESS") {
                        return <Icon id="success" size='big' name='check square outline' color='green'/>
                    } else {
                        if (d.state == "STARTED") {
                            return <Icon id="started" size='big' name='ellipsis horizontal' color='yellow'/>
                        } else {
                            return <Icon id="failed" size='big' name='times' color='red'/>
                        }
                    }
                },
                sortField: 'STATE'
            }, {
                id: 'intervalInSeconds',
                Header: 'intervalInSeconds',
                width: 200,
                accessor: (d: any) => d.intervalInSeconds,
                sortField: 'INTERVAL_IN_SECONDS'
            }, {
                id: 'lastRun',
                Header: 'lastRun',
                width: 200,
                accessor: (d: any) => d.lastRun,
                Cell: (d: any) => {
                    return <span>{formatDate(d.value)}</span>
                },
                sortField: 'LAST_RUN'
            }, {
                id: 'nextRun',
                Header: 'nextRun',
                width: 150,
                accessor: (d: any) => d.nextRun,
                Cell: (d: any) => {
                    return <span>{formatDate(d.value)}</span>
                },
                sortField: 'NEXT_RUN'
            }
        ]

        if (this.state.schedulerTasks == null) {
            return <p>Loading</p>
        }

        return <ReactTable
            data={this.state.schedulerTasks}
            pages={Math.ceil(this.state.count / this.state.take)}
            columns={columns}
            defaultPageSize={this.state.take}
            className="-striped -highlight"
            loading={this.state.isLoading}
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: () => {
                        this.setState({
                            selectedIndex: rowInfo.index,
                            selectedTask: rowInfo.row._original,
                            workingCopy: JSON.stringify(rowInfo.row._original, null, 4)
                        })
                    },
                    style: {
                        background: rowInfo?.index === this.state.selectedIndex ? '#00afec' : 'white',
                        color: rowInfo?.index === this.state.selectedIndex ? 'white' : 'black'
                    }
                };
            }}
            style={{cursor: "pointer"}}
            onPageChange={(pageIndex) => {
                console.log("changing pageIndex -> ", pageIndex)
                this.changePage(pageIndex)
            }}
            onPageSizeChange={(pageSize, pageIndex) => this.changePageSize(pageSize)}
        />
    }


    changePage(pageIndex: number) {
        console.log("NEW PAGEINDEX: ", pageIndex)
        this.setState({isLoading: true})
        this.loadSchedulerTasks(this.state.take, pageIndex + 1)
    }

    changePageSize(newSize: number) {
        this.setState({isLoading: true})
        this.loadSchedulerTasks(newSize, this.state.page)
    }
}