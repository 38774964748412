import * as React from "react";
import {Header, Modal} from "semantic-ui-react";

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {ScriptModifier} from "../ScriptModifier";

export interface ScriptModalProps {
    isOpen: boolean
    onClose: () => void
    script: string | null
    dispatcher: (script) => void
    saveKeyListener: (e: any) => void
    path: string
    readOnly: boolean
    fieldDescriptionEnabled: boolean
    defaultDescription: string | null
}

export interface ScriptModalState {

}

export class ScriptModal extends React.Component<ScriptModalProps, ScriptModalState> {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        console.log("[ScriptModal] - rendering with props script: " + this.props.script)
        return <Modal
            key="ScriptModal"
            id='ScriptModal'
            open={this.props.isOpen}
            onClose={this.props.onClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Edit Script'/>
            <Modal.Content
                style={{
                    height: "calc(100vh - 100px)",
                }}
            >
                <ScriptModifier
                    key="ScriptModifier"
                    defaultDescription={this.props.defaultDescription}
                    fieldDescriptionEnabled={this.props.fieldDescriptionEnabled}
                    readOnly={this.props.readOnly}
                    path={this.props.path}
                    dispatcher={this.props.dispatcher}
                    script={this.props.script}
                    saveKeyListener={this.props.saveKeyListener}
                />
            </Modal.Content>
        </Modal>
    }

}