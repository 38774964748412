import * as React from "react";
import {ConfigContext} from "../../../context/ConfigContext";
import {Splitter} from "../../../../util/table-splitter-ui/src/components/Splitters/Splitter";
import ImportSimTabMenu from "./ImportSimTabMenu";
import {ImportSimOutputWithFileSelection} from "./ImportSimOutputWithFileSelection";

export function ImportSimPage() {

    const context = React.useContext(ConfigContext)

    return <>
        <Splitter
            left={<ImportSimTabMenu context={context}/>}
            right={<ImportSimOutputWithFileSelection/>}
            name={"importSim"}
        />
    </>
}

