import * as React from "react";
import {Button, Divider, Header, Icon, Input, Label, List, Modal} from "semantic-ui-react";
import {QueuedEventFilter} from "./QueuedEventsTable";
import {IfBox} from "../../../../style/if";


export interface QueuedEventsSavedFilterModalProps {
    isOpen: boolean
    onClose: () => void
    currentFilter: QueuedEventFilter
}

export function QueuedEventsSavedFilterModal(props: React.PropsWithChildren<QueuedEventsSavedFilterModalProps>) {

    const [filters, setFilters] = React.useState<QueuedEventFilter[]>(JSON.parse(localStorage.getItem("queuedEventFilters")) || [])
    const [filterName, setFilterName] = React.useState<string>("")


    React.useEffect(() => {
        console.log("INITIAL currentFilter", props.currentFilter)
    },[])
    function handleClose() {
        props.onClose()
    }

    function saveFilters() {

        let newCurrentFilter = JSON.parse(JSON.stringify(props.currentFilter)) // deep copy so the state update works
        newCurrentFilter.name = filterName

        let newFilters = JSON.parse(JSON.stringify(filters)) // deep copy so the state update works
        newFilters.push(newCurrentFilter)

        localStorage.setItem("queuedEventFilters", JSON.stringify(newFilters))
        setFilters(newFilters)
    }

    function deleteFilter(filter: QueuedEventFilter) {
        let newFilters = filters.filter(f => f.name != filter.name)
        localStorage.setItem("queuedEventFilters", JSON.stringify(newFilters))
        setFilters(newFilters)
    }

    function validFilterName() {
        return filterName != "" && filters.find(f => f.name == filterName) == null
    }
    function drawSaveNewFilter() {
        return <div>
            <Input
                placeholder="Filter Name"
                onChange={(e) => {
                    setFilterName(e.target.value)
                    console.log("currentFilter", props.currentFilter)
                }}
                error={!validFilterName()}
            />
            <Button
                onClick={() => saveFilters()}
                disabled={!validFilterName()}
            >
                <Icon name={"save"}/>
                Save
            </Button>
        </div>
    }

    function drawFilters() {
        return <div>
            {filters.map(filter => {
                return <div>

                    <Button
                        icon
                        size={"tiny"}
                        onClick={() => deleteFilter(filter)}
                    >
                        <Icon
                            color={"red"}
                            name={"trash"}
                        />
                    </Button>
                    <Label>{filter.name}</Label>
                </div>
            })}
        </div>
    }

    function drawCurrentFilter() {
        return <List bulleted>
            <IfBox shouldShow={props.currentFilter.queuedEventId != null}>
                <List.Item>
                    <p>{'QueuedEvent id: '} {props.currentFilter.queuedEventId}</p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={props.currentFilter.queueType != null}>
                <List.Item>
                    <p>{'Queue type: '} {props.currentFilter.queueType}</p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={props.currentFilter.eventType != null}>
                <List.Item>
                    <p>{'Event Type: '} {props.currentFilter.eventType}</p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={props.currentFilter.changeType != null}>
                <List.Item>
                    <p>{'Change Type: '} {props.currentFilter.changeType}</p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={props.currentFilter.transportOrderId != null}>
                <List.Item>
                    <p>{'TransportOrderId: '} {props.currentFilter.transportOrderId}</p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={props.currentFilter.processingState != null}>
                <List.Item>
                    <p>{'Processing State: '} {props.currentFilter.processingState}</p>
                </List.Item>
            </IfBox>
            <IfBox shouldShow={props.currentFilter.processed != null}>
                <List.Item>
                    <p>{'Processed: '} {props.currentFilter.processed}</p>
                </List.Item>
            </IfBox>
        </List>
    }

    return <Modal
        open={props.isOpen}
        onClose={handleClose}
        size='small'>
        <Header icon='browser' content='Manage your personal filters'/>
        <Modal.Content>
            <h3>Current Filter:</h3>
            {drawCurrentFilter()}
            {drawSaveNewFilter()}
            <Divider/>
            <h3>Saved Filters:</h3>
            {drawFilters()}
            <Divider/>
            <Button onClick={() => handleClose()}>Close</Button>

        </Modal.Content>
    </Modal>

}