import * as React from "react";
import {Tab} from "semantic-ui-react";
import {ConfigContext} from "../main/context/ConfigContext";
import {SemanticShorthandItem} from "semantic-ui-react/dist/commonjs/generic";
import {TabPaneProps} from "semantic-ui-react/dist/commonjs/modules/Tab/TabPane";



export interface LTabProps {
    name: string
    panes: {
        pane?: SemanticShorthandItem<TabPaneProps>
        menuItem?: any
        render?: () => React.ReactNode
    }[],

}

export function LTab(props: React.PropsWithChildren<LTabProps>) {

    const context = React.useContext(ConfigContext);
    const [activeIndex, setActiveIndex] = React.useState(context.tabByName(props.name))


    return <Tab
        class={"flexTab"}
        panes={props.panes}
        onTabChange={(_, data) => {
            let indexNumber = parseInt(data.activeIndex.toString())
            context.setSingleTabSession(props.name, indexNumber, null, null)
            setActiveIndex(indexNumber)
        }}
        activeIndex={activeIndex}
    />
}