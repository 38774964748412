import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

import {useDispatch, useSelector} from "react-redux";
import {getConversionLogs, getConversionOutput, getSourceCode} from "../slices/sourceCodeSlice";
import "./SourceCodeView.css"
import * as React from "react";
import {useEffect, useState} from "react";
import {getFlatOutputList} from "../slices/tableSlice";
import {getParseOptions, selectRules} from "../slices/ruleSlice";
import {ExecuteScriptButton, ScriptLang} from "./buttons/ExecuteScriptButton";
import {ToImmediateFormatButton} from "./buttons/ToImmediateFormatButton";
import {ConvertImmediateFormatButton} from "./buttons/ConvertImmediateFormatButton";
import {LoadRulesButton} from "./buttons/LoadRulesButton";
import {SyncScriptButton} from "./SyncScriptButton";

export interface ExecutionOutputViewProps {

}

type ShowMode = "SHOW_LOGS" | "SHOW_OUTPUT" | "SHOW_MATCHES" | "SHOW_RULES"

export function ExecutionOutputView(props: React.PropsWithChildren<ExecutionOutputViewProps>) {

    const [state, setState] = useState<ShowMode>("SHOW_OUTPUT")
    const [scriptLang, setScriptLang] = useState<ScriptLang>("KOTLIN")
    const [editorValue, setEditorValue] = useState<string>("")
    const output = useSelector(getConversionOutput)
    const source = useSelector(getSourceCode)
    const logs = useSelector(getConversionLogs)
    const selections = useSelector(getFlatOutputList)
    const options = useSelector(getParseOptions)
    const rules = useSelector(selectRules)
    const [rulesManuallyEdited, setRulesManuallyEdited] = useState(false)
    const dispatch = useDispatch()


    useEffect(() => {
        switch (state) {
            case "SHOW_LOGS":
                setEditorValue(logs);
                break;
            case "SHOW_OUTPUT":
                setEditorValue(output);
                break;
            case "SHOW_MATCHES":
                setEditorValue(JSON.stringify(selections, null, 2))
                break;
            case "SHOW_RULES":
                setEditorValue(JSON.stringify(rules, null, 2))
                break;
        }
    }, [state, logs, output, selections, rules])


    return (
        <>
            <form>
                <ExecuteScriptButton lang={scriptLang}/>
                <ConvertImmediateFormatButton/>
                <ToImmediateFormatButton/>
                <span>
                      <select name="scriptLang" id="scriptLang" onChange={(evt) => {
                          console.log(evt.target.value);
                          setScriptLang(evt.target.value as ScriptLang)
                      }} value={scriptLang}>
                        <option value={"KOTLIN"}>Kotlin</option>
                        <option value={"GROOVY"}>Groovy</option>
                      </select>
                </span>
                <SyncScriptButton script={source}/>
                <br/>
                <span style={{paddingLeft: "5px"}}>
                    <input id={"SHOW_OUTPUT"} type={"radio"} name={"SHOW_OUTPUT"}
                           checked={state == "SHOW_OUTPUT"} value={"SHOW_OUTPUT"}
                           onChange={() => setState("SHOW_OUTPUT")}/>
                    <label htmlFor={"SHOW_OUTPUT"}>TransportOrder</label>
                </span>
                <span style={{paddingLeft: "5px"}}>
                    <input id={"SHOW_LOGS"} type={"radio"} name={"SHOW_LOGS"}
                           checked={state == "SHOW_LOGS"} value={"SHOW_LOGS"}
                           onChange={() => setState("SHOW_LOGS")}/>
                    <label htmlFor={"SHOW_LOGS"}>Logs</label>
                </span>
                <span style={{paddingLeft: "5px"}}>
                    <input id={"SHOW_MATCHES"} type={"radio"} name={"SHOW_MATCHES"}
                           checked={state == "SHOW_MATCHES"} value={"SHOW_MATCHES"}
                           onChange={() => setState("SHOW_MATCHES")}/>
                    <label htmlFor={"SHOW_MATCHES"}>RuleMatches</label>
                </span> <span style={{paddingLeft: "5px"}}>
                    <input type={"radio"} name={"SHOW_RULES"} checked={state == "SHOW_RULES"}
                           onChange={() => setState("SHOW_RULES")} value={"SHOW_RULES"} id={"SHOW_RULES"}/>
                    <label htmlFor={"SHOW_RULES"}>Show rules</label>
                </span>
            </form>
            {state == "SHOW_RULES" && rulesManuallyEdited ? <LoadRulesButton ruleJson={editorValue} optionsJson={JSON.stringify(options)} label={"Update Rules"}/> : undefined}
            <AceEditor
                key={Math.random()}
                name="logOutputView"
                style={{flex: "1", flexGrow: 1, minHeight: "100px"}}
                mode={state != "SHOW_LOGS" ? "json" : undefined}
                width="calc(100%-4px)"
                value={editorValue}
                readOnly={state != "SHOW_RULES"}
                onChange={evt => {
                    if (state == "SHOW_RULES")
                        setEditorValue(evt)
                    setRulesManuallyEdited(true)
                }}
            />
        </>
    )
}