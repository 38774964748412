import * as React from "react";
import {Button, Checkbox, Divider, Form, Grid, Input, Label} from 'semantic-ui-react';
import {authentication} from "../../../../../authentication";
import {TelematicTourMapRequest, TransportOrder} from "../../../../../generated/api";
import {backend} from "../../../../../xconvert-backend";
import {ConfigContext} from "../../../context/ConfigContext";
import {LogView} from "../../logView/LogView";
import Notifications, {notify} from 'react-notify-toast';
import AceEditor from 'react-ace';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'

export interface TelematicMappingProps {

}

export interface TelematicMappingState {
    isConverting: boolean
    telMapCustomerId: string
    telMapTourId: string
    xRequestId: string
    tryMappedTransportOrdersAndIgnoreState: boolean
}

export class TelematicMapping extends React.Component<TelematicMappingProps, TelematicMappingState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading TelematicMapping")

        this.state = {
            isConverting: false,
            telMapCustomerId: null,
            telMapTourId: null,
            xRequestId: null,
            tryMappedTransportOrdersAndIgnoreState: false
        }
    }

    async doConvert() {
        console.log("[doConvert] - start")
        let output = null

        if (this.context.telMapTourId == null) {
            notify.show('TourId needs to be filled ', 'error', 5000, '#fc0303');
            return
        }

        try {
            this.setState({ isConverting: true })

            let auth = backend.withTokenAuthHeader(authentication.token)
            let urlReadyTelMapTourId = encodeURIComponent(this.context.telMapTourId)
            let path = "/simpex_to_logenios/" + this.context.telMapCustomerId + "/" + urlReadyTelMapTourId

            console.log("[doConvert] - path: ", path)

            let transportOrder: TransportOrder
            let xRequestId = ""
            try {
                let response = await fetch(path, {
                    method: 'GET',
                    headers: {
                        "Authorization": auth.headers.Authorization.toString(),
                        "Content-Type": "application/json; charset=utf-8"
                    }

                })
                let toOutput = ""
                xRequestId = response.headers.get("x-request-id")
                this.setState({ xRequestId: xRequestId })
                console.log("[doConvert] - xRequestId: ", xRequestId)

                if (response.status !== 200) {
                    toOutput = await response.text()
                    output = JSON.stringify(response, null, 4)

                    console.log("[doConvert] - toOutput: ", toOutput)
                    console.log("[doConvert] - output: ", output)

                    //this.setState({ isConverting: false })
                } else {
                    transportOrder = await response.json()
                    toOutput = JSON.stringify(transportOrder, null, 4)
                    console.log("[doConvert] - toOutput: ", toOutput)
                    let script = null
                    if (this.context.telMapScript != "") {
                        script = this.context.telMapScript
                    }
                    try {
                        let mapRequest = {} as TelematicTourMapRequest
                        mapRequest.setTelematicId = false
                        mapRequest.assignVehicle = false
                        mapRequest.targetCompanyId = this.context.telMapCustomerId
                        mapRequest.mappingScript = script
                        mapRequest.transportOrderToMap = transportOrder
                        mapRequest.tryMappedTransportOrdersAndIgnoreState = this.state.tryMappedTransportOrdersAndIgnoreState

                        //let data = await backend.internalApi.mapTelematicTour(mapRequest, auth)

                        console.log("[doConvert] - mapRequest: ", mapRequest)

                        let response = await fetch('/api/internal/transportOrders/mapTelematicTourToTransportOrders', {
                            method: 'POST',
                            headers: {
                                "Authorization": auth.headers.Authorization.toString(),
                                "Content-Type": "application/json; charset=utf-8"
                            },
                            body: JSON.stringify(mapRequest)
                        })

                        console.log("[doConvert] - response: ", response)

                        if (response.status >= 200 && response.status < 300 && response.status != 204) {
                            let data = await response.json()
                            output = JSON.stringify(data, null, 4)
                        } else {
                            output = await response.text()
                        }
                        xRequestId = xRequestId + " OR " + response.headers.get("x-request-id")
                        this.setState({ xRequestId: xRequestId })
                    } catch (ex) {
                        console.log("[doConvert] - EXCEPTION: ", ex)
                        output = await ex.text()
                    }
                }

                this.context.setTelMapToOutput(toOutput)

            } catch (ex) {
                console.log("[doConvert] - EXCEPTION: ", ex)
                output = await ex.text()
            }
        } catch (ex) {
            console.log("[doConvert] - EXCEPTION: ", ex)
            output = await ex.text()
        }
        this.context.setTelMapOutput(output)
        console.log("[doConvert] - output: ", output)

        this.setState({ isConverting: false })

    }

    componentDidMount() {
        if (this.context.telMapCustomerId == null || this.context.telMapCustomerId == "") {
            this.context.setTelMapCustomerId(this.context.companyId)
        }
        this.setState({
            telMapCustomerId: this.context.telMapCustomerId,
            telMapTourId: this.context.telMapTourId
        })
    }

    setTelMapCustomerId(value) {
        this.setState({ telMapCustomerId: value })
        this.context.setTelMapCustomerId(value)
    }

    setTelMapTourId(value: string) {
        this.setState({ telMapTourId: value })
        this.context.setTelMapTourId(value)
    }

    render() {

        return <span>

            <Form>
                <Grid padded="horizontally" stackable columns='equal' centered>
                    <Grid.Column width={4}>

                        <Label>
                            Groovy Script
                        </Label>
                        <AceEditor
                            theme="monokai"
                            mode='groovy'
                            value={this.context.telMapScript}
                            placeholder='Groovy Script'
                            width="100%"
                            onChange={(value) => this.context.setTelMapScript(value)}
                            readOnly={false}
                        />

                        <Divider hidden />
                        <Input
                            style={{ width: 220 }}
                            placeholder='Kunden-ID'
                            value={this.state.telMapCustomerId}
                            onChange={(evt) => this.setTelMapCustomerId(evt.target.value?.trim())}
                        />
                        <Label>
                            Kunden CompanyId
                        </Label>
                        <Divider hidden />

                        <Input style={{ width: 220 }} placeholder='Spedion TourNr.' value={this.state.telMapTourId} onChange={(evt) => this.setTelMapTourId(evt.target.value)} />
                        <Label>
                            Spedion TourNr
                        </Label>

                        <Divider hidden />
                        <Checkbox checked={this.state.tryMappedTransportOrdersAndIgnoreState} onChange={() => this.setState({tryMappedTransportOrdersAndIgnoreState: !this.state.tryMappedTransportOrdersAndIgnoreState})} />
                        <Label>
                            try mapped TransportOrders and ignore state
                        </Label>

                        <Divider />
                        <Button onClick={(evt) => this.doConvert()} loading={this.state.isConverting}>Start</Button>
                    </Grid.Column>
                    <Grid.Column>
                        <Label>
                            TransportOrder aus der Spedion Tour
                        </Label>
                        <AceEditor
                            theme="monokai"
                            mode="json"
                            value={this.context.telMapToOutput}
                            placeholder='TransportOrder from Api-Gateway'
                            width='100%'
                            height="400px"
                            readOnly={true}
                        />
                        <Label>
                            TransportOrder nach TelematicMapping
                        </Label>
                        <AceEditor
                            theme="monokai"
                            mode="json"
                            value={this.context.telMapOutput}
                            placeholder='TransportOrder nach TelematicMapping'
                            width='100%'
                            height="600px"
                            readOnly={true}
                        />
                    </Grid.Column>
                </Grid>
            </Form>
            <br />
            <h4>Logs:</h4>
            <LogView query={this.state.xRequestId} origin={"telematicMapping"}/>
            <Notifications />
        </span >;
    }


}