import * as React from "react";
import {Button, Grid, Header, Label, Modal} from "semantic-ui-react";

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import {CompanyConfigurationDraft} from "../../../../../../generated";

export interface ShareDraftModalProps {
    isOpen: boolean
    onClose: () => void
    draft: CompanyConfigurationDraft

}
export interface ShareDraftModalState{
    superUsers: []
}

export class ShareDraftModal extends React.Component<ShareDraftModalProps, ShareDraftModalState> {

    constructor(props) {
        super(props)
        this.state = {
            superUsers: []
        }
    }

    handleClose = () => {
        this.props.onClose()
    }


    async componentDidMount() {

    }

    handleSave() {

        this.props.onClose()
    }

    render() {

        return <Modal
            id='ShareDraftModal'
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Share Draft'/>
            <Modal.Content>
                <Grid padded="horizontally" stackable columns='equal' centered>
                    <Grid.Row>
                        <Label>share with: </Label>

                        <Button id='ShareDraftButton' onClick={() => this.handleSave()}>Save</Button>
                        <Button id='CancelShareDraftButton' onClick={() => this.handleClose()}>Cancel</Button>
                    </Grid.Row>

                </Grid>
            </Modal.Content>
        </Modal>
    }

}