import {
    AccountApi,
    CompanyApi,
    EventsApi,
    InternalApi,
    QueueApi,
    TrackingApi,
    TransportOrderApi,
    AttachmentsApi,
    QueryApi, ChangeEventsApi
} from "./generated/api";

const BASE_URL = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
import {v4 as uuid} from 'uuid';

class BackendService {


    basePath = BASE_URL + "/api"

    attachmentApi: AttachmentsApi
    accountApi: AccountApi
    transportOrderApi: TransportOrderApi
    companyApi: CompanyApi
    trackingApi: TrackingApi
    queueApi: QueueApi
    eventsApi: EventsApi
    internalApi: InternalApi
    queryApi: QueryApi
    changeEvents: ChangeEventsApi;

    constructor() {
        this.accountApi = this.getAccountApi()
        this.transportOrderApi = this.getTransportOrderApi()
        this.companyApi = this.getCompanyApi()
        this.trackingApi = this.getTrackingApi()
        this.queueApi = this.getQueueApi()
        this.eventsApi = this.getEventsApi()
        this.internalApi = this.getInternalApi()
        this.attachmentApi = this.getAttachmentApi()
        this.queryApi = this.getQueryApi()
        this.changeEvents = this.getChangeEventsApi()

    }

    withBasicAuthHeader(user: String, pass: String, totp: String | null) {
        let headers: any = {
            'Authorization': 'Basic ' + btoa(`${user}:${pass}`),
            'Accept': 'application/json',
            'x-request-id': uuid()
        }
        if (totp) {
            headers['x-totp'] = totp
        }
        return {headers: headers}
    }

    withTokenAuthHeader(token: String) {
        const queryString = window.location.href;
        //console.log("queryString :", queryString);
        const urlParams = queryString.split("/");
        let xRequestId = uuid();
        //console.log("urlParams: ", urlParams);

        let companyId = urlParams[3]
        if (companyId.length != 24) {
            companyId = null
        }

        if (token && token.startsWith("Bearer"))
            return {
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json; charset=utf-8',
                    'x-company-id': urlParams[3],
                    'x-request-id': xRequestId
                }
            }
        return {
            headers: {
                "Authorization": "Bearer " + token,
                'x-company-id': urlParams[3],
                'x-request-id': xRequestId
            }
        }
    }

    private getAttachmentApi(): AttachmentsApi {
        return new AttachmentsApi(null, this.basePath)
    }

    private getQueryApi(): QueryApi {
        return new QueryApi(null, this.basePath)
    }

    private getChangeEventsApi(): ChangeEventsApi {
        return new ChangeEventsApi(null, this.basePath)
    }

    private getAccountApi(): AccountApi {
        let api = new AccountApi(null, this.basePath)
        return api
    }

    private getTransportOrderApi(): TransportOrderApi {
        let api = new TransportOrderApi(null, this.basePath)
        return api
    }

    private getCompanyApi(): CompanyApi {
        let api = new CompanyApi(null, this.basePath)
        return api
    }

    private getTrackingApi(): TrackingApi {
        let api = new TrackingApi(null, this.basePath)
        return api
    }

    private getQueueApi(): QueueApi {
        let api = new QueueApi(null, this.basePath)
        return api
    }

    private getEventsApi(): EventsApi {
        let api = new EventsApi(null, this.basePath)
        return api
    }

    private getInternalApi(): InternalApi {
        let api = new InternalApi(null, this.basePath)
        return api
    }
}

export const backend = new BackendService()