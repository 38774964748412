import * as React from "react";
import {ConfigContext} from "../../../../context/ConfigContext";
import {authentication} from "../../../../../../authentication";
import {Button, Divider, Label} from "semantic-ui-react";
import {backend} from "../../../../../../xconvert-backend";
import {AddDefaultsToConfigRequest} from "../../../../../../generated";
import Notifications, {notify} from 'react-notify-toast';
import {InputFile} from "../../../../../util/InputFile";
import {v4 as uuid} from 'uuid';
import { workingConfig } from "../../../configChange/ConfigSignal";

export interface MatrixDocInputFileViewProps {
    reload: (string) => void
    changeXRequestId: (string) => void
}

export interface MatrixDocInputFileViewState {
    isLoading: boolean
    isConverting: boolean
    dictionary: string
    xIdMatrix: string
    xIdTO: string
}

export class MatrixDocInputFileView extends React.Component<MatrixDocInputFileViewProps, MatrixDocInputFileViewState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading ImportSimFile")

        this.state = {
            isLoading: false,
            isConverting: false,
            dictionary: "",
            xIdMatrix: "",
            xIdTO: ""
        }
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    componentDidMount() {
        this.context.setMatrixDocInputActive("FILE")
    }

    async sendToConverter() {
        this.setState({isConverting: true})

        let fileBase64 = '';
        await (this.getBase64(this.context.matrixDocFile, (result) => {
            fileBase64 = result.split(";base64,")[1];
        }))

        let counter = 0
        while (fileBase64 == '' && counter < 30) {
            await this.sleep(100)
            counter++
        }
        await this.convertToTransportOrders(fileBase64)
        await this.letConverterCreateMatrix(fileBase64)
        this.props.changeXRequestId("'" + this.state.xIdMatrix + "'" + " OR " + "'" + this.state.xIdTO + "'")

        this.setState({isConverting: false})
    }

    async convertToTransportOrders(fileBase64) {

        let auth = backend.withTokenAuthHeader(authentication.token)

        let request = {} as AddDefaultsToConfigRequest
        request.config = JSON.parse(workingConfig.value)

        let config = await (await backend.internalApi.addDefaultsToConfig(request, auth)).config

        try {
            let xReqId = uuid()
            this.setState({ xIdMatrix: xReqId} )

            let response = await fetch('/converter/v3/convertExternalFormatToTransportOrder', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    'x-request-id': xReqId
                },
                body: JSON.stringify({
                    base64Data: fileBase64,
                    config: config,
                    companyId: this.context.companyId
                })
            }).then((response) => {
                if (response.ok) {
                    return response.text()
                } else {
                    throw new Error(response.statusText)
                }
            })

            this.context.setMatrixDocOrders(response)
        } catch (ex) {
            this.context.setMatrixDocOrders(ex)
            notify.show('Error while converting, see Grid!', 'error', 5000, '#fc0303');

        }
    }

    async letConverterCreateMatrix(fileBase64) {
        this.setState({isConverting: true})

        let request = {} as AddDefaultsToConfigRequest
        request.config = JSON.parse(workingConfig.value)

        try {
            let xReqId = uuid()
            this.setState({ xIdTO: xReqId} )
            let response = await fetch('/converter/csvDocumentToMatrix/true', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    'x-request-id': xReqId
                },
                body: JSON.stringify({
                    base64Data: fileBase64,
                    config: JSON.parse(workingConfig.value),
                    companyId: this.context.companyId,
                })
            }).then((response) => {
                if(response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.statusText)
                }
            })
            console.log("INFO RESPONSE: ", response)
            this.setState({dictionary: JSON.stringify(response, null, 4)})
            this.context.setMatrixDocResult(response)
            notify.show('Successfully converted, see Grid!', 'success', 3000, '#28f751');

        } catch (ex) {
            this.setState({dictionary: ex})
            this.context.setMatrixDocResult(ex)
            notify.show('Error while converting, check Logs!', 'error', 5000, '#fc0303');

        }

    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }


    handleChange(file: File) {
        this.setState({isLoading: true})
        this.context.setMatrixDocFile(file)

        this.setState({isLoading: false})
    }

    render() {
        return <p>
            <Label>
                Upload external format File (e.g. LIS)
            </Label>
            <br/>

            <InputFile
                button={{}}
                input={{
                    id: 'input-control-id',
                    onChange: (evt) => this.handleChange(evt.target.files[0])
                }}
                multiple={false}
            />

            <Divider hidden/>

            FileName: <Label>{this.getFileName(this.context.matrixDocFile)}</Label>
            <br/>
            <br/>
            FileSize: <Label>{this.getReadableFileSizeString(this.context.matrixDocFile)}</Label>

            <Divider hidden/>
            <Button onClick={(evt) => this.sendToConverter()} loading={this.state.isConverting}>Convert</Button>
            <Notifications/>
        </p>
    }

    getFileName(file: File) {
        if (file == null) {
            return ""
        } else {
            return file.name
        }
    }

    getReadableFileSizeString(file: File) {

        if (file == null) {
            return 0
        }
        let fileSizeInBytes = file.size
        var i = -1;
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }

}