import * as React from "react";
import { Modal, Header, Button, Label } from "semantic-ui-react";
import { ConfigContext } from "../../../../context/ConfigContext";
import AceEditor from 'react-ace';

import 'brace/ext/searchbox'
import { backend } from "../../../../../../xconvert-backend";
import {updateConfig} from "../../ConfigSignal";

export class CreateDefaultConfigModal extends React.Component<any, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            defaultConfig: ""
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    handleSave() {
        updateConfig(this.state.defaultConfig, false, false)
        this.handleClose()
    }

    async componentDidMount() {
        let defaultConfig = (await backend.internalApi.fetchDefaultCompanyConfiguration(this.props.auth))
        this.setState({ defaultConfig: JSON.stringify(defaultConfig, null, 4) })
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Create default Config' />
            <Modal.Content>
                <AceEditor
                    theme="monokai"
                    mode="json"
                    value={this.state.defaultConfig}
                    width="100%"
                    height="900px"
                    readOnly={true}
                />
                <Button onClick={() => this.handleSave()}>Save</Button>
                <Button onClick={() => this.handleClose()}>Close</Button>

            </Modal.Content>
        </Modal>
    }

}