import * as React from "react";
import {useEffect} from "react";
import {Button, ButtonGroup, Icon, Segment} from "semantic-ui-react";
import * as XLSX from 'xlsx/xlsx.mjs';
import Spreadsheet from "react-spreadsheet";

export interface XlsxViewer2Props {
    blob: any;
}

export function XlsxViewer({blob}: XlsxViewer2Props) {

    const [data, setData] = React.useState([]);
    const [sheetIndex, setSheetIndex] = React.useState(0);
    const [workbook, setWorkbook] = React.useState(null);
    const [worksheets, setWorksheets] = React.useState([]);

    useEffect(() => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target?.result;
            const wb = XLSX.read(binaryStr, {type: "binary"});
            setWorkbook(wb);
            setWorksheets(wb.SheetNames);
            openSheet(0, wb);
        };
        reader.readAsBinaryString(blob);

    }, [blob]);

    function openSheet(index = sheetIndex, wb = workbook) {

        const worksheet = wb.Sheets[wb.SheetNames[index]];
        const sheetData: string[][] = XLSX.utils.sheet_to_json(worksheet, {header: 1});

        const longestRow = sheetData.reduce((acc, row) => row.length > acc ? row.length : acc, 0);
        let fixedSheetData = sheetData.map(row => {
            let newRow = [];
            for (let i = 0; i < longestRow; i++) {
                newRow.push({value: row[i] ?? ""});
            }
            return newRow;
        })
        setData(fixedSheetData);
    }


    function worksheetSelector() {
        let worksheetButtons = []
        worksheets.forEach((sheet, index) => {
            console.log(sheet, index);

            worksheetButtons.push(
                <Button
                    key={index}
                    primary={index === sheetIndex}
                    onClick={() => {
                        setSheetIndex(index)
                        openSheet(index)
                    }}
                    value={worksheets[index]}
                >
                    {sheet}
                </Button>
            )

        })
        return <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            overflow: 'auto',
            marginTop: '1em'
        }}><ButtonGroup>{worksheetButtons}</ButtonGroup>{disclaimer}</div>

    }

    const disclaimer = <>
        <Icon name={"info circle"} color={"blue"} size={"large"}/>
        <>
            Note: This is a preview of the data in the file.
            It doesn't include formatting and similar styling features.
            Please download the file and open it in Excel for full functionality.
        </>
    </>

    return <>
        <Segment style={{
            display: 'flex',
            flexDirection: 'column',
            height: '72vh',
            overflow: 'auto'
        }}>
            <Spreadsheet data={data}/>
        </Segment>
        {worksheetSelector()}
    </>
}