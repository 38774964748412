import * as React from "react";
import {Checkbox} from "semantic-ui-react";
import {DiffWrapper} from "../../../configChange/configEdit/diff/DiffWrapper";
import {useEffect} from "react";

const YAML = require('yaml');

export interface CustomDiffProps {
    oldJson: string,
    newJson: string
}

export function CustomDiff(props: React.PropsWithChildren<CustomDiffProps>) {

    const [displayFormat, setDisplayFormat] = React.useState('json')
    const [oldText, setOldText] = React.useState("")
    const [newText, setNewText] = React.useState("")

    useEffect(() => {
        setOldText(props.oldJson)
        setNewText(props.newJson)
    }, [])

    function jsonToYaml(json: string) {
        if (json) {
            const doc = new YAML.Document();
            doc.contents = JSON.parse(json);
            return doc.toString();
        } else {
            return '';
        }
    }

    function switchDisplayFormat() {
        if (displayFormat == 'json') {
            setOldText(jsonToYaml(oldText))
            setNewText(jsonToYaml(newText))
            return 'yaml'
        }
        setOldText(props.oldJson)
        setNewText(props.newJson)
        return 'json'
    }

    return <>
        <p style={{fontSize: "16px"}}>Yaml - <Checkbox
            type='checkbox'
            toggle
            checked={displayFormat == 'json'}
            onChange={() => {
                setDisplayFormat(switchDisplayFormat())
            }}
        /> - Json </p>

        <DiffWrapper
            oldText={oldText}
            newText={newText}
        />

    </>


}