import * as React from "react";
import {Modal, Header, Button, Label, Segment, Icon, Input, Grid} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import {FileSystemEntry, MoveFileRequest} from "../../../../../generated-ftp/api";
import {FtpExplorerShowFileSystemEntry} from "../FtpExplorerShowFileSystemEntry";
import ReactTable from "react-table";
import {backend} from "../../../../../xconvert-backend";
import {ftp} from "../../../../../xconvert-ftp";
import {authentication} from "../../../../../authentication";
import {IfBox} from "../../../../style/if";
import {ListFilesResponse} from "../../../../../generated-ftp/api"

export interface FtpExplorerFileMoveModalProps {
    isOpen: boolean
    onClose: (success) => void
    file: FileSystemEntry,
    sourcePath: string,
    sortBy: 'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED',
    sortDirection: 'ASC' | 'DESC'
}

export interface FtpExplorerFileMoveModalState {
    isLoading: boolean,
    response: ListFilesResponse | null,
    targetPath: string,
    moveResponse: string | null,
    file: FileSystemEntry | null,

    //Table
    take: number,
    page: number,
    pages: number,
    sortBy: 'DIRECTORY' | 'NAME' | 'SIZE' | 'CREATED' | 'MODIFIED',
    sortDirection: 'ASC' | 'DESC'
}

export class FtpExplorerFileMoveModal extends React.Component<FtpExplorerFileMoveModalProps, FtpExplorerFileMoveModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {

            isLoading: false,
            response: null,
            targetPath: "",
            moveResponse: null,
            file: null,

            //Table
            take: 10,
            page: 0,
            pages: -1,
            sortBy: "DIRECTORY",
            sortDirection: "ASC",
        }
    }

    handleClose = () => {
        this.props.onClose(false)
    }

    async handleMove(copy = false) {

        let targetPath = this.state.targetPath

        var moveFileRequest = {} as MoveFileRequest
        moveFileRequest.targetPath = targetPath
        moveFileRequest.copy = copy
        moveFileRequest.fileNames = [this.props.file.name]


        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let moveResponse = (await ftp.ftpApi.moveFile(this.props.sourcePath, moveFileRequest, this.context.companyId,  auth))

        if (moveResponse.success == true) {
            this.props.onClose(true)

        } else {
            this.setState({moveResponse: moveResponse.errorMessage})
        }

    }

    handleDiscard() {
        this.handleClose()
    }

    async openDir(path: string, page: number = this.state.page) {
        this.setState({isLoading: true})

        let auth = (await backend.withTokenAuthHeader(authentication.token))
        let response = (await ftp.ftpApi.listFiles(
                this.context.companyId,
                undefined,
                page,
                path,
                null, // no filter here, since only directories will be displayed, so there should be only a limited output and simple filters can screw up that view.
                false,
                this.state.sortBy,
                this.state.sortDirection,
                this.state.take,
                undefined,
                null,
                auth
            )
        )
        console.log("File-response: " + JSON.stringify(response, null, 4))
        this.setState({
            response: response,
            isLoading: false,
            targetPath: path,
            page: page
        })
    }

    async componentDidMount() {
        this.openDir(this.props.sourcePath)
        this.setState({file: this.props.file, targetPath: this.props.sourcePath})
    }

    render() {

        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='large'>
            <Header icon='browser' content='Move File'/>
            <Modal.Content>
                <Label pointing='below'>File to move</Label>
                <FtpExplorerShowFileSystemEntry file={this.props.file}/>

                <Grid padded="horizontally" stackable columns='equal' centered>
                    <Grid.Column>

                        <Label pointing='below'>current location</Label>
                        <br/>
                        <Label as='a' color='blue'>
                            <Label.Detail>{this.props.sourcePath}</Label.Detail>
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Label pointing='below'>move to</Label>
                        <br/>
                        <Label as='a' color='green'>
                            <Label.Detail>{this.state.targetPath}</Label.Detail>
                        </Label>
                    </Grid.Column>
                </Grid>

                <IfBox shouldShow={this.state.moveResponse != null}>
                    <Label color='red'>{this.state.moveResponse}</Label>
                </IfBox>

                {this.drawTargetSelectionTable()}

                <Button onClick={() => this.handleMove()} disabled={this.canNotMoveToHere()}>move</Button>
                <Button onClick={() => this.handleMove(true)} disabled={this.canNotMoveToHere()}>copy</Button>

                <Button onClick={() => this.handleClose()}>close</Button>

            </Modal.Content>
        </Modal>
    }

    canNotMoveToHere() {
        return (
            this.props.sourcePath == this.state.targetPath
            || this.state.targetPath == "/"
        )
    }

    goDownADir(newDir: string) {
        this.openDir(this.state.targetPath + "/" + newDir)
    }

    goUpADir() {
        let path = this.state.targetPath
        let newPath = path.substring(0, path.lastIndexOf("/"))
        this.openDir(newPath)
    }

    drawTargetSelectionTable() {
        if (this.state.response) {
            let files = this.state.response.files

            try {

                //filter to only get directories
                const files: any[] | null = this.state.response.files.filter(function (file) {
                    if (file.isDirectory == true) {
                        return file
                    } else {
                        return
                    }

                })

                const columns = [{
                    id: 'dir',
                    Header: 'Dir',
                    width: 40,
                    accessor: (d: any) => {
                        if (d.isDirectory) {
                            return <Icon name='folder'/>
                        } else {
                            return <Icon name='file outline'/>
                        }
                    }, // when changing the Folder-Icon, make sure you also change it in the ReactTable in the click desicion
                }, {
                    id: 'name',
                    Header: 'Name',
                    width: 120,
                    accessor: (d: any) => d.name,
                }]

                return <Segment>
                    <Button icon onClick={() => this.goUpADir()}><Icon name='reply'/></Button>
                    <Button icon onClick={() => this.openDir(this.state.targetPath)}><Icon name='refresh'/></Button>
                    <ReactTable
                        data={files}
                        pages={this.state.pages}
                        columns={columns}
                        sorted={[
                            {
                                id: 'timestamp',
                                desc: false
                            }
                        ]}
                        defaultPageSize={this.state.take}
                        className="-striped -highlight"
                        loading={this.state.isLoading}
                        getTdProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick: () => {
                                    if (rowInfo) {
                                        if (rowInfo.row.dir.props.name == 'folder') {
                                            console.log("Opening Dir: ", rowInfo.row.name)
                                            this.goDownADir(rowInfo.row.name)
                                        }
                                    }
                                }
                            };
                        }}
                        style={{cursor: "pointer"}}
                    />
                </Segment>

            } catch (ex) {
                return <Label color='red'>The data is in bad format: {JSON.stringify(files, null, 4)}</Label>

            }
        } else {
            return <Label color='yellow'>Loading...</Label>
        }
    }

}