import * as React from "react";
import {useEffect} from "react";
import {configStorage} from "../../../../../ConfigStorage";
import ReactTable from "react-table";
import {Button, Checkbox, Icon, Popup, Segment} from "semantic-ui-react";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";

export function StoredConfigs() {

    const auth = backend.withTokenAuthHeader(authentication.token)

    const [companiesWithConfigs, setCompaniesWithConfigs] = React.useState([])
    const [selected, setSelected] = React.useState([])
    const [changedOnDb, setChangedOnDb] = React.useState<
        {
            companyId: string,
            changedOnDb: boolean
        }[]>([])

    useEffect(() => {
        loadFromStorage()
    }, [])

    function loadFromStorage() {
        configStorage.getAllElements().then((result) => {
            setCompaniesWithConfigs(result)
            console.log("companiesWithConfigs", result)
            result.forEach((company: any) => {
                checkForChanges(company.companyId)
            })
        })
    }

    function checkForChanges(companyId: string) {
        configStorage.getElement(companyId).then((result) => {
            console.log("checkForChanges", result)
            backend.internalApi.getConfiguration(companyId, auth).then((r) => {
                console.log("checkForChanges", r)
                setChangedOnDb([...changedOnDb, {companyId: companyId, changedOnDb: r !== result.config}])
            })
        })
    }

    function displayUnsavedChanges(warn: Boolean) {
        if (warn) {
            return <><Icon name='exclamation triangle' color='red'/> Unsaved Changes</>
        } else {
            return <Icon name='check' color='green'/>
        }
    }
    function displayChangesOnDb(warn: Boolean) {
        if (warn) {
            return <><Icon name='exclamation triangle' color='red'/> Changes on DB</>
        } else {
            return <Icon name='check' color='green'/>
        }
    }

    function deleteSelected() {
        for (let company of selected) {
            configStorage.deleteCompany(company.companyId).then(r => {
                setSelected([])
                loadFromStorage()
            })
        }

    }

    function drawTable() {
        let columns = [
            {
                id: 'select',
                Header: 'Select',
                width: 70,
                accessor: (d: any) => <Checkbox
                    checked={selected.includes(d)}
                    onChange={() => setSelected(selected.includes(d) ? selected.filter((x) => x !== d) : [...selected, d])}
                />
            },
            {
                id: 'companyId',
                Header: 'companyId',
                width: 220,
                accessor: (d: any) => d.companyId,
            },
            {
                id: 'Name',
                Header: 'Name',
                width: 300,
                accessor: (d: any) => d.company.name,
            },
            {
                id: 'Unsaved Changes',
                Header: 'Unsaved Changes',
                width: 300,
                accessor: (d: any) => displayUnsavedChanges(d.config !== d.unchangedConfig),
            },
            {
                id: 'Changes on DB',
                Header: 'Changes on DB',
                width: 300,
                accessor: (d: any) => displayUnsavedChanges(changedOnDb.find((x) => x.companyId === d.companyId)?.changedOnDb),
            }
        ]

        return <>
            <div style={{
                display: "flex",
                flexDirection: "row",
            }}>
                <Segment
                    compact
                    style={{
                        padding: "-10px",
                    }}
                >
                    <Checkbox
                        checked={selected.length === companiesWithConfigs.length}
                        onChange={() => {
                            if (selected.length === companiesWithConfigs.length) {
                                setSelected([])
                            } else {
                                setSelected(companiesWithConfigs)
                            }
                        }}
                    /> Select All
                </Segment>

                <Popup content='Delete selected' trigger={
                    <Button
                        style={{
                            paddingLeft: 10,
                            width: 50,
                            height: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        disabled={selected.length === 0}
                        icon
                        onClick={deleteSelected}
                    >
                        <Icon name={"trash"} color={"red"}/>
                    </Button>
                }/>

            </div>


            <ReactTable
                data={companiesWithConfigs}
                columns={columns}
                showPagination={false}
                className="-striped -highlight"
                getTdProps={(state, rowInfo) => {
                    return {
                        onClick: () => {

                        }
                    };
                }}
            />
        </>
    }

    return (
        <div>
            <h1>StoredConfigs</h1>
            {drawTable()}
        </div>
    );
}