import * as React from "react";
import {useEffect} from "react";
import {Splitter} from "../../../../util/table-splitter-ui/src/components/Splitters/Splitter";
import {ExportSimPrepareSide} from "./ExportSimPrepareSide";
import {QueuedEvent} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {ExportSimOutputSide} from "./output/ExportSimOutputSide";

export interface ExportSimPageProps {

}

export function ExportSimPage(props: React.PropsWithChildren<ExportSimPageProps>) {

    const [auth, setAuth] = React.useState(null)
    const [xRequestId, setXRequestId] = React.useState(null)
    const [response, setResponse] = React.useState(null)
    const [queuedEvent, setQueuedEvent] = React.useState(null)
    const [config, setConfig] = React.useState(null)
    const [encoding, setEncoding] = React.useState(null)

    useEffect(() => {

        async function getAuth() {
            console.log("[ExportSimPage] getAuth")
            setAuth(backend.withTokenAuthHeader(authentication.token))
        }

        if (auth == null) {
            getAuth().then(r => {})
        }

    }, [])

    function drawLeftSide() {
        return <ExportSimPrepareSide
            auth={auth}
            beforeSend={(xRequestId: string) => {
                setXRequestId(xRequestId)
            }}
            onSend={(
                response: any,
                queuedEvent: QueuedEvent,
                respondedConfig: any,
                encoding: string,
            ) => {
                console.log("onSend")
                setResponse(response)
                setQueuedEvent(JSON.stringify(queuedEvent, null, 4))
                setConfig(respondedConfig)
                setEncoding(encoding)
            }
            }
        />
    }


    function drawRightSide() {

        return <ExportSimOutputSide
            queuedEvent={queuedEvent}
            xRequestId={xRequestId}
            response={response}
            config={config}
            encoding={encoding}
        />
    }

    return <>
        <Splitter
            left={drawLeftSide()}
            right={drawRightSide()}
            name={"exportSim"}
        />
    </>

}