import * as React from 'react';
import {Button, Header, Icon, Input, Modal} from "semantic-ui-react";
import {Address} from "../../../../../generated";


export interface CompanyAddressEditModalProps {
    isOpen: boolean,
    onClose: (newAddress: Address | null) => void,
    address: Address
}

export function CompanyAddressEditModal(props: React.PropsWithChildren<CompanyAddressEditModalProps>) {


    const [address, setAddress] = React.useState<Address>(props.address)

    async function save() {
        props.onClose(address)
    }

    function drawAddressFields() {

        let width = 300
        return <p >
            <Input
                style={{width: width}}
                label={"name"}
                labelPosition={"left corner"}
                value={address.name}
                onChange={(evt) => {
                    let newAddress = JSON.parse(JSON.stringify(address))
                    newAddress.name = evt.target.value
                    setAddress(newAddress)
                }}
            />
            <br/>
            <br/>
            <Input
                style={{width: width}}
                label={"country"}
                labelPosition={"left corner"}
                value={address.country}
                onChange={(evt) => {
                    let newAddress = JSON.parse(JSON.stringify(address))
                    newAddress.country = evt.target.value
                    setAddress(newAddress)
                }}
            />
            <br/>
            <br/>
            <Input
                style={{width: width}}
                label={"city"}
                labelPosition={"left corner"}
                value={address.city}
                onChange={(evt) => {
                    let newAddress = JSON.parse(JSON.stringify(address))
                    newAddress.city = evt.target.value
                    setAddress(newAddress)
                }}
            />
            <br/>
            <br/>
            <Input
                style={{width: width}}
                label={"zipCode"}
                labelPosition={"left corner"}
                value={address.zipCode}
                onChange={(evt) => {
                    let newAddress = JSON.parse(JSON.stringify(address))
                    newAddress.zipCode = evt.target.value
                    setAddress(newAddress)
                }}
            />
            <br/>
            <br/>
            <Input
                style={{width: width}}
                label={"street"}
                labelPosition={"left corner"}
                value={address.street}
                onChange={(evt) => {
                    let newAddress = JSON.parse(JSON.stringify(address))
                    newAddress.street = evt.target.value
                    setAddress(newAddress)
                }}
            />
            <br/>
            <br/>
            <Input
                style={{width: width}}
                label={"houseNumber"}
                labelPosition={"left corner"}
                value={address.houseNumber}
                onChange={(evt) => {
                    let newAddress = JSON.parse(JSON.stringify(address))
                    newAddress.houseNumber = evt.target.value
                    setAddress(newAddress)
                }}
            />
            <br/>
            <br/>
            <Input
                style={{width: width}}
                label={"additionalInfo"}
                labelPosition={"left corner"}
                value={address.additionalInfo}
                onChange={(evt) => {
                    let newAddress = JSON.parse(JSON.stringify(address))
                    newAddress.additionalInfo = evt.target.value
                    setAddress(newAddress)
                }}
            />
            <br/>
            <br/>
            <Input
                style={{width: width}}
                label={"gs1GlobalLocationNumber"}
                labelPosition={"left corner"}
                value={address.gs1GlobalLocationNumber}
                onChange={(evt) => {
                    let newAddress = JSON.parse(JSON.stringify(address))
                    newAddress.gs1GlobalLocationNumber = evt.target.value
                    setAddress(newAddress)
                }}
            />
        </p>

    }

    return <Modal
        open={props.isOpen}
        onClose={() => props.onClose(null)}
        dimmer="blurring"
        size='tiny'>
        <Header icon='browser' content='Company Address'/>
        <Modal.Content>

            {drawAddressFields()}
            <br/>
            <br/>
            <Button
                icon
                onClick={() => save()}>
                <Icon
                    name='save'/>
                Save
            </Button>

            <Button
                icon
                onClick={() => props.onClose(null)}>
                <Icon
                    name='cancel'/>
                Cancel
            </Button>

        </Modal.Content>
    </Modal>

}