import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button, Label, Popup, Segment} from 'semantic-ui-react';
import {authentication} from "../../../../../authentication";
import {backend} from "../../../../../xconvert-backend";
import RightSideTabView from "./rightSide/RightSideTabView";
import {ConfigContext} from "../../../context/ConfigContext";
import {EditRawCompanyConfig} from "./EditRawCompanyConfig";
import {IfBox} from "../../../../style/if";
import Notifications from 'react-notify-toast';
import {NavTree} from "./newNavTree/NavTree";
import {Splitter} from "../../../../util/table-splitter-ui/src/components/Splitters/Splitter";


export interface CompanyConfigProps {
    changeTab: (index: number, scriptPath: any) => void
}

export function CompanyConfig(props: React.PropsWithChildren<CompanyConfigProps>) {
    const context = useContext(ConfigContext);
    const [isLoading, setIsLoading] = useState(true);
    const [currentCompanyConfig, setCurrentCompanyConfig] = useState(null);
    const [auth, setAuth] = useState(null);
    const [activeItem, setActiveItem] = useState("NavTree");
    const [updateSwitch, setUpdateSwitch] = useState(false);
    const [validJson, setValidJson] = useState(true);

    function jsonValidation(value) {
        try {
            JSON.parse(value)
            setValidJson(true);
        } catch (e) {
            setValidJson(false);
        }
    }

    function rerender() {
        console.log("triggered onCloseCompareModal")
        setUpdateSwitch(!updateSwitch);
    }

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            setActiveItem(context.tabSession.configViewActiveItem);

            try {
                let auth = backend.withTokenAuthHeader(authentication.token)

                // ScriptInfo
                if (context.scriptInfo == null) {
                    let response = await backend.internalApi.getScriptInfo(auth)
                    context.setScriptInfo(response.scripts)
                }

                try {
                    // history and currentCompanyConfig should always come fresh from the backend, in case other users changed something.
                    //(currentCompanyConfig it the config currently in the database, "companyConfig" is our working copy and can come from the context)
                    let currentCompanyConfig = (await backend.internalApi.fetchCompanyConfiguration(context.companyId, auth))

                    setAuth(auth);
                    setCurrentCompanyConfig(currentCompanyConfig);
                } catch (ex) {
                    console.log(ex)
                    console.log("UNABLE TO LOAD CONFIG")
                }

            } catch (ex) {
                console.log(ex)
                console.log("UNABLE TO AUTHORIZE")
            }
            setIsLoading(false);
        }

        fetchData();
    }, []);

    function switchNavTreeAndRawView() {
        let name = context.tabSession.configViewActiveItem
        if (name == "NavTree") {
            name = "Raw"
        } else {
            name = "NavTree"
        }
        context.setSingleTabSession("configViewActiveItem", null, name, null)
        setActiveItem(name);
    }

    function drawFloatingElements(rawView) {
        return <div>
            {(validJson == false) &&
                <>
                    <Label size={'huge'} color='red'>INVALID JSON</Label>
                    <Button
                        onClick={() => {
                            setValidJson(true)
                            switchNavTreeAndRawView()
                        }}
                        size="small"
                        toggle
                    >
                        dismiss changes
                    </Button>
                </>
            }
            <Button
                disabled={validJson == false && rawView}
                size="small"
                toggle
                active={rawView}
                onClick={switchNavTreeAndRawView}
            >
                Switch to {rawView ? "Nav Tree" : "Raw Mode"}
            </Button>
        </div>
    }

    function drawMenu() {
        const rawView = activeItem == 'Raw'
        return <Segment className={"flex3"} style={{padding: "1px", margin: 0}}>
            <div id={"rawViewFloatingButton"} className={"rightFloatingBtn"} style={{top: "10px", right: "20px"}}>
                <Popup
                    content={'Switch to ' + (rawView ? "NavTree" : "Raw Mode")}
                    trigger={drawFloatingElements(rawView)}
                />
            </div>
            <IfBox shouldShow={rawView} else={
                <NavTree
                    auth={auth}
                    saveKeyListener={undefined}
                    changeTab={props.changeTab}
                />}>
                <EditRawCompanyConfig
                    currentCompanyConfig={currentCompanyConfig}
                    auth={auth}
                    jsonValidation={jsonValidation}
                    updateSwitch={updateSwitch}
                />
            </IfBox>
        </Segment>
    }

    console.log("RENDER COMPANY_CONFIG")

    if(isLoading) {
        return <div>Loading...</div>
    }
    return <div className={"flexRow"}>

        <Splitter
            name={"ConfigEditSplitter"}
            left={drawMenu()}
            right={<RightSideTabView
                auth={auth}
                onCloseCompareModal={rerender}
                reloadFromContext={(reload) => {
                    if (reload) {
                        setUpdateSwitch(!updateSwitch)
                        window.location.reload()
                    }
                }}
                context={context}
            />}
        />


        <Notifications/>
    </div>;

}