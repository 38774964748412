import * as React from "react";

const version = require('../../version.js');

export class HealthPage extends React.Component<any, any> {


    render() {
        let healthResponse = {
            healthy: true,
            version: version.version
        }

        return JSON.stringify(healthResponse, null, 4)
    }
}
