import * as React from "react";
import {AiPrompt} from "../slices/ruleSlice";
import {useState} from "react";
import {ParseTextRequest} from "../../../../../generated";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";

export type AiPomptTesterProps = {
    pompt: AiPrompt
    exampleInput: string
}

export const AiPomptTester = (props:React.PropsWithChildren<AiPomptTesterProps>) => {
    const [testOutput, setTestOutput] = useState("")

    async function runPomptWithTestData(){
        let input = `${props.pompt.instructionText}\n`
        input += props.pompt.examples.map(example=>`${example.input}|${example.output}`).join("\n")
        if(props.pompt.examples.length==1)  {
            input+= "\n"
        }
        input += props.exampleInput+"|"

        let aiRequest = {
            input: input,
            rawInput: input,
            type: "immediateFormat."+props.pompt.name,
            stopSequence: "\n"
        } as ParseTextRequest
        const auth = backend.withTokenAuthHeader(authentication.token)
        const aiResponse = await backend.internalApi.aiParseText(aiRequest, auth)

        setTestOutput(aiResponse.output)
    }

    return <>
        <span>{props.exampleInput}</span>
        <span>{testOutput}</span>
        <span><button type={"button"} onClick={runPomptWithTestData}>▶</button></span>
    </>
}