import * as React from "react";
import {useState} from "react";
import "./RuleList.css"
import {useDispatch, useSelector} from "react-redux";
import {AiPrompt, getParseOptions, setParseOptions} from "../slices/ruleSlice";
import {AiPromptModalButton} from "./AiPomptModal";
import "./AiPomptList.css"

interface AiPromptListProps {

}

export function AiPromptList(props:React.PropsWithChildren<AiPromptListProps>){
    const options = useSelector(getParseOptions)
    const dispatch = useDispatch()

    const deriveState = () => {
        if(!options){
            console.log("********** AI Prompt options are not set! **********")
        }
        return options?.aiPrompts ?? []
    }

    const [aiPrompts, setAiPrompts] = useState(deriveState())

    function save(prompt: AiPrompt){
        const entryToUpdate = aiPrompts.find(x=>x.id==prompt.id)

        if(entryToUpdate == undefined){
            const p = [...aiPrompts, prompt]
            setAiPrompts(p)
            dispatch(setParseOptions({...options, aiPrompts:p}))
        } else {
            const p = aiPrompts.map(p=>p.id==prompt.id?prompt:p)
            setAiPrompts(p)
            dispatch(setParseOptions({...options, aiPrompts:p}))
        }
    }

    return <>
        <fieldset>
            <legend>AI Prompts</legend>
            <table className={"aiPromptTable"}>
                {aiPrompts && aiPrompts.length>0 ?<>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th className={"aiPromptOptColumn"}>Opt</th>
                        </tr>
                        </thead>
                       <tbody>
                       <>
                           {
                               aiPrompts.map(p=>{
                                 return <tr>
                                       <td>{p.name ?? "---"}</td>
                                       <td><AiPromptModalButton pompt={p} onSave={save}>✎</AiPromptModalButton></td>
                                   </tr>
                                })
                           }
                       </>
                       </tbody>
                    </>:undefined
                }
                <tfoot>
                <tr>
                    <td colSpan={2}><AiPromptModalButton
                        pompt={{id: Math.max(0,...aiPrompts.map(x=>x.id))+1, name:undefined,instructionText:undefined, examples:[]}}
                        onSave={save}
                    >Add</AiPromptModalButton></td>
                </tr>
                </tfoot>
            </table>
        </fieldset>
    </>
}