import * as React from 'react';
import {Header, Modal} from "semantic-ui-react";
import {AddressMappingGeofenceForm} from "./address_mapping_geofence_form";
import {GlobalGeofence} from "../../../../../generated";

export interface GlobalGeofenceCreateModalProps {
    isOpen: boolean,
    onClose: () => void
    onSave: (newGlobalGeofence: GlobalGeofence) => Promise<any>
    onPendingChange: () => void
}

export function GlobalGeofenceCreateModal(props: React.PropsWithChildren<GlobalGeofenceCreateModalProps>) {

    return <Modal
        open={props.isOpen}
        onClose={props.onClose}
        dimmer="blurring"
        size='fullscreen'>
        <Header icon='browser' content='Create Global Geofence'/>
        <Modal.Content>

            <AddressMappingGeofenceForm
                mapping={null}
                initialLatitude={49.882452}
                initialLongitude={8.644958}
                onSave={props.onSave}
                saveButtonText={"save"}
                onPendingChange={props.onPendingChange}
                onClose={props.onClose}
            />

        </Modal.Content>
    </Modal>

}