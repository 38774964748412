import * as React from "react";
import {Button, Grid} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import AceEditor from 'react-ace';
import TabEditConfigMenu from "./EdiDocViewerOutputTabView";
import {configStorage} from "../../../../../ConfigStorage";
import {IfBox} from "../../../../style/if";
import {EdiDocumentDefinitionBuilderModal} from "./EdiDocumentDefinitionBuilderModal";
import {v4 as uuid} from 'uuid';


export interface EdiDocViewerProps {
    output: string
}

export interface EdiDocViewerState {
    ediDoc: string
    dictionary: string
    treeDocumentDefinition: string
    showDocumentDefinition: boolean
    treeDocDefSuccess: boolean
}


export class EdiDocViewer extends React.Component<EdiDocViewerProps, EdiDocViewerState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)

        this.state = {
            ediDoc: "",
            dictionary: "",
            treeDocumentDefinition: "",
            showDocumentDefinition: false,
            treeDocDefSuccess: false
        }
    }

    componentDidMount() {
        if (this.props.output) {
            this.setState({ediDoc: this.props.output})
        } else {
            this.setState({ediDoc: this.context.ediDoc})
        }
    }

    setEdiDoc(value) {
        console.log("setting Edi Doc")
        this.context.setEdiDoc(value)
        this.setState({ediDoc: value})
    }

    async sendToConverter() {


        try {
            let xReqId = uuid()
            let resp = await fetch('/converter/ediDocToRecordsWithDescription', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    'x-request-id': xReqId
                },
                body: this.state.ediDoc
            }).then((resp) => {
                return resp.json()
            })
            console.log("INFO RESPONSE: ", resp)
            this.setState({dictionary: JSON.stringify(resp, null, 4)})
        } catch (ex) {
            this.setState({dictionary: ex})
        }

        let config = (await configStorage.getElement(this.context.companyId)).config
        if (config.ediConverterConfiguration?.documentDefinition) {

            let req = {} as any

            req.config = config
            req.doc = this.state.ediDoc

            try {
                let xReqId = uuid()
                let resp = await fetch('/converter/ediDocToRecordTreeWithDescription', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                        'x-request-id': xReqId
                    },
                    body: JSON.stringify(req, null, 4)
                }).then((resp) => {
                    return resp.json()
                })
                console.log("INFO RESPONSE: ", resp)
                let respStr = JSON.stringify(resp, null, 4)
                this.setState({treeDocumentDefinition: respStr})

                this.setState({treeDocDefSuccess: (!respStr.includes("detailMessage") || !respStr.includes("stackTrace"))})

            } catch (ex) {
                this.setState({treeDocumentDefinition: ex, treeDocDefSuccess: false})
            }
        } else {
            console.log("This config doesn't have a documentDefinition in the ediConverterConfiguration. Can not load the Edi DocumentDefinitionTree from the converter!")
        }
    }


    render() {
        console.log("render edi doc viewer")
        return <div className={"flexRow"}>
            <div className={"flex2"}>
                <AceEditor
                    theme="monokai"
                    mode='groovy'
                    style={{flex: 2}}
                    value={this.state.ediDoc}
                    placeholder='copy your EDI-Document here!'
                    width="100%"
                    onChange={(value) => {
                        this.setState({ediDoc: value})
                        this.context.setEdiDoc(value)
                    }}
                    readOnly={false}
                />
            </div>
            <div className="flex" style={{width: "110px", padding: "3px"}}>
                <Button type="button" onClick={() => this.sendToConverter()}>Convert</Button>
                <Button type="button" disabled={!this.state.treeDocDefSuccess} onClick={() => this.setState({showDocumentDefinition: true})}>Definition</Button>
            </div>
            <div className={"flex2"}>

                <TabEditConfigMenu context={this.context} flatOutput={this.state.dictionary} treeOutput={this.state.treeDocumentDefinition}/>

                <IfBox shouldShow={this.state.showDocumentDefinition}>
                    <EdiDocumentDefinitionBuilderModal isOpen={this.state.showDocumentDefinition}
                                                       onClose={() => this.setState({showDocumentDefinition: false})}
                                                       treeJson={this.state.treeDocumentDefinition}/>
                </IfBox>
            </div>
        </div>
    }

}