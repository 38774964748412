import * as React from "react";
import "./RuleList.css"
import {RuleList} from "./RuleList";
import {AutoSaveRulesToConfig} from "./buttons/AutoSaveRulesToConfig";
import {RuleToggle} from "./buttons/RuleToggle";
import {SelectConfig} from "./buttons/SelectConfig";
import {Container} from "semantic-ui-react";
import {SpecificRuleToggle} from "./buttons/SpecificRuleToggle";
import {useSelector} from "react-redux";
import {getActiveConfig} from "../slices/ruleSlice";
import {IfBox} from "../../../../style/if";


interface LeftMenuProps {
}

export function LeftMenu(props:React.PropsWithChildren<LeftMenuProps>){
    const activeConfig = useSelector(getActiveConfig)

    return <Container className={"flex1"} id={"tableSplitterLeftMenu"}>
        <RuleToggle/>
        <SelectConfig/>
        <IfBox shouldShow={activeConfig!="default"}><SpecificRuleToggle/></IfBox>
        {/*<EditModeButton/>*/}
        <AutoSaveRulesToConfig/>
        {/*<LoadRulesFromConfigButton />*/}
        <RuleList key={"LeftRuleList"}/>
    </Container>
}
