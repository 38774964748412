import * as React from "react";
import {useContext} from "react";
import {CustomerReportDynamicSection, statusHighlighter} from "../CustomerReportDynamicSection";
import {ConfigContext} from "../../../../context/ConfigContext";
import {AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest} from "../../../../../../generated";
import ServiceTypeEnum = AddOrRemoveCompaniesFromIgnoreListForCustomerReportRequest.ServiceTypeEnum;
import {customerReport} from "../../../../../style/page_with_sidebar";

export function CustomerReportApiGatewayStatus({reFetchReportData}: { reFetchReportData: () => Promise<void> }) {

    const context = useContext(ConfigContext)

    const columns = [
        {
            id: 'displayName',
            Header: 'Queue Name',
            width: 300,
            accessor: (d: any) => d.displayName,
        },
        {
            id: 'icon',
            Header: 'api Status',
            width: 100,
            accessor: (d: any) => <div>{statusHighlighter(d.status)} {d.status}</div>,
        },
        {
            id: 'responsibleManager',
            Header: 'Responsible manager',
            width: 100,
            accessor: (d: any) => d.responsibleManager,
        },]
    return <CustomerReportDynamicSection
        columns={columns}
        data={customerReport.value?.apiGatewayStatus?.companyApiStatus}
        currentService={ServiceTypeEnum.APIGATEWAYREPORT}
        reFetchReportData={reFetchReportData}
    />

}