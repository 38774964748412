import * as React from "react";
import {PageWithSidebar} from "../../../style/page_with_sidebar";
import {ConfigContext} from "../../context/ConfigContext";
import Notifications from 'react-notify-toast';
import {Tab} from "semantic-ui-react";
import {CompanyConfig} from "./configEdit/CompanyConfig";
import {ConfigDiagramPage} from "./configDiagram/ConfigDiagramPage";
import {CompanyEditPage} from "./companyEdit/CompanyEditPage";
import {ScriptConverterPlayground} from "./ScriptConverterPlayground/ScriptConverterPlayground";
import {StoredConfigs} from "./storedConfigs/StoredConfigs";

export function ConfigPage() {
    const context = React.useContext(ConfigContext);

    const [scriptPath, setScriptPath] = React.useState("")
    const [activeIndex, setActiveIndex] = React.useState(context.tabSession.configTab)


    function changeTab(index, scriptPath) {
        console.log("changeTab", index)
        context.setSingleTabSession("configTab", index, null, null)
        context.setScriptConverterPlaygroundScriptPath(scriptPath)
        setActiveIndex(index)
        if(scriptPath != "" && scriptPath != undefined) {
            setScriptPath(scriptPath)
        }
    }

    return (
        <PageWithSidebar activeMenu="Config">
            <Tab
                class={"flexTab"}
                panes={[
                    { pane: null, menuItem: 'Edit', render: () => <Tab.Pane id='navTreeTab'><CompanyConfig changeTab={changeTab}/></Tab.Pane> },
                    { pane: null, menuItem: 'Diagram', render: () => <Tab.Pane id='treeTab'><ConfigDiagramPage /></Tab.Pane> },
                    { pane: null, menuItem: 'Company Edit', render: () => <Tab.Pane id='companyEditTab'><CompanyEditPage /></Tab.Pane> },
                    { pane: null, menuItem: 'Script Converter Playground', render: () => <Tab.Pane id='scriptConverterPlaygroundTab'><ScriptConverterPlayground scriptPath={scriptPath}/></Tab.Pane> },
                    { pane: null, menuItem: 'StoredConfigs', render: () => <Tab.Pane id='StoredConfigs'><StoredConfigs /></Tab.Pane> },
                ]}
                onTabChange={(_, data) => {
                    let indexNumber = parseInt(data.activeIndex.toString())
                    context.setSingleTabSession("configTab", indexNumber, null, null)
                    setActiveIndex(indexNumber)
                }}
                activeIndex={activeIndex}
            />

            <Notifications/>
        </PageWithSidebar>
    );
}
