import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button, Dropdown, DropdownItemProps, Grid, Header, Icon, Label, Modal} from "semantic-ui-react";
import {ConfigContext} from "../../../../main/context/ConfigContext";
import {IfBox} from "../../../../style/if";
import {CompanyConfiguration, StatusReportConfiguration} from "../../../../../generated";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import {configStorage} from "../../../../../ConfigStorage";
import {updateConfig, workingConfig} from "../../configChange/ConfigSignal";

export interface ReportScriptSyncModalProps {
    isOpen: boolean,
    loadedScript: (script: string) => void
    onClose: () => void,
    script: string
}

export function ReportScriptSyncModal(props: React.PropsWithChildren<ReportScriptSyncModalProps>) {

    const context = useContext(ConfigContext)


    const [selectedReport, setSelectedReport] = useState<DropdownProps>(null)
    const [path, setPath] = useState("$.")
    const [config, setConfig] = useState<CompanyConfiguration>(null)

    useEffect(() => {
        if (workingConfig.value) {
            setConfig(JSON.parse(workingConfig.value))
        } else {
            configStorage.getElement(context.companyId).then((c) => {
                setConfig(c.config)
                updateConfig(JSON.stringify(config, null, 4), false, false)
            })

        }
    }, [])

    function specificDropdownOptions() {
        let options: Array<DropdownItemProps> = [];

        let index = 0

        config.uiUiViewConfiguration.statusMonitoring.reports?.forEach(s => {
            options.push({
                key: index,
                value: JSON.stringify(s, null, 4),
                text: s.id,
            })
            index++
        })
        return options
    }

    function drawPathSelection() {

        if (config?.uiUiViewConfiguration?.statusMonitoring?.reports?.length > 0) {
            return <>
                <Dropdown
                    selectOnBlur={false}
                    readOnly={false}
                    options={specificDropdownOptions()}
                    selection
                    value={selectedReport?.value}
                    onChange={(evt, data) =>
                        setSelectedReport(data)
                    }
                />
            </>
        } else {
            return <p>There is no genericXlsConfiguration in your current config</p>
        }
    }

    function drawButtons() {

        let script

        if (selectedReport?.value) {
            script = (JSON.parse(selectedReport?.value as string) as StatusReportConfiguration).reportScript
        }


        let newPath = path

        if (selectedReport?.value) {
            let i = config?.uiUiViewConfiguration?.statusMonitoring?.reports?.findIndex(sc =>
                sc.id == (JSON.parse(selectedReport?.value as string) as StatusReportConfiguration).id
            )
            newPath = "$.uiUiViewConfiguration.statusMonitoring.reports." +
                i +
                ".reportScript"
        }


        if (path != newPath) {
            setPath(newPath)
        }
        return <>
            <br/>
            <Label>Path: </Label>
            <h4>
                {path}
            </h4>

            <IfBox shouldShow={selectedReport == null}>
                <Label color={'red'}>No report selected!</Label>
            </IfBox>
            <IfBox shouldShow={script == null}>
                <Label color={'yellow'}>No script present there! Please create one or save this one first!</Label>
            </IfBox>


            <br/>

            <Button
                icon
                disabled={script == null}
                onClick={() => loadScript(script)}
            >
                <Icon color={'blue'} name='download'/>
                load script
            </Button>
            <Button
                icon
                disabled={selectedReport == null}
                onClick={() => saveScript()}
            >
                <Icon color={'green'} name='save'/>
                save script
            </Button>
            <Button
                icon
                onClick={() => props.onClose()}
            >
                <Icon color={'red'} name='close'/>
                close
            </Button>
        </>
    }

    function loadScript(script) {
        props.loadedScript(script)
        props.onClose()
    }

    function saveScript() {
        // change in currentJSON
        const jp = require('jsonpath');

        jp.value(config, path, props.script)
        updateConfig(JSON.stringify(config, null, 4), true, false)
        props.onClose()
    }

    return <Modal
        open={props.isOpen}
        onClose={props.onClose}
        dimmer="blurring"
        size='large'>
        <Header icon='browser' content='Sync Script'/>
        <Modal.Content>
            <Grid padded="horizontally" stackable columns='equal' centered>
                <Grid.Column>
                    <Grid.Row>
                        {drawPathSelection()}

                    </Grid.Row>
                    <Grid.Row>
                        {drawButtons()}
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column>
                    <h4>Info</h4>
                    <p> Here you can select if you want to load from/save to the reportScripts from the config.
                        This please make sure id's are unique.
                        Otherwise one of the duplicates might not run and it's impossible to know which one.
                        You cannot create new reports from here, they must be created in the config before.
                    </p>
                </Grid.Column>
            </Grid>
        </Modal.Content>
    </Modal>

}