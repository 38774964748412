import AceEditor from "react-ace";
import {useDispatch, useSelector} from "react-redux";
import {getSourceCode, setSourceCode} from "../slices/sourceCodeSlice";
import "./SourceCodeView.css"
import * as React from "react";
import {useEffect} from "react";

export interface SourceCodeViewProps {

}

export function SourceCodeView(props: React.PropsWithChildren<SourceCodeViewProps>){

    const source = useSelector(getSourceCode)
    const dispatch = useDispatch()

    useEffect(() => {
        if(source == null || source == "") {
            let storedSourceCode = localStorage.getItem("TABLE_SPLITTER_SCRIPT")
            dispatch(setSourceCode(storedSourceCode))
        }
    },[])
    return (
        <AceEditor
            name="sourceCodeView"
            width="calc(100%-4px)"
            style={{flex: "1", flexGrow: 1, minHeight: "300px"}}
            value={source}
            onChange={evt=>{dispatch(setSourceCode(evt))}}
        />
    )
}