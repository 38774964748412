import * as React from "react";
import {Button, Form, Header, Input, Modal} from "semantic-ui-react";
import {authentication} from "../../../../../../authentication";
import {SecondFactorAuth} from "../../../../../account/SecondFactorAuth";

export interface RefreshTokenModalProps {
    isOpen: boolean
    onClose: () => void
}

export class RefreshTokenModal extends React.Component<RefreshTokenModalProps, any> {

    constructor(props: RefreshTokenModalProps) {
        super(props)
        this.state = {}
    }

    handleClose() {
        this.props.onClose()
    }

    handleInputChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        this.setState({
            [name]: value
        });
    }

    async performLogin(totp: String | null): Promise<void> {
        this.setState({"isLoading": true})

        let result = await authentication.doAuthenticate(this.state.username, this.state.password, totp)

        this.setState({"isLoading": false})

        if (!result.successful) {
            console.log("Login has failed")
            this.setState({"hasFailed": true})
            return
        }

        console.info("Auth successful")
        this.handleClose()

    }

    render() {

        return <Modal
            id='RefreshTokenModal'
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'>
            <Header icon='browser' content='Refresh Token!'/>
            <Modal.Content>

                <p>Your Token expires at {authentication.validUntil.toString()}</p>

                <p>Enter your credentials again to refresh it.</p>

                <Form>
                    <Form.Field>
                        <Input id='mail' icon='user' placeholder='E-Mail Addresse' iconPosition='left' name="username"
                               value={this.state["username"]} onChange={evt => this.handleInputChange(evt)}/>
                    </Form.Field>
                    <Form.Field>
                        <Input icon='lock' type="password" placeholder="Password" iconPosition='left' name="password"
                               value={this.state["password"]} onChange={evt => this.handleInputChange(evt)}/>
                    </Form.Field>
                </Form>

                <Button color="teal" onClick={async evt => {
                    try {
                        await this.performLogin(null)
                    } catch (e: any) {
                        console.log("Authentication failed: ", e)
                        if (e.status == 420) {
                            this.setState({showTotpInput: true, isLoading: false})
                        } else {
                            throw e
                        }
                    }
                }}>refresh</Button>
                {this.state.showTotpInput && <SecondFactorAuth validateTotp={(totp: string) => this.performLogin(totp)}/>}
                <Button onClick={() => this.handleClose()}>Close</Button>

            </Modal.Content>
        </Modal>
    }

}