import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {DbQueryBuilder} from "../../DBquery/DbQueryBuilder";
import {QueryResponse} from "../../../../../generated";
import {Dropdown, Input, Label} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import * as moment from "moment";


export interface GeostatusWorkbenchTransportOrderQueryProps {
    onUpdate: (queryResponse: QueryResponse) => void
}

export function GeostatusWorkbenchTransportOrderQuery(props: React.PropsWithChildren<GeostatusWorkbenchTransportOrderQueryProps>) {

    const context = useContext(ConfigContext)

    const [limit, setLimit] = useState(10)
    const [transportOrders, setTransportOrders] = useState([])

    const [predefinedTimeQuery, setPredefinedTimeQuery] = useState<any>()

    useEffect(() => {
        setLimit(context.reportScriptQueryLimit)
        if (context.reportScriptOrderResponse != null) {
            onUpdate(context.reportScriptOrderResponse, "TransportOrders")
        }
    }, [])

    function updateLimit(newLimit: number) {
        context.setReportScriptQueryLimit(newLimit)
        setLimit(newLimit)
    }

    function onUpdate(queryResponse: QueryResponse, tabCollection: string) {
        setTransportOrders(queryResponse.transportOrders)
        props.onUpdate(queryResponse)
        if (queryResponse != context.reportScriptOrderResponse) {
            console.log("[CONTEXT] - onUpdate: ", queryResponse)
            context.setReportScriptOrderResponse(queryResponse)
        }
    }

    function getColor() {
        if (transportOrders.length > 0) {
            return "green"
        } else {
            return "red"
        }
    }

    function dropDownCollectionOptions() {
        return [
            {key: 'Day', text: 'Day', value: 'Day'},
            {key: 'Week', text: 'Week', value: 'Week'},
            {key: 'Month', text: 'Month', value: 'Month'},
        ]
    }

    async function onSelectionOfPredefinedTimeQuery(tq: string) {
        let newQuery = null
        let today = Date.now()
        switch (tq) {
            case 'Day': {
                let todayStr = moment().subtract(0).format("DD.MM.YYYY")
                newQuery = "{\n" +
                    "  \"rules\": [\n" +
                    "    {\n" +
                    "      \"field\": \"planStart\",\n" +
                    "      \"value\": \"" + todayStr + "\",\n" +
                    "      \"operator\": \"Equal\"\n" +
                    "    }\n" +
                    "  ],\n" +
                    "  \"combinator\": \"and\"\n" +
                    "}"
                break;
            }
            case 'Week': {
                let startmoment = moment().subtract(0).startOf("week").format("DD.MM.YYYY")
                let endmoment = moment().subtract(0).endOf("week").format("DD.MM.YYYY")

                newQuery = "{\n" +
                    "  \"rules\": [\n" +
                    "    {\n" +
                    "      \"field\": \"planStart\",\n" +
                    "      \"value\": \"" + startmoment + "\",\n" +
                    "      \"operator\": \"GreaterThan\"\n" +
                    "    },\n" +
                    "    {\n" +
                    "      \"field\": \"planStart\",\n" +
                    "      \"value\": \"" + endmoment + "\",\n" +
                    "      \"operator\": \"LowerThan\"\n" +
                    "    }\n" +
                    "  ],\n" +
                    "  \"combinator\": \"and\"\n" +
                    "}"
                break;
            }
            case 'Month': {
                let startmoment = moment().subtract(0).startOf("month").format("DD.MM.YYYY")
                let endmoment = moment().subtract(0).endOf("month").format("DD.MM.YYYY")

                newQuery = "{\n" +
                    "  \"rules\": [\n" +
                    "    {\n" +
                    "      \"field\": \"planStart\",\n" +
                    "      \"value\": \"" + startmoment + "\",\n" +
                    "      \"operator\": \"GreaterThan\"\n" +
                    "    },\n" +
                    "    {\n" +
                    "      \"field\": \"planStart\",\n" +
                    "      \"value\": \"" + endmoment + "\",\n" +
                    "      \"operator\": \"LowerThan\"\n" +
                    "    }\n" +
                    "  ],\n" +
                    "  \"combinator\": \"and\"\n" +
                    "}"
                break;
            }
            case null: {
                newQuery = "{\n" +
                    "  \"rules\": [],\n" +
                    "  \"combinator\": \"and\"\n" +
                    "}"
                break;
            }
        }
        context.setDbQuery(JSON.parse(newQuery))

        // force the DbQueryBuilder to update
        let tempLimit = limit
        await setLimit(10)
        await setLimit(tempLimit)

    }

    return <>
        <Input
            label={'limit'}
            value={limit}
            type="number"
            onChange={evt => updateLimit(Number(evt.target.value))}
        />
        <Label
            color={getColor()}
        >
            Found: {transportOrders.length} Orders
        </Label>
        <Dropdown
            selectOnBlur={false}
            placeholder={"Select a predefined query"}
            clearable
            options={dropDownCollectionOptions()}
            selection
            value={predefinedTimeQuery}
            onChange={(evt, data) => onSelectionOfPredefinedTimeQuery(data.value.toString())
            }/>

        <DbQueryBuilder
            onUpdate={onUpdate}
            take={limit}
            page={1}
            sortBy={'_id'}
            sortDirection={'DESC'}
            restrictToCollections={['TransportOrders']}
        />
    </>
}