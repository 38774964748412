import * as React from "react";
import {useSelector} from "react-redux";
import {getRulesToEdit} from "../slices/rightMenuSlice";
import {RuleEditForm} from "./RuleEditForm";
import {SourceCodeView} from "./SourceCodeView";
import {ExecutionOutputView} from "./ExcecutionOutputView";
import {useState} from "react";
import './RightMenu.css'
import {OptionsEditForm} from "./OptionsForm";
import {AiPromptList} from "./AiPromptList";
import {GenericXlsExtractionRule} from "../../../../../generated";

export interface RightMenuProps{

}

export interface RightMenuHeaderProps{
    defaultExpanded?: boolean
    title: string
}

export function RightMenuHeader(props:React.PropsWithChildren<RightMenuHeaderProps>){
    const [isExpanded, setExpanded] = useState(props.defaultExpanded??false)
    return <><li className="RuleListHeader clickable" onClick={()=>setExpanded(!isExpanded)}>{isExpanded ? "v" : ">"} {props.title}</li>
        { isExpanded && props.children }
    </>
}

export function RightMenu(props:React.PropsWithChildren<RightMenuProps>){
    const selectedRules: GenericXlsExtractionRule[]|null = useSelector(getRulesToEdit)

    return <ul className="flex1 pad0">

        <RightMenuHeader title={"General Options"}>
            <OptionsEditForm/>
        </RightMenuHeader>

        <RightMenuHeader title={"AI Prompts"}>
            <AiPromptList/>
        </RightMenuHeader>

        <RightMenuHeader title={"Edit Matching Rule"} defaultExpanded={true}>
            { (selectedRules && selectedRules.length > 0) &&
                <RuleEditForm rules={selectedRules}/>
            }
        </RightMenuHeader>

        <RightMenuHeader title={"Extraction Script"}>
            <SourceCodeView/>
        </RightMenuHeader>

        <RightMenuHeader title={"Output"} defaultExpanded={true}>
            <ExecutionOutputView/>
        </RightMenuHeader>
    </ul>
}