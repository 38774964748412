import * as React from "react";
import { Modal, Header, Button, Label, Input } from "semantic-ui-react";
import { TransportOrder, ExpectedResult, SaveTransportOrderConfigTestRequest, TransportOrderConfigTestInput, TransportOrderConfigTestInputImport } from "../../../../../generated";
import { ConfigContext } from "../../../context/ConfigContext";
import { backend } from "../../../../../xconvert-backend";
import { authentication } from "../../../../../authentication";

export interface ImportSimSaveAsTestModalProps {
    isOpen: boolean
    onClose: (success: boolean) => void
    expectedResult: [TransportOrder] | null
    input: string | null
    inputFile: File | null
    inputActive: string | null
}
export interface ImportSimSaveAsTestModalState {
    testName: string | null
}


export class ImportSimSaveAsTestModal extends React.Component<ImportSimSaveAsTestModalProps, ImportSimSaveAsTestModalState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            testName: ""
        }
    }

    async onSave() {

        var fileBase64 = null
        if (this.props.inputActive == "TEXT") {
            fileBase64 = new Buffer(this.props.input).toString('base64');
        } else {
            fileBase64 = await takeInputAsBase64(this.props.inputActive, this.props.inputFile)
            fileBase64 = fileBase64.toString().split(";base64,")[1]

        }



        let request = {} as SaveTransportOrderConfigTestRequest
        request.companyId =  this.context.companyId
        request.testName = this.state.testName
        request.type = SaveTransportOrderConfigTestRequest.TypeEnum.IMPORT

        request.expectedResult = {} as ExpectedResult
        request.expectedResult.importData = this.props.expectedResult

        request.input = {} as TransportOrderConfigTestInput
        request.input.importData = {} as TransportOrderConfigTestInputImport
        request.input.importData.importDoc = fileBase64
        request.input.importData.type = this.context.confSimInputActive as TransportOrderConfigTestInputImport.TypeEnum
        if (this.props.inputFile != null) {
            request.input.importData.fileName = this.props.inputFile.name
        }
        console.log("sending the following: " + request)

        let auth = (await backend.withTokenAuthHeader(authentication.token))

        let response = (await backend.internalApi.saveTransportOrderConfigTest(request, auth))
        this.props.onClose(false)

    }

    handleClose = () => {
        this.props.onClose(false)
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="inverted"
            size='small'>
            <Header icon='browser' content='Save as Test' />
            <Modal.Content>

                <Label >
                    Test Name
                </Label>
                <br />
                <Input style={{ width: 220 }} placeholder='TestName'
                    value={this.state.testName}
                    onChange={(evt) => this.setState({ testName: evt.target.value })}
                />

                <Button onClick={(evt) => this.onSave()} >Save</Button>
                <Button onClick={(evt) => this.handleClose()} >Cancel</Button>


            </Modal.Content>
        </Modal >
    }
}

function takeInputAsBase64(activeSource, input) {
    return new Promise<string>((resolve, reject) => {
        let reader = new FileReader();
        if (activeSource == "TEXT") {
            reader.readAsText(input)
        } else {
            reader.readAsDataURL(input)
        }

        reader.onload = () => {
            resolve(reader.result.toString());
        };

        reader.onerror = reject;

    })
}