import * as React from "react";
import {LatLng, Map, Marker, Polygon, Popup, TileLayer} from 'react-leaflet';
import {Button, Form, Icon, Input, Label} from 'semantic-ui-react';
import {AddressWithCoordinates, GlobalGeofence} from "../../../../../generated/api";
import {TILE_SERVER_ACCESS_TOKEN, TILE_SERVER_URL, TILE_STYLE} from "../../../../../mapUtils";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {LatLngExpression} from "leaflet";
import * as Leaflet from "leaflet";

export interface AddressMappingGeofenceFormProps {
    mapping?: GlobalGeofence
    initialLatitude?: number
    initialLongitude?: number
    onSave: (newGlobalGeofence: GlobalGeofence) => Promise<any>
    saveButtonText?: string
    onPendingChange: () => void
    onClose: () => void
}

export interface AddressMappingGeofenceFormState {
    multiPolygonGeofence: string | any[]
    position: LatLngExpression | undefined
    defaultPosition: LatLngExpression
    zoom: number
    editHistory: any[]
    geofence: any[]
    activeGeofence: any[]
    activeIndex: number
    editMode: boolean
    dragging: boolean
    isUndoing: boolean
    isSubmitting: boolean
    isDeleting: boolean
    name: string
    addressRegex: string
}

export class AddressMappingGeofenceForm extends React.Component<AddressMappingGeofenceFormProps, AddressMappingGeofenceFormState> {

    private mapRef = React.createRef<Map>()

    constructor(props: AddressMappingGeofenceFormProps) {
        super(props);
        console.log("Current mapping is")
        console.log(this.props.mapping)

        console.log("Initial pos is " + props.initialLatitude + "/" + props.initialLongitude)


        this.state = {
            multiPolygonGeofence: "",
            position: (props.initialLatitude && props.initialLongitude) ? {
                lat: props.initialLatitude,
                lng: props.initialLongitude
            } as LatLngExpression : undefined,
            defaultPosition: {lat: 49.882452, lng: 8.644958} as LatLngExpression,
            zoom: 13,
            editHistory: [],
            geofence: [],
            activeGeofence: [],
            activeIndex: 0,
            editMode: false,
            dragging: false,
            isUndoing: false,
            isSubmitting: false,
            isDeleting: false,
            name: "",
            addressRegex: ""
        }
        if (this.props.mapping) {
            let m = this.props.mapping
            if (m.matchingAreaGeofences !== undefined && m.matchingAreaGeofences !== null) {

                let activeGeofence = JSON.parse(m.resolvingGeofence.geofence)
                    if (m.resolvingGeofence.geofence.startsWith("[[")) {
                        activeGeofence = activeGeofence[this.state.activeIndex]
                    }
                this.state = {
                    ...this.state,
                    geofence: m.matchingAreaGeofences.map((gf) => JSON.parse(gf.geofence) as LatLng) || [],
                    activeGeofence: activeGeofence || [],
                    multiPolygonGeofence: m.matchingAreaGeofences.map((gf) => JSON.parse(gf.geofence) as LatLng) || [],
                    name: m.name,
                    addressRegex: m.addressRegex
                }
            } else {
                this.state = {
                    ...this.state,
                    geofence: [],
                    activeGeofence: [],
                    multiPolygonGeofence: [],
                }
            }
        }
    }

    componentDidMount() {
        this.updateGeofence()
    }

    async setCoordinatesFromBackend() {
        try {
            const authHeader = backend.withTokenAuthHeader(authentication.token)

            let mapping = this.props.mapping

            let coordinates: LatLng = {lat: 49.882452, lng: 8.644958}

            if (mapping) {
                let response: AddressWithCoordinates = (await backend.internalApi.resolveCompanyAddressMappingToCoordinates(mapping, authHeader))
                if (response && response.coordinates && response.coordinates.latitude && response.coordinates.longitude) {
                    coordinates = {lat: response.coordinates.latitude, lng: response.coordinates.longitude} as LatLng
                }
            }
            this.setState({position: coordinates as LatLngExpression})
        } catch (e) {

        }
    }

    updateGeofence() {
        console.log("Updating geofence")


        let geofence = this.state.geofence
        let index = this.state.activeIndex
        let activeGeofence = this.state.activeGeofence
        geofence[index] = activeGeofence
        if (JSON.stringify(geofence) != this.state.multiPolygonGeofence) { this.props.onPendingChange() }
        this.setState({ multiPolygonGeofence: JSON.stringify(geofence) })
        this.setState({ geofence: geofence })
        console.log("Updated geofence")

        if (activeGeofence.length > 2 && this.state.zoom < 16) {
            this.setState({ position: this.getCenter() })
        }
        let editBtn = document.getElementById("editButton")
        if (editBtn) {
            editBtn.blur()
        }


      /*  let geofence = this.state.geofence
        let index = this.state.activeIndex
        let activeGeofence = this.state.activeGeofence
        console.log("DRAG-DEBUG: activeGeofence: " + JSON.stringify(activeGeofence))

        geofence[index] = activeGeofence
        if (JSON.stringify(geofence) != this.state.multiPolygonGeofence) {
            this.props.onPendingChange()
        }
        this.setState({multiPolygonGeofence: geofence})
        this.setState({geofence: geofence})
        if (activeGeofence.length > 2 && this.state.zoom < 16) {
            this.setState({position: this.getCenter()})
        }
        let editBtn = document.getElementById("editButton")
        if (editBtn) {
            editBtn.blur()
        }*/
    }

    handleZoom = (e: { target: { _zoom: any; }; }) => {
        if (!this.state.isUndoing && !this.state.isSubmitting && !this.state.isDeleting)
            this.setState({zoom: e.target._zoom})
    }

    handleClick = (e: { latlng: any; }) => {
        if (!this.state.isUndoing && !this.state.isSubmitting && !this.state.isDeleting && this.state.dragging == false) {
            let intersect = false
            let index = null
            for (let i = 0; i < this.state.geofence.length; i++) {
                intersect = this.isMarkerInsidePolygon(e.latlng, this.state.geofence[i])
                if (intersect) {
                    index = i
                    break
                }
            }
            if (!intersect) {
                this.handleCreate(e)
            } else {
                if (index) {
                    this.changeActiveGeofence(index)
                    this.setState({editMode: true})
                } else {
                    console.log("cannot change active geofence. index is null");
                }
            }
        }
    }

    isMarkerInsidePolygon(latlng: { lat: any; lng: any; }, poly: string | any[]) {
        let x = latlng.lat, y = latlng.lng

        let inside = false;
        for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
            let xi = poly[i].lat, yi = poly[i].lng
            let xj = poly[j].lat, yj = poly[j].lng

            let intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
            if (intersect) inside = !inside;
        }

        return inside;
    }

    handleCreate = (e: { latlng: any; }) => {
        this.state.editMode && e.latlng &&
        this.setState({
            editHistory: this.state.editHistory.concat({
                "type": "add",
                "item": this.state.activeGeofence.length,
                "original": null
            }),
            activeGeofence: this.state.activeGeofence.concat(e.latlng)
        }, this.updateGeofence)
    }

    handleMarkerDrag = (event: { target?: any; type?: string; }) => {
        let self = this
        let activeGeofence = this.state.activeGeofence
        const LatLng = event.target.getLatLng()
        const index = event.target.options.index
        const rest = activeGeofence.splice(index, 1, LatLng)
        this.setState({
            editHistory: this.state.editHistory.concat({ "type": "drag", "item": index, "original": rest[0] }),
            activeGeofence: activeGeofence,
        }, this.updateGeofence)
        setTimeout(function () { self.handleDragging(event) }, 10)


       /* let self = this
        let activeGeofence = this.state.activeGeofence
        const LatLng = event.target.getLatLng()
        const index = event.target.options.index-1
        console.log("DRAG-DEBUG: index: " + JSON.stringify(index))
        const rest = activeGeofence.splice(index, 1, LatLng)
        console.log("DRAG-DEBUG: rest: " + JSON.stringify(rest))
        const editHistory = this.state.editHistory.concat({"type": "drag", "item": index, "original": rest[0]})
        let newGeofences = this.state.geofence
        newGeofences[index] = activeGeofence
        console.log("DRAG-DEBUG: newGeofences: " + JSON.stringify(newGeofences))
        console.log("DRAG-DEBUG: new LatLng: " + JSON.stringify(LatLng))
        this.setState({
            editHistory: editHistory,
            activeGeofence: activeGeofence,
            geofence: newGeofences
        }, this.updateGeofence)
        setTimeout(function () {
            self.handleDragging(event)
        }, 100)*/
    }

    handleDragging = (event: { target?: any; type?: string }) => {
        if (event.type == "dragstart") {
            this.setState({dragging: true})
        } else {
            this.setState({dragging: false})
        }
    }

    handleDelete(i: number) {
        this.setState({isDeleting: true})
        let activeGeofence = this.state.activeGeofence
        let original = activeGeofence.splice(i, 1)
        this.setState({
            activeGeofence: activeGeofence,
            editHistory: this.state.editHistory.concat({"type": "delete", "item": i, "original": original[0]}),
        })
        this.setState({isDeleting: false}, this.updateGeofence)
        this.mapRef?.current?.leafletElement.closePopup()
        let markerDeleteButton = document.getElementById("markerDeleteButton")
        if (markerDeleteButton) {
            markerDeleteButton.blur()
        }
    }

    getCenter() {
        let geofence = this.state.activeGeofence
        let lat = 0
        let lng = 0
        geofence.forEach((element: { lat: number; lng: number; }) => {
            lat = lat + element.lat
            lng = lng + element.lng
        });
        lat = lat / geofence.length
        lng = lng / geofence.length
        return {"lat": lat, "lng": lng}
    }

    handleUndo() {
        this.setState({isUndoing: true})
        let editHistory = this.state.editHistory
        let geofence = this.state.geofence
        let activeGeofence = this.state.activeGeofence
        const latestEvent = editHistory[editHistory.length - 1]
        if (latestEvent) switch (latestEvent.type) {
            case "add":
                this.setState({
                    activeGeofence: activeGeofence.filter((i: any) => i !== latestEvent.item),
                })
                console.log("Removed most recent Marker")
                editHistory.splice(editHistory.length - 1, 1)
                break
            case "drag":
                activeGeofence.splice(latestEvent.item, 1, latestEvent.original)
                this.setState({activeGeofence: activeGeofence})
                console.log("Put back marker in its previous position")
                editHistory.splice(editHistory.length - 1, 1)
                break
            case "delete":
                activeGeofence.splice(latestEvent.item, 0, latestEvent.original)
                this.setState({activeGeofence: activeGeofence})
                console.log("Restored most recent Marker")
                editHistory.splice(editHistory.length - 1, 1)
                break
            case "addPolygon":
                this.setState({
                    geofence: geofence.filter((i: any) => i !== latestEvent.item),
                    activeGeofence: geofence[latestEvent.original],
                    activeIndex: latestEvent.original
                })
                console.log("Removed Geofence " + latestEvent.item + " and changed active Geofence back to " + latestEvent.original)
                editHistory.splice(editHistory.length - 1, 1)
                break
            case "deletePolygon":
                geofence.splice(latestEvent.item, latestEvent.options, latestEvent.original)
                this.setState({
                    activeIndex: latestEvent.item,
                    activeGeofence: latestEvent.original,
                })
                console.log("Restored deleted Geofence")
                editHistory.splice(editHistory.length - 1, 1)
                break
            case "changeActiveGeofence":
                this.setState({
                    activeIndex: latestEvent.original,
                    activeGeofence: geofence[latestEvent.original],
                })
                console.log("Changed active Geofence back from " + latestEvent.item + " to " + latestEvent.original)
                editHistory.splice(editHistory.length - 1, 1)
                break
            default:
                console.log("Unable to undo last event")
                break
        } else {
            console.log("There are no events that could be undone")
        }
        this.setState({isUndoing: false}, this.updateGeofence)
        let undoButton = document.getElementById("undoButton")
        if (undoButton) {
            undoButton.blur()
        }
    }

    handlePolygonDelete() {
        this.setState({isDeleting: true})
        let geofence = this.state.geofence
        let activeGeofence = this.state.activeGeofence
        let activeIndex = this.state.activeIndex
        if (activeGeofence.length !== 0 || activeIndex !== 0) {
            let original = geofence.splice(this.state.activeIndex, 1)
            if (!geofence.length) {
                this.setState({
                    editHistory: this.state.editHistory.concat({
                        "type": "deletePolygon",
                        "item": this.state.activeIndex,
                        "original": original[0],
                        "options": 1
                    }),
                    geofence: [],
                    activeGeofence: [],
                    activeIndex: 0
                })
            } else {
                this.setState({
                    editHistory: this.state.editHistory.concat({
                        "type": "deletePolygon",
                        "item": this.state.activeIndex,
                        "original": original[0],
                        "options": 0
                    }),
                    geofence: geofence,
                })
                if (activeIndex == 0) {
                    this.setState({
                        activeGeofence: geofence[0],
                        activeIndex: 0
                    })
                } else {
                    this.setState({
                        activeGeofence: geofence[activeIndex - 1],
                        activeIndex: activeIndex - 1
                    })
                }
            }
        } else {
            console.log("Cannot delete, since there is nothing to be deleted")
        }

        this.setState({isDeleting: false}, this.updateGeofence)
        let polygonDelete = document.getElementById("polygonDelete")
        if (polygonDelete) {
            polygonDelete.blur()
        }
    }

    async handleSubmitClick(): Promise<void> {
        this.setState({isSubmitting: true})
        console.log("Handle submit")
        let hasError = false
        for (let index = 0; index < this.state.geofence.length; index++) {
            if (this.state.geofence[index].length < 3) {
                hasError = true
                console.log("One of the Polygons is invalid")
            }
        }
        try {
            if (!hasError && this.state.multiPolygonGeofence) {
                let newGlobalGeofence: GlobalGeofence = {}
                newGlobalGeofence._id = this.props.mapping?._id
                newGlobalGeofence.resolvingGeofence = {}
                newGlobalGeofence.resolvingGeofence.geofence = JSON.stringify([this.state.geofence[0]])
                newGlobalGeofence.matchingAreaGeofences = this.state.geofence?.map((geoFence) => {
                    return {
                        geofence: JSON.stringify([geoFence])
                    }
                })
                newGlobalGeofence.name = this.state.name
                newGlobalGeofence.addressRegex = this.state.addressRegex

                await this.props.onSave(newGlobalGeofence)
                let onSaveLabel = document.getElementById("onSaveLabel");
                if (onSaveLabel) {
                    onSaveLabel.style.display = "inline"
                }
            } else {
                throw "Error in the polygons or no mapping available to save it to"
            }
        } catch (ex) {
            console.log(ex)
        }

        this.setState({isSubmitting: false})
        let saveButton = document.getElementById("saveButton")
        if (saveButton) {
            saveButton.blur()
        }
    }

    changeActiveGeofence(index: number) {
        if (this.state.activeGeofence.length >= 3) {
            if (index !== this.state.activeIndex) {
                if (index == this.state.geofence.length) {
                    this.setState({
                        activeGeofence: [],
                        editHistory: this.state.editHistory.concat({
                            "type": "addPolygon",
                            "item": index,
                            "original": this.state.activeIndex
                        })
                    })
                } else {
                    this.setState({
                        activeGeofence: this.state.geofence[index],
                        editHistory: this.state.editHistory.concat({
                            "type": "changeActiveGeofence",
                            "item": index,
                            "original": this.state.activeIndex
                        })
                    })
                }
                this.setState({
                    activeIndex: index,
                }, this.updateGeofence)
            } else {
                console.log("Cannot change active Geofence to already active Geofence")
            }
        } else {
            console.log("Cannot change active Geofence due to the currently active Geofence being invalid. Please create at least 3 Points.")
            let onChangeError = document.getElementById("onChangeError")
            if (onChangeError) {
                onChangeError.style.display = "block"
            }
            window.setTimeout(() => {
                if (onChangeError) {
                    onChangeError.style.display = "none"
                }
            }, 5000)
        }
        let addPolygonButton = document.getElementById("addPolygonButton")
        if (addPolygonButton) {
            addPolygonButton.blur()
        }
    }

    renderMultiPolygon() {


        console.log("Render MultiPolygon")


        let positions = []
        for (let index = 0; index < this.state.geofence.length; index++) {
            positions[index] = this.state.geofence[index]
        }
        return <Polygon color="purple" positions={positions} />

        // Having several Polygons with different colors leads to problems while updating (dragging)

       /*
        let positions = []
        for (let index = 0; index < this.state.geofence.length; index++) {
            positions[index] = this.state.geofence[index]
        }
        let active = positions[this.state.activeIndex]
        positions = positions.slice(0, this.state.activeIndex).concat(positions.slice(this.state.activeIndex + 1))
        console.log("DEBUG: positions - ", positions)
        console.log("DEBUG: active - ",  active)
        return <>
            <Polygon color="blue" positions={active}/>
            <Polygon color="purple" positions={positions}/>

        </>
*/

       /* let positions = []
        let active = null
        let currentIndex = 0
        this.state.geofence.forEach((geoFence) => {
            console.log("geoFence - ", geoFence)
            console.log("currentIndex - ", currentIndex)
            if (geoFence.length > 2) {
                if (currentIndex == this.state.activeIndex) {
                    active = geoFence
                } else {
                    positions.push(geoFence)
                }
            }
            currentIndex++

        })
*/
      /*
        if(this.state.geofence.length >= 1) {
            for (let index = 1; index < this.state.geofence.length; index++) {
                console.log("this.state.geofence[index] - ", this.state.geofence[index])
                if (this.state.geofence[index].length > 2) {
                    positions[index - 1] = this.state.geofence[index]
                }
            }
        }
        let first: any[] = this.state.geofence[0]
        console.log("first - ", first)
        return <>
            <Polygon color="blue" positions={active}/>
            <Polygon color="purple" positions={positions}/>
        </>*/
    }

    addMarker(markers: any[], latlng: Leaflet.LatLngExpression, i: number) {
        if ("lng" in latlng) {
            markers.push(
                <Marker
                    key={i}
                    index={i}
                    position={latlng}
                    draggable={this.state.editMode}
                    onDragstart={(e: any) => this.handleDragging(e)}
                    onDragend={this.handleMarkerDrag.bind(this)}
                >
                    <Popup
                        id="markerPopup"
                    >
                        <div
                            style={{fontSize: "15px", paddingBottom: "3px"}}
                        >
                            Eckpunkt {i + 1}
                        </div>
                        lat: {latlng.lat}
                        <br/>
                        lng: {latlng.lng}
                        <br/>
                        <br/>
                        <Button id="markerDeleteButton" color='red' onClick={() => this.handleDelete(i)} inverted
                                loading={this.state.isDeleting}
                                disabled={this.state.isUndoing || this.state.isSubmitting || this.state.isDeleting}>
                            <Icon name='delete'/> Eckpunkt löschen
                        </Button>
                    </Popup>
                </Marker>
            )
        }
        return markers
    }

    renderMarkers() {
        let markers = []
        for (let i = 0; i < this.state.activeGeofence.length; i++) {
            let latlng = this.state.activeGeofence[i]
            console.log("renderMarkers - latlng: ", latlng)
            console.log("renderMarkers - latlng json: ", JSON.stringify(latlng))

            markers = this.addMarker(markers, latlng, i)
        }
        console.log("renderMarkers - markers: ", markers)
        return markers
    }

    renderActiveGeofencePicker() {
        console.log("renderActiveGeofencePicker - this.state.geofence: ", this.state.geofence)
        let items = []
        let first = true
        for (let index = 0; index < this.state.geofence.length; index++) {
            items.push(
                <Button
                    key={index}
                    primary={first}
                    active={index == this.state.activeIndex}
                    disabled={this.state.isUndoing || this.state.isSubmitting || this.state.isDeleting}
                    onClick={() => this.changeActiveGeofence(index)}
                >
                    {index + 1}
                </Button>
            )
            first = false
        }
        if (items.length == 0) {
            items.push(
                <Button
                    primary
                    key={0}
                    active={true}
                    onClick={() => this.changeActiveGeofence(0)}
                >
                    1
                </Button>
            )
        }

        return <Button.Group style={{padding: "5px"}}>
            {items}
            <Button
                id="addPolygonButton"
                key="add"
                onClick={() => this.changeActiveGeofence(this.state.geofence.length)}
                disabled={this.state.isUndoing || this.state.isSubmitting || this.state.isDeleting}
            >
                <Icon name='add'/>
            </Button>
        </Button.Group>
    }

    render() {

        if (this.state.position == undefined) {
            this.setCoordinatesFromBackend()
        }

        return <Form error>
            <Form.Field>
                <Map
                    id="map"
                    center={this.state.position ?? this.state.defaultPosition}
                    zoom={this.state.zoom}
                    onclick={this.handleClick}
                    onzoomend={this.handleZoom}
                    ref={this.mapRef}
                    style={{height: "65vh"}}>
                    <TileLayer
                        url={TILE_SERVER_URL}
                        accessToken={TILE_SERVER_ACCESS_TOKEN}
                        id={TILE_STYLE}
                        attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                        tileSize={512}
                        zoomOffset={-1}
                    />
                    {this.renderMultiPolygon()}
                    {this.state.editMode && this.renderMarkers()}
                </Map>
            </Form.Field>

            {this.state.editMode &&
                <div style={{paddingBottom: "5px"}}>
                    {this.renderActiveGeofencePicker()}
                    <br/>
                    <Button id="polygonDelete" color='red' onClick={this.handlePolygonDelete.bind(this)} inverted
                            loading={this.state.isDeleting}
                            disabled={this.state.isUndoing || this.state.isSubmitting || this.state.isDeleting}>
                        <Icon name='delete'/> Geofence löschen
                    </Button>
                    <Button id="undoButton" floated="right" color='red' onClick={this.handleUndo.bind(this)} inverted
                            loading={this.state.isUndoing}
                            disabled={this.state.isUndoing || this.state.isSubmitting || this.state.isDeleting}>
                        <Icon name='undo'/>Rückgängig machen
                    </Button>
                </div>
            }

            <Label id="onChangeError" style={{display: "none"}}>

                Bitte kreieren sie mindestens 3 Eckpunkte für den aktiven Geofence bevor sie zu einem anderen wechseln.

            </Label>
            <br/>
            <Label>Name</Label>
            <Input
                type="text"
                disabled={!this.state.editMode}
                onChange={(event) => this.setState({name: event.target.value})}
                value={this.state.name}
            />
            <br/>
            <Label>Address-Regex</Label>
            <Input
                type="text"
                disabled={!this.state.editMode}
                onChange={(event) => this.setState({addressRegex: event.target.value})}
                value={this.state.addressRegex}
            />
            <br/>
            <Button
                id="saveButton"
                color='green'
                onClick={() => this.handleSubmitClick()}
                inverted
                loading={this.state.isSubmitting}
                disabled={this.state.isUndoing || this.state.isSubmitting || this.state.isDeleting || this.state.name == ""}
            >
                <Icon name='checkmark'/> {this.props.saveButtonText || "Speichern"}
            </Button>
            <Button
                id="editButton"
                floated="right"
                color='orange'
                onClick={() => this.setState({editMode: !this.state.editMode}, this.updateGeofence)}
                inverted
                loading={this.state.isUndoing}
                disabled={this.state.isUndoing || this.state.isSubmitting || this.state.isDeleting}
            >
                <Icon name='edit'/> Bearbeitungsmodus
            </Button>
            <Button color='grey' id="addressMappingGeoFence_button_cancel" onClick={() => this.props.onClose()}
                    loading={this.state.isSubmitting} disabled={this.state.isSubmitting}>
                <Icon name='x'/> "Abbrechen"
            </Button>
        </Form>
    }
}