import {combineReducers, configureStore} from '@reduxjs/toolkit'
import tableReducer from './slices/tableSlice'
import rulesReducer from './slices/ruleSlice'
import rightMenuReducer from './slices/rightMenuSlice'
import sourceCodeReducer from './slices/sourceCodeSlice'
import {ApplyRuleMiddleware} from "./middleware";

const rootReducer = combineReducers({table:tableReducer, rules:rulesReducer,rightMenu:rightMenuReducer, sourceCode: sourceCodeReducer})
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ApplyRuleMiddleware)
})


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch