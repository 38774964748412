import * as React from "react";
import {Button, Divider, Grid, Icon, Message, Modal, Popup, TextArea} from 'semantic-ui-react';
import {Address, ContactInfo, ParseTextRequest} from "../../../../generated";
import {authentication} from "../../../../authentication";
import {backend} from "../../../../xconvert-backend";
import {IfBox} from "../../../style/if";
import {AddressEditForm} from "./address_edit_form";

export interface CreateCompanyModalProps {
    onClose: () => (void)
    open: boolean
}
export interface CreateCompanyModalState {
    submitCounter: number
    shouldShowSuccessMessage: boolean
    loading: boolean
}

export class CreateCompanyModal extends React.Component<CreateCompanyModalProps, CreateCompanyModalState> {
    constructor(props: CreateCompanyModalProps) {
        super(props)
        this.state = {
            submitCounter: 0,
            shouldShowSuccessMessage: false,
            loading: false,
        }
    }

    onAddressSave = async (a: Address, c: ContactInfo) => {
        this.setState({loading: true})
        let authHeader = backend.withTokenAuthHeader(authentication.token)
        await backend.internalApi.createCompany({name: a.name, address: a}, authHeader)
        this.setState({
            shouldShowSuccessMessage: true,
            submitCounter: this.state.submitCounter + 1,
        })
        setTimeout(() => {
            this.setState({shouldShowSuccessMessage: false})
            this.props.onClose()
        }, 2000)
    }

    render() {
        return <Modal closeOnDimmerClick={false} closeIcon size="large" open={this.props.open} onClose={this.props.onClose}>
            <Modal.Header>Neue Firma erstellen</Modal.Header>
            <Modal.Content>
                <IfBox shouldShow={this.state.shouldShowSuccessMessage}>
                    <Message success header='Aktion erfolgreich abgeschlossen'
                             content='Eine neue Firma wurde erfolgreich erstellt'/>
                </IfBox>

                <AddressEditForm onSave={this.onAddressSave} key={this.state.submitCounter} />
            </Modal.Content>
        </Modal>
    }


}