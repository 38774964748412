import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Dimmer, Divider, Form, Icon, Input, Loader, Message, Segment } from 'semantic-ui-react';
import { authentication } from '../../authentication';
import Box from "../style/box";
import {SecondFactorAuth} from "./SecondFactorAuth";


export class LoginPage extends React.Component<any, any> {

    constructor(props) {
        super(props)

        this.state = {
            username: "",
            password: "",
            isLoading: false,
            hasFailed: false
        }
    }

    handleInputChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        this.setState({
            [name]: value
        });
    }

    async performLogin(totp: String | null): Promise<void> {
        this.setState({ "isLoading": true })

        let result = await authentication.doAuthenticate(this.state.username, this.state.password, totp)

        this.setState({ "isLoading": false })

        if (!result.successful) {
            console.log("Login has failed")
            this.setState({ "hasFailed": true })
            return
        }

        console.info("Auth successful")
        const next = this.props.location.search
        if (next !== null) {
            console.log("Goto next page", next)
            this.props.history.push(next.substr(1))
        }
        else {

            console.log("Goto start page")
            this.props.history.push("/")
        }
    }

    doRenderHasFailedMessage() {
        if (this.state.hasFailed)
            return (<Message attached='bottom' error>
                <Icon name='exclamation triangle' />
                Anmeldung fehlgeschlagen. Eingaben falsch oder kein SuperUser oder CompanyAdmin.
            </Message>)
        return (<div></div>)
    }

    render() {
        return <Box showLogo>
            <Form>
                <Segment stacked>
                    <Form.Field>
                        <Input id='mail' icon='user' placeholder='E-Mail Addresse' iconPosition='left' name="username" value={this.state["username"]} onChange={evt => this.handleInputChange(evt)} />
                    </Form.Field>
                    <Form.Field>
                        <Input icon='lock' type="password" placeholder="Password" iconPosition='left' name="password" value={this.state["password"]} onChange={evt => this.handleInputChange(evt)} />
                    </Form.Field>

                    <Button color="teal" onClick={async evt => {
                        try {
                            await this.performLogin(null)
                        } catch (e: any) {
                            console.log("Authentication failed: ", e)
                            if (e.status == 420) {
                                this.setState({showTotpInput: true, isLoading: false})
                            } else {
                                throw e
                            }
                        }
                    }}>Anmelden</Button>

                    <Dimmer active={this.state.isLoading} inverted>
                        <Loader>Anmelden</Loader>
                    </Dimmer>

                </Segment>
            </Form>
            {this.state.showTotpInput && <SecondFactorAuth validateTotp={(totp: string) => this.performLogin(totp)}/>}
            {this.doRenderHasFailedMessage()}

            <div className="ui message">
                Sie haben noch keinen Account? <Link to="/account/register">Registrieren</Link><br />
            </div>

        </Box>
    }
}

export class LogoutPage extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        console.log("Logout component did mount")
        authentication.logout()

        setTimeout((ev) => {
            if (location.pathname == "/account/logout") {
                this.props.history.push("/account/login")
            }
        }, 3000)
    }

    render() {

        return <Box showLogo>
            <Segment stacked>
                <h2>Abgemeldet</h2>
                <p>
                    Sie wurden erfolgreich abgemeldet.
                </p>
                <Divider hidden />
                <p>
                    Sie werden in 3 Sekunden zur Anmeldeseite weitergeleitet.
                    Weiter zum <Link to="/account/login">Anmelden</Link>
                </p>
            </Segment>
        </Box>
    }
}