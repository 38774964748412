import * as React from "react";
import {Header, Modal} from "semantic-ui-react";
import {Company, SystemStatusMessage} from "../../../../../generated";
import {isActive} from "../../../../../format";
import SystemStatusMessageEditTab from "./SystemStatusMessageEditTab";

export interface SystemStatusMessageModalProps {
    isOpen: boolean
    onClose: () => void
    message: SystemStatusMessage
    onlyOwnCompany: boolean
}

export interface SystemStatusMessageModalState {
    endTime: Date | null
    message: string
    selectedCompanies: Company[]
    readOnly: boolean
    title: string
}

export class SystemStatusMessageModal extends React.Component<SystemStatusMessageModalProps, SystemStatusMessageModalState> {

    constructor(props) {
        super(props)
        this.state = {
            endTime: null,
            message: "",
            selectedCompanies: [],
            readOnly: false,
            title: null
        }
    }

    handleClose = () => {

        this.props.onClose()
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon={isActive(this.props.message.startTime, this.props.message.endTime)}
                    content={this.props.message.message.title}/>
            <Modal.Content>
                <SystemStatusMessageEditTab isOpen={this.props.isOpen} onClose={this.props.onClose} message={this.props.message} onlyOwnCompany={this.props.onlyOwnCompany} />

            </Modal.Content>
        </Modal>

    }

}

