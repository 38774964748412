import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button} from "semantic-ui-react";
import AceEditor from 'react-ace';
import {IfBox} from "../../../style/if";
import {ExportSimFortrasDocTable} from "../converter/ExportSim/output/ExportSimFortrasDocTable";
import {ConfigContext} from "../../context/ConfigContext";
import {EncodingSelector} from "./EncodingSelector";

export interface FortrasDocViewerProps {
    output: string
}

export function FortrasDocViewer(props: React.PropsWithChildren<FortrasDocViewerProps>) {
    const context = useContext(ConfigContext);
    const [fortrasDoc, setFortrasDoc] = useState("");
    const [drawTable, setDrawTable] = useState(false);
    const [encoding, setEncoding] = useState('ISO-8859-1');

    useEffect(() => {
        if (props.output) {
            context.setFortrasDoc(props.output);
        }
        setFortrasDoc(context.fortrasDoc);
    }, [props.output, context]);

    const handleDrawTable = () => {
        setDrawTable(true);
    };


    return (
        <>
            <AceEditor
                theme="monokai"
                mode='groovy'
                style={{flex: 2}}
                value={fortrasDoc}
                placeholder='copy your Fortras-Document here!'
                width="100%"
                onChange={(value) => {
                    setFortrasDoc(value);
                    setDrawTable(false);
                    context.setFortrasDoc(value);
                }}
                readOnly={false}
            />
            <div>
                <EncodingSelector
                    encoding={encoding}
                    onChange={(encoding) => {
                        setDrawTable(false);
                        setEncoding(encoding)
                    }}
                />
                <Button type="button" onClick={handleDrawTable}>Draw Table</Button>
            </div>
            <div style={{flex: 2}}>
                <IfBox shouldShow={drawTable}>
                    <ExportSimFortrasDocTable output={fortrasDoc} encoding={encoding}/>
                </IfBox>
            </div>
        </>
    );
}