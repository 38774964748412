import * as moment from "moment"
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import {
    DoubleWithUnit,
    Freight,
    FreightStatus,
    StopAction,
    StopActionStatus,
    StopLocationStatus,
    TransportOrderStatus,
    TransportOrder,
    Vehicle, TelematicState
} from "./generated/api";
import { Icon } from 'semantic-ui-react';

export function prettyRelativeDate(time) {
    let timestamp = moment(time)

    if (timestamp.isSame(moment(), 'day')) {
        return `heute ${timestamp.format("HH:mm")}`
    }
    if (timestamp.isSame(moment().subtract(1, 'day'), 'day')) {
        return `gestern ${timestamp.format("HH:mm")}`
    }
    return timestamp.format("D.MM.YY HH:mm")
}

/*
export function prettyRelativeDate(time) {


    moment().diff(SpecialTo, 'days')
    var date = new Date((time || "")),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        day_diff = Math.floor(diff / 86400);

    if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) return;

    return day_diff == 0 && (
        diff < 60 && "gerade eben" || diff < 120 && "vor einer Minute" || diff < 3600 && "vor " + Math.floor(diff / 60) + " Minuten" || diff < 7200 && "vor einer Stunde" || diff < 86400 && "vor " + Math.floor(diff / 3600) + " Stunden") || day_diff == 1 && "Gestern" || day_diff < 7 && "vor " + day_diff + " Tagen" || day_diff < 31 && "vor " + Math.ceil(day_diff / 7) + " Wochen";
}*/


export function formatStopActionStatus(s: StopActionStatus.StatusCodeEnum) {
    switch (s) {
        case StopActionStatus.StatusCodeEnum.DONE:
            return "abgeschlossen"
        case StopActionStatus.StatusCodeEnum.INPROGRESS:
            return "in Bearbeitung"
        case StopActionStatus.StatusCodeEnum.PLANNED:
            return "geplant"
        case StopActionStatus.StatusCodeEnum.CANCELED:
            return "unbekannt"
    }
}


/*
EpalEurPallet = 'epalEurPallet',
        Epal2Pallet = 'epal2Pallet',
        Epal3Pallet = 'epal3Pallet',
        Epal6HalfPallet = 'epal6HalfPallet',
        Epal7HalfPallet = 'epal7HalfPallet',
        EpalBoxPallet = 'epalBoxPallet',
        ChepEuroPallet = 'chepEuroPallet',
        ChepHalfPallet = 'chepHalfPallet',
        ChepIndustrialPallet = 'chepIndustrialPallet',
        ChepPlasticEurPallet = 'chepPlasticEurPallet',
        ChepPlasticQuarterPallet = 'chepPlasticQuarterPallet',
        Pallet = 'pallet',
        PalletCollar = 'palletCollar',
        PaperSack = 'paperSack',
        InflatableBag = 'inflatableBag',
        Container20ft = 'container20ft',
        Container40ft = 'container40ft',
        Container45ft = 'container45ft',
        Container48ft = 'container48ft',
        Container53ft = 'container53ft',
        IbcContainer = 'ibcContainer',
        IntermediateBulkContainer = 'intermediateBulkContainer',
        IfcoTray = 'ifcoTray',
        SlipSheet = 'slipSheet',
        CagePallet = 'cagePallet',
        RollContainer = 'rollContainer',
        Bag = 'bag',
        Piece = 'piece',
        Other = 'other'
        */

export function formatContainmentType(containmentType: Freight.ContainmentTypeEnum): String {
    let containmentTypes = [
        { key: 'epalEurPallet', text: 'Europalette', value: 'epalEurPallet' },
        { key: 'epal2Pallet', text: 'EPAL EUR2 Palette', value: 'epal2Pallet' },
        { key: 'epal3Pallet', text: 'EPAL EUR3 Palette', value: 'epal3Pallet' },
        { key: 'epal6HalfPallet', text: 'EPAL EUR6 Halbpalette', value: 'epal6HalfPallet' },
        { key: 'epal7HalfPallet', text: 'EPAL EUR7 Halbpalette', value: 'epal7HalfPallet' },
        { key: 'epalBoxPallet', text: 'EPAL Box', value: 'epalBoxPallet' },
        { key: 'chepEuroPallet', text: 'CHEP EUR Palette', value: 'chepEuroPallet' },
        { key: 'chepHalfPallet', text: 'CHEP Halbpalette', value: 'chepHalfPallet' },
        { key: 'chepIndustrialPallet', text: 'CHEP Industriepalette', value: 'chepIndustrialPallet' },
        { key: 'chepPlasticEurPallet', text: 'CHEP Kunststoff Industrieplatte', value: 'chepPlasticEurPallet' },
        { key: 'chepPlasticQuarterPallet', text: 'CHEP Viertelpalette', value: 'chepPlasticQuarterPallet' },
        { key: 'pallet', text: 'Palette', value: 'pallet' },
        { key: 'palletCollar', text: 'Palette mit Aufsatzrahmen', value: 'palletCollar' },
        { key: 'paperSack', text: 'Papiersack', value: 'paperSack' },
        { key: 'inflatableBag', text: 'Falttasche', value: 'inflatableBag' },
        { key: 'container20ft', text: 'Container 20ft', value: 'container20ft' },
        { key: 'container40ft', text: 'Container 40ft', value: 'container40ft' },
        { key: 'container45ft', text: 'Container 45ft', value: 'container45ft' },
        { key: 'container48ft', text: 'Container 48ft', value: 'container48ft' },
        { key: 'container53ft', text: 'Container 53ft', value: 'container53ft' },
        { key: 'ibcContainer', text: 'Intermediate Bulk Container', value: 'ibcContainer' },
        { key: 'intermediateBulkContainer', text: 'Intermediate Bulk Container', value: 'intermediateBulkContainer' },
        { key: 'ifcoTray', text: 'Fruchtcontainer', value: 'ifcoTray' },
        { key: 'slipSheet', text: 'Slip Sheet', value: 'slipSheet' },
        { key: 'cagePallet', text: 'Gitterbox', value: 'cagePallet' },
        { key: 'rollContainer', text: 'Rollcontainer', value: 'rollContainer' },
        { key: 'bag', text: 'Tasche', value: 'bag' },
        { key: 'piece', text: 'Stück', value: 'piece' },
        { key: 'other', text: 'Sonstiges', value: 'other' },
    ]
    let type = containmentTypes.filter(function (ct) { return ct.key == (containmentType || "").toString() })

    console.log(type)

    if (type.length > 0) {
        return type[0].text
    }
    return (containmentType || "").toString()
}

export function formatContainment(f: Freight) {
    let result = ""
    if (f.containmentQuantity) {
        result += f.containmentQuantity + "x"
    }
    if (f.containmentName) {
        result += f.containmentName
    } else {
        result += formatContainmentType(f.containmentType)
    }
    return result
}

export function getURLs(text: String) {
    let findings = text.match(/((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi)
    let texts = []
    if (findings) {
        let startIndex = 0
        for (let i = 0; i < findings.length; i++) {
            const element = findings[i];

            let index = text.indexOf(element, startIndex)
            let firstText = text.substring(startIndex, index)
            let endIndex = text.indexOf(findings[i + 1], index + 1)
            let secondText = ""
            if (endIndex != -1) {
                secondText = text.substring(index + element.length, endIndex)
            } else {
                secondText = text.substring(index + element.length)
            }
            startIndex = endIndex

            texts.push(firstText)
            texts.push(<a key={uuid()} target="_blank" href={element}> {element} </a>)
            texts.push(secondText)
        }
    }
    return texts
}

export function formatFreightStatusEnum(f: FreightStatus.StatusCodeEnum) {
    switch (f) {
        case FreightStatus.StatusCodeEnum.DELIVERED:
            return "geliefert"
        case FreightStatus.StatusCodeEnum.LOADED:
            return "geladen"
        case FreightStatus.StatusCodeEnum.LOADING:
            return "wird geladen"
        case FreightStatus.StatusCodeEnum.NOTLOADED:
            return "nicht geladen"
        case FreightStatus.StatusCodeEnum.PLANNED:
            return "geplant"
        case FreightStatus.StatusCodeEnum.REJECTED:
            return "abgelehnt"
        case FreightStatus.StatusCodeEnum.UNLOADING:
            return "wird entladen"
        default:
            return "unbekannt"
    }
}

export function formatFreightStatus(f: FreightStatus) {
    if (f) {
        return formatFreightStatusEnum(f.statusCode)
    } else {
        return ""
    }
}

export function formatStopLocationStatusEnum(sc: StopLocationStatus.StatusCodeEnum) {
    switch (sc) {
        case StopLocationStatus.StatusCodeEnum.APPROACHING: return "wird angefahren"
        case StopLocationStatus.StatusCodeEnum.ARRIVED: return " angekommen"
        case StopLocationStatus.StatusCodeEnum.DEPARTED: return "Haltestelle verlassen"
        case StopLocationStatus.StatusCodeEnum.PLANNED: return "geplant"
        case StopLocationStatus.StatusCodeEnum.CANCELED: return "storniert"
        default:
            return "unbekannt"
    }
}

export function formatStopLocationStatus(sc: StopLocationStatus) {
    if (sc) {
        return formatStopLocationStatusEnum(sc.statusCode)
    } else {
        return ""
    }
}

export function formatTransportOrderStatusEnum(t: TransportOrderStatus.StatusCodeEnum) {
    switch (t) {
        case TransportOrderStatus.StatusCodeEnum.ACCEPTED:
            return "Akzeptiert"
        case TransportOrderStatus.StatusCodeEnum.ACKNOWLEDGING:
            return "Warte auf Bestätigung"
        case TransportOrderStatus.StatusCodeEnum.CANCELED:
            return "Storniert"
        case TransportOrderStatus.StatusCodeEnum.CREATED:
            return "Neu"
        case TransportOrderStatus.StatusCodeEnum.DISPATCHING:
            return "Warte auf Zuweisung"
        case TransportOrderStatus.StatusCodeEnum.DONE:
            return "Fertig"
        case TransportOrderStatus.StatusCodeEnum.EXECUTING:
            return "Wird ausgeführt"
        case TransportOrderStatus.StatusCodeEnum.REJECTED:
            return "Abgelehnt"
        default:
            return "Unbekannt"
    }
}

export function formatTransportOrderStatus(t: TransportOrderStatus) {
    if (t) {
        return formatTransportOrderStatusEnum(t.statusCode)
    } else {
        return ""
    }
}

export function getFormattedActionKindStatusEnumByIndex(actions: StopAction[], index: number) {
    if (actions) {
        return formatActionKindStatus(actions[index])
    }
    return "Unbekannt"
}

export function formatActionKindStatusEnum(kind: StopAction.KindEnum) {
    switch (kind) {
        case StopAction.KindEnum.ENTERFERRY: return "Fahren auf Fähre"
        case StopAction.KindEnum.ENTERTRAIN: return "Fahren auf Zug"
        case StopAction.KindEnum.LEAVEFERRY: return "Verlasse Fähre"
        case StopAction.KindEnum.LEAVETRAIN: return "Verlasse Zug"
        case StopAction.KindEnum.LOAD: return "Beladen"
        case StopAction.KindEnum.UNLOAD: return "Entladen"
        case StopAction.KindEnum.OTHER: return "sonstiges"
        default:
            return "Unbekannt"
    }
}

export function formatActionKindStatus(a: StopAction) {
    if (a) {
        return formatActionKindStatusEnum(a.kind)
    } else {
        return "Unbekannt"
    }
}


export function formatDoubleWithUnit(value: DoubleWithUnit | null, defaultUnit: DoubleWithUnit.UnitEnum): string {
    if (value && value.value) {
        let unit = value.unit || defaultUnit
        return `${value.value.toFixed(2)} ${unit}`
    }
    return ""
}

export function isActive(start: Date | null, end: Date | null) {
    const startN = Number(Date.parse(start.toString()))
    const endN = end ? Number(Date.parse(end.toString())) : null
    const nowN = Number(new Date())

    // when startTime is in future:
    //      endTime is behind startTime or null => yellow (upcoming)
    //      endTime is before startTime => red (will never be seen)

    // when startTime is in the past:
    //      endTime is in the future or null => green (currently shown)
    //      endTime is in the past => red (old/outdated)

    if (startN > nowN) {
        if (endN == null || endN > startN) {
            return <Icon name='ellipsis horizontal' color='yellow' />
        } else {
            return <Icon name='x' color='red' />
        }
    } else {
        if (endN == null || endN > nowN) {
            return <Icon name='check' color='green' />
        } else {
            return <Icon name='x' color='red' />
        }
    }
}

export function formatDate(date: Date | null): string {
    if (date) {
        return new Date(date).toLocaleString()
    }
    return ""
}

export function formatDateNoTime(date: Date | null): string {
    if (date) {
        return date.getDate() + "." + (date.getMonth()+1) + "." + date.getFullYear()
    }
    return ""
}

export function formatDateFromStringNoTime(dateStr: string) {
    let date = new Date(dateStr)

    return date.getDate() + "." + (date.getMonth()+1) + "." + date.getFullYear()

}

export function getFreightStatusEnumFromString(statusString: string | undefined): FreightStatus.StatusCodeEnum | null {
    switch (statusString) {
        case "DELIVERED":
            return FreightStatus.StatusCodeEnum.DELIVERED
        case "LOADED":
            return FreightStatus.StatusCodeEnum.LOADED
        case "LOADING":
            return FreightStatus.StatusCodeEnum.LOADING
        case "NOTLOADED":
            return FreightStatus.StatusCodeEnum.NOTLOADED
        case "NOT_LOADED":
            return FreightStatus.StatusCodeEnum.NOTLOADED
        case "PLANNED":
            return FreightStatus.StatusCodeEnum.PLANNED
        case "REJECTED":
            return FreightStatus.StatusCodeEnum.REJECTED
        case "UNLOADING":
            return FreightStatus.StatusCodeEnum.UNLOADING
        case "PICKED":
            return FreightStatus.StatusCodeEnum.PICKED
        default:
            return null
    }
}

export function formatVehicleTypeEnum(v: Vehicle.VehicleTypeEnum | undefined) {
    switch (v) {
        case Vehicle.VehicleTypeEnum.TRAILER: return "Anhänger"
        case Vehicle.VehicleTypeEnum.CAR: return "Auto"
        case Vehicle.VehicleTypeEnum.TRANSPORTER: return "Transporter"
        case Vehicle.VehicleTypeEnum.TRUCK75T: return "LKW 75T"
        case Vehicle.VehicleTypeEnum.TRUCK1199T: return "LKW 1199T"
        case Vehicle.VehicleTypeEnum.TRUCK18T: return "LKW 18T"
        case Vehicle.VehicleTypeEnum.TRUCK40T: return "LKW 40T"
        case Vehicle.VehicleTypeEnum.TRUCKTRAILER: return "LKW Anhänger"
        case Vehicle.VehicleTypeEnum.TANKERTRUCK: return "Tanklaster"
        default: return "Unbekannt"
    }
}

export function getTransportOrderStatusEnumFromString(s: string | undefined): TransportOrderStatus.StatusCodeEnum | undefined {
    switch (s) {
        case "ACCEPTED":
            return TransportOrderStatus.StatusCodeEnum.ACCEPTED
        case "ACKNOWLEDGING":
            return TransportOrderStatus.StatusCodeEnum.ACKNOWLEDGING
        case "CANCELED":
            return TransportOrderStatus.StatusCodeEnum.CANCELED
        case "CREATED":
            return TransportOrderStatus.StatusCodeEnum.CREATED
        case "DISPATCHING":
            return TransportOrderStatus.StatusCodeEnum.DISPATCHING
        case "DONE":
            return TransportOrderStatus.StatusCodeEnum.DONE
        case "EXECUTING":
            return TransportOrderStatus.StatusCodeEnum.EXECUTING
        case "REJECTED":
            return TransportOrderStatus.StatusCodeEnum.REJECTED
        case "INPUTMISSING":
            return TransportOrderStatus.StatusCodeEnum.INPUTMISSING
        default:
            return undefined
    }
}


export function formatTransportOrderTelematicStatus(t: TransportOrderStatus | undefined) {
    if (t?.telematicState?.statusCode) {
        return formatTransportOrderTelematicStatusEnum(t.telematicState.statusCode)
    } else {
        return ""
    }
}

export function formatTransportOrderTelematicStatusEnum(t: TelematicState.StatusCodeEnum) {
    switch (t) {
        case TelematicState.StatusCodeEnum.ARRIVEDONDEVICE:return "Empfangen"
        case TelematicState.StatusCodeEnum.SENT:return "Gesendet"
        case TelematicState.StatusCodeEnum.CANCELED:return "Abgebrochen"
        case TelematicState.StatusCodeEnum.READBYDRIVER:return "Gelesen"
        case TelematicState.StatusCodeEnum.MODIFIED:return "Verändert"
    }
}

export function getStopLocationStatusEnumFromString(statusString: string | undefined) {
    switch (statusString) {
        case "APPROACHING":
            return StopLocationStatus.StatusCodeEnum.APPROACHING
        case "ARRIVED":
            return StopLocationStatus.StatusCodeEnum.ARRIVED
        case "DEPARTED":
            return StopLocationStatus.StatusCodeEnum.DEPARTED
        case "PLANNED":
            return StopLocationStatus.StatusCodeEnum.PLANNED
        case "ADVISED":
            return StopLocationStatus.StatusCodeEnum.ADVISED
        case "CANCELED":
            return StopLocationStatus.StatusCodeEnum.CANCELED
        default:
            return undefined
    }
}