import * as React from "react";
import {Button, Header, Input, Modal} from "semantic-ui-react";
import {parseDiff} from 'react-diff-view';
import {backend} from "../../../../../../xconvert-backend";
import {authentication} from "../../../../../../authentication";
import {SaveConfigAsDraftRequest} from "../../../../../../generated";
import {diffLines, formatLines} from 'unidiff';
import {ConfigContext} from "../../../../context/ConfigContext";
import {CustomDiff} from "../diff/CustomDiff";

export interface SaveAsDraftModalProps {
    isOpen: boolean
    onClose: () => void
    oldConfig: string
    draft: string
    dispatch: () => void
}

export class SaveAsDraftModal extends React.Component<SaveAsDraftModalProps, any> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            comment: "",
            response: null,
            hunks: null,
            type: null,
            draftName: "",
        }
    }

    handleClose = () => {
        this.props.onClose()
    }

    async save() {
        this.setState({ isSubmitting: true })
        try {
            console.log("save config as draft in backend")
            let auth = backend.withTokenAuthHeader(authentication.token)
            let request = {} as SaveConfigAsDraftRequest
            request.config = JSON.parse(this.props.draft)
            request.companyId = this.context.companyId
            request.draftName = this.state.draftName
            request.oldConfig = JSON.parse(this.props.oldConfig)

            let response = await backend.internalApi.saveConfigAsDraft(request, auth)
            console.log("backend responded with: ", response)
            this.setState({ response: response })

            this.props.dispatch()
            this.handleClose()
        } catch (ex) {
            console.log(ex);
        }
        this.setState({ isSubmitting: false })
    }

    componentDidMount() {
        if (this.state.hunks == null) {
            let oldConfig = this.props.oldConfig
            let newConfig = this.props.draft

            const diffText = formatLines(diffLines(oldConfig, newConfig), { context: 3 });
            const [diff] = parseDiff(diffText, { nearbySequences: 'zip' });

            this.setState({ hunks: diff.hunks });
        }
    }

    render() {
        return <Modal
            open={this.props.isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='fullscreen'>
            <Header icon='browser' content='Save config as Draft' />
            <Modal.Content>

                <CustomDiff
                    oldJson={this.props.oldConfig}
                    newJson={this.props.draft}
                />

                <h3>give your draft a name:</h3>
                <Input style={{ width: 220 }} placeholder='DraftName'
                    value={this.state.draftName}
                    onChange={(evt) => this.setState({ draftName: evt.target.value })}
                />
                <br />
                <Button id='SaveAsDraftModalSaveButton' primary loading={this.state.isSubmitting} onClick={() => { this.save(); this.handleClose }}>Save</Button>
                <Button id='SaveAsDraftModalCancelButton' onClick={() => this.handleClose()}>Cancel</Button>

            </Modal.Content>
        </Modal>

    }

}

