import * as React from "react";

export function useKeyDownDetector(checkKey:string) {
    const [isKeyDown, setKeyDown] = React.useState(false);
    function downHandler(evt) {
        if (evt.key === checkKey) {
            setKeyDown(true);
            evt.preventDefault()

            console.log("Key down")
        }
    }

    function upHandler(evt) {
        if (evt.key === checkKey) {
            setKeyDown(false);
            evt.preventDefault()

            console.log("Key up")
        }
    }

    React.useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, []);

    return isKeyDown;
}