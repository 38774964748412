import { Freight, Packet, PacketItem, StopAction, StopLocation, Transport, TransportOrder } from "./generated/api";
import * as moment from "moment";
import { User } from "@sentry/types";
import {Moment} from "moment";
import {DropdownItemProps} from "semantic-ui-react";
import {Class} from "leaflet";
moment.locale('de');

export function getNextWorkingDay(): Moment {
    const today: Moment = moment();
    if (today.day() == (5 || 6)) {
        // friday, show monday then set to monday
        return today.add(1, "week").weekday(0);
    } else {
        return today.add(1, 'days');
    }
}

/**
 *  1. Send promise after timeout -> set filter
 *  2. Insert and remove from list
 *  3. set filter only of promise is fulfilled
 */
export class DebounceInput {
    // list with search queries
    searchQueries: SearchQuery[] = []
    counter: number = 0
    timeOutMillis: number = 1000
    constructor(timeOutMillis?: number) {
        if(timeOutMillis) this.timeOutMillis = timeOutMillis
    }

    newInput(input: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let currentCounter = this.counter

            this.searchQueries.push(new SearchQuery(currentCounter, input))
            this.counter = currentCounter + 1

            setTimeout( () => {

                if (this.searchQueries.length > 1) {
                    this.searchQueries = this.searchQueries.filter(e => e.id != currentCounter);
                    reject(new Error('there are more queries'))
                }
                else if (this.searchQueries.length == 0) {
                    reject(new Error('no queries pending'))
                }
                else {
                    this.searchQueries = []
                    resolve(input);
                }
            }, this.timeOutMillis);
        });

    }

    clearQueries() {
        this.searchQueries = []
    }
}

export class SearchQuery {
    id: number
    query: string
    constructor(id: number, query: string) {
        this.id = id
        this.query = query
    }
}

export function fmtDatetime(dtString: string | Date) {
    return moment(dtString).format("DD.MM.YY HH:mm")
}

export function delay(milliseconds: number, count: number): Promise<number> {
    return new Promise<number>(resolve => {
        setTimeout(() => {
            resolve(count);
        }, milliseconds);
    });
}

export function applyPatchFunc(to: TransportOrder, element: any, patchFunc: (p: any) => any) {
    return TransportOrderExtensions.applyPatch(to, element, patchFunc)
}

export function removeTransportOrderElement(to: TransportOrder, element: any) {
    const patchFunc = (current) => { return null }
    return TransportOrderExtensions.applyPatch(to, element, patchFunc);
}

export function findUserById(users: User[], id: string) {
    let user = users.find(function (ele) {
        return ele._id == id
    })
    return user
}

export function mergeAndOrderStops(stopLocations: StopLocation[]) {
    //console.log("STOPLIST INPUT", stopLocations);

    let stops: StopLocation[]
    //console.log("sorting stops");
    stops = stopLocations.sort((a: StopLocation, b: StopLocation) => {
        if (a.mergeAndOrderKey != undefined && b.mergeAndOrderKey != undefined) {
            return a.mergeAndOrderKey - b.mergeAndOrderKey
        } else if (a.planArrive && b.planArrive) {
            //console.log(a.mergeAndOrderKey + ", " + b.mergeAndOrderKey)
            return moment(a.planArrive).isBefore(moment(b.planArrive)) ? -1 : moment(a.planArrive).isAfter(b.planArrive) ? 1 : 0;
        }
        return 0
    })
    //console.log(stops.map(sl => {
    //    return sl.city
    //}))
    if (stops.every(item => {
        return item.mergeAndOrderKey != null
    })) {
        //console.log("All stops have a mergeAndOrderKey -> merging");

        stops = [...new Map(stops.map(stop =>
            [stop.mergeAndOrderKey, stop])).values()]
    }

    //console.log("STOPLIST OUTPUT", stops)
    return stops
}

export function toQueryString<T>(params: T): string {
    let keys = Object.keys(params)

    let queryString = "?"

    for (const key of keys) {
        queryString += `${key}=${params[key]}&`
    }

    return queryString
}

export function parseQueryString<T>(queryStr: string): T {

    if (queryStr.startsWith("?"))
        queryStr = queryStr.substring(1)

    let queryDict = {}
    for (let component of queryStr.split("&")) {
        const parts = component.split("=")

        if (parts.length == 1) {
            queryDict[parts[0]] = true
        } else {
            queryDict[parts[0]] = parts[1]
        }
    }
    return queryDict as T
}

export function applyPatch(to: TransportOrder, element: any, params: any) {
    const patchFunc = (current) => {
        return { ...current, ...params }
    }
    return TransportOrderExtensions.applyPatch(to, element, patchFunc)
}

export class TransportOrderExtensions {

    private static applyAPatch(t: StopAction, element: any, patchFunc: (any) => any): StopAction {
        if (t.actionId === element.actionId)
            return patchFunc(t)
        return t
    }

    private static applyPIPatch(t: PacketItem, element: any, patchFunc: (any) => any): Packet {
        if (t.packetItemId === element.packetItemId)
            return patchFunc(t)
        return t
    }

    private static applyPPatch(t: Packet, element: any, patchFunc: (any) => any): Packet {
        if (t.packetId === element.packetId)
            return patchFunc(t)
        t.items = t.items && t.items.map((pi) => TransportOrderExtensions.applyPIPatch(pi, element, patchFunc)).filter((x) => x != null)
        return t
    }

    private static applyFPatch(t: Freight, element: any, patchFunc: (any) => any): Freight {
        if (t.freightId === element.freightId)
            return patchFunc(t)
        t.packets = t.packets && t.packets.map((p) => TransportOrderExtensions.applyPPatch(p, element, patchFunc)).filter((x) => x != null)
        return t
    }
    static applySPatch(t: StopLocation, element: any, patchFunc: (any) => any): StopLocation {
        if (t.stopId === element.stopId)
            return patchFunc(t)
        t.actions = t.actions && t.actions.map((a) => TransportOrderExtensions.applyAPatch(a, element, patchFunc)).filter((x) => x != null)
        return t
    }

    private static applyTPatch(t: Transport, element: any, patchFunc: (any) => any): Transport {
        console.log("Patching transport")
        if (t.transportId === element.transportId)
            return patchFunc(t)
        t.stops = t.stops && t.stops.map((s) => TransportOrderExtensions.applySPatch(s, element, patchFunc)).filter((x) => x != null)
        return t
    }

    static applyPatch(to: TransportOrder, element: any, patchFunc: (any) => any): TransportOrder {
        if (to === element) {
            return patchFunc(to)
        }
        to.transports = to.transports && to.transports.map((t) => TransportOrderExtensions.applyTPatch(t, element, patchFunc))
        to.freight = to.freight && to.freight.map((f) => TransportOrderExtensions.applyFPatch(f, element, patchFunc)).filter((x) => x != null)

        return to
    }
}

export function enumToDropdownItemProps(enumToConvert: Class) {
    let enumValues: Array<DropdownItemProps> = [];
    Object.values(enumToConvert).forEach(value => {
        enumValues.push({
            key: "key_" + value,
            value: value,
            text: value
        });
    })
    return enumValues
}

export function openInNewTab(url: string) {
    window.open(url, '_blank')?.focus();
}