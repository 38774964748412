import * as React from "react";
import { Tab } from 'semantic-ui-react'
import { DiffWrapper } from "../diff/DiffWrapper";
import { TransportOrderConfigTest, TransportOrderConfigTestResult } from "../../../../../../generated";
import {CustomDiff} from "../diff/CustomDiff";

export interface FailedTestTabViewProps {
    source: any[]
}

export class FailedTestTabView extends React.Component<FailedTestTabViewProps, any> {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    getExpected(test: TransportOrderConfigTest) {
        if (test.type == TransportOrderConfigTest.TypeEnum.EXPORT) {
            return test.expectedResult.exportData
        } else {
            return JSON.stringify(test.expectedResult.importData, null, 4)
        }
    }

    getPanes() {
        return this.props.source.map((source) => ({
            menuItem: source.test?.name,
            render: () => <Tab.Pane>
                <CustomDiff
                    oldJson={source.result?.expected}
                    newJson={source.result?.fullResult}
                />
            </Tab.Pane>
        }))
    }

    render() {
        return <Tab props={this.props} panes={this.getPanes()} />
    }
}
export default FailedTestTabView