import * as React from "react";
import {Card, Icon, Label, List, Segment} from "semantic-ui-react";
import {ConfigContext} from "../../../context/ConfigContext";
import {backend} from "../../../../../xconvert-backend";
import {authentication} from "../../../../../authentication";
import {CompanyConfiguration} from "../../../../../generated";
import {updateConfig, workingConfig} from "../ConfigSignal";


export interface ApiGatewayCardState {
    config: CompanyConfiguration
    drawn: boolean
}

export class ApiGatewayCard extends React.Component<any, ApiGatewayCardState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading ApiGatewayCards")

        this.state = {
            config: null,
            drawn: false
        }
    }

    componentDidMount() {
        this.setState({config: JSON.parse(workingConfig.value)})
    }

    getIconFromDisabled(disabled: boolean) {
        if (disabled || disabled == null) {
            return <Icon style={{textAlign: 'right'}} name='x' color='red'/>
        }
        return <Icon style={{textAlign: 'right'}} name='check' color='green'/>

    }

    drawEmptyScriptIfScriptIsEmpty(script: string) {
        if (script == undefined || script == "") {
            return "Script is empty!"
        }
        return ""

    }

    drawCards() {
        if (this.state.config != null && this.state.config.apiGatewayConfigs != null) {
            return this.state.config.apiGatewayConfigs.map((apiGatewayConfig) => {
                return <Card>
                    <Card.Content>
                        <Card.Header>{apiGatewayConfig.api}</Card.Header>

                        <Card.Meta>{apiGatewayConfig.exchangeOptions}</Card.Meta>

                        <Card.Description>
                            <List items={[
                                'accountName:     ' + apiGatewayConfig.accountName,
                                'userName:    ' + apiGatewayConfig.userName,

                            ]}/>
                        </Card.Description>
                        <Card.Description>
                            active: {this.getIconFromDisabled(apiGatewayConfig.disabled)}
                        </Card.Description>
                        <Card.Description> MAP telematic tour active:
                            {this.getIconFromDisabled(!this.state.config.transportOrderScripts.telematicTourMappingScript?.active)}
                            {this.drawEmptyScriptIfScriptIsEmpty(this.state.config.transportOrderScripts.telematicTourMappingScript?.script)}
                        </Card.Description>
                    </Card.Content>
                </Card>
            })
        } else {
            return "no ApiGatewayConfigs found!"
        }
    }

    render() {
        console.log("Render Partners")

        return <Segment>
            <Label>ApiGateway</Label>
            <Card.Group>
                {this.drawCards()}
            </Card.Group>

        </Segment>
    }
}
