import * as React from "react";
import { Button, Divider, Form, Grid, Input, Label, Dropdown, DropdownItemProps, Segment } from 'semantic-ui-react';

import Notifications, { notify } from 'react-notify-toast';

import 'brace/mode/groovy'
import 'brace/ext/searchbox'
import { CompanyConfiguration, EtaEvent, GpsPosition, PositionTestEventRequest, QueuedEvent, StopLocation, TelematicState, TransportOrder, TransportOrderTestEventRequest } from "../../../../../generated";
import { ConfigContext } from "../../../context/ConfigContext";
import { backend } from "../../../../../xconvert-backend";
import { authentication } from "../../../../../authentication";

import { IfBox } from "../../../../style/if";
import { StatusUpdateModal } from "../../converter/ExportSim/StatusUpdateModal";
import {updateConfig, workingConfig } from "../../configChange/ConfigSignal";

export interface QueuedEventCreatorProps {
    dispatch: (any) => void
}

export interface QueuedEventCreatorState {
    queuedEvent: string
    config: string
    isConverting: boolean
    exportSimToId: string
    exportSimOrderId: string
    showStatusUpdateEventModal: boolean
    transportOrderObject: TransportOrder
    validJson: boolean
    isLoadingTransportOrder: boolean
    currentCompanyConfig: CompanyConfiguration,
    auth: any,
    showAddFileModal: boolean,
    addFilePath: string,
    showSaveAsTestModal: boolean,
    eventType: TransportOrderTestEventRequest.EventTypeEnum,
    newStatus: string,
    xRequestId: string | null,
    selectedEvent: EventEnum | null
    telematicState: TelematicState | null
    encoding: string | null
}

export enum EventEnum {
    ASSIGN_COMPANY_EVENT = "AssignCompanyEvent",
    UPDATE_EVENT = "UpdateEvent",
    STORNO_EVENT = "StornoEvent",
    STATUS_UPDATE_EVENT = "StatusUpdateEvent",
    POSITION_EVENT = "PositionEvent",
    ETA_EVENT = "EtaEvent"
}

export class QueuedEventCreator extends React.Component<QueuedEventCreatorProps, QueuedEventCreatorState> {

    static contextType = ConfigContext
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props) {
        super(props)
        console.log("loading QueuedEventCreator")

        this.state = {
            queuedEvent: "",
            config: "",
            isConverting: false,
            exportSimToId: "",
            exportSimOrderId: "",
            showStatusUpdateEventModal: false,
            transportOrderObject: null,
            validJson: true,
            isLoadingTransportOrder: false,
            currentCompanyConfig: null,
            auth: null,
            showAddFileModal: false,
            addFilePath: "//ROOT/logenios2customer",
            showSaveAsTestModal: false,
            eventType: null,
            newStatus: "",
            xRequestId: null,
            selectedEvent: null,
            telematicState: null,
            encoding: null,

        }
    }

    async componentDidMount() {
        this.setState({
            exportSimToId: this.context.exportSimToId,
            exportSimOrderId: this.context.exportSimOrderId,
            auth: backend.withTokenAuthHeader(authentication.token),
            currentCompanyConfig: JSON.parse(workingConfig.value),
        })
    }

    render() {

        return <Grid padded="horizontally" stackable columns='equal' centered>
            <Grid.Column width='5'>

                <Input style={{ width: 220 }} placeholder='TransportOrderId'
                    value={this.state.exportSimToId}
                    onChange={(evt) => this.setExportSimToId(evt.target.value)}
                />

                <Divider hidden />



            </Grid.Column>

            <Grid.Column>


                {this.drawButtons()}


                <IfBox shouldShow={this.state.showStatusUpdateEventModal}>
                    <StatusUpdateModal
                        isOpen={this.state.showStatusUpdateEventModal}
                        onClose={(status, changeType, telematicStateCode) => {

                            this.doConvertStatus(status, changeType, telematicStateCode)
                        }
                        }
                    />
                </IfBox>



            </Grid.Column>
            <Notifications />
        </Grid>


    }

    async doConvert(eventType: TransportOrderTestEventRequest.EventTypeEnum, status: string | null, telematicStateStatus: TelematicState.StatusCodeEnum | null) {
        try {

            this.setState({ isConverting: true })

            let auth = backend.withTokenAuthHeader(authentication.token)

            let orderCompanyId = null
            if (this.context.exportSimOrderId != null && this.context.exportSimOrderId != "") {
                orderCompanyId = this.context.exportSimOrderId
            }

            let telematicState = null
            if (telematicStateStatus != null) {
                telematicState = {} as TelematicState
                telematicState.statusCode = telematicStateStatus
            }

            let request = {} as TransportOrderTestEventRequest
            request.configCompanyId = this.context.companyId
            request.eventType = eventType
            request.newStatus = status
            request.telematicState = telematicState
            request.orderCompanyId = orderCompanyId
            request.transportOrder = JSON.parse(this.context.exportSimTo)
            request.transportOrderId = this.context.exportSimToId
            request.config = JSON.parse(workingConfig.value)

            let data = await backend.internalApi.getTransportOrderTestEvent(request, auth)

            console.log(data);

            this.setState({
                queuedEvent: JSON.stringify(data.event, null, 4),
                config: JSON.stringify(data.config, null, 4),
                auth: auth,
                isConverting: false
            })


            this.context.setScriptRunnerQueuedEvent(this.state.queuedEvent)
            this.props.dispatch(this.state.queuedEvent)
        } catch (ex) {
            console.log(ex)
            this.setState({ isConverting: false })
        }
    }

    async doConvertPosition() {
        try {

            this.setState({ isConverting: true })

            let auth = backend.withTokenAuthHeader(authentication.token)

            let orderCompanyId = null
            if (this.context.exportSimOrderId != null && this.context.exportSimOrderId != "") {
                orderCompanyId = this.context.exportSimOrderId
            }

            let position = {} as GpsPosition
            position.latitude = 42
            position.longitude = 42
            position.timestamp = new Date
            position.accuracyInM = 1


            let request = {} as PositionTestEventRequest
            request.configCompanyId = this.context.companyId
            request.transportOrder = JSON.parse(this.context.exportSimTo)
            request.transportOrderId = this.context.exportSimToId
            request.config = JSON.parse(workingConfig.value)
            request.position = position


            let data = await backend.internalApi.getPositionTestEvent(request, auth)

            console.log(data);

            this.setState({
                queuedEvent: JSON.stringify(data.event, null, 4),
                config: JSON.stringify(data.config, null, 4),
                auth: auth,
                isConverting: false
            })

            this.props.dispatch(this.state.queuedEvent)


        } catch (ex) {
            console.log(ex)
            this.setState({ isConverting: false })
        }
    }

    async doConvertEtaEvent() {
        try {

            this.setState({ isConverting: true })

            let auth = backend.withTokenAuthHeader(authentication.token)

            let orderCompanyId = null
            if (this.context.exportSimOrderId != null && this.context.exportSimOrderId != "") {
                orderCompanyId = this.context.exportSimOrderId
            }


            // Build QueuedEvent with EtaEvent

            let to = JSON.parse(this.context.exportSimTo) as TransportOrder

            let stops = [] as StopLocation[]
            to.transports.forEach(t => {
                t.stops.forEach(s => {
                    stops.push(s)
                })
            })



            let gpsPosition = {} as GpsPosition
            gpsPosition.accuracyInM = 10
            gpsPosition.latitude = to.transports[0].stops[0].coordinates.latitude
            gpsPosition.longitude = to.transports[0].stops[0].coordinates.longitude
            gpsPosition.timestamp = new Date()

            let etaEvent = {} as EtaEvent
            etaEvent.currentPosition = gpsPosition
            etaEvent.transportOrder = to
            etaEvent.driver = to.assignedDriver
            etaEvent.vehicle = to.assignedVehicle
            etaEvent.nextStops = stops
            etaEvent.timestamp = new Date()

            let queuedEvent = {} as QueuedEvent
            queuedEvent._id = "testId"
            queuedEvent.companyId = this.context.companyId
            queuedEvent.eventType = QueuedEvent.EventTypeEnum.ETAEVENT
            queuedEvent.etaEvent = etaEvent
            queuedEvent.timestamp = new Date()

            this.setState({
                queuedEvent: JSON.stringify(queuedEvent, null, 4),
                config: workingConfig.value,
                auth: auth,
                isConverting: false
            })
            this.props.dispatch(this.state.queuedEvent)


        } catch (ex) {
            console.log(ex)
            this.setState({ isConverting: false })
        }
    }





    setExportSimToId(value) {
        this.context.setExportSimToId(value)

        this.setState({ exportSimToId: value })
    }

    setExportSimOrderId(value) {
        this.setState({ exportSimOrderId: value })
        this.context.setExportSimOrderId(value)
    }





    async doConvertStatus(status, changeType, telematicStateCode) {

        this.setState({ showStatusUpdateEventModal: false })
        if ((status == null && telematicStateCode == null) || changeType == null) {

            console.log("(status and telematicStateCode) or changeType empty")
            return
        }
        console.log("status: " + status + "telematicStateCode: " + telematicStateCode + " changetype: " + changeType)

        await (this.doConvert(changeType, status, telematicStateCode))

    }

    enumToArray() {
        let enumValues: Array<DropdownItemProps> = [];
        Object.values(EventEnum).forEach(value => {
            enumValues.push({ key: value, value: value, text: value });
        })
        return enumValues
    }

    drawButtons() {
        return <Grid padded="horizontally" stackable columns='equal' centered>
            <Grid.Column>
                <Dropdown
                    selectOnBlur={false}
                    placeholder='Select Type'
                    fluid
                    search
                    selection
                    options={this.enumToArray()}
                    clearable
                    onChange={(event, { value }) => {
                        console.log(value);
                        this.setState({ selectedEvent: value as EventEnum })
                    }}
                />
            </Grid.Column>

            <Grid.Column>
                <Button disabled={this.state.selectedEvent == null} onClick={(evt) => this.sendEvent()} loading={this.state.isConverting}>Send</Button>

            </Grid.Column>

        </Grid>

    }

    async sendEvent() {
        let auth = backend.withTokenAuthHeader(authentication.token)
        let transportOrder = await backend.transportOrderApi.fetchTransportOrderById(this.context.exportSimToId, auth)

        let toAsJson = JSON.stringify(transportOrder, null, 4)
        this.context.setExportSimTo(toAsJson)
        this.context.setExportSimToValid(toAsJson)

        switch (this.state.selectedEvent) {
            case EventEnum.ASSIGN_COMPANY_EVENT:
                this.doConvert(TransportOrderTestEventRequest.EventTypeEnum.ASSIGNCOMPANY, null, null)
                return;
            case EventEnum.STATUS_UPDATE_EVENT:
                this.setState({ showStatusUpdateEventModal: true })
                return;
            case EventEnum.STORNO_EVENT:
                this.doConvert(TransportOrderTestEventRequest.EventTypeEnum.STORNO, null, null)
                return;
            case EventEnum.UPDATE_EVENT:
                this.doConvert(TransportOrderTestEventRequest.EventTypeEnum.UPDATE, null, null)
                return;
            case EventEnum.POSITION_EVENT:
                this.doConvertPosition()
                return;
            case EventEnum.ETA_EVENT:
                this.doConvertEtaEvent()
                return;
            default:
                notify.show('Event must be selected ', 'error', 5000, '#fc0303');
                return;
        }
    }

}