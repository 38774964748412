import * as React from "react";
import {Button, Header, Icon, Modal, Popup} from 'semantic-ui-react';
import {Origin} from "../../../../../../generated";


export interface AdminModifiedObjectInfoModalProps {
    isOpen: boolean
    tc?: any
    onClose: () => void
}

export interface ShowAdminModifiedObjectInfoButtonProps {
    tc?: any
    origin?: Origin
}


export class ShowAdminModifiedObjectInfoButton extends React.Component<ShowAdminModifiedObjectInfoButtonProps, any> {
    constructor(props: ShowAdminModifiedObjectInfoButtonProps) {
        super(props)
        this.state = {
            isOpen: false
        }
    }

    render() {
        return <>
                <AdminModifiedObjectInfoModal tc={this.props.tc} isOpen={this.state.isOpen}
                                              onClose={() => this.setState({isOpen: false})}/>
                <span onClick={() => {
                    this.setState({isOpen: true})
                }}>
                <Popup trigger={<Icon name="info" link style={{marginRight: "0px"}}/>} content={"View raw data"}
                       position={"left center"}/>
            </span>
        </>
    }
}


export class AdminModifiedObjectInfoModal extends React.Component<AdminModifiedObjectInfoModalProps, any> {

    constructor(props: AdminModifiedObjectInfoModalProps) {
        super(props);
        this.state = {
            copied: false, // Track whether content has been copied to the clipboard
        };
    }

    handleClose = () => {
        this.props.onClose()
    }

    copyToClipboard = () => {
        const { tc } = this.props;
        if (tc) {
            const textToCopy = JSON.stringify(tc, null, 4);
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);

            // Set copied to true to show a message or change button appearance
            this.setState({ copied: true });
        }
    }
    render() {
        const { isOpen } = this.props;
        const { copied } = this.state;
        return <Modal
            closeOnDimmerClick={false}
            closeIcon
            open={isOpen}
            onClose={this.handleClose}
            dimmer="blurring"
            size='small'
        >
            <Header icon='browser' content={"Tourenhistore"} />
            <Modal.Content style={{ height: "75vh", overflow: "auto" }}>
                    <pre>
                        {JSON.stringify(this.props.tc, null, 4)}
                    </pre>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={this.copyToClipboard}>
                    {copied ? (
                        "Copied"
                    ) : (
                        "Copy to Clipboard"
                    )}
                </Button>
                <Button primary onClick={() => this.props.onClose()}>
                    schließen
                </Button>
            </Modal.Actions>
        </Modal>
    }
}